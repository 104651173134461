import Constants from "./Constants";

export function getRecordMatcherForCategory(category) {
    if (category === Constants.CTX_CATEGORIES.ROOMTYPE) {
        return window.infinito.vao.model.biStatistics.recordMatchers.roomType;
    } else if (category === Constants.CTX_CATEGORIES.SEGMENT) {
        return window.infinito.vao.model.biStatistics.recordMatchers.marketSegment;
    }
    return null;
}

export function isTZTimestampsBalanced(primaryTs, contextTs) {
    return (
        primaryTs
        && typeof primaryTs === 'string'
        && contextTs
        && typeof contextTs === 'string'
        && window.infinito.vao.controller.dateHelper.calcHoursBetweenTZStrings(
            primaryTs,
            contextTs
        ) === 0
    )
}

export function getDisplayableTextForCategory(category) {
    if (category === Constants.CTX_CATEGORIES.ROOMTYPE) {
        return Constants.STRINGS.ROOM_TYPE;
    } else if (category === Constants.CTX_CATEGORIES.SEGMENT) {
        return Constants.STRINGS.SEGMENT;
    } else {
        return Constants.MISSING_STR;
    }
}