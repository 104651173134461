import { AppElement, html } from '../AppElement.js';

export const btnGroupSizes = {
    small: 'btn-group-sm',
    normal: '',
    large: 'btn-group-lg'
};

export const btnGroupVariations = {
    vertical: 'vertical'
};

export default class ButtonGroup extends AppElement {
    static get properties() {
        return {
            size: { type: String },
            variation: { type: String },
            slot: { type: Object } // because we are not using shadow DOM we cannot use real slot's
        };
    }

    constructor(props = {}) {
        super();
        this.size = props.size || '';
        this.variation = props.variation || '';
        this.compClass="vao__components--buttonGroup";
    }

    determineBtnGroupSizeCls() {
        if (!this.size || typeof this.size !== 'string' || this.size.length === 0) {
            return '';
        }
        if (this.size in btnGroupSizes) {
            return btnGroupSizes[this.size];
        }
        return this.size; // allow input to control the class
    }

    determineBtnGroupVariationCls() {
        if (!this.variation || typeof this.variation !== 'string' || this.variation.length === 0) {
            return '';
        }
        if (this.variation in btnGroupVariations) {
            return btnGroupVariations[this.variation];
        }
        return this.variation; // allow input to control the class
    }

    render() {
        return html`
<div class="${this.compClass} btn-group ${this.determineBtnGroupSizeCls()} ${this.determineBtnGroupVariationCls()}" role="group">
    ${this.slot}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ButtonGroup = ButtonGroup;
customElements.define('vao-button-group', ButtonGroup);
