import Constants from "../Constants";

const formatDataMap = new Map();

export default class BudgetUtils {
    static formatDate(budgetValue) {
        const startDate = budgetValue.startDate;
        const endDate = budgetValue.endDate;
        let formatted = 'undefined';
        if (!startDate || !endDate) {
            return formatted;
        }

        const key = startDate + endDate;
        if (formatDataMap.has(key)) {
            return formatDataMap.get(key);
        }

        const dateHelper = window.infinito.vao.controller.dateHelper;
        if (startDate === endDate) {
            formatted = dateHelper.getShortDayDateMonString(startDate);
        } else {
            const startOfStartMonth = dateHelper.format(
                dateHelper.calcStartOfMonth(startDate),
                dateHelper.getISODateFormat()
            );
            const endOfStartMonth = dateHelper.format(
                dateHelper.calcEndOfMonth(startDate),
                dateHelper.getISODateFormat()
            );
            if (startDate === startOfStartMonth && endDate === endOfStartMonth) {
                formatted = dateHelper.getPrettyMonthDateStringFrom(startDate);
            } else {
                formatted = dateHelper.getShortDayDateMonString(startDate)
                    + ' - '
                    + dateHelper.getShortDayDateMonString(endDate);
            }
        }
        formatDataMap.set(key, formatted);
        return formatted;
    }

    static getNameForMetric(metric) {
        switch (metric) {
            case 'availableRooms':
                return Constants.STRINGS.AVAILABLE;
            case 'roomsOtb':
                return Constants.STRINGS.ROOMS;
            case 'revenue':
                return Constants.STRINGS.REV;
            default:
                return metric;
        }
    }
}