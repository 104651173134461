import ContextTable from "./ContextTable";
import Constants from "../Constants";

export default class ContextTableRoomType extends ContextTable {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            firstStayDate: { type: String },
            lastStayDate: { type: String },
            pickupSetting: { type: Number },
            metric: { type: String },
        };
    }

    constructor(props = {}) {
        super(props);
    }

    reflow(props = {}) {
        super.reflow(props);
    }

    getCategory() {
        return Constants.CTX_CATEGORIES.ROOMTYPE;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ContextTableRoomType = ContextTableRoomType;
customElements.define('vao-context-table-room-type', ContextTableRoomType);
