import { AppElement, html, TemplateResult } from '../AppElement.js';
import Constants from "../Constants";

export default class TotalPickupTitle extends AppElement {
    static get properties() {
        return {
            name: { type: String },
            otb: { type: String },
            adr: { type: String },
            rev: { type: String },
            duration: { type: String },
            isLoading: { type: String },
            counters: {type:String}
        };
    }

    constructor(props = {}) {
        super();

        this.name = props.name;
        this.otb = props.otb;
        this.adr = props.adr;
        this.rev = props.rev;
        this.duration = props.duration;
        this.counters = props.counters;
        this.isLoading = props.isLoading;
    }

    reflow(props = {}) {
        this.name = props.name || this.name;
        this.otb = props.otb || this.otb;
        this.adr = props.adr || this.adr;
        this.rev = props.rev || this.rev;
        this.counters = props.counters || this.counters;
        this.duration = props.duration || this.duration;
        if (String(props.isLoading) === 'true' || String(props.isLoading) === 'false') {
            this.isLoading = String(props.isLoading);
        }
    }

    obtainRenderAbleEl(input, showLoad) {
        if (input instanceof HTMLElement || input instanceof TemplateResult) {
            return input;
        }

        let str;
        if (typeof input === 'string' && input.length > 0) {
            str = input;
        } else {
            str = Constants.MISSING_STR;
        }

        if (showLoad && String(this.isLoading) === 'true') {
            return html`<div class="loading-shimmer" style="width:45px;height:12px;"></div>`;
        }

        return html`<div>${str}</div>`;
    }

    renderName() {
       return this.obtainRenderAbleEl(this.name);
    }

    renderDuration() {
        if (typeof this.duration === 'string' && this.duration.length > 0) {
            return html`
                <div 
                    style="font-size:.8rem;margin-left:1rem;" 
                    data-placement="top" 
                    data-original-title 
                    data-trigger="${Constants.TOOLTIP.TRIGGERS.MANUAL}"
                    title="Total pickup duration"
                >
                    <span class="badge badge-primary">${this.duration}</span>
                </div>`;
        } else {
            return html`<div
                    style="font-size:.8rem;margin-left:1rem;"
                    data-placement="top"
                    data-original-title
                    data-trigger="${Constants.TOOLTIP.TRIGGERS.MANUAL}"
                    title="Number of hotels which have not submitted the data"
            >
                <span class="badge badge-warning">${this.counters}</span>

            </div>`;
        }
    }

    renderOtb() {
        return this.obtainRenderAbleEl(this.otb, true);
    }

    renderAdr() {
        return this.obtainRenderAbleEl(this.adr, true);
    }

    renderRev() {
        return this.obtainRenderAbleEl(this.rev, true);
    }

    render() {
        return html`
        <div class="vao__components--totalPickupTitle">
            <div class="vao__components--totalPickupTitle-title">${this.renderName()}${this.renderDuration()}</div>
            <div class="vao__components--totalPickupTitle-body">
                <div class="vao__components--totalPickupTitle-otb">${this.renderOtb()}</div>
                <div class="vao__components--totalPickupTitle-adr">${this.renderAdr()}</div>
                <div class="vao__components--totalPickupTitle-rev">${this.renderRev()}</div>
            </div>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TotalPickupTitle = TotalPickupTitle;
customElements.define('vao-total-pickup-title', TotalPickupTitle);
