import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryCompPricing extends AppElement {
    static get properties() {
        return {
            compSetAvg: { type: String },
            compSetAvgConsider: { type: String },
            dow: { type: String },
            leadTime: { type: String },
            numCompSetChangedRate: { type: String },
            pickupDesc: { type: String },
            compSetAvgPU: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = props.pickupSetting;
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        if ('pickupSetting' in props) {
            this.pickupSetting = props.pickupSetting;
        }
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.compSetAvg = Constants.MISSING_STR;
        this.compSetAvgConsider = Constants.MISSING_STR;
        this.dow = Constants.MISSING_STR;
        this.leadTime = Constants.MISSING_STR;
        this.numCompSetChangedRate = Constants.MISSING_STR;
        this.pickupDesc = Constants.MISSING_STR;
        this.compSetAvgPU = Constants.MISSING_STR;
        this.typicalLow = Constants.MISSING_STR;
        this.typicalHigh = Constants.MISSING_STR;
        this.typicalNow = Constants.MISSING_STR;
    }

    fill() {
        if (
            !$.isNumeric(this.pickupSetting)
            || typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.compAverageRate,
                    modelBiStats.fields.compSetAvgLeadSiblingPace,
                    modelBiStats.fields.compPricesPickup,
                    modelBiStats.fields.compAverageRatePickup
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                let compAvg = ((procData.compAverageRate || {})[this.stayDate] || {}).value || null;
                let compSibPace = ((procData.compSetAvgLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let compPricesPU = ((procData.compPricesPickup || {})[this.stayDate] || {}).value || null;
                let compAvgPU = ((procData.compAverageRatePickup || {})[this.stayDate] || {}).value || null;

                if ($.isNumeric(compAvg)) {
                    this.compSetAvg = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        compAvg,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.compSetAvg = Constants.MISSING_STR;
                }

                if (
                    typeof compSibPace === 'object'
                    && 'T0' in compSibPace
                    && typeof compSibPace.T0 === 'object'
                    && 'setDenseRank' in compSibPace.T0
                    && 'setDenseLength' in compSibPace.T0
                ) {
                    let pnn = window.infinito.vao.controller.analyseAttributesHelper
                        .getCompPricePNNForSetRank(
                            compSibPace.T0.setDenseRank,
                            compSibPace.T0.setDenseLength
                        );
                    if (pnn) {
                        let pnnPretty = window.infinito.vao.controller.analyseAttributesHelper
                            .getCompPriceUserFriendlyForPNN(pnn);
                        if (pnnPretty) {
                            this.compSetAvgConsider =  pnnPretty;
                        } else {
                            this.compSetAvgConsider =  pnn;
                        }
                    } else {
                        this.compSetAvgConsider =  Constants.MISSING_STR;
                    }

                    let t0 = parseFloat((compSibPace.T0 || {}).value || 0);
                    let t1 = parseFloat((compSibPace.T1 || {}).value || 0);
                    let t2 = parseFloat((compSibPace.T2 || {}).value || 0);
                    let t3 = parseFloat((compSibPace.T3 || {}).value || 0);
                    let t4 = parseFloat((compSibPace.T4 || {}).value || 0);
                    let set = [t0, t1, t2, t3, t4];
                    set = set.filter((setItem) => {
                        if (!setItem || typeof setItem !== 'number' || Number.isNaN(setItem) || setItem <= 0) {
                            return false;
                        }
                        return true;
                    });
                    let max = Math.max.apply(null, set);
                    let min = Math.min.apply(null, set);
                    this.typicalLow = min;
                    this.typicalHigh = max;
                    this.typicalNow = t0;
                } else {
                    this.compSetAvgConsider = Constants.MISSING_STR;
                    this.typicalLow = Constants.MISSING_STR;
                    this.typicalHigh = Constants.MISSING_STR;
                    this.typicalNow = Constants.MISSING_STR;
                }

                // Put these here to help batch lit async DOM update
                this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(this.stayDate);
                this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    this.stayDate,
                    this.recordDate
                );
                this.pickupDesc = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
                    this.pickupSetting
                );

                if ( typeof compPricesPU === 'string' && compPricesPU !== 'no data') {
                    compPricesPU = JSON.parse(compPricesPU);
                }
                if (compPricesPU && typeof compPricesPU === 'object') {
                    let hasChanged = 0;
                    Object.values(compPricesPU).forEach((puVal) => {
                        let pickupAsNum = window.infinito.vao.controller.moneyHelper
                            .unformatMoneyBracketStyle(
                                window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    puVal,
                                    0,
                                    true,
                                    this.locale
                                ),
                                this.locale
                            );

                        if (typeof pickupAsNum === 'number'
                            && !Number.isNaN(pickupAsNum)
                            && pickupAsNum !== 0) {
                            hasChanged += 1;
                        }
                    });
                    this.numCompSetChangedRate = hasChanged;
                } else {
                    this.numCompSetChangedRate = Constants.MISSING_STR;
                }

                if ($.isNumeric(compAvgPU)) {
                    this.compSetAvgPU = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        compAvgPU,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.compSetAvgPU = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyCompPricing">
    ${storyHtml`
    <p>
        Our comp set is currently pricing at 
        ${new StoryText(this.compSetAvg, true, storyTextTypes.TYPE_NEUTRAL)}. 
        Overall, this is considered 
        ${new StoryText(this.compSetAvgConsider, true, storyTextTypes.TYPE_TEXTUAL)} for 
        a ${new StoryText(this.dow, true, storyTextTypes.TYPE_NEUTRAL)}, 
        ${new StoryText(this.leadTime, true, storyTextTypes.TYPE_NEUTRAL)} days out. 
    </p>
    <p>
        ${new StoryText(this.numCompSetChangedRate, true, storyTextTypes.TYPE_NEUTRAL)} competitors 
        changed their rates since 
        ${new StoryText(this.pickupDesc, true, storyTextTypes.TYPE_NEUTRAL)}. Over this time, we saw 
        the average comp set price change by 
        ${new StoryText(this.compSetAvgPU, true, storyTextTypes.TYPE_MONETARY, this.locale)}.
    </p>
    `}
    <vao-typical-price-evaluation 
        .low="${this.typicalLow}" 
        .high="${this.typicalHigh}" 
        .now="${this.typicalNow}">
    </vao-typical-price-evaluation>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryCompPricing = StoryCompPricing;
customElements.define('vao-story-comp-pricing', StoryCompPricing);
