import { AppElement, html } from '../AppElement.js';
import TableNoDataRow from '../table/TableNoDataRow';
import Table from '../table/Table';
import TableCell, { tableCellTypes } from '../table/TableCell';
import TableColumn from '../table/TableColumn';
import TableRow from '../table/TableRow';
import { getIdentityDependantTableStateKey } from '../TableUtils';

export default class PerformanceForecastTable extends AppElement {
    static get properties() {
        return {
            recordDate: { type: String },
            hotels: { type: Array }
        };
    }

    constructor() {
        super();

        this.id = AppElement.getUniqueElementId();
        this.cache = {};

        this.recordDate = null;
        this.hotels = null;
        this.months = this.calculateMonths();
    }

    calculateMonths() {
        let months = [];

        let date = moment(window.infinito.vao.controller.storageHelper.getCurrentDate())
            .startOf('month');
        for (let i = 0; i < 6; i++) {
            // start from next month
            date.add(1, 'month');
            months.push(date.format('MMMM YYYY'));
        }
        months.push('Overall');

        return months;
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [
            new TableColumn(new TableCell({
                field: 'hotelName',
                value: 'Hotel Name',
                type: tableCellTypes.TH
            })),
            ...this.months.map(month => new TableColumn(new TableCell({
                field: month,
                value: month,
                type: tableCellTypes.TH
            })))
        ];
        this.cache.cols = cols;
        return cols;
    }

    makeTableRow(hotelName) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(hotelName)) {
            return this.cache.rowMap.get(hotelName);
        }

        const cells = [
            new TableCell({
                field: 'hotelName',
                value: hotelName,
                isLoading: true
            }),
            ...this.months.map(month => new TableCell({
                field: month,
                value: null,
                isLoading: true
            }))
        ];
        const row = new TableRow(cells);

        this.cache.rowMap.set(hotelName, row);
        return row;
    }

    makeTableRows() {
        return this.hotels.map(hotel => {
            return this.makeTableRow(hotel.name);
        });
    }

    getTable() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            const stateKey = getIdentityDependantTableStateKey(
                `PerformanceForecastTable_${this.getTableStateKey()}`
            );
            this.cache.table = new Table({
                stateKey: stateKey,
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ]
            });
        }

        return this.cache.table;
    }

    getTableStateKey() {
        return 'group';
    }

    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows
            });
        }

        return table;
    }

    domTheTable(table) {
        $('#' + this.id)
            .find('.vao__components--performanceForecast-table')
            .empty()
            .append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    fill() {
        // Expect child class overrides.
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
    }

    updated(changedProperties) {
        super.updated(changedProperties);
        this.fill();
    }

    render() {
        return html`
            <div class="vao__components--performanceForecast">
                <div class="card-body">
                    <div class="vao__components--performanceForecast-title">
                        <h6 class="vao__components--flashGroup-bodySectionTitle">Performance Forecast</h6>
                    </div>
                    <div class="vao__components--performanceForecast-table"></div>
                </div>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PerformanceForecastTable = PerformanceForecastTable;
customElements.define('vao-performance-forecast-table', PerformanceForecastTable);
