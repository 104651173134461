import { AppElement, html, TemplateResult } from '../AppElement.js';
import Button from '../button/Button';
import Icon from "../icon/Icon";
import ColorArray from "../ColorArray";
import Constants from "../Constants";

export default class FlashExpandCard extends AppElement {
    static get properties() {
        return {
            actionEl: { type: Object },
            hotel_id: { type: Object },
            contentEl: { type: Object },
            expandEl: { type: Object },
            onHelp: { type: Object },
            isExpanded : { type: String },
            borderCol: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();
        this.indiIco = new Icon({
            cls: Constants.ICONS.CHEVRON_RIGHT
        });
        this._expandElCache = null;
        this.isShowingHelpTooltips = false;

        this.actionEl = props.actionEl;
        this.hotel_id = props.hotel_id;
        this.contentEl = props.contentEl;
        this.expandEl = props.expandEl;
        this.onHelp = props.onHelp;
        this.isExpanded = props.isExpanded;
        this.borderCol = props.borderCol;
    }

    reflow(props = {}) {
        this.actionEl = props.actionEl || this.actionEl;
        this.hotel_id = props.hotel_id || this.hotel_id;
        this.contentEl = props.contentEl || this.contentEl;
        this.expandEl = props.expandEl || this.expandEl;
        this.onHelp = props.onHelp || this.onHelp;
        if (String(props.isExpanded) === 'true' || String(props.isExpanded) === 'false') {
            this.isExpanded = String(props.isExpanded);
        }
        this.borderCol = props.borderCol || this.borderCol;
    }

    _isExpanded() {
        return String(this.isExpanded) === 'true';
    }

    getIsExpandedClass() {
        if (this._isExpanded()) {
            return ' expanded ';
        }
        return '';
    }

    obtainRenderEl(inputEl) {
        let el;
        if (inputEl instanceof HTMLElement || inputEl instanceof TemplateResult) {
            el = inputEl;
        } else {
            el = html``;
        }
        return el;
    }

    renderAction() {
        let actionEl = this.obtainRenderEl(this.actionEl);
        return html`
            <div class="vao__components--flashExpandCard-action">
                ${actionEl}
            </div>
        `;
    }

    renderContent() {
        let contentEl = this.obtainRenderEl(this.contentEl);
        return html`
            <div class="vao__components--flashExpandCard-content">
                ${contentEl}
            </div>
        `;
    }

    onMonthlyButtonClick(){
        let hd = this.hotel_id;
        let body =  '<input type="hidden" id="hid" value="'+this.hotel_id.id+'" />' +
                    '<input type="hidden" id="hname" value="'+this.hotel_id.name+'" />' +
                    '<vao-page-monthlyanalysis-modal hotel_id="1"></vao-page-monthlyanalysis-modal>';

        let $title =  $('<div></div>').append(
            `<h5>Monthly Analysis</h5>`
        );
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'xl'
        });
    }


    renderIndication() {
        let helpEl;
        if (typeof this.onHelp === 'function') {
            helpEl = new Button({
                startIcon: Constants.ICONS.INFO,
                variant: 'invert',
                size: 'xs',
                onClick: () => {
                    this.onHelp();
                }
            });
        } else if (this.onHelp === 'tooltips') {
            helpEl = new Button({
                startIcon: Constants.ICONS.INFO,
                variant: 'invert',
                size: 'xs',
                onClick: () => {
                    const root = `#${this.id} .vao__components--flashExpandCard-face`;
                    const targetRootChildren = 'div:not(.vao__components--flashExpandCard-indication)';
                    const targetTooltips = '*[data-original-title]';
                    const selector = `${root} ${targetRootChildren} ${targetTooltips}`;
                    if (this.isShowingHelpTooltips) {
                        this.isShowingHelpTooltips = false;
                        $(selector).tooltip('hide');
                    } else {
                        this.isShowingHelpTooltips = true;
                        $(selector).tooltip('show');
                        // Automatically hide the tooltips.
                        setTimeout(() => {
                            if (this.isShowingHelpTooltips) {
                                this.isShowingHelpTooltips = false;
                                $(selector).tooltip('hide');
                            }
                        }, 2000);
                    }
                }
            });
        } else {
            helpEl = html``;
        }

        return html`
            <div class="vao__components--flashExpandCard-indication">
                <div class="vao__components--flashExpandCard-indication-info">
                    ${helpEl}
                </div>
                <div class="vao__components--flashExpandCard-indication-exp">
                    ${this.indiIco}
                </div>
            </div>
        `;
    }

    renderBody() {
        if (this._expandElCache === null && this._isExpanded()) {
            this._expandElCache = this.obtainRenderEl(this.expandEl);
        }
        return this._expandElCache || '';
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        let $this = $('#' + this.id);

        // let $thisBody = $this.find('.vao__components--flashExpandCard-body');
        //
        // if (this._isExpanded()) {
        //     $thisBody.collapse('show');
        // } else {
        //     $thisBody.collapse('hide');
        // }

        $this.on('click', (event) => {
            let target = $(event.target);
            // let $_this = $('#' + this.id);

            let isCurrentTargetBtn = target.is('button');
            let isTargetChildOfABtn = target.parents('button').length;
            let isTargetChildOfContent = target.parents('.vao__components--flashExpandCard-body').length;
            let isTargetOnTheBody = target.is('.vao__components--flashExpandCard-body');

            if (isCurrentTargetBtn || isTargetChildOfABtn || isTargetChildOfContent || isTargetOnTheBody) {
                return;
            }

            if (this._isExpanded()) {
                this.isExpanded = 'false';
                // $_this.find('.vao__components--flashExpandCard-body').collapse('hide');
            } else {
                this.isExpanded = 'true';
                // $_this.find('.vao__components--flashExpandCard-body').collapse('show');
            }
        });
    }

    renderCss() {
        if (ColorArray.isHexColor(this.borderCol)) {
            return `border: 2px solid ${this.borderCol}`;
        } else {
            return '';
        }
    }

    render() {
        if(this.hotel_id){
            return html`
        <div class="vao__components--flashExpandCard ${this.getIsExpandedClass()}" style="${this.renderCss()}">
            <div class="vao__components--flashExpandCard-face">
                ${this.renderAction()}
                <div class="vao__components--flashExpandCard-divider"></div>
                <button  class="btn" style="margin-top: 15px;margin-right:15px;border-color: rgb(33 150 243);color:grey;height:38px;width:auto;" @click="${this.onMonthlyButtonClick}"><i class="fas fa-chart-area"></i></button>
                <div class="vao__components--flashExpandCard-divider"></div>
                ${this.renderContent()}
                <div class="vao__components--flashExpandCard-divider"></div>
                ${this.renderIndication()}
            </div>
            <div class="vao__components--flashExpandCard-body">
                ${this.renderBody()}
            </div>
        </div>
        `;
        }else{
            return html`
        <div class="vao__components--flashExpandCard ${this.getIsExpandedClass()}" style="${this.renderCss()}">
            <div class="vao__components--flashExpandCard-face">
                ${this.renderAction()}
                <div class="vao__components--flashExpandCard-divider"></div>
                ${this.renderContent()}
                <div class="vao__components--flashExpandCard-divider"></div>
                ${this.renderIndication()}
            </div>
            <div class="vao__components--flashExpandCard-body">
                ${this.renderBody()}
            </div>
        </div>
        `;
        }

    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.FlashExpandCard = FlashExpandCard;
customElements.define('vao-flash-expand-card', FlashExpandCard);
