import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StoryForecastSubtitle extends AppElement {
    static get properties() {
        return {
            forecast: { type: String },
            badgeClass: { type: String },

            recordDate: { type: String },
            stayDate: { type: String },
            hotel: { type: Object },
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.hotel = props.hotel || this.hotel;

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.hotel = props.hotel || this.hotel;
        this.fill();
    }

    empty() {
        this.forecast = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !this.recordDate
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !this.stayDate
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.occupancyLeaderCloserTrendProphecy
                ]
            }),
            (data, procData) => {
                const _procData = procData || {};
                let occProphecy =
                    ((_procData.occupancyLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || null;

                if (
                    typeof occProphecy === 'object'
                    && 'leadersClosersProphecy' in occProphecy
                    && $.isNumeric(occProphecy.leadersClosersProphecy)
                ) {
                    this.forecast = (window.infinito.vao.controller.utils.interpretOccupancyForecastOutput(
                            occProphecy.leadersClosersProphecy
                    ) || Constants.MISSING_STR);
                    if (this.forecast === 'danger zone') {
                        this.badgeClass = 'badge-dark';
                    } else if (this.forecast === 'no fill') {
                        this.badgeClass = 'badge-danger';
                    } else if (this.forecast === 'ability to fill') {
                        this.badgeClass = 'badge-warning';
                    } else if (this.forecast === 'excess demand') {
                        this.badgeClass = 'badge-success';
                    } else {
                        this.badgeClass = 'badge-primary';
                    }

                } else {
                    this.forecast = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyForecastSubtitle">
    <vao-story-subtitle subtitle="${this.forecast}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryForecastSubtitle = StoryForecastSubtitle;
customElements.define('vao-story-forecast-subtitle', StoryForecastSubtitle);
