import { html } from '../AppElement';
import Constants from '../Constants';
import NumericVarianceBlock from '../numericVarianceBlock/NumericVarianceBlock';
import PickupPerMonth from './PickupPerMonth';

export default class PickupPerMonthHotel extends PickupPerMonth {
    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            pickupSetting: { type: String }
        };
    }

    constructor(props = {}) {
        super(props);
        this.isgroup = infinito.vao.controller.storageHelper.getState();
        this.hotel = props.hotel;
    }

    reflow(props = {}) {
        super.reflow(props);
        this.hotel = props.hotel || this.hotel;
    }

    getTableStateKey() {
        return 'hotel';
    }

    fillRows() {
        let biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                fields: [
                    biStatistics.fields.occupancyTwelveMonths,
                    biStatistics.fields.roomsOtbTwelveMonths,
                    biStatistics.fields.roomsOtbPickupTwelveMonths,
                    biStatistics.fields.adrTwelveMonths,
                    biStatistics.fields.adrPickupTwelveMonths,
                    biStatistics.fields.revenueTwelveMonths,
                    biStatistics.fields.revenuePickupTwelveMonths,
                    biStatistics.fields.occupancyMonthToDay,
                    biStatistics.fields.roomsOtbMonthToDay,
                    biStatistics.fields.adrMonthToDay,
                    biStatistics.fields.revenueMonthToDay
                ],
                pickupOffset: this.pickupSetting
            }),
            (rawData, procData) => {
                if (!procData || Object.keys(procData).length === 0) {
                    super.empty();
                    return;
                }
                let foundAnyData = false;
                let _procData = (procData) || {};
                let cell;
                let row;

                let occupancyTwelveMonths = super.objectify((_procData.occupancyTwelveMonths || {}).value);
                let roomsOtbTwelveMonths = super.objectify((_procData.roomsOtbTwelveMonths || {}).value);
                let roomsOtbPickupTwelveMonths = super.objectify((_procData.roomsOtbPickupTwelveMonths || {}).value);
                let adrTwelveMonths = super.objectify((_procData.adrTwelveMonths || {}).value);
                let adrPickupTwelveMonths = super.objectify((_procData.adrPickupTwelveMonths || {}).value);
                let revenueTwelveMonths = super.objectify((_procData.revenueTwelveMonths || {}).value);
                let revenuePickupTwelveMonths = super.objectify((_procData.revenuePickupTwelveMonths || {}).value);
                let occupancyMonthToDay = (_procData.occupancyMonthToDay || {}).value;
                let roomsOtbMonthToDay = (_procData.roomsOtbMonthToDay || {}).value;
                let adrMonthToDay = (_procData.adrMonthToDay || {}).value;
                let revenueMonthToDay = (_procData.revenueMonthToDay || {}).value;

                // MTD Row.
                row = this.cache.rowMap.get('MTD');

                cell = row.tableCellPerField.get(this.cellFieldMap.occ);
                if ($.isNumeric(occupancyMonthToDay)) {
                    foundAnyData = true;
                    cell.value = this.formatPercentageValue(occupancyMonthToDay);
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(this.cellFieldMap.rms);
                if ($.isNumeric(roomsOtbMonthToDay)) {
                    foundAnyData = true;
                    cell.value = roomsOtbMonthToDay;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(this.cellFieldMap.adr);
                if ($.isNumeric(adrMonthToDay)) {
                    foundAnyData = true;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adrMonthToDay,
                        Constants.ADR_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;
                cell = row.tableCellPerField.get(this.cellFieldMap.rev);
                if ($.isNumeric(revenueMonthToDay)) {
                    foundAnyData = true;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueMonthToDay,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                // Pickup -

                cell = row.tableCellPerField.get(this.cellFieldMap.pkuprms);
                if ($.isNumeric(roomsOtbPickupTwelveMonths)) {
                    foundAnyData = true;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        roomsOtbPickupTwelveMonths,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(this.cellFieldMap.pkupadr);
                if ($.isNumeric(adrPickupTwelveMonths)) {
                    foundAnyData = true;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adrPickupTwelveMonths,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;


                cell = row.tableCellPerField.get(this.cellFieldMap.pkuprev);
                if ($.isNumeric(revenuePickupTwelveMonths)) {
                    foundAnyData = true;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenuePickupTwelveMonths,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;


                super.forEachMonthKey((month, yearMonKey) => {
                    let row = this.cache.rowMap.get(month);
                    let occupancyTwelveMonthsVal = occupancyTwelveMonths[yearMonKey];
                    let roomsOtbTwelveMonthsVal = roomsOtbTwelveMonths[yearMonKey];
                    let roomsOtbPickupTwelveMonthsVal = roomsOtbPickupTwelveMonths[yearMonKey];
                    let adrTwelveMonthsVal = adrTwelveMonths[yearMonKey];
                    let adrPickupTwelveMonthsVal = adrPickupTwelveMonths[yearMonKey];
                    let revenueTwelveMonthsVal = revenueTwelveMonths[yearMonKey];
                    let revenuePickupTwelveMonthsVal = revenuePickupTwelveMonths[yearMonKey];

                    // Occupancy
                    cell = row.tableCellPerField.get(this.cellFieldMap.occ);
                    if ($.isNumeric(occupancyTwelveMonthsVal)) {
                        foundAnyData = true;
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${this.formatPercentageValue(occupancyTwelveMonthsVal)}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // Rooms
                    cell = row.tableCellPerField.get(this.cellFieldMap.rms);
                    if ($.isNumeric(roomsOtbTwelveMonthsVal)) {
                        foundAnyData = true;
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${roomsOtbTwelveMonthsVal}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // ADR
                    cell = row.tableCellPerField.get(this.cellFieldMap.adr);
                    if ($.isNumeric(adrTwelveMonthsVal)) {
                        foundAnyData = true;
                        const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            adrTwelveMonthsVal,
                            Constants.ADR_DIGITS,
                            true,
                            this.hotel.locale
                        );
                        let cellVal = html`
                            <div style="flex-direction:column;">
                                <div>${formatted}</div>

                            </div>`;
                        cell.value = cellVal;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // Rev
                    cell = row.tableCellPerField.get(this.cellFieldMap.rev);
                    if ($.isNumeric(revenueTwelveMonthsVal)) {
                        foundAnyData = true;
                        const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            revenueTwelveMonthsVal,
                            Constants.REVENUE_DIGITS,
                            true,
                            this.hotel.locale
                        );
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${formatted}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // Pkup RMS
                    cell = row.tableCellPerField.get(this.cellFieldMap.pkuprms);

                    if ($.isNumeric(roomsOtbPickupTwelveMonthsVal)) {
                        foundAnyData = true;
                        const numVarBlock = new NumericVarianceBlock({
                            numericValue: roomsOtbPickupTwelveMonthsVal,
                            formattedValue: String(roomsOtbPickupTwelveMonthsVal),
                            beEmptyOnEmpty: 'true'
                        });

                        const varBlock = numVarBlock.isEmpty()
                            ? html``
                            : html`
                                    <div style="font-size:.7rem">${numVarBlock}</div>`;

                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // Pkup ADR
                    cell = row.tableCellPerField.get(this.cellFieldMap.pkupadr);

                    if ($.isNumeric(adrPickupTwelveMonthsVal)) {
                        foundAnyData = true;
                        let varBlock;
                        if (adrPickupTwelveMonthsVal > 0 || adrPickupTwelveMonthsVal < 0) {
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                adrPickupTwelveMonthsVal,
                                Constants.ADR_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            const _varBlock = new NumericVarianceBlock({
                                numericValue: adrPickupTwelveMonthsVal,
                                formattedValue: formatted,
                                beEmptyOnEmpty: 'true'
                            });
                            varBlock = html`
                                <div style="font-size:.7rem;">${_varBlock}</div>`;
                        } else {
                            varBlock = html``;
                        }
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // Pkup Rev
                    cell = row.tableCellPerField.get(this.cellFieldMap.pkuprev);

                    if ($.isNumeric(revenuePickupTwelveMonthsVal)) {
                        foundAnyData = true;
                        let varBlock;
                        if (revenuePickupTwelveMonthsVal > 0 || revenuePickupTwelveMonthsVal < 0) {
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                revenuePickupTwelveMonthsVal,
                                Constants.REVENUE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            const _varBlock = new NumericVarianceBlock({
                                numericValue: revenuePickupTwelveMonthsVal,
                                formattedValue: formatted,
                                beEmptyOnEmpty: 'true'
                            });
                            varBlock = html`
                                <div style="font-size:.7rem;">${_varBlock}</div>`;
                        } else {
                            varBlock = html``;
                        }
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;
                });

                if (!foundAnyData) {
                    super.empty();
                }
            }
        );
    }


    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            super.empty();
            return;
        }

        let rows = super.makeTableRows();
        this.fillRows();
        let table = super.reflowTable(rows);
        super.domTheTable(table);
    }

    formatPercentageValue(unformattedValue) {
        let percent = parseFloat(unformattedValue * 100);
        if (!Number.isNaN(percent)) {
            return percent.toFixed(2) + '%';
        }
        return unformattedValue;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PickupPerMonthHotel = PickupPerMonthHotel;
customElements.define('vao-pickup-per-month-hotel', PickupPerMonthHotel);
