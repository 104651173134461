import {AppElement, html} from '../AppElement.js';
import Button from "../button/Button";
import TotalPickupHotelTrendDetails from "./TotalPickupHotelTrendDetails";
import Constants from "../Constants";

const ASSESSMENT_INLINE = 'inline';
const ASSESSMENT_ABOVE = 'above';
const ASSESSMENT_BELOW = 'below';

export default class TotalPickupHotelTrend extends AppElement {
    static get properties() {
        return {
            totalPickup: { type: String },
            totalPickupTrend: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        // Not props. Required to automatically fill props.
        this.hotel = props.hotel;
        this.pickupSetting = props.pickupSetting;
        this.recordDate = props.recordDate;

        // Props.
        this.totalPickup = props.totalPickup;
        this.totalPickupTrend = props.totalPickupTrend;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = typeof props.pickupSetting === 'undefined' ? this.pickupSetting : props.pickupSetting;

        this.totalPickup = props.totalPickup || this.totalPickup;
        this.totalPickupTrend = props.totalPickupTrend || this.totalPickupTrend;
    }

    static determinePickupAssessment(pickup, pickupTrend) {
        const tolerance = 0.05;
        const fPickup = parseFloat(pickup);
        const fPickupTrend = parseFloat(pickupTrend);
        const upperThreshold = (1 + tolerance) * fPickupTrend;
        const lowerThreshold = (1 - tolerance) * fPickupTrend;

        if (fPickup > upperThreshold) {
            return ASSESSMENT_ABOVE;
        }

        if (fPickup < lowerThreshold) {
            return ASSESSMENT_BELOW;
        }

        return ASSESSMENT_INLINE;
    }

    renderBtn() {
        let icon, color;

        if (
            $.isNumeric(this.totalPickup)
            && $.isNumeric(this.totalPickupTrend)
        ) {
            const ass = TotalPickupHotelTrend.determinePickupAssessment(this.totalPickup, this.totalPickupTrend);
            if (ass === ASSESSMENT_INLINE) {
                icon = Constants.ICONS.EMOTE_MEH;
                color = 'primary';
            } else if (ass === ASSESSMENT_ABOVE) {
                icon = Constants.ICONS.EMOTE_SMILE;
                color = 'success';
            } else if (ass === ASSESSMENT_BELOW) {
                icon = Constants.ICONS.EMOTE_FROWN;
                color = 'danger';
            }
        } else if (
            $.isNumeric(this.totalPickup)
            || $.isNumeric(this.totalPickupTrend)
        ) {
            icon = Constants.ICONS.EMOTE_MEH_BLANK;
            color = 'light';
        }

        if (icon && color) {
            return new Button({
                startIcon: icon,
                variant: 'invert',
                color: color,
                size: 'normal',
                tooltip: 'Trend',
                tooltipTrigger: Constants.TOOLTIP.TRIGGERS.MANUAL,
                onClick: () => {
                    const prettyRecordDate = window.infinito.vao.controller.dateHelper
                        .getShortDayDateMonFullYearString(this.recordDate);
                    const prettyPickupSetting = window.infinito.vao.controller.pickupHelper
                        .getPickupOffsetTextForPickupOffset(this.pickupSetting);
                    const $title =  $('<div></div>').append(
                            '<h5>Total Pickup</h5>'
                        ).append(new window.vao.components.MiniContext({
                            recordDate: prettyRecordDate,
                            pickupSetting: prettyPickupSetting,
                            isModalStyle: false
                        }));
                    let totalPickupHotelTrendDetails = new TotalPickupHotelTrendDetails({
                        hotel: this.hotel,
                        pickupSetting: this.pickupSetting,
                        recordDate: this.recordDate
                    });
                    window.infinito.components.basicModal.render({
                        title: $title,
                        isTitleHtml: true,
                        bodyElem: $(totalPickupHotelTrendDetails)
                    });
                }
            });
        } else {
            return '';
        }
    }

    empty() {
        this.totalPickup = null;
        this.totalPickupTrend = null;
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !$.isNumeric(this.pickupSetting)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        ) {
            return;
        }

        this.empty();

        const biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                fields: [
                    biStatistics.fields.revenueTotalPickup,
                    biStatistics.fields.revenueTotalPickupTrend
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                const _procData = procData || {};
                this.totalPickup = (_procData.revenueTotalPickup || {}).value;
                this.totalPickupTrend = (_procData.revenueTotalPickupTrend || {}).value;
            }
        )
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties instanceof Map) {
            let hotelChange = _changedProperties.get('hotel');
            let recordDateChange = _changedProperties.get('recordDate');
            let pickupSettingChange = _changedProperties.get('pickupSetting');
            if (hotelChange || recordDateChange || pickupSettingChange) {
                this.fill();
            }
        }

    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
        <div class="vao__components--totalPickupHotelTrend">
            ${this.renderBtn()}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TotalPickupHotelTrend = TotalPickupHotelTrend;
customElements.define('vao-total-pickup-hotel-trend', TotalPickupHotelTrend);
