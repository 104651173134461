import { AppElement, html } from '../AppElement.js';

let requestCount = 0;

export default class ChannelHotelSelectionCheckerAlert extends AppElement {
    static get properties() {
        return {
            // Props.
            selectedChannelId: { type: String },
            hotelId: { type: String },

            // State.
            alertText: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId || null;
        this.hotelId = props.hotelId || this.hotelId || null;

        this.alertText = '';
    }

    reflow(props = {}) {
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId ;
        this.hotelId = props.hotelId || this.hotelId ;
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('selectedChannelId') || _changedProperties.has('hotelId')) {
            this.check();
        }
    }

    check() {
        if (!this.hotelId || !this.selectedChannelId) {
            this.alertText = '';
            return;
        }
        this.alertText = 'Checking selection...';
        requestCount++;
        const initialRequestCount = requestCount;
        window.infinito.vao.model.biHotelChannel.patch(
            this.hotelId,
            'determine_suggested_sub_channel',
            {
                channelsId: this.selectedChannelId
            },
            (errorMsg, response) => {
                if (
                    initialRequestCount !== requestCount
                    || this.alertText === ''
                ) {
                    // Another request has been initiated whilst this one was in-flight.
                    return;
                }
                if (errorMsg) {
                    this.alertText = '';
                    console.error(errorMsg);
                    return;
                }
                const _response = response || {};
                const responseData = _response.data || {};
                const responseDataPatchData = responseData.patchData || {};
                const suggestion = responseDataPatchData.suggestion;
                const hasAnyForOrSubForChannelData = responseDataPatchData.hasAnyForOrSubForChannelData;
                if (hasAnyForOrSubForChannelData !== true) {
                    this.alertText = 'No data found for main channel or any sub-channel';
                } else if (suggestion && typeof suggestion === 'object') {
                    const distinctions = suggestion.name.split('|', 2)[1];
                    this.alertText = 'Please select a sub-channel such as ' + distinctions;
                } else {
                    this.alertText = '';
                }
            }
        );
    }

    render() {
        return html`
            <div class="vao__components--channelHotelSelectionCheckerAlert">
                ${(typeof this.alertText === 'string' && this.alertText.length > 0)
                    ? html`
<div class="alert alert-warning" role="alert" style="font-size:85%;padding:.5rem .8rem;">${this.alertText}</div>`
                    : html``
                }
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ChannelHotelSelectionCheckerAlert = ChannelHotelSelectionCheckerAlert;
customElements.define('vao-channel-hotel-selection-checker-alert', ChannelHotelSelectionCheckerAlert);
