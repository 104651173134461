import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {getRecordMatcherForCategory, isTZTimestampsBalanced} from "../CtxUtils";
import {findCtxStatInProcDataByFieldByStayDate} from "../StatisticUtils";
import ContextBalanceDescBlock from "./ContextBalanceDescBlock";

const descBlockPropsCacheKey = 'descBlockProps';

export default class ContextBalance extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            btnText: { type: String },
            btnColor: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel;
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
        this.btnText = props.btnText;
        this.btnColor = props.btnColor;

        this.dateHelper = window.infinito.vao.controller.dateHelper;
        this.biStatistics = window.infinito.vao.model.biStatistics;
        this.pickupHelper = window.infinito.vao.controller.pickupHelper;
        this.cache = new Map();
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.btnText = props.btnText || this.btnText;
        this.btnColor = props.btnColor || this.btnColor;
    }

    getCategory() {
        throw new Error(Constants.STRINGS.ABSTRACT_FUNC_ERR_MSG);
    }

    canFill() {
        return (
            this.hotel
            && typeof this.hotel === 'object'
            && ('id' in this.hotel)
            && this.dateHelper.isDateStr(this.recordDate)
            && $.isNumeric(this.pickupSetting)
            && getRecordMatcherForCategory(this.getCategory())
        );
    }

    updateStateForReceivedTimestamps(primaryRecTs, primaryOffsetRecTs, ctxRecTs, ctxOffsetRecTs) {
        if (
            !primaryRecTs
            && !primaryOffsetRecTs
            && !ctxRecTs
            && !ctxOffsetRecTs
        ) {
            this.btnText = Constants.STRINGS.NO_DATA.toUpperCase();
            this.btnColor = Constants.COLORS.STRINGS.DANGER;
            return;
        }
        const isBalancedRecTs = isTZTimestampsBalanced(primaryRecTs, ctxRecTs);
        const isBalancedOffsetRecTs = isTZTimestampsBalanced(primaryOffsetRecTs, ctxOffsetRecTs);
        let isBalanced = false;
        if (
            (isBalancedRecTs && isBalancedOffsetRecTs)
            || (isBalancedRecTs && !primaryOffsetRecTs && !ctxOffsetRecTs)
            || (isBalancedOffsetRecTs && !primaryRecTs && !ctxRecTs)
        ) {
            isBalanced = true;
        }
        if (isBalanced) {
            this.btnText = Constants.STRINGS.BALANCED.toUpperCase();
            this.btnColor = Constants.COLORS.STRINGS.SUCCESS;
        } else {
            this.btnText = Constants.STRINGS.IMBALANCED.toUpperCase();
            this.btnColor = Constants.COLORS.STRINGS.WARN;
        }
    }

    fill() {
        if (!this.canFill()) {
            return;
        }
        this.cache.set(descBlockPropsCacheKey, null);
        const stayDate = this.recordDate;
        this.btnText = Constants.STRINGS.LOADING_ELLIPSIS.toUpperCase();
        this.biStatistics.fetchStatistics(
            this.hotel.id,
            this.biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: stayDate,
                lastStayDate: stayDate,
                fields: [
                    this.biStatistics.fields.roomsOtb,
                    this.biStatistics.fields.roomsOtbPickup
                ],
                pickupOffset: this.pickupSetting,
            }),
            (data, primaryProcData) => {
                const _primaryProcData = (primaryProcData || {});
                const roomsOtb = ((_primaryProcData.roomsOtb || {})[stayDate] || {});
                const roomsOtbPickup = ((_primaryProcData.roomsOtbPickup || {})[stayDate] || {});

                this.biStatistics.fetchStatistics(
                    this.hotel.id,
                    this.biStatistics.buildQuery({
                        recordDate: this.recordDate,
                        firstStayDate: stayDate,
                        lastStayDate: stayDate,
                        fields: [
                            this.biStatistics.fields.roomsOtbCtx,
                            this.biStatistics.fields.roomsOtbCtxPickup
                        ],
                        pickupOffset: this.pickupSetting,
                        recordMatcher: getRecordMatcherForCategory(this.getCategory())
                    }),
                    (data, ctxProcData) => {
                        const _ctxProcData = (ctxProcData || {});
                        const roomsOtbCtx = findCtxStatInProcDataByFieldByStayDate(
                            _ctxProcData,
                            this.biStatistics.fields.roomsOtbCtx,
                            stayDate
                        );
                        const roomsOtbCtxPickup = findCtxStatInProcDataByFieldByStayDate(
                            _ctxProcData,
                            this.biStatistics.fields.roomsOtbCtxPickup,
                            stayDate
                        );

                        const primaryRecTs = roomsOtb.receivedTs;
                        const primaryOffsetRecTs = roomsOtbPickup.offsetReceivedTs;
                        const ctxRecTs = roomsOtbCtx.receivedTs;
                        const ctxOffsetRecTs = roomsOtbCtxPickup.offsetReceivedTs;

                        this.updateStateForReceivedTimestamps(
                            primaryRecTs,
                            primaryOffsetRecTs,
                            ctxRecTs,
                            ctxOffsetRecTs
                        );

                        this.cache.set(descBlockPropsCacheKey, {
                            tz: this.hotel.timezone,
                            category: this.getCategory(),
                            primaryRecTs,
                            primaryOffsetRecTs,
                            ctxRecTs,
                            ctxOffsetRecTs,
                            balanceTxt: this.btnText
                        });
                    }
                );
            }
        )
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('hotel')
            || _changedProperties.has('recordDate')
            || _changedProperties.has('pickupSetting')
        ) {
            this.fill();
        }
    }

    _onClick() {
        const data = this.cache.get(descBlockPropsCacheKey);
        if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
            console.warn('Balance button clicked without data to load modal');
            return;
        }
        const prettyPickupSetting = this.pickupHelper.getPickupOffsetTextForPickupOffset(this.pickupSetting);
        const body = new ContextBalanceDescBlock(data);
        const prettyRecordDate = this.dateHelper.getShortDayDateMonFullYearString(this.recordDate);
        let $title =  $('<div></div>').append(
            `<h5>${Constants.STRINGS.CONTEXT_BALANCE_MODAL_TITLE}</h5>`
        ).append(new vao.components.MiniContext({
            recordDate: prettyRecordDate,
            pickupSetting: prettyPickupSetting,
            isModalStyle: false
        }));
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body)
        });
    }

    getRenderableBtnText() {
        return (this.btnText || Constants.MISSING_STR).toUpperCase();
    }

    getRenderableBtnColor() {
        if (Object.values(Constants.COLORS.STRINGS).includes(this.btnColor)) {
            return this.btnColor;
        }
        return Constants.COLORS.STRINGS.LIGHT;
    }

    render() {
        return html`
<div class="vao__components--contextBalance">
    <vao-button
        @click="${this._onClick}"
        .text="${this.getRenderableBtnText()}"
        size="small"
        tooltip="Determines if contextualized data values should be balanced with the rest of the application"
        variant="contained"
        .color="${this.getRenderableBtnColor()}">
    </vao-button> 
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ContextBalance = ContextBalance;
customElements.define('vao-context-balance', ContextBalance);
