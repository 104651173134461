import { html } from '../AppElement.js';
import StoryInternalTotalPickup from "./StoryInternalTotalPickup";
import Constants from "../Constants";

export default class StoryInternalHotelTotalPickup extends StoryInternalTotalPickup {
    constructor(props = {}) {
        super(props);

        this.hotel = props.hotel;
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        if ('pickupSetting' in props) {
            this.pickupSetting = props.pickupSetting;
        }
        this.fill();
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            return;
        }

        const biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                fields: [
                    biStatistics.fields.roomsOtbTotalPickup,
                    biStatistics.fields.adrTotalPickup,
                    biStatistics.fields.revenueTotalPickup,
                    biStatistics.fields.revenueTotalPickupTrend,
                    biStatistics.fields.roomsOtbTotalPickupTrend,
                    biStatistics.fields.adrTotalPickupTrend,
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                const _procData = procData || {};
                const roomsOtbTotalPickup = (_procData.roomsOtbTotalPickup || {}).value;
                const adrTotalPickup = (_procData.adrTotalPickup || {}).value;
                const revenueTotalPickup = (_procData.revenueTotalPickup || {}).value;
                const revenueTotalPickupTrend = (_procData.revenueTotalPickupTrend || {}).value;
                const roomsOtbTotalPickupTrend = (_procData.roomsOtbTotalPickupTrend || {}).value;
                const adrTotalPickupTrend = (_procData.adrTotalPickupTrend || {}).value;

                this.pickupDesc = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
                    this.pickupSetting
                );

                if ($.isNumeric(roomsOtbTotalPickup)) {
                    this.otbPickup = roomsOtbTotalPickup;
                } else {
                    this.otbPickup = Constants.MISSING_STR;
                }

                if ($.isNumeric(revenueTotalPickup)) {
                    this.revenuePickup = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueTotalPickup,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    this.revenuePickup = Constants.MISSING_STR;
                }

                if ($.isNumeric(adrTotalPickup)) {
                    this.pickupsAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adrTotalPickup,
                        Constants.ADR_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    this.pickupsAdr = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(revenueTotalPickupTrend)
                    && $.isNumeric(revenueTotalPickup)
                ) {
                    let assessment = window.infinito.vao.controller.pickupHelper.determinePickupAssessment(
                        revenueTotalPickup,
                        revenueTotalPickupTrend
                    );
                    if (assessment === 'above') {
                        this.pickupAB = 'above';
                    } else if (assessment === 'below') {
                        this.pickupAB = 'below';
                    } else if (assessment === 'inline') {
                        this.pickupAB = 'inline with';
                    } else {
                        this.pickupAB = Constants.MISSING_STR;
                    }
                } else {
                    this.pickupAB = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(revenueTotalPickupTrend)
                ) {
                    this.revenuePickupTrend = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueTotalPickupTrend,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    this.revenuePickupTrend = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(roomsOtbTotalPickupTrend)
                ) {
                    this.roomsPickupTrend = roomsOtbTotalPickupTrend;
                } else {
                    this.roomsPickupTrend = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(adrTotalPickupTrend)
                ) {
                    this.pickupsAdrTrend = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adrTotalPickupTrend,
                        Constants.ADR_DIGITS,
                        true,
                        this.hotel.locale
                    );
                } else {
                    this.pickupsAdrTrend = Constants.MISSING_STR;
                }
            }
        )
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyInternalHotelTotalPickup">
    ${super.render()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalHotelTotalPickup = StoryInternalHotelTotalPickup;
customElements.define('vao-story-internal-hotel-total-pickup', StoryInternalHotelTotalPickup);
