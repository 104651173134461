import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';

export default class AddAssociateProperty extends AppElement {
    static get properties() {
        return {
            hotel_id: {type: Object},
            templatedata : []
        };
    }

    constructor(props = {}) {
        super();
        this.hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();
        this.templatedata = [];

    }
    

    render(){

        /**** Listen for click on toggle checkbox ****/
        $("#select-all").on('click', function(event){
            console.log('inside select all');
            if(this.checked) {
                // Iterate each checkbox
                $(':checkbox').each(function() {
                    this.checked = true;
                });
            } else {
                $(':checkbox').each(function() {
                    this.checked = false;
                });
            }
        });

        $('#table_popupassociateProperty').DataTable({
            "ordering": false
        });

        return html`
            <div style="width: auto;">

                <!-------------------------- Associate Property Form Starts --------------------------->

                <div class="row mb-2">
                    <div class="col-sm-6 mb-2"> Template &nbsp;
                        <select type="text" class="form-control">
                            <option value="Draft">Draft</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                    </div>
                    <div class="col-sm-6">Rule Text &nbsp;
                        <textarea id="" name="" rows="3" cols="50" class="form-control"></textarea>
                    </div>
                    <!--
                    <div class="col-sm-6 mb-2">Email Subject &nbsp;
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="col-sm-6">Browser Heading &nbsp;
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="col-sm-6 mb-2">Email Text &nbsp;
                        <textarea id="" name="" rows="2" cols="50" class="form-control"></textarea>
                    </div>
                    <div class="col-sm-6">Browser Text &nbsp;
                        <textarea id="" name="" rows="2" cols="50" class="form-control"></textarea>
                    </div>
                    -->
                    <div class="col-sm-6">Process Days &nbsp;
                        <input type="text" class="form-control"/>
                    </div>
                </div>

                <div class="table-responsive">
                    <table id="table_popupassociateProperty" class="table" style="width:100%; text-align: center">
                        <thead>
                        <tr style="background: #edf1f5">
                            <th><input type="checkbox" name="select-all" id="select-all" /> Select</th>
                            <th>Property Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><input type="checkbox" name="checkbox-1" id="checkbox-1" /></td>
                            <td>Skye Suites Parramatta</td>
                        </tr>
                        <tr>
                            <td><input type="checkbox" name="checkbox-2" id="checkbox-2" /></td>
                            <td>Skye Suites Sydney</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="row" style="display: block; margin: auto; text-align: center;">
                    <button type="button" class="btn-primary btn-class">Save</button>
                    <button type="button" class="btn-primary btn-class">Cancel</button>
                </div>

                <!-------------------------- Associate Property Form Ends --------------------------->
                
            </div>`;
    }

}


window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.AddAssociateProperty = AddAssociateProperty;
customElements.define('vao-add-associateproperty', AddAssociateProperty);