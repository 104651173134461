import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

const title = 'Define Demand';
const hotelNamePrefix = 'For';
const text = 'How would you classify demand and occupancy levels for this hotel?';
const high = 'High';
const medium = 'Medium';
const low = 'Low';
const pipFormats = {
    0: '0% - Dead',
    100: '100% - Packed',
};

export default class PersonalDemand extends AppElement {
    static get properties() {
        return {
            // @internalProperty
            isLoading: { attribute: false },
        };
    }

    constructor(props = {}) {
        super();
    }

    reflow(props = {}) {}

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('resize', this.onSliderUpdate.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('resize', this.onSliderUpdate.bind(this));
    }

    getHotelName() {
        return window.infinito.vao.controller.storageHelper.getSelectedHotelName() || Constants.MISSING_STR;
    }

    getSliderElement() {
        return this.renderRoot.querySelector('.vao__components--personalDemand-slider');
    }

    onSliderUpdate() {
        const el = this.getSliderElement();
        if (!el) {
            return;
        }
        const handles = el.querySelectorAll('.noUi-handle');
        const length = handles.length;
        // Update the floating handle text to match the slider values.
        for (let i = 0; i < length; i++) {
            let selector = null;
            if (i === 0) {
                selector = 'vao__components--personalDemand-valueLow';
            } else if (i === 1) {
                selector = 'vao__components--personalDemand-valueMed';
            } else if (i === 2) {
                selector = 'vao__components--personalDemand-valueHigh';
            }
            if (!selector) {
                continue;
            }
            const textEl = this.renderRoot.querySelector(`.${selector}`);
            const textOriginEl = handles[i].closest('.noUi-origin');
            if (!textEl || !textOriginEl) {
                continue;
            }
            const textOriginParentEl = textOriginEl.offsetParent;
            if (!textOriginParentEl) {
                continue;
            }
            const parentRect = textOriginParentEl.getBoundingClientRect();
            const handleRect = textOriginEl.getBoundingClientRect();
            if (!parentRect || !handleRect) {
                continue;
            }
            const offsetTop = handleRect.y - parentRect.y;
            if (offsetTop <= 0) {
                continue;
            }
            textEl.style.top = (parseInt(offsetTop, 10) + 5) + 'px';
        }
    }

    onCLickSave() {
        const el = this.getSliderElement();
        if (!el) {
            return;
        }
        let values = [];
        el.querySelectorAll('.noUi-origin').forEach(elOrigin => {
            const value = elOrigin.querySelector('.noUi-handle').getAttribute('aria-valuenow');
            if ($.isNumeric(value)) {
                values.push(value);
            }
        });
        if (values.length !== 3) {
            return;
        }
        const sortedValues = window.infinito.vao.controller.sortNumberArray(values);
        let completeCount = 0;
        const expectedCompletions = 3;
        const callback = () => {
            completeCount++;
            if (completeCount >= expectedCompletions) {
                this.isLoading = false;
            }
        }
        this.isLoading = true;
        window.infinito.vao.controller.demandHelper.setLowDem(sortedValues[0], callback);
        window.infinito.vao.controller.demandHelper.setMedDem(sortedValues[1], callback);
        window.infinito.vao.controller.demandHelper.setHighDem(sortedValues[2], callback);
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        this.isLoading = true;
        const demandHelper = window.infinito.vao.controller.demandHelper;
        $.when(
            demandHelper.fetchLowDem(),
            demandHelper.fetchMedDem(),
            demandHelper.fetchHighDem()
        ).then(
            (lowDem, medDem, highDem) => {
                this.isLoading = false;
                const el = this.getSliderElement();
                window.noUiSlider.create(el, {
                    start: [lowDem, medDem, highDem],
                    orientation: 'vertical',
                    direction: 'rtl',
                    tooltips: [true, true, true],
                    padding: [5, 5],
                    margin: 5,
                    range: {
                        min: 0,
                        max: 100
                    },
                    pips: {
                        mode: 'steps',
                        stepped: true,
                        density: 10,
                        format: {
                            to: function format(a) {
                                return pipFormats[a];
                            }
                        }
                    },
                    format: {
                        from: function from(value) {
                            return parseInt(value, 10);
                        },
                        to: function to(value) {
                            return parseInt(value, 10) + '%';
                        }
                    }
                });
                el.noUiSlider.on('update', this.onSliderUpdate.bind(this));
            }
        );
    }

    render() {
        const highColor = window.infinito.vao.controller.demandHelper.getState().highDemColor;
        const medColor = window.infinito.vao.controller.demandHelper.getState().medDemColor;
        const lowColor = window.infinito.vao.controller.demandHelper.getState().lowDemColor;
        return html`
<div class="vao__components--personalDemand">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">${title}</h5>
            <h6 class="card-subtitle mb-2 text-muted">${hotelNamePrefix} ${this.getHotelName()}</h6>
            <p class="card-text">${text}</p>
            <div class="vao__components--personalDemand-sliderBody" style="position:relative;padding: 1rem 2.7rem;">
                <div class="vao__components--personalDemand-sliderBlock">
                    <div class="vao__components--personalDemand-slider" style="height:300px;"></div>
                </div>
                <div class="vao__components--personalDemand-valuesBlock" style="position:absolute;top:0;">
                    <div class="vao__components--personalDemand-values">
                        <span 
                            class="vao__components--personalDemand-valueHigh badge" 
                            style="position:absolute;left:4rem;background:${highColor};"
                        >
                            ${high}
                        </span>
                        <span 
                            class="vao__components--personalDemand-valueMed badge" 
                            style="position:absolute;left:4rem;background:${medColor};"
                        >
                            ${medium}
                        </span>
                        <span 
                            class="vao__components--personalDemand-valueLow badge"
                            style="position:absolute;left:4rem;background:${lowColor};"
                        >
                            ${low}
                        </span>
                    </div>
                </div>
            </div>
            <br>
            ${this.isLoading
                ? html`<vao-spinner></vao-spinner>`
                : html`<vao-button text="Save" @click="${this.onCLickSave}"></vao-button>`
            }
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PersonalDemand = PersonalDemand;
customElements.define('vao-personal-demand', PersonalDemand);
