import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import Popover, {popPlacements, popTriggers} from "../popover/Popover";
import CategoryOptionBlock from "./CategoryOptionBlock";
import {getDisplayableTextForCategory} from "../CtxUtils";

export default class CategorySelectChip extends AppElement {
    static get properties() {
        return {
            currentCategory: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.currentCategory = props.currentCategory;
        this.onChange = props.onChange;
        this.id = AppElement.getUniqueElementId();
    }

    _onChange(newCategory) {
        if (newCategory === this.currentCategory) {
            return;
        }
        this.currentCategory = newCategory;
        if (typeof this.onChange === 'function') {
            this.onChange(this.currentCategory);
        }
    }

    getDisplayableTextForCategory(category) {
        return getDisplayableTextForCategory(category);
    }

    renderPopover() {
        let $popWrapper = $('#' + this.id).find('.vao__components--categorySelectChip');
        let $anchor = $popWrapper.find('vao-chip');
        if ($anchor) {
            let pop;
            let content = new CategoryOptionBlock({
                currentCategory: this.currentCategory,
                onChange: (newCategory) => {
                    this._onChange(newCategory);
                }
            });
            $popWrapper.find('vao-popover').remove();
            pop = new Popover({
                anchor: $anchor,
                content: content,
                trigger: popTriggers.focusCustom,
                placement: popPlacements.bottom
            });
            $popWrapper.append(pop);
        }
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.renderPopover();
    }

    render() {
        return html`
<div class="vao__components--categorySelectChip">
    <vao-chip 
        .label="${this.getDisplayableTextForCategory(this.currentCategory)}"
        endIconCls="${Constants.ICONS.ANGLE_DOWN}"
        clickable="true">
    </vao-chip> 
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.CategorySelectChip = CategorySelectChip;
customElements.define('vao-category-select-chip', CategorySelectChip);
