import { AppElement, html } from '../AppElement.js';
import TotalPickupHotelTrendDetailsRooms from "./TotalPickupHotelTrendDetailsRooms";
import TotalPickupHotelTrendDetailsRevenue from "./TotalPickupHotelTrendDetailsRevenue";
import Constants from "../Constants";
import StoryInternalHotelTotalPickup from "../storyInternalPickup/StoryInternalHotelTotalPickup";

export default class TotalPickupHotelTrendDetails extends AppElement {
    static get properties() {
        return {};
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();

        this.hotel = props.hotel;
        this.pickupSetting = props.pickupSetting;
        this.recordDate = props.recordDate;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = typeof props.pickupSetting === 'undefined' ? this.pickupSetting : props.pickupSetting;
    }

    fill() {
        const $storyBlock = $(
            new StoryInternalHotelTotalPickup({
                hotel: this.hotel,
                recordDate: this.recordDate,
                pickupSetting: this.pickupSetting
            })
        );

        const $roomsBlock = $(
            new TotalPickupHotelTrendDetailsRooms({
                hotel: this.hotel,
                recordDate: this.recordDate,
                pickupSetting: this.pickupSetting
            })
        );

        const $revenueBlock = $(
            new TotalPickupHotelTrendDetailsRevenue({
                hotel: this.hotel,
                recordDate: this.recordDate,
                pickupSetting: this.pickupSetting
            })
        );

        const $listItems = $(window.infinito.components.threadItems.render({
            threadItemsProps: [
                {
                    title: Constants.STRINGS.STORY,
                    block: $storyBlock,
                    isBlockHidden: false
                },
                {
                    title: Constants.STRINGS.ROOMS,
                    block: $roomsBlock,
                    isBlockHidden: true
                },
                {
                    title: Constants.STRINGS.REV,
                    block: $revenueBlock,
                    isBlockHidden: true
                },
            ]
        }));

        $('#' + this.id).find('.vao__components--totalPickupHotelTrendDetails-listItems').empty().append($listItems);
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
        <div class="vao__components--totalPickupHotelTrendDetails">
            <div class="vao__components--totalPickupHotelTrendDetails-listItems"></div>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TotalPickupHotelTrendDetails = TotalPickupHotelTrendDetails;
customElements.define('vao-total-pickup-hotel-trend-details', TotalPickupHotelTrendDetails);