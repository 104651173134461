import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StorySubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    render() {
        return html`
<div class="vao__components--storySubtitle">
    ${(!this.subtitle || this.subtitle === Constants.MISSING_STR)
            ? html``
            : html`<span class="badge ${this.badgeClass}">${this.subtitle}</span>`
        }
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StorySubtitle = StorySubtitle;
customElements.define('vao-story-subtitle', StorySubtitle);
