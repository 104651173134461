import { AppElement, html } from '../AppElement.js';
import TopPickup from './TopPickup';
import TopPickupCard from './TopPickupCard';
import ColorArray from '../ColorArray';
import { sendRequest } from '../App.common';

const NAME_ROOMS = 'Rooms';
const NAME_ADR = 'ADR';
const NAME_REVENUE = 'Revenue';
const x = 1;
const NUM_TOPS = 3;

export default class TopPickupHotelCardList extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            topPickupCards: { type: Array }
        };
    }

    constructor(props = {}) {
        super();

        this.hotel = props.hotel;
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
        this.topPickupCards = props.topPickupCards;

        this.colorArray = (new ColorArray());

        if (!Array.isArray(this.topPickupCards)) {
            this.topPickupCards = [];
            this.obtainRoomsCard();
            this.obtainAdrCard();
            this.obtainRevenueCard();
        }
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = props.pickupSetting || this.pickupSetting;
        this.topPickupCards = props.topPickupCards || this.topPickupCards;

        if (!Array.isArray(this.topPickupCards)) {
            this.topPickupCards = [];
            this.obtainRoomsCard();
            this.obtainAdrCard();
            this.obtainRevenueCard();
        }

        this.fill();
    }

    fill() {
        // if (
        //     !this.hotel
        //     || typeof this.hotel !== 'object'
        //     || !('id' in this.hotel)
        //     || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        //     || !$.isNumeric(this.pickupSetting)
        // ) {
        //     return;
        // }


        let dataObj = {
            hotel_id: this.hotel.id,
            recordDate: this.recordDate, // '2021-06-20',
            pickupOffset: this.pickupSetting,
            op: 'getFlashGroupHotelCardExpand',
            serviceName: 'flashgroupapi.php'
        };
        let flag = this;

        sendRequest(dataObj, function (res) {
            let obj = JSON.parse(res);
            const _procData = obj.data || {};
            let roomsOtbTopPickups = (_procData.roomsOtbTopPickups || {}).value;
            let revenueTopPickups = (_procData.revenueTopPickups || {}).value;
            let pickupsAdrTopPickups = (_procData.pickupsAdrTopPickups || {}).value;
            const roomsOtbPickups = (_procData.roomsOtbPickup || {});
            const revenuePickups = (_procData.revenuePickup || {});
            const pickupsAdrs = (_procData.pickupsAdr || {});
            let i; let
                card;
            let _otbTopPickupObjs = [];
            let _adrTopPickupsObjs = [];
            let _revTopPickupsObjs = [];

            if (roomsOtbTopPickups && typeof roomsOtbTopPickups === 'string' && roomsOtbTopPickups !== 'no data') {
                roomsOtbTopPickups = roomsOtbTopPickups.split(',');
            } else {
                roomsOtbTopPickups = [];
            }

            if (revenueTopPickups && typeof revenueTopPickups === 'string' && revenueTopPickups !== 'no data') {
                revenueTopPickups = revenueTopPickups.split(',');
            } else {
                revenueTopPickups = [];
            }

            if (pickupsAdrTopPickups && typeof pickupsAdrTopPickups === 'string' && pickupsAdrTopPickups !== 'no data') {
                pickupsAdrTopPickups = pickupsAdrTopPickups.split(',');
            } else {
                pickupsAdrTopPickups = [];
            }

            for (i = 0; i < NUM_TOPS; i++) {
                let topPickupDate = roomsOtbTopPickups[i];
                if (window.infinito.vao.controller.dateHelper.isDateStr(topPickupDate)) {
                    let matchOtbData = (roomsOtbPickups[topPickupDate] || {}).value;
                    let matchAdrData = (pickupsAdrs[topPickupDate]).value;
                    let matchRevData = (revenuePickups[topPickupDate]).value;
                    let topPU = new TopPickup(
                        topPickupDate,
                        matchOtbData,
                        matchRevData,
                        matchAdrData,
                        flag.hotel.locale
                    );
                    _otbTopPickupObjs.push(topPU);
                }
            }
            card = flag.obtainRoomsCard();
            if (card instanceof TopPickupCard) {
                card.reflow({
                    topPickups: _otbTopPickupObjs
                });
            }

            for (i = 0; i < NUM_TOPS; i++) {
                let topPickupDate = revenueTopPickups[i];
                if (window.infinito.vao.controller.dateHelper.isDateStr(topPickupDate)) {
                    let matchOtbData = (roomsOtbPickups[topPickupDate] || {}).value;
                    let matchAdrData = (pickupsAdrs[topPickupDate]).value;
                    let matchRevData = (revenuePickups[topPickupDate]).value;
                    let topPU = new TopPickup(
                        topPickupDate,
                        matchOtbData,
                        matchRevData,
                        matchAdrData,
                        flag.hotel.locale
                    );
                    _revTopPickupsObjs.push(topPU);
                }
            }
            card = flag.obtainRevenueCard();
            if (card instanceof TopPickupCard) {
                card.reflow({
                    topPickups: _revTopPickupsObjs
                });
            }

            for (i = 0; i < NUM_TOPS; i++) {
                let topPickupDate = pickupsAdrTopPickups[i];
                if (window.infinito.vao.controller.dateHelper.isDateStr(topPickupDate)) {
                    let matchOtbData = (roomsOtbPickups[topPickupDate] || {}).value;
                    let matchAdrData = (pickupsAdrs[topPickupDate]).value;
                    let matchRevData = (revenuePickups[topPickupDate]).value;
                    let topPU = new TopPickup(
                        topPickupDate,
                        matchOtbData,
                        matchRevData,
                        matchAdrData,
                        flag.hotel.locale
                    );
                    _adrTopPickupsObjs.push(topPU);
                }
            }
            card = flag.obtainAdrCard();
            if (card instanceof TopPickupCard) {
                card.reflow({
                    topPickups: _adrTopPickupsObjs
                });
            }
        });


        // let biStatistics = window.infinito.vao.model.biStatistics;
        // biStatistics.fetchStatistics(
        //     this.hotel.id,
        //     biStatistics.buildQuery({
        //         recordDate: '2021-06-20',
        //         fields: [
        //             biStatistics.fields.roomsOtbTopPickups,
        //             biStatistics.fields.revenueTopPickups,
        //             biStatistics.fields.pickupsAdrTopPickups
        //         ],
        //         pickupOffset: this.pickupSetting
        //     }),
        //     (data, procData) => {
        //         const _procData = procData || {};
        //         let roomsOtbTopPickups = (_procData.roomsOtbTopPickups || {}).value;
        //         let revenueTopPickups = (_procData.revenueTopPickups || {}).value;
        //         let pickupsAdrTopPickups = (_procData.pickupsAdrTopPickups || {}).value;
        //         const roomsOtbPickups = (_procData.roomsOtbPickup || {});
        //         const revenuePickups = (_procData.revenuePickup || {});
        //         const pickupsAdrs = (_procData.pickupsAdr || {});
        //         let i, card;
        //         let _otbTopPickupObjs = [];
        //         let _adrTopPickupsObjs = [];
        //         let _revTopPickupsObjs = [];
        //
        //         if (roomsOtbTopPickups && typeof roomsOtbTopPickups === 'string' && roomsOtbTopPickups !== 'no data') {
        //             roomsOtbTopPickups = roomsOtbTopPickups.split(',');
        //         } else {
        //             roomsOtbTopPickups = [];
        //         }
        //
        //         if (revenueTopPickups && typeof revenueTopPickups === 'string' && revenueTopPickups !== 'no data') {
        //             revenueTopPickups = revenueTopPickups.split(',');
        //         } else {
        //             revenueTopPickups = [];
        //         }
        //
        //         if (pickupsAdrTopPickups && typeof pickupsAdrTopPickups === 'string' && pickupsAdrTopPickups !== 'no data') {
        //             pickupsAdrTopPickups = pickupsAdrTopPickups.split(',');
        //         } else {
        //             pickupsAdrTopPickups = [];
        //         }
        //
        //         for (i = 0; i < NUM_TOPS; i++) {
        //             let topPickupDate = roomsOtbTopPickups[i];
        //             if (window.infinito.vao.controller.dateHelper.isDateStr(topPickupDate)) {
        //                 let matchOtbData = (roomsOtbPickups[topPickupDate] || {}).value;
        //                 let matchAdrData = (pickupsAdrs[topPickupDate]).value;
        //                 let matchRevData = (revenuePickups[topPickupDate]).value;
        //                 let topPU = new TopPickup(
        //                     topPickupDate,
        //                     matchOtbData,
        //                     matchRevData,
        //                     matchAdrData,
        //                     this.hotel.locale
        //                 );
        //                 _otbTopPickupObjs.push(topPU);
        //             }
        //         }
        //         card = this.obtainRoomsCard();
        //         if (card instanceof TopPickupCard) {
        //             card.reflow({
        //                 topPickups: _otbTopPickupObjs
        //             });
        //         }
        //
        //         for (i = 0; i < NUM_TOPS; i++) {
        //             let topPickupDate = revenueTopPickups[i];
        //             if (window.infinito.vao.controller.dateHelper.isDateStr(topPickupDate)) {
        //                 let matchOtbData = (roomsOtbPickups[topPickupDate] || {}).value;
        //                 let matchAdrData = (pickupsAdrs[topPickupDate]).value;
        //                 let matchRevData = (revenuePickups[topPickupDate]).value;
        //                 let topPU = new TopPickup(
        //                     topPickupDate,
        //                     matchOtbData,
        //                     matchRevData,
        //                     matchAdrData,
        //                     this.hotel.locale
        //                 );
        //                 _revTopPickupsObjs.push(topPU);
        //             }
        //         }
        //         card = this.obtainRevenueCard();
        //         if (card instanceof TopPickupCard) {
        //             card.reflow({
        //                 topPickups: _revTopPickupsObjs
        //             });
        //         }
        //
        //         for (i = 0; i < NUM_TOPS; i++) {
        //             let topPickupDate = pickupsAdrTopPickups[i];
        //             if (window.infinito.vao.controller.dateHelper.isDateStr(topPickupDate)) {
        //                 let matchOtbData = (roomsOtbPickups[topPickupDate] || {}).value;
        //                 let matchAdrData = (pickupsAdrs[topPickupDate]).value;
        //                 let matchRevData = (revenuePickups[topPickupDate]).value;
        //                 let topPU = new TopPickup(
        //                     topPickupDate,
        //                     matchOtbData,
        //                     matchRevData,
        //                     matchAdrData,
        //                     this.hotel.locale
        //                 );
        //                 _adrTopPickupsObjs.push(topPU);
        //             }
        //         }
        //         card = this.obtainAdrCard();
        //         if (card instanceof TopPickupCard) {
        //             card.reflow({
        //                 topPickups: _adrTopPickupsObjs
        //             });
        //         }
        //     }
        // )
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        this.colorArray = (new ColorArray());
    }

    obtainCard(name) {
        let card = this.topPickupCards.find(topPickupCard => {
            return topPickupCard instanceof TopPickupCard && topPickupCard.name === name;
        });
        if (!card) {
            card = new TopPickupCard({
                name: name,
                topPickup: [],
                color: this.colorArray.next().color
            });
            this.topPickupCards.push(card);
        }
        return card;
    }

    obtainRoomsCard() {
        return this.obtainCard(NAME_ROOMS);
    }

    obtainAdrCard() {
        return this.obtainCard(NAME_ADR);
    }

    obtainRevenueCard() {
        return this.obtainCard(NAME_REVENUE);
    }

    render() {
        return html`
        <div class="vao__components--topPickupHotelCardList">
            ${this.obtainRoomsCard()}
            <div class="vao__components--topPickupHotelCardList-divider"></div>
            ${this.obtainAdrCard()}
            <div class="vao__components--topPickupHotelCardList-divider"></div>
            ${this.obtainRevenueCard()}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TopPickupHotelCardList = TopPickupHotelCardList;
customElements.define('vao-top-pickup-hotel-card-list', TopPickupHotelCardList);
