import {makeChannelOptionHtml} from "./ChannelOptionHtml";

export function makeBrandChannelOptionHtml() {
    const innerHtml = 'Brand.com';
    const html = makeChannelOptionHtml(innerHtml, [
        'background: white',
        'color: gray',
        'border-color: lightgray',
    ]);
    return html;
}
