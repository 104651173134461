import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';

export default class RoomType extends AppElement {
    static get properties() {
        return {
            hotel_id: { type: Object }
        };
    }

    constructor(props = {}) {
        super();
        this.hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();

    }
    savebtn(e){
        console.log(e);
        var id = $(e).attr("data-id");
        var shortname = $("#shortname_"+id).val();
        var descr = $("#descr_"+id).val();
        var total_rooms = $("#total_rooms_"+id).val();
        let dataObj = {
            "id":id,
            "shortname":shortname,
            "description":descr,
            "total_rooms":total_rooms,
            "op":"updateHotelRoomTypes",
            "serviceName":"roomtypesapi.php"
        };

        sendRequest(dataObj,function(res){

        });
    }
    render() {

        return html`
            <div style="width: auto;overflow-x: scroll;">
                <table id="roomtypetable" style="text-align: center;"
                       class="vao__components--table-table table nowrap table-striped table-md table-align-center table-fs-small table-static-left">
                    <thead>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">Sr
                    </th>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                        Short Name
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">
                        Total Rooms
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">Action
                    </th>
                    </thead>
                    <tbody id="tbody__roomtype">
                    <tr role="row" class=""><!----><!---->
                        <td class="table-cell-date" rowspan="1" colspan="1">
                            1
                        </td><!---->
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-lg width-auto" type="text" name="" min="" max=""
                                   step="1" pattern="">
                        </td><!---->
                        <td class="table-cell-revenue" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" type="number" name="" min="" max=""
                                   step="any" pattern="">
                        </td><!---->
                        <td class="table-cell-action" rowspan="1" colspan="1">
                            <button type="button" data-toggle="tooltip" data-placement="bottom" data-original-title=""
                                    class="vao__components--button btn btn-invert-primary btn-sm start-icon"
                                    style="display: initial;" data-trigger="hover focus" title="Save">
                                <div class="vao__components--button-startIcon">
                                    <vao-icon id="__vao-appelement__112" cls="far fa-save">
                                        <i class="vao__components--icon far fa-save"></i>
                                    </vao-icon>
                                </div>
                            </button>
                        </td>
                    </tr>

                    </tbody>
                </table>
                <p id="data_fetch_msg" style="text-align: center;">Fetching RoomTypes. Please Wait.</p>
            </div>`;

    }
    firstUpdated() {
        //roomtypetable
        var table = document.getElementById("roomtypetable");
        var rowCount = table.rows.length;
        table.deleteRow(1);

        let dataObj = {
            'hotel_id': this.hotel_id,
            'op': 'getHotelRoomTypes',
            'serviceName': "roomtypesapi.php"
        };

        let flag = this;
        sendRequest(dataObj, function (res) {

            let obj = JSON.parse(res);
            var tabledata = obj.data;
            if(tabledata.length > 0){
                $("#data_fetch_msg").html("");
            }else{
                $("#data_fetch_msg").html("");
                $("#data_fetch_msg").html("No Data available");
            }
            var jsonObj = tabledata;
            flag.drawTable(jsonObj);
        });
    }
    drawTable(jsonObj) {
        var count = 1;
        for (var i = 0; i < jsonObj.length; i++) {
            var nttable = document.getElementById("roomtypetable");
            var tb = '<tr role="row" class="">' +
                '<td class="table-cell-date" rowspan="1" colspan="1">' + count + '</td>' +
                '<td class="table-cell-available" rowspan="1" colspan="1">' +
                '<input class="form-control form-control-sm width-auto" type="text" title="' + jsonObj[i].descr + '" readonly name="shortname_' + jsonObj[i].id + '" id="shortname_'+jsonObj[i].id+'" min="" max="" step="1" pattern="" value="' + jsonObj[i].shortname + '">' +
                '</td>' +
                '<td class="table-cell-revenue" rowspan="1" colspan="1">' +
                '<input class="diff_class form-control form-control-sm width-auto" data-id="' + jsonObj[i].id + '" data-initial_val="' + jsonObj[i].total_rooms + '" type="number" name="total_rooms_' + jsonObj[i].id + '" id="total_rooms_' + jsonObj[i].id + '" step="any" value="' + jsonObj[i].total_rooms + '">' +
                '</td>' +
                '<td class="table-cell-action" rowspan="1" colspan="1">' +
                '<button type="button" data-toggle="tooltip" data-placement="bottom" disabled="true" id="savebtn_' + jsonObj[i].id + '" data-original-title="" data-id="' + jsonObj[i].id + '" class="vao__component--btnsave vao__components--button btn btn-invert-primary btn-sm start-icon savebtn" style="display: initial;color:#6b6668;" data-trigger="hover focus" title="Save">' +
                '<div class="vao__components--button-startIcon">' +
                '<vao-icon id="__vao-appelement__112" cls="far fa-save">' +
                '<i class="vao__components--icon far fa-save"></i>' +
                '</vao-icon>' +
                '</div>' +
                '</button>' +
                '<button type="button" data-toggle="tooltip" disabled="true" id="diffbtn_' + jsonObj[i].id + '" data-placement="bottom" data-original-title="" data-id="' + jsonObj[i].id + '" class="vao__component--btndiff vao__components--button btn btn-invert-secondary btn-sm start-icon " style="display: initial;color:#6b6668;" data-trigger="hover focus" title="Diff">' +
                '<div class="vao__components--button-startIcon">' +
                '<vao-icon id="__vao-appelement__112" cls="far fa-file-code">' +
                '<i class="vao__components--icon far fa-file-code"></i>' +
                '</vao-icon>' +
                '</div>' +
                '</button>' +
                '</td>' +
                '</tr>';

            $("#tbody__roomtype").append(tb);
            count++;
        }

        //on saveclick
        $(".vao__component--btnsave").on('click',function(e){

            var id = $(e.target).closest('button').data('id');
            var totalrooms = $('#total_rooms_'+id).val();

            var initial_val = $('#total_rooms_'+id).data("initial_val");
            $('.diff_class').data('initial_val', totalrooms);

            $('#savebtn_'+id).prop('disabled', true);
            $('#savebtn_'+id).css("color", "#6b6668");

            $('#diffbtn_'+id).prop('disabled', true);
            $('#diffbtn_'+id).css("color", "#6b6668");

            $("#total_rooms_"+id).removeClass("cls_modified");

        });
        //Diff Click
        $(".vao__component--btndiff").on('click',function(e){
            var id = $(e.target).closest('button').data('id');

            var totalrooms = $('#total_rooms_'+id).val();
            var initial_val = $('#total_rooms_'+id).data("initial_val");

            let body = '<li class="list-group-item">' +
                '<div style="font-weight:bold;margin-bottom:.3rem;">' +
                '</div>' +
                '<div style="margin-left:1rem;">' +
                '<div>' +
                '<div style="background: #FFEEF0;display: flex;">' +
                '<div style="margin-right:1rem;">-</div>' +
                '<div>'+initial_val+'</div>' +
                '</div>' +
                '<div style="background: #E6FFED;display: flex;">' +
                '<div style="margin-right:1rem;">+</div>' +
                '<div>'+totalrooms+'</div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</li>';


            let $title = `<h5>Total Rooms</h5>`;
            window.infinito.components.basicModal.render({
                title: $title,
                isTitleHtml: true,
                bodyElem: $(body),
                size:'lg'
            });
        });

        // $(".diff_class").change(function(e){
        $(".diff_class").bind('keyup mouseup', function (e) {
            var obj = $(e.target);
            var id = obj.data('id');

            if(obj.val() != obj.data('initial_val')){
                obj.addClass('cls_modified');
                $('#diffbtn_'+id).prop('disabled', false);
                $('#diffbtn_'+id).css("color", "#e91e63");

                $('#savebtn_'+id).prop('disabled', false);
                $('#savebtn_'+id).css("color", "#2196f3");

            } else {
                obj.removeClass('cls_modified');
                $('#diffbtn_'+id).prop('disabled', true);
                $('#diffbtn_'+id).css("color", "#6b6668");

                $('#savebtn_'+id).prop('disabled', false);
                $('#savebtn_'+id).css("color", "#6b6668");
            }
        });

        let flag = this;
        var sal = document.getElementsByClassName("savebtn");
        for (var i = 0; i < sal.length; i++) {
            sal[i].addEventListener("click", function (e) {
                e.preventDefault();
                flag.savebtn(this);
            });
        }

    }

}



window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.RoomType = RoomType;
customElements.define('vao-room-type', RoomType);
