export function makeChannelOptionHtml(innerHtml, icoStyles = []) {
    const wrapStyleStr = [
        'display: flex',
        'min-width: 120px',
        'align-items: center',
        'line-height: 26px',
        'height: 26px'
    ].join(';');
    const icoStyleStr = [
        'border: 1px solid lightgray',
        'font-size: .7rem',
        'padding: 0 5px',
        'line-height: 17px',
        'border-radius: .1rem',
        'background: lightgray',
        'margin-right: .5rem'
    ].concat(icoStyles).join(';');
    var tmpl = '<div style="' + wrapStyleStr + '">'
        + '<div style="' + icoStyleStr + '">'
        + innerHtml
        + '</div>'
        + '</div>';
    return tmpl;
}

export function makeChannelSubOptionHtml(subOptionText, canReplaceISOText = true) {
    let safeSubOptionText = $('<div/>').text(subOptionText).html();
    if (canReplaceISOText) {
        safeSubOptionText = safeSubOptionText.replace('ISO3166 ', '');
    }
    const styles = [
        'margin-right: .5rem',
    ].join(';');
    const tmpl = '<div class="badge badge-light" style="' + styles + '">' + safeSubOptionText + '</div>';
    return tmpl;
}
