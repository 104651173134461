import { AppElement, html } from '../AppElement.js';

export default class PageGroupMonth extends AppElement {
    static get properties() {
        return {
            recordDate: { type: String },
            hotelGroup: { type: Object }
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.hotelGroup = props.hotelGroup || window.infinito.vao.controller.storageHelper.getSelectedGroup();
        this.compClass = "vao__components--pageGroupMonth";
    }

    render() {
        return html`
<div class="${this.compClass}" style="margin:1rem">
    <vao-group-per-property-month-table .recordDate="${this.recordDate}" .hotelGroup="${this.hotelGroup}"></vao-group-per-property-month-table>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageGroupMonth = PageGroupMonth;
customElements.define('vao-page-group-month', PageGroupMonth);
