import { html } from '../AppElement.js';
import Yesterday from './Yesterday';
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import Table from "../table/Table";
import TableNoDataRow from "../table/TableNoDataRow";
import TableRow from "../table/TableRow";
import Constants from "../Constants";
import {formatOccupancy} from "../Utils";
import ColorArray from "../ColorArray";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";

const cellFieldMap = {
    kpi: 'kpi',
    actual: 'actual',
    lastYear: 'lastyear',
    budget: 'budget'
};

const tableRowTitles = {
    occ: 'Occupancy',
    adr: 'ADR',
    rev: 'Revenue',
    revpar: 'RevPAR',
    noshow: 'No Shows',
    inbus: 'Ind Bus',
    grpbus: 'Grp Bus'
};

export default class YesterdayHotel extends Yesterday {

    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },

            roomsOtbPickup: { type: String },
            adrPickup: { type: String },
            revenuePickup: { type: String }
        };
    }

    constructor(props = {}) {
        super(props);
        this.hotel = props.hotel;

        this.roomsOtbPickup = props.roomsOtbPickup || Constants.MISSING_STR;
        this.adrPickup = props.adrPickup || Constants.MISSING_STR;
        this.revenuePickup = props.revenuePickup || Constants.MISSING_STR;

        this.colorArray = (new ColorArray()).colorArray;
    }

    reflow(props = {}) {
        super.reflow(props);
        this.hotel = props.hotel || this.hotel;
        this.roomsOtbPickup = props.roomsOtbPickup || this.roomsOtbPickup;
        this.adrPickup = props.adrPickup || this.adrPickup;
        this.revenuePickup = props.revenuePickup || this.revenuePickup;
        this.fill();
    }

    diff(rawActual, rawLastYear) {
        if (!$.isNumeric(rawActual) || !$.isNumeric(rawLastYear)) {
            return undefined;
        }
        return parseFloat(rawActual) - parseFloat(rawLastYear);
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.yesterday)
        ) {
            return;
        }

        let rows = this.makeTableRows();
        let table = this.reflowTable(rows);
        this.domTheTable(table);

        let biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.yesterday,
                lastStayDate: this.yesterday,
                fields: [
                    biStatistics.fields.occupancy,
                    biStatistics.fields.occupancyLastYear,
                    biStatistics.fields.occupancyBudget,
                    biStatistics.fields.adr,
                    biStatistics.fields.adrLastYear,
                    biStatistics.fields.adrBudget,
                    biStatistics.fields.revenue,
                    biStatistics.fields.revenueLastYear,
                    biStatistics.fields.revenueBudget,
                    biStatistics.fields.revPar,
                    biStatistics.fields.revParLastYear,
                    biStatistics.fields.revParBudget,
                    biStatistics.fields.noShows,
                    biStatistics.fields.noShowsLastYear,
                    biStatistics.fields.noShowsBudget,
                    biStatistics.fields.foodAndBeverage,
                    biStatistics.fields.foodAndBeverageLastYear,
                    biStatistics.fields.foodAndBeverageBudget,
                    biStatistics.fields.individualsBusiness,
                    biStatistics.fields.individualsBusinessLastYear,
                    biStatistics.fields.individualsBusinessBudget,
                    biStatistics.fields.groupBusiness,
                    biStatistics.fields.groupBusinessLastYear,
                    biStatistics.fields.groupBusinessBudget,

                    biStatistics.fields.roomsOtbPickup,
                    biStatistics.fields.adrPickup,
                    biStatistics.fields.revenuePickup,
                ],
                pickupOffset: -1
            }),
            (data, procData) => {
                const _procData = (procData || {});

                const occupancy = ((_procData.occupancy || {})[this.yesterday] || {}).value;
                const occupancyLastYear = ((_procData.occupancyLastYear || {})[this.yesterday] || {}).value;
                const occupancyBudget = ((_procData.occupancyBudget || {})[this.yesterday] || {}).value;

                const adr = ((_procData.adr || {})[this.yesterday] || {}).value;
                const adrLastYear = ((_procData.adrLastYear || {})[this.yesterday] || {}).value;
                const adrBudget = ((_procData.adrBudget || {})[this.yesterday] || {}).value;

                const revenue = ((_procData.revenue || {})[this.yesterday] || {}).value;
                const revenueLastYear = ((_procData.revenueLastYear || {})[this.yesterday] || {}).value;
                const revenueBudget = ((_procData.revenueBudget || {})[this.yesterday] || {}).value;

                const revPar = ((_procData.revPar || {})[this.yesterday] || {}).value;
                const revParLastYear = ((_procData.revParLastYear || {})[this.yesterday] || {}).value;
                const revParBudget = ((_procData.revParBudget || {})[this.yesterday] || {}).value;

                const noShows = ((_procData.noShows || {})[this.yesterday] || {}).value;
                const noShowsLastYear = ((_procData.noShowsLastYear || {})[this.yesterday] || {}).value;
                const noShowsBudget = ((_procData.noShowsBudget || {})[this.yesterday] || {}).value;

                const foodAndBeverage = ((_procData.foodAndBeverage || {})[this.yesterday] || {}).value;
                const foodAndBeverageLastYear = ((_procData.foodAndBeverageLastYear || {})[this.yesterday] || {}).value;
                const foodAndBeverageBudget = ((_procData.foodAndBeverageBudget || {})[this.yesterday] || {}).value;

                const individualsBusiness = ((_procData.individualsBusiness || {})[this.yesterday] || {}).value;
                const individualsBusinessLastYear = ((_procData.individualsBusinessLastYear || {})[this.yesterday] || {}).value;
                const individualsBusinessBudget = ((_procData.individualsBusinessBudget || {})[this.yesterday] || {}).value;

                const groupBusiness = ((_procData.groupBusiness || {})[this.yesterday] || {}).value;
                const groupBusinessLastYear = ((_procData.groupBusinessLastYear || {})[this.yesterday] || {}).value;
                const groupBusinessBudget = ((_procData.groupBusinessBudget || {})[this.yesterday] || {}).value;

                const roomsOtbPickup = ((_procData.roomsOtbPickup || {})[this.yesterday] || {}).value;
                const adrPickup = ((_procData.adrPickup || {})[this.yesterday] || {}).value;
                const revenuePickup = ((_procData.revenuePickup || {})[this.yesterday] || {}).value;

                let cell, row, tmp;
                let foundAnyData = false;

                if (
                    $.isNumeric(occupancy)
                    || $.isNumeric(occupancyLastYear)
                    || $.isNumeric(occupancyBudget)
                    || $.isNumeric(adr)
                    || $.isNumeric(adrLastYear)
                    || $.isNumeric(adrBudget)
                    || $.isNumeric(revenue)
                    || $.isNumeric(revenueLastYear)
                    || $.isNumeric(revenueBudget)
                    || $.isNumeric(revPar)
                    || $.isNumeric(revParLastYear)
                    || $.isNumeric(revParBudget)
                    || $.isNumeric(noShows)
                    || $.isNumeric(noShowsLastYear)
                    || $.isNumeric(noShowsBudget)
                    || $.isNumeric(foodAndBeverage)
                    || $.isNumeric(foodAndBeverageLastYear)
                    || $.isNumeric(foodAndBeverageBudget)
                    || $.isNumeric(individualsBusiness)
                    || $.isNumeric(individualsBusinessLastYear)
                    || $.isNumeric(individualsBusinessBudget)
                    || $.isNumeric(groupBusiness)
                    || $.isNumeric(groupBusinessLastYear)
                    || $.isNumeric(groupBusinessBudget)
                ) {
                    foundAnyData = true;
                }
                if (!foundAnyData || !procData || Object.keys(procData).length === 0) {
                    this.empty();
                    return;
                }

                // Occupancy.
                row = this.makeTableRow(tableRowTitles.occ);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = formatOccupancy(occupancy);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(occupancy, occupancyLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: formatOccupancy(tmp)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(occupancy, occupancyBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: formatOccupancy(tmp)
                });
                cell.isLoading = false;

                // ADR.
                row = this.makeTableRow(tableRowTitles.adr);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = super.formatCurrency(adr, Constants.ADR_DIGITS,1);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(adr, adrLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatCurrency(tmp, Constants.ADR_DIGITS,1)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(adr, adrBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatCurrency(tmp, Constants.ADR_DIGITS,1)
                });
                cell.isLoading = false;

                // Revenue.
                row = this.makeTableRow(tableRowTitles.rev);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = super.formatCurrency(revenue, Constants.REVENUE_DIGITS);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(revenue, revenueLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatCurrency(tmp, Constants.REVENUE_DIGITS)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(revenue, revenueBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatCurrency(tmp, Constants.REVENUE_DIGITS)
                });
                cell.isLoading = false;

                // RevPAR.
                row = this.makeTableRow(tableRowTitles.revpar);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = super.formatCurrency(revPar, Constants.ADR_DIGITS,2);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(revPar, revParLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatCurrency(tmp,Constants.ADR_DIGITS,2)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(revPar, revParBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatCurrency(tmp,Constants.ADR_DIGITS,2)
                });
                cell.isLoading = false;

                // NoShows.
                row = this.makeTableRow(tableRowTitles.noshow);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = super.formatNumeric(noShows);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(noShows, noShowsLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatNumeric(tmp)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(noShows, noShowsBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatNumeric(tmp)
                });
                cell.isLoading = false;

                // IndBus.
                row = this.makeTableRow(tableRowTitles.inbus);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = super.formatNumeric(individualsBusiness);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(individualsBusiness, individualsBusinessLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatNumeric(tmp)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(individualsBusiness, individualsBusinessBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatNumeric(tmp)
                });
                cell.isLoading = false;

                // GrpBus.
                row = this.makeTableRow(tableRowTitles.grpbus);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = super.formatNumeric(groupBusiness);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.lastYear);
                tmp = this.diff(groupBusiness, groupBusinessLastYear);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatNumeric(tmp)
                });
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.budget);
                tmp = this.diff(groupBusiness, groupBusinessBudget);
                cell.value = new NumericVarianceBlock({
                    numericValue: tmp,
                    formattedValue: super.formatNumeric(tmp)
                });
                cell.isLoading = false;

                // OTB Tile.
                this.roomsOtbPickup = super.formatNumeric(roomsOtbPickup);

                // ADR Tile;
                this.adrPickup = super.formatCurrency(adrPickup, Constants.ADR_DIGITS,1);

                // Revenue Tile.
                this.revenuePickup = super.formatCurrency(revenuePickup, Constants.REVENUE_DIGITS);
            }
        )
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.kpi,
                    value: 'KPI',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.actual,
                    value: 'Actual',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.lastYear,
                    value: html`&#9651 LY`,
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.budget,
                    value: html`&#9651 B`,
                    type: tableCellTypes.TH
                })
            ),
        ];
        this.cache['cols'] = cols;
        return cols;
    }

    getTable() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            let table = new Table({
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ],
                tableOpts: {
                    canShowToolbar: false,
                }
            });
            this.cache['table'] = table;
        }

        return this.cache.table;
    }

    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows
            });
        }

        return table;
    }

    domTheTable(table) {
        $('#' + this.id).find('.vao__components--yesterdayHotel-table').empty().append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    makeTableRow(title) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(title)) {
            return this.cache.rowMap.get(title);
        }

        const row = new TableRow([
            new TableCell({
                field: cellFieldMap.kpi,
                value: title
            }),
            new TableCell({
                field: cellFieldMap.actual,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.lastYear,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.budget,
                value: null,
                isLoading: true
            }),
        ]);

        this.cache.rowMap.set(title, row);
        return row;
    }

    makeTableRows() {
        let rows = [];
        Object.values(tableRowTitles).forEach(title => {
            rows.push(this.makeTableRow(title));
        });
        return rows;
    }

    renderYesterday() {
        return html`
            <h6>Performance Metrics</h6>
            <div class="vao__components--yesterdayHotel-table"></div>
            <h6>On The Day Pickup</h6>
            <div class="vao__components--yesterdayHotel-metricTiles">
                <div>
                    <vao-metric-tile 
                        .iconCls="${Constants.ICONS.ROOMS}" 
                        .txt="${Constants.STRINGS.ROOMS}" 
                        .metric="${this.roomsOtbPickup}"
                        .color="${this.colorArray[0]}"
                    ></vao-metric-tile>
                </div>
                <div class="vao__components--yesterdayHotel-metricTiles-divider"></div>
                <div>
                    <vao-metric-tile 
                        .iconCls="${Constants.ICONS.ADR}" 
                        .txt="${Constants.STRINGS.ADR}" 
                        .metric="${this.adrPickup}"
                        .color="${this.colorArray[1]}"
                    ></vao-metric-tile>
                </div>
                <div class="vao__components--yesterdayHotel-metricTiles-divider"></div>
                <div>
                    <vao-metric-tile 
                        .iconCls="${Constants.ICONS.REVENUE}" 
                        .txt="${Constants.STRINGS.REV}" 
                        .metric="${this.revenuePickup}"
                        .color="${this.colorArray[2]}"
                    ></vao-metric-tile>
                </div>
                <!-- To allow scroll container to be longer --> 
                <div 
                class="vao__components--yesterdayHotel-metricTiles-spacer" 
                style="min-height:1px;min-width:3px;"
                ></div>
            </div>
        `;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.YesterdayHotel = YesterdayHotel;
customElements.define('vao-yesterday-hotel', YesterdayHotel);
