import {makeChannelOptionHtml} from "./ChannelOptionHtml";

export function makeAgodaChannelOptionHtml() {
    const innerHtml = '<span style="color: #EE2225;">A</span>'
        + '<span style="color: #F69E1D;">g</span>'
        + '<span style="color: #19AC5B;">o</span>'
        + '<span style="color: #814FA0;">d</span>'
        + '<span style="color: #347FC2;">a</span>';
    const html = makeChannelOptionHtml(innerHtml, [
        'background: white;',
    ]);
    return html;
}
