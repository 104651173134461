import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryWhatToExpectMyPrice extends AppElement {
    static get properties() {
        return {
            numTimeLeadCloseDownChange: { type: Number },
            numTimeLeadCloseLength: { type: Number },
            leaderCloserListItems: { type: Array },
            dropIncreasePercentAnalysis: { type: String },
            leadTime: { type: String },
            dow: { type: String },
            dropIncreaseAverageAnalysis: { type: String },
            dropIncreasePercent7Days: { type: String },
            dropIncreasePercent7DaysTxt: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.numTimeLeadCloseDownChange = Constants.MISSING_STR;
        this.numTimeLeadCloseLength = Constants.MISSING_STR;
        this.leaderCloserListItems = [];
        this.dropIncreasePercentAnalysis = Constants.MISSING_STR;
        this.leadTime = Constants.MISSING_STR;
        this.dow = Constants.MISSING_STR;
        this.dropIncreaseAverageAnalysis = Constants.MISSING_STR;
        this.dropIncreasePercent7Days = Constants.MISSING_STR;
        this.dropIncreasePercent7DaysTxt = Constants.MISSING_STR;
    }

    canShowWhatToExpected7Days() {
        return Number.isInteger(this.leadTime) && this.leadTime > 7;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.rateLeaderCloserTrendProphecy,
                    modelBiStats.fields.rateLeaderFutureLeaderTrendProphecy
                ]
            }),
            (data, procData) => {
                let rateLeaderCloserTrendProphecy =
                    ((procData.rateLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || {};
                let rateLeaderFutureLeaderTrendProphecy =
                    ((procData.rateLeaderFutureLeaderTrendProphecy || {})[this.stayDate] || {}).value || {};

                // Put these here to help batch lit async DOM update
                this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(this.stayDate);
                this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    this.stayDate,
                    this.recordDate
                );

                if (
                    rateLeaderCloserTrendProphecy
                    && typeof rateLeaderCloserTrendProphecy === 'object'
                    && 'leaders' in rateLeaderCloserTrendProphecy
                    && Array.isArray(rateLeaderCloserTrendProphecy.leaders)
                    && 'closers' in rateLeaderCloserTrendProphecy
                    && Array.isArray(rateLeaderCloserTrendProphecy.closers)
                ) {
                    this.numTimeLeadCloseLength = rateLeaderCloserTrendProphecy.leaders.length;

                    let findMatchingCloser = (stayDate) => {
                        return rateLeaderCloserTrendProphecy.closers.find((closer) => {
                            if (closer.stayDate === stayDate) {
                                return true;
                            }
                            return false;
                        });
                    };

                    let numUp = 0;
                    let ups = [];
                    let numDown = 0;
                    let downs = [];
                    const isZero = (nowOrThen) => {
                        const parsedNowOrThen = parseFloat(nowOrThen);
                        return parsedNowOrThen === 0;
                    };
                    const isSold = (nowOrThen) => {
                        return isZero(nowOrThen);
                    };
                    const canIncludeNowOrThenInAverage = (nowOrThen) => {
                        if (!$.isNumeric(nowOrThen)) {
                            return false;
                        }
                        return !isZero(nowOrThen);
                    };
                    rateLeaderCloserTrendProphecy.leaders.forEach((leader) => {
                        let closer = findMatchingCloser(leader.stayDate);
                        if (!closer) {
                            return;
                        }
                        let prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
                            leader.stayDate
                        );
                        let then = leader.value;
                        let now = closer.value;
                        if (!$.isNumeric(then)) {
                            then = Constants.MISSING_STR;
                        }
                        if (!$.isNumeric(now)) {
                            now = Constants.MISSING_STR;
                        }
                        if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                            // Special case told to handle when going from selling to closed is actually an increase.
                            if (isSold(now)) {
                                numUp++;
                            } else {
                                numDown++;
                            }
                            if (
                                canIncludeNowOrThenInAverage(now)
                                && canIncludeNowOrThenInAverage(then)
                            ) {
                                downs.push(now - then);
                            }
                        }
                        if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                            // Special case told to handle when property was sold out but now selling again decrease.
                            if (isSold(then)) {
                                numDown++;
                            } else {
                                numUp++;
                            }
                            if (
                                canIncludeNowOrThenInAverage(now)
                                && canIncludeNowOrThenInAverage(then)
                            ) {
                                ups.push(now - then);
                            }
                        }
                        then = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            then,
                            Constants.RATE_DIGITS,
                            true,
                            this.locale
                        );
                        now = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            now,
                            Constants.RATE_DIGITS,
                            true,
                            this.locale
                        );
                        this.leaderCloserListItems.push({
                            stayDate: prettyStayDate,
                            then: then,
                            now: now
                        })
                    });

                    if (this.numTimeLeadCloseLength > 0) {
                        if (numDown === numUp) {
                            this.dropIncreasePercentAnalysis = '0% of the time we changed our rate ';
                            this.dropIncreaseAverageAnalysis = Constants.MISSING_STR;
                        } else if (numDown > numUp) {
                            let percent = Math.floor((numDown / this.numTimeLeadCloseLength) * 100);
                            this.dropIncreasePercentAnalysis = percent + '% of the time we dropped our rate ';
                            let sum = downs.reduce((a, b) => a + b, 0);
                            let avg = (sum / downs.length) || 0;
                            this.dropIncreaseAverageAnalysis = window.infinito.vao.controller.moneyHelper.
                                formatMoneyBracketStyle(
                                avg,
                                Constants.RATE_DIGITS,
                                true,
                                this.locale
                            );
                        } else {
                            let percent = Math.floor((numUp / this.numTimeLeadCloseLength) * 100);
                            this.dropIncreasePercentAnalysis = percent + '% of the time we increased our rate ';
                            let sum = ups.reduce((a, b) => a + b, 0);
                            let avg = (sum / ups.length) || 0;
                            this.dropIncreaseAverageAnalysis = window.infinito.vao.controller.moneyHelper.
                            formatMoneyBracketStyle(
                                avg,
                                Constants.RATE_DIGITS,
                                true,
                                this.locale
                            );
                        }
                    }
                } else {
                    this.numTimeLeadCloseLength = Constants.MISSING_STR;
                }

                if (
                    this.canShowWhatToExpected7Days()
                    && rateLeaderFutureLeaderTrendProphecy
                    && typeof rateLeaderFutureLeaderTrendProphecy === 'object'
                    && 'leaders' in rateLeaderFutureLeaderTrendProphecy
                    && Array.isArray(rateLeaderFutureLeaderTrendProphecy.leaders)
                    && 'futureLeaders' in rateLeaderFutureLeaderTrendProphecy
                    && Array.isArray(rateLeaderFutureLeaderTrendProphecy.futureLeaders)
                ) {
                    let findMatchingFutureLeader = (stayDate) => {
                        return rateLeaderFutureLeaderTrendProphecy.futureLeaders.find((closer) => {
                            if (closer.stayDate === stayDate) {
                                return true;
                            }
                            return false;
                        });
                    };

                    let numUp = 0;
                    let numDown = 0;
                    rateLeaderFutureLeaderTrendProphecy.leaders.forEach((leader) => {
                        let futureLeader = findMatchingFutureLeader(leader.stayDate);
                        if (!futureLeader) {
                            return;
                        }
                        let then = leader.value;
                        let now = futureLeader.value;
                        if (!$.isNumeric(then)) {
                            then = Constants.MISSING_STR;
                        }
                        if (!$.isNumeric(now)) {
                            now = Constants.MISSING_STR;
                        }
                        if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                            numDown++;
                        }
                        if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                            numUp++;
                        }
                    });

                    if (numDown === numUp) {
                        this.dropIncreasePercent7Days = '50/50';
                        this.dropIncreasePercent7DaysTxt = 'change';
                    } else if (numDown > numUp) {
                        let percent = Math.floor(
                            (numDown / rateLeaderFutureLeaderTrendProphecy.leaders.length) * 100
                        );
                        this.dropIncreasePercent7Days = percent + '%';
                        this.dropIncreasePercent7DaysTxt = 'drop';
                    } else {
                        let percent = Math.floor(
                            (numUp / rateLeaderFutureLeaderTrendProphecy.leaders.length) * 100
                        );
                        this.dropIncreasePercent7Days = percent + '%';
                        this.dropIncreasePercent7DaysTxt = 'increase';
                    }
                } else {
                    this.dropIncreasePercent7Days = Constants.MISSING_STR;
                    this.dropIncreasePercent7DaysTxt = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyWhatToExpectMyPrice">
    ${storyHtml`
    <p>
        ${
            (this.dropIncreasePercentAnalysis === Constants.MISSING_STR)
            ? html``
            : storyHtml`
                ${new StoryText(this.dropIncreasePercentAnalysis, true, storyTextTypes.TYPE_TEXTUAL)} 
                within 
                ${new StoryText(this.leadTime, true, storyTextTypes.TYPE_NEUTRAL)} days out for a 
                ${new StoryText(this.dow, true, storyTextTypes.TYPE_NEUTRAL)}. This averages out at 
                ${new StoryText(this.dropIncreaseAverageAnalysis, true, 
                storyTextTypes.TYPE_MONETARY, this.locale)}.
                `
        }
    </p>
    `}
    <div style="margin-bottom: 1rem;">
        <ul class="list-group">
            ${
                this.leaderCloserListItems.map((listItem) => {
                    return html`
                        <li class="list-group-item" style="display: flex;justify-content: space-between;
                        align-items: center;}">
                            <div style="font-size: .8rem;">${listItem.stayDate}</div>
                            <div style="color: gray;font-size: .7rem;">
                                <span>${listItem.then}</span>
                                <i class="${Constants.ICONS.ARROW_RIGHT}" style="font-size: .6rem;margin: 0 .3rem;"></i>
                                <span>${listItem.now}</span>
                            </div>
                        </li>`;    
                })
            }
        </ul>
    </div>
    ${this.dropIncreasePercent7Days === Constants.MISSING_STR ? html`` : storyHtml`
    <p>
        There is a ${new StoryText(this.dropIncreasePercent7Days, true, storyTextTypes.TYPE_NEUTRAL)} 
        chance that we will 
        ${new StoryText(this.dropIncreasePercent7DaysTxt, true, storyTextTypes.TYPE_TEXTUAL)} our 
        rate within the next 7 days.
    </p>
    `}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryWhatToExpectMyPrice = StoryWhatToExpectMyPrice;
customElements.define('vao-story-what-to-expect-my-price', StoryWhatToExpectMyPrice);
