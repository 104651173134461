export default {
    MISSING_STR: '-',
    NO_DATA_STRING: 'No data to play with.',
    PRIMARY_COLOR_CLASS: 'prim-col',
    REVENUE_DIGITS: 0,
    ADR_DIGITS: 2,
    RATE_DIGITS: 0,
    ICONS: {
        INFO: 'fas fa-info',
        ROOMS: 'fas fa-bed',
        ADR: 'fas fa-tachometer-alt',
        REVENUE: 'fas fa-money-bill-alt',
        JUMP: 'fas fa-external-link-alt',
        CHEVRON_LEFT: 'fas fa-chevron-left',
        CHEVRON_RIGHT: 'fas fa-chevron-right',
        PICKUP: 'fas fa-exchange-alt',
        STAY_DATE: 'far fa-calendar-check',
        RECORD_DATE: 'far fa-calendar-alt',
        CARET_DOWN: 'fas fa-caret-down',
        CARET_UP: 'fas fa-caret-up',
        ANGLE_DOWN: 'fas fa-angle-down',
        ARROW_RIGHT: 'fas fa-arrow-right',
        CALENDAR: 'far fa-calendar-alt',
        CIRCLE: 'fas fa-circle',
        COLUMNS: 'fas fa-columns',
        CSV: 'fas fa-file-csv',
        RETWEET: 'fas fa-retweet',
        PAINT_BRUSH: 'fas fa-paint-brush',
        CARET_SQUARE_LEFT: 'far fa-caret-square-left',
        CARET_SQUARE_UP: 'far fa-caret-square-up',
        BORDER_ALL: 'fas fa-border-all',
        HORSE: 'fas fa-horse',
        ALIGN_LEFT: 'fas fa-align-left',
        ALIGN_CENTER: 'fas fa-align-center',
        ALIGN_RIGHT: 'fas fa-align-right',
        WINDOW_MINIMIZE: 'far fa-window-minimize',
        WINDOW_MAXIMIZE: 'far fa-window-maximize',
        WINDOW_RESTORE: 'far fa-window-restore',
        TEXT_HEIGHT: 'fas fa-text-height',
        MASK: 'fas fa-mask',
        QUESTION_CIRCLED: 'far fa-question-circle',
        EMOTE_SAD_CRY: 'far fa-sad-cry',
        EMOTE_MEH: 'far fa-meh',
        EMOTE_SMILE: 'far fa-smile',
        EMOTE_FROWN: 'far fa-frown',
        EMOTE_MEH_BLANK: 'far fa-meh-blank',
        COLLAPSE_ALL: 'fas fa-compress-alt',
        EXPAND_ALL: 'fas fa-expand-alt',
        SORT: 'fas fa-sort',
        SORT_UP: 'fas fa-sort-up',
        SORT_DOWN: 'fas fa-sort-down',
        ROOM_TYPE: 'fas fa-shapes',
        MARKET_SEGMENT: 'fas fa-chart-pie',
        OCCUPANCY: 'fas fa-percent',
        SAVE: 'far fa-save',
        UNDO: 'fas fa-undo',
        DIFF: 'far fa-file-code',
        CROWN: 'fas fa-crown',
        MINUS: 'fas fa-minus',
        PLUS: 'fas fa-plus',
        ELLIPSIS: 'fas fa-ellipsis-h',
        XLSX: 'fas fa-file-excel',
        ELLIPSIS:'fas fa-ellipsis-h',
        XLSX:'fas fa-file-excel',
        TICKCRICLE:'far fa-check-circle',
        TIMESCIRCLE:'far fa-times-circle',
        YINYANG:'fas fa-yin-yang'
    },
    STRINGS: {
        ROOMS: 'Rooms',
        ADR: 'ADR',
        REV: 'Revenue',
        OCC: 'Occupancy',
        STORY: 'Story',
        REVPAR: 'RevPAR',
        NO_DATA: 'No Data',
        TOTAL: 'Total',
        STAY_DATE: 'Stay Date',
        METRIC_BREAKDOWN: 'Metric Breakdown',
        ROOM_TYPE: 'Room Type',
        SEGMENT: 'Segment',
        ABSTRACT_FUNC_ERR_MSG: 'Child must implement',
        BALANCED: 'Balanced',
        IMBALANCED: 'Imbalanced',
        LOADING: 'Loading',
        LOADING_ELLIPSIS: 'Loading...',
        CONTEXT_BALANCE_MODAL_TITLE: 'Data Set Balance',
        PRIMARY: 'Primary',
        CONTEXT: 'Context',
        SNAPSHOT: 'Snapshot',
        OFFSET: 'Offset',
        COMPARE: 'Compare',
        MATCH: 'Match',
        APART: 'Apart',
        DAY: 'Day',
        WEEK: 'Week',
        MONTH: 'Month',
        YEAR: 'Year',
        DATE: 'Date',
        AVAILABLE: 'Available',
        ACTION: 'Action',
        DAYS_30: '30 Days',
        DAYS_60: '60 Days',
        DAYS_90: '90 Days',
        DAYS_365: '365 Days'
    },
    TOOLTIP: {
        TRIGGERS: {
            CLICK: 'click',
            HOVER: 'hover',
            FOCUS: 'focus',
            MANUAL: 'manual'
        }
    },
    CTX_CATEGORIES: {
        ROOMTYPE: 'roomType',
        SEGMENT: 'marketSegment',
        CHANNEL: 'channel',
        PROPERTY: 'property'
    },
    CTX_PROPERTY_KEY: '__@property__',
    COLORS: {
        HEX: {
            PRIMARY: '#2196f3',
            SECONDARY: '#e91e63',
            THIRDLY: '#9c27b0',
            SUCCESS: '#28a745',
            DANGER: '#dc3545',
            WARN: '#ffc107',
            INFO: '#17a2b8',
            LIGHT: '#f8f9fa',
            DARK: '#343a40',
            TEXT_MUTED: '#6c757d',

            VERY_GOOD: '#009688',
            GOOD: '#03A9F4',
            AVERAGE: '#FFC107',
            BAD: '#FF5722',
            VERY_BAD: '#ff0000'
        },
        // For CSS classes such as "text-primary", "bg-primary", "btn-primary" etc...
        STRINGS: {
            PRIMARY: 'primary',
            SECONDARY: 'secondary',
            THIRDLY: 'thirdly',
            SUCCESS: 'success',
            DANGER: 'danger',
            WARN: 'warning',
            INFO: 'info',
            LIGHT: 'light',
            DARK: 'dark',
            MUTED: 'muted',
            WHITE: 'white'
        }
    },
    SIZES: {
        XS: 'xs',
        SMALL: 'sm',
        MEDIUM: 'md',
        LARGE: 'lg',
        EXTRA_LARGE: 'xl'
    },
    METRICS: {
        ROOMS: 'ROOMS',
        OCCUPANCY: 'OCCUPANCY',
        ADR: 'ADR',
        REVENUE: 'REVENUE'
    },
    BADGES: {
        CLASSES: {
            PRIMARY: 'badge-primary',
            SECONDARY: 'badge-secondary',
            SUCCESS: 'badge-success',
            DANGER: 'badge-danger',
            WARNING: 'badge-warning',
            INFO: 'badge-info',
            LIGHT: 'badge-light',
            DARK: 'badge-dark'
        }
    }
};
