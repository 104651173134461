import { html } from './AppElement.js';
import Constants from "./Constants";

export const storyTextTypes = {
    // color derived from value
    TYPE_NUMERIC: 'numeric',
    TYPE_MONETARY: 'monetary',
    TYPE_TEXTUAL: 'textual',
    // color dictated
    TYPE_POSITIVE: 'positive',
    TYPE_NEGATIVE: 'negative',
    TYPE_NEUTRAL: 'neutral',
};

export class StoryText {

    constructor(value, shouldColor, type, locale) {
        this.value = value;
        this.shouldColor = shouldColor;
        this.type = type;
        this.locale = locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
    }

}

const textualPositives = [
    'strong',
    'ahead',
    'increas',
    'positive',
    'above',
    'improve',
    'high'
];

const textualNegatives = [
    'behind',
    'below',
    'negative',
    'decreas',
    'drop',
    'low'
];

export const storyHtml = (strings, ...values) => {
    let stringsClone = (strings || []).slice(0);
    let valuesClone = (values || []).slice(0);
    if (valuesClone.length) {
        valuesClone = valuesClone.map((variable, index) => {
            if (variable instanceof StoryText) {
                let colCls = '';
                if (variable.shouldColor) {
                    let parsed = 0;
                    if (variable.type === storyTextTypes.TYPE_MONETARY) {
                        parsed = window.infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                            variable.value,
                            variable.locale
                        );
                    } else if (variable.type === storyTextTypes.TYPE_NUMERIC) {
                        parsed = parseFloat(variable.value);
                    } else if (variable.type === storyTextTypes.TYPE_POSITIVE) {
                        parsed = 1;
                    } else if (variable.type === storyTextTypes.TYPE_NEGATIVE) {
                        parsed = -1;
                    } else if (variable.type === storyTextTypes.TYPE_NEUTRAL) {
                        parsed = 0;
                    } else if (variable.type === storyTextTypes.TYPE_TEXTUAL) {
                        if (
                            textualPositives.some(substring => variable.value.includes(substring))
                        ) {
                            parsed = 1;
                        } else if (
                            textualNegatives.some(substring => variable.value.includes(substring))
                        ) {
                            parsed = -1;
                        } else {
                            parsed = 0;
                        }
                    }
                    if (parsed < 0) {
                        colCls = 'text-danger';
                    } else if (parsed > 0) {
                        colCls = 'text-success';
                    } else {
                        colCls = 'text-primary';
                    }
                }
                stringsClone[index] = (stringsClone[index] || '') + '<span class="vao-highlight-text ' + colCls +'">';
                stringsClone[index + 1] = '</span>' + (stringsClone[index + 1] || '');
                return variable.value;
            } else {
                return variable;
            }
        });
    }
    return html(stringsClone, ...valuesClone); // spread operator
};

export const getBadgeClassForString = (str, defaultCls) => {
    if (!str || typeof str !== 'string' || str.length === 0) {
        return defaultCls || '';
    }
    const successHaystack = [
        'bullish',
        'top',
        'upside'
    ];
    const warningHaystack = [
        'risk of over',
        'review pos',
        'trying to buy',
        'low rates to drive'
    ];
    const dangerHaystack = [
        'high risk',
        'underpric',
        'overpriced in low demand',
        'rockbottom'
    ];
    const hasNeedleInHaystack = (needle, haystack) => {
        return haystack.some(haystackPart => needle.includes(haystackPart));
    };
    const needle = str.toLowerCase().trim();
    if (hasNeedleInHaystack(needle, successHaystack)) {
        return Constants.BADGES.CLASSES.SUCCESS;
    }
    if (hasNeedleInHaystack(needle, warningHaystack)) {
        return Constants.BADGES.CLASSES.WARNING;
    }
    if (hasNeedleInHaystack(needle, dangerHaystack)) {
        return Constants.BADGES.CLASSES.DANGER;
    }
    return defaultCls || '';
};