let colorPalettes = {
    'material': [
        '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
        '#2196f3', '#03a9f4', '#00bcd4', '#009688',
        '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b',
        '#ffc107', '#ff9800', '#ff5722', '#f44336',
        '#795548', '#9e9e9e', '#607d8b'
    ],
    'soft': [
        '#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0',
        '#B2912F', '#B276B2', '#DECF3F', '#F15854',
        '#4D4D4D', '#FFB399', '#FF33FF', '#FFFF99',
        '#E6B333', '#3366E6', '#999966', '#99FF99',
        '#B34D4D', '#80B300', '#809900', '#E6B3B3',
        '#6680B3', '#66991A', '#FF99E6', '#CCFF1A',
        '#FF1A66', '#E6331A', '#33FFCC', '#66994D',
        '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF',
        '#1AB399', '#E666B3', '#33991A', '#CC9999',
        '#B3B31A', '#00E680', '#4D8066', '#809980',
        '#1AFF33', '#999933', '#FF3380', '#CCCC00',
        '#66E64D', '#4D80CC', '#9900B3', '#E64D66',
        '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    ],
    'high': [
        "#7cb5ec", "#434348", "#90ed7d", "#f7a35c",
        "#8085e9", "#f15c80", "#e4d354", "#2b908f",
        "#f45b5b", "#91e8e1"
    ],
    'pale': [
        '#b276b2', '#FAA43A', '#4D4D4D', '#F17CB0',
        '#B2912F'
    ],
    'minimal': [
        '#20c997', '#00BCD4', '#3f51b5', '#007bff',
        '#9C27B0', '#E91E63'
    ]
};
let customPaletteCount = 0;
let defaultColorArrayKey = 'soft';

export default class ColorArray {
    static isHexColor(col) {
        // Should do more validation here.
        if (
            typeof col !== 'string'
            || col.length !== 7
            || !col.startsWith('#')
        ) {
            return false;
        }
        return true;
    }

    // https://stackoverflow.com/a/32685393
    static isValidColor(color) {
        var e = document.getElementById('__divValidColor__');
        if (!e) {
            e = document.createElement('div');
            e.id = '__divValidColor__';
        }
        e.style.borderColor = '';
        e.style.borderColor = color;
        var tmpcolor = e.style.borderColor;
        if (tmpcolor.length == 0) {
            return false;
        }
        return true;
    }

    static isColorArray(arr) {
        if (!Array.isArray(arr)) {
            return false;
        }
        return arr.every(ColorArray.isHexColor);
    }

    static setDefaultColorArray(newDefault) {
        if (typeof newDefault === 'string' && (newDefault in colorPalettes)) {
            defaultColorArrayKey = newDefault;
            return;
        }

        if (Array.isArray(newDefault)) {
            let found = Object.keys(colorPalettes).some(key => {
               if (colorPalettes[key] === newDefault) {
                   defaultColorArrayKey = key;
                   return true;
               }
               return false;
            });

            if (found) {
                return;
            }

            if (!found && ColorArray.isColorArray(newDefault)) {
                let newCustomKey = '__@custom__' + customPaletteCount;
                colorPalettes[newCustomKey] = newDefault;
                customPaletteCount++;
                defaultColorArrayKey = newCustomKey;
                return;
            }
        }

        throw 'Invalid ColorArray default palette input. Expect valid string key or color array.';
    }

    static getColorPalettes() {
        return {...colorPalettes};
    }

    static getDefaultColorPaletteKey() {
        return defaultColorArrayKey;
    }

    static hexToRGBA(hex, opacity) {
        if (!ColorArray.isHexColor(hex)) {
            throw 'Cannot convert invalid hex ' + hex + ' to RGBA';
        }

        if (!$.isNumeric(opacity)) {
            opacity = 1;
        }

        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);

        return `rgba(${r},${g},${b},${opacity})`;
    }

    // Credit: https://www.sitepoint.com/javascript-generate-lighter-darker-color/
    static modifyColorLuminance(hex, lum) {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }

        return rgb;
    }

    constructor(colorArray = [], index = null, dataPerKey = null, luminanceLevels = 0, modifier = null) {
        if (!Array.isArray(colorArray) || colorArray.length === 0) {
            this.colorArray = colorPalettes[defaultColorArrayKey];
        } else {
            this.colorArray = colorArray;
        }

        if (Number.isFinite(index) && index > 0) {
            this.index = index;
        } else {
            this.index = 0;
        }

        if (dataPerKey instanceof Map) {
            this.dataPerKey = dataPerKey;
        } else {
            this.dataPerKey = new Map();
        }

        if (Number.isFinite(luminanceLevels) && luminanceLevels > 0) {
            let luminousColorArray = [];
            this.colorArray.forEach(hex => {
                luminousColorArray.push(hex);
                for (let i = 0; i < luminanceLevels; i++) {
                    let lum = (i + 1) / 10;
                    luminousColorArray.push(ColorArray.modifyColorLuminance(hex, lum));
                }
            });
            this.colorArray = luminousColorArray;
        }

        if (typeof modifier === 'function') {
            this.colorArray = this.colorArray.map((hex, index) => {
                let tmpCol = modifier(hex, index);
                if (tmpCol && typeof tmpCol === 'string' && tmpCol.length > 0) {
                    return tmpCol;
                }
                return hex;
            });
        }
    }

    _alias(key) {
        return '__@alias__' + key;
    }

    next(optionalKey, optionalData) {
        let _colIndex = (this.index % this.colorArray.length); // So that we wrap colors when we run out.
        let color = this.colorArray[_colIndex];

        let cacheItem;
        if (this.dataPerKey.has(this.index)) {
            if (optionalData !== undefined) {
                cacheItem = {...this.dataPerKey.get(this.index), data: optionalData};
            } else {
                cacheItem = this.dataPerKey.get(this.index);
            }
        } else {
            cacheItem = {
                index: this.index,
                key: this.index,
                alias: undefined,
                color: color,
                data: optionalData
            };
        }
        if (typeof optionalKey === 'string' && optionalKey.length > 0) {
            let _alias = this._alias(optionalKey);

            // Safety net in-case request next() with same key.
            // Should we throw an error here instead?
            if (this.dataPerKey.has(_alias)) {
                return this.dataPerKey.get(_alias);
            }

            cacheItem['key'] = optionalKey;
            cacheItem['alias'] = _alias;

            // Add another map key - but the same obj ref used.
            this.dataPerKey.set(_alias, cacheItem);
        } else {
            this.dataPerKey.set(this.index, cacheItem);
        }

        this.index++;
        return cacheItem;
    }

    get(key) {
        return this.dataPerKey.get(key) || this.dataPerKey.get(this._alias(key));
    }

}

window.vao.classes = window.vao.classes || {};
window.vao.classes.ColorArray = ColorArray;
