import { AppElement, html } from '../AppElement.js';
import TableNoDataRow from "../table/TableNoDataRow";
import Table from "../table/Table";
import TableLoadingRow from "../table/TableLoadingRow";
import TableCell, {tableCellTypes} from "../table/TableCell";
import TableColumn from "../table/TableColumn";
import Constants from "../Constants";
import TableRow from "../table/TableRow";
import TableCellValueInput, {EVENT_TABLE_CELL_VALUE_INPUT_CHANGE, INPUT_TYPES} from "../table/TableCelIValueInput";
import BudgetForecastFormTableActionCell, {
    EVENT_BUDGET_FORM_TABLE_ACTION_CELL_DIFF,
    EVENT_BUDGET_FORM_TABLE_ACTION_CELL_RESET,
    EVENT_BUDGET_FORM_TABLE_ACTION_CELL_SAVE
} from "./BudgetForecastFormTableActionCell";
import BudgetForecastUtils from "./BudgetForecastUtils";
import BudgetForecastDiff from "./BudgetForecastDiff";
import {sendRequest} from "../App.common";

const cellFieldMap = {
    date: 'date',
    availableRooms: 'available',
    roomsOtb: 'roomsOtb',
    revenue: 'revenue',
    action: 'action'
};

export default class BudgetForecastFormTable extends AppElement {
    static get properties() {
        return {
            // props
            aggregator: { type: String },
            year: { type: String },
            hotel: { type: Object },

            // state
            isLoading: { type: String },
            budgetValues: { type: Array },
        };
    }

    constructor(props = {}) {
        super();

        this.aggregator = props.aggregator;
        this.year = props.year;
        this.hotel = props.hotel;

        this.tableOpts = {
            canShowToolbar: false
        };
        this.cache = new Map();
        this.empty();
        this.getTable();

        this.addEventListener(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_SAVE, this.handleBudgetFormTableActionCellSave);
        this.addEventListener(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_DIFF, this.handleBudgetFormTableActionCellDiff);
        this.addEventListener(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_RESET, this.handleBudgetFormTableActionCellReset);
        this.addEventListener(EVENT_TABLE_CELL_VALUE_INPUT_CHANGE, this.handleTableCellInputChange);
    }

    reflow(props = {}) {
        this.aggreagtor = props.aggregator || this.aggreagtor;
        this.year = props.year || this.year;
        this.hotel = props.hotel || this.hotel;
    }

    empty() {
        this.isLoading = false;
        this.budgetValues = [];
    }

    makeDateCellValue(budgetValue) {
        return BudgetForecastUtils.formatDate(budgetValue);
    }

    postBudgetValue(budgetValue) {
        // console.log(this.hotel.id,
        //     {
        //         startDate: budgetValue.startDate,
        //         endDate: budgetValue.endDate,
        //         roomType: budgetValue.roomType,
        //         marketSegment: budgetValue.marketSegment,
        //         availableRooms: this.getMetricFromBudgetValue(budgetValue, 'availableRooms'),
        //         roomsOtb: this.getMetricFromBudgetValue(budgetValue, 'roomsOtb'),
        //         revenue: this.getMetricFromBudgetValue(budgetValue, 'revenue')
        //     });


        const apiModel = window.infinito.vao.model.biBudgets;
        apiModel.post(
            this.hotel.id,
            {
                startDate: budgetValue.startDate,
                endDate: budgetValue.endDate,
                roomType: budgetValue.roomType,
                marketSegment: budgetValue.marketSegment,
                availableRooms: this.getMetricFromBudgetValue(budgetValue, 'availableRooms'),
                roomsOtb: this.getMetricFromBudgetValue(budgetValue, 'roomsOtb'),
                revenue: this.getMetricFromBudgetValue(budgetValue, 'revenue')
            },
            (errorMsg, data) => {
                console.log(errorMsg, data);

                if (errorMsg) {
                    console.error(errorMsg);
                    this.reduceBudgetValues({
                        ...budgetValue,
                        hasError: 'true',
                        isPosting: 'false',
                    });
                } else {
                    this.reduceBudgetValues({
                        ...data.data,
                        hasError: 'false',
                        isPosting: 'false',
                    });
                }
            }
        )
    }

    handleTableCellInputChange(e) {
        const budgetValue = e.detail.eventData.budgetValue;
        const metric = e.detail.eventData.metric;
        const event = e.detail.event;
        const element = e.detail.that;
        this.onChangeTableRowInput(event, element, budgetValue, metric);
    }

    handleBudgetFormTableActionCellSave(e) {
        const budgetValue = e.detail.budgetValue;
        const match = this.budgetValues.find(possibleMatch => {
            return possibleMatch.key === budgetValue.key;
        });
        if (match) {
            this.reduceBudgetValues({
                ...match,
                isPosting: 'true',
            });
            this.postBudgetValue(budgetValue);
        }
    }

    handleBudgetFormTableActionCellDiff(e) {
        const budgetValue = e.detail.budgetValue;
        const budgetDiffTitle = 'Budget Diff';
        window.infinito.components.basicModal.render({
            title: budgetDiffTitle,
            bodyElem: $(new BudgetForecastDiff({
                budgetValue
            }))
        });
    }

    handleBudgetFormTableActionCellReset(e) {
        const budgetValue = e.detail.budgetValue;
        this.reduceBudgetValues({
            ...budgetValue,
            diff: new Map()
        });
    }

    getCachedBudgetFormTableActionCell(budgetValue) {
        const key = 'budget-form-table-action-cell-' + budgetValue.key;

        const opts = {
            budgetValue,
            isDisabled: (budgetValue.diff || new Map()).size > 0 ? 'false' : 'true',
        };
        return this.acquireCacheElement(opts, key, (constructorOpts) => {
            return new BudgetForecastFormTableActionCell(constructorOpts);
        });
    }

    reduceBudgetValues(changedBudgetValue) {
        this.budgetValues = this.budgetValues.map(budgetValue => {
            if (budgetValue.key === changedBudgetValue.key) {
                return changedBudgetValue;
            }  else {
                return budgetValue;
            }
        });
    }

    onChangeTableRowInput(event, element, budgetValue, metric) {
        const value = event.currentTarget.value;
        const diff = budgetValue.diff || new Map();
        if (element.isDirty === 'true') {
            diff.set(metric, value);
        } else {
            diff.delete(metric);
        }
        this.reduceBudgetValues({
            ...budgetValue,
            diff,
            hasError: 'false'
        });
    }

    getMetricFromBudgetValue(budgetValue, metric) {
        const diff = budgetValue.diff || new Map();
        if (diff.has(metric)) {
            return diff.get(metric);
        }
        return budgetValue[metric];
    }

    getTableCellKey(budgetValue, fieldMapKey) {
        return 'vao-table-cell-' + cellFieldMap[fieldMapKey] + '-' + budgetValue.key;
    }

    acquireCacheElement(opts, cacheKey, newElementCreator) {
        if (this.cache.has(cacheKey)) {
            const existingElement = this.cache.get(cacheKey);
            existingElement.reflow(opts);
            return existingElement;
        }
        const newElement = newElementCreator(opts);
        this.cache.set(cacheKey, newElement);
        return newElement;
    }

    makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value) {
        const opts = {
            field: fieldMapKey,
            value
        };
        return this.acquireCacheElement(opts, cellKey, (constructorOpts) => {
            return new TableCell(constructorOpts);
        });
    }

    makeTableCellDate(budgetValue) {
        const fieldMapKey = cellFieldMap.date;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeDateCellValue(budgetValue);
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellNumberInput(budgetValue, metricKey, step) {
        const cacheKey = 'makeTableCellNumberInput-' + metricKey + '-' + budgetValue.key;
        const opts = {
            eventData: {
                budgetValue,
                metric: metricKey
            },
            initialValue: budgetValue[metricKey],
            value: this.getMetricFromBudgetValue(budgetValue, metricKey),
            type: INPUT_TYPES.number,
            step,
            isDisabled: budgetValue.isPosting,
            isError: budgetValue.hasError,
        };
        return this.acquireCacheElement(opts, cacheKey, (constructorOpts) => {
            return new TableCellValueInput(constructorOpts);
        });
    }

    makeTableCellAvailable(budgetValue) {
        const fieldMapKey = cellFieldMap.availableRooms;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeTableCellNumberInput(budgetValue, 'availableRooms', '1');
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellRooms(budgetValue) {
        const fieldMapKey = cellFieldMap.roomsOtb;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeTableCellNumberInput(budgetValue, 'roomsOtb', '1');
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellRevenue(budgetValue) {
        const fieldMapKey = cellFieldMap.revenue;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeTableCellNumberInput(budgetValue, 'revenue', 'any');
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellAction(budgetValue) {
        const fieldMapKey = cellFieldMap.action;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.getCachedBudgetFormTableActionCell(budgetValue);
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    getTableRows() {
        return this.budgetValues.map(budgetValue => {
            return new TableRow([
                this.makeTableCellDate(budgetValue),
                this.makeTableCellAvailable(budgetValue),
                this.makeTableCellRooms(budgetValue),
                this.makeTableCellRevenue(budgetValue),
                this.makeTableCellAction(budgetValue),
            ], budgetValue)
        });
    }

    reflowTable() {
        const rows = this.getTableRows();
        let innerRows;
        if (this.isLoading) {
            innerRows = [
                new TableLoadingRow()
            ];
        } else if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        table.reflow({
            columns: table.columns,
            rows: innerRows,
        });
        return table;
    }

    makeTableColumns() {
        if (!this.cache.has('tableColumns')) {
            this.cache.set('tableColumns', [
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.date,
                        value: Constants.STRINGS.DATE,
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                // new TableColumn(
                //     new TableCell({
                //         field: cellFieldMap.availableRooms,
                //         value: BudgetForecastUtils.getNameForMetric('availableRooms'),
                //         rowSpan: 1,
                //         colSpan: 1,
                //         type: tableCellTypes.TH
                //     })
                // ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.roomsOtb,
                        value: BudgetForecastUtils.getNameForMetric('roomsOtb'),
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.revenue,
                        value: BudgetForecastUtils.getNameForMetric('revenue'),
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.action,
                        value: Constants.STRINGS.ACTION,
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
            ]);
        }
        return this.cache.get('tableColumns');
    }

    getTable() {
        if (!this.cache.has('table')) {
            this.cache.set('table', new Table({
                columns: this.makeTableColumns(),
                rows: [],
                tableOpts: this.tableOpts
            }));
        }
        return this.cache.get('table');
    }

    canFill() {
        if (
            this.hotel
            && this.hotel.id
        ) {
            return true;
        }
        return false;
    }

    fill() {
        var table = document.getElementById("roomtypetable");
        var rowCount = table.rows.length;
        table.deleteRow(1);
        $("#tbody__roomtype").empty();

        this.isLoading = true;
        let postData = {
            'hotel_id': this.hotel.id,
            'aggregator': this.aggregator,
            'year': this.year,
            'canPadGroups': 'true',
            'isOnlyTotals': 'true',
            'op': 'getBudgetForecast',
            'serviceName': "forecastbudget.php"
        };
        let flag = this;
        sendRequest(postData,function(res){

            flag.isLoading = false;

            let obj = JSON.parse(res);
            var tabledata = obj.data;
            if(tabledata.length > 0){
                $("#data_fetch_msg").html("");
            }else{
                $("#data_fetch_msg").html("");
                $("#data_fetch_msg").html("No Data available");
            }
            var jsonObj = tabledata;
            flag.drawTable(jsonObj);


        });
        // apiModel.fetch(
        //     this.hotel.id,
        //     {
        //         'aggregator': this.aggregator,
        //         'year': this.year,
        //         'canPadGroups': 'true',
        //         'isOnlyTotals': 'true'
        //     },
        //     (errorMsg, data) => {
        //         console.log('***********');
        //         console.log(data);
        //         this.isLoading = false;
        //         if (errorMsg) {
        //             console.error(errorMsg);
        //             this.budgetValues = [];
        //             return; // TODO handle error
        //         }
        //         this.budgetValues = data.data;
        //     }
        // );
    }

    drawTable(jsonObj) {

        var count = 1;
        for (var i = 0; i <  Object. keys(jsonObj).length; i++) {
            var nttable = document.getElementById("roomtypetable");
            var tb = '<tr role="row" class="">' +
                '<td class="" style="display:none;" id="stayDatedt_' + count + '">' + jsonObj[i].stayDate_dt + '</td>' +
                '<td class="table-cell-date" style="padding-top: 9px !important;" rowspan="1" colspan="1" id="stayDate_' + count + '">' + jsonObj[i].stayDate + '</td>' +
                '<td class="table-cell-available" rowspan="1" colspan="1">' +
                '<input class="diff_class form-control form-control-sm width-auto" type="number" data-id="roomsOtb_' + count + '" data-initial_val="' + jsonObj[i].roomsOtb + '" name="roomsOtb_' + count + '" id="roomsOtb_'+count+'"  value="' + jsonObj[i].roomsOtb + '">' +
                '</td>' +
                '<td class="table-cell-revenue" rowspan="1" colspan="1">' +
                '<input class="diff_class form-control form-control-sm width-auto" data-id="revenue_' + count + '" data-initial_val="' + jsonObj[i].revenue + '" type="number" name="revenue_' + count + '" id="revenue_' + count + '" value="' + jsonObj[i].revenue + '">' +
                '</td>' +
                '<td class="table-cell-action" rowspan="1" colspan="1">' +
                '<button type="button" data-toggle="tooltip" data-placement="bottom" disabled="true" id="savebtn_' + count + '" data-original-title="" data-id="' + count + '" class="vao__component--btnsave vao__components--button btn btn-invert-primary btn-sm start-icon savebtn" style="display: initial;color:#6b6668;" data-trigger="hover focus" title="Save">' +
                '<div class="vao__components--button-startIcon">' +
                '<vao-icon id="__vao-appelement__112" cls="far fa-save">' +
                '<i class="vao__components--icon far fa-save"></i>' +
                '</vao-icon>' +
                '</div>' +
                '</button>' +
                '<button type="button" data-toggle="tooltip" disabled="true" id="diffbtn_' + count + '" data-placement="bottom" data-original-title="" data-id="' + jsonObj[i].stayDate + '" class="vao__component--btndiff vao__components--button btn btn-invert-secondary btn-sm start-icon " style="display: initial;color:#6b6668;" data-trigger="hover focus" title="Diff">' +
                '<div class="vao__components--button-startIcon">' +
                '<vao-icon id="__vao-appelement__112" cls="far fa-file-code">' +
                '<i class="vao__components--icon far fa-file-code"></i>' +
                '</vao-icon>' +
                '</div>' +
                '</button>' +
                '</td>' +
                '</tr>';

            $("#tbody__roomtype").append(tb);
            count++;
        }

        //on saveclick
        $(".vao__component--btnsave").on('click',function(e){

            var id = $(e.target).closest('button').data('id');

            var totalrooms = $('#revenue_'+id).val();

            $('.diff_class').data('initial_val', totalrooms);

            $('#savebtn_'+id).prop('disabled', true);
            $('#savebtn_'+id).css("color", "#6b6668");

            $('#diffbtn_'+id).prop('disabled', true);
            $('#diffbtn_'+id).css("color", "#6b6668");

            $("#revenue_"+id).removeClass("cls_modified");

        });

        //Diff Click
        $(".vao__component--btndiff").on('click',function(e){
            var id = $(e.target).closest('button').data('id');

            var totalrooms = $('#revenue'+id).val();
            var initial_val = $('#revenue'+id).data("initial_val");

            let body = '<li class="list-group-item">' +
                '<div style="font-weight:bold;margin-bottom:.3rem;">' +
                '</div>' +
                '<div style="margin-left:1rem;">' +
                '<div>' +
                '<div style="background: #FFEEF0;display: flex;">' +
                '<div style="margin-right:1rem;">-</div>' +
                '<div>'+initial_val+'</div>' +
                '</div>' +
                '<div style="background: #E6FFED;display: flex;">' +
                '<div style="margin-right:1rem;">+</div>' +
                '<div>'+totalrooms+'</div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</li>';


            let $title = `<h5>Total Rooms</h5>`;
            window.infinito.components.basicModal.render({
                title: $title,
                isTitleHtml: true,
                bodyElem: $(body),
                size:'lg'
            });
        });

        // $(".diff_class").change(function(e){
        $(".diff_class").bind('keyup mouseup', function (e) {
            var obj = $(e.target);
            var name_id = obj.data('id');
            let id = name_id.split('_');
            $('#diffbtn_'+id[1]).prop('disabled', true);
            $('#diffbtn_'+id[1]).css("color", "#e91e63");

            $('#savebtn_'+id[1]).prop('disabled', false);
            $('#savebtn_'+id[1]).css("color", "#2196f3");




            // var obj = $(e.target);
            // var name_id = obj.data('id');
            // name_id = name_id.split('_');
            // console.log(name_id);return false;
            //
            // if(obj.val() != obj.data('initial_val')){
            //     obj.addClass('cls_modified');
            //     $('#diffbtn_'+id).prop('disabled', false);
            //     $('#diffbtn_'+id).css("color", "#e91e63");
            //
            //     $('#savebtn_'+id).prop('disabled', false);
            //     $('#savebtn_'+id).css("color", "#2196f3");
            //
            // } else {
            //     obj.removeClass('cls_modified');
            //     $('#diffbtn_'+id).prop('disabled', true);
            //     $('#diffbtn_'+id).css("color", "#6b6668");
            //
            //     $('#savebtn_'+id).prop('disabled', false);
            //     $('#savebtn_'+id).css("color", "#6b6668");
            // }
        });

        let flag = this;
        var sal = document.getElementsByClassName("savebtn");
        for (var i = 0; i < sal.length; i++) {
            sal[i].addEventListener("click", function (e) {
                e.preventDefault();
                flag.savebtn(this);
            });
        }

    }

    savebtn(e){
        var id = $(e).attr("data-id");
        var roomsOtb = $("#roomsOtb_"+id).val();
        var revenue = $("#revenue_"+id).val();
        var stayDate = document.getElementById("stayDatedt_"+id).innerText;

        
        let dataObj = {
            "hotel_id": this.hotel.id,
            "stayDate": stayDate,
            "identity_id":infinito.vao.controller.storageHelper.getIdentitiesEmail(),
            "id":id,
            "roomsOtb":roomsOtb,
            "revenue":revenue,
            'aggregator': this.aggregator,
            'year': this.year,
            "op":"updateHotelForecastBudget",
            "serviceName":"forecastbudget.php"
        };
        sendRequest(dataObj,function(res){

        });
    }
    
    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('aggregator')
            || _changedProperties.has('year')
            || _changedProperties.has('hotel')
        ) {
            this.empty();
            if (this.canFill()) {
                this.fill();
            }
        }
        if (
            _changedProperties.has('isLoading')
            || _changedProperties.has('budgetValues')
        ) {
            this.reflowTable();
        }
    }

    render() {
        // const table = this.cache.get('table');

        return html`
<div class="vao__components--budgeFormTable"> 
    <div style="margin-bottom:1rem;">
        <vao-budgetforecast-latest-ts-badge .hotel="${this.hotel}" .budgetValues="${this.budgetValues}">
        </vao-budgetforecast-latest-ts-badge>
    </div>
    <div style="width: auto;overflow-x: scroll;">
        <table id="roomtypetable" class="vao__components--table-table table nowrap table-bordered table-md table-align-center table-fs-small table-static-left" style="text-align: center;"
               class="vao__components--table-table table nowrap table-md table-align-center table-fs-small table-static-left">
            <thead>
            <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">Date
            </th>
            <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                Rooms
            </th>
            <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">
                Revenue
            </th>
            <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">Action
            </th>
            </thead>
            <tbody id="tbody__roomtype">
            <tr role="row" class=""><!----><!---->
                <td class="table-cell-date" rowspan="1" colspan="1">
                    1
                </td><!---->
                <td class="table-cell-available" rowspan="1" colspan="1">
                    <input class="form-control form-control-sm width-auto" type="text" name="" min="" max=""
                           step="1" pattern="">
                </td><!---->
                <td class="table-cell-revenue" rowspan="1" colspan="1">
                    <input class="form-control form-control-sm width-auto" type="number" name="" min="" max=""
                           step="any" pattern="">
                </td><!---->
                <td class="table-cell-action" rowspan="1" colspan="1">
                    <button type="button" data-toggle="tooltip" data-placement="bottom" data-original-title=""
                            class="vao__components--button btn btn-invert-primary btn-sm start-icon"
                            style="display: initial;" data-trigger="hover focus" title="Save">
                        <div class="vao__components--button-startIcon">
                            <vao-icon id="__vao-appelement__112" cls="far fa-save">
                                <i class="vao__components--icon far fa-save"></i>
                            </vao-icon>
                        </div>
                    </button>
                </td>
            </tr>

            </tbody>
        </table>
        <p id="data_fetch_msg" style="text-align: center;">Fetching RoomTypes. Please Wait.</p>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetForecastFormTable = BudgetForecastFormTable;
customElements.define('vao-budgetforecast-form-table', BudgetForecastFormTable);
