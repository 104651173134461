import { AppElement, html, TemplateResult } from '../AppElement.js';
import Constants from "../Constants";

export default class PickupDetail extends AppElement {
    static get properties() {
        return {
            from: { type: String },
            to: { type: Object },
            change: { type: String },
            fromTs: { type: String },
            toTs: { type: String },
            tz: { type: String },
            shouldWrap: { type: String }, // "true" or "false"
            shouldHideChange: { type: String }, // "true" or "false"
        };
    }

    constructor(props = {}) {
        super();

        this.from = props.from;
        this.to = props.to;
        this.change = props.change;
        this.fromTs = props.fromTs;
        this.toTs = props.toTs;
        this.tz = props.tz;
        this.shouldWrap = props.shouldWrap;
        this.shouldHideChange = props.shouldHideChange;
    }

    reflow(props = {}) {
        this.from = props.from || this.from;
        this.to = props.to || this.to;
        this.change = props.change || this.change;
        this.fromTs = props.fromTs || this.fromTs;
        this.toTs = props.toTs || this.toTs;
        this.tz = props.tz || this.tz;
        this.shouldWrap = props.shouldWrap || this.shouldWrap;
        this.shouldHideChange = props.shouldHideChange || this.shouldHideChange;
    }

    formatTs(ts) {
        if (ts) {
            if (this.tz) {
                let formatted = window.infinito.vao.controller.dateHelper.convertTzStringTimezone(
                    ts,
                    this.tz,
                    'DD MMM hh:mma'
                );
                return html`<span title="Timezone: ${this.tz}">${formatted}</span>`
            } else {
                return ts;
            }
        } else {
            return Constants.MISSING_STR;
        }
    }

    formatVal(val) {
        if (val && typeof val === 'string' && val.length > 0) {
            // Allow string inputs.
            return val;
        } else if (val instanceof HTMLElement) {
            // Allow slot element inputs.
            return val;
        } else if (val instanceof TemplateResult) {
            // Allot lit-html inputs.
            return val;
        }
        return Constants.MISSING_STR;
    }

    render() {
        return html`
        <div class="vao__components--pickupDetail">
            <div class="vao__components--pickupDetail-fromToWrapper ${this.shouldWrap === 'true' ? 'wrapped' : ''}">
                <div class="vao__components--pickupDetail-fromTo">   
                    <div class="vao__components--pickupDetail-time badge badge-light">
                        ${this.formatTs(this.fromTs)}
                    </div>
                    <div class="vao__components--pickupDetail-val">${this.formatVal(this.from)}</div>             
                </div>
                <div class="vao__components--pickupDetail-divider">
                    <vao-icon cls="${Constants.ICONS.ARROW_RIGHT}"></vao-icon>
                </div>
                <div class="vao__components--pickupDetail-fromTo">
                    <div class="vao__components--pickupDetail-time badge badge-light">
                        ${this.formatTs(this.toTs)}
                    </div>
                    <div class="vao__components--pickupDetail-val">${this.formatVal(this.to)}</div>
                </div>
            </div>
            ${this.shouldHideChange !== 'true' && html`
                <div class="vao__components--pickupDetail-puWrapper">
                    <div class="text-muted">Pickup</div>
                    <div class="vao__components--pickupDetail-pu">${this.formatVal(this.change)}</div>
                </div>
            `}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PickupDetail = PickupDetail;
customElements.define('vao-pickup-detail', PickupDetail);
