import { AppElement, html } from '../AppElement.js';
import BudgetUtils from "./BudgetUtils";

export default class BudgetDiff extends AppElement {
    static get properties() {
        return {
            budgetValue: { type: Object },
        };
    }

    constructor(props = {}) {
        super();
        this.budgetValue = props.budgetValue || {};
    }

    reflow(props = {}) {
        this.budgetValue = props.budgetValue || this.budgetValue;
    }

    render() {
        const date = BudgetUtils.formatDate(this.budgetValue);
        const key = this.budgetValue.key;

        return html`
<div class="vao__components--budgetDiff">
    <p>${date}</p>
    <ul class="list-group" style="margin-bottom:1rem;">
        ${this.renderListGroupItems()}
    </ul>
    <small class="text-muted">${key}</small>
</div>
        `;
    }

    renderListGroupItems() {
        let listGroupItems = [];
        listGroupItems.push(this.renderListGroupItemForMetric('availableRooms'));
        listGroupItems.push(this.renderListGroupItemForMetric('roomsOtb'));
        listGroupItems.push(this.renderListGroupItemForMetric('revenue'));
        return listGroupItems;
    }

    renderListGroupItemForMetric(metric) {
        let then = this.budgetValue[metric] || undefined;
        let diff = (this.budgetValue.diff || new Map());
        let now;
        if (diff.has(metric)) {
            now = diff.get(metric);
        } else {
            now = then;
        }
        return this.renderListGroupItem(metric, then, now);
    }

    renderHasDiffText(then, now) {
        return html`
            <div>
                <div style="background: #FFEEF0;display: flex;">
                    <div style="margin-right:1rem;">-</div>
                    <div>${then}</div>
                </div>
                <div style="background: #E6FFED;display: flex;">
                    <div style="margin-right:1rem;">+</div>
                    <div>${now}</div>
                </div>
            </div>
        `;
    }

    renderListGroupItem(metric, then, now) {
        const hasChange = then !== now;
        const metricName = BudgetUtils.getNameForMetric(metric);

        return html`
        <li class="list-group-item">
            <div style="font-weight:bold;margin-bottom:.3rem;">
                ${metricName}
            </div>
            <div style="margin-left:1rem;">
                ${hasChange 
                ?
                    this.renderHasDiffText(then, now)
                : 
                    html`<div>${then || '-'}</div>`
                }
            </div>
        </li>`;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetDiff = BudgetDiff;
customElements.define('vao-budget-diff', BudgetDiff);
