import ContextTable from "./ContextTable";
import Constants from "../Constants";

export default class ContextTableSegment extends ContextTable {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            firstStayDate: { type: String },
            lastStayDate: { type: String },
            pickupSetting: { type: Number },
            metric: { type: String },
        };
    }

    constructor(props = {}) {
        super(props);
    }

    reflow(props = {}) {
        super.reflow(props);
    }

    getCategory() {
        return Constants.CTX_CATEGORIES.SEGMENT;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ContextTableSegment = ContextTableSegment;
customElements.define('vao-context-table-segment', ContextTableSegment);
