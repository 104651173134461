import { AppElement, html } from '../AppElement.js';
import {OPTION_DAY, OPTION_MONTH} from "./BudgetRangeSelect";

export default class BudgetControl extends AppElement {
    static get properties() {
        return {
            // props
            recordDate: { type: String },
            hotel: { type : Object },

            // state
            rangeValue: { type: String },
            year: { type: String },
        };
    }

    constructor(props = {}) {
        super();

        // props
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.hotel = props.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();

        // state
        this.rangeValue = props.rangeValue || OPTION_MONTH;
        this.year = props.year || window.infinito.vao.controller.dateHelper.format(this.recordDate, 'YYYY');
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        this.hotel = props.hotel || this.hotel;

        this.rangeValue = props.rangeValue || this.rangeValue;
        this.year = props.year || this.year;
    }

    onRangeChange(e) {
        this.rangeValue = e.currentTarget.value;
    }

    onChangeYear(e) {
        this.year = e.currentTarget.value;
    }

    render() {
        const baseYear = window.infinito.vao.controller.dateHelper.format(this.recordDate, 'YYYY');

        return html`
<div class="vao__components--budgetControl">
    <vao-budget-range-select 
        .value="${this.rangeValue}" 
        @change="${this.onRangeChange}"
    ></vao-budget-range-select>
    <br>
    <vao-budget-year-select 
        .baseYear="${baseYear}" 
        .year="${this.year}" 
        @change="${this.onChangeYear}"
    ></vao-budget-year-select>
    <br> 
    <vao-budget-form-table
        .aggregator="${this.rangeValue}"
        .year="${this.year}"
        .hotel="${this.hotel}"
    ></vao-budget-form-table>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetControl = BudgetControl;
customElements.define('vao-budget-control', BudgetControl);
