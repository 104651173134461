import { AppElement, html, TemplateResult } from '../AppElement.js';
import Constants from "../Constants";

export default class Select extends AppElement {
    static get properties() {
        return {
            value: { type: String },
            options: { type: Array },
            size: { type: String },
            isWidthAuto: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.value = props.value || undefined;
        this.options = props.options || [];
        this.size = props.size || undefined;
        this.onChange = props.onChange || undefined;
        this.isWidthAuto = super.reflowBool('true', props.isWidthAuto);
    }

    reflow(props = {}) {
        this.value = props.value || this.value;
        this.options = props.options || this.options;
        this.size = props.size || this.size;
        this.onChange = props.onChange || this.onChange;
        this.isWidthAuto = super.reflowBool(this.isWidthAuto, props.isWidthAuto);
    }

    _onChange(e) {
        this.value = e.currentTarget.value;
        if (typeof this.onChange === 'function') {
            this.onChange(e);
        }
    }

    getSizeClass() {
        switch (this.size) {
            case Constants.SIZES.SMALL:
                return 'form-control-sm';
            case Constants.SIZES.LARGE:
                return 'form-control-lg';
            default:
                return '';
        }
    }

    getSupplementClasses() {
        let classes = [];
        classes.push(this.getSizeClass());
        if (this.isWidthAuto === 'true') {
            classes.push('width-auto');
        }
        return classes.join(' ');
    }

    render() {
        return html`
<select @change="${this._onChange}" class="form-control ${this.getSupplementClasses()}" value="${this.value}">
    ${this.options.map(option => {
        if (option instanceof HTMLElement || option instanceof TemplateResult) {
            return option;
        } else if (typeof option === 'string') {
            return html`<option>${option}</option>`    
        } else {
            // Assume option is a raw object.
            let isSelected = option.value === this.value;
            return html`<option value="${option.value}" ?selected="${isSelected}">${option.text}</option>`
        }
    })}
</select>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Select = Select;
customElements.define('vao-select', Select);
