import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryPositioning extends AppElement {
    static get properties() {
        return {
            rate: { type: String },
            rateToCompVariance: { type: String },
            rateRank: { type: String },
            rateRankLength: { type: String },
            restrictionPercent: { type: String },
            typicalPriceRank: { type: String },
            dow: { type: String },
            leadTime: { type: Number }
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.rate = Constants.MISSING_STR;
        this.rateToCompVariance = Constants.MISSING_STR;
        this.rateRank = Constants.MISSING_STR;
        this.rateRankLength = Constants.MISSING_STR;
        this.restrictionPercent = Constants.MISSING_STR;
        this.typicalPriceRank = Constants.MISSING_STR;
        this.dow = Constants.MISSING_STR;
        this.leadTime = Constants.MISSING_STR;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.rate,
                    modelBiStats.fields.compAverageRate,
                    modelBiStats.fields.priceRank,
                    modelBiStats.fields.compPriceRanks,
                    modelBiStats.fields.compPrices,
                    modelBiStats.fields.priceRankLeadSiblingPace
                ]
            }),
            (data, procData) => {
                let rate = ((procData.rate || {})[this.stayDate] || {}).value || null;
                let compRate = ((procData.compAverageRate || {})[this.stayDate] || {}).value || null;
                let priceRank = ((procData.priceRank || {})[this.stayDate] || {}).value || null;
                let compPriceRanks = ((procData.compPriceRanks || {})[this.stayDate] || {}).value || null;
                let compPrices = ((procData.compPrices || {})[this.stayDate] || {}).value || null;
                let priceRankLeadSiblingPace =
                    ((procData.priceRankLeadSiblingPace || {})[this.stayDate] || {}).value || null;

                this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(this.stayDate);
                this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    this.stayDate,
                    this.recordDate
                );

                if (typeof compPriceRanks === 'string' && compPriceRanks !== 'no data') {
                    compPriceRanks = JSON.parse(compPriceRanks);
                }
                if (typeof compPrices === 'string' && compPrices !== 'no data') {
                    compPrices = JSON.parse(compPrices);
                }

                if ($.isNumeric(rate)) {
                    this.rate = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        rate,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    );
                }

                if ($.isNumeric(rate) && $.isNumeric(compRate)) {
                    this.rateToCompVariance = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        (rate - compRate),
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.rateToCompVariance = Constants.MISSING_STR;
                }

                if ($.isNumeric(priceRank)) {
                    this.rateRank = priceRank;
                } else {
                    this.rateRank = Constants.MISSING_STR;
                }

                if (typeof compPriceRanks === 'object') {
                    this.rateRankLength = Object.keys(compPriceRanks).length + 1; // +1 for me
                } else {
                    this.rateRankLength = Constants.MISSING_STR;
                }

                if (typeof compPrices === 'object') {
                    let compPricesOnly = Object.values(compPrices);
                    let length = compPricesOnly.length;
                    let numNoRate = 0;
                    compPricesOnly.forEach((compPrice) => {
                        let fCompPrice = parseFloat(compPrice);
                        if (!$.isNumeric(fCompPrice) || fCompPrice <= 0) {
                            numNoRate++;
                        }
                    });
                    if (length === 0) {
                        this.restrictionPercent = '0%';
                    } else {
                        let percent = Math.floor((numNoRate / length) * 100);
                        this.restrictionPercent = percent + '%';
                    }
                } else {
                    this.restrictionPercent = Constants.MISSING_STR;
                }

                if (
                    priceRankLeadSiblingPace
                    && typeof priceRankLeadSiblingPace === 'object'
                    && 'T0' in priceRankLeadSiblingPace
                    && 'setRank' in priceRankLeadSiblingPace.T0
                    && $.isNumeric(priceRankLeadSiblingPace.T0.setRank)
                ) {
                    let setLength = parseInt(priceRankLeadSiblingPace.T0.setLength);
                    let setRankSum = 0;
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T0.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T1.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T2.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T3.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T4.value || 0) || 0);
                    if (setLength) {
                        let avg = +parseFloat(setRankSum / setLength).toFixed(0); // + to drop 0's
                        this.typicalPriceRank = avg + '';
                    } else {
                        this.typicalPriceRank = Constants.MISSING_STR;
                    }
                } else {
                    this.typicalPriceRank = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    renderNotSellingBlurb() {
        return storyHtml`We are not selling.`;
    }

    renderSellingBlurb() {
        const rate = new StoryText(this.rate, true, storyTextTypes.TYPE_NEUTRAL);
        const rateToCompVariance = new StoryText(
            this.rateToCompVariance,
            true,
            storyTextTypes.TYPE_MONETARY,
            this.locale
        );
        const rateRank = new StoryText(this.rateRank, true, storyTextTypes.TYPE_NEUTRAL);
        const rateRankLength = new StoryText(this.rateRankLength, true, storyTextTypes.TYPE_NEUTRAL);
        return storyHtml`
            We are currently pricing ${rate} which is ${rateToCompVariance} different to our comp set average. This 
            puts us at rank ${rateRank} out of ${rateRankLength} properties.
        `;
    }

    renderAvgPosition() {
        const leadTime = new StoryText(this.leadTime, true, storyTextTypes.TYPE_NEUTRAL);
        const dow = new StoryText(this.dow, true, storyTextTypes.TYPE_NEUTRAL);
        const typicalPriceRank = new StoryText(this.typicalPriceRank, true, storyTextTypes.TYPE_NEUTRAL);
        let normality = Constants.MISSING_STR;
        if ($.isNumeric(this.rateRank) && $.isNumeric(this.typicalPriceRank)) {
            const diff = this.rateRank - this.typicalPriceRank;
            if (diff <= 1 && diff >= -1) {
                normality = new StoryText('normal', true, storyTextTypes.TYPE_NEUTRAL);
            } else if (diff > 0) {
                normality = new StoryText('higher than normal', true, storyTextTypes.TYPE_POSITIVE);
            } else {
                normality = new StoryText('lower than normal', true, storyTextTypes.TYPE_NEGATIVE);
            }
        }
        return storyHtml`
            Our average position over the last 5 weeks ${leadTime} days out for a ${dow} is ${typicalPriceRank}. Our 
            position is considered ${normality}.
        `;
    }

    render() {
        return html`
<div class="vao__components--storyPositioning">
    ${storyHtml`
    <p>
        ${(parseInt(this.rateRank) === 0) ? this.renderNotSellingBlurb(): this.renderSellingBlurb()}
    </p>
    ${(this.typicalPriceRank !== Constants.MISSING_STR) ? storyHtml`<p>${this.renderAvgPosition()}</p>` : ''}
    <p>
        ${new StoryText(this.restrictionPercent, true, storyTextTypes.TYPE_NEUTRAL)} 
        of our competitors have either a restriction in place, or are sold out.
    </p>
    `}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryPositioning = StoryPositioning;
customElements.define('vao-story-positioning', StoryPositioning);
