import { AppElement, html } from '../AppElement.js';
import TableNoDataRow from '../table/TableNoDataRow';
import Table from '../table/Table';
import TableCell, { tableCellTypes } from '../table/TableCell';
import TableColumn from '../table/TableColumn';
import TableRow from '../table/TableRow';
import { getIdentityDependantTableStateKey } from '../TableUtils';

const cellFieldMap = {
    month: 'month',
    occ: 'occ',
    rms: 'rms',
    adr: 'adr',
    rev: 'rev',
    // pkuprms:'pkuprms',
    // pkupadr:'pkupadr',
    // pkuprev:'pkuprev',
    occfc: 'occfc',
    roomsfc: 'roomsfc',
    adrfc: 'adrfc',
    revfc: 'revfc',
    occBu: 'occBu',
    roomsBu: 'roomsBu',
    adrBu: 'adrBu',
    revBu: 'revBu',
    occaLy: 'occaLy',
    roomsaLy: 'roomsaLy',
    adraLy: 'adraLy',
    revaLy: 'revaLy',
    occstLy: 'occstLy',
    roomsstLy: 'roomsstLy',
    adrstLy: 'adrstLy',
    revstLy: 'revstLy',
    RevStlyRev: 'RevStlyRev',
    AdrStlyAdr: 'AdrStlyAdr',
    OccStlyOcc: 'OccStlyOcc',
    DeltaFcBuOcc: 'DeltaFcBuOcc',
    DeltaFcBuRooms: 'DeltaFcBuRooms',
    DeltaFcBuAdr: 'DeltaFcBuAdr',
    DeltaFcBuRev: 'DeltaFcBuRev',
    DeltaLyOtbRooms: 'DeltaLyOtbRooms',
    DeltaLyOtbOcc: 'DeltaLyOtbOcc',
    DeltaLyOtbAdr: 'DeltaLyOtbAdr',
    DeltaLyOtbRev: 'DeltaLyOtbRev'
};

export default class AnnualTileSingle extends AppElement {
    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();
        this.cache = {};
        this.cellFieldMap = cellFieldMap;
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
        this.randomNumberId = Math.round(Math.random() * 100000);
        this.CellColor = '#EFEFEF';
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        if ($.isNumeric(props.pickupSetting)) {
            this.pickupSetting = props.pickupSetting;
        }
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.month,
                    value: 'Month',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occ,
                    value: 'Occupancy',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.rms,
                    value: 'Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adr,
                    value: 'ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.rev,
                    value: 'Revenue (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occfc,
                    value: 'FC Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsfc,
                    value: 'FC Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrfc,
                    value: 'FC ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revfc,
                    value: 'FC Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occBu,
                    value: 'B Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsBu,
                    value: 'B Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrBu,
                    value: 'B ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revBu,
                    value: 'B Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaFcBuOcc,
                    value: 'Δ Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaFcBuRooms,
                    value: 'Δ Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaFcBuAdr,
                    value: 'Δ ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaFcBuRev,
                    value: 'Δ Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occstLy,
                    value: 'STLY Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsstLy,
                    value: 'STLY Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrstLy,
                    value: 'STLY ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revstLy,
                    value: 'STLY Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.RevStlyRev,
                    value: 'Δ STLY Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.AdrStlyAdr,
                    value: 'Δ STLY ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.OccStlyOcc,
                    value: 'Δ STLY Occ',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occaLy,
                    value: 'LY Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsaLy,
                    value: 'LY Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adraLy,
                    value: 'LY ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revaLy,
                    value: 'LY Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaLyOtbOcc,
                    value: 'Δ LY Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaLyOtbRooms,
                    value: 'Δ LY Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaLyOtbAdr,
                    value: 'Δ LY ADR (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.DeltaLyOtbRev,
                    value: 'Δ LY Rev (' + infinito.vao.controller.moneyHelper.getCurrencySymbol() + ')',
                    type: tableCellTypes.TH
                })
            )
        ];
        this.cache.cols = cols;
        return cols;
    }
    getTable() {
        var HotelName = window.infinito.vao.controller.storageHelper.getSelectedHotel();
        var Hotel = HotelName.name.replaceAll(' ', '-');
        var DownloadFileName = '[' + Hotel + ']' + '_yearly' + this.getDateInYYYYMMDDFormat();
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            const stateKey = getIdentityDependantTableStateKey(
                `AnnualTileSingle_${this.getTableStateKey()}`
            );
            let table = new Table({
                stateKey: stateKey,
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ],
                tableOpts: {
                    tableExportOpts: {
                        filename: DownloadFileName
                    }
                }
            });
            this.cache.table = table;
        }

        return this.cache.table;
    }
    getDateInYYYYMMDDFormat() {
        var x = new Date();
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length == 1) && (d = '0' + d);
        (m.length == 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }
    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows
            });
        }

        return table;
    }
    domTheTable(table) {
        $('#' + this.id).find('.vao__components--AnnualTileSingle-table').empty().append(table);
    }
    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }
    makeTableRow(title, UseSpecialColorForCell) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(title)) {
            return this.cache.rowMap.get(title);
        }

        const row = new TableRow([
            new TableCell({
                field: cellFieldMap.month,
                cellcolor: UseSpecialColorForCell,
                value: html`<span style="font-weight:bold;">${title}</span>`
            }),
            new TableCell({
                field: cellFieldMap.occ,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.rms,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adr,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.rev,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occfc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsfc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adrfc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revfc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occBu,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsBu,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adrBu,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revBu,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaFcBuOcc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaFcBuRooms,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaFcBuAdr,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaFcBuRev,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occstLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsstLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adrstLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revstLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.RevStlyRev,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.AdrStlyAdr,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.OccStlyOcc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occaLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsaLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adraLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revaLy,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaLyOtbOcc,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaLyOtbRooms,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaLyOtbAdr,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.DeltaLyOtbRev,
                value: null,
                cellcolor: UseSpecialColorForCell,
                isLoading: true
            })
        ]);
        this.cache.rowMap.set(title, row);
        return row;
    }
    getCurrentFinancialYear(strDocDate) {
        /** Here We need Financial Year as monthw start so we have given 9 number hardcoded i.e. October and End month will be September **/
        var startYear = '';
        var endYear = '';
        var docDate = new Date(strDocDate);
        if ((docDate.getMonth() + 1) <= 9) {
            startYear = docDate.getFullYear() - 1;
            endYear = docDate.getFullYear();
        } else {
            startYear = docDate.getFullYear();
            endYear = docDate.getFullYear() + 1;
        }
        return { startDate: startYear + '-10', endDate: endYear + '-09' };
    }
    dateRange(startDate, endDate) {
        var start = startDate.split('-');
        var end = endDate.split('-');
        var startYear = parseInt(start[0]);
        var endYear = parseInt(end[0]);
        var dates = [];

        for (var i = startYear; i <= endYear; i++) {
            var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayMonth = month < 10 ? '0' + month : month;
                dates.push([i, displayMonth].join('-'));
            }
        }
        return dates;
    }
    makeTableRows(prop = 0) {
        let rows = [];
        var datesarr = [];
        if (prop == 1) {
            datesarr = this.dateRange(document.getElementById('start_date').value, document.getElementById('end_date').value);
        } else {
            let financialyear = this.getCurrentFinancialYear(this.recordDate);
            datesarr = this.dateRange(financialyear.startDate, financialyear.endDate);
        }
        const monthNames = ['NA', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        let flag = this;
        var UseSpecialColorForCell = this.CellColor;
        $.each(datesarr, function (key, val) {
            /* Here we compare dates as yyyy-mm == yyyy-mm and if match found we change the cellcolor to White. */
            let DateObj1 = new Date(val+'-01');
            let DateObj2 = new Date(flag.recordDate);
            if (DateObj1 > DateObj2) {
                UseSpecialColorForCell = 'White';
            }
            rows.push(flag.makeTableRow(val.substr(0, 4) + ' ' + monthNames[val.substr(5, 2) * 1], UseSpecialColorForCell));
        });
        rows.push(this.makeTableRow("Total",UseSpecialColorForCell));
        return rows;
    }
    objectify(input) {
        if (input && typeof input === 'object') {
            return input;
        }
        if (input && typeof input === 'string' && input.includes('{')) {
            return JSON.parse(input);
        }
        return {};
    }
    fill() {
        // Expect child class overrides.
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        this.fill();
    }
    render() {
        return html`
        <div class="vao__components--AnnualTileSingle">
            <div class="vao__components--AnnualTileSingle-table"></div>
           
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.AnnualTileSingle = AnnualTileSingle;
customElements.define('vao-annual-tile-single', AnnualTileSingle);
