export class Throttler {

    constructor(concurrentLimit, timeout = 0, maxExecutionMs = 10000) {
        this.queue = [];
        if (!Number.isFinite(concurrentLimit) || concurrentLimit <= 0) {
            this.concurrentLimit = 3;
        } else {
            this.concurrentLimit = concurrentLimit;
        }
        if (!Number.isFinite(timeout) || timeout < 0) {
            this.timeout = 0;
        } else {
            this.timeout = timeout;
        }
        if (!Number.isFinite(maxExecutionMs) || maxExecutionMs <= 0) {
            this.maxExecutionMs = 10000;
        } else {
            this.maxExecutionMs = maxExecutionMs;
        }
        this.state = {
            active: 0
        }
    }

    perform() {
        if (this.queue.length === 0 || this.state.active === this.concurrentLimit) {
            return;
        }

        let item = this.queue.shift();
        this.state.active = this.state.active + 1;

        new Promise((resolve, reject) => {
            let timeSafety = setTimeout(() => {
                console.warn('Throttler hit maxExecutionMs.');
                reject();
            }, this.maxExecutionMs);
            let _resolve = (res) => {
                clearInterval(timeSafety);
                resolve(res);
            };
            let _reject = (rej) => {
                clearInterval(timeSafety);
                reject(rej);
            };
            item.executor(_resolve, _reject);
        }).then(() => {}).catch(() => {}).finally(() => {
            this.state.active = this.state.active - 1;
            // in-case this.emptyQueue() called
            if (this.state.active < 0) {
                this.state.active = 0;
            }
            setTimeout(() => {
                this.perform();
            }, this.timeout);
        });
    }

    queueUp(executor, precedence = 0) {
        if (typeof executor !== 'function') {
            throw 'Throttler expects to queue a Promise executor function.';
        }

        if (!Number.isFinite(precedence) || precedence < 0) {
            precedence = 0;
        }

        this.queue.push({
            executor: executor,
            precedence: precedence
        });

        this.queue.sort((a, b) => {
            return a.precedence - b.precedence;
        });

        this.perform();
    }

    emptyQueue() {
        this.queue = [];
        this.state.active = 0;
    }

}

window.vao.classes = window.vao.classes || {};
window.vao.classes.Throttler = Throttler;