import { AppElement, html } from '../AppElement.js';

export default class TopTenPickup extends AppElement {

    static get properties() {
        return {
            populateTopTenPickupString: {type: String},
        };
    }

    constructor() {
        super();
        this.populateTopTenPickupString = '';

    }

    // Section 1 - TopTenPickupHTML -

    populateTopTenPickup() {
        let populateTopTenPickupHTML = html `
            <div class="col-sm-12 col-md-12 col-lg-12 biggy-small">
                <section id="vao__flash--topTen" class="no-bor-small">

                    <div class="vao__flash--topTen-context">
                        <ul class="nav nav-pills" id="vao__flash--topTenTabs" role="tablist">
                            <li class="nav-item vao__flash--topTen-rmsButton">
                                <a class="nav-link active" id="otb-tab" data-toggle="pill" href="#otb-content"
                                   role="tab" aria-controls="1a" aria-selected="true"><i class="fas fa-bed"></i></a>
                            </li>
                            <li class="nav-item vao__flash--topTen-adrButton">
                                <a class="nav-link" id="adr-tab" data-toggle="pill" href="#adr-content" role="tab"
                                   aria-controls="adr-content" aria-selected="false"><i
                                        class="fas fa-tachometer-alt"></i></a>
                            </li>
                            <li class="nav-item vao__flash--topTen-revButton">
                                <a class="nav-link" id="rev-tab" data-toggle="pill" href="#rev-content" role="tab"
                                   aria-controls="rev-content" aria-selected="false"><i
                                        class="fas fa-money-bill-alt"></i></a>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content" id="vao__flash--topTen-tabContent">
                        <div class="tab-pane fade show active" id="otb-content" role="tabpanel"
                             aria-labelledby="otb-tab">
                            <div id="vao__flash--topTen-rooms-table-from-component"></div>
                        </div>
                        <div class="tab-pane fade" id="adr-content" role="tabpanel" aria-labelledby="adr-tab">
                            <div id="vao__flash--topTen-adr-table-from-component"></div>
                        </div>
                        <div class="tab-pane fade" id="rev-content" role="tabpanel" aria-labelledby="rev-tab">
                            <div id="vao__flash--topTen-revenue-table-from-component"></div>
                        </div>
                    </div>
                </section>
            </div>
        `;

        return populateTopTenPickupHTML;

    }

    // Data Related Functions -

    getFlashDateStr() {
        return infinito.vao.controller.dateHelper.getCurDateStr();
    }

    retrieveHotelId() {
        return infinito.vao.controller.storageHelper.getSelectedHotelId();
    }

    getPickupOffset() {
        return infinito.vao.controller.storageHelper.getPickupOffset();
    }


    // Getting Data For Section 1 -

    dataTopTenPickup() {

        // DOM Selectors -
        let pageSections = {
            topTen: {
                domSelectors: {
                    rmsButton: '.vao__flash--topTen-rmsButton',
                    adrButton: '.vao__flash--topTen-adrButton',
                    revButton: '.vao__flash--topTen-revButton',
                    rmsPickupTable: '#vao__flash--topTen-rooms-table-from-component',
                    adrPickupTable: '#vao__flash--topTen-adr-table-from-component',
                    revenuePickupTable: '#vao__flash--topTen-revenue-table-from-component'
                }
            }
        };

        var pageSection = pageSections.topTen;
        var hasOpenedAdr = false;
        var hasOpenedRev = false;

        infinito.components.topTenRoomsOtbPickupsTable.init(
            pageSections.topTen.domSelectors.rmsPickupTable, infinito.vao.controller.storageHelper.getPickupOffset()
        );

        $('.vao__flash--topTen-adrButton').on('click', function onClickAdrTopTenButton() {
            if (hasOpenedAdr !== false) {
                return;
            }
            hasOpenedAdr = true;
            infinito.components.topTenAdrPickupsTable.init(
                pageSections.topTen.domSelectors.adrPickupTable, infinito.vao.controller.storageHelper.getPickupOffset()
            );
        });

        $('.vao__flash--topTen-revButton').on('click', function onClickRevTopTenButton() {
            if (hasOpenedRev !== false) {
                return;
            }
            hasOpenedRev = true;
            infinito.components.topTenRevenuePickupsTable.init(
                pageSections.topTen.domSelectors.revenuePickupTable, infinito.vao.controller.storageHelper.getPickupOffset()
            );
        });

    }

    render() {
        this.populateTopTenPickupString = this.populateTopTenPickup();

        return html`
        <div>${this.populateTopTenPickupString }</div>
        `;

    }

    firstUpdated(changedProperties) {
        this.dataTopTenPickup();
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TopTenPickup = TopTenPickup;
customElements.define('vao-page-top-ten-pickup-modal', TopTenPickup);