import { AppElement, html } from '../AppElement.js';

export default class Spinner extends AppElement {
    static get properties() {
        return {
            color: { type: String },
            wrapperStyle: { type: String },
            compStyle: { type: String },
        };
    }

    render() {
        return html`
<div class="vao__components--spinner" style="${this.compStyle || ''}">
    <div class="spinner-border ${this.color || 'text-primary'}" role="status" style="${this.wrapperStyle || ''}">
        <span class="sr-only">Loading...</span>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Spinner = Spinner;
customElements.define('vao-spinner', Spinner);
