import { AppElement, html } from '../AppElement.js';
import TableNoDataRow from '../table/TableNoDataRow';
import Table from '../table/Table';
import TableCell, { tableCellTypes } from '../table/TableCell';
import TableColumn from '../table/TableColumn';
import TableRow from '../table/TableRow';
import { getIdentityDependantTableStateKey } from '../TableUtils';
const cellFieldMap = {
    month: 'month',
    occ: 'occ',
    rms: 'rms',
    adr: 'adr',
    rev: 'rev',
    pkuprms: 'pkuprms',
    pkupadr: 'pkupadr',
    pkuprev: 'pkuprev'
};

export default class PickupPerMonth extends AppElement {
    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();
        this.cache = {};
        this.cellFieldMap = cellFieldMap;

        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        if ($.isNumeric(props.pickupSetting)) {
            this.pickupSetting = props.pickupSetting;
        }
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.month,
                    value: 'Month',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occ,
                    value: 'Occupancy',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.rms,
                    value: 'Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adr,
                    value: 'ADR',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.rev,
                    value: 'Revenue',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.pkuprms,
                    value: 'PU Rooms',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.pkupadr,
                    value: 'PU ADR',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.pkuprev,
                    value: 'PU Revenue',
                    type: tableCellTypes.TH
                })
            )
        ];
        this.cache.cols = cols;
        return cols;
    }

    getTable() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            const stateKey = getIdentityDependantTableStateKey(
                `PickupPerMonth_${this.getTableStateKey()}`
            );
            let table = new Table({
                stateKey: stateKey,
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ]
            });
            this.cache.table = table;
        }

        return this.cache.table;
    }

    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows
            });
        }

        return table;
    }

    domTheTable(table) {
        $('#' + this.id).find('.vao__components--pickupPerMonth-table').empty().append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    makeTableRow(title) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(title)) {
            return this.cache.rowMap.get(title);
        }

        const row = new TableRow([
            new TableCell({
                field: cellFieldMap.month,
                value: title
            }),
            new TableCell({
                field: cellFieldMap.occ,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.rms,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adr,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.rev,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.pkuprms,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.pkupadr,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.pkuprev,
                value: null,
                isLoading: true
            })

        ]);
        this.cache.rowMap.set(title, row);
        return row;
    }

    forEachMonthKey(callback) {
        [...Array(12).keys()].forEach((i => {
            let month = window.infinito.vao.controller.dateHelper.addMonthsToDateString(this.recordDate, i);
            let monthStr = window.infinito.vao.controller.dateHelper.getShortYearMonStr(month);
            let yearMonKey = window.infinito.vao.controller.dateHelper.getYearMonthDateStringFrom(month);
            callback(monthStr, yearMonKey);
        }));
    }

    makeTableRows() {
        let rows = [];
        const mtdRow = this.makeTableRow('MTD');
        rows.push(mtdRow);
        this.forEachMonthKey(monthKey => {
            rows.push(this.makeTableRow(monthKey));
        });
        return rows;
    }

    objectify(input) {
        if (input && typeof input === 'object') {
            return input;
        }
        if (input && typeof input === 'string' && input.includes('{')) {
            return JSON.parse(input);
        }
        return {};
    }

    fill() {
        // Expect child class overrides.
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
    }

    updated(changedProperties) {
        super.updated(changedProperties);
        this.fill();
    }

    render() {
        return html`
        <div class="vao__components--pickupPerMonth">
            <div class="vao__components--pickupPerMonth-title">
                <h6 class="vao__components--flashGroup-bodySectionTitle">Pickup Per Month</h6>
            </div>
            <div class="vao__components--pickupPerMonth-table"></div>
           
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PickupPerMonth = PickupPerMonth;
customElements.define('vao-pickup-per-month', PickupPerMonth);
