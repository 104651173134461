import { AppElement, html } from '../AppElement.js';

export default class BudgetForecastLatestTsBadge extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            budgetValues: { type: Array }
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel || {};
        this.budgetValues = props.budgetValues || [];
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.budgetValues = props.budgetValues || this.budgetValues;
    }

    getTs() {
        let latestTs = null;
        this.budgetValues.forEach(budgetValue => {
            const ts = budgetValue.latestTs; // "Y-m-d H:i:s"
            if (!ts || typeof ts !== 'string' || ts.length === 0) {
                return;
            }
            if (latestTs === null || ts > latestTs) {
                latestTs = ts;
            }
        });
        if (!latestTs) {
            return null;
        }
        const utcTz = latestTs.replace(' ', 'T') + '+0000'; // "Y-m-dTH:i:sZ"
        if (
            this.hotel
            && this.hotel.timezone
        ) {
            return window.infinito.vao.controller.dateHelper.convertTzStringTimezone(
                utcTz,
                this.hotel.timezone,
                'DD MMM hh:mma ZZ'
            );
        } else {
            return utcTz;
        }
    }

    render() {
        let ts = this.getTs();
        if (ts) {
            ts = 'Last Updated: ' + ts;
            return html`
<span class="vao__components--budgetLatestTsBadge badge badge-primary">
    ${ts}
</span>
            `;
        } else {
            return html`
<span class="vao__components--budgetLatestTsBadge"></span>
            `;
        }
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetForecastLatestTsBadge = BudgetForecastLatestTsBadge;
customElements.define('vao-budgetforecast-latest-ts-badge', BudgetForecastLatestTsBadge);
