import Constants from "../Constants";
import MetricBreakdownTable, {cellFieldMap} from "./MetricBreakdownTable";

export default class SegmentBreakdownTable extends MetricBreakdownTable {

    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            stayDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super({...props, cellFieldsToSkip: [cellFieldMap.occ]});
    }

    reflow(props = {}) {
        super.reflow(props);
    }

    canFill() {
        return (
            this.hasFirstUpdated
            && this.hotel
            && typeof this.hotel === 'object'
            && ('id' in this.hotel)
            && window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            && window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        );
    }

    fill() {
        if (!this.canFill()) {
            super.empty();
            return;
        }

        let table = super.reflowTable([], true);
        super.domTheTable(table);

        let biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    biStatistics.fields.roomsOtbCtx,
                    biStatistics.fields.roomsOtbCtxPickup,
                    biStatistics.fields.revenueCtx,
                    biStatistics.fields.revenueCtxPickup,
                    biStatistics.fields.adrCtx,
                    biStatistics.fields.adrCtxPickup
                ],
                recordMatcher: biStatistics.recordMatchers.marketSegment,
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                const _procData = (procData || {});
                const roomsOtbCtx = ((_procData.roomsOtbCtx || {})[this.stayDate] || {}).value;
                const roomsOtbCtxPickup = ((_procData.roomsOtbCtxPickup || {})[this.stayDate] || {}).value;
                const revenueCtx = ((_procData.revenueCtx || {})[this.stayDate] || {}).value;
                const revenueCtxPickup = ((_procData.revenueCtxPickup || {})[this.stayDate] || {}).value;
                const adrCtx = ((_procData.adrCtx || {})[this.stayDate] || {}).value;
                const adrCtxPickup = ((_procData.adrCtxPickup || {})[this.stayDate] || {}).value;
                let foundAnyData = false;

                if (super.fillRoomsOtbCells(
                    [
                        Constants.CTX_CATEGORIES.SEGMENT,
                        Constants.CTX_CATEGORIES.PROPERTY
                    ],
                    roomsOtbCtx,
                    roomsOtbCtxPickup
                )) {
                    foundAnyData = true;
                }

                if (super.fillRevenueCells(
                    [
                        Constants.CTX_CATEGORIES.SEGMENT,
                        Constants.CTX_CATEGORIES.PROPERTY
                    ],
                    revenueCtx,
                    revenueCtxPickup
                )) {
                    foundAnyData = true;
                }

                if (super.fillADRCells(
                    [
                        Constants.CTX_CATEGORIES.SEGMENT,
                        Constants.CTX_CATEGORIES.PROPERTY
                    ],
                    adrCtx,
                    adrCtxPickup
                )) {
                    foundAnyData = true;
                }

                if (!foundAnyData) {
                    super.empty();
                } else {
                    super.reflowAllTableRows();
                }
            }
        )
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.SegmentBreakdownTable = SegmentBreakdownTable;
customElements.define('vao-segment-breakdown-table', SegmentBreakdownTable);
