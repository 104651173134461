import {AppElement, html} from '../AppElement.js';
import FlashGroupHotelExpandCard from "./FlashGroupHotelExpandCard";
import FlashGroupGroupExpandCard from "./FlashGroupGroupExpandCard";

export default class FlashGroup extends AppElement {
    static get properties() {
        return {
            hotelGroup: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super();
        this.hotelGroup = props.hotelGroup;
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = props.pickupSetting || window.infinito.vao.controller.storageHelper.getPickupOffset();
    }

    renderCards() {
        let cards = [];

        // Hotel cards.
        this.hotelGroup.hotels.forEach(hotel => {
            let card = new FlashGroupHotelExpandCard({
                hotel,
                recordDate: this.recordDate,
                pickupSetting: this.pickupSetting
            });
            cards.push(card);
        });

        // Group Card.
        let card = new FlashGroupGroupExpandCard({
            hotelGroup: this.hotelGroup,
            recordDate: this.recordDate,
            pickupSetting: this.pickupSetting
        });

        let ncards = [];
        ncards.push(card);
        for(var i=0;i<cards.length;i++){
            ncards.push(cards[i]);
        }
        // cards.push(card);

        return ncards;
    }

    render() {
        return html`
        <div class="vao__components--flashGroup">
            <div class="vao__components--flashGroup-cards">
                ${this.renderCards()}
            </div>       
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.FlashGroup = FlashGroup;
customElements.define('vao-flash-group', FlashGroup);
