import Constants from "./Constants";
import {objectify} from "./Utils";

export function isValidNumericStatistic(statistic) {
    return (
        statistic
        && typeof statistic === 'object'
        && $.isNumeric(statistic.value)
    )
}

export function isValidCtxStatisticAndHasData(ctxStatistic, recordMatcher) {
    const hasMatchData = () => {
        let key = '';
        if (recordMatcher === window.infinito.vao.model.biStatistics.recordMatchers.roomType) {
            key = Constants.CTX_CATEGORIES.ROOMTYPE;
        } else if (recordMatcher === window.infinito.vao.model.biStatistics.recordMatchers.marketSegment) {
            key = Constants.CTX_CATEGORIES.SEGMENT;
        }
        return Array.isArray(ctxStatistic.value[key]) && ctxStatistic.value[key].length > 0;
    };

    return (
        ctxStatistic
        && typeof ctxStatistic === 'object'
        && ('receivedTs' in ctxStatistic)
        && ctxStatistic.value
        && typeof ctxStatistic.value === 'object'
        && hasMatchData()
    );
}

export function findMatchingCtxMetricForCategoryByCtxMetricForCtxValues(
    contextCategory,
    ctxMetricNow,
    offsetStatValue
) {
    if (
        offsetStatValue
        && typeof offsetStatValue === 'object'
        && contextCategory in offsetStatValue
    ) {
        const match = offsetStatValue[contextCategory].find((ctxMetric) => {
            return ctxMetric.ctx === ctxMetricNow.ctx;
        });
        if (match) {
            return match;
        }
    }
    return null;
}

export function findCtxStatInProcDataByFieldByStayDate(procData, field, stayDate) {
    const val = ((procData[field] || {})[stayDate] || {});
    return objectify(val);
}

export function findCtxStatValueInProcDataByFieldByStayDate(procData, field, stayDate) {
    const val = ((procData[field] || {})[stayDate] || {}).value;
    return objectify(val);
}

export function isFutureLeaderStatistic(statistic) {
    return (
        statistic
        && typeof statistic === 'object'
        && statistic.value
        && typeof statistic.value === 'object'
        && 'leaders' in statistic.value
        && Array.isArray(statistic.value.leaders)
        && 'futureLeaders' in statistic.value
        && Array.isArray(statistic.value.futureLeaders)
    );
}

export function findMatchingFutureLeaderForStayDate(statistic, stayDate) {
    if (!isFutureLeaderStatistic(statistic)) {
        throw 'Invalid statistic';
    }
    return statistic.value.futureLeaders.find(futureLeader => futureLeader.stayDate === stayDate);
}

export function getLeaderToFutureLeaderDifferenceCounts(statistic) {
    if (!isFutureLeaderStatistic(statistic)) {
        throw 'Invalid statistic';
    }
    let increaseCount = 0;
    let decreaseCount = 0;
    statistic.value.leaders.forEach(leader => {
        const futureLeader = findMatchingFutureLeaderForStayDate(statistic, leader.stayDate);
        if (!futureLeader) {
            return;
        }
        const then = leader.value;
        const now = futureLeader.value;
        if (!$.isNumeric(then) || !$.isNumeric(now)) {
            return;
        }
        if (now < then) {
            decreaseCount++;
        } else if (now > then) {
            increaseCount++;
        }
    });
    return {
        increaseCount,
        decreaseCount
    };
}