import {AppElement, html} from "../AppElement";

export const INPUT_TYPES = {
    text: 'text',
    number: 'number',
    // etc
};

export const EVENT_TABLE_CELL_VALUE_INPUT_CHANGE = 'table-cell-value-input-change';

export default class TableCellValueInput extends AppElement {
    static get properties() {
        return {
            initialValue: { type: String },
            value: { type: String },
            type: { type: String },
            name: { type: String },

            min: { type: String },
            max: { type: String },
            step: { type: String },
            pattern: { type: String },

            canColorDirty: { type: String },
            canColorError: { type: String },
            isDirty: { type: String },
            isError: { type: String },
            isDisabled: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.value = props.value;
        if ('initialValue' in props) {
            this.initialValue = props.initialValue;
        } else {
            this.initialValue = this.value;
        }
        this.type = props.type || INPUT_TYPES.text;
        this.name = props.name || '';

        this.eventData = props.eventData || {};

        this.min = props.min || '';
        this.max = props.max || '';
        this.step = props.step || '';
        this.pattern = props.pattern || '';

        this.canColorDirty = super.reflowBool('true', props.canColorDirty);
        this.canColorError = super.reflowBool('true', props.canColorError);
        this.isDirty = super.reflowBool(undefined, props.isDirty) || this._isDirtyBoolStr();
        this.isError = super.reflowBool('false', props.isError);
        this.isDiabled = super.reflowBool('false', props.isDisabled);
    }

    reflow(props = {}) {
        if ('value' in props) {
            this.value = props.value;
        }
        if ('initialValue' in props) {
            this.initialValue = props.initialValue;
        }
        this.type = props.type || this.type;
        this.name = props.name || this.name;
        this.eventData = props.eventData || this.eventData;

        this.min = props.min || this.min;
        this.max = props.max || this.max;
        this.step = props.step || this.step;
        this.pattern = props.pattern || this.pattern;

        this.canColorDirty = super.reflowBool(this.canColorDirty, props.canColorDirty);
        this.canColorError = super.reflowBool(this.canColorError, props.canColorError);
        this.isDirty = super.reflowBool(this._isDirtyBoolStr(), props.isDirty);
        this.isError = super.reflowBool(this.isError, props.isError);
        this.isDisabled = super.reflowBool(this.isDisabled, props.isDisabled);
    }

    _isDirtyBoolStr() {
        return this._isDirty() ? 'true' : 'false';
    }

    _isDirty() {
        return ((this.value + '') || 'undefined') !== ((this.initialValue + '') || 'undefined');
    }

    _onChange(e) {
        this.isError = false;
        this.value = e.currentTarget.value;
        if (this._isDirty()) {
            this.isDirty = super.reflowBool(this.isDirty, 'true');
        } else {
            this.isDirty = super.reflowBool(this.isDirty, 'false');
        }

        const event = new CustomEvent(EVENT_TABLE_CELL_VALUE_INPUT_CHANGE, {
            detail: {
                event: e,
                that: this,
                eventData: this.eventData
            },
            bubbles: true,
        });
        this.dispatchEvent(event);
    }

    getStyleClass() {
        let classes = [];
        classes.push('form-control');
        classes.push('form-control-sm');
        classes.push('width-auto');
        if (this.isError === 'true') {
            if (this.canColorError === 'true') {
                classes.push('border-danger');
                classes.push('border-width-2');
            }
        } else if (this.isDirty === 'true' && this.canColorDirty === 'true') {
            classes.push('border-primary');
            classes.push('border-width-2');
        }
        return classes.join(' ');
    }

    render() {
        return html`
            <input 
                class="${this.getStyleClass()}"
                type="${this.type}" 
                name="${this.name}" 
                .value="${this.value}" 
                min="${this.min}"
                max="${this.max}"
                step="${this.step}"
                pattern="${this.pattern}"
                @change="${this._onChange}"
                ?disabled="${this.isDisabled === 'true'}"
            >
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableCellValueInput = TableCellValueInput;
customElements.define('vao-table-cell-value-input', TableCellValueInput);
