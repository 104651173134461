import { AppElement, html } from '../AppElement.js';
import StoryInternalKpi from '../storyInternalKpi/StoryInternalKpi';
import Constants from '../Constants';
import StoryInternalKpiPace from '../storyInternalKpiPace/StoryInternalKpiPace';
import StoryInternalPickup from '../storyInternalPickup/StoryInternalPickup';
import StoryForecast from '../storyForecast/StoryForecast';
import StoryForecastSubtitle from '../storyForecastSubtitle/StoryForecastSubtitle';
import StoryCompPricing from '../storyCompPricing/StoryCompPricing';
import StoryMyPricing from '../storyMyPricing/StoryMyPricing';
import StoryPositioning from '../storyPositioning/StoryPositioning';
import StoryLastRateChange from '../storyLastRateChange/StoryLastRateChange';
import StoryWhatToExpectPickup from '../storyWhatToExpectPickup/StoryWhatToExpectPickup';
import StoryWhatToExpectMyPrice from '../storyWhatToExpectMyPrice/StoryWhatToExpectMyPrice';
import StoryWhatToExpectComp from '../storyWhatToExpectComp/StoryWhatToExpectComp';
import StoryPitch from '../storyPitch/StoryPitch';
import StoryLastRateChangeSubtitle from '../storyLastRateChangeSubtitle/StoryLastRateChangeSubtitle';
import StoryInternalPickupSubtitle from '../storyInternalPickupSubtitle/StoryInternalPickupSubtitle';
import StoryInternalKpiSubtitle from '../storyInternalKpiSubtitle/StoryInternalKpiSubtitle';
import StoryCompPricingSubtitle from '../storyCompPricingSubtitle/StoryCompPricingSubtitle';
import StoryMyPricingSubtitle from '../storyMyPricingSubtitle/StoryMyPricingSubtitle';
import StoryPositioningSubtitle from '../storyPositioningSubtitle/StoryPositioningSubtitle';
import StoryWhatToExpectCompSubtitle from '../storyWhatToExpectCompSubtitle/StoryWhatToExpectCompSubtitle';
import StoryWhatToExpectMyPriceSubtitle from '../storyWhatToExpectMyPriceSubtitle/StoryWhatToExpectMyPriceSubtitle';
import StoryWhatToExpectPickupSubtitle from '../storyWhatToExpectPickupSubtitle/StoryWhatToExpectPickupSubtitle';
import StoryInternalDataSubtitle from '../storyInternalDataSubtitle/StoryInternalDataSubtitle';
import StoryExternalDataSubtitle from '../storyExternalDataSubtitle/StoryExternalDataSubtitle';
import StoryWhatToExpectSubtitle from '../storyWhatToExpectSubtitle/StoryWhatToExpectSubtitle';

export default class VirtualAnalyst extends AppElement {
    static get properties() {
        return {};
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();

        this.hotel = props.hotel || {};
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.pickupSetting = props.pickupSetting;
        if (!$.isNumeric(this.pickupSetting)) {
            this.pickupSetting = Constants.MISSING_STR;
        }
    }

    reflow(props = {}) {
        this.hotel = props.hotel || {};
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        if ('pickupSetting' in props) {
            this.pickupSetting = props.pickupSetting;
            if (!$.isNumeric(this.pickupSetting)) {
                this.pickupSetting = Constants.MISSING_STR;
            }
        }
        this.fill();
    }

    fill() {
        let commonOpts = {
            recordDate: this.recordDate,
            stayDate: this.stayDate,
            hotel: this.hotel,
            pickupSetting: this.pickupSetting
        };

        let $storyInternalKpi = $('<div></div>')
            .append($(new StoryInternalKpi(commonOpts)))
            .append('<p class="font-weight-bold">Pace</p>')
            .append(new StoryInternalKpiPace(commonOpts));

        let $storyInternalPickupSubtitle = $(new StoryInternalPickupSubtitle(commonOpts));

        let $storyInternalPickup = $(new StoryInternalPickup(commonOpts));

        let $storyForecast = $(new StoryForecast(commonOpts));

        let $storyForecastSubtitle = $(new StoryForecastSubtitle(commonOpts));

        let $storyCompPriceSubtitle = $(new StoryCompPricingSubtitle(commonOpts));

        let $storyCompPrice = $(new StoryCompPricing(commonOpts));

        let $storyMyPricingSubtitle = $(new StoryMyPricingSubtitle(commonOpts));

        let $storyMyPrice = $(new StoryMyPricing(commonOpts));

        let $storyPositioningSubtitle = $(new StoryPositioningSubtitle(commonOpts));

        let $storyPositioning = $(new StoryPositioning(commonOpts));

        let $storyLastRateChangeSubtitle = $(new StoryLastRateChangeSubtitle(commonOpts));

        let $storyLastRateChange = $(new StoryLastRateChange(commonOpts));

        let $storyWhatToExpectPickupSubtitle = $(new StoryWhatToExpectPickupSubtitle(commonOpts));

        let $storyWhatToExpectPickup = $(new StoryWhatToExpectPickup(commonOpts));

        let $storyWhatToExpectMyPriceSubtitle = $(new StoryWhatToExpectMyPriceSubtitle(commonOpts));

        let $storyWhatToExpectMyPrice = $(new StoryWhatToExpectMyPrice(commonOpts));

        let $storyWhatToExpectCompSubtitle = $(new StoryWhatToExpectCompSubtitle(commonOpts));

        let $storyWhatToExpectComp = $(new StoryWhatToExpectComp(commonOpts));

        let $pitch = $(new StoryPitch(commonOpts));

        let $listItems = $(window.infinito.components.threadItems.render({
            threadItemsProps: [
                {
                    title: 'Elevator Pitch',
                    block: $pitch,
                    isBlockHidden: false
                },
                {
                    title: 'Forecast',
                    subtitle: $storyForecastSubtitle,
                    block: $storyForecast,
                    isBlockHidden: true
                },
                {
                    title: 'OTB, Pace & Pickup',
                    subtitle: $(new StoryInternalDataSubtitle(commonOpts)),
                    block: window.infinito.components.threadItems.render({
                        threadItemsProps: [
                            {
                                title: 'OTB & Pace',
                                subtitle: $(new StoryInternalKpiSubtitle(commonOpts)),
                                block: $storyInternalKpi,
                                isBlockHidden: true
                            },
                            {
                                title: 'Pickup Trend',
                                subtitle: $storyInternalPickupSubtitle,
                                block: $storyInternalPickup,
                                isBlockHidden: true
                            }
                        ]
                    }),
                    isBlockHidden: true
                },
                {
                    title: 'Pricing & Positioning',
                    subtitle: $(new StoryExternalDataSubtitle(commonOpts)),
                    block: window.infinito.components.threadItems.render({
                        threadItemsProps: [
                            {
                                title: 'Positioning',
                                subtitle: $storyPositioningSubtitle,
                                block: $storyPositioning,
                                isBlockHidden: true
                            },
                            {
                                title: 'Comp Pricing',
                                subtitle: $storyCompPriceSubtitle,
                                block: $storyCompPrice,
                                isBlockHidden: true
                            },
                            {
                                title: 'My Pricing',
                                subtitle: $storyMyPricingSubtitle,
                                block: $storyMyPrice,
                                isBlockHidden: true
                            },
                            {
                                title: 'Last Time Rate Change',
                                subtitle: $storyLastRateChangeSubtitle,
                                block: $storyLastRateChange,
                                isBlockHidden: true
                            }
                        ]
                    }),
                    isBlockHidden: true
                },
                {
                    title: 'What To Expect',
                    subtitle: $(new StoryWhatToExpectSubtitle(commonOpts)),
                    block: window.infinito.components.threadItems.render({
                        threadItemsProps: [
                            {
                                title: 'Pickup',
                                subtitle: $storyWhatToExpectPickupSubtitle,
                                block: $storyWhatToExpectPickup,
                                isBlockHidden: true
                            },
                            {
                                title: 'My Pricing',
                                subtitle: $storyWhatToExpectMyPriceSubtitle,
                                block: $storyWhatToExpectMyPrice,
                                isBlockHidden: true
                            },
                            {
                                title: 'Comp Set',
                                subtitle: $storyWhatToExpectCompSubtitle,
                                block: $storyWhatToExpectComp,
                                isBlockHidden: true
                            }
                        ]
                    }),
                    isBlockHidden: true
                },
            ]
        }));

        $('#' + this.id).find('.vao__components--virtualAnalyst').empty().append($listItems);
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`<div class="vao__components--virtualAnalyst"></div>`;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.VirtualAnalyst = VirtualAnalyst;
customElements.define('vao-virtual-analyst', VirtualAnalyst);
