import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryLastRateChange extends AppElement {
    static get properties() {
        return {
            lastRateChangeDaysAgo: { type: String },
            offsetRateVal: { type: String },
            nowRateVal: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.rateEvolutionId = AppElement.getUniqueElementId();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.lastRateChangeDaysAgo = Constants.MISSING_STR;
        this.offsetRateVal = Constants.MISSING_STR;
        this.nowRateVal = Constants.MISSING_STR;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        document.getElementById(this.rateEvolutionId).reflow({
            recordDate: this.recordDate,
            stayDate: this.stayDate,
            hotel: this.hotel,
            locale: this.locale,
            chartHeight: '200px'
        });

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.ratesLastChanges
                ]
            }),
            (data, procData) => {
                let ratesLastChanges = ((procData.ratesLastChanges || {})[this.stayDate] || {}).value || null;

                let primChannel = window.infinito.vao.controller.channelHelper.getPrimaryChannelsName();
                if (
                    typeof ratesLastChanges === 'object'
                    && primChannel in ratesLastChanges
                    && typeof ratesLastChanges[primChannel] === 'object'
                ) {
                    ratesLastChanges = ratesLastChanges[primChannel];
                }

                if (
                    typeof ratesLastChanges === 'object'
                    && 'currentVal' in ratesLastChanges
                    && $.isNumeric(ratesLastChanges.currentVal)
                ) {
                    this.nowRateVal = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ratesLastChanges.currentVal,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    )
                } else {
                    this.nowRateVal = Constants.MISSING_STR;
                }

                if (
                    typeof ratesLastChanges === 'object'
                    && 'offsetVal' in ratesLastChanges
                    && $.isNumeric(ratesLastChanges.offsetVal)
                ) {
                    this.offsetRateVal = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ratesLastChanges.offsetVal,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    )
                } else {
                    this.offsetRateVal = Constants.MISSING_STR;
                }

                if (
                    typeof ratesLastChanges === 'object'
                    && 'dayDiff' in ratesLastChanges
                    && $.isNumeric(ratesLastChanges.dayDiff)
                ) {
                    this.lastRateChangeDaysAgo = ratesLastChanges.dayDiff;
                } else {
                    this.lastRateChangeDaysAgo = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    renderLastRateChangeParagraph() {
        if (!$.isNumeric(this.lastRateChangeDaysAgo)) {
            return '';
        }
        return storyHtml`
        <p>
            The last time our rate changed for this stay date was 
            ${new StoryText(this.lastRateChangeDaysAgo, true, storyTextTypes.TYPE_NEUTRAL)} day ago. 
            Our lowest rate before the change was 
            ${new StoryText(this.offsetRateVal, true, storyTextTypes.TYPE_NEUTRAL)}, and our 
            rate after the change was 
            ${new StoryText(this.nowRateVal, true, storyTextTypes.TYPE_NEUTRAL)}.
        </p>
        `;
    }

    render() {
        return html`
<div class="vao__components--storyLastRateChange">
    ${this.renderLastRateChangeParagraph()}
    <div style="
    border: 1px solid lightgray;
    border-radius: .3rem;
    padding: 0 1rem;">
        <vao-my-rate-evolution id="${this.rateEvolutionId}"></vao-my-rate-evolution>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryLastRateChange = StoryLastRateChange;
customElements.define('vao-story-last-rate-change', StoryLastRateChange);
