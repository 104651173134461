import Constants from "./Constants";

export function calcDuration(stat) {
    let duration = '0h';
    if (stat && stat.receivedTs && stat.offsetReceivedTs) {
        let durationHours =  window.infinito.vao.controller.dateHelper.calcHoursBetweenTZStrings(
            stat.receivedTs,
            stat.offsetReceivedTs
        );
        if (durationHours) {
            duration = parseInt(durationHours) + 'h';
        }
    }
    return duration;
}

export function formatOccupancy(stat) {
    const format = (numericVal) => {
        return Math.round(parseFloat(numericVal) * 100) + '%';
    };
    // Allow stat.value to be passed in directly.
    if ($.isNumeric(stat)) {
        return format(stat);
    }
    if (!stat || !$.isNumeric(stat.value)) {
        return Constants.MISSING_STR;
    }
    return format(stat.value);
}

export function objectify(input) {
    if (!input || typeof input !== 'object') {
        return {};
    }
    return input;
}

export function isJSONString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
