import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';
import StoryWhatToExpectPickup from "../storyWhatToExpectPickup/StoryWhatToExpectPickup";

export default class StoryForecast extends AppElement {
    static get properties() {
        return {
            occupancy: { type: String },
            forecast: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();

        this.cache = new Map();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.reflowPickupEl();
        this.fill();
    }

    reflowPickupEl() {
        if (this.cache.has('pickupEl')) {
            let pickupEl = this.cache.get('pickupEl');
            if (pickupEl instanceof StoryWhatToExpectPickup) {
                pickupEl.reflow({
                    recordDate: this.recordDate,
                    stayDate: this.stayDate,
                    hotel: this.hotel
                });
            }
        }
    }

    empty() {
        this.occupancy = Constants.MISSING_STR;
        this.forecast = Constants.MISSING_STR;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.occupancy,
                    modelBiStats.fields.occupancyLeaderCloserTrendProphecy
                ]
            }),
            (data, procData) => {
                let occ = ((procData.occupancy || {})[this.stayDate] || {}).value || null;
                let occProphecy =
                    ((procData.occupancyLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || null;

                if ($.isNumeric(occ)) {
                    this.occupancy = Math.floor(occ * 100) + '%';
                } else {
                    this.occupancy = Constants.MISSING_STR;
                }

                if (
                    typeof occProphecy === 'object'
                    && 'leadersClosersProphecy' in occProphecy
                    && $.isNumeric(occProphecy.leadersClosersProphecy)
                ) {
                    this.forecast = (window.infinito.vao.controller.utils.interpretOccupancyForecastOutput(
                        occProphecy.leadersClosersProphecy
                    ) || Constants.MISSING_STR);
                } else {
                    this.forecast = Constants.MISSING_STR;
                }
            }
        );
    }

    forecastStoryTextType() {
        if (this.forecast === window.infinito.vao.controller.utils.forecastInterpretations.noFill) {
            return storyTextTypes.TYPE_NEGATIVE;
        } else if (this.forecast === window.infinito.vao.controller.utils.forecastInterpretations.abilityToFill) {
            return storyTextTypes.TYPE_NEUTRAL;
        } else if (this.forecast === window.infinito.vao.controller.utils.forecastInterpretations.excessDemand) {
            return storyTextTypes.TYPE_POSITIVE;
        } else {
            return null;
        }
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    renderWhatToExpectPickup() {
        if (this.cache.has('pickupEl')) {
            return this.cache.get('pickupEl');
        }
        let pickupEl = new StoryWhatToExpectPickup({
            recordDate: this.recordDate,
            stayDate: this.stayDate,
            hotel: this.hotel
        });
        this.cache.set('pickupEl', pickupEl);
        return pickupEl;
    }

    render() {
        return storyHtml`
<div class="vao__components--storyForecast">
    <p>
        We currently have an occupancy of 
        ${new StoryText(this.occupancy, true, storyTextTypes.TYPE_NEUTRAL)} 
        for this stay date. We forecast 
        ${new StoryText(this.forecast, true, this.forecastStoryTextType())}.
    </p>
    ${this.renderWhatToExpectPickup()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryForecast = StoryForecast;
customElements.define('vao-story-forecast', StoryForecast);
