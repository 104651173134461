import { AppElement, html } from '../AppElement.js';
import {OPTION_DAY} from "./GroupRatesRangeSelect";
import {sendRequest} from "../App.common";

export default class GroupRatesControl extends AppElement {
    static get properties() {
        return {
            // props
            recordDate: { type: String },
            hotel: { type : Object },

            // state
            rangeValue: { type: String },
            year: { type: String },
        };
    }
    constructor(props = {}) {
        super();

        // props
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.hotel = props.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();

        // state
        this.rangeValue = props.rangeValue || OPTION_DAY;
        this.year = props.year || window.infinito.vao.controller.dateHelper.format(this.recordDate, 'YYYY');
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        this.hotel = props.hotel || this.hotel;

        this.rangeValue = props.rangeValue || this.rangeValue;
        this.year = props.year || this.year;
    }

    onRangeChange(e) {
        this.rangeValue = e.currentTarget.value;
    }

    onChangeYear(e) {
        this.year = e.currentTarget.value;
    }
    onClicksaveBufferSeasonBtn(){
        //buffer_
        let season_name = $("#buffer_season_name").val();
        if(season_name===''){
            this.applyclassred('buffer_season_name');
            return false;
        }else{
            this.removeclassred('buffer_season_name');
        }
        let high_value = $("#buffer_high_value").val();
        if(high_value===''){
            this.applyclassred('buffer_high_value');
            return false;
        }else{
            this.removeclassred('buffer_high_value');
        }
        let medium_value = $("#buffer_medium_value").val();
        if(medium_value===''){
            this.applyclassred('buffer_medium_value');
            return false;
        }else{
            this.removeclassred('buffer_medium_value');
        }
        let low_value = $("#buffer_low_value").val();
        if(low_value===''){
            this.applyclassred('buffer_low_value');
            return false;
        }else{
            this.removeclassred('buffer_low_value');
        }
        let hoteldata = window.infinito.vao.controller.storageHelper.getSelectedHotel();

        let postData = {
            'hotel_id': hoteldata.id,
            'season_name': season_name,
            'high_value': high_value,
            'medium_value': medium_value,
            'low_value': low_value,
            'op': 'createbufferagainstseason',
            'serviceName': "hotelbufferrates.php"
        };
        sendRequest(postData,function(res){
            // $("#season_name").val('');
            // $("#high_value").val(0);
            // $("#medium_value").val(0);
            // $("#low_value").val(0);
            var bodyText = 'New Buffer created. Wait until page gets refreshed.';
            $(infinito.vao.controller.alertHelper.constructPrimaryAlert(
                '',
                bodyText,
                true
            )).insertBefore('#season_table');
            setTimeout(function(){window.location.reload()},5000);

        });
    }
    onClicksaveSeasonBtn(){
        let season_name = $("#season_name").val();
        if(season_name===''){
            this.applyclassred('season_name');
            return false;
        }else{
            this.removeclassred('season_name');
        }
        let high_value = $("#high_value").val();
        if(high_value===''){
            this.applyclassred('high_value');
            return false;
        }else{
            this.removeclassred('high_value');
        }
        let medium_value = $("#medium_value").val();
        if(medium_value===''){
            this.applyclassred('medium_value');
            return false;
        }else{
            this.removeclassred('medium_value');
        }
        let low_value = $("#low_value").val();
        if(low_value===''){
            this.applyclassred('low_value');
            return false;
        }else{
            this.removeclassred('low_value');
        }
        let hoteldata = window.infinito.vao.controller.storageHelper.getSelectedHotel();

        let postData = {
            'hotel_id': hoteldata.id,
            'season_name': season_name,
            'high_value': high_value,
            'medium_value': medium_value,
            'low_value': low_value,
            'op': 'createbufferseason',
            'serviceName': "hotelbufferrates.php"
        };
        sendRequest(postData,function(res){
            // $("#season_name").val(0);
            // $("#high_value").val(0);
            // $("#medium_value").val(0);
            // $("#low_value").val(0);
            var bodyText = 'New Season created. Wait until page gets refreshed.';
            $(infinito.vao.controller.alertHelper.constructPrimaryAlert(
                '',
                bodyText,
                true
            )).insertBefore('#season_table');
            setTimeout(function(){window.location.reload()},5000);

        });
    }
    applyclassred(id){
        $("#"+id).css('border-color','red');
    }
    removeclassred(id){
        $("#"+id).css('border-color','#dee2e6');
    }
    onClickdaterange(){
        let body = '<vao-page-bufferrates-daterange-modal></vao-page-bufferrates-daterange-modal>';
        let stay_date = `<h5>Date range</h5>`;
        let $title =  $('<div></div>').append(stay_date);
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'xl'
        });
    }
    render() {
        const baseYear = window.infinito.vao.controller.dateHelper.format(this.recordDate, 'YYYY');
        return html`
            <div style="width: auto;overflow-x: auto;">
                <button class="btn"
                        style="margin-top: 15px;margin-right:15px;border-color: rgb(242 101 50);color:grey;width:auto;margin-bottom: 24px;margin-left: 3px;"
                        @click="${this.onClickdaterange}"> Add Date Range
                </button>
                <table id="season_table"
                       class="vao__components--table-table table nowrap table-bordered table-md table-align-center table-fs-small table-static-left"
                       style="text-align: center;"
                       class="vao__components--table-table table nowrap table-md table-align-center table-fs-small table-static-left">
                    <thead>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">Event
                    </th>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                        High
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">
                        Medium
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">
                        Low
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">Action
                    </th>
                    </thead>
                    <tbody>
                    <tr role="row" class="">
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#ff1c1c;color:white;"
                                   type="text" name="season_name" id="season_name" value="0"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-revenue" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#ff6e41;color:white;"
                                   type="number" name="high_value" id="high_value" value="0"
                                   step="any" pattern="">
                        </td>
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#ffc107;color:white;"
                                   type="number" name="medium_value" id="medium_value" value="0"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#03a9f4;color:white;"
                                   type="number" name="low_value" id="low_value" value="0"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-action" rowspan="1" colspan="1">
                            <vao-button
                                    text="Save"
                                    variant="invert"
                                    size="sm"
                                    @click=${this.onClicksaveSeasonBtn}
                            ></vao-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <p>Seasons Buffer</p>
                <table id="buffer_season_table"
                       class="vao__components--table-table table nowrap table-bordered table-md table-align-center table-fs-small table-static-left"
                       style="text-align: center;"
                       class="vao__components--table-table table nowrap table-md table-align-center table-fs-small table-static-left">
                    <thead>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">Event Buffer%
                    </th>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                        High Buffer%
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">
                        Medium Buffer%
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">
                        Low Buffer%
                    </th>
                    <th style="z-index: 3;left: 0;background: #fff;position: -webkit-sticky;position: sticky;">Action
                    </th>
                    </thead>
                    <tbody>
                    <tr role="row" class="">
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#ff1c1c;color:white;"
                                   type="text" name="buffer_season_name" id="buffer_season_name" value="0"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-revenue" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#ff6e41;color:white;"
                                   type="number" name="buffer_high_value" id="buffer_high_value" value="0"
                                   step="any" pattern="">
                        </td>
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#ffc107;color:white;"
                                   type="number" name="buffer_medium_value" id="buffer_medium_value" value="0"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" style="background:#03a9f4;color:white;"
                                   type="number" name="buffer_low_value" id="buffer_low_value" value="0"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-action" rowspan="1" colspan="1">
                            <vao-button
                                    text="Save"
                                    variant="invert"
                                    size="sm"
                                    @click=${this.onClicksaveBufferSeasonBtn}
                            ></vao-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <div class="vao__components--budgetControl">
                <vao-grouprates-range-select
                        .value="${this.rangeValue}"
                        @change="${this.onRangeChange}"
                ></vao-grouprates-range-select>
                <br>
                <vao-grouprates-year-select
                        .baseYear="${baseYear}"
                        .year="${this.year}"
                        @change="${this.onChangeYear}"
                ></vao-grouprates-year-select>
                <br>
                <vao-grouprates-form-table
                        .aggregator="${this.rangeValue}"
                        .year="${this.year}"
                        .hotel="${this.hotel}"
                ></vao-grouprates-form-table>
            </div>
        `;
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.GroupRatesControl = GroupRatesControl;
customElements.define('vao-grouprates-control', GroupRatesControl);
