import {AppElement, html} from '../AppElement.js';
import Constants from "../Constants";

export const OPTION_30_DAYS = '30-days';
export const OPTION_60_DAYS = '60-days';
export const OPTION_90_DAYS = '90-days';
export const OPTION_365_DAYS = '365-days';

export const defaultOptions = [
    {
        value: OPTION_30_DAYS,
        text: Constants.STRINGS.DAYS_30,
        _changeDaysToAdd: 30,
    },
    {
        value: OPTION_60_DAYS,
        text: Constants.STRINGS.DAYS_60,
        _changeDaysToAdd: 60,
    },
    {
        value: OPTION_90_DAYS,
        text: Constants.STRINGS.DAYS_90,
        _changeDaysToAdd: 90,
    },
    {
        value: OPTION_365_DAYS,
        text: Constants.STRINGS.DAYS_365,
        _changeDaysToAdd: 365,
    }
];

export const EVENT_STAY_DATE_RANGE_SELECT_CHANGE = 'vao-stay-date-range-select-change';

export default class StayDateRangeSelect extends AppElement {
    static get properties() {
        return {
            value: {type: String},
            options: {type: Array},
            recordDate: {type: String},

            // @internalProperty
            dynamicOptions: {attribute: false},
        };
    }

    constructor(props = {}) {
        super();
        this.dateHelper = window.infinito.vao.controller.dateHelper;

        this.value = props.value;
        this.options = props.options || defaultOptions;
        this.recordDate = props.recordDate;

        this.dynamicOptions = [];
    }

    reflow(props = {}) {
        this.value = props.value || this.value;
        this.options = props.options || this.options;
        this.recordDate = props.recordDate || this.recordDate;
    }

    isValidRecordDate() {
        return (
            this.recordDate
            && this.dateHelper.isDateStr(this.recordDate)
        );
    }

    makeSelectChangeInformation() {
        if (!this.isValidRecordDate() || !this.value || typeof this.value !== 'string') {
            return {
                startDate: null,
                endDate: null,
            };
        }
        const selectedOption = this.options.find(option => {
            return option.value === this.value;
        });
        if (
            selectedOption
            && '_changeDaysToAdd' in selectedOption
            && Number.isInteger(selectedOption._changeDaysToAdd)
        ) {
            return {
                startDate: this.recordDate,
                endDate: this.dateHelper.addDaysToDateString(this.recordDate, selectedOption._changeDaysToAdd),
            };
        }
        if (this.dateHelper.isInFormat(this.value, 'YYYY-MM')) {
            const recordDateAsYearMonth = this.dateHelper.format(this.recordDate, 'YYYY-MM');
            let firstOfMonth;
            if (this.value === recordDateAsYearMonth) {
                firstOfMonth = this.recordDate; // Special case to not allow historical dates in current month.
            } else {
                firstOfMonth = `${this.value}-01`;
            }
            const lastOfMonth = this.dateHelper.getLastDayInMonthFromDateString(firstOfMonth);
            return {
                startDate: firstOfMonth,
                endDate: lastOfMonth,
            };
        }
        return {
            startDate: null,
            endDate: null,
        };
    }

    onSelectChange(e) {
        this.value = e.currentTarget.value;

        const event = new CustomEvent(EVENT_STAY_DATE_RANGE_SELECT_CHANGE, {
            detail: {
                newValue: this.value,
                rangeData: this.makeSelectChangeInformation()
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    setDynamicRecordDateOptions() {
        this.dynamicOptions = [];

        if (!this.isValidRecordDate()) {
            return;
        }

        const dateHelper = this.dateHelper;
        for (let i = 0; i < 12; i += 1) {
            const futureRecordDate = dateHelper.addMonthsToDateString(this.recordDate, i);
            const futureRecordDateKey = dateHelper.format(futureRecordDate, 'YYYY-MM');
            const futureRecordDateString = dateHelper.format(futureRecordDate, 'MMMM YYYY');
            this.dynamicOptions.push({
                value: futureRecordDateKey,
                text: futureRecordDateString
            });
        }
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('recordDate')) {
            this.setDynamicRecordDateOptions();
        }
    }

    render() {
        const options = [...this.options, ...this.dynamicOptions];
        return html`
            <div class="vao__components--stayDateRangeSelect">
                <vao-select .value="${this.value}" .options="${options}" @change="${this.onSelectChange}">
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StayDateRangeSelect = StayDateRangeSelect;
customElements.define('vao-stay-date-range-select', StayDateRangeSelect);
