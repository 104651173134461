import TableCell from "./TableCell";

function areValidChildren(children) {
    return Array.isArray(children) && children.length > 0 && children.every(child => {
        return child instanceof TableColumn;
    });
}

export const columnSortStates = {
    none: 'none',
    ascending: 'asc',
    descending: 'desc'
};

export default class TableColumn {
    static iterate(columns, callback) {
        const recursiveColumnIterator = (columns) => {
            columns.forEach(column => {
                if (column instanceof TableColumn) {
                    callback(column);
                    if (column.children.length > 0) {
                        recursiveColumnIterator(column.children);
                    }
                }
            });
        };
        recursiveColumnIterator(columns);
    }

    constructor(
        tableCell,
        children = [],
        colGroup = undefined,
        description = null,
        isVisible = true,
        isSortable = false
    ) {
        if (tableCell instanceof TableCell) {
            this.tableCell = tableCell;
        } else {
            this.tableCell = null;
        }

        this.colGroup = colGroup;

        if (areValidChildren(children)) {
            this.children = children;
        } else {
            this.children = [];
        }

        if (typeof description === 'string' && description.length > 0) {
            this.description = description;
        } else {
            this.description = null;
        }

        if (isVisible === false) {
            this.isVisible = false;
        } else {
            this.isVisible = true;
        }

        if (isSortable === true) {
            this.isSortable = true;
        } else {
            this.isSortable = false;
        }
        this.sortState = columnSortStates.none;
    }

    resetSortState() {
        this.sortState = columnSortStates.none;
    }

    nextSortState() {
        if (this.sortState === columnSortStates.none) {
            this.sortState = columnSortStates.ascending;
        } else if (this.sortState === columnSortStates.ascending) {
            this.sortState = columnSortStates.descending;
        } else {
            this.sortState = columnSortStates.none;
        }
    }

    isSortAscending() {
        return this.sortState === columnSortStates.ascending;
    }

    isSortDescending() {
        return this.sortState === columnSortStates.descending;
    }

    hasSortApplied() {
        return this.isSortable && this.sortState !== columnSortStates.none;
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableColumn = TableColumn;
