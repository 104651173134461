import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { mapMyPricingCompPricingPositioningAvailabilityCombosPNN } from '../StoryPitchMappings';
import {getBadgeClassForString} from "../StoryTextElement";

export default class StoryExternalDataSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.rateLeadSiblingPace,
                    modelBiStats.fields.compSetAvgLeadSiblingPace,
                    modelBiStats.fields.priceRankLeadSiblingPace,
                    modelBiStats.fields.ratesPickup
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                let rateLeadSiblingPace =
                    ((procData.rateLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let compSetAvgLeadSiblingPace =
                    ((procData.compSetAvgLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let priceRankLeadSiblingPace =
                    ((procData.priceRankLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let ratesPu = ((procData.ratesPickup || {})[this.stayDate] || {});
                const analyseAttributesHelper = window.infinito.vao.controller.analyseAttributesHelper;

                this.badgeClass = Constants.BADGES.CLASSES.PRIMARY;

                if (ratesPu && typeof ratesPu === 'object') {
                    let ratesPnnRes = analyseAttributesHelper.interpretRatesPickupForAvailability(ratesPu);
                    if (ratesPnnRes && typeof ratesPnnRes === 'object' && 'now' in ratesPnnRes) {
                        let availPnn = ratesPnnRes['now'] || null;
                        if (!availPnn) {
                            this.subtitle = Constants.MISSING_STR;
                            return;
                        }
                        if (availPnn === 'negative') {
                            this.subtitle = 'We are not selling';
                        } else {
                            if (
                                rateLeadSiblingPace
                                && typeof rateLeadSiblingPace === 'object'
                                && 'T0' in rateLeadSiblingPace
                                && 'setDenseRank' in rateLeadSiblingPace.T0
                                && 'setDenseLength' in rateLeadSiblingPace.T0
                                && $.isNumeric(rateLeadSiblingPace.T0.setDenseRank)
                                && compSetAvgLeadSiblingPace
                                && typeof compSetAvgLeadSiblingPace === 'object'
                                && 'T0' in compSetAvgLeadSiblingPace
                                && 'setDenseRank' in compSetAvgLeadSiblingPace.T0
                                && 'setDenseLength' in compSetAvgLeadSiblingPace.T0
                                && $.isNumeric(compSetAvgLeadSiblingPace.T0.setDenseRank)
                                && priceRankLeadSiblingPace
                                && typeof priceRankLeadSiblingPace === 'object'
                                && 'T0' in priceRankLeadSiblingPace
                                && 'setDenseRank' in priceRankLeadSiblingPace.T0
                                && 'setDenseLength' in priceRankLeadSiblingPace.T0
                                && $.isNumeric(priceRankLeadSiblingPace.T0.setDenseRank)
                                && ratesPu
                                && typeof ratesPu === 'object'
                            ) {
                                let ratePnn = analyseAttributesHelper.getMyPricePNNForSetRank(
                                    rateLeadSiblingPace.T0.setDenseRank,
                                    rateLeadSiblingPace.T0.setDenseLength
                                );
                                let compPnn = analyseAttributesHelper.getCompPricePNNForSetRank(
                                    compSetAvgLeadSiblingPace.T0.setDenseRank,
                                    compSetAvgLeadSiblingPace.T0.setDenseLength
                                );
                                let positionPnn = analyseAttributesHelper.getPriceRankPNNForSetRank(
                                    priceRankLeadSiblingPace.T0.setDenseRank,
                                    priceRankLeadSiblingPace.T0.setDenseLength
                                );
                                let combo = ratePnn + compPnn + positionPnn + availPnn;
                                let str = mapMyPricingCompPricingPositioningAvailabilityCombosPNN[combo];
                                if (str) {
                                    this.badgeClass = getBadgeClassForString(str, Constants.BADGES.CLASSES.PRIMARY);
                                    this.subtitle = str;
                                } else {
                                    this.subtitle = Constants.MISSING_STR;
                                }
                            } else {
                                this.subtitle = Constants.MISSING_STR;
                            }
                        }
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryExternalDataSubtitle = StoryExternalDataSubtitle;
customElements.define('vao-story-external-data-subtitle', StoryExternalDataSubtitle);
