import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class MetricOptionBlock extends AppElement {
    static get properties() {
        return {
            currentMetric: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.currentMetric = props.currentMetric;
        this.onChange = props.onChange;
        this.id = AppElement.getUniqueElementId();
    }

    getDisplayableTextForMetric(metric) {
        if (metric === Constants.METRICS.OCCUPANCY) {
            return Constants.STRINGS.OCC;
        } else if (metric === Constants.METRICS.ROOMS) {
            return Constants.STRINGS.ROOMS;
        } else if (metric === Constants.METRICS.ADR) {
            return Constants.STRINGS.ADR;
        } else if (metric === Constants.METRICS.REVENUE) {
            return Constants.STRINGS.REV;
        } else {
            return Constants.MISSING_STR;
        }
    }

    _onChange(newMetric) {
        if (newMetric === this.currentMetric) {
            return;
        }
        this.currentMetric = newMetric;
        if (typeof this.onChange === 'function') {
            this.onChange(this.currentMetric);
        }
    }

    renderOptions() {
        let options = [];
        let btn;

        const getColorForMetric = (metric) => {
            if (metric === this.currentMetric) {
                return 'primary';
            }
            return 'light';
        };

        const getDisabledStateForMetric = (metric) => {
            if (metric === this.currentMetric) {
                return 'true';
            }
            return 'false';
        };

        btn = html`
<vao-button  
    @click="${() => {this._onChange(Constants.METRICS.OCCUPANCY)}}"
    text="${this.getDisplayableTextForMetric(Constants.METRICS.OCCUPANCY)}" 
    startIcon="${Constants.ICONS.OCCUPANCY}"
    variant="contained" 
    size="small"
    color="${getColorForMetric(Constants.METRICS.OCCUPANCY)}" 
    disabledState="${getDisabledStateForMetric(Constants.METRICS.OCCUPANCY)}">                            
</vao-button>`;
        options.push(btn);

        btn = html`
<vao-button  
    @click="${() => {this._onChange(Constants.METRICS.ROOMS)}}"
    text="${this.getDisplayableTextForMetric(Constants.METRICS.ROOMS)}" 
    startIcon="${Constants.ICONS.ROOMS}"
    variant="contained" 
    size="small"
    color="${getColorForMetric(Constants.METRICS.ROOMS)}" 
    disabledState="${getDisabledStateForMetric(Constants.METRICS.ROOMS)}">                            
</vao-button>
        `;
        options.push(btn);

        btn = html`
<vao-button  
    @click="${() => {this._onChange(Constants.METRICS.ADR)}}"
    text="${this.getDisplayableTextForMetric(Constants.METRICS.ADR)}" 
    startIcon="${Constants.ICONS.ADR}"
    variant="contained" 
    size="small"
    color="${getColorForMetric(Constants.METRICS.ADR)}" 
    disabledState="${getDisabledStateForMetric(Constants.METRICS.ADR)}">                            
</vao-button>
        `;
        options.push(btn);

        btn = html`
<vao-button  
    @click="${() => {this._onChange(Constants.METRICS.REVENUE)}}"
    text="${this.getDisplayableTextForMetric(Constants.METRICS.REVENUE)}" 
    startIcon="${Constants.ICONS.REVENUE}"
    variant="contained" 
    size="small"
    color="${getColorForMetric(Constants.METRICS.REVENUE)}" 
    disabledState="${getDisabledStateForMetric(Constants.METRICS.REVENUE)}">                            
</vao-button>
        `;
        options.push(btn);

        return html`<div class="vao__components--metricOptionBlock-options">${options}</div>`;
    }

    render() {
        return html`
<div class="vao__components--metricOptionBlock">
    ${this.renderOptions()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MetricOptionBlock = MetricOptionBlock;
customElements.define('vao-metric-option-block', MetricOptionBlock);
