import {html} from '../AppElement.js';
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import Table from "../table/Table";
import TableNoDataRow from "../table/TableNoDataRow";
import TableRow from "../table/TableRow";
import Constants from "../Constants";
import {AppElement} from "../AppElement";
import TableLoadingRow from "../table/TableLoadingRow";
import {formatOccupancy} from "../Utils";
import Tag from "../tag/Tag";
import ColorArray from "../ColorArray";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";
import ActualWithVarianceTableCellValue from "../table/ActualWithVarianceTableCellValue";
import TableTotalRow from "../table/TableTotalRow";

export const cellFieldMap = {
    name: 'name',
    occ: 'occ',
    rooms: 'rooms',
    rev: 'rev',
    adr: 'adr',
    busmix: 'busmix'
};

export default class MetricBreakdownTable extends AppElement {

    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            stayDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel;
        this.recordDate = props.recordDate;
        this.stayDate = props.stayDate;
        this.cellFieldsToSkip = props.cellFieldsToSkip || [];
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();

        this.id = AppElement.getUniqueElementId();
        this.cache = {};
        this.cellFieldMap = {
            name: cellFieldMap.name,
            occ: cellFieldMap.occ,
            rooms: cellFieldMap.rooms,
            rev: cellFieldMap.rev,
            adr: cellFieldMap.adr,
            busmix: cellFieldMap.busmix
        };
        this.hasFirstUpdated = false;
        this.ctxNameMap = {
            [Constants.CTX_PROPERTY_KEY]: Constants.STRINGS.TOTAL
        }
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.fill();
    }

    canIncludeCellField(cellField) {
        return !this.cellFieldsToSkip.includes(cellField);
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [];
        cols.push(new TableColumn(
            new TableCell({
                field: this.cellFieldMap.name,
                value: 'Name',
                type: tableCellTypes.TH
            }),
            [], undefined, null, true, true
        ));
        if (this.canIncludeCellField(this.cellFieldMap.occ)) {
            cols.push(new TableColumn(
                new TableCell({
                    field: this.cellFieldMap.occ,
                    value: Constants.STRINGS.OCC,
                    type: tableCellTypes.TH
                }),
                [], undefined, null, true, true
            ));
        }
        if (this.canIncludeCellField(this.cellFieldMap.rooms)) {
            cols.push(new TableColumn(
                new TableCell({
                    field: this.cellFieldMap.rooms,
                    value: Constants.STRINGS.ROOMS,
                    type: tableCellTypes.TH
                }),
                [], undefined, null, true, true
            ));
        }
        if (this.canIncludeCellField(this.cellFieldMap.rev)) {
            cols.push(new TableColumn(
                new TableCell({
                    field: this.cellFieldMap.rev,
                    value: Constants.STRINGS.REV,
                    type: tableCellTypes.TH
                }),
                [], undefined, null, true, true
            ));
        }
        if (this.canIncludeCellField(this.cellFieldMap.adr)) {
            cols.push(new TableColumn(
                new TableCell({
                    field: this.cellFieldMap.adr,
                    value: Constants.STRINGS.ADR,
                    type: tableCellTypes.TH
                }),
                [], undefined, null, true, true
            ));
        }
        this.cache['cols'] = cols;
        return cols;
    }

    acquireTableEl() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            let table = new Table({
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ],
                tableOpts: {
                    canShowToolbarFilters: false,
                }
            });
            this.cache['table'] = table;
        }

        return this.cache.table;
    }

    reflowTable(rows = [], isLoading = false) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            this.cache.rowMap = new Map();
            if (isLoading) {
                innerRows = [
                    new TableLoadingRow()
                ]
            } else {
                innerRows = [
                    new TableNoDataRow()
                ];
            }
        } else {
            innerRows = rows;
        }

        let table = this.acquireTableEl();
        if (table instanceof Table) {
            table.reflow({
                rows: innerRows
            });
        }

        return table;
    }

    reflowAllTableRows() {
        const rowArr = Array.from(this.cache.rowMap.values());
        this.reflowTable(rowArr);
    }

    makeOccupancyCell(input) {
        let val = input;
        if (!$.isNumeric(val)) {
            val = Constants.MISSING_STR;
        } else {
            const occVal = formatOccupancy(val);
            const tag = new Tag({
                label: occVal,
                size: Constants.SIZES.SMALL
            });
            const occNumericVal = parseFloat(occVal.replace('%', ''));
            const deferred = window.infinito.vao.controller.demandHelper.fetchDemandColorFor(
                occNumericVal
            );
            $.when(deferred).then((color) => {
                let labelColor;
                if (ColorArray.isHexColor(color) && occNumericVal > 0) {
                    labelColor = color;
                } else {
                    labelColor = Constants.COLORS.HEX.TEXT_MUTED;
                }
                tag.reflow({
                    labelColor
                });
            });
            val = tag;
        }
        return val;
    }

    domTheTable(table) {
        $('#' + this.id).find('.vao__components--metricBreakdownTable-table').empty().append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    acquireTableRow(title) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(title)) {
            return this.cache.rowMap.get(title);
        }

        let rowCells = [];
        rowCells.push(new TableCell({
            field: this.cellFieldMap.name,
            value: title
        }));
        if (this.canIncludeCellField(this.cellFieldMap.occ)) {
            rowCells.push(new TableCell({
                field: this.cellFieldMap.occ,
                value: null,
                isLoading: false
            }));
        }
        if (this.canIncludeCellField(this.cellFieldMap.rooms)) {
            rowCells.push(new TableCell({
                field: this.cellFieldMap.rooms,
                value: null,
                isLoading: false
            }));
        }
        if (this.canIncludeCellField(this.cellFieldMap.rev)) {
            rowCells.push(new TableCell({
                field: this.cellFieldMap.rev,
                value: null,
                isLoading: false
            }));
        }
        if (this.canIncludeCellField(this.cellFieldMap.adr)) {
            rowCells.push(new TableCell({
                field: this.cellFieldMap.adr,
                value: null,
                isLoading: false
            }));
        }
        if (this.canIncludeCellField(this.cellFieldMap.busmix)) {
            rowCells.push(new TableCell({
                field: this.cellFieldMap.busmix,
                value: null,
                isLoading: false
            }));
        }

        let row;
        if (title === this.ctxNameMap[Constants.CTX_PROPERTY_KEY]) {
            row = new TableTotalRow(rowCells);
        } else {
            row = new TableRow(rowCells);
        }
        this.cache.rowMap.set(title, row);
        return row;
    }

    findMatchingCtxStatValue(contextCategory, ctxMetricNow, offsetStatValue) {
        if (
            offsetStatValue
            && typeof offsetStatValue === 'object'
            && contextCategory in offsetStatValue
        ) {
            const match = offsetStatValue[contextCategory].find((ctxMetric) => {
                return ctxMetric.ctx === ctxMetricNow.ctx;
            });
            if (match) {
                return match;
            }
        }
        return null;
    };

    fillRoomsOtbCells(contextCategories, nowStat, thenStat) {
        let res = true;
        contextCategories.forEach(contextCategory => {
            let _res = this.fillRoomsOtbCell(contextCategory, nowStat, thenStat);
            res = res && _res;
        });
        return res;
    }

    fillRoomsOtbCell(contextCategory, nowStat, thenStat) {
        let hasAnyData = false;
        if (
            nowStat
            && typeof nowStat === 'object'
            && contextCategory in nowStat
        ) {
            nowStat[contextCategory].forEach((ctxObj => {
                const ctx = ctxObj.ctx;
                if (typeof ctx !== 'string' || ctx.length === 0) {
                    return;
                }
                const ctxName = this.ctxNameMap[ctx] || ctx;
                const row = this.acquireTableRow(ctxName);
                const cell = row.tableCellPerField.get(this.cellFieldMap.rooms);
                hasAnyData = true;
                let val = ctxObj.value;
                if (!$.isNumeric(val)) {
                    cell.value = Constants.MISSING_STR;
                    cell.sortableValue = null;
                } else {
                    let numVarBlock;
                    const pickupObj = this.findMatchingCtxStatValue(contextCategory, ctxObj, thenStat);
                    if (pickupObj && $.isNumeric(pickupObj.value)) {
                        numVarBlock = new NumericVarianceBlock({
                            numericValue: pickupObj.value,
                            formattedValue: String(pickupObj.value),
                            beEmptyOnEmpty: 'true'
                        });
                    }
                    cell.value = new ActualWithVarianceTableCellValue({
                        actual: val,
                        numericVarianceBlock: numVarBlock
                    });
                    cell.sortableValue = parseFloat(val);
                }
                cell.isLoading = false;
            }));
        }
        return hasAnyData;
    }

    fillRevenueCells(contextCategories, nowStat, thenStat) {
        let res = true;
        contextCategories.forEach(contextCategory => {
            let _res = this.fillRevenueCell(contextCategory, nowStat, thenStat);
            res = res && _res;
        });
        return res;
    }

    fillRevenueCell(contextCategory, nowStat, thenStat) {
        if (
            nowStat
            && typeof nowStat === 'object'
            && contextCategory in nowStat
        ) {
            let hasAnyData = false;
            nowStat[contextCategory].forEach((ctxObj => {
                const ctx = ctxObj.ctx;
                if (typeof ctx !== 'string' || ctx.length === 0) {
                    return;
                }
                const ctxName = this.ctxNameMap[ctx] || ctx;
                const row = this.acquireTableRow(ctxName);
                const cell = row.tableCellPerField.get(this.cellFieldMap.rev);
                hasAnyData = true;
                let val = ctxObj.value;
                if (!$.isNumeric(val)) {
                    val = Constants.MISSING_STR;
                    cell.value = val;
                    cell.sortableValue = null;
                } else {
                    let numVarBlock;
                    const pickupObj = this.findMatchingCtxStatValue(contextCategory, ctxObj, thenStat);
                    if (pickupObj && $.isNumeric(pickupObj.value)) {
                        numVarBlock = new NumericVarianceBlock({
                            numericValue: pickupObj.value,
                            formattedValue: window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                pickupObj.value,
                                Constants.REVENUE_DIGITS,
                                true,
                                this.hotel.locale
                            ),
                            beEmptyOnEmpty: 'true'
                        });
                    }
                    cell.value = new ActualWithVarianceTableCellValue({
                        actual: window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            val,
                            Constants.REVENUE_DIGITS,
                            true,
                            this.hotel.locale
                        ),
                        numericVarianceBlock: numVarBlock
                    });
                    cell.sortableValue = parseFloat(val);
                }
                cell.isLoading = false;
            }));
            return hasAnyData;
        }
    }

    fillADRCells(contextCategories, nowStat, thenStat) {
        let res = true;
        contextCategories.forEach(contextCategory => {
            let _res = this.fillADRCell(contextCategory, nowStat, thenStat);
            res = res && _res;
        });
        return res;
    }

    fillADRCell(contextCategory, nowStat, thenStat) {
        if (
            nowStat
            && typeof nowStat === 'object'
            && contextCategory in nowStat
        ) {
            let hasAnyData = false;
            nowStat[contextCategory].forEach((ctxObj => {
                const ctx = ctxObj.ctx;
                if (typeof ctx !== 'string' || ctx.length === 0) {
                    return;
                }
                const ctxName = this.ctxNameMap[ctx] || ctx;
                const row = this.acquireTableRow(ctxName);
                const cell = row.tableCellPerField.get(this.cellFieldMap.adr);
                hasAnyData = true;
                let val = ctxObj.value;
                if (!$.isNumeric(val)) {
                    val = Constants.MISSING_STR;
                    cell.value = val;
                    cell.sortableValue = null;
                } else {
                    let numVarBlock;
                    const pickupObj = this.findMatchingCtxStatValue(contextCategory, ctxObj, thenStat);
                    if (pickupObj && $.isNumeric(pickupObj.value)) {
                        numVarBlock = new NumericVarianceBlock({
                            numericValue: pickupObj.value,
                            formattedValue: window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                pickupObj.value,
                                Constants.ADR_DIGITS,
                                true,
                                this.hotel.locale,
                                1
                            ),
                            beEmptyOnEmpty: 'true'
                        });
                    }
                    cell.value = new ActualWithVarianceTableCellValue({
                        actual: window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            val,
                            Constants.ADR_DIGITS,
                            true,
                            this.hotel.locale,
                            1
                        ),
                        numericVarianceBlock: numVarBlock
                    });
                    cell.sortableValue = parseFloat(val);
                }
                cell.isLoading = false;
            }));
            return hasAnyData;
        }
    }

    fillOccupancyCells(contextCategories, nowStat, thenStat) {
        let res = true;
        contextCategories.forEach((contextCategory) => {
            let _res = this.fillOccupancyCell(contextCategory, nowStat, thenStat);
            res = res && _res;
        });
        return res;
    }

    fillOccupancyCell(contextCategory, nowStat, thenStat) {
        let hasAnyData = false;
        if (
            nowStat
            && typeof nowStat === 'object'
            && contextCategory in nowStat
        ) {
            nowStat[contextCategory].forEach((ctxObj => {
                const ctx = ctxObj.ctx;
                if (typeof ctx !== 'string' || ctx.length === 0) {
                    return;
                }
                const ctxName = this.ctxNameMap[ctx] || ctx;
                const row = this.acquireTableRow(ctxName);
                const cell = row.tableCellPerField.get(this.cellFieldMap.occ);
                hasAnyData = true;
                const val = ctxObj.value;
                const cellValue = this.makeOccupancyCell(val);
                if (!$.isNumeric(val)) {
                    cell.sortableValue = null;
                    cell.value = cellValue;
                } else {
                    cell.sortableValue = parseFloat(val);
                    let numVarBlock;
                    const pickupObj = this.findMatchingCtxStatValue(contextCategory, ctxObj, thenStat);
                    if (pickupObj && $.isNumeric(pickupObj.value)) {
                        let pickup = formatOccupancy(pickupObj.value);
                        numVarBlock = new NumericVarianceBlock({
                            numericValue: pickupObj.value,
                            formattedValue: pickup,
                            beEmptyOnEmpty: 'true'
                        });
                    }
                    cell.value = new ActualWithVarianceTableCellValue({
                        actual: cellValue,
                        numericVarianceBlock: numVarBlock
                    });
                }
                cell.isLoading = false;
            }));
        }
        return hasAnyData;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.hasFirstUpdated = true;
        this.fill();
    }

    render() {
        return html`
            <div class="vao__components--metricBreakdownTable">
                <div class="vao__components--metricBreakdownTable-table"></div>
            </div>
        `;
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MetricBreakdownTable = MetricBreakdownTable;
customElements.define('vao-metric-breakdown-table', MetricBreakdownTable);
