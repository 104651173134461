export function sendRequest(p_sPoststr, successCallBack) {
    try {

        var url = infinito.vao.controller.urlHelper.apiUrl;
        var apikey = infinito.vao.controller.urlHelper.apiKey;


		p_sPoststr["clid"] = apikey;

        $.ajax({
            type: "POST",
            url: url,
            datatype: 'json',
            async: true,
            data: p_sPoststr,
            success: function (res) {
                successCallBack(res);
                //call back to assginData
            },
            error: function (res) {
                successCallBack(res);
                // or we can even throw it here
                // throw "Ex in ajax error block";
            }
        });
    } catch (ex) {
        console.log(ex.toString());
        var sErrorText = "Error:";
        if (ex.hasOwnProperty("message")) {
            sErrorText += ex.message;
        } else {
            sErrorText += ex.toString();
        }
        throw sErrorText;
    }
}