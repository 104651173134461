import { AppElement, html } from '../AppElement.js';
import TopPickup from "./TopPickup";
import Expand from "../expand/Expand";
import Constants from "../Constants";

export default class TopPickupCardExpand extends AppElement {
    static get properties() {
        return {
            topPickup: { type: Object },
        };
    }

    constructor(props = {}) {
        super();

        this.topPickup = props.topPickup;
    }

    reflow(props = {}) {
        this.topPickup = props.topPickup || this.topPickup;
    }

    renderTopPickup() {
        let contentEl, expandEl;

        if (!(this.topPickup instanceof TopPickup)) {
            return '';
        }

        contentEl = html`<div class="vao__components--topPickupCardExpand-face">${this.topPickup.getDate()}</div>`;

        expandEl = html`
            <div class="vao__components--topPickupCardExpand-body">
                <div class="vao__components--topPickupCardExpand-body-item otb">
                    <div class="text-muted vao__components--topPickupCardExpand-body-item-title">
                        <vao-icon cls="${Constants.ICONS.ROOMS}"></vao-icon>
                        <span>Rooms</span>
                    </div>
                    ${this.topPickup.getRooms()}
                </div>
                <div class="vao__components--topPickupCardExpand-body-item adr">
                    <div class="text-muted vao__components--topPickupCardExpand-body-item-title">
                        <vao-icon cls="${Constants.ICONS.ADR}"></vao-icon>
                        <span>ADR</span>
                    </div>
                    ${this.topPickup.getAdr()}
                </div>
                <div class="vao__components--topPickupCardExpand-body-item rev">
                    <div class="text-muted vao__components--topPickupCardExpand-body-item-title">
                        <vao-icon cls="${Constants.ICONS.REVENUE}"></vao-icon>
                        <span>Revenue</span>
                    </div>
                    ${this.topPickup.getRevenue()}
                </div>
            </div>
        `;

        return new Expand({
            contentEl,
            expandEl,
            canShowIndication: 'false'
        });
    }

    render() {
        return html`
        <div class="vao__components--topPickupCardExpand">
            ${this.renderTopPickup()}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TopPickupCardExpand = TopPickupCardExpand;
customElements.define('vao-top-pickup-card-expand', TopPickupCardExpand);
