import { AppElement, html } from '../AppElement.js';
import {tableTheadStyles} from "./Table";

export default class TableTheadStyleContent extends AppElement {
    static get properties() {
        return {
            current: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.current = props.current || '';
        this.onCLickOption = (typeof props.onCLickOption === 'function') ? props.onCLickOption : () => {};
        this.compClass="vao__components--tableTheadStyleContent";
    }

    reflow(props = {}) {
        this.current = props.current || this.current;
        this.onCLick = (typeof props.onCLickOption === 'function') ? props.onCLick : this.onCLickOption;
    }

    handleOptionClick(newStyle) {
        if (newStyle === this.current) {
            return;
        }
        this.current = newStyle;
        this.onCLickOption(newStyle);
    }

    render() {
        return html`
<div class="${this.compClass}">
    <vao-button  
    text="None" 
    variant="contained" 
    size="small"
    color="${this.current === tableTheadStyles.none ? 'primary' : 'light'}"
    @click="${() => {this.handleOptionClick(tableTheadStyles.none)}}" 
    disabledState="${this.current === tableTheadStyles.none}">                   
    </vao-button>
    <vao-button  
    text="Light" 
    variant="contained" 
    size="small"
    color="${this.current === tableTheadStyles.light ? 'primary' : 'light'}"
    @click="${() => {this.handleOptionClick(tableTheadStyles.light)}}"
    disabledState="${this.current === tableTheadStyles.light}">                   
    </vao-button>
    <vao-button  
    text="Dark" 
    variant="contained" 
    size="small"
    color="${this.current === tableTheadStyles.dark ? 'primary' : 'light'}"
    @click="${() => {this.handleOptionClick(tableTheadStyles.dark)}}"
    disabledState="${this.current === tableTheadStyles.dark}">                   
    </vao-button>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableTheadStyleContent = TableTheadStyleContent;
customElements.define('vao-table-thead-style-content', TableTheadStyleContent);
