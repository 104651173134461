import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import Button from "../button/Button";


const title = 'Define Pickup';
const hotelNamePrefix = 'For';
const text = 'For any day in the future, what would you consider good vs bad pick up? For example, lets say for 21st November 2022 you picked up 8 rooms but for the 12th June only 3. Which one would be good average poor?'
const TYPE_VERY_BAD = 'veryBad';
const TYPE_BAD = 'bad';
const TYPE_AVERAGE = 'average';
const TYPE_GOOD = 'good';
const TYPE_VERY_GOOD = 'veryGood';
const typeToText = {
    [TYPE_VERY_BAD]: 'Very Bad',
    [TYPE_BAD]: 'Bad',
    [TYPE_AVERAGE]: 'Average',
    [TYPE_GOOD]: 'Good',
    [TYPE_VERY_GOOD]: 'Very Good',
}
const typeToColor = {
    [TYPE_VERY_BAD]: Constants.COLORS.HEX.VERY_BAD,
    [TYPE_BAD]: Constants.COLORS.HEX.BAD,
    [TYPE_AVERAGE]: Constants.COLORS.HEX.AVERAGE,
    [TYPE_GOOD]: Constants.COLORS.HEX.GOOD,
    [TYPE_VERY_GOOD]: Constants.COLORS.HEX.VERY_GOOD,
};

export default class PersonalPickup extends AppElement {
    static get properties() {
        return {
            // @internalProperty
            veryGood: { attribute: false },
            good: { attribute: false },
            average: { attribute: false },
            bad: { attribute: false },
            veryBad: { attribute: false },
            isLoading: { attribute: false },
        };
    }

    constructor(props = {}) {
        super();
        this.veryGood = Constants.MISSING_STR;
        this.good = Constants.MISSING_STR;
        this.average = Constants.MISSING_STR;
        this.bad = Constants.MISSING_STR;
        this.veryBad = Constants.MISSING_STR;
    }

    reflow(props = {}) {}

    getHotelName() {
        return window.infinito.vao.controller.storageHelper.getSelectedHotelName() || Constants.MISSING_STR;
    }

    getValue(type) {
        if (type === TYPE_VERY_BAD) {
            return this.veryBad;
        } else if (type === TYPE_BAD) {
            return this.bad;
        } else if (type === TYPE_AVERAGE) {
            return this.average;
        } else if (type === TYPE_GOOD) {
            return this.good;
        } else if (type === TYPE_VERY_GOOD) {
            return this.veryGood;
        } else {
            return Constants.MISSING_STR;
        }
    }

    setValue(type, value) {
        if (type === TYPE_VERY_BAD) {
            this.veryBad = value;
        } else if (type === TYPE_BAD) {
            this.bad = value;
        } else if (type === TYPE_AVERAGE) {
            this.average = value;
        } else if (type === TYPE_GOOD) {
            this.good = value;
        } else if (type === TYPE_VERY_GOOD) {
            this.veryGood = value;
        }
    }

    onCLickSave() {
        let completeCount = 0;
        const expectedCompletions = 5;
        const callback = () => {
            completeCount++;
            if (completeCount >= expectedCompletions) {
                this.isLoading = false;
            }
        }
        this.isLoading = true;
        window.infinito.vao.controller.pickupHelper.setVeryBadPickup(this.veryBad, callback);
        window.infinito.vao.controller.pickupHelper.setBadPickup(this.bad, callback);
        window.infinito.vao.controller.pickupHelper.setAveragePickup(this.average, callback);
        window.infinito.vao.controller.pickupHelper.setGoodPickup(this.good, callback);
        window.infinito.vao.controller.pickupHelper.setVeryGoodPickup(this.veryGood, callback);
    }

    onClickPlus(type, e) {
        if (Button.isEventDisabled(e)) {
            return;
        }
        let maxAllowedValue;
        const currentValue = this.getValue(type);
        if (!$.isNumeric(currentValue)) {
            return;
        }
        const candidateValue = parseInt(currentValue, 10) + 1;
        if (type === TYPE_VERY_BAD) {
            maxAllowedValue = parseInt(this.bad, 10);
        } else if (type === TYPE_BAD) {
            maxAllowedValue = parseInt(this.average, 10);
        } else if (type === TYPE_AVERAGE) {
            maxAllowedValue = parseInt(this.good, 10);
        } else if (type === TYPE_GOOD) {
            maxAllowedValue = parseInt(this.veryGood, 10);
        }
        if (!$.isNumeric(maxAllowedValue)) {
            maxAllowedValue = Number.POSITIVE_INFINITY;
        }
        if (candidateValue >= maxAllowedValue) {
            return;
        }
        this.setValue(type, candidateValue);
    }
    onChangepickup(type,e){
        const currentValue = e.target.value;
        if (!$.isNumeric(currentValue)) {
            return;
        }
        this.setValue(type, currentValue);
    }

    onClickMinus(type, e) {
        if (Button.isEventDisabled(e)) {
            return;
        }
        let minAllowedValue;
        const currentValue = this.getValue(type);
        if (!$.isNumeric(currentValue)) {
            return;
        }
        const candidateValue = parseInt(currentValue, 10) - 1;
        if (type === TYPE_BAD) {
            minAllowedValue = parseInt(this.veryBad, 10);
        }else if (type === TYPE_AVERAGE) {
            minAllowedValue = parseInt(this.bad, 10);
        } else if (type === TYPE_GOOD) {
            minAllowedValue = parseInt(this.average, 10);
        } else if (type === TYPE_VERY_GOOD) {
            minAllowedValue = parseInt(this.good, 10);
        }
        if (!$.isNumeric(minAllowedValue)) {
            minAllowedValue = Number.NEGATIVE_INFINITY;
        }
        if (candidateValue <= minAllowedValue) {
            return;
        }
        this.setValue(type, candidateValue);
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        this.isLoading = true;
        const pickupHelper = window.infinito.vao.controller.pickupHelper;
        $.when(
            pickupHelper.fetchVeryGoodPickup(),
            pickupHelper.fetchGoodPickup(),
            pickupHelper.fetchAveragePickup(),
            pickupHelper.fetchBadPickup(),
            pickupHelper.fetchVeryBadPickup()
        ).then((vGood, good, avg, bad, vBad) => {
            this.isLoading = false;
            this.veryGood = vGood;
            this.good = good;
            this.average = avg;
            this.bad = bad;
            this.veryBad = vBad;
        });
    }


    renderListItem(type, isDisabled = false) {
        const liStyle = `
            display: flex;
            align-items: center;
            justify-content: space-between;
        `;
        const modifierStyle = `
            display: flex;
            flex-wrap: nowrap;
        `;
        const disabledState = isDisabled ? 'true' : 'false';
        const color = typeToColor[type];
        const text = typeToText[type];
        const value = this.getValue(type);
        return html`
            <li 
                class="list-group-item vao__components--personalPickup-${type}" 
                style="${liStyle}"
            >
                <span class="badge" style="border-left: 3px solid ${color};border-radius:0;">${text}</span>
<!--                <span class="vao__components--personalPickup-value">${value}</span>-->
                <input class="form-control vao__components--personalPickup-value" @change=${this.onChangepickup.bind(this, type)} style="width:100px;" type="number" value="${value}" />
                <!-- <span class="vao__components--personalPickup-modifiers" style="${modifierStyle}">
                    <vao-button 
                        .startIcon="${Constants.ICONS.MINUS}" 
                        size="xs"
                        variant="invert"
                        color="danger"
                        @click="${this.onClickMinus.bind(this, type)}"
                        disabledState="${disabledState}"
                        ?disabled="${disabledState === 'true'}"
                    ></vao-button>
                    <div style="width:5px;"></div>
                    <vao-button 
                        .startIcon="${Constants.ICONS.PLUS}" 
                        size="xs"
                        variant="invert"
                        color="success"
                        @click="${this.onClickPlus.bind(this, type)}"
                        disabledState="${disabledState}"
                        ?disabled="${disabledState === 'true'}"
                    ></vao-button>
                </span> -->
            </li>
        `;
    }


    render() {
        return html`
<div class="vao__components--personalPickup">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">${title}</h5>
            <h6 class="card-subtitle mb-2 text-muted">${hotelNamePrefix} ${this.getHotelName()}</h6>
            <p class="card-text">${text}</p>
            <ul class="list-group">
                ${this.renderListItem(TYPE_VERY_GOOD)}
                ${this.renderListItem(TYPE_GOOD)}
                ${this.renderListItem(TYPE_AVERAGE)}
                ${this.renderListItem(TYPE_BAD)}
                ${this.renderListItem(TYPE_VERY_BAD)}
            </ul>
            <br>
            ${this.isLoading
                ? html`<vao-spinner></vao-spinner>`
                : html`<vao-button text="Save" @click="${this.onCLickSave}"></vao-button>`
            }
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PersonalPickup = PersonalPickup;
customElements.define('vao-personal-pickup', PersonalPickup);
