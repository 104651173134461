import { AppElement, html } from '../AppElement.js';
import {makeChannelSubOptionHtml} from "./ChannelOptionHtml";

export const EVENT_ON_CHANNEL_DISTINCTION_SELECT_CHANGE = 'vao-channel-select-distinction-on-change';

export default class ChannelDistinctionSelect extends AppElement {
    static get properties() {
        return {
            selectedChannelId: { type: String },
            channels: { type: Array },
        };
    }

    constructor(props = {}) {
        super();
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId || null;
        this.channels = props.channels || this.channels || [];
    }

    reflow(props = {}) {
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId ;
        this.channels = props.channels || this.channels;
    }

    _onSelect(e) {
        this.selectedChannelId = e.detail.newValue;
        const event = new CustomEvent(EVENT_ON_CHANNEL_DISTINCTION_SELECT_CHANGE, {
            detail: {
                newValue: this.selectedChannelId
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    getChannelsIntoOptions() {
        return (this.channels || []).map((channel) => {
            return {
                value: channel.id,
                text: channel.name,
            }
        });
    }

    optionFormatter(state) {
        const channelName = state.text;
        if (!state.text.includes('|')) {
            return $('<div class="text-muted font-italic" style="font-size:75%;">select sub-channel</div>');
        } else {
            let $el = $(
                '<div style="display: flex;min-width:120px;align-items: center;line-height: 26px;height:26px;">' +
                '</div>'
            );
            const parts = channelName.split('|');
            parts.shift();
            parts.forEach((part) => {
                let $partEl = $(makeChannelSubOptionHtml(part, false));
                $el.append($partEl);
            });
            return $el;
        }
    }

    render() {
        const options = this.getChannelsIntoOptions();

        return html`
            <div class="vao__components--channelDistinctionSelect">
                <vao-select2 
                    .value="${this.selectedChannelId}" 
                    .options="${options}" 
                    @vao-select2-select="${this._onSelect}"
                    .dropdownFormatter="${this.optionFormatter.bind(this)}"
                    .selectionFormatter="${this.optionFormatter.bind(this)}"
                ></vao-select2>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ChannelDistinctionSelect = ChannelDistinctionSelect;
customElements.define('vao-channel-distinction-select', ChannelDistinctionSelect);
