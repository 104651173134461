import Constants from "../Constants";
import MetricBreakdownTable from "./MetricBreakdownTable";
import {sendRequest} from "../App.common";

export default class RoomTypeBreakdownTable extends MetricBreakdownTable {

    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            stayDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super(props);
    }

    reflow(props = {}) {
        super.reflow(props);
    }

    canFill() {
        return (
            this.hasFirstUpdated
            && this.hotel
            && typeof this.hotel === 'object'
            && ('id' in this.hotel)
            && window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            && window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        );
    }

    fill() {
        if (!this.canFill()) {
            super.empty();
            return;
        }

        let table = super.reflowTable([], true);
        super.domTheTable(table);

        let biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    biStatistics.fields.roomsOtbCtx,
                    biStatistics.fields.roomsOtbCtxPickup,
                    biStatistics.fields.revenueCtx,
                    biStatistics.fields.revenueCtxPickup,
                    biStatistics.fields.adrCtx,
                    biStatistics.fields.adrCtxPickup,
                    biStatistics.fields.occupancyCtx,
                    biStatistics.fields.occupancyCtxPickup,
                ],
                recordMatcher: biStatistics.recordMatchers.roomType,
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {

                let obj = procData.occupancyCtx;
                let receivedTs = procData.occupancyCtx[this.stayDate]['receivedTs'];
                let pickupreceivedTs = procData.occupancyCtxPickup[this.stayDate]['offsetReceivedTs'];
                let flag = this;

                let dataObj = {
                    'hotel_id':this.hotel.id,
                    'recordDate':this.recordDate,
                    'pickupOffset':this.pickupSetting,
                    'firstStayDate': this.stayDate,
                    'lastStayDate': this.stayDate,
                    'receivedTs': receivedTs,
                    'pickupreceivedTs': pickupreceivedTs,
                    'op':'getRoomtypeOccupancy',
                    'serviceName':"roomtypesapi.php"
                };
                sendRequest(dataObj,function(res) {
                    let jsonObj = JSON.parse(res);
                    var resultObj = jsonObj.data['occ'];
                    for (var i = 0; i < resultObj.length; i++) {
                        var staydt = resultObj[i]['stayDate'];
                        var roomtypeObj = obj[staydt]['value']['roomType'];
                        for (var j = 0; j < roomtypeObj.length; j++) {
                            if (roomtypeObj[j]['ctx'] == resultObj[i]['name']) {
                                let occ = resultObj[i]['occ'];
                                procData.occupancyCtx[staydt]['value']['roomType'][j]['value'] = occ;
                            }
                        }
                    }

                    var pickupresultObj = jsonObj.data['pickupOcc'];
                    for (var i = 0; i < pickupresultObj.length; i++) {
                        var staydt = pickupresultObj[i]['stayDate'];
                        var roomtypeObj = obj[staydt]['value']['roomType'];
                        for (var j = 0; j < roomtypeObj.length; j++) {
                            if (roomtypeObj[j]['ctx'] == pickupresultObj[i]['name']) {
                                let occ = pickupresultObj[i]['occ'];
                                procData.occupancyCtxPickup[staydt]['value']['roomType'][j]['value'] = occ;
                            }
                        }
                    }
                    flag.fnrenderHTML(procData);
                });


            }
        )
    }

    fnrenderHTML(procData){

        const _procData = (procData || {});
        const roomsOtbCtx = ((_procData.roomsOtbCtx || {})[this.stayDate] || {}).value;
        const roomsOtbCtxPickup = ((_procData.roomsOtbCtxPickup || {})[this.stayDate] || {}).value;
        const revenueCtx = ((_procData.revenueCtx || {})[this.stayDate] || {}).value;
        const revenueCtxPickup = ((_procData.revenueCtxPickup || {})[this.stayDate] || {}).value;
        const adrCtx = ((_procData.adrCtx || {})[this.stayDate] || {}).value;
        const adrCtxPickup = ((_procData.adrCtxPickup || {})[this.stayDate] || {}).value;
        const occupancyCtx = ((_procData.occupancyCtx || {})[this.stayDate] || {}).value;
        const occupancyCtxPickup = ((_procData.occupancyCtxPickup || {})[this.stayDate] || {}).value;
        let foundAnyData = false;

        if (super.fillRoomsOtbCells(
            [
                Constants.CTX_CATEGORIES.ROOMTYPE,
                Constants.CTX_CATEGORIES.PROPERTY
            ],
            roomsOtbCtx,
            roomsOtbCtxPickup
        )) {
            foundAnyData = true;
        }

        if (super.fillRevenueCells(
            [
                Constants.CTX_CATEGORIES.ROOMTYPE,
                Constants.CTX_CATEGORIES.PROPERTY
            ],
            revenueCtx,
            revenueCtxPickup
        )) {
            foundAnyData = true;
        }

        if (super.fillADRCells(
            [
                Constants.CTX_CATEGORIES.ROOMTYPE,
                Constants.CTX_CATEGORIES.PROPERTY
            ],
            adrCtx,
            adrCtxPickup
        )) {
            foundAnyData = true;
        }

        if (super.fillOccupancyCells(
            [
                Constants.CTX_CATEGORIES.ROOMTYPE,
                Constants.CTX_CATEGORIES.PROPERTY
            ],
            occupancyCtx,
            occupancyCtxPickup
        )) {
            foundAnyData = true;
        }

        if (!foundAnyData) {
            super.empty();
        } else {
            super.reflowAllTableRows();
        }

    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.RoomTypeBreakdownTable = RoomTypeBreakdownTable;
customElements.define('vao-room-type-breakdown-table', RoomTypeBreakdownTable);
