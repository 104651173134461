import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

const title = 'Average Total Pickup';
const hotelNamePrefix = 'For';
const text = 'How much revenue on average would you pick up overall on any given day in a 24hr period?';
const inputLabel = 'Amount (to the nearest dollar)';

export default class PersonalAverage extends AppElement {
    static get properties() {
        return {
            // @internalProperty
            value: { attribute: false },
            isLoading: { attribute: false },
        };
    }

    constructor(props = {}) {
        super();
        this.value = '';
    }

    reflow(props = {}) {}

    getHotelName() {
        return window.infinito.vao.controller.storageHelper.getSelectedHotelName() || Constants.MISSING_STR;
    }

    getVAOSettingKey() {
        return window.infinito.vao.model.vaoSettings.obtainHotelIdTotalRevPickupAverageKey(
            window.infinito.vao.controller.storageHelper.getSelectedHotelId()
        );
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        this.isLoading = true;
        window.infinito.vao.model.vaoSettings.fetchSettingByKey(
            window.infinito.vao.controller.storageHelper.getIdentitiesId(),
            this.getVAOSettingKey(),
            (setting) => {
                this.isLoading = false;
                if (!setting || !$.isNumeric(setting.value)) {
                    return;
                }
                this.value = setting.value;
            }
        );
    }

    onCLickSave() {
        if (!$.isNumeric(this.value)) {
            return;
        }
        this.isLoading = true;
        window.infinito.vao.model.vaoSettings.makeSetting(
            window.infinito.vao.controller.storageHelper.getIdentitiesId(),
            this.getVAOSettingKey(),
            this.value,
            () => {
                this.isLoading = false;
            }
        );
    }

    onChangeInput(e) {
        this.value = e.currentTarget.value;
    }

    render() {
        const currencySymbol = window.infinito.vao.controller.moneyHelper.getCurrencySymbol();

        return html`
<div class="vao__components--personalAverage">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">${title}</h5>
            <h6 class="card-subtitle mb-2 text-muted">${hotelNamePrefix} ${this.getHotelName()}</h6>
            <p class="card-text">${text}</p>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">${currencySymbol}</span>
                </div>
                <input 
                    type="number" 
                    class="form-control" 
                    min="0"
                    step="1"
                    aria-label="${inputLabel}" 
                    .value="${this.value}"
                    @change="${this.onChangeInput}"
                >
            </div>
            <br>
            ${this.isLoading
                ? html`<vao-spinner></vao-spinner>`
                : html`<vao-button text="Save" @click="${this.onCLickSave}"></vao-button>`
            }
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PersonalAverage = PersonalAverage;
customElements.define('vao-personal-average', PersonalAverage);
