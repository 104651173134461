import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class TypicalPriceEvaluation extends AppElement {
    static get properties() {
        return {
            low: { type: Number },
            high: { type: Number },
            now: { type: Number },
            title: { type: String },
            subtitle: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.id = AppElement.getUniqueElementId();
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.title = props.title || null;
        this.subtitle = props.subtitle || 'Typical Price';
        this.empty();
    }

    reflow(props = {}) {
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.title = props.title || null;
        this.subtitle = props.subtitle || 'Typical Price';
    }

    empty() {
        this.low = Constants.MISSING_STR;
        this.high = Constants.MISSING_STR;
        this.now = Constants.MISSING_STR;
    }

    updated(changedProperties) {
        if ($.isNumeric(this.low) && $.isNumeric(this.high) && $.isNumeric(this.now)) {
            let range = this.high - this.low;
            if (range === 0) {
                // If the range is 0 (i.e. min === max) then always just put the point in the middle.
                $('#' + this.id).find('.vao__components--typicalPriceEvaluation-now').css({
                    left: 'calc(50% - 5px)'
                });
            } else {
                let nowOffset = this.now - this.low;
                let nowOffsetPercent = Math.floor((nowOffset / range) * 100);
                $('#' + this.id).find('.vao__components--typicalPriceEvaluation-now').css({
                    left: 'calc(' + nowOffsetPercent + '% - 5px)'
                });
            }
        }
        $('#' + this.id).find('.vao__components--typicalPriceEvaluation-now').popover('dispose').popover({
            content: window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                this.now,
                Constants.RATE_DIGITS,
                true,
                this.locale
            ),
            placement: 'top',
            container: $('#' + this.id).find('.vao__components--typicalPriceEvaluation-now')[0],
            boundary: $('#' + this.id).find('.vao__components--typicalPriceEvaluation')[0],
            fallbackPlacement: ['top'],
            display: 'static'
        }).popover('show');
    }

    disconnectedCallback() {
        $('#' + this.id).find('.vao__components--typicalPriceEvaluation-now').popover('dispose');
    }

    render() {
        return html`
<div class="vao__components--typicalPriceEvaluation">
    ${this.title ? 
        html `<div class="vao__components--typicalPriceEvaluation-title">${this.title}</div>` :
        html``}
    <div class="vao__components--typicalPriceEvaluation-subtitle">${this.subtitle}</div> 
    <div class="vao__components--typicalPriceEvaluation-sectorsContainer">
        <div class="vao__components--typicalPriceEvaluation-now"></div>
        <div class="vao__components--typicalPriceEvaluation-sectors">
            <div class="vao__components--typicalPriceEvaluation-sector"></div>
            <div class="vao__components--typicalPriceEvaluation-sector"></div>
            <div class="vao__components--typicalPriceEvaluation-sector"></div>
            <div class="vao__components--typicalPriceEvaluation-sector"></div>
            <div class="vao__components--typicalPriceEvaluation-sector"></div>
        </div>
    </div>
    <div class="vao__components--typicalPriceEvaluation-sectorDesc">
        <div>Min</div>
        <div>Max</div>
    </div>
    <div class="vao__components--typicalPriceEvaluation-legend">
        <div class="vao__components--typicalPriceEvaluation-legendItem low">
            <i class="${Constants.ICONS.CIRCLE}"></i>
            <span>Min</span>
            <span class="dotsep">.</span>
            <span>${
                window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    this.low,
                    Constants.RATE_DIGITS,
                    true,
                    this.locale)
            }</span>
        </div>
        <div class="vao__components--typicalPriceEvaluation-legendItem high">
            <i class="${Constants.ICONS.CIRCLE}"></i>
            <span>Max</span>
            <span class="dotsep">.</span>
            <span>${
                window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    this.high, 
                    Constants.RATE_DIGITS, 
                    true, 
                    this.locale)
            }</span>
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TypicalPriceEvaluation = TypicalPriceEvaluation;
customElements.define('vao-typical-price-evaluation', TypicalPriceEvaluation);
