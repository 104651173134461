import { AppElement, html } from '../AppElement.js';
import ColorArray from "../ColorArray";
import ColorPalette from "./ColorPalette";
import Radio, {radioColors, radioVariants} from "../radio/Radio";

export default class ColorPalettePicker extends AppElement {
    static get properties() {
        return {
            current: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.current = props.current || ColorArray.getDefaultColorPaletteKey();
        this.compClass = 'vao__components--colorPalettePicker';
    }

    render() {
        let colorPalettes = ColorArray.getColorPalettes();

        return html`
        <div class="${this.compClass}">
            ${
            new Radio({
                states: Object.keys(colorPalettes).map(paletteKey => {
                    let palette = colorPalettes[paletteKey];
                    let checked = paletteKey === this.current; 
                    
                    return Radio.makeState(
                        paletteKey,
                        'colorPalettePicker',
                        paletteKey,
                        radioVariants.curve,
                        radioColors.primary,
                        checked,
                        new ColorPalette({
                            palette: palette
                        })
                    );
                }),
                onChange: (e) => {
                    let newPaletteKey = e.target.value;
                    ColorArray.setDefaultColorArray(newPaletteKey);
                    this.current = newPaletteKey;
                }
            })}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ColorPalettePicker = ColorPalettePicker;
customElements.define('vao-color-palette-picker', ColorPalettePicker);
