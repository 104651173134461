import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StoryInternalPickupSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = props.pickupSetting;

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        if ('pickupSetting' in props) {
            this.pickupSetting = props.pickupSetting;
        }
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            !$.isNumeric(this.pickupSetting)
            || typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                pickupOffset: this.pickupSetting,
                fields: [
                    modelBiStats.fields.revenuePickup,
                    modelBiStats.fields.revenuePickupTrend,
                ],
                evolutionRange: this.evolutionRange
            }),
            (data, procData = {}) => {
                const revPUVal = ((procData.revenuePickup || {})[this.stayDate] || {}).value || null;
                const revPUTrend = ((procData.revenuePickupTrend || {})[this.stayDate] || {}).value || null;

                if (
                    $.isNumeric(revPUVal)
                    && typeof revPUTrend === 'object'
                    && 'median' in revPUTrend
                    && $.isNumeric(revPUTrend.median)
                ) {
                    const assessment = window.infinito.vao.controller.pickupHelper.determinePickupAssessment(
                        revPUVal,
                        revPUTrend.median
                    );
                    if (assessment === 'above') {
                        this.subtitle = 'Ahead of trend';
                        this.badgeClass = 'badge-success';
                    } else if (assessment === 'below') {
                        this.subtitle = 'Below trend';
                        this.badgeClass = 'badge-warning';
                    } else if (assessment === 'inline') {
                        this.subtitle = 'Inline with trend';
                        this.badgeClass = 'badge-primary';
                    } else {
                        this.subtitle = Constants.MISSING_STR;
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalPickupSubtitle = StoryInternalPickupSubtitle;
customElements.define('vao-story-internal-pickup-subtitle', StoryInternalPickupSubtitle);
