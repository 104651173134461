
import { AppElement, html } from '../AppElement.js';
import AnnualTileSingleTable from "./AnnualTileSingleTable";

export const MODE_GROUP = 'group';
export const MODE_SINGLE = 'single';

export default class AnnualTilePage extends AppElement {
    static get properties() {
        return {
            mode: { type: String },
            hotelOrGroup: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number }
        };
    }


    constructor(props = {}) {
        super();
        this.mode = props.mode
            || this.mode
            || window.infinito.vao.controller.storageHelper.getAppMode();
        this.hotelOrGroup = props.hotelOrGroup
            || this.hotelOrGroup
            || (this.mode === MODE_SINGLE
                    ? window.infinito.vao.controller.storageHelper.getSelectedHotel()
                    : window.infinito.vao.controller.storageHelper.getSelectedGroup()
            );
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );
    }

    reflow(props = {}) {
        this.mode = props.mode || this.mode;
        this.hotelOrGroup = props.hotelOrGroup || this.hotelOrGroup;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    renderSingle() {
        var body = new window.vao.components.AnnualTileSingleTable({
            hotel: infinito.vao.controller.storageHelper.getSelectedHotel(),
            recordDate: window.infinito.vao.controller.storageHelper.getCurrentDate(),
            pickupSetting:window.infinito.vao.controller.storageHelper.getPickupOffset()
        });
        return html`
            <div class="vao__components--AnnualTileSingle">
                <div class="rounded border bg-white">
                    <div
                            class="vao__components--AnnualTileSingle-filters"
                            style="display:flex;align-items:center;flex-wrap:wrap;padding: 0rem 1rem 0 1rem;"
                    >
                    <span style="margin-right:15px;margin-top: 15px;">
                    <input type="month" name="start_date" id="start_date"/>
                    <input type="month" name="end_date" id="end_date"/>
                    </span>
                    </div>
                    <hr>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-2">
                                <div class="card">
                                    <div class="card-header" style="text-align: center;font-weight: bold;">
                                        RoomNights
                                    </div>
                                    <div class="card-body" style="padding-left: 5px;padding-right: 5px;text-align: center;">
                                        <span style="font-size: 18px;font-weight: bold;"
                                              id="vao__annual--roomnights_absolute">-</span><br/>
                                        <span style="font-size: 12px;font-weight: 400;"
                                              id="vao__annual--roomnights_relative">-</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card">
                                    <div class="card-header" style="text-align: center;font-weight: bold;">
                                        ADR
                                    </div>
                                    <div class="card-body" style="padding-left: 5px;padding-right: 5px;text-align: center;">
                                        <span style="font-size: 18px;font-weight: bold;"
                                              id="vao__annual--adr_absolute">-</span><br/>
                                        <span style="font-size: 12px;font-weight: 400;"
                                              id="vao__annual--adr_relative">-</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card">
                                    <div class="card-header" style="text-align: center;font-weight: bold;">
                                        Revenue
                                    </div>
                                    <div class="card-body" style="padding-left: 5px;padding-right: 5px;text-align: center;">
                                        <span style="font-size: 18px;font-weight: bold;"
                                              id="vao__annual--rev_absolute">-</span><br/>
                                        <span style="font-size: 12px;font-weight: 400;"
                                              id="vao__annual--rev_relative">-</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card">
                                    <div class="card-header" style="text-align: center;font-weight: bold;">
                                        Budget
                                    </div>
                                    <div class="card-body" style="padding-left: 5px;padding-right: 5px;text-align: center;">
                                        <span style="font-size: 18px;font-weight: bold;"
                                              id="vao__annual--budgetroomnights_absolute">-</span><br/>
                                        <span style="font-size: 12px;font-weight: 400;"
                                              id="vao__annual--budgetroomnights_relative">-</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card">
                                    <div class="card-header" style="text-align: center;font-weight: bold;">
                                        Budget Forecast
                                    </div>
                                    <div class="card-body" style="padding-left: 5px;padding-right: 5px;text-align: center;">
                                        <span style="font-size: 18px;font-weight: bold;"
                                              id="vao__annual--forecastroomnights_absolute">-</span><br/>
                                        <span style="font-size: 12px;font-weight: 400;"
                                              id="vao__annual--forecastroomnights_relative">-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top:.5rem;">
                        ${body}
                    </div>
                </div>
            </div>
        `;
    }

    renderGroup() {
        if (!infinito.vao.controller.storageHelper.isInSingleMode()) {
            $('.vao__annual').empty().append(
                infinito.components.noPage.render({
                    text: 'Page not available in group mode'
                })
            ).show();
            return;
        }
    }

    render() {
        return html`
<div class="vao__components--AnnualTilePage">
    ${this.mode === MODE_GROUP ? this.renderGroup() : this.renderSingle()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.AnnualTilePage = AnnualTilePage;
customElements.define('vao-page-annual', AnnualTilePage);
