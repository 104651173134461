import { AppElement, html } from '../AppElement.js';
import {OPTION_30_DAYS} from "../dateSelect/StayDateRangeSelect";

export default class PageDesktopSingle extends AppElement {
    static get properties() {
        return {
            hotel: {type: Object},
            recordDate: {type: String},
            pickupSetting: {type: Number},

            // @internalProperty
            rangeValue: { attribute: false },
            startDate: {attribute: false},
            endDate: {attribute: false},
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel || this.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );

        this.rangeValue = OPTION_30_DAYS;
        this.startDate = props.startDate || this.recordDate;
        this.endDate = props.endDate
            || window.infinito.vao.controller.dateHelper.addDaysToDateString(this.startDate, 30);
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    onChangeStayDateRange(e) {
        this.rangeValue = e.detail.newValue;
        this.startDate = e.detail.rangeData.startDate;
        this.endDate = e.detail.rangeData.endDate;
    }

    render() {
        return html`
<div class="vao__components--pageDesktopSingle">
    <div class="rounded border bg-white">
        <div class="vao__components--pageDesktopSingle-filters" 
            style="display:flex;align-items:center;flex-wrap:wrap;padding: 1rem 1rem 0 1rem;"
        >
            <div style="position: relative;margin: 0 auto;">
                <div class="header__hotelName btn" id="occmvnt">Occ movement</div>
                <div class="header__hotelName btn" id="pacemvmt" style="margin-left: 5px;">Pace changes</div>
                <div class="header__hotelName btn" id="forecastmvmt" style="margin-left: 5px;">Forecast changes</div>
            </div>
            
        </div>
        <hr>
        <div style="margin-top:.5rem;">
            <vao-page-rules-single-table
                .hotel="${this.hotel}"
                .recordDate="${this.recordDate}"
                .pickupSetting="${this.pickupSetting}"
                startDate="${this.startDate}"
                endDate="${this.endDate}"
            ></vao-page-rules-single-table>
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktopSingle = PageDesktopSingle;
customElements.define('vao-page-rules-single', PageDesktopSingle);
