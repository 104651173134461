import { AppElement, html } from '../AppElement.js';
import {getDisplayableTextForCategory, isTZTimestampsBalanced} from "../CtxUtils";
import Constants from "../Constants";

export default class ContextBalanceDescBlock extends AppElement {
    static get properties() {
        return {
            tz: { type: String },
            balanceTxt: { type: String },
            category: { type: String },
            primaryRecTs: { type: String },
            primaryOffsetRecTs: { type: String },
            ctxRecTs: { type: String },
            ctxOffsetRecTs: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.tz = props.tz;
        this.category = props.category;
        this.primaryRecTs = props.primaryRecTs;
        this.primaryOffsetRecTs = props.primaryOffsetRecTs;
        this.ctxRecTs = props.ctxRecTs;
        this.ctxOffsetRecTs = props.ctxOffsetRecTs;
        this.balanceTxt = props.balanceTxt;

        this.dateHelper = window.infinito.vao.controller.dateHelper;
    }

    reflow(props = {}) {
        this.tz = props.tz || this.tz;
        this.category = props.category || this.category;
        this.primaryRecTs = props.primaryRecTs || this.primaryRecTs;
        this.primaryOffsetRecTs = props.primaryOffsetRecTs || this.primaryOffsetRecTs;
        this.ctxRecTs = props.ctxRecTs || this.ctxRecTs;
        this.ctxOffsetRecTs = props.ctxOffsetRecTs || this.ctxOffsetRecTs;
        this.balanceTxt = props.balanceTxt || this.balanceTxt;
    }

    formatTs(ts) {
        if (ts) {
            if (this.tz) {
                let formatted = this.dateHelper.convertTzStringTimezone(
                    ts,
                    this.tz,
                    'DD MMM hh:mma'
                );
                return html`<span title="Timezone: ${this.tz}">${formatted}</span>`
            } else {
                return ts;
            }
        } else {
            return Constants.MISSING_STR;
        }
    }

    renderComparisonForRecTsForOffsetTs(recTs, offsetTs) {
        if (!recTs || !offsetTs) {
            return Constants.MISSING_STR;
        }
        const timeDiff = this.dateHelper.calcAndFormatTimeBetween(recTs, offsetTs);
        if (this.dateHelper.isFormattedTimeBetweenEmpty(timeDiff)) {
            return html`<span class="badge badge-success">${Constants.STRINGS.MATCH.toLowerCase()}</span>`;
        } else {
            const txt = `${timeDiff.replace('-', '')} ${Constants.STRINGS.APART.toLowerCase()}`;
            return html`<span class="badge badge-warning">${txt}</span>`;
        }
    }

    getBalanceStyle() {
        return 'margin:.5rem 0;padding:1rem;';
    }

    getBalanceValueStyle() {
        return `color:${Constants.COLORS.HEX.THIRDLY}`;
    }

    isStringsSameInsensitive(str1, str2) {
        return (str1 || '').toLowerCase() === (str2 || '').toLowerCase();
    }

    renderBalanceTxt() {
        if (this.isStringsSameInsensitive(this.balanceTxt, Constants.STRINGS.BALANCED)) {
            return html`<span class="badge badge-success">${this.balanceTxt}</span>`;
        } else if (this.isStringsSameInsensitive(this.balanceTxt, Constants.STRINGS.IMBALANCED)) {
            return html`<span class="badge badge-warning">${this.balanceTxt}</span>`;
        } else {
            return html`<span class="badge badge-light">${this.balanceTxt}</span>`;
        }
    }

    renderVerboseText() {
        const isBalancedRecTs = isTZTimestampsBalanced(this.primaryRecTs, this.ctxRecTs);
        const isBalancedOffsetRecTs = isTZTimestampsBalanced(this.primaryOffsetRecTs, this.ctxOffsetRecTs);
        let txtParts = [];
        if (this.primaryRecTs && this.ctxRecTs) {
            if (isBalancedRecTs) {
                txtParts.push(
                    'All data in the application for current snapshot actual\'s will match with breakdown data.'
                );
            } else {
                txtParts.push(
                    'Breakdown actual data may NOT match with other actual\'s in the application.'
                );
            }
        }
        if (this.primaryOffsetRecTs && this.ctxOffsetRecTs) {
            if (isBalancedOffsetRecTs) {
                txtParts.push(
                    'Breakdown pickup values should match with other pickup values in the application.'
                );
            } else {
                txtParts.push(
                    'Breakdown pickup values may NOT match with other pickup values in the application.'
                );
            }
        }
        return txtParts.join(' ');
    }

    render() {
        return html`
<div class="vao__components--contextBalanceDescBlock">
    <div 
    class="vao__components--contextBalanceDescBlock-cat" 
    style="display:flex;justify-content:space-between;align-items:center;margin-bottom:.5rem;"
    >
        <div>
            ${this.renderBalanceTxt()}
        </div>
        <div class="" style="padding:.3rem;">
            ${Constants.STRINGS.CONTEXT}: 
            <span class="text-primary font-weight-bold">${getDisplayableTextForCategory(this.category)}</span>
        </div>
    </div>
    <div class="vao__components--contextBalanceDescBlock-verboseText">
        ${this.renderVerboseText()}
    </div>
    <div class="vao__components--contextBalanceDescBlock-bal bg-light rounded" style="${this.getBalanceStyle()}">
        <div>${Constants.STRINGS.SNAPSHOT}</div>
        <div>
            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="vao__components--contextBalanceDescBlock-bal-name">${Constants.STRINGS.PRIMARY}</span>
                    <span 
                    class="vao__components--contextBalanceDescBlock-bal-val" 
                    style="${this.getBalanceValueStyle()}"
                    >
                        ${this.formatTs(this.primaryRecTs)}
                    </span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="vao__components--contextBalanceDescBlock-bal-name">${Constants.STRINGS.CONTEXT}</span>
                    <span 
                    class="vao__components--contextBalanceDescBlock-bal-val" 
                    style="${this.getBalanceValueStyle()}"
                    >
                        ${this.formatTs(this.ctxRecTs)}
                    </span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="vao__components--contextBalanceDescBlock-bal-name">${Constants.STRINGS.COMPARE}</span>
                    <span 
                    class="vao__components--contextBalanceDescBlock-bal-val-diff" 
                    >
                        ${this.renderComparisonForRecTsForOffsetTs(this.primaryRecTs, this.ctxRecTs)}
                    </span>
                </li>
            </ul>
        </div>
    </div>
    <div class="vao__components--contextBalanceDescBlock-bal bg-light rounded" style="${this.getBalanceStyle()}">
        <div>Offset</div>
        <div>
            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="vao__components--contextBalanceDescBlock-bal-name">${Constants.STRINGS.PRIMARY}</span>
                    <span 
                    class="vao__components--contextBalanceDescBlock-bal-val" 
                    style="${this.getBalanceValueStyle()}"
                    >
                        ${this.formatTs(this.primaryOffsetRecTs)}
                    </span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="vao__components--contextBalanceDescBlock-bal-name">${Constants.STRINGS.CONTEXT}</span>
                    <span 
                    class="vao__components--contextBalanceDescBlock-bal-val" 
                    style="${this.getBalanceValueStyle()}"
                    >
                        ${this.formatTs(this.ctxOffsetRecTs)}
                    </span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span class="vao__components--contextBalanceDescBlock-bal-name">${Constants.STRINGS.COMPARE}</span>
                    <span 
                    class="vao__components--contextBalanceDescBlock-bal-val-diff" 
                    >
                        ${this.renderComparisonForRecTsForOffsetTs(this.primaryOffsetRecTs, this.ctxOffsetRecTs)}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ContextBalanceDescBlock = ContextBalanceDescBlock;
customElements.define('vao-context-balance-desc-block', ContextBalanceDescBlock);
