import { AppElement, html } from '../AppElement.js';

export default class PageGroupInsights extends AppElement {
    static get properties() {
        return {
            recordDate: { type: String },
            hotelGroup: { type: Object },
            performanceMetric: { type: String }
        };
    }

    constructor(props = {}) {
        super(props);
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.hotelGroup = props.hotelGroup || window.infinito.vao.controller.storageHelper.getSelectedGroup();
        this.performanceMetric = props.performanceMetric || 'roomsOtb';
        this.compClass = 'vao__components--pageGroupInsights';
    }

    updateMetric(metric) {
        this.performanceMetric = metric;
    }

    getButtonStyle(metric) {
        return this.performanceMetric === metric
            ? 'background-color: white; color: black; font-weight: medium; padding: 6px 12px; margin: 0 8px; border-radius: 8px; border: none;'
            : 'background-color: #f2f4f5; color: grey; font-weight: normal; padding: 6px 12px; margin: 0 8px; border-radius: 8px; border: none;';
    }

    render() {
        return html`
            <div class="${this.compClass} rounded border">

                <div class="pageGroupInsights-metric-selector">
                    <button class="btn"
                            style="${this.getButtonStyle('roomsOtb')}"
                            @click="${() => this.updateMetric('roomsOtb')}">
                        Rooms Sold
                    </button>

                    <button class="btn"
                            style="${this.getButtonStyle('occupancy')}"
                            @click="${() => this.updateMetric('occupancy')}">
                        Occupancy
                    </button>

                    <button class="btn"
                            style="${this.getButtonStyle('revenue')}"
                            @click="${() => this.updateMetric('revenue')}">
                        Revenue
                    </button>

                    <button class="btn"
                            style="${this.getButtonStyle('adr')}"
                            @click="${() => this.updateMetric('adr')}">
                        ADR
                    </button>
                </div>

                <hr>

                <vao-performance-forecast-table-wrapper .recordDate="${this.recordDate}"
                                                        .hotels="${this.hotelGroup.hotels}"
                                                        .performanceMetric="${this.performanceMetric}"></vao-performance-forecast-table-wrapper>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageGroupInsights = PageGroupInsights;
customElements.define('vao-page-group-insights', PageGroupInsights);
