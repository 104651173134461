import { html } from '../AppElement.js';
import Constants from '../Constants';
import NumericVarianceBlock from '../numericVarianceBlock/NumericVarianceBlock';
import PickupPerMonthPopup from './PickupPerMonthPopup';
import { sendRequest } from '../App.common';


export default class PickupPerMonthHotelPopup extends PickupPerMonthPopup {
    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            pickupSetting: { type: String }
        };
    }

    constructor(props = {}) {
        super(props);
        this.isgroup = infinito.vao.controller.storageHelper.getState();
        this.hotel = props.hotel;
        this.cntr = 0;
        this.MTDObject = [];
        this.MTDLyObject = [];
        this.MonthsObject = [];
    }

    reflow(props = {}) {
        super.reflow(props);
        this.hotel = props.hotel || this.hotel;
        this.cntr = 0;
    }

    getTableStateKey() {
        return 'hotel';
    }

    formatPercentageValue(unformattedValue) {
        var percent = parseFloat(unformattedValue * 100);
        if (!Number.isNaN(percent)) {
            return percent.toFixed(1) + '%';
        }
        return unformattedValue;
    }

    fnrenderHTML(res) {
        // adrPickupTwelveMonths
        let obj = JSON.parse(res);
        if (!obj || Object.keys(obj).length === 0) {
            super.empty();
            return;
        }

        let foundAnyData = false;
        let _procData = (obj) ? obj.data : {};
        let cell;
        let row;

        let roomsOtbTwelveMonths = super.objectify((_procData.roomsOtbTwelveMonths || {}).value);
        let availableRoomsTwelveMonths = super.objectify((_procData.availableRoomsTwelveMonths || {}).value);
        let roomsOtbPickupTwelveMonths = super.objectify((_procData.roomsOtbPickupTwelveMonths || {}).value);
        let adrTwelveMonths = super.objectify((_procData.adrTwelveMonths || {}).value);
        let adrPickupTwelveMonths = super.objectify((_procData.adrPickupTwelveMonths || {}).value);
        let revenueTwelveMonths = super.objectify((_procData.revenueTwelveMonths || {}).value);
        let revenuePickupTwelveMonths = super.objectify((_procData.revenuePickupTwelveMonths || {}).value);
        let roomsOtbMonthToDay = (_procData.roomsOtbMonthToDay || {}).value;
        let adrMonthToDay = (_procData.adrMonthToDay || {}).value;
        let occMonthToDay = (_procData.occMonthToDay || {}).value;
        let revenueMonthToDay = (_procData.revenueMonthToDay || {}).value;

        // MTD Row.
        row = this.cache.rowMap.get('MTD');
        /* OCC */
        cell = row.tableCellPerField.get(this.cellFieldMap.occ);
        if ($.isNumeric(occMonthToDay)) {
            foundAnyData = true;
            cell.value = occMonthToDay + '%';
        } else {
            cell.value = null;
        }
        cell.isLoading = false;


        cell = row.tableCellPerField.get(this.cellFieldMap.rms);
        if ($.isNumeric(roomsOtbMonthToDay)) {
            foundAnyData = true;
            cell.value = roomsOtbMonthToDay;
        } else {
            cell.value = null;
        }
        cell.isLoading = false;
        cell = row.tableCellPerField.get(this.cellFieldMap.adr);
        if ($.isNumeric(adrMonthToDay)) {
            foundAnyData = true;
            let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                adrMonthToDay,
                Constants.ADR_DIGITS,
                true,
                this.hotel.locale,
                1
            );

            cell.value = formatted;
        } else {
            cell.value = null;
        }
        cell.isLoading = false;
        cell = row.tableCellPerField.get(this.cellFieldMap.rev);
        if ($.isNumeric(revenueMonthToDay)) {
            foundAnyData = true;
            let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                revenueMonthToDay,
                Constants.REVENUE_DIGITS,
                true,
                this.hotel.locale
            );
            cell.value = formatted;
        } else {
            cell.value = null;
        }
        cell.isLoading = false;


        // MTD Occ
        let dataObj = {
            hotel_id: this.hotel.id,
            recordDate: this.recordDate,
            op: 'getFlashHotelPickupPerMonthOccMTD',
            serviceName: 'flashsingleapi.php'
        };
        let flag = this;

        sendRequest(dataObj, function (res) {
            let obj = JSON.parse(res);

            cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
            cell.value = '';
            if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                cell.value = obj.data.Bfc.bfcMTDtotalOcc;
            } else {
                cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
            cell.value = '';
            cell.value = obj.data.Bu.BMTDtotalOcc;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
            cell.value = '';
            cell.value = obj.data.Bfc.bfcMTDtotalOtb === 0 ? '-' : obj.data.Bfc.bfcMTDtotalOtb;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
            cell.value = '';
            cell.value = obj.data.Bu.BMTDtotalOtb === 0 ? '-' : obj.data.Bu.BMTDtotalOtb;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
            cell.value = '';
            cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bfc.bfcMTDtotalAdr,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale,
                1
            );
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
            cell.value = '';
            cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bu.BMTDtotalAdr,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale,
                1
            );
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
            cell.value = '';
            cell.value = obj.data.Bfc.bfcMTDtotalRev === 0 ? '-' : window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bfc.bfcMTDtotalRev,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale
            );
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
            cell.value = '';
            cell.value = obj.data.Bu.BMTDtotalRev === 0 ? '-' : window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bu.BMTDtotalRev,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale
            );
            cell.isLoading = false;
        });


        /** ************* */
        /** ***====PICKPUP PER MONTH MONTH WISE ROWS====**** */
        /** ************* */
        super.forEachMonthKey((month, yearMonKey) => {
            let row = this.cache.rowMap.get(month);
            let roomsOtbTwelveMonthsVal = roomsOtbTwelveMonths[yearMonKey];
            let availableRoomsTwelveMonthsVal = availableRoomsTwelveMonths[yearMonKey];
            let adrTwelveMonthsVal = adrTwelveMonths[yearMonKey];
            let revenueTwelveMonthsVal = revenueTwelveMonths[yearMonKey];

            // Occ
            let dataObj = {
                hotel_id: this.hotel.id,
                recordDate: this.recordDate,
                yearMonKey: yearMonKey,
                op: 'getFlashHotelPickupPerMonthOcc',
                serviceName: 'flashsingleapi.php'
            };
            let flag = this;
            sendRequest(dataObj, function (res) {
                let obj = JSON.parse(res);
                /** *****==============******* */
                /** * Pickup Per Month Table * */
                /** *****==============******* */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                cell.value = '';
                if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc;
                } else {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOcc;
                cell.isLoading = false;

                /** *******=================******* */

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOtb;
                cell.isLoading = false;

                /** ***********================*********** */

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;

                /** **************================************** */

                cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;
                /** *****==============******* */
                /** * Pickup Per Month Table * */
                /** *****==============******* */
            });
            // Occ
            cell = row.tableCellPerField.get(this.cellFieldMap.occ);
            if ($.isNumeric(availableRoomsTwelveMonthsVal)) {
                foundAnyData = true;
                cell.value = html`
                    <div style="flex-direction:column;">
                        <div>${availableRoomsTwelveMonthsVal + '%'}</div>

                    </div>`;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            // Rooms
            cell = row.tableCellPerField.get(this.cellFieldMap.rms);
            if ($.isNumeric(roomsOtbTwelveMonthsVal)) {
                foundAnyData = true;
                cell.value = html`
                        <div style="flex-direction:column;">
                            <div>${roomsOtbTwelveMonthsVal}</div>

                        </div>`;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;


            // ADR
            cell = row.tableCellPerField.get(this.cellFieldMap.adr);
            if ($.isNumeric(adrTwelveMonthsVal)) {
                foundAnyData = true;
                let varBlock;

                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    adrTwelveMonthsVal,
                    Constants.ADR_DIGITS,
                    true,
                    this.hotel.locale,
                    1
                );
                let cellVal = html`<div style="flex-direction:column;">
                        <div>${formatted}</div>

                    </div>`;
                cell.value = cellVal;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            // Rev
            cell = row.tableCellPerField.get(this.cellFieldMap.rev);
            if ($.isNumeric(revenueTwelveMonthsVal)) {
                foundAnyData = true;
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    revenueTwelveMonthsVal,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.hotel.locale
                );
                let cellVal = html`<div style="flex-direction:column;">
                        <div>${formatted}</div>

                    </div>`;
                cell.value = cellVal;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;
        });

        if (!foundAnyData) {
            super.empty();
        }
    }

    renderCaret(numericValue) {
        if (numericValue < 0) {
            return html`<vao-icon cls="${Constants.ICONS.CARET_DOWN}" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>`;
        } if (numericValue > 0) {
            return html`<vao-icon cls="${Constants.ICONS.CARET_UP}" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>`;
        }
        return html``;
    }

    renderValue(formattedValue) {
        if (typeof formattedValue === 'string' && formattedValue.length > 0) {
            let val = '';
            formattedValue = parseFloat(formattedValue);
            if (formattedValue < 0) {
                val = '(' + Math.abs(formattedValue) + ')';
            } else {
                val = formattedValue;
            }
            return html`<div class="vao__components--numericVarianceBlock-val">${String(val) + '%'}</div>`;
        }
        return html`<div class="vao__components--numericVarianceBlock-val">${Constants.MISSING_STR}</div>`;
    }

    makeColorClass(numericValue) {
        if (numericValue < 0) {
            return 'text-danger';
        } if (numericValue > 0) {
            return 'text-success';
        }
        return '';
    }

    fetchvariancemonthsdata() {
        var flag = this;
        /** *****
         * FOR VARIANCE LOAD
         * ***** */

        if (flag.MTDObject.length === 0) {
            let dataObj = {
                hotel_id: flag.hotel.id,
                recordDate: flag.recordDate,
                isoffset: 0,
                isLy: 1,
                op: 'getFlashHotelPickupPerMonthOccMTD',
                serviceName: 'flashsingleapi.php'
            };
            sendRequest(dataObj, function (res) {
                let foundAnyData = false;
                let cell;
                let row;
                row = flag.cache.rowMap.get('MTD');
                let obj = JSON.parse(res);
                flag.MTDObject = obj;
                let rms = obj.data.roomsOtb;
                let adr = obj.data.adr;
                let rev = obj.data.revenueNet;
                /** ***************================***************** */
                // MTD RMS.
                cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                if ($.isNumeric(obj.data.roomsOtb)) {
                    foundAnyData = true;
                    cell.value = obj.data.roomsOtb;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;
                cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                if ($.isNumeric(obj.data.adr)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.adr,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;
                cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                if ($.isNumeric(obj.data.revenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.revenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;
                let occ = obj.data.occupancy;
                cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                cell.value = occ + '%';
                cell.isLoading = false;
                /** ******========FORECAST AND BUDGET========****** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                cell.value = '';
                let FcOccVar = '';
                let BFcMTDtotalOcc = parseFloat(obj.data.Bfc.bfcMTDtotalOcc);
                if ($.isNumeric(occ) > 0 && $.isNumeric(BFcMTDtotalOcc)) {
                    FcOccVar = (occ - BFcMTDtotalOcc).toFixed(2);
                }
                let BFCOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(FcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(FcOccVar)}
                            ${flag.renderValue(FcOccVar)}
                        </div>`;
                cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BFCOCCnumVarBlock}</div></div>`;
                cell.isLoading = false;
                // BUDGET OCC
                cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                cell.value = '';
                let BFcOccVar = '';
                let BMTDtotalOcc = parseFloat(obj.data.Bu.BMTDtotalOcc);
                if ($.isNumeric(occ) > 0 && $.isNumeric(BMTDtotalOcc)) {
                    BFcOccVar = (occ - BMTDtotalOcc).toFixed(2);
                }
                let BOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(BFcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(BFcOccVar)}
                            ${flag.renderValue(BFcOccVar)}
                        </div>`;

                cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BOCCnumVarBlock}</div></div>`;
                cell.isLoading = false;
                // RMS B AND FC
                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                cell.value = '';
                let bfcMTDtotalOtb = '';
                if (rms > 0 && obj.data.Bfc.bfcMTDtotalOtb > 0) {
                    bfcMTDtotalOtb = rms - obj.data.Bfc.bfcMTDtotalOtb;
                }
                const numVarBlock = new NumericVarianceBlock({
                    numericValue: bfcMTDtotalOtb,
                    formattedValue: String(bfcMTDtotalOtb),
                    beEmptyOnEmpty: 'true'
                });
                const varBlock = numVarBlock.isEmpty()
                    ? html`-`
                    : html`<div style="font-size:.7rem">${numVarBlock}</div>`;
                cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>
                            </div>`;
                cell.isLoading = false;
                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                cell.value = '';
                let BMTDtotalOtb = '-';
                if (rms > 0 && obj.data.Bu.BMTDtotalOtb > 0) {
                    BMTDtotalOtb = rms - obj.data.Bu.BMTDtotalOtb;
                }
                const BnumVarBlock = new NumericVarianceBlock({
                    numericValue: BMTDtotalOtb,
                    formattedValue: String(BMTDtotalOtb),
                    beEmptyOnEmpty: 'true'
                });
                const BvarBlock = BnumVarBlock.isEmpty()
                    ? html`-`
                    : html`<div style="font-size:.7rem">${BnumVarBlock}</div>`;
                cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${BvarBlock}</div>
                            </div>`;
                cell.isLoading = false;
                // ADR B AND FC
                cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                cell.value = '';
                let bfcadrvar = '-';
                if ($.isNumeric(adr) > 0 && $.isNumeric(obj.data.Bfc.bfcMTDtotalAdr)) {
                    bfcadrvar = adr - obj.data.Bfc.bfcMTDtotalAdr;
                }
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    bfcadrvar,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                const _varBlock = new NumericVarianceBlock({
                    numericValue: bfcadrvar,
                    formattedValue: formatted,
                    beEmptyOnEmpty: 'true'
                });
                let bfcMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlock}</div>`;
                let cellVal = html`<div style="flex-direction:column;">
                                <div>${bfcMTDtotalAdrvarBlock}</div>
    
                            </div>`;
                cell.value = cellVal;
                cell.isLoading = false;
                cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                cell.value = '';
                let Badrvar = '-';
                if ($.isNumeric(adr) > 0 && $.isNumeric(obj.data.Bu.BMTDtotalAdr)) {
                    Badrvar = adr - obj.data.Bu.BMTDtotalAdr;
                }
                let formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    Badrvar,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                const _varBlockBadr = new NumericVarianceBlock({
                    numericValue: Badrvar,
                    formattedValue: formatted1,
                    beEmptyOnEmpty: 'true'
                });
                let BMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockBadr}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalAdrvarBlock}</div></div>`;
                cell.isLoading = false;
                // REV B AND FC
                cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                cell.value = '';
                let BfcRev = '-';
                if ($.isNumeric(rev)
                    && rev > 0 && $.isNumeric(obj.data.Bfc.bfcMTDtotalRev)
                    && obj.data.Bfc.bfcMTDtotalRev > 0) {
                    BfcRev = rev - obj.data.Bfc.bfcMTDtotalRev;
                }
                let formatted2 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    BfcRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                const _varBlockFCRev = new NumericVarianceBlock({
                    numericValue: BfcRev,
                    formattedValue: formatted2,
                    beEmptyOnEmpty: 'true'
                });
                let bfcMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockFCRev}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${bfcMTDtotalRevvarBlock}</div></div>`;
                cell.isLoading = false;
                cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                cell.value = '';
                let BRev = '-';
                if ($.isNumeric(rev)
                    && rev > 0
                    && $.isNumeric(obj.data.Bu.BMTDtotalRev)
                    && obj.data.Bu.BMTDtotalRev > 0) {
                    BRev = rev - obj.data.Bu.BMTDtotalRev;
                }
                let formatted3 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    BRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                const _varBlockBRev = new NumericVarianceBlock({
                    numericValue: BRev,
                    formattedValue: formatted3,
                    beEmptyOnEmpty: 'true'
                });
                let BMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockBRev}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalRevvarBlock}</div></div>`;
                cell.isLoading = false;


                /** ************========LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                cell.value = '';
                let LyOccVar = '';
                let LyMTDtotalOcc = obj.data.Lydata[0].occupancy;
                if ($.isNumeric(occ) > 0 && $.isNumeric(LyMTDtotalOcc)) {
                    LyOccVar = (occ - LyMTDtotalOcc).toFixed(2);
                    let LyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(LyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(LyOccVar)}
                            ${flag.renderValue(LyOccVar)}
                        </div>`;
                    cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${LyOCCnumVarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                cell.value = '';
                let LyMTDtotalOtb = '';
                if (rms > 0 && obj.data.Lydata[0].roomsOtb > 0) {
                    LyMTDtotalOtb = rms - obj.data.Lydata[0].roomsOtb;
                    const LynumVarBlock = new NumericVarianceBlock({
                        numericValue: LyMTDtotalOtb,
                        formattedValue: String(LyMTDtotalOtb),
                        beEmptyOnEmpty: 'true'
                    });
                    const LyvarBlock = LynumVarBlock.isEmpty()
                        ? html`-`
                        : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                    cell.isLoading = false;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;


                cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                let Lyadrvar = '-';
                if ($.isNumeric(adr) && adr > 0 && $.isNumeric(obj.data.Lydata[0].adrNet) && obj.data.Lydata[0].adrNet > 0) {
                    Lyadrvar = adr - obj.data.Lydata[0].adrNet;
                    let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        Lyadrvar,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    const _varBlockLyadr = new NumericVarianceBlock({
                        numericValue: Lyadrvar,
                        formattedValue: formattedLyAdr,
                        beEmptyOnEmpty: 'true'
                    });
                    let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;


                cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                let LyRev = '-';
                if ($.isNumeric(rev)
                    && rev > 0
                    && $.isNumeric(obj.data.Lydata[0].totalrevenueNet)
                    && obj.data.Lydata[0].totalrevenueNet > 0) {
                    LyRev = rev - obj.data.Lydata[0].totalrevenueNet;
                    let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        LyRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    const _varBlockLYRev = new NumericVarianceBlock({
                        numericValue: LyRev,
                        formattedValue: formatted4,
                        beEmptyOnEmpty: 'true'
                    });
                    let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
                } else {
                    cell.value = LyRev;
                }
                cell.isLoading = false;

                /** ************========ACTUAL LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                cell.value = '';
                let ALyOccVar = '';
                let ALyMTDtotalOcc = obj.data.ActualLyData.occupancy;
                if ($.isNumeric(occ) > 0 && $.isNumeric(ALyMTDtotalOcc)) {
                    ALyOccVar = (occ - ALyMTDtotalOcc).toFixed(2);
                    let ALyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(ALyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(ALyOccVar)}
                            ${flag.renderValue(ALyOccVar)}
                        </div>`;
                    cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${ALyOCCnumVarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                cell.value = '';
                let ALyMTDtotalOtb = '';
                if (rms > 0 && obj.data.ActualLyData.roomsOtb > 0) {
                    ALyMTDtotalOtb = rms - obj.data.ActualLyData.roomsOtb;
                    const LynumVarBlock = new NumericVarianceBlock({
                        numericValue: ALyMTDtotalOtb,
                        formattedValue: String(ALyMTDtotalOtb),
                        beEmptyOnEmpty: 'true'
                    });
                    const LyvarBlock = LynumVarBlock.isEmpty()
                        ? html`-`
                        : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                    cell.isLoading = false;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                let ALyadrvar = '-';
                if ($.isNumeric(adr) && adr > 0 && $.isNumeric(obj.data.ActualLyData.adrNet) && obj.data.ActualLyData.adrNet > 0) {
                    ALyadrvar = adr - obj.data.ActualLyData.adrNet;
                    let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ALyadrvar,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    const _varBlockLyadr = new NumericVarianceBlock({
                        numericValue: ALyadrvar,
                        formattedValue: formattedLyAdr,
                        beEmptyOnEmpty: 'true'
                    });
                    let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                let ALyRev = '-';
                if ($.isNumeric(rev)
                    && rev > 0
                    && $.isNumeric(obj.data.ActualLyData.totalrevenueNet)
                    && obj.data.ActualLyData.totalrevenueNet > 0) {
                    ALyRev = rev - obj.data.ActualLyData.totalrevenueNet;
                    let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ALyRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    const _varBlockLYRev = new NumericVarianceBlock({
                        numericValue: ALyRev,
                        formattedValue: formatted4,
                        beEmptyOnEmpty: 'true'
                    });
                    let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
                } else {
                    cell.value = ALyRev;
                }
                cell.isLoading = false;
            });
        } else {
            let foundAnyData = false;
            let cell;
            let row;
            row = flag.cache.rowMap.get('MTD');
            let obj = flag.MTDObject;
            let rms = obj.data.roomsOtb;
            let adr = obj.data.adr;
            let rev = obj.data.revenueNet;
            /** ***************================***************** */
            // MTD RMS.
            cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
            if ($.isNumeric(obj.data.roomsOtb)) {
                foundAnyData = true;
                cell.value = obj.data.roomsOtb;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;
            cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
            if ($.isNumeric(obj.data.adr)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.adr,
                    Constants.ADR_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;
            cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
            if ($.isNumeric(obj.data.revenueNet)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.revenueNet,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;
            let occ = obj.data.occupancy;
            cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
            cell.value = occ + '%';
            cell.isLoading = false;
            /** ******========FORECAST AND BUDGET========****** */
            cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
            cell.value = '';
            let FcOccVar = '';
            let BFcMTDtotalOcc = parseFloat(obj.data.Bfc.bfcMTDtotalOcc);
            if ($.isNumeric(occ) > 0 && $.isNumeric(BFcMTDtotalOcc)) {
                FcOccVar = (occ - BFcMTDtotalOcc).toFixed(2);
            }
            let BFCOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(FcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(FcOccVar)}
                            ${flag.renderValue(FcOccVar)}
                        </div>`;
            cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BFCOCCnumVarBlock}</div></div>`;
            cell.isLoading = false;
            // BUDGET OCC
            cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
            cell.value = '';
            let BFcOccVar = '';
            let BMTDtotalOcc = parseFloat(obj.data.Bu.BMTDtotalOcc);
            if ($.isNumeric(occ) > 0 && $.isNumeric(BMTDtotalOcc)) {
                BFcOccVar = (occ - BMTDtotalOcc).toFixed(2);
            }
            let BOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(BFcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(BFcOccVar)}
                            ${flag.renderValue(BFcOccVar)}
                        </div>`;
            cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BOCCnumVarBlock}</div></div>`;
            cell.isLoading = false;
            // RMS B AND FC
            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
            cell.value = '';
            let bfcMTDtotalOtb = '';
            if (rms > 0 && obj.data.Bfc.bfcMTDtotalOtb > 0) {
                bfcMTDtotalOtb = rms - obj.data.Bfc.bfcMTDtotalOtb;
            }
            const numVarBlock = new NumericVarianceBlock({
                numericValue: bfcMTDtotalOtb,
                formattedValue: String(bfcMTDtotalOtb),
                beEmptyOnEmpty: 'true'
            });
            const varBlock = numVarBlock.isEmpty()
                ? html`-`
                : html`<div style="font-size:.7rem">${numVarBlock}</div>`;
            cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>
                            </div>`;
            cell.isLoading = false;
            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
            cell.value = '';
            let BMTDtotalOtb = '-';
            if (rms > 0 && obj.data.Bu.BMTDtotalOtb > 0) {
                BMTDtotalOtb = rms - obj.data.Bu.BMTDtotalOtb;
            }
            const BnumVarBlock = new NumericVarianceBlock({
                numericValue: BMTDtotalOtb,
                formattedValue: String(BMTDtotalOtb),
                beEmptyOnEmpty: 'true'
            });
            const BvarBlock = BnumVarBlock.isEmpty()
                ? html`-`
                : html`<div style="font-size:.7rem">${BnumVarBlock}</div>`;
            cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${BvarBlock}</div>
                            </div>`;
            cell.isLoading = false;
            // ADR B AND FC
            cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
            cell.value = '';
            let bfcadrvar = '';
            if ($.isNumeric(adr) > 0 && $.isNumeric(obj.data.Bfc.bfcMTDtotalAdr)) {
                bfcadrvar = adr - obj.data.Bfc.bfcMTDtotalAdr;
            }
            let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                bfcadrvar,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale,
                1
            );
            const _varBlock = new NumericVarianceBlock({
                numericValue: bfcadrvar,
                formattedValue: formatted,
                beEmptyOnEmpty: 'true'
            });
            let bfcMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlock}</div>`;
            let cellVal = html`<div style="flex-direction:column;">
                                <div>${bfcMTDtotalAdrvarBlock}</div>
    
                            </div>`;
            cell.value = cellVal;
            cell.isLoading = false;
            cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
            cell.value = '';
            let Badrvar = '';
            if ($.isNumeric(adr) > 0 && $.isNumeric(obj.data.Bu.BMTDtotalAdr)) {
                Badrvar = adr - obj.data.Bu.BMTDtotalAdr;
            }
            let formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                Badrvar,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale,
                1
            );
            const _varBlockBadr = new NumericVarianceBlock({
                numericValue: Badrvar,
                formattedValue: formatted1,
                beEmptyOnEmpty: 'true'
            });
            let BMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockBadr}</div>`;
            cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalAdrvarBlock}</div></div>`;
            cell.isLoading = false;
            // REV B AND FC
            cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
            cell.value = '';
            let BfcRev = '';
            if ($.isNumeric(rev)
                && rev > 0 && $.isNumeric(obj.data.Bfc.bfcMTDtotalRev)
                && obj.data.Bfc.bfcMTDtotalRev > 0) {
                BfcRev = rev - obj.data.Bfc.bfcMTDtotalRev;
            }
            let formatted2 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                BfcRev,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale
            );
            const _varBlockFCRev = new NumericVarianceBlock({
                numericValue: BfcRev,
                formattedValue: formatted2,
                beEmptyOnEmpty: 'true'
            });
            let bfcMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockFCRev}</div>`;
            cell.value = html`<div style="flex-direction:column;"><div>${bfcMTDtotalRevvarBlock}</div></div>`;
            cell.isLoading = false;
            cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
            cell.value = '';
            let BRev = '';
            if ($.isNumeric(rev)
                && rev > 0
                && $.isNumeric(obj.data.Bu.BMTDtotalRev)
                && obj.data.Bu.BMTDtotalRev > 0) {
                BRev = rev - obj.data.Bu.BMTDtotalRev;
            }
            let formatted3 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                BRev,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale
            );
            const _varBlockBRev = new NumericVarianceBlock({
                numericValue: BRev,
                formattedValue: formatted3,
                beEmptyOnEmpty: 'true'
            });
            let BMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockBRev}</div>`;
            cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalRevvarBlock}</div></div>`;
            cell.isLoading = false;

            /** ************========LAST YEAR ROW=========******************** */
            cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
            cell.value = '';
            let LyOccVar = '';
            let LyMTDtotalOcc = obj.data.Lydata[0].occupancy;
            if ($.isNumeric(occ) > 0 && $.isNumeric(LyMTDtotalOcc)) {
                LyOccVar = (occ - LyMTDtotalOcc).toFixed(2);
                let LyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(LyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(LyOccVar)}
                            ${flag.renderValue(LyOccVar)}
                        </div>`;
                cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${LyOCCnumVarBlock}</div></div>`;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;


            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
            cell.value = '';
            let LyMTDtotalOtb = '';
            if (rms > 0 && obj.data.Lydata[0].roomsOtb > 0) {
                LyMTDtotalOtb = rms - obj.data.Lydata[0].roomsOtb;
                const LynumVarBlock = new NumericVarianceBlock({
                    numericValue: LyMTDtotalOtb,
                    formattedValue: String(LyMTDtotalOtb),
                    beEmptyOnEmpty: 'true'
                });
                const LyvarBlock = LynumVarBlock.isEmpty()
                    ? html`-`
                    : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                cell.isLoading = false;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;


            cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
            let Lyadrvar = '-';
            if ($.isNumeric(adr) && adr > 0 && $.isNumeric(obj.data.Lydata[0].adrNet) && obj.data.Lydata[0].adrNet > 0) {
                Lyadrvar = adr - obj.data.Lydata[0].adrNet;
                let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    Lyadrvar,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                const _varBlockLyadr = new NumericVarianceBlock({
                    numericValue: Lyadrvar,
                    formattedValue: formattedLyAdr,
                    beEmptyOnEmpty: 'true'
                });
                let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
            let LyRev = '-';
            if ($.isNumeric(rev)
                && rev > 0
                && $.isNumeric(obj.data.Lydata[0].totalrevenueNet)
                && obj.data.Lydata[0].totalrevenueNet > 0) {
                LyRev = rev - obj.data.Lydata[0].totalrevenueNet;
                let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    LyRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                const _varBlockLYRev = new NumericVarianceBlock({
                    numericValue: LyRev,
                    formattedValue: formatted4,
                    beEmptyOnEmpty: 'true'
                });
                let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
            } else {
                cell.value = LyRev;
            }
            cell.isLoading = false;

            /** ************========ACTUAL LAST YEAR ROW=========******************** */
            cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
            cell.value = '';
            let ALyOccVar = '';
            let ALyMTDtotalOcc = obj.data.ActualLyData.occupancy;
            if ($.isNumeric(occ) > 0 && $.isNumeric(ALyMTDtotalOcc)) {
                ALyOccVar = (occ - ALyMTDtotalOcc).toFixed(2);
                let ALyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(ALyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(ALyOccVar)}
                            ${flag.renderValue(ALyOccVar)}
                        </div>`;
                cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${ALyOCCnumVarBlock}</div></div>`;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
            cell.value = '';
            let ALyMTDtotalOtb = '';
            if (rms > 0 && obj.data.ActualLyData.roomsOtb > 0) {
                ALyMTDtotalOtb = rms - obj.data.ActualLyData.roomsOtb;
                const LynumVarBlock = new NumericVarianceBlock({
                    numericValue: ALyMTDtotalOtb,
                    formattedValue: String(ALyMTDtotalOtb),
                    beEmptyOnEmpty: 'true'
                });
                const LyvarBlock = LynumVarBlock.isEmpty()
                    ? html`-`
                    : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                cell.isLoading = false;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
            let ALyadrvar = '-';
            if ($.isNumeric(adr) && adr > 0 && $.isNumeric(obj.data.ActualLyData.adrNet) && obj.data.ActualLyData.adrNet > 0) {
                ALyadrvar = adr - obj.data.ActualLyData.adrNet;
                let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    ALyadrvar,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                const _varBlockLyadr = new NumericVarianceBlock({
                    numericValue: ALyadrvar,
                    formattedValue: formattedLyAdr,
                    beEmptyOnEmpty: 'true'
                });
                let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
            let ALyRev = '-';
            if ($.isNumeric(rev)
                && rev > 0
                && $.isNumeric(obj.data.ActualLyData.totalrevenueNet)
                && obj.data.ActualLyData.totalrevenueNet > 0) {
                ALyRev = rev - obj.data.ActualLyData.totalrevenueNet;
                let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    ALyRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                const _varBlockLYRev = new NumericVarianceBlock({
                    numericValue: ALyRev,
                    formattedValue: formatted4,
                    beEmptyOnEmpty: 'true'
                });
                let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
            } else {
                cell.value = ALyRev;
            }
            cell.isLoading = false;
        }

        super.forEachMonthKey((month, yearMonKey) => {
            let cell;
            let foundAnyData = false;
            let row = this.cache.rowMap.get(month);
            let cnt = 0;

            if (flag.MonthsObject[month].length === 0) {
                /* MONTHS */
                // Occ,rms,adr,rev
                let dataObj = {
                    hotel_id: this.hotel.id,
                    recordDate: this.recordDate,
                    yearMonKey: yearMonKey,
                    offset: infinito.vao.controller.storageHelper.getPickupOffset(),
                    isoffset: 0,
                    isbufc: 1,
                    isLy: 1,
                    op: 'getFlashHotelPickupPerMonthOcc',
                    serviceName: 'flashsingleapi.php'
                };
                sendRequest(dataObj, function (res) {
                    let obj = JSON.parse(res);
                    flag.MonthsObject[month] = obj;
                    let occ = obj.data.occupancy;
                    let rms = obj.data.roomsOtb;
                    let adr = obj.data.adr;
                    let rev = obj.data.revenueNet;
                    var months = ['01', '02', '03', '04', '05', '06', '07', '08', '08', '10', '11', '12'];
                    var date = new Date();
                    cnt = date.getFullYear() + '-' + months[date.getMonth()];
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                    cell.value = occ + '%';
                    cell.isLoading = false;

                    /** ***************================***************** */
                    // MTD RMS.
                    cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                    if ($.isNumeric(obj.data.roomsOtb)) {
                        foundAnyData = true;
                        cell.value = obj.data.roomsOtb;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                    if ($.isNumeric(obj.data.adr)) {
                        foundAnyData = true;
                        let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.adr,
                            Constants.ADR_DIGITS,
                            true,
                            flag.hotel.locale,
                            1
                        );
                        cell.value = formatted;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                    if ($.isNumeric(obj.data.revenueNet)) {
                        foundAnyData = true;
                        let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.revenueNet,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale
                        );
                        cell.value = formatted;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                    cell.value = occ + '%';
                    cell.isLoading = false;

                    /** ******========FORECAST AND BUDGET========****** */
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                    cell.value = '';
                    let FcOccVar = '';
                    let BFcMTDtotalOcc = parseFloat(obj.data.Bfc.bfcMTDtotalOcc);
                    if ($.isNumeric(occ) && $.isNumeric(BFcMTDtotalOcc)) {
                        FcOccVar = (occ - BFcMTDtotalOcc).toFixed(2);
                    }
                    let BFCOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(FcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(FcOccVar)}
                            ${flag.renderValue(FcOccVar)}
                        </div>`;
                    cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BFCOCCnumVarBlock}</div></div>`;
                    cell.isLoading = false;


                    // BUDGET OCC
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                    cell.value = '';
                    let BFcOccVar = '-';
                    let BMTDtotalOcc = parseFloat(obj.data.Bu.BMTDtotalOcc);
                    if ($.isNumeric(occ) && $.isNumeric(BMTDtotalOcc)) {
                        BFcOccVar = (occ - BMTDtotalOcc).toFixed(2);
                    }
                    let BOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(BFcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(BFcOccVar)}
                            ${flag.renderValue(BFcOccVar)}
                        </div>`;
                    cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BOCCnumVarBlock}</div></div>`;
                    cell.isLoading = false;

                    // RMS B AND FC
                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                    cell.value = '';
                    let bfcMTDtotalOtb = '';
                    if (rms > 0 && obj.data.Bfc.bfcMTDtotalOtb > 0) {
                        bfcMTDtotalOtb = rms - obj.data.Bfc.bfcMTDtotalOtb;
                    }
                    const numVarBlock = new NumericVarianceBlock({
                        numericValue: bfcMTDtotalOtb,
                        formattedValue: String(bfcMTDtotalOtb),
                        beEmptyOnEmpty: 'true'
                    });
                    const varBlock = numVarBlock.isEmpty()
                        ? html`-`
                        : html`<div style="font-size:.7rem">${numVarBlock}</div>`;

                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>
                            </div>`;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                    cell.value = '';
                    let BMTDtotalOtb = '-';
                    if (rms > 0 && obj.data.Bu.BMTDtotalOtb > 0) {
                        BMTDtotalOtb = rms - obj.data.Bu.BMTDtotalOtb;
                    }
                    const BnumVarBlock = new NumericVarianceBlock({
                        numericValue: BMTDtotalOtb,
                        formattedValue: String(BMTDtotalOtb),
                        beEmptyOnEmpty: 'true'
                    });

                    const BvarBlock = BnumVarBlock.isEmpty()
                        ? html`-`
                        : html`<div style="font-size:.7rem">${BnumVarBlock}</div>`;

                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${BvarBlock}</div>
                            </div>`;
                    cell.isLoading = false;

                    // ADR B AND FC
                    cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                    cell.value = '';

                    let bfcadrvar = '-';
                    if ($.isNumeric(adr) && $.isNumeric(obj.data.Bfc.bfcMTDtotalAdr)) {
                        bfcadrvar = adr - obj.data.Bfc.bfcMTDtotalAdr;
                    }
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        bfcadrvar,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    const _varBlock = new NumericVarianceBlock({
                        numericValue: bfcadrvar,
                        formattedValue: formatted,
                        beEmptyOnEmpty: 'true'
                    });
                    let bfcMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlock}</div>`;
                    let cellVal = html`<div style="flex-direction:column;">
                                <div>${bfcMTDtotalAdrvarBlock}</div>
    
                            </div>`;
                    cell.value = cellVal;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                    cell.value = '';
                    let Badrvar = '-';
                    if ($.isNumeric(adr) && $.isNumeric(obj.data.Bu.BMTDtotalAdr)) {
                        Badrvar = adr - obj.data.Bu.BMTDtotalAdr;
                    }
                    let formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        Badrvar,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    const _varBlockBadr = new NumericVarianceBlock({
                        numericValue: Badrvar,
                        formattedValue: formatted1,
                        beEmptyOnEmpty: 'true'
                    });
                    let BMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockBadr}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalAdrvarBlock}</div></div>`;
                    cell.isLoading = false;

                    // REV B AND FC
                    cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                    cell.value = '';
                    let BfcRev = '-';
                    if ($.isNumeric(rev)
                        && $.isNumeric(obj.data.Bfc.bfcMTDtotalRev)) {
                        BfcRev = rev - obj.data.Bfc.bfcMTDtotalRev;
                    }

                    let formatted2 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        BfcRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    const _varBlockFCRev = new NumericVarianceBlock({
                        numericValue: BfcRev,
                        formattedValue: formatted2,
                        beEmptyOnEmpty: 'true'
                    });
                    let bfcMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockFCRev}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${bfcMTDtotalRevvarBlock}</div></div>`;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                    cell.value = '';
                    let BRev = '-';
                    if ($.isNumeric(rev)
                        && $.isNumeric(obj.data.Bu.BMTDtotalRev)) {
                        BRev = rev - obj.data.Bu.BMTDtotalRev;
                    }
                    let formatted3 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        BRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    const _varBlockBRev = new NumericVarianceBlock({
                        numericValue: BRev,
                        formattedValue: formatted3,
                        beEmptyOnEmpty: 'true'
                    });
                    let BMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockBRev}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalRevvarBlock}</div></div>`;
                    cell.isLoading = false;

                    /** ************========LAST YEAR ROW=========******************** */
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                    cell.value = '';
                    let LyOccVar = '';
                    let LyMTDtotalOcc = obj.data.Lydata.occupancy;

                    if ($.isNumeric(occ) && $.isNumeric(LyMTDtotalOcc)) {
                        LyOccVar = (occ - LyMTDtotalOcc).toFixed(2);
                        let LyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(LyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(LyOccVar)}
                            ${flag.renderValue(LyOccVar)}
                        </div>`;
                        cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${LyOCCnumVarBlock}</div></div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;


                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                    cell.value = '';
                    let LyMTDtotalOtb = '';
                    if ($.isNumeric(rms) && $.isNumeric(obj.data.Lydata.roomsOtb)) {
                        LyMTDtotalOtb = rms - obj.data.Lydata.roomsOtb;
                        const LynumVarBlock = new NumericVarianceBlock({
                            numericValue: LyMTDtotalOtb,
                            formattedValue: String(LyMTDtotalOtb),
                            beEmptyOnEmpty: 'true'
                        });
                        const LyvarBlock = LynumVarBlock.isEmpty()
                            ? html`-`
                            : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                        cell.isLoading = false;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;


                    cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                    let Lyadrvar = '-';
                    if ($.isNumeric(adr) && $.isNumeric(obj.data.Lydata.adrNet)) {
                        Lyadrvar = adr - obj.data.Lydata.adrNet;
                        let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            Lyadrvar,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale,
                            1
                        );
                        const _varBlockLyadr = new NumericVarianceBlock({
                            numericValue: Lyadrvar,
                            formattedValue: formattedLyAdr,
                            beEmptyOnEmpty: 'true'
                        });
                        let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                        cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;


                    cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                    let LyRev = '-';
                    if ($.isNumeric(rev)
                        && $.isNumeric(obj.data.Lydata.totalrevenueNet)) {
                        LyRev = rev - obj.data.Lydata.totalrevenueNet;
                        let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            LyRev,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale
                        );
                        const _varBlockLYRev = new NumericVarianceBlock({
                            numericValue: LyRev,
                            formattedValue: formatted4,
                            beEmptyOnEmpty: 'true'
                        });
                        let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                        cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
                    } else {
                        cell.value = LyRev;
                    }
                    cell.isLoading = false;

                    /** ************========ACTUAL LAST YEAR ROW=========******************** */
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                    cell.value = '';
                    let ALyOccVar = '';
                    let ALyMTDtotalOcc = obj.data.ActualLyData.occupancy;
                    if ($.isNumeric(occ) > 0 && $.isNumeric(ALyMTDtotalOcc)) {
                        ALyOccVar = (occ - ALyMTDtotalOcc).toFixed(2);
                        let ALyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(ALyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(ALyOccVar)}
                            ${flag.renderValue(ALyOccVar)}
                        </div>`;
                        cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${ALyOCCnumVarBlock}</div></div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                    cell.value = '';
                    let ALyMTDtotalOtb = '';
                    if (rms > 0 && obj.data.ActualLyData.roomsOtb > 0) {
                        ALyMTDtotalOtb = rms - obj.data.ActualLyData.roomsOtb;
                        const LynumVarBlock = new NumericVarianceBlock({
                            numericValue: ALyMTDtotalOtb,
                            formattedValue: String(ALyMTDtotalOtb),
                            beEmptyOnEmpty: 'true'
                        });
                        const LyvarBlock = LynumVarBlock.isEmpty()
                            ? html`-`
                            : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                        cell.isLoading = false;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                    let ALyadrvar = '-';
                    if ($.isNumeric(adr) && adr > 0 && $.isNumeric(obj.data.ActualLyData.adrNet) && obj.data.ActualLyData.adrNet > 0) {
                        ALyadrvar = adr - obj.data.ActualLyData.adrNet;
                        let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            ALyadrvar,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale,
                            1
                        );
                        const _varBlockLyadr = new NumericVarianceBlock({
                            numericValue: ALyadrvar,
                            formattedValue: formattedLyAdr,
                            beEmptyOnEmpty: 'true'
                        });
                        let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                        cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                    let ALyRev = '-';
                    if ($.isNumeric(rev)
                        && rev > 0
                        && $.isNumeric(obj.data.ActualLyData.totalrevenueNet)
                        && obj.data.ActualLyData.totalrevenueNet > 0) {
                        ALyRev = rev - obj.data.ActualLyData.totalrevenueNet;
                        let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            ALyRev,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale
                        );
                        const _varBlockLYRev = new NumericVarianceBlock({
                            numericValue: ALyRev,
                            formattedValue: formatted4,
                            beEmptyOnEmpty: 'true'
                        });
                        let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                        cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
                    } else {
                        cell.value = ALyRev;
                    }
                    cell.isLoading = false;
                });
            } else {
                let obj = flag.MonthsObject[month];
                let occ = obj.data.occupancy;
                let rms = obj.data.roomsOtb;
                let adr = obj.data.adr;
                let rev = obj.data.revenueNet;
                var months = ['01', '02', '03', '04', '05', '06', '07', '08', '08', '10', '11', '12'];
                var date = new Date();
                cnt = date.getFullYear() + '-' + months[date.getMonth()];
                cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                cell.value = occ + '%';
                cell.isLoading = false;

                /** ***************================***************** */
                // MTD RMS.
                cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                if ($.isNumeric(obj.data.roomsOtb)) {
                    foundAnyData = true;
                    cell.value = obj.data.roomsOtb;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                if ($.isNumeric(obj.data.adr)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.adr,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                if ($.isNumeric(obj.data.revenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.revenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                cell.value = occ + '%';
                cell.isLoading = false;


                /** ******========FORECAST AND BUDGET========****** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                cell.value = '';
                let FcOccVar = '';
                let BFcMTDtotalOcc = parseFloat(obj.data.Bfc.bfcMTDtotalOcc);
                if ($.isNumeric(occ) && $.isNumeric(BFcMTDtotalOcc)) {
                    FcOccVar = (occ - BFcMTDtotalOcc).toFixed(2);
                }
                let BFCOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(FcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(FcOccVar)}
                            ${flag.renderValue(FcOccVar)}
                        </div>`;
                cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BFCOCCnumVarBlock}</div></div>`;
                cell.isLoading = false;


                // BUDGET OCC
                cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                cell.value = '';
                let BFcOccVar = '';
                let BMTDtotalOcc = parseFloat(obj.data.Bu.BMTDtotalOcc);
                if ($.isNumeric(occ) && $.isNumeric(BMTDtotalOcc)) {
                    BFcOccVar = (occ - BMTDtotalOcc).toFixed(2);
                }
                let BOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(BFcOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(BFcOccVar)}
                            ${flag.renderValue(BFcOccVar)}
                        </div>`;
                cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${BOCCnumVarBlock}</div></div>`;
                cell.isLoading = false;

                // RMS B AND FC
                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                cell.value = '';
                let bfcMTDtotalOtb = '';
                if (rms > 0 && obj.data.Bfc.bfcMTDtotalOtb > 0) {
                    bfcMTDtotalOtb = rms - obj.data.Bfc.bfcMTDtotalOtb;
                }
                const numVarBlock = new NumericVarianceBlock({
                    numericValue: bfcMTDtotalOtb,
                    formattedValue: String(bfcMTDtotalOtb),
                    beEmptyOnEmpty: 'true'
                });
                const varBlock = numVarBlock.isEmpty()
                    ? html`-`
                    : html`<div style="font-size:.7rem">${numVarBlock}</div>`;

                cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${varBlock}</div>
                            </div>`;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                cell.value = '';
                let BMTDtotalOtb = '-';
                if (rms > 0 && obj.data.Bu.BMTDtotalOtb > 0) {
                    BMTDtotalOtb = rms - obj.data.Bu.BMTDtotalOtb;
                }
                const BnumVarBlock = new NumericVarianceBlock({
                    numericValue: BMTDtotalOtb,
                    formattedValue: String(BMTDtotalOtb),
                    beEmptyOnEmpty: 'true'
                });

                const BvarBlock = BnumVarBlock.isEmpty()
                    ? html`-`
                    : html`<div style="font-size:.7rem">${BnumVarBlock}</div>`;

                cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${BvarBlock}</div>
                            </div>`;
                cell.isLoading = false;

                // ADR B AND FC
                cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                cell.value = '';

                let bfcadrvar = '-';
                if ($.isNumeric(adr) && $.isNumeric(obj.data.Bfc.bfcMTDtotalAdr)) {
                    bfcadrvar = adr - obj.data.Bfc.bfcMTDtotalAdr;
                }
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    bfcadrvar,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                const _varBlock = new NumericVarianceBlock({
                    numericValue: bfcadrvar,
                    formattedValue: formatted,
                    beEmptyOnEmpty: 'true'
                });
                let bfcMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlock}</div>`;
                let cellVal = html`<div style="flex-direction:column;">
                                <div>${bfcMTDtotalAdrvarBlock}</div>
    
                            </div>`;
                cell.value = cellVal;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                cell.value = '';
                let Badrvar = '-';
                if ($.isNumeric(adr) && $.isNumeric(obj.data.Bu.BMTDtotalAdr)) {
                    Badrvar = adr - obj.data.Bu.BMTDtotalAdr;
                }
                let formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    Badrvar,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                const _varBlockBadr = new NumericVarianceBlock({
                    numericValue: Badrvar,
                    formattedValue: formatted1,
                    beEmptyOnEmpty: 'true'
                });
                let BMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockBadr}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalAdrvarBlock}</div></div>`;
                cell.isLoading = false;

                // REV B AND FC
                cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                cell.value = '';
                let BfcRev = '-';
                if ($.isNumeric(rev)
                    && $.isNumeric(obj.data.Bfc.bfcMTDtotalRev)) {
                    BfcRev = rev - obj.data.Bfc.bfcMTDtotalRev;
                }

                let formatted2 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    BfcRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                const _varBlockFCRev = new NumericVarianceBlock({
                    numericValue: BfcRev,
                    formattedValue: formatted2,
                    beEmptyOnEmpty: 'true'
                });
                let bfcMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockFCRev}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${bfcMTDtotalRevvarBlock}</div></div>`;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                cell.value = '';
                let BRev = '-';
                if ($.isNumeric(rev)
                    && $.isNumeric(obj.data.Bu.BMTDtotalRev)) {
                    BRev = rev - obj.data.Bu.BMTDtotalRev;
                }
                let formatted3 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    BRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                const _varBlockBRev = new NumericVarianceBlock({
                    numericValue: BRev,
                    formattedValue: formatted3,
                    beEmptyOnEmpty: 'true'
                });
                let BMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockBRev}</div>`;
                cell.value = html`<div style="flex-direction:column;"><div>${BMTDtotalRevvarBlock}</div></div>`;
                cell.isLoading = false;

                /** ************========LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                cell.value = '';
                let LyOccVar = '';
                let LyMTDtotalOcc = obj.data.Lydata.occupancy;
                if ($.isNumeric(occ) && $.isNumeric(LyMTDtotalOcc)) {
                    LyOccVar = (occ - LyMTDtotalOcc).toFixed(2);
                    let LyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(LyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(LyOccVar)}
                            ${flag.renderValue(LyOccVar)}
                        </div>`;
                    cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${LyOCCnumVarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                cell.value = '';
                let LyMTDtotalOtb = '';
                if ($.isNumeric(rms) && $.isNumeric(obj.data.Lydata.roomsOtb)) {
                    LyMTDtotalOtb = rms - obj.data.Lydata.roomsOtb;
                    const LynumVarBlock = new NumericVarianceBlock({
                        numericValue: LyMTDtotalOtb,
                        formattedValue: String(LyMTDtotalOtb),
                        beEmptyOnEmpty: 'true'
                    });
                    const LyvarBlock = LynumVarBlock.isEmpty()
                        ? html`-`
                        : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                    cell.isLoading = false;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                let Lyadrvar = '-';
                if ($.isNumeric(adr) || $.isNumeric(obj.data.Lydata.adrNet)) {
                    Lyadrvar = adr - obj.data.Lydata.adrNet;
                    let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        Lyadrvar,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    const _varBlockLyadr = new NumericVarianceBlock({
                        numericValue: Lyadrvar,
                        formattedValue: formattedLyAdr,
                        beEmptyOnEmpty: 'true'
                    });
                    let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                let LyRev = '-';
                if ($.isNumeric(rev)
                    && $.isNumeric(obj.data.Lydata.totalrevenueNet)) {
                    LyRev = rev - obj.data.Lydata.totalrevenueNet;
                    let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        LyRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    const _varBlockLYRev = new NumericVarianceBlock({
                        numericValue: LyRev,
                        formattedValue: formatted4,
                        beEmptyOnEmpty: 'true'
                    });
                    let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
                } else {
                    cell.value = LyRev;
                }
                cell.isLoading = false;

                /** ************========ACTUAL LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                cell.value = '';
                let ALyOccVar = '';
                let ALyMTDtotalOcc = obj.data.ActualLyData.occupancy;
                if ($.isNumeric(occ) > 0 && $.isNumeric(ALyMTDtotalOcc)) {
                    ALyOccVar = (occ - ALyMTDtotalOcc).toFixed(2);
                    let ALyOCCnumVarBlock = html`
                        <div class="vao__components--numericVarianceBlock ${flag.makeColorClass(String(ALyOccVar))}" style="display:flex;align-items:center;">
                            ${flag.renderCaret(ALyOccVar)}
                            ${flag.renderValue(ALyOccVar)}
                        </div>`;
                    cell.value = html`<div style="flex-direction:column;font-size:11.2px;"><div>${ALyOCCnumVarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                cell.value = '';
                let ALyMTDtotalOtb = '';
                if (rms > 0 && obj.data.ActualLyData.roomsOtb > 0) {
                    ALyMTDtotalOtb = rms - obj.data.ActualLyData.roomsOtb;
                    const LynumVarBlock = new NumericVarianceBlock({
                        numericValue: ALyMTDtotalOtb,
                        formattedValue: String(ALyMTDtotalOtb),
                        beEmptyOnEmpty: 'true'
                    });
                    const LyvarBlock = LynumVarBlock.isEmpty()
                        ? html`-`
                        : html`<div style="font-size:.7rem">${LynumVarBlock}</div>`;
                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${LyvarBlock}</div>
                            </div>`;
                    cell.isLoading = false;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                let ALyadrvar = '-';
                if ($.isNumeric(adr) && adr > 0 && $.isNumeric(obj.data.ActualLyData.adrNet) && obj.data.ActualLyData.adrNet > 0) {
                    ALyadrvar = adr - obj.data.ActualLyData.adrNet;
                    let formattedLyAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ALyadrvar,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    const _varBlockLyadr = new NumericVarianceBlock({
                        numericValue: ALyadrvar,
                        formattedValue: formattedLyAdr,
                        beEmptyOnEmpty: 'true'
                    });
                    let LyMTDtotalAdrvarBlock = html`<div style="font-size:.7rem;">${_varBlockLyadr}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LyMTDtotalAdrvarBlock}</div></div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                let ALyRev = '-';
                if ($.isNumeric(rev)
                    && rev > 0
                    && $.isNumeric(obj.data.ActualLyData.totalrevenueNet)
                    && obj.data.ActualLyData.totalrevenueNet > 0) {
                    ALyRev = rev - obj.data.ActualLyData.totalrevenueNet;
                    let formatted4 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ALyRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    const _varBlockLYRev = new NumericVarianceBlock({
                        numericValue: ALyRev,
                        formattedValue: formatted4,
                        beEmptyOnEmpty: 'true'
                    });
                    let LYMTDtotalRevvarBlock = html`<div style="font-size:.7rem;">${_varBlockLYRev}</div>`;
                    cell.value = html`<div style="flex-direction:column;"><div>${LYMTDtotalRevvarBlock}</div></div>`;
                } else {
                    cell.value = ALyRev;
                }
                cell.isLoading = false;
            }
        });
    }

    fetchnormalmonthsdata() {
        var flag = this;
        /** *****
         * FOR NORMAL LOAD
         * ***** */
        if (flag.MTDObject.length === 0) {
            let PostdataObj = {
                hotel_id: flag.hotel.id,
                recordDate: flag.recordDate,
                isoffset: 0,
                isLy: 1,
                op: 'getFlashHotelPickupPerMonthOccMTD',
                serviceName: 'flashsingleapi.php'
            };
            sendRequest(PostdataObj, function (res) {
                let foundAnyData = false;
                let cell;
                let row;
                row = flag.cache.rowMap.get('MTD');
                let obj = JSON.parse(res);
                flag.MTDObject = obj;
                let rms = obj.data.roomsOtb;
                let adr = obj.data.adr;
                let rev = obj.data.revenueNet;

                /** ***************========OTB========***************** */
                // MTD RMS.
                cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                if ($.isNumeric(obj.data.roomsOtb)) {
                    foundAnyData = true;
                    cell.value = obj.data.roomsOtb;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                if ($.isNumeric(obj.data.adr)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.adr,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                if ($.isNumeric(obj.data.revenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.revenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                let occ = obj.data.occupancy;
                // document.getElementById('vao__flash--month2DayProductionTable-month2DayOcc').innerHTML = occ+"%";
                cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                cell.value = occ + '%';
                cell.isLoading = false;

                /** ******========FORECAST BUDGET========****** */

                cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                cell.value = '';
                if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc;
                } else {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOcc;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalOtb === 0 ? '-' : obj.data.Bfc.bfcMTDtotalOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOtb === 0 ? '-' : obj.data.Bu.BMTDtotalOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalRev === 0 ? '-' : window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalRev === 0 ? '-' : window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;

                /** ************========LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                cell.value = '';
                if (obj.data.Lydata[0].occupancy === 0) {
                    cell.value = '-';
                } else {
                    cell.value = obj.data.Lydata[0].occupancy + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                cell.value = '';
                cell.value = obj.data.Lydata[0].roomsOtb === 0 ? '-' : obj.data.Lydata[0].roomsOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                if ($.isNumeric(obj.data.Lydata[0].adrNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Lydata[0].adrNet,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                if ($.isNumeric(obj.data.Lydata[0].totalrevenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Lydata[0].totalrevenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** ************========ACTUALS LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                cell.value = '';

                if (obj.data.ActualLyData.occupancy === 0) {
                    cell.value = '-';
                } else {
                    cell.value = obj.data.ActualLyData.occupancy + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                cell.value = '';
                cell.value = obj.data.ActualLyData.roomsOtb === 0 ? '-' : obj.data.ActualLyData.roomsOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                if ($.isNumeric(obj.data.ActualLyData.adrNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.ActualLyData.adrNet,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                if ($.isNumeric(obj.data.ActualLyData.totalrevenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.ActualLyData.totalrevenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;
            });
        } else {
            let foundAnyData = false;
            let cell;
            let row;
            row = flag.cache.rowMap.get('MTD');
            let obj = flag.MTDObject;
            let rms = obj.data.roomsOtb;
            let adr = obj.data.adr;
            let rev = obj.data.revenueNet;

            /** ***************========OTB========***************** */
            // MTD RMS.
            cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
            if ($.isNumeric(obj.data.roomsOtb)) {
                foundAnyData = true;
                cell.value = obj.data.roomsOtb;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
            if ($.isNumeric(obj.data.adr)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.adr,
                    Constants.ADR_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
            if ($.isNumeric(obj.data.revenueNet)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.revenueNet,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            let occ = obj.data.occupancy;
            document.getElementById('vao__flash--month2DayProductionTable-month2DayOcc').innerHTML = occ + '%';
            cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
            cell.value = occ + '%';
            cell.isLoading = false;

            /** ******========FORECAST BUDGET========****** */

            cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
            cell.value = '';
            if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                cell.value = obj.data.Bfc.bfcMTDtotalOcc;
            } else {
                cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
            cell.value = '';
            cell.value = obj.data.Bu.BMTDtotalOcc;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
            cell.value = '';
            cell.value = obj.data.Bfc.bfcMTDtotalOtb === 0 ? '-' : obj.data.Bfc.bfcMTDtotalOtb;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
            cell.value = '';
            cell.value = obj.data.Bu.BMTDtotalOtb === 0 ? '-' : obj.data.Bu.BMTDtotalOtb;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
            cell.value = '';
            cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bfc.bfcMTDtotalAdr,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale,
                1
            );
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
            cell.value = '';
            cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bu.BMTDtotalAdr,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale,
                1
            );
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
            cell.value = '';
            cell.value = obj.data.Bfc.bfcMTDtotalRev > 0 ? window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bfc.bfcMTDtotalRev,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale
            ) : '-';
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
            cell.value = '';
            cell.value = obj.data.Bu.BMTDtotalRev > 0 ? window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                obj.data.Bu.BMTDtotalRev,
                Constants.REVENUE_DIGITS,
                true,
                flag.hotel.locale
            ) : '-';
            cell.isLoading = false;

            /** ********=========LAST YEAR ROW============********* */
            cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
            cell.value = '';
            if (obj.data.Lydata[0].occupancy === 0) {
                cell.value = '-';
            } else {
                cell.value = obj.data.Lydata[0].occupancy + '%';
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
            cell.value = '';
            cell.value = obj.data.Lydata[0].roomsOtb === 0 ? '-' : obj.data.Lydata[0].roomsOtb;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
            if ($.isNumeric(obj.data.Lydata[0].adrNet)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Lydata[0].adrNet,
                    Constants.ADR_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
            if ($.isNumeric(obj.data.Lydata[0].totalrevenueNet)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Lydata[0].totalrevenueNet,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            /** ************========ACTUALS LAST YEAR ROW=========******************** */
            cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
            cell.value = '';
            if (obj.data.ActualLyData.occupancy === 0) {
                cell.value = '-';
            } else {
                cell.value = obj.data.ActualLyData.occupancy + '%';
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
            cell.value = '';
            cell.value = obj.data.ActualLyData.roomsOtb === 0 ? '-' : obj.data.ActualLyData.roomsOtb;
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
            if ($.isNumeric(obj.data.ActualLyData.adrNet)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.ActualLyData.adrNet,
                    Constants.ADR_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;

            cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
            if ($.isNumeric(obj.data.ActualLyData.totalrevenueNet)) {
                foundAnyData = true;
                let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.ActualLyData.totalrevenueNet,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.value = formatted;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;
        }


        super.forEachMonthKey((month, yearMonKey) => {
            let cell;
            let foundAnyData = false;
            let row = this.cache.rowMap.get(month);
            let cnt = 0;

            if (typeof flag.MonthsObject[month] === 'undefined') {
                /* MONTHS */
                // Occ,rms,adr,rev
                let dataObj = {
                    hotel_id: this.hotel.id,
                    recordDate: this.recordDate,
                    yearMonKey: yearMonKey,
                    offset: infinito.vao.controller.storageHelper.getPickupOffset(),
                    isoffset: 0,
                    isbufc: 1,
                    isLy: 1,
                    op: 'getFlashHotelPickupPerMonthOcc',
                    serviceName: 'flashsingleapi.php'
                };
                sendRequest(dataObj, function (res) {
                    let obj = JSON.parse(res);
                    flag.MonthsObject[month] = obj;
                    let occ = obj.data.occupancy;
                    let rms = obj.data.roomsOtb;
                    let adr = obj.data.adr;
                    let rev = obj.data.revenueNet;
                    var months = ['01', '02', '03', '04', '05', '06', '07', '08', '08', '10', '11', '12'];
                    var date = new Date();
                    cnt = date.getFullYear() + '-' + months[date.getMonth()];
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                    cell.value = occ + '%';
                    cell.isLoading = false;

                    /** ******************************************** */
                    // Rooms
                    cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                    if ($.isNumeric(obj.data.roomsOtb)) {
                        foundAnyData = true;
                        cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${obj.data.roomsOtb}</div>

                            </div>`;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // ADR
                    cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                    if ($.isNumeric(obj.data.adr)) {
                        foundAnyData = true;
                        const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.adr,
                            Constants.ADR_DIGITS,
                            true,
                            flag.hotel.locale,
                            1
                        );
                        let cellVal = html`<div style="flex-direction:column;">
                            <div>${formatted}</div>

                        </div>`;
                        cell.value = cellVal;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    // Rev
                    cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                    if ($.isNumeric(obj.data.revenueNet)) {
                        foundAnyData = true;
                        const formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.revenueNet,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale
                        );
                        let cellVal = html`<div style="flex-direction:column;">
                            <div>${formatted1}</div>

                        </div>`;
                        cell.value = cellVal;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    /** ******========FORECAST BUDGET========****** */
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                    cell.value = '';
                    if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                        cell.value = obj.data.Bfc.bfcMTDtotalOcc;
                    } else {
                        cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                    cell.value = '';
                    cell.value = obj.data.Bu.BMTDtotalOcc;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                    cell.value = '';
                    cell.value = obj.data.Bfc.bfcMTDtotalOtb === 0 ? '-' : obj.data.Bfc.bfcMTDtotalOtb;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                    cell.value = '';
                    cell.value = obj.data.Bu.BMTDtotalOtb === 0 ? '-' : obj.data.Bu.BMTDtotalOtb;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                    cell.value = '';
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Bfc.bfcMTDtotalAdr,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                    cell.value = '';
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Bu.BMTDtotalAdr,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                    cell.value = '';
                    cell.value = obj.data.Bfc.bfcMTDtotalRev > 0 ? window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Bfc.bfcMTDtotalRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ) : '-';
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                    cell.value = '';
                    cell.value = obj.data.Bu.BMTDtotalRev > 0 ? window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Bu.BMTDtotalRev,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ) : '-';
                    cell.isLoading = false;

                    /** ********=========LAST YEAR ROW============********* */
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                    cell.value = '';
                    if (obj.data.Lydata.occupancy === 0) {
                        cell.value = '-';
                    } else {
                        cell.value = obj.data.Lydata.occupancy + '%';
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                    cell.value = '';
                    cell.value = obj.data.Lydata.roomsOtb === 0 ? '-' : obj.data.Lydata.roomsOtb;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                    if ($.isNumeric(obj.data.Lydata.adrNet)) {
                        foundAnyData = true;
                        let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.Lydata.adrNet,
                            Constants.ADR_DIGITS,
                            true,
                            flag.hotel.locale,
                            1
                        );
                        cell.value = formatted;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                    if ($.isNumeric(obj.data.Lydata.totalrevenueNet)) {
                        foundAnyData = true;
                        let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.Lydata.totalrevenueNet,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale
                        );
                        cell.value = formatted;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    /** ************========ACTUALS LAST YEAR ROW=========******************** */
                    cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                    cell.value = '';
                    if (obj.data.ActualLyData.occupancy === 0) {
                        cell.value = '-';
                    } else {
                        cell.value = obj.data.ActualLyData.occupancy + '%';
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                    cell.value = '';
                    cell.value = obj.data.ActualLyData.roomsOtb === 0 ? '-' : obj.data.ActualLyData.roomsOtb;
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                    if ($.isNumeric(obj.data.ActualLyData.adrNet)) {
                        foundAnyData = true;
                        let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.ActualLyData.adrNet,
                            Constants.ADR_DIGITS,
                            true,
                            flag.hotel.locale,
                            1
                        );
                        cell.value = formatted;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;

                    cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                    if ($.isNumeric(obj.data.ActualLyData.totalrevenueNet)) {
                        foundAnyData = true;
                        let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            obj.data.ActualLyData.totalrevenueNet,
                            Constants.REVENUE_DIGITS,
                            true,
                            flag.hotel.locale
                        );
                        cell.value = formatted;
                    } else {
                        cell.value = null;
                    }
                    cell.isLoading = false;
                });
            } else {
                let obj = flag.MonthsObject[month];
                let occ = obj.data.occupancy;
                let rms = obj.data.roomsOtb;
                let adr = obj.data.adr;
                let rev = obj.data.revenueNet;
                var months = ['01', '02', '03', '04', '05', '06', '07', '08', '08', '10', '11', '12'];
                var date = new Date();
                cnt = date.getFullYear() + '-' + months[date.getMonth()];
                cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                cell.value = occ + '%';
                cell.isLoading = false;

                /** ******************************************** */
                // Rooms
                cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                if ($.isNumeric(obj.data.roomsOtb)) {
                    foundAnyData = true;
                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${obj.data.roomsOtb}</div>

                            </div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                // ADR
                cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                if ($.isNumeric(obj.data.adr)) {
                    foundAnyData = true;
                    const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.adr,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    let cellVal = html`<div style="flex-direction:column;">
                            <div>${formatted}</div>

                        </div>`;
                    cell.value = cellVal;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                // Rev
                cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                if ($.isNumeric(obj.data.revenueNet)) {
                    foundAnyData = true;
                    const formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.revenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    let cellVal = html`<div style="flex-direction:column;">
                            <div>${formatted1}</div>

                        </div>`;
                    cell.value = cellVal;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** ******========FORECAST BUDGET========****** */

                cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                cell.value = '';
                if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc;
                } else {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOcc;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalOtb === 0 ? '-' : obj.data.Bfc.bfcMTDtotalOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOtb === 0 ? '-' : obj.data.Bu.BMTDtotalOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalRev === 0 ? '-' : window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalRev === 0 ? '-' : window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                cell.isLoading = false;

                /** ********=========LAST YEAR ROW============********* */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                cell.value = '';
                if (obj.data.Lydata.occupancy === 0) {
                    cell.value = '-';
                } else {
                    cell.value = obj.data.Lydata.occupancy + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                cell.value = '';
                cell.value = obj.data.Lydata.roomsOtb === 0 ? '-' : obj.data.Lydata.roomsOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                if ($.isNumeric(obj.data.Lydata.adrNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Lydata.adrNet,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                if ($.isNumeric(obj.data.Lydata.totalrevenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Lydata.totalrevenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** ************========ACTUALS LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                cell.value = '';
                if (obj.data.ActualLyData.occupancy === 0) {
                    cell.value = '-';
                } else {
                    cell.value = obj.data.ActualLyData.occupancy + '%';
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                cell.value = '';
                cell.value = obj.data.ActualLyData.roomsOtb === 0 ? '-' : obj.data.ActualLyData.roomsOtb;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                if ($.isNumeric(obj.data.ActualLyData.adrNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.ActualLyData.adrNet,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                if ($.isNumeric(obj.data.ActualLyData.totalrevenueNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.ActualLyData.totalrevenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;
            }
        });
    }

    fillRows() {
        if (this.isgroup.appMode === 'group') {
            let dataObj = {
                hotel_id: this.hotel.id,
                recordDate: this.recordDate,
                pickupOffset: this.pickupSetting,
                op: 'getFlashGroupHotelCardPickupPerMonth',
                serviceName: 'flashgroupapi.php'
            };

            let flag = this;
            sendRequest(dataObj, function (res) {
                flag.fnrenderHTML(res);
            });
        } else if (this.isgroup.appMode === 'single') {
            let flag = this;
            var globalvariance = document.getElementById('vao__pickuppermonthpopup--Variancedecimals-check');
            globalvariance.addEventListener('change', () => {
                if (globalvariance.checked) {
                    CalculateVarianceofOtbWithBudgetAndForecast();
                } else {
                    CalculateSeperateOtbWithBudgetAndForecast();
                }
            });

            /** *****
             * FOR VARIANCE LOAD
             * ***** */
            var CalculateVarianceofOtbWithBudgetAndForecast = function () {
                flag.fetchvariancemonthsdata();
            };

            /** *****
             * FOR NORMAL LOAD
             * ***** */
            var CalculateSeperateOtbWithBudgetAndForecast = function () {
                flag.fetchnormalmonthsdata();
            };

            /** *****
             * FOR FIRST TIME LOAD
             * ***** */
            this.fetchnormalmonthsdata();
        }
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            super.empty();
            return;
        }

        let rows = super.makeTableRows();
        this.fillRows();
        let table = super.reflowTable(rows);
        super.domTheTable(table);
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PickupPerMonthHotelPopup = PickupPerMonthHotelPopup;
customElements.define('vao-pickup-per-month-hotel-popup', PickupPerMonthHotelPopup);
