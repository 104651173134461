import { AppElement, html } from '../AppElement.js';
import ColorArray from "../ColorArray";

export default class MetricTile extends AppElement {
    static get properties() {
        return {
            iconCls: { type: String },
            txt: { type: String },
            metric: { type: Object },
            color: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.iconCls = props.iconCls;
        this.txt = props.txt;
        this.metric = props.metric; // Can be string or a NumericVarianceBlock etc.
        this.color = props.color;
    }

    getColorStyle() {
        if (ColorArray.isHexColor(this.color)) {
            return `color:${this.color};`;
        }
        return '';
    }

    render() {
        return html`
<div class="vao__components--metricTile vao-shadow-div">
    <div class="vao__components--metricTile-icon" style="${this.getColorStyle()}">
        <vao-icon .cls="${this.iconCls}"></vao-icon>
    </div>
    <div class="vao__components--metricTile-txt" style="${this.getColorStyle()}">${this.txt}</div>
    <div class="vao__components--metricTile-metric">${this.metric}</div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MetricTile = MetricTile;
customElements.define('vao-metric-tile', MetricTile);
