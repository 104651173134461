import { AppElement, html } from '../AppElement.js';
import {OPTION_30_DAYS} from "../dateSelect/StayDateRangeSelect";

export default class PageAdhocSingle extends AppElement {
    static get properties() {
        return {
            hotel: {type: Object},
            recordDate: {type: String},
            pickupSetting: {type: Number},

            // @internalProperty
            rangeValue: { attribute: false },
            startDate: {attribute: false},
            endDate: {attribute: false},
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel || this.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );

        this.rangeValue = OPTION_30_DAYS;
        this.startDate = props.startDate || this.recordDate;
        this.endDate = props.endDate
            || window.infinito.vao.controller.dateHelper.addDaysToDateString(this.startDate, 30);
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    onChangeStayDateRange(e) {
        this.rangeValue = e.detail.newValue;
        this.startDate = e.detail.rangeData.startDate;
        this.endDate = e.detail.rangeData.endDate;
    }

    onFlashButtonClick(){
        let body = '<vao-page-flash-modal></vao-page-flash-modal>';

        let $title =  $('<div></div>').append(
            `<h5>Flash</h5>`
        );
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'lg'
        });
    }

    onMonthlyButtonClick(){
        let body =  '<input type="hidden" id="hid" value="'+this.hotel.id+'" />' +
                    '<input type="hidden" id="hname" value="'+this.hotel.name+'" />' +
                    '<vao-page-monthlyanalysis-modal></vao-page-monthlyanalysis-modal>';

        let $title =  $('<div></div>').append(
            `<h5>Monthly Analysis</h5>`
        );
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'xl'
        });
    }


    render() {
        return html`
            <div class="vao__components--pageDesktopSingle">
                <div class="rounded border bg-white">        
                    <div style="margin-top:.5rem;">
                        <vao-page-adhoc-single-table
                            .hotel="${this.hotel}"
                            .recordDate="${this.recordDate}"
                            .pickupSetting="${this.pickupSetting}"
                            startDate="${this.startDate}"
                            endDate="${this.endDate}"
                        ></vao-page-adhoc-single-table>
                    </div>
                </div>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageAdhocSingle = PageAdhocSingle;
customElements.define('vao-page-adhoc-single', PageAdhocSingle);
