import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import ContextTableRoomType from "./ContextTableRoomType";
import RangeDateSelectInput from "../dateSelect/RangeDateSelectInput";
import ContextTableSegment from "./ContextTableSegment";
import CategorySelectChip from "./CategorySelectChip";
import MetricSelectChip from "./MetricSelectChip";
import ContextBalanceRoomType from "./ContextBalanceRoomType";
import ContextBalanceSegment from "./ContextBalanceSegment";

export default class PageContext extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            firstStayDate: { type: String },
            lastStayDate: { type: String },
            pickupSetting: { type: Number },
            category: { type: String },
            metric: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.minRange = 7;
        this.hotel = props.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.firstStayDate = props.firstStayDate || this.recordDate;
        this.lastStayDate = props.lastStayDate || window.infinito.vao.controller.dateHelper.addDaysToDateString(
            this.firstStayDate,
            this.minRange
        );
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.category = props.category || Constants.CTX_CATEGORIES.ROOMTYPE;
        this.metric = props.metric || Constants.METRICS.ROOMS;

        this.cache = new Map();
        this.dateHelper = window.infinito.vao.controller.dateHelper;
    }

    renderTable() {
        const tableOpts = {
            hotel: this.hotel,
            recordDate: this.recordDate,
            pickupSetting: this.pickupSetting,
            firstStayDate: this.firstStayDate,
            lastStayDate: this.lastStayDate,
            metric: this.metric,
        };
        let tableEl;
        if (this.category === Constants.CTX_CATEGORIES.ROOMTYPE) {
            if (this.cache.has('rtTable')) {
                tableEl = this.cache.get('rtTable');
                tableEl.reflow(tableOpts);
            } else {
                tableEl = new ContextTableRoomType(tableOpts);
                this.cache.set('rtTable', tableEl);
            }
        } else if (this.category === Constants.CTX_CATEGORIES.SEGMENT) {
            if (this.cache.has('segTable')) {
                tableEl = this.cache.get('segTable');
                tableEl.reflow(tableOpts);
            } else {
                tableEl = new ContextTableSegment(tableOpts);
                this.cache.set('segTable', tableEl);
            }
        }
        return tableEl || '';
    }

    renderRangeDateSelectInput() {
        let el;
        const opts = {
            startDate: this.firstStayDate,
            endDate: this.lastStayDate,
            minRange: this.minRange,
            minAllowedDate: this.dateHelper.subtractDaysFromDateString(this.recordDate, 1),
            maxAllowedDate: this.dateHelper.addDaysToDateString(this.recordDate, 365),
            onChange: (newStartDate, newEndDate) => {
                this.firstStayDate = newStartDate;
                this.lastStayDate = newEndDate;
            }
        };
        if (this.cache.has('dateSelect')) {
            el = this.cache.get('dateSelect');
            el.reflow(opts);
        } else {
            el = new RangeDateSelectInput(opts);
            this.cache.set('dateSelect', el);
        }
        return el;
    }

    renderCategorySelect() {
        if (this.cache.has('catSel')) {
            return this.cache.get('catSel');
        }
        let el = new CategorySelectChip({
            currentCategory: this.category,
            onChange: (newCategory) => {
                this.category = newCategory;
            }
        });
        this.cache.set('catSel', el);
        return el;
    }

    renderMetricSelect() {
        if (this.cache.has('metSel')) {
            return this.cache.get('metSel');
        }
        let el = new MetricSelectChip({
            currentMetric: this.metric,
            onChange: (newMetric) => {
                this.metric = newMetric;
            }
        });
        this.cache.set('metSel', el);
        return el;
    }

    renderBalance() {
        const opts = {
            hotel: this.hotel,
            recordDate: this.recordDate,
            pickupSetting: this.pickupSetting
        };
        let el = '';
        if (this.category === Constants.CTX_CATEGORIES.ROOMTYPE) {
            if (this.cache.has('rtBalance')) {
                el = this.cache.get('rtBalance');
                el.reflow(opts);
            } else {
                el = new ContextBalanceRoomType(opts);
                this.cache.set('rtBalance', el);
            }
        } else if (this.category === Constants.CTX_CATEGORIES.SEGMENT) {
            if (this.cache.has('segBalance')) {
                el = this.cache.get('segBalance');
                el.reflow(opts);
            } else {
                el = new ContextBalanceSegment(opts);
                this.cache.set('segBalance', el);
            }
        }
        return el;
    }

    render() {
        return html`
<div class="vao__components--pageContext rounded border" style="margin:1rem 0;background:white;">
    <div 
    class="vao__components--pageContext-filters" 
    style="display:flex;align-items:center;flex-wrap:wrap;padding: 1rem 1rem 0 1rem;"
    >
        <div>${this.renderRangeDateSelectInput()}</div>
        <div style="display: flex;flex-wrap:wrap;">
            <div style="margin-right:.5rem;margin-top:.5rem;">
                ${this.renderCategorySelect()}
            </div>
            <div style="margin-right:.5rem;margin-top:.5rem;">
                ${this.renderMetricSelect()}
            </div>
        </div>
        <div style="margin-right:.5rem;margin-top:.5rem;">
            ${this.renderBalance()}
        </div>
    </div>
    <hr>
    <div style="margin-top:.5rem;">${this.renderTable()}</div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageContext = PageContext;
customElements.define('vao-page-context', PageContext);
