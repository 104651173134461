import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import Popover, {popPlacements, popTriggers} from "../popover/Popover";
import MetricOptionBlock from "./MetricOptionBlock";

export default class MetricSelectChip extends AppElement {
    static get properties() {
        return {
            currentMetric: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.currentMetric = props.currentMetric;
        this.onChange = props.onChange;
        this.id = AppElement.getUniqueElementId();
    }

    _onChange(newMetric) {
        if (newMetric === this.currentMetric) {
            return;
        }
        this.currentMetric = newMetric;
        if (typeof this.onChange === 'function') {
            this.onChange(this.currentMetric);
        }
    }

    getDisplayableTextForMetric(metric) {
        if (metric === Constants.METRICS.ROOMS) {
            return Constants.STRINGS.ROOMS;
        } else if (metric === Constants.METRICS.OCCUPANCY) {
            return Constants.STRINGS.OCC;
        } else if (metric === Constants.METRICS.ADR) {
            return Constants.STRINGS.ADR;
        } else if (metric === Constants.METRICS.REVENUE) {
            return Constants.STRINGS.REV;
        } else {
            return Constants.MISSING_STR;
        }
    }

    renderPopover() {
        let $popWrapper = $('#' + this.id).find('.vao__components--metricSelectChip');
        let $anchor = $popWrapper.find('vao-chip');
        if ($anchor) {
            let pop;
            let content = new MetricOptionBlock({
                currentMetric: this.currentMetric,
                onChange: (newMetric) => {
                    this._onChange(newMetric);
                }
            });
            $popWrapper.find('vao-popover').remove();
            pop = new Popover({
                anchor: $anchor,
                content: content,
                trigger: popTriggers.focusCustom,
                placement: popPlacements.bottom
            });
            $popWrapper.append(pop);
        }
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.renderPopover();
    }

    render() {
        return html`
<div class="vao__components--metricSelectChip">
    <vao-chip 
        .label="${this.getDisplayableTextForMetric(this.currentMetric)}"
        endIconCls="${Constants.ICONS.ANGLE_DOWN}"
        clickable="true">
    </vao-chip> 
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MetricSelectChip = MetricSelectChip;
customElements.define('vao-metric-select-chip', MetricSelectChip);
