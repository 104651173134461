import {AppElement, html} from '../AppElement.js';
import {sendRequest} from '../App.common';
import TotalPickupTitle from "../totalPickupExpandCard/TotalPickupTitle";
import FlashExpandCard from "../expand/FlashExpandCard";
import Button from '../button/Button';
import Constants from "../Constants";
import TotalPickupExpandCardInfo from "../totalPickupExpandCard/TotalPickupExpandCardInfo";
import {calcDuration} from '../Utils';
import PickupPerMonthGroup from "../pickupPerMonth/PickupPerMonthGroup";
import YesterdayGroup from "../yesterday/YesterdayGroup";
import MiniContext from "../miniContext/MiniContext";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";

export default class FlashGroupGroupExpandCard extends AppElement {
    static get properties() {
        return {
            hotelGroup: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super();
        this.hotelGroup = props.hotelGroup || {};
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
        this.myvar = '';
        this.card = this.createCard();
    }

    reflow() {
        this.hotelGroup = props.hotelGroup || this.hotelGroup;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = props.pickupSetting || this.pickupSetting;

        this.card = this.createCard();
        this.fill();
    }


    fill() {
        let flg = this;
        // this.myvar = setInterval(function(){
        //     flg.fill();
        // },15000);

        let hotelsids = [];
        for(var j=0;j<this.hotelGroup.hotels.length;j++){
            hotelsids.push(this.hotelGroup.hotels[j]['id']);
        }

        let dataObj = {
            'hotelGroup_id':this.hotelGroup.id,
            'hotelsids':JSON.stringify(hotelsids),
            'hotel_count':this.hotelGroup.hotels.length,
            'recordDate': this.recordDate, // '2021-06-20'
            'pickupOffset':this.pickupSetting,
            'op':'getFlashGroupHotelGroupCardOptimized',
            'serviceName':"flashgroupapi.php"
        }
        let flag = this;

        sendRequest(dataObj,function(res){
            // clearInterval(flag.myvar);
            let obj = JSON.parse(res);
            const _procData = obj.data || {};
            const roomsOtbTotalPickup = _procData[0].value || {};
            const roomsOtbTotalPickupVal = roomsOtbTotalPickup;
            const adrTotalPickupVal = _procData[1].value || {};
            const revenueTotalPickupVal = _procData[2].value || {};
            const counters = _procData[3].counter;
            let otb, adr, rev, duration;

            if ($.isNumeric(roomsOtbTotalPickupVal)) {
                otb = new NumericVarianceBlock({
                    numericValue: roomsOtbTotalPickupVal,
                    formattedValue: String(roomsOtbTotalPickupVal),
                    tooltip: Constants.STRINGS.ROOMS
                });
                let roomsotbttlpickup = {
                    "receivedTs"  : _procData[0].receivedTs,
                    "offsetReceivedTs"  : _procData[0].offsetReceivedTs
                };
                duration = calcDuration(roomsotbttlpickup);
            } else {
                if(_procData[0].value == 0){
                    otb = new NumericVarianceBlock({
                        numericValue: 0,
                        formattedValue: String(0),
                        tooltip: Constants.STRINGS.ROOMS
                    });
                }else{
                    otb = Constants.MISSING_STR;
                }
            }

            if ($.isNumeric(adrTotalPickupVal)) {
                let _fAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    adrTotalPickupVal,
                    Constants.ADR_DIGITS,
                    true,
                    flag.hotelGroup.locale,
                    1
                );
                adr = new NumericVarianceBlock({
                    numericValue: adrTotalPickupVal,
                    formattedValue: _fAdr,
                    tooltip: Constants.STRINGS.ADR
                });
            } else {
                if(_procData[1].value == 0){
                    let _fAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        0,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotelGroup.locale, 1
                    );
                    adr = new NumericVarianceBlock({
                        numericValue: 0,
                        formattedValue: _fAdr,
                        tooltip: Constants.STRINGS.ADR
                    });
                }else{
                    adr = Constants.MISSING_STR;
                }
            }

            if ($.isNumeric(revenueTotalPickupVal)) {
                let _fRev = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    revenueTotalPickupVal,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotelGroup.locale
                );
                rev = new NumericVarianceBlock({
                    numericValue: revenueTotalPickupVal,
                    formattedValue: _fRev,
                    tooltip: Constants.STRINGS.REV
                });
            } else {
                if(_procData[2].value == 0){
                    let _fRev = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        0,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotelGroup.locale
                    );
                    rev = new NumericVarianceBlock({
                        numericValue: 0,
                        formattedValue: _fRev,
                        tooltip: Constants.STRINGS.REV
                    })
                }else{
                    rev = Constants.MISSING_STR;
                }
            }

            if (flag.card instanceof FlashExpandCard && flag.card.contentEl instanceof TotalPickupTitle) {
                flag.card.contentEl.reflow({
                    otb,
                    adr,
                    rev,
                    duration,
                    counters,
                    isLoading: false
                });
            }
        });


        // if (
        //     !this.hotelGroup
        //     || typeof this.hotelGroup !== 'object'
        //     || !('id' in this.hotelGroup)
        //     || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        //     || !$.isNumeric(this.pickupSetting)
        // ) {
        //     return;
        // }

        // let biStatistics = window.infinito.vao.model.biHotelGroupsStatistics;
        // biStatistics.fetchStatistics(
        //     this.hotelGroup.id,
        //     biStatistics.buildQuery({
        //         recordDate: this.recordDate,
        //         fields: [
        //             biStatistics.fields.roomsOtbTotalPickup,
        //             biStatistics.fields.adrTotalPickup,
        //             biStatistics.fields.revenueTotalPickup
        //         ],
        //         pickupOffset: this.pickupSetting
        //     }),
        //     (data, procData) => {
        //         const _procData = procData || {};
        //         const roomsOtbTotalPickup = _procData.roomsOtbTotalPickup || {};
        //         const roomsOtbTotalPickupVal = roomsOtbTotalPickup.value;
        //         const adrTotalPickupVal = (_procData.adrTotalPickup || {}).value;
        //         const revenueTotalPickupVal = (_procData.revenueTotalPickup || {}).value;
        //         let otb, adr, rev, duration;
        //
        //         if ($.isNumeric(roomsOtbTotalPickupVal)) {
        //             otb = new NumericVarianceBlock({
        //                 numericValue: roomsOtbTotalPickupVal,
        //                 formattedValue: String(roomsOtbTotalPickupVal),
        //                 tooltip: Constants.STRINGS.ROOMS
        //             });
        //             duration = calcDuration(roomsOtbTotalPickup);
        //         } else {
        //             otb = Constants.MISSING_STR;
        //         }
        //
        //         if ($.isNumeric(adrTotalPickupVal)) {
        //             let _fAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
        //                 adrTotalPickupVal,
        //                 Constants.ADR_DIGITS,
        //                 true,
        //                 this.hotelGroup.locale
        //             );
        //             adr = new NumericVarianceBlock({
        //                 numericValue: adrTotalPickupVal,
        //                 formattedValue: _fAdr,
        //                 tooltip: Constants.STRINGS.ADR
        //             });
        //         } else {
        //             adr = Constants.MISSING_STR;
        //         }
        //
        //         if ($.isNumeric(revenueTotalPickupVal)) {
        //             let _fRev = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
        //                 revenueTotalPickupVal,
        //                 Constants.REVENUE_DIGITS,
        //                 true,
        //                 this.hotelGroup.locale
        //             );
        //             rev = new NumericVarianceBlock({
        //                 numericValue: revenueTotalPickupVal,
        //                 formattedValue: _fRev,
        //                 tooltip: Constants.STRINGS.REV
        //             });
        //         } else {
        //             rev = Constants.MISSING_STR;
        //         }
        //
        //         if (this.card instanceof FlashExpandCard && this.card.contentEl instanceof TotalPickupTitle) {
        //             this.card.contentEl.reflow({
        //                 otb,
        //                 adr,
        //                 rev,
        //                 duration,
        //                 isLoading: false
        //             });
        //         }
        //     }
        // )
    }

    onCardHelp() {
        window.infinito.components.basicModal.render({
            title: 'Skeleton',
            bodyElem: $(new TotalPickupExpandCardInfo())
        });
    }

    createCard() {
        const content = new TotalPickupTitle({
            name: this.hotelGroup.groupName,
            isLoading: true
        });

        const pickupPerMonthGroup = new PickupPerMonthGroup({
            hotelGroup: this.hotelGroup,
            recordDate: this.recordDate,
            pickupSetting: this.pickupSetting
        });

        const yesterdayBtn = new Button({
            text: 'Yesterday',
            color: 'light',
            size: 'small',
            onClick: () => {
                const yesterdayGroup = new YesterdayGroup({
                    recordDate: this.recordDate,
                    hotelGroup: this.hotelGroup
                });
                const prettyRecordDate = window.infinito.vao.controller.dateHelper
                    .getShortDayDateMonFullYearString(this.recordDate);
                const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
                    yesterdayGroup.yesterday
                );
                const $title =  $('<div></div>').append(
                    '<h5>Yesterday</h5>'
                ).append(new MiniContext({
                    recordDate: prettyRecordDate,
                    stayDate: prettyStayDate,
                    isModalStyle: false
                }));
                window.infinito.components.basicModal.render({
                    title: $title,
                    isTitleHtml: true,
                    bodyElem: $(yesterdayGroup)
                });
            }
        });

        const expand = html`
            <div 
                class="vao__components--flashGroupGroupExpandCard-btns" 
                style="padding:.5rem 0;display:flex;justify-content:flex-end;"
            >
                <div>${yesterdayBtn}</div>     
            </div>
            ${pickupPerMonthGroup} 
        `;

        return new FlashExpandCard({
            contentEl: content,
            expandEl: expand,
            onHelp: 'tooltips',
            isExpanded: false,
            borderCol: '#9c27b0'
        });
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
        <div class="vao__components--flashGroupGroupExpandCard">
            ${this.card}     
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.FlashGroupGroupExpandCard = FlashGroupGroupExpandCard;
customElements.define('vao-flash-group-group-expand-card', FlashGroupGroupExpandCard);
