import {AppElement, html} from '../AppElement.js';
import Constants from "../Constants";
import Popover, {popBoundaries, popPlacements, popTriggers} from "../popover/Popover";
import TableTheadStyleContent from "./TableTheadStyleContent";
import TableColumnVisibilityContent from "./TableColumnVisibilityContent";
import {tableAlignTypes, tableDensityTypes} from "./Table";

export const EVENT_ON_TABLE_FILTER_CHANGE = 'vao-table-filters-change';

export default class TableFilters extends AppElement {
    static get properties() {
        return {
            tableOpts: { type: Object },
            columns: { type: Array },
            canRenderSwappedMode: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.id = AppElement.getUniqueElementId();
        this.theadStylePop = null;
        this.tableColumnPop = null;
        this.tableColumnPopContent = null;

        this.tableOpts = props.tableOpts || this.tableOpts;
        this.columns = props.columns || this.columns;
        this.canRenderSwappedMode = this.reflowBool('true', props.canRenderSwappedMode);
    }

    reflow(props = {}) {
        this.tableOpts = Object.assign({}, this.tableOpts, props.tableOpts || {});
        if ('columns' in props) {
            this.columns = props.columns || [];
        }
        this.canRenderSwappedMode = this.reflowBool(this.canRenderSwappedMode, props.canRenderSwappedMode);
    }

    onTableOptsChange(e) {
        const event = new CustomEvent(EVENT_ON_TABLE_FILTER_CHANGE, {
            detail: {
                event: e,
                tableOpts: this.tableOpts
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    makeColumnVisibilityPopover() {
        let $popWrapper = $('#' + this.id);
        let $anchor = $popWrapper.find('.vao__components--table-filterColumns').find('vao-button');
        if ($anchor) {
            this.tableColumnPopContent = new TableColumnVisibilityContent({
                columns: this.columns,
                eventTarget: this, // Need this because a popover body no longer has the table as a parent.
            });
            this.tableColumnPop = new Popover({
                anchor: $anchor,
                content: this.tableColumnPopContent,
                trigger: popTriggers.focusCustom,
                placement: popPlacements.bottom,
                boundary: popBoundaries.window
            });
            $popWrapper.append(this.tableColumnPop);
        }
    }

    makeTheadStylePopover() {
        let $popWrapper = $('#' + this.id);
        let $anchor = $popWrapper.find('.vao__components--table-filterTheadStyle').find('vao-button');
        if ($anchor) {
            let content = new TableTheadStyleContent({
                current: this.tableOpts.theadStyle,
                onCLickOption: (newStyle) => {
                    this.tableOpts = {...this.tableOpts, theadStyle: newStyle};
                    this.theadStylePop.popHide();
                    this.onTableOptsChange();
                }
            });
            this.theadStylePop = new Popover({
                anchor: $anchor,
                content: content,
                trigger: popTriggers.focusCustom,
                placement: popPlacements.bottom,
                boundary: popBoundaries.window
            });
            $popWrapper.append(this.theadStylePop);
        }
    }

    handleColumnVisibilityClick() {
        if (!(this.tableColumnPop instanceof Popover)) {
            this.makeColumnVisibilityPopover();
        }
    }

    handleTheadStyleClick() {
        if (!(this.theadStylePop instanceof Popover)) {
            this.makeTheadStylePopover();
        }
    }

    handleSwapAxisClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isSwappedAxis: !this.tableOpts.isSwappedAxis
        });
        this.onTableOptsChange(e);
    }

    handleColBorderBottomClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isColoredColumns: !this.tableOpts.isColoredColumns
        });
        this.onTableOptsChange(e);
    }

    handleStaticLeftCLick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isFixedFirstColumn: !this.tableOpts.isFixedFirstColumn
        });
        this.onTableOptsChange(e);
    }

    handleStickyHeaderClick(e) {
        const isDisabled = e.currentTarget.disabledState;
        let newValue = !this.tableOpts.isStickyHeader;
        if (isDisabled === true || isDisabled === 'true') {
            newValue = false; // Never let isStickyHeader be anything other than false when disabled.
        }
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isStickyHeader: newValue
        });
        this.onTableOptsChange(e);
    }

    handleAlignLeftBtnClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            align: tableAlignTypes.left
        });
        this.onTableOptsChange(e);
    }

    handleAlignCenterBtnClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            align: tableAlignTypes.center
        });
        this.onTableOptsChange(e);
    }

    handleAlignRightBtnClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            align: tableAlignTypes.right
        });
        this.onTableOptsChange(e);
    }

    handleBorderBtnClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isBordered: !this.tableOpts.isBordered
        });
        this.onTableOptsChange(e);
    }

    handleZebraBtnClick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isStripedRows: !this.tableOpts.isStripedRows
        });
        this.onTableOptsChange(e);
    }

    handlePadClickSmall(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            paddingType: tableDensityTypes.compact
        });
        this.onTableOptsChange(e);
    }

    handlePadClickNormal(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            paddingType: tableDensityTypes.normal
        });
        this.onTableOptsChange(e);
    }

    handlePadClickLarge(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            paddingType: tableDensityTypes.large
        });
        this.onTableOptsChange(e);
    }

    handleFontSizeBtnCLick(e) {
        this.tableOpts = Object.assign({}, this.tableOpts, {
            isSmallFont: !this.tableOpts.isSmallFont
        });
        this.onTableOptsChange(e);
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.makeTheadStylePopover();
        this.makeColumnVisibilityPopover();
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('columns')) {
            if (this.tableColumnPopContent instanceof TableColumnVisibilityContent) {
                this.tableColumnPopContent.reflow({
                    columns: this.columns
                });
            }
        }
    }

    render() {
        return html`
<div class="vao__components--table-filters" style="display:flex;overflow:auto;">
    <div class="vao__components--table-filterSwapAxis">
        <vao-button
            tooltip="Swap X & Y Axis"
            variant="switch"
            .activeState="${this.tableOpts.isSwappedAxis}"
            .disabledState="${this.canRenderSwappedMode === 'false'}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.RETWEET}"
            @click="${this.handleSwapAxisClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterStickyHeader">
        <vao-button
            tooltip="Sticky Header"
            variant="switch"
            .activeState="${this.tableOpts.isStickyHeader}"
            .disabledState="${this.canRenderSwappedMode === 'false'}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.CARET_SQUARE_UP}"
            @click="${this.handleStickyHeaderClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterStaticLeft">
        <vao-button
            tooltip="Static First Column"
            variant="switch"
            .activeState="${this.tableOpts.isFixedFirstColumn}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.CARET_SQUARE_LEFT}"
            @click="${this.handleStaticLeftCLick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterSeparator"></div>
    <div class="vao__components--table-filterColumns">
        <vao-button
                tooltip="Show/Hide Columns"
                variant="switch"
                activeState="true"
                size="small"
                color="light"
                startIcon="${Constants.ICONS.COLUMNS}"
                @click="${this.handleColumnVisibilityClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterSeparator"></div>
    <div class="vao__components--table-filterBorder">
        <vao-button
            tooltip="Bordered"
            variant="switch"
            .activeState="${this.tableOpts.isBordered}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.BORDER_ALL}"
            @click="${this.handleBorderBtnClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterZebra">
        <vao-button
            tooltip="Striped"
            variant="switch"
            .activeState="${this.tableOpts.isStripedRows}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.HORSE}"
            @click="${this.handleZebraBtnClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterFontSize">
        <vao-button
            tooltip="Font Size"
            variant="switch"
            .activeState="${!this.tableOpts.isSmallFont}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.TEXT_HEIGHT}"
            @click="${this.handleFontSizeBtnCLick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterTheadStyle">
        <vao-button
            tooltip="Heading Style"
            variant="switch"
            activeState="true"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.MASK}"
            @click="${this.handleTheadStyleClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterColorColBorderBottom">
        <vao-button
                tooltip="Color Columns"
                variant="switch"
                .activeState="${this.tableOpts.isColoredColumns}"
                size="small"
                color="light"
                startIcon="${Constants.ICONS.PAINT_BRUSH}"
                @click="${this.handleColBorderBottomClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterSeparator"></div>
    <div class="vao__components--table-filterAlignLeft">
        <vao-button
            tooltip="Align Left"
            variant="switch"
            .activeState="${this.tableOpts.align === tableAlignTypes.left}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.ALIGN_LEFT}"
            @click="${this.handleAlignLeftBtnClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterAlignCenter">
        <vao-button
            tooltip="Align Center"
            variant="switch"
            .activeState="${this.tableOpts.align === tableAlignTypes.center}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.ALIGN_CENTER}"
            @click="${this.handleAlignCenterBtnClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterAlignRight">
        <vao-button
            tooltip="Align Right"
            variant="switch"
            .activeState="${this.tableOpts.align === tableAlignTypes.right}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.ALIGN_RIGHT}"
            @click="${this.handleAlignRightBtnClick}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterSeparator"></div>
    <div class="vao__components--table-filterPadSmall">
        <vao-button
            tooltip="Compact Density"
            variant="switch"
            .activeState="${this.tableOpts.paddingType === tableDensityTypes.compact}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.WINDOW_MINIMIZE}"
            @click="${this.handlePadClickSmall}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterPadMedium">
        <vao-button
            tooltip="Normal Density"
            variant="switch"
            .activeState="${this.tableOpts.paddingType === tableDensityTypes.normal}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.WINDOW_MAXIMIZE}"
            @click="${this.handlePadClickNormal}">
        </vao-button>
    </div>
    <div class="vao__components--table-filterPadLarge">
        <vao-button
            tooltip="Large Density"
            variant="switch"
            .activeState="${this.tableOpts.paddingType === tableDensityTypes.large}"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.WINDOW_RESTORE}"
            @click="${this.handlePadClickLarge}">
        </vao-button>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableFilters = TableFilters;
customElements.define('vao-table-filters', TableFilters);
