import { AppElement, html } from '../AppElement.js';
import {OPTION_30_DAYS} from "../dateSelect/StayDateRangeSelect";

export default class PageDesktopSingle extends AppElement {
    static get properties() {
        return {
            hotel: {type: Object},
            recordDate: {type: String},
            pickupSetting: {type: Number},

            // @internalProperty
            dayofweekfilter: { attribute: false },
            rangeValue: { attribute: false },
            startDate: {attribute: false},
            endDate: {attribute: false},
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel || this.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );

        this.rangeValue = OPTION_30_DAYS;
        this.dayofweekfilter = 'all_days';
        this.startDate = props.startDate || this.recordDate;
        this.endDate = props.endDate
            || window.infinito.vao.controller.dateHelper.addDaysToDateString(this.startDate, 30);
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    onChangeStayDateRange(e) {
        this.rangeValue = e.detail.newValue;
        this.dayofweekfilter = $("#vao__desktop-weekday-Selection").val();
        this.startDate = e.detail.rangeData.startDate;
        this.endDate = e.detail.rangeData.endDate;
    }
    dayofweekchange(e){
        this.dayofweekfilter = e.target.value;
    }
    onFlashButtonClick(){
        let body = '<vao-page-flash-modal></vao-page-flash-modal>';

        let $title =  $('<div></div>').append(
            `<h5>Flash</h5>`
        );
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'lg'
        });
    }

    onMonthlyButtonClick(){
        let body =  '<input type="hidden" id="hid" value="'+this.hotel.id+'" />' +
                    '<input type="hidden" id="hname" value="'+this.hotel.name+'" />' +
                    '<vao-page-monthlyanalysis-modal></vao-page-monthlyanalysis-modal>';

        let $title =  $('<div></div>').append(
            `<h5>Monthly Analysis</h5>`
        );
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'xl'
        });
    }

    render() {
        return html`
            <div class="vao__components--pageDesktopSingle">
                <div class="rounded border bg-white">
                    <div 
                        class="vao__components--pageDesktopSingle-filters" 
                        style="display:flex;align-items:center;flex-wrap:wrap;padding: 0rem 1rem 0 1rem;"
                    >
                        <span style="margin-right:15px;margin-top: 15px;">
                            <vao-stay-date-range-select
                                    .recordDate=${this.recordDate}
                                    .value=${this.rangeValue}
                                    @vao-stay-date-range-select-change="${this.onChangeStayDateRange}"
                            ></vao-stay-date-range-select>
                        </span>
                        <span style="margin-right:15px;margin-top: 15px;">
                            <select id="vao__desktop-weekday-Selection" @change="${this.dayofweekchange}" class="form-control">
                                <option value="all_days">All Days</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>
                        </span>
                        <button  class="btn" style="margin-top: 15px;margin-right:15px;border-color: rgb(233, 30, 99);color:grey;height:38px;width:100px;" @click="${this.onFlashButtonClick}"><i class="fas fa-bolt"></i> Flash</button>
                        <button  class="btn" style="margin-top: 15px;margin-right:15px;border-color: rgb(33 150 243);color:grey;height:38px;width:auto;" @click="${this.onMonthlyButtonClick}"><i class="fas fa-chart-area"></i> Monthly Analysis </button>
                       
                    </div>
                    <hr>
                    <div style="margin-top:.5rem;">
                        <vao-page-desktop-single-table
                            .hotel="${this.hotel}"
                            .recordDate="${this.recordDate}"
                            .pickupSetting="${this.pickupSetting}"
                            startDate="${this.startDate}"
                            endDate="${this.endDate}"
                            dayofweekfilter="${this.dayofweekfilter}"
                        ></vao-page-desktop-single-table>
                    </div>
                </div>
            </div>
        `;
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktopSingle = PageDesktopSingle;
customElements.define('vao-page-desktop-single', PageDesktopSingle);
