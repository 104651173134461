import {makeChannelOptionHtml} from "./ChannelOptionHtml";

export function makeTiketChannelOptionHtml() {
    const innerHtml = '<span style="color:#0163D3;font-weight:bold;">tiket</span>'
        + '<span style="background:#FFDC02;height:6px;width:6px;border-radius:50%;display:inline-block;"></span>'
        + '<span style="color:#0163D3;">com</span>'
    const html = makeChannelOptionHtml(innerHtml, [
        'background: white',
    ]);
    return html;
}
