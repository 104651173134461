import { AppElement, html } from '../AppElement.js';

export default class ColorPalette extends AppElement {
    static get properties() {
        return {
            palette: { type: Array }
        };
    }

    constructor(props = {}) {
        super();
        this.palette = props.palette || [];
        this.compClass = 'vao__components--colorPalette';
    }

    render() {
        return html`
        <div class="${this.compClass}">
             ${this.palette.map(hexColor => {
                return html`<div class="${this.compClass + '-col'}" style="background:${hexColor}"></div>`
             })}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ColorPalette = ColorPalette;
customElements.define('vao-color-palette', ColorPalette);
