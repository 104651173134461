import { AppElement, html } from '../AppElement.js';
import TableColumn from "./TableColumn";
import TableCell from "./TableCell";

export const EVENT_ON_TABLE_COLUMN_VISIBILITY_CHANGE = 'vao-table-column-visibility-change';

export default class TableColumnVisibilityContent extends AppElement {
    static get properties() {
        return {
            columns: { type: Array }
        };
    }

    constructor(props = {}) {
        super();
        this.id = AppElement.getUniqueElementId();

        this.columns = props.columns || this.columns || [];
        this.eventTarget = props.eventTarget;
    }

    reflow(props = {}) {
        if ('columns' in props) {
            this.columns = props.columns || [];
        }
    }

    onClickColumn(col, e) {
        const event = new CustomEvent(EVENT_ON_TABLE_COLUMN_VISIBILITY_CHANGE, {
            detail: {
                event: e,
                column: col
            },
            bubbles: true
        });
        if (this.eventTarget) {
            this.eventTarget.dispatchEvent(event);
            return;
        }
        this.dispatchEvent(event);
    }

    hasAnyVisibleChildren(col) {
        return col.children.some(childColumn => childColumn.isVisible);
    }

    renderOptions() {
        let colTemplateResults = [];
        let isFirstColumn = true;

        if (!Array.isArray(this.columns) || this.columns.length === 0) {
            return html`No columns to toggle.`;
        }

        const recurse = (cols, depth, shouldTrickleDisable) => {
            cols.forEach((col) => {
                if (!(col instanceof TableColumn) || !(col.tableCell instanceof TableCell)) {
                    return;
                }
                const id = AppElement.getUniqueElementId();
                const isDisabled = isFirstColumn || shouldTrickleDisable;
                const hasAnyChildren = col.children.length > 0;
                const hasAnyVisibleChildren = this.hasAnyVisibleChildren(col);
                const hasChildrenAndNoneAreVisible = hasAnyChildren ? !hasAnyVisibleChildren : false;
                const isCheckedAndHasChildrenAndNoneAreVisible = col.isVisible && hasChildrenAndNoneAreVisible;
                colTemplateResults.push(html`
                    <div 
                        class="form-check ${isCheckedAndHasChildrenAndNoneAreVisible ? 'text-muted' : ''}" 
                        style="margin:0.1rem 0.1rem 0.1rem ${depth}rem"
                    >
                        <input 
                            id="${id}" 
                            type="checkbox" 
                            class="form-check-input"
                            @change="${this.onClickColumn.bind(this, col)}"
                            ?checked="${col.isVisible}"
                            ?disabled="${isDisabled}"
                        >
                        <label class="form-check-label" for="${id}">${col.tableCell.value}</label>
                    </div>
                `);
                isFirstColumn = false;
                if (col.children.length > 0) {
                    recurse(col.children, depth + 1, isDisabled || !col.isVisible);
                }
            });
        };
        recurse(this.columns, 0, false);

        return colTemplateResults;
    }

    render() {
        return html`
<div class="vao__components--tableColumnVisibilityContent" style="overflow: auto;max-height: 40vh;">
    ${this.renderOptions()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableColumnVisibilityContent = TableColumnVisibilityContent;
customElements.define('vao-table-column-visibility-content', TableColumnVisibilityContent);
