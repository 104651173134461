import {makeChannelOptionHtml} from "./ChannelOptionHtml";

export function makeTrivagoChannelOptionHtml() {
    const innerHtml = '<span style="color: #1391D2;font-weight:bold;">tri</span>'
        + '<span style="color: #FC9E15;font-weight:bold;">va</span>'
        + '<span style="color: #E3403D;font-weight:bold;">go</span>';
    const html = makeChannelOptionHtml(innerHtml, [
        'background: white;',
    ]);
    return html;
}
