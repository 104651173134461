import { AppElement, html } from '../AppElement.js';
import Expand from "../expand/Expand";
import SegmentBreakdownTable from "./SegmentBreakdownTable";
import MetricBreakdownStaleBadge from "./MetricBreakdownStaleBadge";

export default class SegmentBreakdownExpand extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            stayDate: { type: String },
            pickupSetting: { type: Number },
            isExpanded: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();

        this.hotel = props.hotel || {};
        this.recordDate = props.recordDate;
        this.stayDate = props.stayDate;
        this.isExpanded = props.isExpanded;
        this.pickupSetting = props.pickupSetting;

        this.cache = new Map();
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.isExpanded = props.isExpanded || this.isExpanded;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    renderExpand() {
        let el;

        if (this.cache.has('el')) {
            el = this.cache.get('el');
            let badgeEl = this.cache.get('badgeEl');
            badgeEl.reflow({
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                hotel: this.hotel,
                badgeTxt: '',
                badgeCls: ''
            });
            let tableEl = this.cache.get('tableEl');
            tableEl.reflow({
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                hotel: this.hotel,
            });
        } else {
            let contentEl, expandEl;
            let badgeEl = new MetricBreakdownStaleBadge({
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                hotel: this.hotel,
                recordMatcher: window.infinito.vao.model.biStatistics.recordMatchers.marketSegment
            });
            this.cache.set('badgeEl', badgeEl);
            contentEl = html`<div class="vao__components--segmentBreakdownExpand-face">Market Segment ${badgeEl}</div>`;
            let tableEl = new SegmentBreakdownTable({
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                hotel: this.hotel,
            });
            this.cache.set('tableEl', tableEl);
            expandEl = html`
                <div class="vao__components--segmentBreakdownExpand-body">
                    ${tableEl}
                </div>
            `;
            el = new Expand({
                contentEl,
                expandEl,
                isExpanded: this.isExpanded
            });
            this.cache.set('el', el);
        }

        return el;
    }

    render() {
        return html`
        <div class="vao__components--segmentBreakdownExpand">
            ${this.renderExpand()}     
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.SegmentBreakdownExpand = SegmentBreakdownExpand;
customElements.define('vao-segment-breakdown-expand', SegmentBreakdownExpand);
