import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {storyHtml, StoryText, storyTextTypes} from "../StoryTextElement";

export default class TotalPickupHotelTrendDetailsRevenue extends AppElement {
    static get properties() {
        return {
            from: { type: String },
            to: { type: Object },
            change: { type: String },
            fromTs: { type: String },
            toTs: { type: String },
            trend: { type: String },
            tz: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.hotel = props.hotel;
        this.pickupSetting = props.pickupSetting;
        this.recordDate = props.recordDate;

        // Props.
        this.from = props.from;
        this.to = props.to;
        this.change = props.change;
        this.fromTs = props.fromTs;
        this.toTs = props.toTs;
        this.tz = props.tz;
        this.trend = props.trend;

        if (!this.tz && this.hotel) {
            this.tz = this.hotel.timezone;
        }
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = typeof props.pickupSetting === 'undefined' ? this.pickupSetting : props.pickupSetting;

        // Props.
        this.from = props.from || this.from;
        this.to = props.to || this.to;
        this.change = props.change || this.change;
        this.fromTs = props.fromTs || this.fromTs;
        this.toTs = props.toTs || this.toTs;
        this.trend = props.trend || this.trend;

        if (!this.tz && this.hotel && this.tz !== this.hotel.timezone) {
            this.tz = this.hotel.timezone;
        }

        this.fill();
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !$.isNumeric(this.pickupSetting)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        ) {
            return;
        }

        const biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                fields: [
                    biStatistics.fields.revenueTotalPickup,
                    biStatistics.fields.revenueTotal,
                    biStatistics.fields.revenueTotalPickupTrend
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                const _procData = procData || {};
                const revenueTotalPickup = _procData.revenueTotalPickup || {};
                const revenueTotal = _procData.revenueTotal || {};
                const revenueTotalPickupTrend = _procData.revenueTotalPickupTrend || {};
                let then, formattedThen, now, formattedNow, change, formattedChange, thenTs, nowTs, trend;

                if ($.isNumeric(revenueTotalPickup.value)) {
                    change = revenueTotalPickup.value;
                    const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueTotalPickup.value,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                    formattedChange = storyHtml`${new StoryText(
                        formatted,
                        true,
                        storyTextTypes.TYPE_MONETARY,
                        this.hotel.locale
                    )}`;
                    nowTs = revenueTotalPickup.receivedTs;
                    thenTs = revenueTotalPickup.offsetReceivedTs;
                }

                if ($.isNumeric(revenueTotal.value)) {
                    now = revenueTotal.value;
                    formattedNow = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueTotal.value,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                }

                if ($.isNumeric(change) && $.isNumeric(now)) {
                    then = String(parseFloat(now) - parseFloat(change));
                    formattedThen = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        then,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                }

                if ($.isNumeric(revenueTotalPickupTrend.value)) {
                    const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueTotalPickupTrend.value,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                    trend = storyHtml`${new StoryText(
                        formatted,
                        true,
                        storyTextTypes.TYPE_MONETARY,
                        this.hotel.locale
                    )}`;
                } else {
                    trend = Constants.MISSING_STR;
                }

                this.from = formattedThen;
                this.to = formattedNow;
                this.change = formattedChange;
                this.fromTs = thenTs;
                this.toTs = nowTs;
                this.tz = this.hotel.timezone;
                this.trend = trend;
            }
        )
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
        <div class="vao__components--totalPickupHotelTrendDetailsRevenue">
            <vao-pickup-detail
                .from="${this.from}"
                .to="${this.to}"
                .change="${this.change}"
                .fromTs="${this.fromTs}"
                .toTs="${this.toTs}"
                .tz="${this.tz}"
                .shouldWrap="true"
            ></vao-pickup-detail>
            <div>
                <span class="text-muted">Trend</span><span style="margin-left:1rem;">${this.trend}</span>
            </div>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TotalPickupHotelTrendDetailsRevenue = TotalPickupHotelTrendDetailsRevenue;
customElements.define('vao-total-pickup-hotel-trend-details-revenue', TotalPickupHotelTrendDetailsRevenue);