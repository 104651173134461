import { AppElement, html } from '../AppElement.js';
import DateSelectInput from "./DateSelectInput";

export default class RangeDateSelectInput extends AppElement {
    static get properties() {
        return {
            startDate: { type: String },
            endDate: { type: String },
            minRange: { type: Number },
            maxRange: { type: Number },
            minAllowedDate: { type: String },
            maxAllowedDate: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.dateHelper = window.infinito.vao.controller.dateHelper;
        this.id = AppElement.getUniqueElementId();
        this.cache = new Map();

        this.startDate = props.startDate;
        this.endDate = props.endDate;
        this.minRange = $.isNumeric(props.minRange) ? props.minRange : 7;
        this.maxRange = $.isNumeric(props.maxRange) ? props.maxRange : 365;
        this.minAllowedDate = props.minAllowedDate;
        this.maxAllowedDate = props.maxAllowedDate;
        this.onChange = props.onChange;

        this.validateProps();
    }

    reflow(props = {}) {
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;
        this.minRange = $.isNumeric(props.minRange) ? props.minRange : this.minRange;
        this.maxRange = $.isNumeric(props.maxRange) ? props.maxRange : this.maxRange;
        this.minAllowedDate = props.minAllowedDate || this.minAllowedDate;
        this.maxAllowedDate = props.maxAllowedDate || this.maxAllowedDate;
        this.onChange = props.onChange || this.onChange;

        this.validateProps();
    }

    validateProps() {
        if (!this.dateHelper.isDateStr(this.startDate)) {
            throw new Error('Start date is not an ISO8601 date string')
        }
        if (!this.dateHelper.isDateStr(this.endDate)) {
            throw new Error('End date is not an ISO8601 date string')
        }
        if (!this.dateHelper.isDateStr(this.minAllowedDate)) {
            throw new Error('Min allowed date is not an ISO8601 date string')
        }
        if (!this.dateHelper.isDateStr(this.maxAllowedDate)) {
            throw new Error('Max allowed date is not an ISO8601 date string')
        }
        if (!$.isNumeric(this.minRange) || this.minRange < 0) {
            throw new Error('Min range is not numeric');
        }
        if (!$.isNumeric(this.maxRange) || this.maxRange < this.minRange) {
            throw new Error('Invalid max range prop')
        }
    }

    _onChange() {
        if (typeof this.onChange === 'function') {
            this.onChange(this.startDate, this.endDate);
        }
    }

    getMinLastStayDateForFirstStayDate(firstStayDate) {
        return this.dateHelper.addDaysToDateString(firstStayDate, this.minRange);
    }

    renderFirstStayDateDateSelect() {
        let el;
        const opts = {
            date: this.startDate,
            canShowNextPrev: 'false',
            canShowIcon: 'false',
            onChange: (newDate) => {
                this.startDate = newDate;
                const minRequiredLastStayDate = this.getMinLastStayDateForFirstStayDate(newDate);
                if (this.dateHelper.compareDateStrs(this.endDate, minRequiredLastStayDate) < 0) {
                    this.endDate = minRequiredLastStayDate;
                }
                this._onChange();
            },
            makeMinDate: () => {
                return this.minAllowedDate;
            },
            makeMaxDate: () => {
                return this.dateHelper.subtractDaysFromDateString(
                    this.maxAllowedDate,
                    this.minRange
                );
            }
        };
        if (this.cache.has('startDateSelect')) {
            el = this.cache.get('startDateSelect');
            el.reflow(opts);
        } else {
            el = new DateSelectInput(opts);
            this.cache.set('startDateSelect', el);
        }
        return el;
    }

    renderLastStayDateDateSelect() {
        let el;
        const opts = {
            date: this.endDate,
            canShowNextPrev: 'false',
            canShowIcon: 'false',
            onChange: (newDate) => {
                this.endDate = newDate;
                this._onChange();
            },
            makeMinDate: () => {
                return this.dateHelper.addDaysToDateString(
                    this.startDate,
                    this.minRange
                );
            },
            makeMaxDate: () => {
                return this.maxAllowedDate;
            }
        };
        if (this.cache.has('endDateSelect')) {
            el = this.cache.get('endDateSelect');
            el.reflow(opts);
        } else {
            el = new DateSelectInput(opts);
            this.cache.set('endDateSelect', el);
        }
        return el;
    }

    render() {
        return html`
            <div class="vao__components--rangeDateSelectInput" style="display:flex;flex-wrap:wrap;">
                <div style="margin-right:.5rem;">
                    <span>Start date</span>
                    ${this.renderFirstStayDateDateSelect()}
                </div>
                <div style="margin-right:.5rem;">
                    <span>End date</span>
                    ${this.renderLastStayDateDateSelect()}
                </div>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.RangeDateSelectInput = RangeDateSelectInput;
customElements.define('vao-range-date-select-input', RangeDateSelectInput);
