import TableRow from "./TableRow";
import {totalRowCSSClass} from "./Table";

export default class TableTotalRow extends TableRow {

    constructor(tableCells = [], data = {}, rowClasses = []) {
        if (!rowClasses.includes(totalRowCSSClass)) {
            rowClasses.push(totalRowCSSClass);
        }
        super(tableCells, data, rowClasses);
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableTotalRow = TableTotalRow;