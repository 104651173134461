import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import ColorArray from "../ColorArray";

export default class PropertyMonthEvolutionSparkChart extends AppElement {
    static get properties() {
        return {
            data: { type: Object },
            month: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.hasDestroyedChart = false;
        this.compClass = "vao__components--propertyMonthEvolutionSparkChart";
        this.chartId = AppElement.getUniqueElementId();

        this.data = props.data || {};
        this.month = props.month || null;
    }

    reflow(props = {}) {
        this.data = props.data || {};
        this.month = props.month || null;
        this.fill();
    }

    chartOpts() {
        return {
            chart: {
                backgroundColor: null,
                borderWidth: 0,
                type: 'area',
                margin: [2, 0, 2, 0],
                width: 120,
                height: 20,
                style: {
                    overflow: 'visible'
                },

                // small optimalization, saves 1-2 ms each sparkline
                skipClone: true
            },
            colors: (new ColorArray()).colorArray,
            title: {
                text: ''
            },
            lang: {
                noData: Constants.MISSING_STR
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                labels: {
                    enabled: false,
                    formatter: function formatter() {
                        return infinito.vao.controller.dateHelper.getShortDateMonString(
                            infinito.vao.controller.dateHelper.dateStrToDate(
                                infinito.vao.controller.dateHelper.getISODateFormat(),
                                this.value
                            )
                        );
                    }
                },
                title: {
                    text: null
                },
                startOnTick: false,
                endOnTick: false,
                tickPositions: []
            },
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tickPositions: [0]
            },
            legend: {
                enabled: false
            },
            tooltip: {
                hideDelay: 0,
                outside: true,
                shared: true
            },
            plotOptions: {
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    marker: {
                        radius: 1,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    },
                    fillOpacity: 0.25
                },
                column: {
                    negativeColor: '#910000',
                    borderColor: 'silver'
                }
            }
        };
    }

    empty() {
        let chart = this.getChart();
        if (!chart) {
            return;
        }
        while (chart.series.length) {
            chart.series[0].remove();
        }
    }

    getChart() {
        if (this.chart) {
            return this.chart;
        }
        if (document.getElementById(this.chartId) === null) {
            // wait until its in the DOM or else highcharts will error
            return null;
        }
        this.chart = window.Highcharts.chart(this.chartId, this.chartOpts());
        return this.chart;
    }

    fill() {
        // Currently not smart enough to lookup the data. Component needs it passed in.
        let biStatistics = window.infinito.vao.model.biStatistics;
        if (
            !this.data
            || typeof this.data !== 'object'
            || !(biStatistics.fields.roomsOtbTwelveMonthsEvolution in this.data)
            || !this.month
            || this.month.length !== 7 // Should actually check str is in format YYYY-MM
        ) {
            this.empty();
            return;
        }

        let chart = this.getChart();
        if (!chart) {
            return;
        }
        chart.showLoading();

        let roomsOtbTwelveMonthsEvolution = (this.data.roomsOtbTwelveMonthsEvolution || {}).value || {};
        if (!roomsOtbTwelveMonthsEvolution || typeof roomsOtbTwelveMonthsEvolution !== 'object') {
            roomsOtbTwelveMonthsEvolution = {};
        }

        let series = {
            data: [],
            pointStart: 1
        };

        Object.keys(roomsOtbTwelveMonthsEvolution).forEach(snapshotDate => {
            let roomsOtbTwelveMonths = roomsOtbTwelveMonthsEvolution[snapshotDate];
            if (
                !roomsOtbTwelveMonths
                || typeof roomsOtbTwelveMonths !== 'object'
                || !('value' in roomsOtbTwelveMonths)
                || !roomsOtbTwelveMonths.value
                || typeof roomsOtbTwelveMonths.value !== 'object'
                || !(this.month in roomsOtbTwelveMonths.value)
            ) {
                return;
            }
            let roomsOtbMonth = roomsOtbTwelveMonths.value[this.month];
            series.data.push([snapshotDate, roomsOtbMonth]);
        });

        chart.addSeries(series, false);
        chart.hideLoading();
        chart.redraw();
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    connectedCallback() {
        super.connectedCallback();

        if (this.hasDestroyedChart) {
            this.empty();
            this.fill();
            this.hasDestroyedChart = false;
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        let chart = this.getChart();
        if (chart) {
            // Prevent memory leaks by destroying the chart. By default, Highcharts will only purge memory on
            // window.unload.
            // https://api.highcharts.com/class-reference/Highcharts.Chart#destroy
            chart.destroy();
            this.chart = null;
            this.hasDestroyedChart = true;
        }
    }

    render() {
        return html`
<div class="${this.compClass}">
    <div id="${this.chartId}"></div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PropertyMonthEvolutionSparkChart = PropertyMonthEvolutionSparkChart;
customElements.define('vao-property-month-evolution-spark-chart', PropertyMonthEvolutionSparkChart);
