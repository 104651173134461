import { AppElement, html } from '../AppElement.js';

export default class PageFlashModal extends AppElement {

    static get properties() {
        return {
            populatePickupString: { type: String },
            monthlyProgressionString:{ type: String },
            pickupPerMonthString:{ type: String }

        };
    }
    constructor() {
        super();
        this.populatePickupString ='';
        this.monthlyProgressionString ='';
        this.pickupPerMonthString = '';
    }
    // Section 1

    populatePickup(){
        let populatePickuphtml  = html`<div class="vao__flash--container container">
            <div class="row">
                <div class="col-sm-12 biggy-small">
                    <section id="vao__flash--totalPickupSection">

                        <div id="vao__flash--totalPickUpTop">
                            <div id="vao__flash--totalPickUpHotel">loading...</div>
                            <div id="vao__flash--totalPickUpForeword">
                                <span id="vao__flash-totalPickupDate"></span>
                                <span class="vao__flash--totalPickUpForewordDot"></span>
                                <span id="vao__flash-totalPickupText">Total Pickup</span>
                                <span class="vao__flash--totalPickUpForewordDot"></span>
                                <span id="vao__flash--totalPickUpForewordVal"></span>
                            </div>
                            <div class="vao__flash--yesterday">
                                <!-- Button added by JS here -->
                            </div>
                        </div>

                        <hr id="vao__flash--totalPickUpForewordToBodyLine">

                        <div class="vao__flash--totalPickUpBody">

                            <div tabindex="0" class="vao__flash--totalPickupSection-revenueTargetPopover" data-toggle="popover" title="Why does this section change color?">
                                <i class="far fa-question-circle"></i>
                            </div>

                            <!-- total pickup body loading overlay -->
                            <div class='vao__flash--totalPickUpBody-loading'>
                                <div class='vao__flash--totalPickUpBody-loading-rms loading-shimmer'></div>
                                <div class='vao__flash--totalPickUpBody-loading-adr loading-shimmer'></div>
                                <div class='vao__flash--totalPickUpBody-loading-rev loading-shimmer'></div>
                            </div>

                            <!-- total pickup body -->
                            <div class="vao__flash--totalPickUpBodyLeft">
                                <div id="vao__flash--totalPickUpRms">
                                    <i id="vao__flash--totalPickUpRmsIcon" class="fas fa-bed"></i>
                                    <span id="vao__flash--totalPickUpRmsAmt"></span>
                                </div>
                                <div id="vao__flash--totalPickUpAdr">
                                    <i id="vao__flash--totalPickUpAdrIcon" class="fas fa-tachometer-alt"></i>
                                    <span id="vao__flash--totalPickUpAdrCur"><span id="vao__flash--totalPickUpAdrAmt"></span></span>
                                </div>
                            </div>
                            <div class="vao__flash--totalPickUpBodyRight">
                                <div id="vao__flash--totalPickUpRev">
                                    <span>Revenue</span>
                                    <div id="vao__flash--totalPickUpRevAmt"></div>
                                </div>
                            </div>

                        </div>

                    </section>
                </div>
            </div>
            </div> <hr>`;

        return populatePickuphtml;
    }

    // Section 2

    monthlyProgression(){
        let monthlyProgressionhtml  = html`
            <div class="row">
                <div class="col-12 biggy-small">
                    <div class="vao__flash--mtd no-bor-small">
                        <div class="row" style="text-align: center">
                            <p class="vao__flash--mtd-title" style="width:750px;">Monthly Progression</p>
                            
                            <div class="col-sm-12 col-md-12 col-lg-12 biggy-small">
                                <section id="vao__flash--month2DayProduction">

                                    <div class="vao__flash--month2DayProduction-loadingOverlay ">
                                        <div class="vao__flash--month2DayProduction-loadingOverlay-container loading-shimmer">
                                            <div class="vao__flash--month2DayProduction-loadingOverlay-row"></div>
                                            <div class="vao__flash--month2DayProduction-loadingOverlay-row"></div>
                                            <div class="vao__flash--month2DayProduction-loadingOverlay-row"></div>
                                        </div>
                                    </div>

                                    <!-- main table -->
                                    <div class="table-responsive">
                                        <table id="vao__flash--monthWhole" class="table table-inf-blue table-bordered">
                                            <thead class='thead-light'>
                                            <tr>
                                                <th id="vao__flash--month2DayProductionTable-descriptionTitle" scope="col"></th>
                                                <th id="vao__flash--month2DayProductionTable-headingRms" scope="col">Rms</th>
                                                <th id="vao__flash--month2DayProductionTable-headingAdr" scope="col">ADR</th>
                                                <th id="vao__flash--month2DayProductionTable-headingRev" scope="col">Revenue</th>
                                                <th id="vao__flash--month2DayProductionTable-headingRevPar" class="nogroup" scope="col">RevPAR</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>MTD</td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayRms">-</td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayAdr">-</td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayRev">-</td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayRevPar" class="nogroup"></td>
                                            </tr>
                                            <tr class="vao__flash--month2DayProductionTable-reducedRow nogroup">
                                                <td>&#9651 LY</td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayLYRms"></td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayLYAdr"></td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayLYRev"></td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayLYRevPar"></td>
                                            </tr>
                                            <tr class="vao__flash--month2DayProductionTable-reducedRow nogroup">
                                                <td>&#9651 B</td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayBRms"></td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayBAdr"></td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayBRev"></td>
                                                <td id="vao__flash--month2DayProductionTable-month2DayBRevPar"></td>
                                            </tr>
                                            <tr class="vao__flash--tableSplitRow">
                                                <td><button class="vao__flash--breakdownBtn btn btn-outline-primary">Month</button></td>
                                                <td id="vao__flash--month2DayProductionTable-monthRms">-</td>
                                                <td id="vao__flash--month2DayProductionTable-monthAdr">-</td>
                                                <td id="vao__flash--month2DayProductionTable-monthRev">-</td>
                                                <td id="vao__flash--month2DayProductionTable-monthRevPar" class="nogroup"></td>
                                            </tr>
                                            <tr class="vao__flash--month2DayProductionTable-reducedRow nogroup">
                                                <td>&#9651 LY</td>
                                                <td id="vao__flash--month2DayProductionTable-monthLYRms"></td>
                                                <td id="vao__flash--month2DayProductionTable-monthLYAdr"></td>
                                                <td id="vao__flash--month2DayProductionTable-monthLYRev"></td>
                                                <td id="vao__flash--month2DayProductionTable-monthLYRevPar"></td>
                                            </tr>
                                            <tr class="vao__flash--month2DayProductionTable-reducedRow nogroup">
                                                <td>&#9651 B</td>
                                                <td id="vao__flash--month2DayProductionTable-monthBRms"></td>
                                                <td id="vao__flash--month2DayProductionTable-monthBAdr"></td>
                                                <td id="vao__flash--month2DayProductionTable-monthBRev"></td>
                                                <td id="vao__flash--month2DayProductionTable-monthBRevPar"></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        `;

        return monthlyProgressionhtml;
    }

    // Section 3

    pickupPerMonth() {
        let pickupPerMonthhtml  = html`
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 biggy-small">
                    <section id="vao__flash--pickupPerMonth" class="no-bor-small">
                        <div class="vao__flash--pickupPerMonth-title" style="text-align: center">Pickup Per Month</div>
                        <div id="vao__flash--pickupPerMonth-accordionPickupPerMonthCard"
                             class="collapse show"
                             aria-labelledby="vao__flash--pickupPerMonth-accordionPickupPerMonthHeading"
                             data-parent="#vao__flash--pickupPerMonth-accordion">
                            <div class="card-body">
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        `;

        return pickupPerMonthhtml;
    }

    // Data Related Functions -

    getFlashDateStr() {
        return infinito.vao.controller.dateHelper.getCurDateStr();
    }

    retrieveHotelId() {
        return infinito.vao.controller.storageHelper.getSelectedHotelId();
    }

    getPickupOffset() {
        return infinito.vao.controller.storageHelper.getPickupOffset();
    }

    formatIntBracketStyle(val) {
        var intVal = parseInt(val, 10);
        if (val === 'no data') {
            return '-';
        }
        if (!intVal) {
            return val;
        }
        if (intVal < 0) {
            return '(' + Math.abs(intVal) + ')';
        }
        return intVal;
    }

    // Getting Data For Section 1 -
    dataPopulatePickup(){
        var curDate = this.getFlashDateStr();
        var pickupOffset = this.getPickupOffset();
        var $loadingShimmers = $('.vao__flash--totalPickUpBody-loading').show();



        var name;
        var fields;
        var fetchStatistics;
        var requestId;
        var buildQuery;
        var query;
        var settingKey;

        // For Single Property Mode Only -

        name = infinito.vao.controller.storageHelper.getSelectedHotelName();
        fields = infinito.vao.model.biStatistics.fields;
        fetchStatistics = infinito.vao.model.biStatistics.fetchStatistics;
        requestId = infinito.vao.controller.storageHelper.getSelectedHotelId();
        buildQuery = infinito.vao.model.biStatistics.buildQuery;
        settingKey = infinito.vao.model.vaoSettings.obtainHotelIdTotalRevPickupAverageKey(
            infinito.vao.controller.storageHelper.getSelectedHotelId()
        );

        $('#vao__flash--totalPickUpHotel').text(name);

        $('#vao__flash-totalPickupDate').text(
            infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(curDate)
        );

        query = buildQuery({
            recordDate: curDate,
            firstStayDate: curDate,
            lastStayDate: curDate,
            fields: [
                fields.roomsOtbTotalPickup,
                fields.adrTotalPickup,
                fields.revenueTotalPickup
            ],
            pickupOffset: pickupOffset
        });

        function fillAllForNoData() {
            $('#vao__flash--totalPickUpRmsAmt').text('-');
            $('#vao__flash--totalPickUpAdrAmt').text('-');
            $('#vao__flash--totalPickUpRevAmt').text('-');
            $('#vao__flash--totalPickUpForewordVal').text('0h');
        }

        function statisticDataCallback(data) {
            $loadingShimmers.hide();
            $loadingShimmers = null; // release
            if (!data || !data.hasOwnProperty('data')) {
                fillAllForNoData();
                return;
            }

            var sectionData = data.data;

            var $rmsTotalPickup = $('#vao__flash--totalPickUpRmsAmt');
            var $adrTotalPickup = $('#vao__flash--totalPickUpAdrAmt');
            var $revTotalPickup = $('#vao__flash--totalPickUpRevAmt');
            var $pickupDiff = $('#vao__flash--totalPickUpForewordVal');

            var fields = infinito.vao.model.biStatistics.fields;
            var roomsOtbTotalPickupStat = sectionData.find(function (obj) {
                return obj.field === fields.roomsOtbTotalPickup;
            });

            var adrTotalPickupStat = sectionData.find(function (obj) {
                return obj.field === fields.adrTotalPickup;
            });
            var revenueTotalPickupStat = sectionData.find(function (obj) {
                return obj.field === fields.revenueTotalPickup;
            });

            var totalPickupTimeDifference = '0h';
            if (roomsOtbTotalPickupStat != null && roomsOtbTotalPickupStat.receivedTs != null
                && roomsOtbTotalPickupStat.offsetReceivedTs != null) {
                var hoursBetween = infinito.vao.controller.dateHelper.calcHoursBetweenTZStrings(
                    roomsOtbTotalPickupStat.receivedTs,
                    roomsOtbTotalPickupStat.offsetReceivedTs
                );
                if (hoursBetween) {
                    totalPickupTimeDifference = parseInt(hoursBetween) + 'h';
                }
            }

            if (!roomsOtbTotalPickupStat || roomsOtbTotalPickupStat.value === 'no data') {
                roomsOtbTotalPickupStat = { value: '-' };
            }

            if (!adrTotalPickupStat || adrTotalPickupStat.value === 'no data') {
                adrTotalPickupStat = { value: '-' };
            }

            if (!revenueTotalPickupStat || revenueTotalPickupStat.value === 'no data') {
                revenueTotalPickupStat = { value: '-' };
            }

            $rmsTotalPickup.text(roomsOtbTotalPickupStat.value); // this.formatIntBracketStyle(roomsOtbTotalPickupStat.value)
            $adrTotalPickup.text(infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                adrTotalPickupStat.value, 2, true, '', 1
            ));
            $revTotalPickup.text(infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                revenueTotalPickupStat.value, 0, true
            ));
            $pickupDiff.text(totalPickupTimeDifference);

            $rmsTotalPickup = null;
            $adrTotalPickup = null;
            $revTotalPickup = null;
            $pickupDiff = null;

            // Make the background red if the total revenue pickup is less than 0.
            // No point continuing checking user settings.
            if (
                infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                    infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueTotalPickupStat.value,
                        0,
                        true
                    )
                ) < 0
            ) {
                $('.vao__flash--totalPickupSection-revenueTargetPopover').popover({
                    content: 'Your total Revenue pickup is less than 0.',
                    trigger: 'focus',
                    container: '#vao__flash--totalPickupSection'
                });

                $('#vao__flash--totalPickupSection').css({
                    'background-color': '#f44336',
                    color: 'white'
                });

                return;
            }

            // Make the background color red or green depending on the user's defined average
            // amount of revenue total pickup if above/below 10% of a threshold.
            infinito.vao.model.vaoSettings.fetchSettingByKey(
                infinito.vao.controller.storageHelper.getIdentitiesId(),
                settingKey,
                function done(setting) {
                    var settingVal;
                    var hours;
                    var threshold;
                    var goodThreshold;
                    var badThreshold;
                    var $section;
                    var totalRevPickup;
                    var popoverText = '';

                    if (setting == null) {
                        popoverText = 'You can now configure a total revenue pickup target over 24'
                            + ' hours for this hotel in the framework pages.';

                        $('.vao__flash--totalPickupSection-revenueTargetPopover').popover({
                            content: popoverText,
                            trigger: 'focus',
                            container: '#vao__flash--totalPickupSection'
                        });

                        return;
                    }

                    settingVal = parseInt(setting.value, 10);

                    if (settingVal == null || settingVal < 0) {
                        $('.vao__flash--totalPickupSection-revenueTargetPopover').popover({
                            content: 'Cannot show color variance where setting is 0.',
                            trigger: 'focus',
                            container: '#vao__flash--totalPickupSection'
                        });
                        return;
                    }

                    hours = parseInt(totalPickupTimeDifference, 10);

                    if (hours == null || hours === 0 || hours < 0) {
                        $('.vao__flash--totalPickupSection-revenueTargetPopover').popover({
                            content: 'Cannot show color variance where pickup is over 0 or less hours.',
                            trigger: 'focus',
                            container: '#vao__flash--totalPickupSection'
                        });
                        return;
                    }

                    totalRevPickup = infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                        infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            revenueTotalPickupStat.value,
                            0,
                            true
                        )
                    );

                    if (totalRevPickup == null) {
                        return;
                    }

                    threshold = (hours / 24) * settingVal;
                    goodThreshold = (1.1) * threshold;
                    badThreshold = (0.9) * threshold;

                    popoverText = 'For this property, you have configured a target total revenue'
                        + ' pickup of '
                        + infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            threshold,
                            0,
                            true
                        )
                        + ' over '
                        + hours
                        + ' hours. We will change the color if your'
                        + ' total revenue pickup falls outside of 10% of this number.';

                    $('.vao__flash--totalPickupSection-revenueTargetPopover').popover({
                        content: popoverText,
                        trigger: 'focus',
                        container: '#vao__flash--totalPickupSection'
                    });

                    $section = $('#vao__flash--totalPickupSection');

                    if (totalRevPickup > goodThreshold) {
                        $section.css({
                            'background-color': '#4CAF50',
                            color: 'white'
                        });
                        $section = null;
                        return;
                    }

                    if (totalRevPickup < badThreshold) {
                        $section.css({
                            'background-color': '#F44336',
                            color: 'white'
                        });
                    }

                    $section = null;
                }
            );
        }

        fetchStatistics(
            requestId,
            query,
            statisticDataCallback
        );



    }

    // Getting Data For Section 2 -
    dataMonthlyProgression(){

        let colorCodeClasses = {
            red: 'col-red-2',
            green: 'col-green-2'
        };
        // Dom Selector =

        let pageSections = {
            month2DayProduction: {
                domSelectors: {
                    loadingOverlay: '.vao__flash--month2DayProduction-loadingOverlay',
                    leftColumn: '#vao__flash--month2DayProductionFixedLeftColumn',
                    leftColumnTitle: '#vao__flash--month2DayProductionFixedLeftColumn-descriptionTitle',
                    table: '#vao__flash--monthWhole',
                    tableTitle: '#vao__flash--month2DayProductionTable-descriptionTitle',
                    tableMonth2DayRms: '#vao__flash--month2DayProductionTable-month2DayRms',
                    tableMonth2DayAdr: '#vao__flash--month2DayProductionTable-month2DayAdr',
                    tableMonth2DayRev: '#vao__flash--month2DayProductionTable-month2DayRev',
                    tableMonth2DayRevPar: '#vao__flash--month2DayProductionTable-month2DayRevPar',
                    tableMonth2DayLYRms: '#vao__flash--month2DayProductionTable-month2DayLYRms',
                    tableMonth2DayLYAdr: '#vao__flash--month2DayProductionTable-month2DayLYAdr',
                    tableMonth2DayLYRev: '#vao__flash--month2DayProductionTable-month2DayLYRev',
                    tableMonth2DayLYRevPar: '#vao__flash--month2DayProductionTable-month2DayLYRevPar',
                    tableMonth2DayBRms: '#vao__flash--month2DayProductionTable-month2DayBRms',
                    tableMonth2DayBAdr: '#vao__flash--month2DayProductionTable-month2DayBAdr',
                    tableMonth2DayBRev: '#vao__flash--month2DayProductionTable-month2DayBRev',
                    tableMonth2DayBRevPar: '#vao__flash--month2DayProductionTable-month2DayBRevPar',
                    tableMonthRms: '#vao__flash--month2DayProductionTable-monthRms',
                    tableMonthAdr: '#vao__flash--month2DayProductionTable-monthAdr',
                    tableMonthRev: '#vao__flash--month2DayProductionTable-monthRev',
                    tableMonthRevPar: '#vao__flash--month2DayProductionTable-monthRevPar',
                    tableMonthLYRms: '#vao__flash--month2DayProductionTable-monthLYRms',
                    tableMonthLYAdr: '#vao__flash--month2DayProductionTable-monthLYAdr',
                    tableMonthLYRev: '#vao__flash--month2DayProductionTable-monthLYRev',
                    tableMonthLYRevPar: '#vao__flash--month2DayProductionTable-monthLYRevPar',
                    tableMonthBRms: '#vao__flash--month2DayProductionTable-monthBRms',
                    tableMonthBAdr: '#vao__flash--month2DayProductionTable-monthBAdr',
                    tableMonthBRev: '#vao__flash--month2DayProductionTable-monthBRev',
                    tableMonthBRevPar: '#vao__flash--month2DayProductionTable-monthBRevPar',
                    callout: '#vao__flash--month2DayProductionTable-requiredPickupMonth .bd-callout',
                    breakdownBtn: '.vao__flash--breakdownBtn',
                    breakdownModal: '#vao__flash--month2DayProduction-breakdownModal'
                },

                matchLeftColumnWidth: function () {
                    $(pageSections.month2DayProduction.domSelectors.leftColumn + ' table').css(
                        'width',
                        $(pageSections.month2DayProduction.domSelectors.table + ' thead th').css('width')
                    );
                }
            },

        };



        let genericDateFormat = null;
        var curDate = this.getFlashDateStr();
        var monthText = infinito.vao.controller.dateHelper.getShortMonStr(curDate, genericDateFormat);

        $('#vao__flash--month2DayProductionTable-descriptionTitle').text(monthText);
       // $(pageSections.domSelectors.tableTitle).text(monthText);

        $(pageSections.month2DayProduction.domSelectors.breakdownBtn).on('click', function onClickBreakdownBtn(e) {
            var $modal = $(pageSections.month2DayProduction.domSelectors.breakdownModal);

            e.stopPropagation();

            $modal.find('.modal-body').empty().append(infinito.components.monthBreakdownDiv.render({}));

            $modal.modal('show');
        });

        var dataRequestFields = (function buildDataRequestFields() {
            var fields = infinito.vao.model.biStatistics.fields;
            var wantedFields = [
                fields.roomsOtbMonthToDay,
                fields.roomsOtbMonthToDayLastYear,
                fields.roomsOtbMonthToDayBudget,
                fields.roomsOtbMonth,
                fields.roomsOtbMonthLastYear,
                fields.roomsOtbMonthBudget,
                fields.adrMonthToDay,
                fields.adrMonthToDayLastYear,
                fields.adrMonthToDayBudget,
                fields.adrMonth,
                fields.adrMonthLastYear,
                fields.adrMonthBudget,
                fields.revenueMonthToDay,
                fields.revenueMonthToDayLastYear,
                fields.revenueMonthToDayBudget,
                fields.revenueMonth,
                fields.revenueMonthLastYear,
                fields.revenueMonthBudget,
                fields.revParMonthToDay,
                fields.revParMonthToDayLastYear,
                fields.revParMonthToDayBudget,
                fields.revParMonth,
                fields.revParMonthLastYear,
                fields.revParMonthBudget
            ];
            var query = '';
            for (var i = 0, len = wantedFields.length; i < len; i++) {
                query += wantedFields[i] + ',';
            }
            return query.slice(0, -1);
        }());

        var dataRequestQuery = (function () {
            var queryParams = infinito.vao.model.biStatistics.queryParams;
            return queryParams.recordDate + '=' + curDate + '&'
                + queryParams.firstStayDate + '=' + curDate + '&'
                + queryParams.lastStayDate + '=' + curDate + '&'
                + queryParams.fields + '=' + dataRequestFields;
        }());

        var statisticDataCallback = function (data) {
            var statisticData = [];

            $('.vao__flash--month2DayProduction-loadingOverlay').hide();

            if (data && data.data != null) {
                statisticData = data.data;
            }

            var thisDomSelectors = pageSections.month2DayProduction.domSelectors;
            var fields = infinito.vao.model.biStatistics.fields;

            var $roomsOtbMtd = $(thisDomSelectors.tableMonth2DayRms);
            var $roomsOtbMtdLy = $(thisDomSelectors.tableMonth2DayLYRms);
            var $roomsOtbMtdB = $(thisDomSelectors.tableMonth2DayBRms);
            var $roomsOtbM = $(thisDomSelectors.tableMonthRms);
            var $roomsOtbMLy = $(thisDomSelectors.tableMonthLYRms);
            var $roomsOtbMB = $(thisDomSelectors.tableMonthBRms);
            var $adrMtd = $(thisDomSelectors.tableMonth2DayAdr);
            var $adrMtdLy = $(thisDomSelectors.tableMonth2DayLYAdr);
            var $adrMtdB = $(thisDomSelectors.tableMonth2DayBAdr);
            var $adrM = $(thisDomSelectors.tableMonthAdr);
            var $adrMLy = $(thisDomSelectors.tableMonthLYAdr);
            var $adrMB = $(thisDomSelectors.tableMonthBAdr);
            var $revMtd = $(thisDomSelectors.tableMonth2DayRev);
            var $revMtdLy = $(thisDomSelectors.tableMonth2DayLYRev);
            var $revMtdB = $(thisDomSelectors.tableMonth2DayBRev);
            var $revM = $(thisDomSelectors.tableMonthRev);
            var $revMLy = $(thisDomSelectors.tableMonthLYRev);
            var $revMB = $(thisDomSelectors.tableMonthBRev);
            var $revParMtd = $(thisDomSelectors.tableMonth2DayRevPar);
            var $revParMtdLy = $(thisDomSelectors.tableMonth2DayLYRevPar);
            var $revParMtdB = $(thisDomSelectors.tableMonth2DayBRevPar);
            var $revParM = $(thisDomSelectors.tableMonthRevPar);
            var $revParMLy = $(thisDomSelectors.tableMonthLYRevPar);
            var $revParMB = $(thisDomSelectors.tableMonthBRevPar);

            var statistics = {};
            statistics.roomsOtbMtd = statisticData.find(function (obj) { return obj.field === fields.roomsOtbMonthToDay; });
            statistics.roomsOtbMtdLy = statisticData.find(function (obj) { return obj.field === fields.roomsOtbMonthToDayLastYear; });
            statistics.roomsOtbMtdB = statisticData.find(function (obj) { return obj.field === fields.roomsOtbMonthToDayBudget; });
            statistics.roomsOtbM = statisticData.find(function (obj) { return obj.field === fields.roomsOtbMonth; });
            statistics.roomsOtbMLy = statisticData.find(function (obj) { return obj.field === fields.roomsOtbMonthLastYear; });
            statistics.roomsOtbMB = statisticData.find(function (obj) { return obj.field === fields.roomsOtbMonthBudget; });
            statistics.adrMtd = statisticData.find(function (obj) { return obj.field === fields.adrMonthToDay; });
            statistics.adrMtdLy = statisticData.find(function (obj) { return obj.field === fields.adrMonthToDayLastYear; });
            statistics.adrMtdB = statisticData.find(function (obj) { return obj.field === fields.adrMonthToDayBudget; });
            statistics.adrM = statisticData.find(function (obj) { return obj.field === fields.adrMonth; });
            statistics.adrMLy = statisticData.find(function (obj) { return obj.field === fields.adrMonthLastYear; });
            statistics.adrMB = statisticData.find(function (obj) { return obj.field === fields.adrMonthBudget; });
            statistics.revMtd = statisticData.find(function (obj) { return obj.field === fields.revenueMonthToDay; });
            statistics.revMtdLy = statisticData.find(function (obj) { return obj.field === fields.revenueMonthToDayLastYear; });
            statistics.revMtdB = statisticData.find(function (obj) { return obj.field === fields.revenueMonthToDayBudget; });
            statistics.revM = statisticData.find(function (obj) { return obj.field === fields.revenueMonth; });
            statistics.revMLy = statisticData.find(function (obj) { return obj.field === fields.revenueMonthLastYear; });
            statistics.revMB = statisticData.find(function (obj) { return obj.field === fields.revenueMonthBudget; });
            statistics.revParMtd = statisticData.find(function (obj) { return obj.field === fields.revParMonthToDay; });
            statistics.revParMtdLy = statisticData.find(function (obj) { return obj.field === fields.revParMonthToDayLastYear; });
            statistics.revParMtdB = statisticData.find(function (obj) { return obj.field === fields.revParMonthToDayBudget; });
            statistics.revParM = statisticData.find(function (obj) { return obj.field === fields.revParMonth; });
            statistics.revParMLy = statisticData.find(function (obj) { return obj.field === fields.revParMonthLastYear; });
            statistics.revParMB = statisticData.find(function (obj) { return obj.field === fields.revParMonthBudget; });

            var defaultVal = '-';
            var keys = Object.keys(statistics);
            for (var i = 0, len = keys.length; i < len; i++) {
                if (!statistics[keys[i]]) {
                    statistics[keys[i]] = { value: defaultVal };
                }
            }

            var intDiffCellFormatter = function (val) {
                if (!$.isNumeric(val)) {
                    return '-';
                }
                // return formatIntBracketStyle(val);
                var intVal = parseInt(val, 10);
                if (val === 'no data') {
                    return '-';
                }
                if (!intVal) {
                    return val;
                }
                if (intVal < 0) {
                    return '(' + Math.abs(intVal) + ')';
                }
                return intVal;
            };

            var moneyDiffCellFormatterADR = function (val) {
                if (!$.isNumeric(val)) {
                    return '-';
                }
                return infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(val, 2, true,'',1);
            };
            var moneyDiffCellFormatterRevPar = function (val) {
                if (!$.isNumeric(val)) {
                    return '-';
                }
                return infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(val, 2, true,'',2);
            };

            var moneyDiffCellFormatter = function (val) {
                if (!$.isNumeric(val)) {
                    return '-';
                }
                return infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(val, 2, true);
            };

            var insertDiffCell = function ($selector, origValue, toDiffValue, formatFunc) {
                $selector.removeClass(colorCodeClasses.red).removeClass(colorCodeClasses.green);

                if (!$.isNumeric(origValue) || !$.isNumeric(toDiffValue)) {
                    $selector.text('-');
                    return;
                }

                var origValueFloat = parseFloat(origValue);
                var toDiffValueFloat = parseFloat(toDiffValue);
                var diff = origValueFloat - toDiffValueFloat;

                if (toDiffValueFloat === 0) {
                    $selector.text('-');
                    return;
                }

                $selector.text(formatFunc(diff));

                if (diff < 0) {
                    $selector.addClass(colorCodeClasses.red);
                } else if (diff > 0) {
                    $selector.addClass(colorCodeClasses.green);
                }
            };

            /**
             * Rooms OTB Column
             */
            $roomsOtbMtd.text(statistics.roomsOtbMtd.value);
            insertDiffCell($roomsOtbMtdLy, statistics.roomsOtbMtd.value, statistics.roomsOtbMtdLy.value, intDiffCellFormatter);
            insertDiffCell($roomsOtbMtdB, statistics.roomsOtbMtd.value, statistics.roomsOtbMtdB.value, intDiffCellFormatter);
            $roomsOtbM.text(statistics.roomsOtbM.value);
            insertDiffCell($roomsOtbMLy, statistics.roomsOtbM.value, statistics.roomsOtbMLy.value, intDiffCellFormatter);
            insertDiffCell($roomsOtbMB, statistics.roomsOtbM.value, statistics.roomsOtbMB.value, intDiffCellFormatter);

            /**
             * ADR Column
             */
            $adrMtd.text(moneyDiffCellFormatterADR(statistics.adrMtd.value));
            insertDiffCell($adrMtdLy, statistics.adrMtd.value, statistics.adrMtdLy.value, moneyDiffCellFormatterADR);
            insertDiffCell($adrMtdB, statistics.adrMtd.value, statistics.adrMtdB.value, moneyDiffCellFormatterADR);
            $adrM.text(moneyDiffCellFormatterADR(statistics.adrM.value));
            insertDiffCell($adrMLy, statistics.adrM.value, statistics.adrMLy.value, moneyDiffCellFormatterADR);
            insertDiffCell($adrMB, statistics.adrM.value, statistics.adrMB.value, moneyDiffCellFormatterADR);

            /**
             * Revenue Column
             */
            $revMtd.text(moneyDiffCellFormatter(statistics.revMtd.value));
            insertDiffCell($revMtdLy, statistics.revMtd.value, statistics.revMtdLy.value, moneyDiffCellFormatter);
            insertDiffCell($revMtdB, statistics.revMtd.value, statistics.revMtdB.value, moneyDiffCellFormatter);
            $revM.text(moneyDiffCellFormatter(statistics.revM.value));
            insertDiffCell($revMLy, statistics.revM.value, statistics.revMLy.value, moneyDiffCellFormatter);
            insertDiffCell($revMB, statistics.revM.value, statistics.revMB.value, moneyDiffCellFormatter);

            /**
             * RevPar Column
             */
            $revParMtd.text(moneyDiffCellFormatterRevPar(statistics.revParMtd.value));
            insertDiffCell($revParMtdLy, statistics.revParMtd.value, statistics.revParMtdLy.value, moneyDiffCellFormatterRevPar);
            insertDiffCell($revParMtdB, statistics.revParMtd.value, statistics.revParMtdB.value, moneyDiffCellFormatterRevPar);
            $revParM.text(moneyDiffCellFormatterRevPar(statistics.revParM.value));
            insertDiffCell($revParMLy, statistics.revParM.value, statistics.revParMLy.value, moneyDiffCellFormatterRevPar);
            insertDiffCell($revParMB, statistics.revParM.value, statistics.revParMB.value, moneyDiffCellFormatterRevPar);


            /*
             * Fill the bd-callout
             */
            (function iife() {
                var revMNum = infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                    infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        statistics.revM.value,
                        0,
                        true
                    )
                ) || undefined;
                var revMBNum = infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                    infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        statistics.revMB.value,
                        0,
                        true
                    )
                ) || undefined;
                var revMLyNum = infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                    infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        statistics.revMLy.value,
                        0,
                        true
                    )
                ) || undefined;
                var otbMNum = (statistics.roomsOtbM || {}).value || null;
                var otbMBNum = (statistics.roomsOtbMB || {}).value || null;
                var otbMLyNum = (statistics.roomsOtbMLy || {}).value || null;
                var revMBDiff = revMNum - revMBNum;
                var revMLyDiff = revMNum - revMLyNum;
                var otbMBDiff = otbMNum - otbMBNum;
                var otbMlyDiff = otbMNum - otbMLyNum;
                var currentMonth = infinito.vao.controller.dateHelper.getFullMonStr(
                    infinito.vao.controller.storageHelper.getCurrentDate()
                );
                var daysLeftInMonth = infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    infinito.vao.controller.dateHelper.getLastDayInMonthFromDateString(
                        infinito.vao.controller.storageHelper.getCurrentDate()
                    ),
                    infinito.vao.controller.storageHelper.getCurrentDate()
                );
                var $requiredBadgeTmpl = $('#vao__flash--mtd-requiredBadgeTemplate');

                if (
                    revMNum == null
                    || typeof daysLeftInMonth !== 'number'
                    || (
                        (typeof revMBDiff !== 'number' || Number.isNaN(revMBDiff))
                        && (typeof revMLyDiff !== 'number' || Number.isNaN(revMLyDiff))
                    )
                ) {
                    $('#vao__flash--mtd-requiredWrapper').hide();
                    return;
                }

                if (Number.isNaN(revMBDiff)) {
                    $('#vao__flash--mtd-requiredBudget').remove();
                }

                if (Number.isNaN(revMLyDiff)) {
                    $('#vao__flash--mtd-requiredLY').remove();
                }

                if (revMBDiff > 0) {
                    $('#vao__flash--mtd-requiredBudget').text(
                        'Congratulations! You have exceeded your budget target for ' + currentMonth + '.'
                    );
                }

                if (revMLyDiff > 0) {
                    $('#vao__flash--mtd-requiredLY').text(
                        'Congratulations! You have exceeded last year\'s revenue for ' + currentMonth + '.'
                    );
                }

                if (revMBDiff <= 0 && daysLeftInMonth >= 0) {
                    (function differ() {
                        var revMBDiffLeft = revMBDiff / (daysLeftInMonth || 1);
                        var revMBDiffLeftFormatted = infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            Math.abs(revMBDiffLeft),
                            0,
                            true
                        );
                        var otbMBDiffLeft = otbMBDiff / (daysLeftInMonth || 1);
                        var txt = (daysLeftInMonth === 0)
                            ? 'It is the end of the month and you still need bookings to'
                            + ' meet your budget target for ' + currentMonth + '.'
                            : 'You have not yet met your budget target for ' + currentMonth + '.';
                        $('#vao__flash--mtd-requiredBudget').html(
                            txt
                        ).append(
                            (function getBadge() {
                                var $badge = $($requiredBadgeTmpl.html());
                                var adr = '-';
                                if (otbMBDiffLeft < 0 || otbMBDiffLeft > 0) {
                                    adr = revMBDiffLeft / otbMBDiffLeft;
                                    if (adr) {
                                        adr = infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                            adr,
                                            2,
                                            true
                                        );
                                    }
                                }
                                $badge.find('.vao__flash--mtd-requiredBadge-otb').text(Math.round(
                                    (otbMBDiffLeft * -1)
                                ));
                                $badge.find('.vao__flash--mtd-requiredBadge-adr').text(adr);
                                $badge.find('.vao__flash--mtd-requiredBadge-rev').text(revMBDiffLeftFormatted);
                                return $badge;
                            }())
                        );
                    }());
                }

                if (revMLyDiff <= 0 && daysLeftInMonth >= 0) {
                    (function differ() {
                        var revMLDiffLeft = revMLyDiff / (daysLeftInMonth ||1);
                        var revMLDiffLeftFormatted = infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            Math.abs(revMLDiffLeft),
                            0,
                            true
                        );
                        var otbMLDiffLeft = otbMlyDiff / (daysLeftInMonth || 1);
                        var txt = (daysLeftInMonth === 0)
                            ? 'It is the end of the month and you still need need bookings to'
                            + ' match last year\'s revenue for ' + currentMonth + '.'
                            : 'You have not yet matched last years revenue for ' + currentMonth + '.';
                        $('#vao__flash--mtd-requiredLY').html(
                            txt
                        ).append(
                            (function getBadge() {
                                var $badge = $($requiredBadgeTmpl.html());
                                var adr = '-';
                                if (otbMLDiffLeft < 0 || otbMLDiffLeft > 0) {
                                    adr = revMLDiffLeft / otbMLDiffLeft;
                                    if (adr) {
                                        adr = infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                            adr,
                                            2,
                                            true
                                        );
                                    }
                                }
                                $badge.find('.vao__flash--mtd-requiredBadge-otb').text(Math.round(
                                    (otbMLDiffLeft * -1)
                                ));
                                $badge.find('.vao__flash--mtd-requiredBadge-adr').text(adr);
                                $badge.find('.vao__flash--mtd-requiredBadge-rev').text(revMLDiffLeftFormatted);
                                return $badge;
                            }())
                        );
                    }());
                }
            }());
        };

        infinito.vao.model.biStatistics.fetchStatistics(
            this.retrieveHotelId(),
            dataRequestQuery,
            statisticDataCallback
        );

    }

    // Getting Data For Section 3 -

    dataPickupPerMonth(){

        let pageSections = {

            pickupPerMonth: {
                domSelectors: {
                    section: '#vao__flash--pickupPerMonth',
                    accordionCardPPM: '#vao__flash--pickupPerMonth-accordionPickupPerMonthCard',
                    accordionCardPPMBody: '#vao__flash--pickupPerMonth-accordionPickupPerMonthCard'
                        + ' .card-body'
                }
            }

        };

        $(pageSections.pickupPerMonth.domSelectors.accordionCardPPMBody).html(
            infinito.components.pickupPerMonthTable.render()
        );
    }

    render() {

        this.populatePickupString =  this.populatePickup();

        this.monthlyProgressionString = this.monthlyProgression();
        this.pickupPerMonthString = this.pickupPerMonth();

        return html`
        <div>${this.populatePickupString}</div>
        <div>${this.monthlyProgressionString}</div>
        <div>${this.pickupPerMonthString}</div>
        `;
    }

    firstUpdated(changedProperties) {
        this.dataPopulatePickup();
        this.dataMonthlyProgression();
        this.dataPickupPerMonth();
    }



}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktop = PageFlashModal;
customElements.define('vao-page-flash-modal', PageFlashModal);