import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class PageDesktopBusinessModal extends AppElement {
    static get properties() {
        return {
            populateTabsString: { type: String }

        };
    }
    constructor(props = {}) {
        super();
        this.populateTabsString ='';
        this.stayDate = this.getAttribute("stayDate")
    }

    onClickSecondTab(){
        if(document.getElementById('vao__pace--rateDOW').innerHTML.trim().length == 0){
            infinito.components.rateEvolutionDiv.render({
                container: '#vao__pace--rateDOW',
                evolutionDate: this.stayDate,
                evolutionRange: 30,
                data: {}
            });
        }
    }

    onClickThirdTab(){
        if(document.getElementById('vao__pace--leadGraph').innerHTML.trim().length == 0){
            let $content = $("#vao__pace--contentleadGraph");
            let $otbOpenCloseComp = infinito.components.otbOpenClosePaceChart.render({
                stayDate: this.stayDate
            });

            let app = document.querySelector('#vao__pace--leadGraph');
            app.innerHTML='';

            $content.find('#vao__pace--leadGraph').append($otbOpenCloseComp);
        }

    }
    onClickFourthTab(){
        if(document.getElementById('vao__pace--comps').innerHTML.trim().length == 0){
            let $content = $("#vao__pace--content_rates");
            let $comSetComp = infinito.components.compSetRatesDetailsBlock.render({
                stayDate: this.stayDate
            });

            let app = document.querySelector('#vao__pace--comps');
            app.innerHTML='';

            $content.find('#vao__pace--comps').append($comSetComp);
        }

    }
    onClickFifthTab(){
        if(document.getElementById('vao__pace--leadTimeEvos-rate').innerHTML.trim().length == 0){
            let recordDate = window.infinito.vao.controller.storageHelper.getCurrentDate();
            let leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                this.stayDate,
                recordDate
            );
            let dowEvoRange;

            if (leadTime < 14) {
                dowEvoRange = 14;
            } else if (leadTime < 30) {
                dowEvoRange = 30;
            } else if (leadTime < 60) {
                dowEvoRange = 60;
            } else if (leadTime <= 120) {
                dowEvoRange = 120;
            } else {
                dowEvoRange = 14;
            }

            let $content = $("#vao__pace--content");


            let ab = new window.vao.components.RateEvolutionLeadTimeCompareChart({
                property: window.infinito.vao.controller.storageHelper.getSelectedHotel(),
                snapshotDate: recordDate,
                stayDate: this.stayDate,
                evolutionRange: dowEvoRange,
                historicRange: 4
            });

            let app = document.querySelector('#vao__pace--leadTimeEvos-rate');
            app.innerHTML='';
            app.append(ab);
        }

    }
    getCategory() {
        throw new Error(Constants.STRINGS.ABSTRACT_FUNC_ERR_MSG);
    }
    onClickSixTab(){
        let expandedCategory = Constants.CTX_CATEGORIES.ROOMTYPE;
        let pickupSetting = window.infinito.vao.controller.storageHelper.getPickupOffset();
        let prettyPickupSetting = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(pickupSetting);
        let body = new vao.components.MetricBreakdown({
            recordDate: window.infinito.vao.controller.storageHelper.getCurrentDate(),
            hotel: window.infinito.vao.controller.storageHelper.getSelectedHotel(),
            stayDate: this.stayDate,
            pickupSetting: pickupSetting,
            expandedCategory: expandedCategory
        });
        let prettyRecordDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(this.recordDate);
        let $title =  $('<div></div>').append(
            `<h5>${Constants.STRINGS.METRIC_BREAKDOWN}</h5>`
        ).append(new vao.components.MiniContext({
            recordDate: prettyRecordDate,
            pickupSetting: prettyPickupSetting,
            isModalStyle: false
        }));

        let $content = $("#vao__pace--content-roomtype");
        let app = document.querySelector('#vao__pace--roomtype');
        app.innerHTML='';

        $content.find('#vao__pace--roomtype').append($title).append(body);
    }

    //HTML
    htmlPopulateTabsString(){
        return html`<div class="col-sm-12 col-lg-12 biggy-small">
            <section id="vao__flash--topTen" class="no-bor-small">

                <div class="vao__flash--topTen-context">
                    <ul class="nav nav-pills" id="vao__flash--topTenTabs" role="tablist" style="flex-basis:100%;padding: revert;">
                        <li class="nav-item vao__flash--topTen-rmsButton" style="width: 115px;">
                            <a class="nav-link active" id="otb-tab" data-toggle="pill" href="#otb-content" role="tab"
                               aria-controls="1a" aria-selected="true" style="padding: 8px;">Occ Evolution</a>
                        </li>
                        <li @click="${this.onClickSecondTab}" class="nav-item vao__flash--topTen-adrButton" style="width: 115px;">
                            <a class="nav-link" id="adr-tab" data-toggle="pill" href="#adr-content" role="tab"
                               aria-controls="adr-content" aria-selected="false" style="padding: 8px;">Rate DOW</a>
                        </li>
                        <li @click="${this.onClickThirdTab}" class="nav-item vao__flash--topTen-adrButton" style="width: 115px;">
                            <a class="nav-link" id="pacerate-tab" data-toggle="pill" href="#pacerate-content" role="tab"
                               aria-controls="pacerate-content" aria-selected="false" style="padding: 8px;">Leadtime</a>
                        </li>
                        <li @click="${this.onClickFourthTab}" class="nav-item vao__flash--topTen-adrButton" style="width: 115px;">
                            <a class="nav-link" id="rates-tab" data-toggle="pill" href="#rates-content" role="tab"
                               aria-controls="rates-content" aria-selected="false" style="padding: 8px;">Pace Rate</a>
                        </li>
                        <li @click="${this.onClickFifthTab}" class="nav-item vao__flash--topTen-revButton" style="width: 115px;">
                            <a class="nav-link" id="compintel-tab" data-toggle="pill" href="#compintel-content"
                               role="tab" aria-controls="compintel-content" aria-selected="false" style="padding: 8px;">Comp
                                Intel
                            </a>
                        </li>
                        <li @click="${this.onClickSixTab}" class="nav-item vao__flash--topTen-revButton" style="width: 115px;">
                            <a class="nav-link" id="roomtype-tab" data-toggle="pill" href="#roomtype-content" role="tab"
                               aria-controls="roomtype-content" aria-selected="false" style="padding: 8px;">Room Type
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content" id="vao__flash--topTen-tabContent">
                    <div class="tab-pane fade show active" id="otb-content" role="tabpanel" aria-labelledby="otb-tab">
                        <div id="vao__pace--content">
                            <div id="vao__pace--leadTimeEvos-occ"></div>
                        </div>    
                    </div>
                    <div class="tab-pane fade" id="adr-content" role="tabpanel"  aria-labelledby="adr-tab">
                        <div id="vao__pace--content_rateDOW">
                            <div id="vao__pace--rateDOW"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pacerate-content" role="tabpanel"  aria-labelledby="pacerate-tab">
                        <div id="vao__pace--contentleadGraph">
                            <div id="vao__pace--leadGraph"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="rates-content" role="tabpanel"  aria-labelledby="rates-tab">
                        <div id="vao__pace--content_rates">
                            <div id="vao__pace--comps"></div>
                        </div>
                    </div>
                    
                    <div class="tab-pane fade" id="compintel-content" role="tabpanel" aria-labelledby="compintel-tab">
                        <div id="vao__pace--content">
                            <div id="vao__pace--leadTimeEvos-rate"></div>
                        </div>
                    </div>
                    
                    <div class="tab-pane fade" id="roomtype-content" role="tabpanel" aria-labelledby="roomtype-tab">
                        <div id="vao__pace--content-roomtype">
                            <div id="vao__pace--roomtype"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>`;
    }

    //DATA FUNCTIONS STARTED
    dataPopulateOccEvolution(){
        let recordDate = window.infinito.vao.controller.storageHelper.getCurrentDate();
        let leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
            this.stayDate,
            recordDate
        );
        let dowEvoRange;

        if (leadTime < 14) {
            dowEvoRange = 14;
        } else if (leadTime < 30) {
            dowEvoRange = 30;
        } else if (leadTime < 60) {
            dowEvoRange = 60;
        } else if (leadTime <= 120) {
            dowEvoRange = 120;
        } else {
            dowEvoRange = 14;
        }

        let $content = $("#vao__pace--content");
        $content.find('#vao__pace--leadTimeEvos-occ').append(
            new window.vao.components.OccupancyEvolutionLeadTimeCompareChart({
                property: window.infinito.vao.controller.storageHelper.getSelectedHotel(),
                snapshotDate: recordDate,
                stayDate: this.stayDate,
                evolutionRange: dowEvoRange,
                historicRange: 4
            })
        );

    }


    render() {

        this.populateTabsString = this.htmlPopulateTabsString();

        return html`
        <div>${this.populateTabsString}</div>
        `;
    }
    firstUpdated(changedProperties) {
        this.dataPopulateOccEvolution();
    }


}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktop = PageDesktopBusinessModal;
customElements.define('vao-page-businessdetails-modal', PageDesktopBusinessModal);