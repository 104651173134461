import { AppElement, html } from '../AppElement.js';
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";

export default class ActualWithVarianceTableCellValue extends AppElement {
    static get properties() {
        return {
            actual: { type: Object },
            numericVarianceBlock: { type: Object },
        };
    }

    constructor(props = {}) {
        super();
        this.actual = props.actual || '';
        this.numericVarianceBlock = props.numericVarianceBlock;
    }

    reflow(props = {}) {
        this.actual = props.actual || this.actual;
        this.numericVarianceBlock = props.numericVarianceBlock || this.numericVarianceBlock;
    }

    render() {
        let varBlock;
        if (this.numericVarianceBlock instanceof NumericVarianceBlock && !this.numericVarianceBlock.isEmpty()) {
            varBlock = html`<div style="font-size:.7rem">${this.numericVarianceBlock}</div>`
        } else {
            varBlock = html``;
        }
        return html`
        <div style="flex-direction:column;">
            <div>${this.actual}</div>
            ${varBlock}
        </div>`;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ActualWithVarianceTableCellValue = ActualWithVarianceTableCellValue;
customElements.define('vao-actual-with-variance-table-cell-value', ActualWithVarianceTableCellValue);
