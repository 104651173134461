import { AppElement, html } from '../AppElement.js';

export default class BudgetYearSelect extends AppElement {
    static get properties() {
        return {
            baseYear: { type: String },
            year: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.baseYear = props.baseYear || '1970';
        this.year = props.year || this.baseYear;
        this.onChange = props.onChange;
    }

    reflow(props = {}) {
        this.baseYear = props.baseYear || this.baseYear;
        this.year = props.year || this.year;
        this.onChange = props.onChange || this.onChange;
    }

    _onChange(e) {
        this.value = e.currentTarget.value;
        if (typeof this.onChange === 'function') {
            this.onChange(e);
        }
    }

    render() {
        const baseLastYear = (parseInt(this.baseYear) - 1).toString();
        const baseNextYear = (parseInt(this.baseYear) + 1).toString();

        const options = [
            {
                value: baseLastYear,
                text: baseLastYear
            },
            {
                value: this.baseYear,
                text: this.baseYear
            },
            {
                value: baseNextYear,
                text: baseNextYear
            },
        ];

        return html`
<div class="vao__components--budgeYearSelect">
    <vao-select .value="${this.year || this.baseYear}" .options="${options}" @change="${this._onChange}">
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetYearSelect = BudgetYearSelect;
customElements.define('vao-budget-year-select', BudgetYearSelect);
