import { AppElement, html } from '../AppElement.js';

export const popTriggers = {
    click: 'click',
    hover: 'hover',
    focus: 'focus',
    manual: 'manual',
    focusCustom: 'focusCustom'
};

export const popPlacements = {
    auto: 'auto',
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right'
};

export const popBoundaries = {
    viewport: 'viewport',
    window: 'window',
    scrollParent: 'scrollParent'
};

export default class Popover extends AppElement {
    static get properties() {
        return {
            anchor: { type: Object },
            content: { type: Object },
            placement: { type: String },
            trigger: { type: String },
            container: { type: String },
            boundary: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.anchor = props.anchor || null;
        this.content = props.content || null;
        this.placement = props.placement || popPlacements.bottom;
        this.trigger = props.trigger || popTriggers.click;
        this.container = props.container === undefined ? 'body' : props.container;
        this.boundary = props.boundary === undefined ? popBoundaries.scrollParent : props.boundary;

        this.id = AppElement.getUniqueElementId();
        this.compClass="vao__components--popover";
        this.isVisible = false;
        this.boundFocusCustomListener = null;
    }

    reflow(props = {}) {
        this.anchor = props.anchor || this.anchor;
        this.content = props.content || this.content;
        this.placement = props.placement || this.placement;
        this.trigger = props.trigger || this.trigger;
        this.container = props.container || this.container;
        this.boundary = props.boundary || this.boundary;
        this.fill();
    }

    popShow() {
        let $anchor = this.get$Anchor();
        if ($anchor) {
            $anchor.popover('show');
        }
    }

    popHide() {
        let $anchor = this.get$Anchor();
        if ($anchor) {
            $anchor.popover('hide');
        }
    }

    popDispose() {
        let $anchor = this.get$Anchor();
        let namespacedClick = this.makeNamespacedEventName('click');

        // Dispose popover in the DOM regardless of the initialization anchors existence.
        $(`.${this.getPopoverTrackingClass()}`).popover('dispose');

        // Dispose popover using the initialization anchor. Calling dispose has no side effects.
        if ($anchor) {
            $anchor.find('*').off(); // Assuming popover anchors are leaf dom nodes - turn off any child pop listeners.
            $anchor.popover('dispose');
            if (this.trigger === popTriggers.focusCustom) {
                $anchor.off(namespacedClick);
            }
        }

        // Turn off the custom global listeners.
        if (this.trigger === popTriggers.focusCustom) {
            $('body').off(namespacedClick);
            if (this.boundFocusCustomListener) {
                document
                    .querySelector('body')
                    .removeEventListener('scroll', this.boundFocusCustomListener, true);
            }
        }
    }

    popUpdate() {
        let $anchor = this.get$Anchor();
        if ($anchor) {
            $anchor.popover('update');
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.fill();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.popDispose();
    }

    get$Anchor() {
        if (this.anchor instanceof HTMLElement) {
            return $(this.anchor);
        } else if (this.anchor instanceof $) {
            return this.anchor;
        } else {
            return $('#' + this.id).find('.' + this.compClass);
        }
    }

    get$Content() {
        if (this.content instanceof HTMLElement) {
            return $(this.content);
        } else if (this.content instanceof $) {
            return this.content;
        } else {
            return null;
        }
    }

    isElement(obj) {
        return obj instanceof HTMLElement || obj instanceof $;
    }

    wasFocusCustomClickInsidePopover(e) {
        let closest = e.target.closest('.popover');
        return closest !== null;
    }

    focusCustomClickListener(e) {
        let wasClickInsidePop = this.wasFocusCustomClickInsidePopover(e);
        if (this.isVisible && wasClickInsidePop) {
            return; // dont hide the popover when user clicks inside
        }
        if (this.isVisible) {
            this.popHide();
        } else {
            this.popShow();
        }
    }

    focusCustomListener(e) {
        if (!this.isVisible) {
            return;
        }
        let wasClickInsidePop = this.wasFocusCustomClickInsidePopover(e);
        if (!wasClickInsidePop) {
            this.popHide();
        }
    }

    makeNamespacedEventName(eventStr) {
        // https://api.jquery.com/on/
        return eventStr + '.' + this.id;
    }

    // Need to track popovers where the container does not match with the anchor.
    // This is because a popover might be on the "body" in the DOM but anchored to a specific
    // element deep in the node tree. The popover element will not be a descendant of the anchor.
    // The popover DOM location is dictated by the container option.
    getPopoverTrackingClass() {
        return `vao__components--popover-tracker-${this.id}`;
    }

    getPopoverTemplate() {
        return `
            <div class="popover ${this.getPopoverTrackingClass()}" role="tooltip">
                <div class="arrow"></div>
                <h3 class="popover-header"></h3>
                <div class="popover-body"></div>
            </div>
        `;
    }

    fill() {
        let $anchor = this.get$Anchor();
        let $content = this.get$Content();
        if (!$anchor || !$content) {
            return;
        }
        if (this.trigger === popTriggers.focus) {
            // https://getbootstrap.com/docs/4.0/components/popovers/#dismiss-on-next-click
            $anchor.attr('tabindex', '0');
        }
        const origTrigger = this.trigger;
        let trigger;
        if (this.trigger === popTriggers.focusCustom) {
            trigger = popTriggers.manual;
        } else {
            trigger = this.trigger;
        }
        this.popDispose(); // cleanup any old popover init
        $anchor.popover({
            animation: false,
            template: this.getPopoverTemplate(),
            html: true,
            container: this.container,
            content: $content,
            placement: this.placement,
            trigger: trigger,
            boundary: this.boundary
        });
        const namespacedClick = this.makeNamespacedEventName('click');
        if (origTrigger === popTriggers.focusCustom) {
            $anchor
                .off(namespacedClick)
                .on(namespacedClick, this.focusCustomClickListener.bind(this));
            if (!this.boundFocusCustomListener) {
                this.boundFocusCustomListener = this.focusCustomListener.bind(this); // Not the click variant.
            }
        }
        $anchor.off('shown.bs.popover').on('shown.bs.popover', () => {
            this.popUpdate();
            if (origTrigger === popTriggers.focusCustom) {
                // Popper.js default focus trigger will stopPropagation to any buttons inside of the .popover-body.
                // Use this custom focus trigger to support event listeners inside popover.
                setTimeout(() => {
                    $('body').on(namespacedClick, this.boundFocusCustomListener);
                    // Scroll event does not support bubbling. Use the vanilla capture.
                    document
                        .querySelector('body')
                        .addEventListener('scroll', this.boundFocusCustomListener, true);
                });
            }
            this.isVisible = true;
        });
        $anchor.off('hide.bs.popover').on('hide.bs.popover', () => {
            this.isVisible = false;
            if (origTrigger === popTriggers.focusCustom) {
                $('body').off(namespacedClick);
                document
                    .querySelector('body')
                    .removeEventListener('scroll', this.boundFocusCustomListener, true);
            }
        });
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
<div class="${this.compClass}">
    <!-- This component is tracking popovers in the DOM with class "vao__components--popover-tracker-{this.id}" -->
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Popover = Popover;
customElements.define('vao-popover', Popover);
