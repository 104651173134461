import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { mapCompPacePerSetRank } from '../StoryPitchMappings';

export default class StoryCompPricingSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.compSetAvgLeadSiblingPace
                ]
            }),
            (data, procData) => {
                let compSetAvgLeadSiblingPace =
                    ((procData.compSetAvgLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                const analyseAttributesHelper = window.infinito.vao.controller.analyseAttributesHelper;

                if (
                    compSetAvgLeadSiblingPace
                    && typeof compSetAvgLeadSiblingPace === 'object'
                    && 'T0' in compSetAvgLeadSiblingPace
                    && 'setDenseRank' in compSetAvgLeadSiblingPace.T0
                    && 'setDenseLength' in compSetAvgLeadSiblingPace.T0
                    && $.isNumeric(compSetAvgLeadSiblingPace.T0.setDenseRank)
                ) {
                    const setRank = parseInt(compSetAvgLeadSiblingPace.T0.setDenseRank);
                    const scaledMap = analyseAttributesHelper.scaleRankMapForUniqueLength(
                        mapCompPacePerSetRank,
                        compSetAvgLeadSiblingPace.T0.setDenseLength
                    );
                    let mapVal = scaledMap[setRank] || null;
                    if (mapVal) {
                        this.subtitle = mapVal;
                        const pnn = analyseAttributesHelper.getCompPricePNNForSetRank(
                            setRank,
                            compSetAvgLeadSiblingPace.T0.setDenseLength
                        );
                        if (pnn === analyseAttributesHelper.positive) {
                            this.badgeClass = Constants.BADGES.CLASSES.SUCCESS;
                        } else if (pnn === analyseAttributesHelper.neutral) {
                            this.badgeClass = Constants.BADGES.CLASSES.INFO;
                        } else if (pnn === analyseAttributesHelper.negative) {
                            this.badgeClass = Constants.BADGES.CLASSES.DANGER;
                        } else {
                            this.badgeClass = Constants.BADGES.CLASSES.PRIMARY;
                        }
                    } else {
                        this.subtitle = Constants.MISSING_STR;
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryCompPricingSubtitle = StoryCompPricingSubtitle;
customElements.define('vao-story-comp-pricing-subtitle', StoryCompPricingSubtitle);
