import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class TotalPickupExpandCardInfo extends AppElement {
    static get properties() {
        return {};
    }

    constructor(props = {}) {
        super();
    }

    render() {
        return html`
        <div class="vao__components--totalPickupExpandCardInfo">
            <div class="vao__components--totalPickupExpandCardInfo-head">
                <div class="vao__components--totalPickupExpandCardInfo-left"><span class="badge badge-light">Trend</span></div>
                <div class="vao__components--totalPickupExpandCardInfo-right">
                       <div class="vao__components--totalPickupExpandCardInfo-title"><span class="badge badge-light">Property/Group Name</span></div>
                       <div class="vao__components--totalPickupExpandCardInfo-stats">
                            <div class="vao__components--totalPickupExpandCardInfo-title"><span class="badge badge-light">Rooms PU</span></div>
                            <div class="vao__components--totalPickupExpandCardInfo-title"><span class="badge badge-light">ADR PU</span></div>
                            <div class="vao__components--totalPickupExpandCardInfo-title"><span class="badge badge-light">Revenue PU</span></div>
                        </div>
                </div>
            </div>
            <div style="display:none;" class="vao__components--totalPickupExpandCardInfo-body">
                <div class="vao__components--totalPickupExpandCardInfo-content"><span class="badge badge-light">More Content</span></div>
            </div>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TotalPickupExpandCardInfo = TotalPickupExpandCardInfo;
customElements.define('vao-total-pickup-expand-card-info', TotalPickupExpandCardInfo);
