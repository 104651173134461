import {html} from '../AppElement.js';
import RoomTypeBreakdownExpand from "./RoomTypeBreakdownExpand";
import {AppElement} from "../AppElement";
import DateSelectInput from "../dateSelect/DateSelectInput";
import SegmentBreakdownExpand from "./SegmentBreakdownExpand";
import Constants from "../Constants";

export default class MetricBreakdown extends AppElement {

    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            stayDate: { type: String },
            pickupSetting: { type: Number },
            expandedCategory: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel;
        this.recordDate = props.recordDate;
        this.stayDate = props.stayDate;
        this.pickupSetting = props.pickupSetting;
        this.expandedCategory = props.expandedCategory || Constants.CTX_CATEGORIES.ROOMTYPE;
        this.cache = new Map();
        this.id = AppElement.getUniqueElementId();
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.expandedCategory = props.expandedCategory || this.expandedCategory;
    }

    renderDateSelect() {
        let el;
        if (this.cache.has('dateSelect')) {
            el = this.cache.get('dateSelect');
            el.reflow({
                date: this.stayDate
            });
        } else {
            el = new DateSelectInput({
                date: this.stayDate,
                onChange: (newDate) => {
                    this.stayDate = newDate;
                },
                makeMinDate: () => {
                    return window.infinito.vao.controller.dateHelper.subtractDaysFromDateString(
                        this.recordDate,
                        1
                    );
                },
                makeMaxDate: () => {
                    return window.infinito.vao.controller.dateHelper.addDaysToDateString(
                        this.recordDate,
                        365
                    );
                }
            });
            this.cache.set('dateSelect', el);
        }
        return el;
    }

    renderRoomTypeBreakdown() {
        let el;
        if (this.cache.has('roomTypeBreakdownExpand')) {
            el = this.cache.get('roomTypeBreakdownExpand');
            el.reflow({
                hotel: this.hotel,
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                pickupSetting: this.pickupSetting,
            });
        } else {
            el = new RoomTypeBreakdownExpand({
                hotel: this.hotel,
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                pickupSetting: this.pickupSetting,
                isExpanded: this.expandedCategory === Constants.CTX_CATEGORIES.ROOMTYPE
            });
            this.cache.set('roomTypeBreakdownExpand', el);
        }
        return el;
    }

    renderSegmentBreakdown() {
        let el;
        if (this.cache.has('segmentBreakdownExpand')) {
            el = this.cache.get('segmentBreakdownExpand');
            el.reflow({
                hotel: this.hotel,
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                pickupSetting: this.pickupSetting,
            });
        } else {
            el = new SegmentBreakdownExpand({
                hotel: this.hotel,
                recordDate: this.recordDate,
                stayDate: this.stayDate,
                pickupSetting: this.pickupSetting,
                isExpanded: this.expandedCategory === Constants.CTX_CATEGORIES.SEGMENT
            });
            this.cache.set('segmentBreakdownExpand', el);
        }
        return el;
    }

    makeExpandTargetSelector() {
        return '#' + this.id + ' .vao__components--metricBreakdown-expands';
    }

    render() {
        return html`
            <div class="vao__components--metricBreakdown">
                <div style="display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;">
                    ${this.renderDateSelect()}
                    <vao-expand-btns .targetSelector="${this.makeExpandTargetSelector()}"></vao-expand-btns>
                </div>
                <br>
                <div class="vao__components--metricBreakdown-expands">
                    ${this.renderRoomTypeBreakdown()}
                    ${this.renderSegmentBreakdown()}
                </div>
            </div>
        `;
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MetricBreakdown = MetricBreakdown;
customElements.define('vao-metric-breakdown', MetricBreakdown);
