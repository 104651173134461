import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { mapRoomsPacePickupTrendComboPNN } from '../StoryPitchMappings';

export default class StoryInternalDataSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.roomsOtbLeadSiblingPace,
                    modelBiStats.fields.roomsOtbPickupTrend,
                    modelBiStats.fields.roomsOtbPickup
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                let roomsOtbLeadSiblingPace =
                    ((procData.roomsOtbLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let roomsOtbPickupTrend =
                    ((procData.roomsOtbPickupTrend || {})[this.stayDate] || {}).value || null;
                let roomsOtbPickup =
                    ((procData.roomsOtbPickup || {})[this.stayDate] || {}).value || null;
                const analyseAttributesHelper = window.infinito.vao.controller.analyseAttributesHelper;

                this.badgeClass = 'badge-primary';

                if (
                    roomsOtbLeadSiblingPace
                    && typeof roomsOtbLeadSiblingPace === 'object'
                    && 'T0' in roomsOtbLeadSiblingPace
                    && 'setDenseRank' in roomsOtbLeadSiblingPace.T0
                    && 'setDenseLength' in roomsOtbLeadSiblingPace.T0
                    && $.isNumeric(roomsOtbLeadSiblingPace.T0.setDenseRank)
                    && roomsOtbPickupTrend
                    && 'median' in roomsOtbPickupTrend
                    && $.isNumeric(roomsOtbPickupTrend.median)
                    && $.isNumeric(roomsOtbPickup)
                ) {
                    const setRank = parseInt(roomsOtbLeadSiblingPace.T0.setDenseRank);
                    const setLength = roomsOtbLeadSiblingPace.T0.setDenseLength;
                    let pacePnn = analyseAttributesHelper.getMyOtbPNNForSetRank(setRank, setLength);
                    let pickupTrend;
                    if (roomsOtbPickup > roomsOtbPickupTrend.median) {
                        pickupTrend = 'positive';
                    } else if (roomsOtbPickup < roomsOtbPickupTrend.median) {
                        pickupTrend = 'negative';
                    } else {
                        pickupTrend = 'neutral';
                    }
                    let combo = pacePnn + pickupTrend;
                    let str = mapRoomsPacePickupTrendComboPNN[combo] || null;
                    if (str) {
                        this.subtitle = str;
                    } else {
                        this.subtitle = Constants.MISSING_STR;
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalDataSubtitle = StoryInternalDataSubtitle;
customElements.define('vao-story-internal-data-subtitle', StoryInternalDataSubtitle);
