import { AppElement, html } from '../AppElement.js';

export const EVENT_ON_CHANNEL_AND_DISTINCTION_SELECT_CHANGE = 'vao-channel-and-distinction-select-on-change';

export default class ChannelAndDistinctionSelect extends AppElement {
    static get properties() {
        return {
            selectedChannelId: { type: String },
            channels: { type: Array },
            hotelId: { type: String }, // Optional
        };
    }

    constructor(props = {}) {
        super();
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId || '0';
        this.channels = props.channels || this.channels || null;
        this.hotelId = props.hotelId || this.hotelId || null;
    }

    reflow(props = {}) {
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId ;
        this.channels = props.channels || this.channels;
        this.hotelId = props.hotelId || this.hotelId;
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('channels') && !Array.isArray(this.channels)) {
            this.fill();
        }
    }

    fill() {
        const cb = (errorMsg, channelPayload) => {
            if (errorMsg) {
                console.error(errorMsg);
                return;
            }
            this.channels = channelPayload.data || [];
        };
        window.infinito.vao.model.biChannels.fetchIncludingSubChannels(cb);
    }

    onChangeSelection(e) {
        this.selectedChannelId = e.detail.newValue;
        const event = new CustomEvent(EVENT_ON_CHANNEL_AND_DISTINCTION_SELECT_CHANGE, {
            detail: {
                newValue: this.selectedChannelId
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    onChangePrimary(e) {
        this.onChangeSelection(e);
    }

    onChangeSubChannel(e) {
        this.onChangeSelection(e);
    }

    getSelectedChannel() {
        return (this.channels || []).find(channel => {
            return String(channel.id) === String(this.selectedChannelId);
        });
    }

    // If user has selected a sub-channel, then return the primary channel id.
    determineSelectedPrimaryChannelId() {
        if (!this.selectedChannelId) {
            return undefined;
        }
        const currentChannel = this.getSelectedChannel();
        if (!currentChannel) {
            return undefined;
        }
        if (!currentChannel.name.includes('|')) {
            return currentChannel.id; // User has already selected a primary channel.
        }
        const primaryName = currentChannel.name.split('|')[0];
        const primaryChannel = (this.channels || []).find(channel => {
            return channel.name === primaryName;
        });
        if (!primaryChannel) {
            return undefined;
        }
        return primaryChannel.id;
    }

    determinePrimaryChannels() {
        return (this.channels || []).filter(channel => {
            return !channel.name.includes('|');
        });
    }

    determineDistinctionChannels() {
        const channel = this.getSelectedChannel();
        if (!channel) {
            return [];
        }
        const primaryName = channel.name.includes('|') ? channel.name.split('|')[0] : channel.name;
        return (this.channels || []).filter(channel => {
            return channel.name.startsWith(primaryName);
        });
    }

    render() {
        const primaryChannelsId = this.determineSelectedPrimaryChannelId();
        const primaryChannels = this.determinePrimaryChannels();
        const distinctionChannels = this.determineDistinctionChannels();
        const alertChannelsId = this.selectedChannelId === primaryChannelsId ? this.selectedChannelId : null;
        const alertHotelsId = this.selectedChannelId === primaryChannelsId ? this.hotelId : null;

        return html`
            <div class="vao__components--channelAndDistinctionSelect">
                <div>
                    <vao-channel-select
                        .selectedChannelId="${primaryChannelsId}"
                        .channels="${primaryChannels}"
                        @vao-channel-select-on-change="${this.onChangePrimary.bind(this)}"
                    ></vao-channel-select>
                </div>
                <div style="margin-top:.5rem;">
                    <vao-channel-distinction-select
                        .selectedChannelId="${this.selectedChannelId}"
                        .channels="${distinctionChannels}"
                        @vao-channel-select-distinction-on-change="${this.onChangeSubChannel.bind(this)}"
                    ></vao-channel-distinction-select>
                </div>
                <div style="margin-top:.5rem;">
                    <vao-channel-hotel-selection-checker-alert
                        .selectedChannelId="${alertChannelsId}"
                        .hotelId=${alertHotelsId}
                    ></vao-channel-hotel-selection-checker-alert>
                </div>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ChannelAndDistinctionSelect = ChannelAndDistinctionSelect;
customElements.define('vao-channel-and-distinction-select', ChannelAndDistinctionSelect);
