import { html } from '../AppElement.js';
import Constants from '../Constants';
import { sendRequest } from '../App.common';
import AnnualTileSingle from './AnnualTileSingle';

const PositiveTextColor = '#32cd32';
const NegativeTextColor = '#8b0000';
export default class AnnualTileSingleTable extends AnnualTileSingle {
    static get properties() {
        return {
            recordDate: { type: String },
            hotel: { type: Object },
            pickupSetting: { type: String }
        };
    }

    constructor(props = {}) {
        super(props);
        this.isgroup = infinito.vao.controller.storageHelper.getState();
        this.hotel = props.hotel;
        this.MonthsObject = [];
        this.currentFinancialYear = this.getCurrentFinancialYear(this.recordDate);
        this.start_date = this.currentFinancialYear.startDate;
        this.end_date = this.currentFinancialYear.endDate;
        this.revstlyrev = [];
        this.adrstlyadr = [];
        this.OccStlyOcc = [];
        this.DeltaFcBuOcc = [];
        this.DeltaFcBuRooms = [];
        this.DeltaFcBuAdr = [];
        this.DeltaFcBuRev = [];
        this.DeltaLyOtbOcc = [];
        this.DeltaLyOtbRooms = [];
        this.DeltaLyOtbAdr = [];
        this.DeltaLyOtbRev = [];

        /* TOTAL ROW VARIABLES */
        this.TotalOcc = [];
        this.TotalRooms = 0;
        this.TotalAdr = [];
        this.TotalRev = 0;

        this.TotalFcOcc = [];
        this.TotalFcRooms = 0;
        this.TotalFcAdr = [];
        this.TotalFcRev = 0;

        this.TotalBudgetOcc = [];
        this.TotalBudgetRooms = 0;
        this.TotalBudgetAdr = [];
        this.TotalBudgetRev = 0;

        this.TotalStlyOcc = [];
        this.TotalStlyRooms = 0;
        this.TotalStlyAdr = [];
        this.TotalStlyRev = 0;

        this.TotalLyOcc = [];
        this.TotalLyRooms = 0;
        this.TotalLyAdr = [];
        this.TotalLyRev = 0;
    }

    reflow(props = {}) {
        super.reflow(props);
        this.hotel = props.hotel || this.hotel;
        this.cntr = 0;
    }

    getTableStateKey() {
        return 'hotel';
    }

    getCurrentFinancialYear(strDocDate) {
        /** Here We need Financial Year as monthw start so we have given 9 number hardcoded i.e. October and End month will be September **/
        var startYear = '';
        var endYear = '';
        var docDate = new Date(strDocDate);
        if ((docDate.getMonth() + 1) <= 9) {
            startYear = docDate.getFullYear() - 1;
            endYear = docDate.getFullYear();
        } else {
            startYear = docDate.getFullYear();
            endYear = docDate.getFullYear() + 1;
        }
        return { startDate: startYear + '-10', endDate: endYear + '-09' };
    }

    dateRange(startDate, endDate) {
        var start = startDate.split('-');
        var end = endDate.split('-');
        var startYear = parseInt(start[0]);
        var endYear = parseInt(end[0]);
        var dates = [];

        for (var i = startYear; i <= endYear; i++) {
            var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayMonth = month < 10 ? '0' + month : month;
                dates.push([i, displayMonth].join('-'));
            }
        }
        return dates;
    }

    fetchnormalmonthsdata() {
        var flag = this;
        var yearlymonths = this.dateRange(this.start_date, this.end_date);
        const monthNames = ['NA', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        var TotalSizeOfMonths = yearlymonths.length;
        var IteratorCall = 0;
        $.each(yearlymonths, function (key, val) {
            let cell;
            let foundAnyData = false;
            var row = flag.cache.rowMap.get(val.substr(0, 4) + ' ' + monthNames[val.substr(5, 2) * 1]);
            var postdata = {
                hotel_id: flag.hotel.id,
                recordDate: flag.recordDate,
                yearMonKey: val,
                op: 'getAnnualTileData',
                serviceName: 'annualtile.php'
            };
            sendRequest(postdata, function (res) {
                IteratorCall++;
                let obj = JSON.parse(res);
                let occ = obj.data.occupancy;
                if (occ > 0) {
                    flag.TotalOcc.push(occ);
                }
                cell = row.tableCellPerField.get(flag.cellFieldMap.occ);
                cell.value = occ + '%';
                cell.isLoading = false;

                // Rooms
                cell = row.tableCellPerField.get(flag.cellFieldMap.rms);
                if ($.isNumeric(obj.data.roomsOtb)) {
                    flag.TotalRooms += obj.data.roomsOtb;
                    foundAnyData = true;
                    cell.value = html`
                            <div style="flex-direction:column;">
                                <div>${obj.data.roomsOtb}</div>

                            </div>`;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                // ADR
                cell = row.tableCellPerField.get(flag.cellFieldMap.adr);
                if ($.isNumeric(obj.data.adr)) {
                    foundAnyData = true;
                    if (obj.data.adr > 0) {
                        flag.TotalAdr.push(obj.data.adr);
                    }
                    const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.adr,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    let cellVal = html`<div style="flex-direction:column;">
                            <div>${formatted}</div>

                        </div>`;
                    cell.value = cellVal;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                // Rev
                cell = row.tableCellPerField.get(flag.cellFieldMap.rev);
                if ($.isNumeric(obj.data.revenueNet)) {
                    flag.TotalRev += obj.data.revenueNet;
                    foundAnyData = true;
                    const formatted1 = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.revenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    let cellVal = html`<div style="flex-direction:column;">
                            <div>${formatted1}</div>

                        </div>`;
                    cell.value = cellVal;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** ******========FORECAST BUDGET========****** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occfc);
                cell.value = '';
                if (obj.data.Bfc.bfcMTDtotalOcc === '-') {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc;
                } else {
                    cell.value = obj.data.Bfc.bfcMTDtotalOcc + '%';
                    if (obj.data.Bfc.bfcMTDtotalOcc > 0) {
                        flag.TotalFcOcc.push(parseFloat(obj.data.Bfc.bfcMTDtotalOcc));
                    }
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.occBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOcc;
                if ($.isNumeric(obj.data.Bu.BMTDtotalOcc.replace('%', '')) && obj.data.Bu.BMTDtotalOcc.replace('%', '') > 0) {
                    flag.TotalBudgetOcc.push(parseFloat(obj.data.Bu.BMTDtotalOcc.replace('%', '')));
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalOtb === 0 ? '-' : obj.data.Bfc.bfcMTDtotalOtb;
                if (obj.data.Bfc.bfcMTDtotalOtb != 0) {
                    flag.TotalFcRooms += obj.data.Bfc.bfcMTDtotalOtb;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalOtb === 0 ? '-' : obj.data.Bu.BMTDtotalOtb;
                if (obj.data.Bu.BMTDtotalOtb != 0) {
                    flag.TotalBudgetRooms += obj.data.Bu.BMTDtotalOtb;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrfc);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                if ($.isNumeric(obj.data.Bfc.bfcMTDtotalAdr) && obj.data.Bfc.bfcMTDtotalAdr > 0) {
                    flag.TotalFcAdr.push(parseFloat(obj.data.Bfc.bfcMTDtotalAdr));
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrBu);
                cell.value = '';
                cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalAdr,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale,
                    1
                ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                if ($.isNumeric(obj.data.Bu.BMTDtotalAdr) && obj.data.Bu.BMTDtotalAdr > 0) {
                    flag.TotalBudgetAdr.push(parseFloat(obj.data.Bu.BMTDtotalAdr));
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revfc);
                cell.value = '';
                cell.value = obj.data.Bfc.bfcMTDtotalRev > 0 ? window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bfc.bfcMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '') : '-';
                flag.TotalFcRev += obj.data.Bfc.bfcMTDtotalRev > 0 ? parseFloat(obj.data.Bfc.bfcMTDtotalRev) : 0;
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revBu);
                cell.value = '';
                cell.value = obj.data.Bu.BMTDtotalRev > 0 ? window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    obj.data.Bu.BMTDtotalRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '') : '-';
                flag.TotalBudgetRev += obj.data.Bu.BMTDtotalRev > 0 ? parseFloat(obj.data.Bu.BMTDtotalRev) : 0;
                cell.isLoading = false;

                /** ********=========LAST YEAR ROW============********* */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occaLy);
                cell.value = '';
                if (obj.data.Lydata.occupancy === 0) {
                    cell.value = '-';
                } else {
                    cell.value = obj.data.Lydata.occupancy + '%';
                    if (obj.data.Lydata.occupancy > 0) {
                        flag.TotalLyOcc.push(parseFloat(obj.data.Lydata.occupancy));
                    }
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsaLy);
                cell.value = '';
                cell.value = obj.data.Lydata.roomsOtb === 0 ? '-' : obj.data.Lydata.roomsOtb;
                if (obj.data.Lydata.roomsOtb != 0) {
                    flag.TotalLyRooms += obj.data.Lydata.roomsOtb;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adraLy);
                if ($.isNumeric(obj.data.Lydata.adrNet)) {
                    foundAnyData = true;
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Lydata.adrNet,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    if (obj.data.Lydata.adrNet > 0) {
                        flag.TotalLyAdr.push(parseFloat(obj.data.Lydata.adrNet));
                    }
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revaLy);
                if ($.isNumeric(obj.data.Lydata.totalrevenueNet)) {
                    foundAnyData = true;
                    flag.TotalLyRev += parseFloat(obj.data.Lydata.totalrevenueNet);
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.Lydata.totalrevenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** ************========ACTUALS LAST YEAR ROW=========******************** */
                cell = row.tableCellPerField.get(flag.cellFieldMap.occstLy);
                cell.value = '';
                if (obj.data.ActualLyData.occupancy === 0) {
                    cell.value = '-';
                } else {
                    cell.value = obj.data.ActualLyData.occupancy + '%';
                    if (obj.data.ActualLyData.occupancy > 0) {
                        flag.TotalStlyOcc.push(parseFloat(obj.data.ActualLyData.occupancy));
                    }
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.roomsstLy);
                cell.value = obj.data.ActualLyData.roomsOtb === 0 ? '-' : obj.data.ActualLyData.roomsOtb;
                if (obj.data.ActualLyData.roomsOtb != 0) {
                    flag.TotalStlyRooms += obj.data.ActualLyData.roomsOtb;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.adrstLy);
                if ($.isNumeric(obj.data.ActualLyData.adrNet)) {
                    foundAnyData = true;
                    if (obj.data.ActualLyData.adrNet > 0) {
                        flag.TotalStlyAdr.push(parseFloat(obj.data.ActualLyData.adrNet));
                    }
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.ActualLyData.adrNet,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                cell = row.tableCellPerField.get(flag.cellFieldMap.revstLy);
                if ($.isNumeric(obj.data.ActualLyData.totalrevenueNet)) {
                    foundAnyData = true;
                    flag.TotalStlyRev += parseFloat(obj.data.ActualLyData.totalrevenueNet);
                    let formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        obj.data.ActualLyData.totalrevenueNet,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    cell.value = formatted;
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** REV - STYLREV * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.RevStlyRev);
                var formatted = '0';
                if ($.isNumeric((obj.data.ActualLyData.totalrevenueNet)) && $.isNumeric((obj.data.revenueNet))) {
                    formatted = obj.data.revenueNet - obj.data.ActualLyData.totalrevenueNet;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        formatted,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    flag.revstlyrev.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('RevStlyRev');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** ADR - STYLADR * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.AdrStlyAdr);
                var formatted = '0';
                if ($.isNumeric((obj.data.ActualLyData.adrNet)) && $.isNumeric((obj.data.adr))) {
                    formatted = obj.data.adr - obj.data.ActualLyData.adrNet;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        formatted,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');

                    flag.adrstlyadr.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('AdrStlyAdr');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Occ - STYLOCC * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.OccStlyOcc);
                var formatted = '0';
                if ($.isNumeric((obj.data.ActualLyData.occupancy)) && $.isNumeric((obj.data.occupancy))) {
                    formatted = (obj.data.occupancy - obj.data.ActualLyData.occupancy).toFixed(2);
                    cell.value = formatted + '%';
                    flag.OccStlyOcc.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('OccStlyOcc');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Fc Occ - Bu Occ * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaFcBuOcc);
                var formatted = '0';
                var BtotalOcc = parseFloat(obj.data.Bu.BMTDtotalOcc.replace('%', ''));
                var FctotalOcc = parseFloat(obj.data.Bfc.bfcMTDtotalOcc.replace('%', ''));
                if ($.isNumeric(BtotalOcc) && $.isNumeric(FctotalOcc)) {
                    formatted = (FctotalOcc - BtotalOcc).toFixed(2);
                    cell.value = formatted + '%';
                    flag.DeltaFcBuOcc.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaFcBuOcc');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Fc Rooms - Bu Rooms * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaFcBuRooms);
                var formatted = '0';
                var BtotalRooms = parseInt(obj.data.Bu.BMTDtotalOtb);
                var FctotalRooms = parseInt(obj.data.Bfc.bfcMTDtotalOtb);
                if ($.isNumeric(BtotalRooms) && $.isNumeric(FctotalRooms)) {
                    formatted = FctotalRooms - BtotalRooms;
                    cell.value = formatted;
                    flag.DeltaFcBuRooms.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaFcBuRooms');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Fc Adr - Bu Adr * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaFcBuAdr);
                var formatted = '0';
                if ($.isNumeric((obj.data.Bfc.bfcMTDtotalAdr)) && $.isNumeric((obj.data.Bu.BMTDtotalAdr))) {
                    formatted = obj.data.Bfc.bfcMTDtotalAdr - obj.data.Bu.BMTDtotalAdr;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        formatted,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    flag.DeltaFcBuAdr.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaFcBuAdr');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Fc Rev - Bu Rev * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaFcBuRev);
                var formatted = '0';
                if ($.isNumeric((obj.data.Bfc.bfcMTDtotalRev)) && $.isNumeric((obj.data.Bu.BMTDtotalRev))) {
                    formatted = obj.data.Bfc.bfcMTDtotalRev - obj.data.Bu.BMTDtotalRev;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        formatted,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    flag.DeltaFcBuRev.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaFcBuRev');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Ly Occ - Otb Occ * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaLyOtbOcc);
                var formatted = '0';
                var LytotalOcc = parseFloat(obj.data.Lydata.occupancy.replace('%', ''));
                var OtbtotalOcc = parseFloat(obj.data.occupancy.replace('%', ''));
                if ($.isNumeric(LytotalOcc) && $.isNumeric(OtbtotalOcc)) {
                    formatted = (LytotalOcc - OtbtotalOcc).toFixed(2);
                    cell.value = formatted + '%';
                    flag.DeltaLyOtbOcc.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaLyOtbOcc');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Ly Rooms - Otb Rooms * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaLyOtbRooms);
                var formatted = '0';
                var LytotalRooms = parseInt(obj.data.Lydata.roomsOtb);
                var OtbtotalRooms = parseInt(obj.data.roomsOtb);
                if ($.isNumeric(LytotalRooms) && $.isNumeric(OtbtotalRooms)) {
                    formatted = LytotalRooms - OtbtotalRooms;
                    cell.value = formatted;
                    flag.DeltaLyOtbRooms.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaLyOtbRooms');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Ly Adr - Otb Adr * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaLyOtbAdr);
                var formatted = '0';
                if ($.isNumeric((obj.data.Lydata.adrNet)) && $.isNumeric((obj.data.adr))) {
                    formatted = obj.data.Lydata.adrNet - obj.data.adr;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        formatted,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale,
                        1
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    flag.DeltaLyOtbAdr.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaLyOtbAdr');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                /** Ly Rev - Otb Rev * */
                cell = row.tableCellPerField.get(flag.cellFieldMap.DeltaLyOtbRev);
                var formatted = '0';
                if ($.isNumeric((obj.data.Lydata.totalrevenueNet)) && $.isNumeric((obj.data.revenueNet))) {
                    formatted = obj.data.Lydata.totalrevenueNet - obj.data.revenueNet;
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        formatted,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
                    flag.DeltaLyOtbRev.push({ stayDate: val, value: formatted });
                    if (IteratorCall == TotalSizeOfMonths) {
                        flag.AllDataMappedForColordradient('DeltaLyOtbRev');
                    }
                } else {
                    cell.value = null;
                }
                cell.isLoading = false;

                if (IteratorCall == TotalSizeOfMonths) {
                    row = flag.cache.rowMap.get('Total');
                    flag.FillTotalsRow(row);
                    flag.FillTotalTiles();
                }
            });
        });
    }

    AllDataMappedForColordradient(MakeGradientType) {
        var MakeGradientTypeList = [];
        if (MakeGradientType == 'RevStlyRev') {
            MakeGradientTypeList = this.revstlyrev;
        } else if (MakeGradientType == 'AdrStlyAdr') {
            MakeGradientTypeList = this.adrstlyadr;
        } else if (MakeGradientType == 'OccStlyOcc') {
            MakeGradientTypeList = this.OccStlyOcc;
        } else if (MakeGradientType == 'DeltaFcBuOcc') {
            MakeGradientTypeList = this.DeltaFcBuOcc;
        } else if (MakeGradientType == 'DeltaFcBuRooms') {
            MakeGradientTypeList = this.DeltaFcBuRooms;
        } else if (MakeGradientType == 'DeltaFcBuAdr') {
            MakeGradientTypeList = this.DeltaFcBuAdr;
        } else if (MakeGradientType == 'DeltaFcBuRev') {
            MakeGradientTypeList = this.DeltaFcBuRev;
        } else if (MakeGradientType == 'DeltaLyOtbOcc') {
            MakeGradientTypeList = this.DeltaLyOtbOcc;
        } else if (MakeGradientType == 'DeltaLyOtbRooms') {
            MakeGradientTypeList = this.DeltaLyOtbRooms;
        } else if (MakeGradientType == 'DeltaLyOtbAdr') {
            MakeGradientTypeList = this.DeltaLyOtbAdr;
        } else if (MakeGradientType == 'DeltaLyOtbRev') {
            MakeGradientTypeList = this.DeltaLyOtbRev;
        }

        const adrMoveColorCoder = this.makeGradientColorCoder(MakeGradientTypeList);
        for (var i = 0; i < MakeGradientTypeList.length; i++) {
            var val = MakeGradientTypeList[i].stayDate;
            const bgColor = adrMoveColorCoder(MakeGradientTypeList[i].value);
            const monthNames = ['NA', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
            let cell;
            var row = this.cache.rowMap.get(val.substr(0, 4) + ' ' + monthNames[val.substr(5, 2) * 1]);
            cell = row.tableCellPerField.get(this.cellFieldMap[MakeGradientType]);

            var formatted = '0';
            if (MakeGradientType == 'RevStlyRev' || MakeGradientType == 'DeltaFcBuRev' || MakeGradientType == 'DeltaLyOtbRev') {
                formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    MakeGradientTypeList[i].value,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.hotel.locale
                ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
            } else if (MakeGradientType == 'AdrStlyAdr' || MakeGradientType == 'DeltaFcBuAdr' || MakeGradientType == 'DeltaLyOtbAdr') {
                formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    MakeGradientTypeList[i].value,
                    Constants.ADR_DIGITS,
                    true,
                    this.hotel.locale,
                    1
                ).replace(infinito.vao.controller.moneyHelper.getCurrencySymbol(), '');
            } else if (MakeGradientType == 'OccStlyOcc' || MakeGradientType == 'DeltaFcBuOcc' || MakeGradientType == 'DeltaLyOtbOcc') {
                formatted = MakeGradientTypeList[i].value + '%';
            } else if (MakeGradientType == 'DeltaFcBuRooms' || MakeGradientType == 'DeltaLyOtbRooms') {
                formatted = MakeGradientTypeList[i].value;
            }

            cell.value = html`<vao-tag
                                    label="${formatted}"
                                    bgColor="${bgColor}"
                                ></vao-tag>`;
            cell.isLoading = false;
        }
    }

    makeGradientColorCoder(MakeGradientTypeList) {
        let values = [];
        let min; let
            max;
        if (MakeGradientTypeList.length > 0) {
            for (var i = 0; i < MakeGradientTypeList.length; i++) {
                values.push(parseFloat(MakeGradientTypeList[i].value));
            }
        }
        if (values.length <= 1) {
            // No point coloring.
            min = null;
            max = null;
        } else {
            min = Math.min(...values);
            max = Math.max(...values);
        }

        return this.makeGradientColorCoderForMinAndMax(min, max);
    }

    makeGradientColorCoderForMinAndMax(min, max) {
        const colorBuckets = window.infinito.vao.controller.gradientColorHelper.getColorBucketsForMinForMax(min, max);

        return function (statisticValue) {
            if (!$.isNumeric(statisticValue)) {
                return null;
            }
            const floatValue = parseFloat(statisticValue);
            if (floatValue === 0 || floatValue === 0.0) {
                return null;
            }
            return window.infinito.vao.controller.gradientColorHelper.getColorForValueForColorBuckets(
                floatValue,
                colorBuckets,
                null
            );
        };
    }

    getTotalCellValueFromArray(ColumnObject, ColumnType) {
        var ColumnIterations = 1;
        var ColunmsTotal = 0;
        for (var i = 0; i < ColumnObject.length; i++) {
            if ($.isNumeric(ColumnObject[i].value) && ColumnObject[i].value != 0) {
                ColunmsTotal += parseFloat(ColumnObject[i].value);
                ColumnIterations++;
            }
        }
        if (ColunmsTotal != 0 && ColumnIterations > 1) {
            if (ColumnType === 'adr') {
                return window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    parseFloat(ColunmsTotal) / ColumnIterations,
                    Constants.ADR_DIGITS,
                    true,
                    this.hotel.locale,
                    1
                );
            } if (ColumnType === 'occ') {
                return (parseFloat(ColunmsTotal) / ColumnIterations).toFixed(2) + '%';
            } if (ColumnType === 'rms') {
                return parseInt(ColunmsTotal);
            } if (ColumnType === 'rev') {
                return window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    ColunmsTotal,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.hotel.locale
                );
            }
        } else {
            return '-';
        }
    }

    fillDataToTotalTiles(rms,lyRms,domAbsoluteId,domRelativeId){
        if (rms != 0 && rms != '-') {
            $(domAbsoluteId).text('');
            $(domAbsoluteId).text(rms);
            if (rms < 0) {
                $(domAbsoluteId).css('color', NegativeTextColor);
            } else if (rms > 0) {
                $(domAbsoluteId).css('color', PositiveTextColor);
            }
            if (lyRms != 0 && lyRms != '-') {
                let roomsNightHtml = '';
                $(domRelativeId).text('');
                let roomsRelativeValue = parseInt(rms) - parseInt(lyRms);
                let roomsRelativeNightChangePercentValue = Math.round((parseInt(roomsRelativeValue) / parseInt(lyRms)) * 100);
                if (roomsRelativeValue < 0) {
                    roomsNightHtml = `<span style="color: ${NegativeTextColor}">${Math.abs(roomsRelativeValue)}</span> `;
                } else if (roomsRelativeValue > 0) {
                    roomsNightHtml = `<span style="color:${PositiveTextColor}">${roomsRelativeValue}</span> `;
                }
                if (roomsRelativeNightChangePercentValue < 0) {
                    roomsNightHtml = roomsNightHtml + `<span style="color: ${NegativeTextColor}">(${Math.abs(roomsRelativeNightChangePercentValue)}%)</span>`;
                } else if (roomsRelativeNightChangePercentValue > 0) {
                    roomsNightHtml = roomsNightHtml + ` <span style="color: ${PositiveTextColor}">(${roomsRelativeNightChangePercentValue}%)</span>`;
                }
                $(domRelativeId).html(roomsNightHtml);
            }
        }
    }

    FillTotalTiles() {
        /** Total Rms Card.* */
        this.fillDataToTotalTiles(this.TotalRooms, this.TotalLyRooms, '#vao__annual--roomnights_absolute', '#vao__annual--roomnights_relative');

        /** Total ADR Card.* */
        let AdrSum = 0;
        this.TotalAdr.forEach((el) => AdrSum += parseFloat(el));
        if (AdrSum > 0) {
            $('#vao__annual--adr_absolute').text('');
            $('#vao__annual--adr_absolute').text(window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                AdrSum,
                Constants.ADR_DIGITS,
                true,
                this.hotel.locale,
                1
            ));
            if (AdrSum < 0) {
                $('#vao__annual--adr_absolute').css('color', NegativeTextColor);
            } else if (AdrSum > 0) {
                $('#vao__annual--adr_absolute').css('color', PositiveTextColor);
            }
            let AdrSymLy = 0;
            this.TotalLyAdr.forEach((el) => AdrSymLy += parseFloat(el));
            if (AdrSymLy > 0) {
                let AdrNightHtml = '';
                $('#vao__annual--adr_relative').text('');
                let AdrRelativeValue = parseInt(AdrSum) - parseInt(AdrSymLy);
                let AdrRelativeNightChangePercentValue = Math.round((parseInt(AdrRelativeValue) / parseInt(AdrSymLy)) * 100);
                if (AdrRelativeValue < 0) {
                    AdrNightHtml = `<span style="color: ${NegativeTextColor}">${window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        Math.abs(AdrRelativeValue),
                        Constants.ADR_DIGITS,
                        true,
                        this.hotel.locale,
                        1
                    )}</span> `;
                } else if (AdrRelativeValue > 0) {
                    AdrNightHtml = `<span style="color: ${PositiveTextColor}">${window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        AdrRelativeValue,
                        Constants.ADR_DIGITS,
                        true,
                        this.hotel.locale,
                        1
                    )}</span> `;
                }
                if (AdrRelativeNightChangePercentValue < 0) {
                    AdrNightHtml = AdrNightHtml + ` <span style="color: ${NegativeTextColor}">(${Math.abs(AdrRelativeNightChangePercentValue)}%)</span>`;
                } else if (AdrRelativeNightChangePercentValue > 0) {
                    AdrNightHtml = AdrNightHtml + ` <span style="color: ${PositiveTextColor}">(${AdrRelativeNightChangePercentValue}%)</span>`;
                }
                $('#vao__annual--adr_relative').html(AdrNightHtml);
            }
        }

        /** Total Rev Card.* */
        if (this.TotalRev > 0) {
            $('#vao__annual--rev_absolute').text('');
            $('#vao__annual--rev_absolute').text(window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                this.TotalRev,
                Constants.REVENUE_DIGITS,
                true,
                this.hotel.locale,
                1
            ));
            if (this.TotalRev < 0) {
                $('#vao__annual--rev_absolute').css('color', NegativeTextColor);
            } else if (this.TotalRev > 0) {
                $('#vao__annual--rev_absolute').css('color', PositiveTextColor);
            }
            if (this.TotalLyRev > 0) {
                let revNightHtml = '';
                $('#vao__annual--rev_relative').text('');
                let revRelativeValue = parseInt(this.TotalRev) - parseInt(this.TotalLyRev);
                let revRelativeNightChangePercentValue = Math.round((parseInt(revRelativeValue) / parseInt(this.TotalLyRev)) * 100);
                if (revRelativeValue < 0) {
                    revNightHtml = `<span style="color: ${NegativeTextColor}"> ${window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        Math.abs(revRelativeValue),
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale,
                        1
                    )}</span> `;
                } else if (revRelativeValue > 0) {
                    revNightHtml = `<span style="color: ${PositiveTextColor}">${window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revRelativeValue,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale,
                        1
                    )}</span> `;
                }
                if (revRelativeNightChangePercentValue < 0) {
                    revNightHtml = revNightHtml + ` <span style="color: ${NegativeTextColor}">(${Math.abs(revRelativeNightChangePercentValue)}%)</span>`;
                } else if (revRelativeNightChangePercentValue > 0) {
                    revNightHtml = revNightHtml + ` <span style="color: ${PositiveTextColor}">(${revRelativeNightChangePercentValue}%)</span>`;
                }
                $('#vao__annual--rev_relative').html(revNightHtml);
            }
        }

        /** Total Budget Rms Card.**/
        this.fillDataToTotalTiles(this.TotalBudgetRooms, this.TotalLyRooms, '#vao__annual--budgetroomnights_absolute', '#vao__annual--budgetroomnights_relative');

        /** Total Forecast Rms Card.**/
        this.fillDataToTotalTiles(this.TotalFcRooms, this.TotalLyRooms, '#vao__annual--forecastroomnights_absolute', '#vao__annual--forecastroomnights_relative');
    }

    FillTotalsRow(row) {
        var cell;
        var sum = 0;

        this.TotalOcc.forEach((el) => sum += parseFloat(el));
        cell = row.tableCellPerField.get(this.cellFieldMap.occ);
        cell.value = this.TotalOcc.length > 0 ? (parseFloat(sum) / this.TotalOcc.length).toFixed(2) + '%' : '-';
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.rms);
        cell.value = this.TotalRooms;
        cell.isLoading = false;

        sum = 0;
        cell = row.tableCellPerField.get(this.cellFieldMap.adr);
        this.TotalAdr.forEach((el) => sum += parseFloat(el));
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalAdr.length > 0 ? parseFloat(sum) / this.TotalAdr.length : '-',
            Constants.ADR_DIGITS,
            true,
            this.hotel.locale,
            1
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.rev);
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalRev,
            Constants.REVENUE_DIGITS,
            true,
            this.hotel.locale
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.occfc);
        sum = 0;
        this.TotalFcOcc.forEach((el) => sum += parseFloat(el));
        cell.value = this.TotalFcOcc.length > 0 ? (parseFloat(sum) / this.TotalFcOcc.length).toFixed(2) + '%' : '-';
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.roomsfc);
        cell.value = this.TotalFcRooms;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.adrfc);
        sum = 0;
        this.TotalFcAdr.forEach((el) => sum += parseFloat(el));
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalFcAdr.length > 0 ? parseFloat(sum) / this.TotalFcAdr.length : '-',
            Constants.ADR_DIGITS,
            true,
            this.hotel.locale,
            1
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.revfc);
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalFcRev,
            Constants.REVENUE_DIGITS,
            true,
            this.hotel.locale
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.occBu);
        sum = 0;
        this.TotalBudgetOcc.forEach((el) => sum += parseFloat(el));
        cell.value = this.TotalBudgetOcc.length > 0 ? (parseFloat(sum) / this.TotalBudgetOcc.length).toFixed(2) + '%' : '-';
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.roomsBu);
        cell.value = this.TotalBudgetRooms;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.adrBu);
        sum = 0;
        this.TotalBudgetAdr.forEach((el) => sum += parseFloat(el));
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalBudgetAdr.length > 0 ? parseFloat(sum) / this.TotalBudgetAdr.length : '-',
            Constants.ADR_DIGITS,
            true,
            this.hotel.locale,
            1
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.revBu);
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalBudgetRev,
            Constants.REVENUE_DIGITS,
            true,
            this.hotel.locale
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.occstLy);
        sum = 0;
        this.TotalStlyOcc.forEach((el) => sum += parseFloat(el));
        cell.value = this.TotalStlyOcc.length > 0 ? (parseFloat(sum) / this.TotalStlyOcc.length).toFixed(2) + '%' : '-';
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.adrstLy);
        sum = 0;
        this.TotalStlyAdr.forEach((el) => sum += parseFloat(el));
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalStlyAdr.length > 0 ? parseFloat(sum) / this.TotalStlyAdr.length : '-',
            Constants.ADR_DIGITS,
            true,
            this.hotel.locale,
            1
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.revstLy);
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalStlyRev,
            Constants.REVENUE_DIGITS,
            true,
            this.hotel.locale
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.roomsstLy);
        cell.value = this.TotalStlyRooms;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.occaLy);
        sum = 0;
        this.TotalLyOcc.forEach((el) => sum += parseFloat(el));
        cell.value = this.TotalLyOcc.length > 0 ? (parseFloat(sum) / this.TotalLyOcc.length).toFixed(2) + '%' : '-';
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.revaLy);
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalLyRev,
            Constants.REVENUE_DIGITS,
            true,
            this.hotel.locale
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.adraLy);
        sum = 0;
        this.TotalLyAdr.forEach((el) => sum += parseFloat(el));
        cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            this.TotalLyAdr.length > 0 ? parseFloat(sum) / this.TotalLyAdr.length : '-',
            Constants.ADR_DIGITS,
            true,
            this.hotel.locale,
            1
        );
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.roomsaLy);
        cell.value = this.TotalLyRooms;
        cell.isLoading = false;


        cell = row.tableCellPerField.get(this.cellFieldMap.RevStlyRev);
        sum = this.getTotalCellValueFromArray(this.revstlyrev, 'rev');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.AdrStlyAdr);
        sum = this.getTotalCellValueFromArray(this.adrstlyadr, 'adr');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.OccStlyOcc);
        sum = this.getTotalCellValueFromArray(this.OccStlyOcc, 'occ');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaFcBuRooms);
        sum = this.getTotalCellValueFromArray(this.DeltaFcBuRooms, 'rms');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaFcBuOcc);
        sum = this.getTotalCellValueFromArray(this.DeltaFcBuOcc, 'occ');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaFcBuAdr);
        sum = this.getTotalCellValueFromArray(this.DeltaFcBuAdr, 'adr');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaFcBuRev);
        sum = this.getTotalCellValueFromArray(this.DeltaFcBuRev, 'rev');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaLyOtbRev);
        sum = this.getTotalCellValueFromArray(this.DeltaLyOtbRev, 'rev');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaLyOtbAdr);
        sum = this.getTotalCellValueFromArray(this.DeltaLyOtbAdr, 'adr');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaLyOtbOcc);
        sum = this.getTotalCellValueFromArray(this.DeltaLyOtbOcc, 'occ');
        cell.value = sum;
        cell.isLoading = false;

        cell = row.tableCellPerField.get(this.cellFieldMap.DeltaLyOtbRooms);
        sum = this.getTotalCellValueFromArray(this.DeltaLyOtbRooms, 'rms');
        cell.value = sum;
        cell.isLoading = false;
    }

    makeTableRowsListsEmpty() {
        this.revstlyrev = [];
        this.adrstlyadr = [];
        this.OccStlyOcc = [];
        this.DeltaFcBuOcc = [];
        this.DeltaFcBuRooms = [];
        this.DeltaFcBuAdr = [];
        this.DeltaFcBuRev = [];
        this.DeltaLyOtbOcc = [];
        this.DeltaLyOtbRooms = [];
        this.DeltaLyOtbAdr = [];
        this.DeltaLyOtbRev = [];

        /* TOTAL ROW VARIABLES */
        this.TotalOcc = [];
        this.TotalRooms = 0;
        this.TotalAdr = [];
        this.TotalRev = 0;

        this.TotalFcOcc = [];
        this.TotalFcRooms = 0;
        this.TotalFcAdr = [];
        this.TotalFcRev = 0;

        this.TotalBudgetOcc = [];
        this.TotalBudgetRooms = 0;
        this.TotalBudgetAdr = [];
        this.TotalBudgetRev = 0;

        this.TotalStlyOcc = [];
        this.TotalStlyRooms = 0;
        this.TotalStlyAdr = [];
        this.TotalStlyRev = 0;

        this.TotalLyOcc = [];
        this.TotalLyRooms = 0;
        this.TotalLyAdr = [];
        this.TotalLyRev = 0;
    }

    fillRows() {
        if (this.isgroup.appMode === 'single') {
            let startDateEle = document.getElementById('start_date');
            startDateEle.addEventListener('change', () => {
                this.makeTableRowsListsEmpty();
                super.empty();
                this.start_date = startDateEle.value;
                let rows = super.makeTableRows(1);
                let table = super.reflowTable(rows);
                super.domTheTable(table);
                this.fetchnormalmonthsdata();
            });

            let endDateEle = document.getElementById('end_date');
            endDateEle.addEventListener('change', () => {
                this.makeTableRowsListsEmpty();
                super.empty();
                this.end_date = endDateEle.value;
                let rows = super.makeTableRows(1);
                let table = super.reflowTable(rows);
                super.domTheTable(table);
                this.fetchnormalmonthsdata();
            });
            this.fetchnormalmonthsdata();
        }
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !('id' in this.hotel)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            super.empty();
            return;
        }

        $('#start_date').val(this.start_date);
        $('#end_date').val(this.end_date);
        let rows = super.makeTableRows();
        this.fillRows();
        let table = super.reflowTable(rows);
        super.domTheTable(table);
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.AnnualTileSingleTable = AnnualTileSingleTable;
customElements.define('vao-annual-tile-single-table', AnnualTileSingleTable);
