import { AppElement, html } from '../AppElement.js';
import {makeExpediaChannelOptionHtml} from "./ChannelOptionHtmlExpedia";
import {makeBcomChannelOptionHtml} from "./ChannelOptionHtmlBcom";
import {makeUnknownChannelOptionHtml} from "./ChannelOptionHtmlUnknown";
import {makeAgodaChannelOptionHtml} from "./ChannelOptionHtmlAgoda";
import {makeBrandChannelOptionHtml} from "./ChannelOptionHtmlBrand";
import {makePegiPegiChannelOptionHtml} from "./ChannelOptionHtmlPegiPegi";
import {makeTiketChannelOptionHtml} from "./ChannelOptionHtmlTiket";
import {makeTravelokaChannelOptionHtml} from "./ChannelOptionHtmlTraveloka";
import {makeTripadvisorChannelOptionHtml} from "./ChannelOptionHtmlTripadvisor";
import {makeTrivagoChannelOptionHtml} from "./ChannelOptionHtmlTrivago";
import {makeHRSChannelOptionHtml} from "./ChannelOptionHtmlHRS";
import {makeChannelSubOptionHtml} from "./ChannelOptionHtml";

export const EVENT_ON_CHANNEL_SELECT_CHANGE = 'vao-channel-select-on-change';

export default class ChannelSelect extends AppElement {
    static get properties() {
        return {
            selectedChannelId: { type: String },
            channels: { type: Array },
            canIncludeSubChannels: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId || '0';
        this.channels = props.channels || this.channels || null;
        this.canIncludeSubChannels = this.reflowBool('true', this.canIncludeSubChannels);

        this.channelNameToFormatterMap = new Map();
        this.channelNameToFormatterMap.set('expedia', makeExpediaChannelOptionHtml);
        this.channelNameToFormatterMap.set('bcom', makeBcomChannelOptionHtml);
        this.channelNameToFormatterMap.set('unknown', makeUnknownChannelOptionHtml);
        this.channelNameToFormatterMap.set('agoda', makeAgodaChannelOptionHtml);
        this.channelNameToFormatterMap.set('brand', makeBrandChannelOptionHtml);
        this.channelNameToFormatterMap.set('pegipegi', makePegiPegiChannelOptionHtml);
        this.channelNameToFormatterMap.set('tiket', makeTiketChannelOptionHtml);
        this.channelNameToFormatterMap.set('traveloka', makeTravelokaChannelOptionHtml);
        this.channelNameToFormatterMap.set('tripadvisor', makeTripadvisorChannelOptionHtml);
        this.channelNameToFormatterMap.set('trivago', makeTrivagoChannelOptionHtml);
        this.channelNameToFormatterMap.set('hrs', makeHRSChannelOptionHtml);
    }

    reflow(props = {}) {
        this.selectedChannelId = props.selectedChannelId || this.selectedChannelId ;
        this.channels = props.channels || this.channels;
        this.canIncludeSubChannels = this.reflowBool(this.canIncludeSubChannels, props.canIncludeSubChannels);
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('channels') && !Array.isArray(this.channels)) {
            this.fill();
        }
    }

    fill() {
        const cb = (errorMsg, channelPayload) => {
            if (errorMsg) {
                console.error(errorMsg);
                return;
            }
            this.channels = channelPayload.data || [];
        };
        if (this.canIncludeSubChannels === 'true') {
            window.infinito.vao.model.biChannels.fetchIncludingSubChannels(cb);
        } else {
            window.infinito.vao.model.biChannels.fetch(cb);
        }
    }

    _onSelect(e) {
        this.selectedChannelId = e.detail.newValue;
        const event = new CustomEvent(EVENT_ON_CHANNEL_SELECT_CHANGE, {
            detail: {
                newValue: this.selectedChannelId
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    getChannelsIntoOptions() {
        return (this.channels || []).map((channel) => {
            return {
                value: channel.id,
                text: channel.name,
            }
        }).sort((a, b) => (a.text.localeCompare(b.text)));
    }

    getSubChannelParts(channelName) {
        let parts = channelName.split('|');
        parts.shift();
        return parts;
    }

    buildSubChannelOptionFromEl($el, stateText) {
        const parts = this.getSubChannelParts(stateText);
        parts.forEach((part) => {
            let $partEl = $(makeChannelSubOptionHtml(part));
            $el.append($partEl);
        });
        return $el;
    }

    buildChannelOption(makeOptionHtmlFunction, stateText) {
        let $el = $(makeOptionHtmlFunction());
        if (stateText.includes('|')) {
            $el = this.buildSubChannelOptionFromEl($el, stateText);
        }
        return $el;
    }

    optionFormatter(state) {
        const channelName = state.text;
        for (let [mapChannelName, mapChannelFormatter] of this.channelNameToFormatterMap) {
            if (channelName.startsWith(mapChannelName)) {
                return this.buildChannelOption(mapChannelFormatter, channelName);
            }
        }
        return channelName;
    }

    render() {
        const options = this.getChannelsIntoOptions();

        return html`
            <div class="vao__components--channelSelect">
                <vao-select2 
                    .value="${this.selectedChannelId}" 
                    .options="${options}" 
                    @vao-select2-select="${this._onSelect}"
                    .dropdownFormatter="${this.optionFormatter.bind(this)}"
                    .selectionFormatter="${this.optionFormatter.bind(this)}"
                ></vao-select2>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ChannelSelect = ChannelSelect;
customElements.define('vao-channel-select', ChannelSelect);
