import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml } from '../StoryTextElement.js';
import {
    mapForecast,
    mapRoomsPaceAdrPaceComboPNN,
    mapMyPricePacePNN,
    mapCompPricePacePNN,
    mapPositionPNN,
    mapAvailabilityPNN } from '../StoryPitchMappings'

export default class StoryPitch extends AppElement {
    static get properties() {
        return {
            pitch: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.fill();
    }

    empty() {
        this.pitch = Constants.MISSING_STR;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                pickupOffset: this.pickupSetting,
                fields: [
                    modelBiStats.fields.occupancyLeaderCloserTrendProphecy,
                    modelBiStats.fields.roomsOtbLeadSiblingPace,
                    modelBiStats.fields.adrLeadSiblingPace,
                    modelBiStats.fields.rateLeadSiblingPace,
                    modelBiStats.fields.compSetAvgLeadSiblingPace,
                    modelBiStats.fields.priceRankLeadSiblingPace,
                    modelBiStats.fields.ratesPickup
                ]
            }),
            (data, procData) => {
                let occProphecy =
                    ((procData.occupancyLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || null;
                let otbLeadSibPaceVal = ((procData.roomsOtbLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let adrLeadSibPaceVal = ((procData.adrLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let rateLeadSibPaceVal = ((procData.rateLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let compLeadSibPaceVal = ((procData.compSetAvgLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let priceRankSibPaceVal = ((procData.priceRankLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let ratesPu = ((procData.ratesPickup || {})[this.stayDate] || {});
                let str = '';

                if (
                    typeof occProphecy === 'object'
                    && 'leadersClosersProphecy' in occProphecy
                    && $.isNumeric(occProphecy.leadersClosersProphecy)
                ) {
                    let forecast = (window.infinito.vao.controller.utils.interpretOccupancyForecastOutput(
                        occProphecy.leadersClosersProphecy
                    ) || Constants.MISSING_STR);
                    str += mapForecast[forecast] || '';
                }

                if (
                    otbLeadSibPaceVal
                    && typeof otbLeadSibPaceVal === 'object'
                    && 'T0' in otbLeadSibPaceVal
                    && 'setDenseRank' in otbLeadSibPaceVal.T0
                    && 'setDenseLength' in otbLeadSibPaceVal.T0
                    && $.isNumeric(otbLeadSibPaceVal.T0.setDenseRank)
                    && adrLeadSibPaceVal
                    && typeof adrLeadSibPaceVal === 'object'
                    && 'T0' in adrLeadSibPaceVal
                    && 'setDenseRank' in adrLeadSibPaceVal.T0
                    && 'setDenseLength' in adrLeadSibPaceVal.T0
                    && $.isNumeric(adrLeadSibPaceVal.T0.setDenseRank)
                ) {
                    let otbPnn = window.infinito.vao.controller.analyseAttributesHelper.getMyOtbPNNForSetRank(
                        otbLeadSibPaceVal.T0.setDenseRank,
                        otbLeadSibPaceVal.T0.setDenseLength
                    );
                    let adrPnn = window.infinito.vao.controller.analyseAttributesHelper.getMyOtbPNNForSetRank(
                        adrLeadSibPaceVal.T0.setDenseRank,
                        adrLeadSibPaceVal.T0.setDenseLength
                    );
                    let combo = otbPnn + adrPnn;
                    str += mapRoomsPaceAdrPaceComboPNN[combo] || '';
                }

                if (
                    rateLeadSibPaceVal
                    && typeof rateLeadSibPaceVal === 'object'
                    && 'T0' in rateLeadSibPaceVal
                    && 'setDenseRank' in rateLeadSibPaceVal.T0
                    && 'setDenseLength' in rateLeadSibPaceVal.T0
                    && $.isNumeric(rateLeadSibPaceVal.T0.setDenseRank)
                ) {
                    let ratePnn = window.infinito.vao.controller.analyseAttributesHelper.getMyPricePNNForSetRank(
                        rateLeadSibPaceVal.T0.setDenseRank,
                        rateLeadSibPaceVal.T0.setDenseLength
                    );
                    str += mapMyPricePacePNN[ratePnn] || '';
                }

                if (
                    compLeadSibPaceVal
                    && typeof compLeadSibPaceVal === 'object'
                    && 'T0' in compLeadSibPaceVal
                    && 'setDenseRank' in compLeadSibPaceVal.T0
                    && 'setDenseLength' in compLeadSibPaceVal.T0
                    && $.isNumeric(compLeadSibPaceVal.T0.setDenseRank)
                ) {
                    let compPnn = window.infinito.vao.controller.analyseAttributesHelper.getCompPricePNNForSetRank(
                        compLeadSibPaceVal.T0.setDenseRank,
                        compLeadSibPaceVal.T0.setDenseLength
                    );
                    str += mapCompPricePacePNN[compPnn] || '';
                }

                if (
                    priceRankSibPaceVal
                    && typeof priceRankSibPaceVal === 'object'
                    && 'T0' in priceRankSibPaceVal
                    && 'setDenseRank' in priceRankSibPaceVal.T0
                    && 'setDenseLength' in priceRankSibPaceVal.T0
                    && $.isNumeric(priceRankSibPaceVal.T0.setDenseRank)
                ) {
                    let compPnn = window.infinito.vao.controller.analyseAttributesHelper.getPriceRankPNNForSetRank(
                        priceRankSibPaceVal.T0.setDenseRank,
                        priceRankSibPaceVal.T0.setDenseLength
                    );
                    str += mapPositionPNN[compPnn] || '';
                }

                if (ratesPu && typeof ratesPu === 'object') {
                    let res = window.infinito.vao.controller.analyseAttributesHelper
                        .interpretRatesPickupForAvailability(ratesPu);
                    if (res && typeof res === 'object' && 'now' in res) {
                        str += mapAvailabilityPNN[res.now] || '';
                    }
                }

                this.pitch = str || Constants.MISSING_STR;
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyPitch">
    ${storyHtml`
    <p>
       ${this.pitch}
     </p>
    `}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryPitch = StoryPitch;
customElements.define('vao-story-pitch', StoryPitch);
