import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {sendRequest} from "../App.common";
import * as constants from "constants";

export default class NotesModal extends AppElement {
    static get properties() {
        return {
            populateTabsString: { type: String }

        };
    }
    
    constructor(props = {}) {
        super();
        this.populateTabsString ='';
        this.stayDate = this.getAttribute("stayDate");
        this.hotel_id = this.getAttribute("hotel_id");
        this.rowCells = this.getAttribute("rowCells");
    }

    //HTML
    htmlPopulateTabsString(){
        return html`<div class="col-sm-12 col-lg-12 biggy-small">
            <section id="vao__flash--topTen" class="no-bor-small">
                <div id="vao__notes--list">
                    
                </div>
                <div>
                    <input type="text" id="notes_text" class="form-control" placeholder="Type Your Notes" />
                    <vao-button 
                            text="Save" 
                            @click="${this.onClickSaveNotes.bind(this,this.stayDate,this.hotel_id)}" 
                            style="float: right;margin-top: 14px;"
                    ></vao-button>
                </div>
            </section>
        </div>`;
    }

    onClickSaveNotes(e,stayDate,hotel_id){
        console.log(this.rowCells);

        let dataObj = {
            //'hotel_id': this.hotel.id,
            'hotel_id': this.hotel_id,
            'stayDate': this.stayDate,
            'text_comment': document.getElementById("notes_text").value,
            'op': 'UpdateNotesDetails',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            document.getElementById("vao__notes--list").innerHTML = '';
            document.getElementById("notes_text").value = '';
            flag.renderDataonPopup();
        });

    }

    //DATA FUNCTIONS STARTED
    renderDataonPopup(){

        let dataObj = {
            //'hotel_id': this.hotel.id,
            'hotel_id': this.hotel_id,
            'stayDate': this.stayDate,
            'op': 'getNotesDetails',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            let notestext = jsonObj.data;
            let htmltext = '';
            if(document.getElementById(flag.stayDate)){
                document.getElementById(flag.stayDate).innerHTML = notestext.length > 0 ? notestext.length : Constants.MISSING_STR;
            }

            document.getElementById("vao__notes--list").innerHTML = '';
            for(var i=0;i<notestext.length;i++){
                var resolvedhtml = '';
                let notesstatus = '';
                if(notestext[i].is_resolved == 0){
                    notesstatus = '<span style="margin-left: 9px;color: #dc3545;border: solid 1px #dc3545;padding: 2px 6px;border-radius: 8px;">Unresolved</span>';
                    resolvedhtml = resolvedhtml+'<button type="button" id="resolvedbtn_' + notestext[i].id + '" data-id="' + notestext[i].id + '" class="vao__component--btnresolve vao__components--button btn btn-invert-warning btn-sm start-icon" style="float: right;display: initial;color:#6b6668;" data-trigger="hover focus" title="Resolved">\n' +
                        '<div class="vao__components--button-startIcon">' +
                        '<vao-icon id="__vao-appelement__112" cls="fas fa-tasks">' +
                        '<i class="vao__components--icon fas fa-tasks"></i>' +
                        '</vao-icon>' +
                        '</div>' +
                        '</button>';
                }else{
                    notesstatus = '<span style="margin-left: 9px;color: #ffc107;border: solid 1px #ffc107;padding: 2px 6px;border-radius: 8px;">Resolved</span>';
                    resolvedhtml = resolvedhtml+'<button type="button" id="resolvedbtn_' + notestext[i].id + '" data-id="' + notestext[i].id + '" class="vao__component--btnunresolve vao__components--button btn btn-invert-danger btn-sm start-icon" style="float: right;display: initial;color:#6b6668;" data-trigger="hover focus" title="Unresolved">\n' +
                        '<div class="vao__components--button-startIcon">' +
                        '<vao-icon id="__vao-appelement__112" cls="fas fa-times-circle">' +
                        '<i class="vao__components--icon fas fa-times-circle"></i>' +
                        '</vao-icon>' +
                        '</div>' +
                        '</button>';
                }

                htmltext = htmltext+'<div id="notes_row_'+notestext[i].id+'"><textarea style="width: -webkit-fill-available;" class="form-control form-control-sm width-auto" id="edit_'+notestext[i].id+'" >'+notestext[i].notes_text+'</textarea><span style="margin-left:5px;">- '+notestext[i].stayDate+'</span>' +
                    '<span  id="savedmsg_'+notestext[i].id+'"></span>' +
                    notesstatus +
                    '<input type="hidden" id="oldedit_'+notestext[i].id+'" value="'+notestext[i].notes_text+'"/>' +
                    '<button type="button" id="savebtn_' + notestext[i].id + '" data-id="' + notestext[i].id + '" class="vao__component--btnedit vao__components--button btn btn-invert-primary btn-sm start-icon" style="float: right;display: initial;color:#6b6668;" data-trigger="hover focus" title="Save">\n' +
                    '<div class="vao__components--button-startIcon">' +
                    '<vao-icon id="__vao-appelement__112" cls="fas fa-save">' +
                    '<i class="vao__components--icon fas fa-save"></i>' +
                    '</vao-icon>' +
                    '</div>' +
                    '</button>' +
                    resolvedhtml+
                    '<button type="button" id="deletebtn_' + notestext[i].id + '" data-id="' + notestext[i].id + '" class="vao__component--btndelete vao__components--button btn btn-invert-danger btn-sm start-icon" style="float: right;display: initial;color:#6b6668;" data-trigger="hover focus" title="Delete">\n' +
                    '<div class="vao__components--button-startIcon">' +
                    '<vao-icon id="__vao-appelement__112" cls="fas fa-trash-alt">' +
                    '<i class="vao__components--icon fas fa-trash-alt"></i>' +
                    '</vao-icon>' +
                    '</div>' +
                    '</button>' +
                    '</div><hr>';
            }
            /*vao__notes--list*/
            document.getElementById("vao__notes--list").innerHTML = htmltext;
            $(".vao__component--btnedit").on('click',function(e){
                var id = $(e.target).closest('button').data('id');
                var notestext = $('#edit_'+id).val();
                var notestext_old = $('#oldedit_'+id).val();
                if(notestext_old != notestext){
                    flag.fn_Saveeditednote(id,notestext);
                }

            });

            $(".vao__component--btnresolve").on('click',function(e){
                var id = $(e.target).closest('button').data('id');
                flag.fn_Marknotesasresolved(id);
            });

            $(".vao__component--btnunresolve").on('click',function(e){
                var id = $(e.target).closest('button').data('id');
                flag.fn_Marknotesasunresolved(id);
            });

            $(".vao__component--btndelete").on('click',function(e){
                var id = $(e.target).closest('button').data('id');
                flag.fn_Removenotes(id);
            });
        });
    }

    /* Delete Notes */
    fn_Removenotes(note_id){
        let dataObj = {
            'note_id': note_id,
            'op': 'removeNotes',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            document.getElementById("savedmsg_"+note_id).innerHTML = '<span style="margin-left: 9px;color: #dc3545;border: solid 1px #dc3545;padding: 2px 6px;border-radius: 8px;">Removed</span>';
            setTimeout(function(){
                flag.renderDataonPopup();
                document.getElementById("savedmsg_"+note_id).innerHTML = '';
            }, 1500);
        });
    }

    /* Marke as Resolved */
    fn_Marknotesasresolved(note_id){
        let dataObj = {
            'note_id': note_id,
            'op': 'marketNotesResolved',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            flag.renderDataonPopup();
            document.getElementById("savedmsg_"+note_id).innerHTML = '';
            // document.getElementById("savedmsg_"+note_id).innerHTML = '<span style="margin-left: 9px;color: #ffc107;border: solid 1px #ffc107;padding: 2px 6px;border-radius: 8px;">Resolved</span>';
            // setTimeout(function(){
            //
            // }, 1500);
        });
    }

    fn_Marknotesasunresolved(note_id){
        let dataObj = {
            'note_id': note_id,
            'op': 'marketNotesUnresolved',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            flag.renderDataonPopup();
            document.getElementById("savedmsg_"+note_id).innerHTML = '';
            // document.getElementById("savedmsg_"+note_id).innerHTML = '<span style="margin-left: 9px;color: #dc3545;border: solid 1px #dc3545;padding: 2px 6px;border-radius: 8px;">Unresolved</span>';
            // setTimeout(function(){
            //
            // }, 1500);
        });
    }

    /* Save the edited text */
    fn_Saveeditednote(note_id,newtext){
        let dataObj = {
            'newtext': newtext,
            'note_id': note_id,
            'op': 'SaveNotesDetails',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {

            document.getElementById("savedmsg_"+note_id).innerHTML = '<span style="margin-left: 9px;color: green;border: solid 1px green;padding: 2px 6px;border-radius: 8px;">Saved</span>';

            setTimeout(function(){
                document.getElementById("savedmsg_"+note_id).innerHTML = '';
            }, 1500);
        });
    }

    render() {

        this.populateTabsString = this.htmlPopulateTabsString();

        return html`
        <div>${this.populateTabsString}</div>
        `;
    }

    firstUpdated(changedProperties) {
        this.renderDataonPopup();

    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktop = NotesModal;
customElements.define('vao-modal-notes-modal', NotesModal);