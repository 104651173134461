import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {Throttler} from "../Throttler";
import {makeEvolutionDateStrArray} from "../EvolutionUtils";
import ColorArray from "../ColorArray";
import {calcRateObjsAvgRate, filterRateObjs} from "../RateUtils";

const avgStr = 'Average';

export default class RateEvolutionLeadTimeCompareChart extends AppElement {
    static get properties() {
        return {
            property: { type: Object },
            stayDate: { type: String },
            snapshotDate: { type: String },
            evolutionRange: { type: Number },
            historicRange: { type: Number },
            them: { type: String },
            compSet: { type: Set },
            meSeriesState: { type: String },
            compSeriesState: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.property = props.property || {};
        this.stayDate = props.stayDate || null;
        this.snapshotDate = props.snapshotDate || null;
        this.evolutionRange = props.evolutionRange || null;
        this.historicRange = props.historicRange || null;
        this.compClass="vao__components--rateEvolutionLeadTimeCompareChart";
        this.chartId = AppElement.getUniqueElementId();
        this.throttler = new Throttler(1, 0);
        this.configs = new Map();
        this.them = props.them || avgStr;
        this.compSet = props.compSet || new Set();
        this.meSeriesState = props.meSeriesState || 'true';
        this.compSeriesState = props.compSeriesState || 'true';
    }

    reflow(props = {}) {
        this.property = props.property || this.property;
        this.stayDate = props.stayDate || this.stayDate;
        this.snapshotDate = props.snapshotDate || this.snapshotDate;
        this.evolutionRange = props.evolutionRange || this.evolutionRange;
        this.historicRange = props.historicRange || this.historicRange;
        this.throttler.emptyQueue();
        this.configs = new Map();
        this.them = props.them || this.them;
        this.meSeriesState = props.meSeriesState || this.meSeriesState;
        this.compSeriesState = props.compSeriesState || this.compSeriesState;
        this.fill();
    }

    chartOpts() {
        let leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
            this.stayDate,
            this.snapshotDate
        );
        let minX = 0;
        let maxX = this.evolutionRange;
        let self = this;

        return {
            title: {
                text: 'Rate DOW Evolution'
            },
            credits: {
                enabled: false
            },
            chart: {
                spacingTop: 20,
                spacingBottom: 20,
                height: this.chartHeight
            },
            lang: {
                noData: 'No data to display'
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            },
            xAxis: {
                type: 'linear',
                title: {
                    text: 'Lead time'
                },
                min: minX,
                max: maxX,
                reversed: true,
                plotLines: [{
                    color: '#d3d3d3',
                    width: 1,
                    value: leadTime,
                    dashStyle: 'Dash',
                    label: {
                        text: 'Today'
                    }
                }]
            },
            yAxis: [
                {
                    gridLineWidth: 0,
                    lineWidth: 1,
                    opposite: true,
                    title: {
                        text: 'Rate'
                    },
                    labels: {
                        format: '{value}'
                    },
                    endOnTick: false,
                    maxPadding: 0.02
                }
            ],
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                labelFormatter: function labelFormatter() {
                    return this.name;
                },
                itemMarginBottom: 5,
                maxHeight: 80
            },
            plotOptions: {
                series: {
                    events: {},
                    connectNulls: true,
                    label: {
                        connectorAllowed: false
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                },
                column: {
                    // Limit the maximum column width
                    maxPointWidth: 15
                }
            },
            tooltip: {
                shared: true,
                split: false,
                enabled: true,
                formatter: function formatter() {
                    return this.points.reduce(function reduce(s, point) {
                        let seriesName = point.series.name;
                        let configKey = seriesName.replace('(me)', '').replace('(comp)', '').trim();
                        let config = self.configs.get(configKey);
                        let y = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            point.y,
                            Constants.RATE_DIGITS,
                            true,
                            self.property.locale
                        );
                        let asOf = '-';
                        if (config) {
                            asOf = window.infinito.vao.controller.dateHelper.getShortDateMonString(
                                window.infinito.vao.controller.dateHelper.subtractDaysFromDateString(
                                    config.stayDate,
                                    point.x
                                )
                            );
                        }

                        return s + '<br/>'
                            + '<span style="color:' + point.color + '">\u25CF</span> '
                            + seriesName + ': <b>' + y + '</b>'
                            + '<span style="font-size:.7rem;">' + ' (as of ' + asOf + ')</span>';
                    }, 'Lead time ' + this.x + '<br/><hr><br/>');
                }
            },
            series: [],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };
    }

    empty() {
        let chart = this.getChart();
        if (!chart) {
            return;
        }
        while (chart.series.length) {
            chart.series[0].remove();
        }
    }

    getChart() {
        if (this.chart) {
            return this.chart;
        }
        if (document.getElementById(this.chartId) === null) {
            // wait until its in the DOM or else highcharts will error
            return null;
        }
        this.chart = window.Highcharts.chart(this.chartId, this.chartOpts());
        return this.chart;
    }

    getRequiredAPIRecordDateForStayDate(stayDate) {
        // Interpreting the result:
        // 0 means the stayDate === snapshotDate
        // 1 means the stayDate is BEFORE the snapshotDate
        // -1 means the stayDate is AFTER the snapshotDate
        let compare = window.infinito.vao.controller.dateHelper.compareDateStrs(this.snapshotDate, stayDate);

        // For the current date or future dates, use the current snapshotDate.
        if (compare <= 0) {
            return this.snapshotDate;
        }

        // For any stay dates that have already closed, gets the data as of that stay date.
        return stayDate;
    }

    makeSeriesData(valObj, valLeadTime) {
        let val;
        let _valObj;
        if (!valObj || typeof valObj !== 'object') {
            _valObj = {};
        } else {
            _valObj = valObj;
        }
        if ($.isNumeric(_valObj.rate)) {
            val =  parseFloat(_valObj.rate);
        } else {
            val = null;
        }
        if (val === 0) {
            val = null;
        }
        return {
            y: val,
            x: valLeadTime
        };
    }

    initConfigs() {
        const biStatistics = window.infinito.vao.model.biStatistics;
        this.configs = new Map();
        const makeNameForSeries = (stayDate) => {
            return window.infinito.vao.controller.dateHelper.getShortDayDateMonString(stayDate);
        };
        const colModifier = (hex, index) => {
            try {
                return ColorArray.hexToRGBA(hex, (1 - (index / 10)))
            } catch (e) {
                return null;
            }
        };
        const colorArray = new ColorArray(null, null, null, 0, colModifier);

        for (let i = 0; i <= this.historicRange; i++) {
            const color = colorArray.next().color;
            const config = {};
            const subbedStayDate = window.infinito.vao.controller.dateHelper.subtractDaysFromDateString(
                this.stayDate,
                (i * 7)
            );
            if (!window.infinito.vao.controller.dateHelper.isDateStr(subbedStayDate)) {
                continue;
            }
            const requiredRecordDate = this.getRequiredAPIRecordDateForStayDate(subbedStayDate);
            const seriesName = makeNameForSeries(subbedStayDate);
            config['procData'] = null;
            config['stayDate'] = subbedStayDate;
            config['recordDate'] = requiredRecordDate;
            config['evolutionRange'] = this.evolutionRange;
            config['biQuery'] = biStatistics.buildQuery({
                recordDate: requiredRecordDate,
                firstStayDate: subbedStayDate,
                lastStayDate: subbedStayDate,
                fields: [
                    biStatistics.fields.allRatesEvolution
                ],
                evolutionRange: this.evolutionRange
            });
            config['seriess'] ={
                'me': {
                    color: color,
                    index: i,
                    seriesName: seriesName,
                    name: seriesName + ' (me)',
                    data: [],
                    yAxis: 0,
                    type: 'spline',
                    dashStyle: 'Solid',
                    zIndex: ((this.historicRange - i) + 1),
                    lineWidth: 2,
                    marker: {
                        enabled: false
                    }
                },
                'them': {
                    color: color,
                    index: i,
                    seriesName: seriesName,
                    name: seriesName + ' (comp)',
                    data: [],
                    yAxis: 0,
                    type: 'spline',
                    dashStyle: 'ShortDash',
                    zIndex: ((this.historicRange - i) + 1),
                    lineWidth: 1,
                    marker: {
                        enabled: false
                    }
                }
            };
            this.configs.set(seriesName, config);
        }

        return this.configs;
    }

    fillBI() {
        let biStatistics = window.infinito.vao.model.biStatistics;

        let chart = this.getChart();
        if (!chart) {
            return;
        }
        this.empty();
        let promises = [];
        chart.showLoading();
        this.disableChange();

        this.configs.forEach(config => {
            promises.push(new Promise((apiFillDone) => {
                this.throttler.queueUp((throttleDone) => {
                    let cb = (data, procData) => {
                        if (!procData || typeof procData !== 'object') {
                            procData = {};
                        }
                        if (config.procData === null) {
                            config.procData = procData;
                        }
                        let configStayDate = config.stayDate;
                        let evolutionVal = ((procData.allRatesEvolution || {})[configStayDate] || {}).value || {};
                        let evolutionDates = makeEvolutionDateStrArray(
                            config.recordDate,
                            config.evolutionRange
                        );

                        let meSeries = config.seriess.me;
                        let themSeries = config.seriess.them;

                        if (evolutionVal && typeof evolutionVal === 'object') {
                            evolutionDates.reverse().forEach((evdate) => {
                                let evolutionDateData = evolutionVal[evdate];

                                if (
                                    !evolutionDateData
                                    || typeof evolutionDateData !== 'object'
                                    || !('leadTime' in evolutionDateData)
                                ) {
                                    return;
                                }

                                let evolutionLeadTime = evolutionDateData.leadTime;

                                try {
                                    let filteredAllRates = filterRateObjs(evolutionDateData.rateObjs);

                                    let didAdd = false;
                                    filteredAllRates.comps.forEach(compRateObj => {
                                        if (
                                            typeof compRateObj.propertyName === 'string'
                                            && !this.compSet.has(compRateObj.propertyName)
                                        ) {
                                            this.compSet.add(compRateObj.propertyName);
                                            didAdd = true;
                                        }
                                    });
                                    if (didAdd) {
                                        this.compSet = new Set(this.compSet);
                                    }

                                    let meSeriesData = this.makeSeriesData(
                                        filteredAllRates.me[0],
                                        evolutionLeadTime
                                    );
                                    if (meSeriesData) {
                                        meSeries.data.push(meSeriesData);
                                    }

                                    let themSeriesData;
                                    if (this.them === avgStr) {
                                        themSeriesData = this.makeSeriesData({
                                            rate: calcRateObjsAvgRate(filteredAllRates.comps)
                                        }, evolutionLeadTime);
                                    } else {
                                        let match = filteredAllRates.comps.find(compRateObj => {
                                            return compRateObj.propertyName === this.them;
                                        });
                                        if (match) {
                                            themSeriesData = this.makeSeriesData(match, evolutionLeadTime);
                                        }
                                    }
                                    if (themSeriesData) {
                                        themSeries.data.push(themSeriesData);
                                    }
                                } catch (e) {
                                    console.error(e);
                                }
                            });
                        }

                        chart.addSeries(meSeries, false);
                        chart.addSeries(themSeries, false);
                        throttleDone();
                        apiFillDone();
                    };

                    config.seriess.me.data = [];
                    config.seriess.them.data = [];

                    if (config.procData !== null) {
                        cb(null, config.procData);
                    } else {
                        biStatistics.fetchStatistics(
                            this.property.id,
                            config.biQuery,
                            cb
                        );
                    }
                });
            }));
        });

        Promise.all(promises).then(() => {}).catch(() => {}).finally(() => {
            chart.hideLoading();
            chart.redraw();
            this.handleMeState();
            this.handleCompState();
            this.enableChange();
        });
    }

    fill() {
        if (
            !this.property
            || typeof this.property !== 'object'
            || !('id' in this.property)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.snapshotDate)
            || !$.isNumeric(this.evolutionRange)
            || this.evolutionRange < 0
            || this.evolutionRange > 120
            || !$.isNumeric(this.historicRange)
            || this.historicRange < 0
            || this.historicRange > 5
            ||  window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                this.stayDate,
                this.snapshotDate
            ) > 120
        ) {
            this.empty();
            return;
        }

        this.initConfigs();
        this.fillBI();
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties instanceof Map) {
            let themChange = _changedProperties.get('them');
            let evoRangeChange = _changedProperties.get('evolutionRange');
            if (evoRangeChange) {
                let chart = this.getChart();
                if (chart) {
                    this.empty();
                    chart.update({
                        xAxis: {
                            max: this.evolutionRange
                        }
                    });
                }
                this.fill();
            } else if (themChange) {
                this.fillBI();
            }
        }
    }

    renderCompSelectOptions() {
        return html`
            ${[...this.compSet].map((compTxt) => {
                return html`<option value="${compTxt}">${compTxt}</option>`;  
            })}
        `;
    }

    disableChange() {
        let $optWrapper = $(this).find('.' + this.compClass + '-opts');
        $optWrapper.find('select').attr('disabled', true);
        $optWrapper.find('button').attr('disabled', true);
    }

    enableChange() {
        let $optWrapper = $(this).find('.' + this.compClass + '-opts');
        $optWrapper.find('select').attr('disabled', false);
        $optWrapper.find('button').attr('disabled', false);
    }

    onCompSelectChange(e) {
        this.them = e.currentTarget.value;
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        let chart = this.getChart();
        if (chart) {
            // https://api.highcharts.com/class-reference/Highcharts.Chart#destroy
            chart.destroy();
        }
    }

    onCLickRange(e) {
        let newEvoRange = e.currentTarget.value;
        if ($.isNumeric(newEvoRange)) {
            this.evolutionRange = newEvoRange;
        }
    }

    toggleAllChartSeriesThatInclude(str, type) {
        let chart = this.getChart();
        if (
            chart
            && typeof chart === 'object'
            && 'series' in chart
            && Array.isArray(chart.series)
            && chart.series.length > 0
        ) {
            chart.series.forEach(series => {
                if (
                    !series
                    || typeof series !== 'object'
                    || !('name' in series)
                    || typeof series.name !== 'string'
                ) {
                    return;
                }
                if (series.name.includes(str)) {
                    if (type === 'hide') {
                        series.hide();
                    } else if (type === 'show') {
                        series.show();
                    }
                }
            });
        }
    }

    handleMeState() {
        if (this.meSeriesState === 'false') {
            this.toggleAllChartSeriesThatInclude('me', 'hide');
        } else {
            this.toggleAllChartSeriesThatInclude('me', 'show');
        }
    }

    handleCompState() {
        if (this.compSeriesState === 'false') {
            this.toggleAllChartSeriesThatInclude('comp', 'hide');
        } else {
            this.toggleAllChartSeriesThatInclude('comp', 'show');
        }
    }

    handleMeSeriesClick() {
        let state = this.meSeriesState === 'true' || this.meSeriesState === true;
        this.meSeriesState = String(!state);
        this.handleMeState();
    }

    handleCompSeriesClick() {
        let state = this.compSeriesState === 'true' || this.compSeriesState === true;
        this.compSeriesState = String(!state);
        this.handleCompState();
    }

    renderMeSeriesBtn() {
        return html`
        <vao-button
            tooltip="Toggle all ME series lines"
            variant="switch"
            .activeState="${this.meSeriesState === true || this.meSeriesState === 'true'}"
            size="small"
            color="light"
            text="ME"
            fontWeight="600"
            @click="${this.handleMeSeriesClick}">
        </vao-button>`;
    }

    renderCompSeriesBtn() {
        return html`
        <vao-button
            tooltip="Toggle all COMP series lines"
            variant="switch"
            .activeState="${this.compSeriesState === true || this.compSeriesState === 'true'}"
            size="small"
            color="light"
            text="COMP"
            fontWeight="600"
            @click="${this.handleCompSeriesClick}">
        </vao-button>`;
    }

    render() {
        return html`
<div class="${this.compClass}">
    <div class="${this.compClass + '-opts'} row no-gutters" style="align-items:center;">
        <div class="col-sm-5" style="display:flex;flex-wrap:wrap;justify-content:space-between;">
            <vao-button-group 
            size="small"
            .slot=${
                [14, 30, 60, 120].map(evoRange => {
                    return html`
                            <button 
                            @click="${this.onCLickRange.bind(this)}" 
                            type="button" 
                            class="btn btn-light ${parseInt(this.evolutionRange) === evoRange ? 'active' : ''}" 
                            value="${evoRange}">
                                ${evoRange}
                            </button>`;
                })
            }>
            </vao-button-group>
            <div style="
            display: flex;
            flex: 1;
            justify-content: center;
            font-weight: 700;
            position: relative;
            font-size: .8rem;">
                ${this.renderMeSeriesBtn()}
                ${this.renderCompSeriesBtn()}
            </div>
        </div>
        <div class="form-group col-sm-7">
            <select @change="${this.onCompSelectChange}" class="form-control form-control-sm" value="${this.them}">
                <option value="${avgStr}">${avgStr}</option>
                ${this.renderCompSelectOptions()}
            </select>
        </div>
    </div>
    <div id="${this.chartId}"></div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.RateEvolutionLeadTimeCompareChart = RateEvolutionLeadTimeCompareChart;
customElements.define('vao-rate-evolution-lead-time-compare-chart', RateEvolutionLeadTimeCompareChart);