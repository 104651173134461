import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class Button extends AppElement {
    static get properties() {
        return {
            text: { type: String },
            startIcon: { type : String},
            endIcon: { type : String},
            tooltip: { type: String },
            tooltipTrigger: { type: String },
            variant: { type: String }, // contained or outlined or switch or invert
            color: { type: String }, // primary, secondary, ...
            size: { type: String }, // small, medium, large, xs, xxs
            activeState: { type: String }, // use string type because Boolean has quirks
            disabledState: { type: String }, // use string type because Boolean has quirks
            fontWeight: { type: Number }
        };
    }

    /**
     * It is possible that the @click listener on the custom element <vao-button> will fire when disabled. Use this
     * function to check the event.
     * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/disabled
     * @param e
     * @returns boolean
     */
    static isEventDisabled(e) {
        return (
            e.target.disabled
            || e.currentTarget.disabled
            || e.target.disabledState === 'true'
            || e.currentTarget.disabledState === 'true'
            || e.target.disabledState === true
            || e.currentTarget.disabledState === true
        );
    }

    constructor(props = {}) {
        super();
        this.text = props.text || '';
        this.startIcon = props.startIcon || null;
        this.endIcon = props.endIcon || null;
        this.tooltip = props.tooltip || null;
        this.tooltipTrigger = props.tooltipTrigger;
        this.variant = props.variant || 'contained';
        this.color = props.color || 'primary';
        this.size = props.size || 'medium';
        this.activeState = props.activeState || false;
        this.disabledState = props.disabledState || false;
        this.onClick = props.onClick;
        this.fontWeight = props.fontWeight || null;
        this.compClass="vao__components--button";
        this.id = AppElement.getUniqueElementId();
    }

    _onClick() {
        if (typeof this.onClick === 'function') {
            this.onClick();
        }
    }

    determineBtnColCls() {
        if (this.variant === 'outline') {
            return 'btn-outline-' + this.color;
        } else if (this.variant === 'switch') {
            return 'btn-switch btn-switch-' + this.color;
        } else if (this.variant === 'invert') {
            return 'btn-invert-' + this.color;
        } else {
            return 'btn-' + this.color;
        }
    }

    determineBtnSizeCls() {
        if (this.size === 'small') {
            return 'btn-sm';
        } else if (this.size === 'large') {
            return 'btn-lg';
        } else if (this.size === 'xs') {
            return 'btn-xs';
        } else if (this.size === 'xxs') {
            return 'btn-xxs';
        } else {
            return '';
        }
    }

    determineRootClass() {
        return `${this.compClass}  
        btn 
        ${this.determineBtnColCls()}  
        ${this.determineBtnSizeCls()}  
        ${(this.activeState === 'true' || this.activeState === true) ? 'active' : ''} 
        ${this.startIcon ? 'start-icon' : ''}
        ${this.text ? 'text' : ''}
        ${this.endIcon ? 'end-icon' : ''}
        `;
    }

    determineRootStyle() {
        return `
            ${$.isNumeric(this.fontWeight) ? 'font-weight:' + this.fontWeight : ''}
        `;
    }

    render() {
        return html`
<button 
type="button" 
class="${this.determineRootClass()}" 
style="${this.determineRootStyle()}" 
data-toggle="tooltip" 
data-placement="bottom"
data-original-title 
data-trigger="${this.tooltipTrigger || (Constants.TOOLTIP.TRIGGERS.HOVER + ' ' + Constants.TOOLTIP.TRIGGERS.FOCUS)}"
title="${this.tooltip || ''}" 
@click="${this._onClick}"
?disabled=${(this.disabledState === 'true' || this.disabledState === true)}>
    <div class="${this.compClass + '-startIcon'}">
        ${this.startIcon ? html`<vao-icon cls="${this.startIcon}"></vao-icon>` : html``}
    </div>
    <div class="${this.compClass + '-text'}">
        ${this.text ? html`<span>${this.text}</span>`: html``}
    </div>
    <div class="${this.compClass + '-endIcon'}">
        ${this.endIcon ? html`<vao-icon cls="${this.endIcon}"></vao-icon>` : html``}
    </div>
</button>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Button = Button;
customElements.define('vao-button', Button);
