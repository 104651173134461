import {AppElement, html} from '../AppElement.js';
import {OPTION_30_DAYS} from "../dateSelect/StayDateRangeSelect";
import {PROPERTY_METRIC_MODE_OCCUPANCY, PROPERTY_METRIC_MODE_ROOMS} from "./PageDesktopGroupTable";
import Constants from "../Constants";

export default class PageDesktopGroup extends AppElement {
    static get properties() {
        return {
            group: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },

            // @internalProperty
            rangeValue: { attribute: false },
            startDate: { attribute: false },
            endDate: { attribute: false },
            propertyMetricMode: { attribute: false },
        };
    }

    constructor(props = {}) {
        super();
        this.group = props.group || this.group || window.infinito.vao.controller.storageHelper.getSelectedGroup();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );

        // @internalProperty
        this.rangeValue = OPTION_30_DAYS;
        this.startDate = props.startDate || this.recordDate;
        this.endDate = props.endDate
            || window.infinito.vao.controller.dateHelper.addDaysToDateString(this.startDate, 30);
        this.propertyMetricMode = PROPERTY_METRIC_MODE_ROOMS;
    }

    reflow(props = {}) {
        this.group = props.group || this.group;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    onChangeStayDateRange(e) {
        this.rangeValue = e.detail.newValue;
        this.startDate = e.detail.rangeData.startDate;
        this.endDate = e.detail.rangeData.endDate;
    }

    onChangePropertyMetricMode(e) {
        this.propertyMetricMode = e.currentTarget.value;
    }

    getPropertyMetricModeOptions() {
        return [
            {
                value: PROPERTY_METRIC_MODE_ROOMS,
                text: Constants.STRINGS.ROOMS
            },
            {
                value: PROPERTY_METRIC_MODE_OCCUPANCY,
                text: Constants.STRINGS.OCC
            },
        ];
    }

    render() {
        return html`
<div class="vao__components--pageDesktopGroup">
    <div class="rounded border bg-white">
        <div 
            class="vao__components--pageDesktopGroup-filters" 
            style="display:flex;align-items:center;flex-wrap:wrap;padding: 1rem 1rem 0 1rem;"
        >
            <vao-stay-date-range-select 
                .recordDate=${this.recordDate}
                .value=${this.rangeValue}
                @vao-stay-date-range-select-change="${this.onChangeStayDateRange}"
            ></vao-stay-date-range-select>
            <div style="width:1rem;"></div>
            <vao-select 
                .value="${this.propertyMetricMode}" 
                .options="${this.getPropertyMetricModeOptions()}" 
                @change="${this.onChangePropertyMetricMode}"
            ></vao-select>
        </div>
        <hr>
        <div style="margin-top:.5rem;">
            <vao-page-desktop-group-table
                .group="${this.group}"
                .recordDate="${this.recordDate}"
                .pickupSetting="${this.pickupSetting}"
                .propertyMetricMode="${this.propertyMetricMode}"
                startDate="${this.startDate}"
                endDate="${this.endDate}"
            ></vao-page-desktop-group-table>
        </div>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktopGroup = PageDesktopGroup;
customElements.define('vao-page-desktop-group', PageDesktopGroup);
