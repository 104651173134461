import { AppElement, html } from '../AppElement.js';
import {isValidCtxStatisticAndHasData, isValidNumericStatistic} from "../StatisticUtils";
import Constants from "../Constants";

export default class MetricBreakdownStaleBadge extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            stayDate: { type: String },
            recordMatcher: { type: String },
            badgeCls: { type: String },
            badgeTxt: { type: String },
            canShowNoData: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.hotel = props.hotel || {};
        this.recordDate = props.recordDate;
        this.stayDate = props.stayDate;
        this.recordMatcher = props.recordMatcher;
        this.badgeCls = props.badgeCls || '';
        this.badgeTxt = props.badgeTxt || '';
        this.canShowNoData = super.reflowBool('true', props.canShowNoData);

        this.dateHelper = window.infinito.vao.controller.dateHelper;
        this.biStatistics = window.infinito.vao.model.biStatistics;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.recordMatcher = props.recordMatcher || this.recordMatcher;
        this.badgeCls = typeof props.badgeCls === 'string' ? props.badgeCls : this.badgeCls;
        this.badgeTxt = typeof props.badgeTxt === 'string' ? props.badgeTxt : this.badgeTxt;
        this.canShowNoData = super.reflowBool(this.canShowNoData, props.canShowNoData);
    }

    canFill() {
        return (
            this.hotel
            && typeof this.hotel === 'object'
            && ('id' in this.hotel)
            && this.dateHelper.isDateStr(this.recordDate)
            && this.dateHelper.isDateStr(this.stayDate)
            && (this.recordMatcher in this.biStatistics.recordMatchers)
        );
    }

    makeCtxQuery() {
        return this.biStatistics.buildQuery({
            recordDate: this.recordDate,
            firstStayDate: this.stayDate,
            lastStayDate: this.stayDate,
            fields: [
                this.biStatistics.fields.roomsOtbCtx,
            ],
            recordMatcher: this.recordMatcher
        });
    }

    // Notice the absence of the recordMatcher here.
    makeNonCtxQuery() {
        return this.biStatistics.buildQuery({
            recordDate: this.recordDate,
            firstStayDate: this.stayDate,
            lastStayDate: this.stayDate,
            fields: [
                this.biStatistics.fields.roomsOtb,
            ]
        });
    }

    updateStateFromReceivedTimestamps(receivedTs, ctxReceivedTs) {
        if (receivedTs && ctxReceivedTs) {
            const timeDiff = this.dateHelper.calcAndFormatTimeBetween(
                receivedTs,
                ctxReceivedTs
            );

            if (this.dateHelper.isFormattedTimeBetweenEmpty(timeDiff)) {
                this.badgeTxt = 'up to date';
                this.badgeCls = 'badge-primary';
            } else {
                this.badgeTxt = `${timeDiff} ago`;
                this.badgeCls = 'badge-warning';
            }
        } else {
            if (this.canShowNoData === 'true') {
                this.badgeTxt = Constants.STRINGS.NO_DATA.toLowerCase();
                this.badgeCls = 'badge-danger';
            }
        }
    }

    fill() {
        if (!this.canFill()) {
            return;
        }

        this.biStatistics.fetchStatistics(
            this.hotel.id,
            this.makeCtxQuery(),
            (data, procData) => {
                const _procData = (procData || {});
                const roomsOtbCtx = ((_procData.roomsOtbCtx || {})[this.stayDate] || {});

                if (!isValidCtxStatisticAndHasData(roomsOtbCtx, this.recordMatcher)) {
                    this.updateStateFromReceivedTimestamps(null, null);
                    return;
                }

                this.biStatistics.fetchStatistics(
                    this.hotel.id,
                    this.makeNonCtxQuery(),
                    (data2, procData2) => {
                        const _procData2 = (procData2 || {});
                        const roomsOtb = ((_procData2.roomsOtb || {})[this.stayDate] || {});

                        if (!isValidNumericStatistic(roomsOtb)) {
                            return;
                        }

                        this.updateStateFromReceivedTimestamps(roomsOtb.receivedTs, roomsOtbCtx.receivedTs);
                    }
                )
            }
        );
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('hotel')
            || _changedProperties.has('recordDate')
            || _changedProperties.has('stayDate')
            || _changedProperties.has('recordMatcher')
        ) {
            this.fill();
        }
    }

    makeStyle() {
        if (this.badgeTxt.length > 0) {
            return 'margin-left:.5rem;';
        }
        return '';
    }

    render() {
        return html`
            <span 
                class="vao__components--metricBreakdownStaleBadge badge ${this.badgeCls}"
                style="${this.makeStyle()}"
            >
                ${this.badgeTxt}     
            </span>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MetricBreakdownStaleBadge = MetricBreakdownStaleBadge;
customElements.define('vao-metric-breakdown-stale-badge', MetricBreakdownStaleBadge);
