import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class TwelveMonthsOptionBlock extends AppElement {
    static get properties() {
        return {
            recordDate: { type: String },
            current: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || null;
        if (typeof props.onChanged !== 'function') {
            this.onChanged = () => {};
        } else {
            this.onChanged = props.onChanged;
        }
        this.current = props.current || null;
        this.compClass="vao__components--twelveMonthsOptionBlock";
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        if (typeof props.onChanged === 'function') {
            this.onChanged = props.onChanged;
        }
        this.current = props.current || this.current;
    }

    onClickOption(yearMon) {
        this.current = yearMon;
        this.onChanged(yearMon);
    }

    renderOptions() {
        let dateHelper = window.infinito.vao.controller.dateHelper;
        if (!this.recordDate || !dateHelper.isDateStr(this.recordDate)) {
            return html`<div>${Constants.MISSING_STR}</div>`;
        }
        let options = [];
        [...Array(12).keys()].forEach((i) => {
            let moddedRecordDate = dateHelper.addMonthsToDateString(this.recordDate, i);
            let moddedRecordDateYearMon = dateHelper.getYearMonthDateStringFrom(moddedRecordDate);
            let prettyYearMon = dateHelper.getPrettyYearMonthDateStringFrom(moddedRecordDateYearMon);
            if (prettyYearMon) {
                if (moddedRecordDateYearMon === this.current) {
                    options.push(
                        html`<vao-button  
                            text="${prettyYearMon}" 
                            startIcon="${Constants.ICONS.CALENDAR}"
                            variant="contained" 
                            size="small"
                            color="primary" 
                            disabledState="true">                            
                    </vao-button>`
                    );
                } else {
                    let fn = () => {
                        this.onClickOption(moddedRecordDateYearMon);
                    };
                    options.push(
                        html`<vao-button 
                            @click="${fn}" 
                            text="${prettyYearMon}"
                            startIcon="${Constants.ICONS.CALENDAR}"
                            variant="contained" 
                            activeState="false"
                            size="small"
                            color="light">                            
                    </vao-button>`
                    );
                }
            }
        });
        return html`<div class="${this.compClass + '-options'}">${options}</div>`;
    }

    render() {
        return html`
<div class="${this.compClass} ${this.recordDate}">
    ${this.renderOptions()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TwelveMonthsOptionBlock = TwelveMonthsOptionBlock;
customElements.define('vao-twelve-months-option-block', TwelveMonthsOptionBlock);
