import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

let paceDescMap = {
    1: 'strong',
    2: 'slightly ahead of pace',
    3: 'business as usual / inline with pace',
    4: 'slightly behind pace',
    5: 'behind pace'
};

export default class StoryInternalKpiPace extends AppElement {
    static get properties() {
        return {
            dow: { type: String },
            leadTime: { type: String },
            medianOcc: { type: String },
            medianAdr: { type: String },
            paceDesc: { type: String },
            rmsPaceDesc: { type: String },
            rmsPaceVar: { type: String },
            adrPaceDesc: { type: String },
            adrPaceVar: { type: String },
            paceDiff: { type: String },
            paceDiffInterval: { type: String },
            paceFrom: { type: String },
            paceTo: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.dow = Constants.MISSING_STR;
        this.leadTime = Constants.MISSING_STR;
        this.medianOcc = Constants.MISSING_STR;
        this.medianAdr = Constants.MISSING_STR;
        this.paceDesc = Constants.MISSING_STR;
        this.rmsPaceDesc = Constants.MISSING_STR;
        this.rmsPaceVar = Constants.MISSING_STR;
        this.adrPaceDesc = Constants.MISSING_STR;
        this.adrPaceVar = Constants.MISSING_STR;
        this.paceDiff = Constants.MISSING_STR;
        this.paceDiffInterval = Constants.MISSING_STR;
        this.paceFrom = Constants.MISSING_STR;
        this.paceTo = Constants.MISSING_STR;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;

        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.revenueLeadSiblingPace,
                    modelBiStats.fields.roomsOtb,
                    modelBiStats.fields.roomsOtbLeadMedian,
                    modelBiStats.fields.occupancyLeadMedian,
                    modelBiStats.fields.adrLeadMedian,
                    modelBiStats.fields.adr,
                    modelBiStats.fields.roomsOtbLeadSiblingPaceEvolution
                ],
                evolutionRange: 14
            }),
            (data, procData) => {
                let revLeadSibPaceVal = ((procData.revenueLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let otbVal = ((procData.roomsOtb || {})[this.stayDate] || {}).value || null;
                let otbLeadMedVal = ((procData.roomsOtbLeadMedian || {})[this.stayDate] || {}).value || null;
                let occTrendVal = ((procData.occupancyLeadMedian || {})[this.stayDate] || {}).value || null;
                let adrTrendVal = ((procData.adrLeadMedian || {})[this.stayDate] || {}).value || null;
                let adrVal = ((procData.adr || {})[this.stayDate] || {}).value || null;
                let otbLeadSibPaceEvoVal =
                    ((procData.roomsOtbLeadSiblingPaceEvolution || {})[this.stayDate] || {}).value || null;

                let occTrendMedian = null;
                let adrTrendMedian = null;

                // Put these here to help batch lit async DOM update
                this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(this.stayDate);
                this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    this.stayDate,
                    this.recordDate
                );

                if (typeof occTrendVal === 'object' && 'median' in occTrendVal) {
                    occTrendMedian = occTrendVal.median;
                }

                if (typeof adrTrendVal === 'object' && 'median' in adrTrendVal) {
                    adrTrendMedian = adrTrendVal.median;
                }

                if ($.isNumeric(occTrendMedian)) {
                    this.medianOcc = Math.round(parseFloat(occTrendMedian) * 100) + '%';
                } else {
                    this.medianOcc = Constants.MISSING_STR;
                }

                if ($.isNumeric(adrTrendMedian)) {
                    this.medianAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adrTrendMedian,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.medianAdr = Constants.MISSING_STR;
                }

                if (
                    typeof revLeadSibPaceVal === 'object'
                    && 'T0' in revLeadSibPaceVal
                    && 'setDenseRank' in revLeadSibPaceVal.T0
                    && 'setDenseLength' in revLeadSibPaceVal.T0
                ) {
                    this.paceDesc = window.infinito.vao.controller.analyseAttributesHelper.scaleRankMapForUniqueLength(
                        paceDescMap,
                        revLeadSibPaceVal.T0.setDenseLength
                    )[revLeadSibPaceVal.T0.setDenseRank] || Constants.MISSING_STR;
                } else {
                    this.paceDesc = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(otbVal)
                    && typeof otbLeadMedVal === 'object'
                    && 'median' in otbLeadMedVal
                    && $.isNumeric(otbLeadMedVal.median)
                ) {
                    let diff = otbVal - otbLeadMedVal.median;
                    this.rmsPaceVar = Math.abs(diff);
                    if (diff < 0) {
                        this.rmsPaceDesc = 'behind';
                    } else if (diff > 0) {
                        this.rmsPaceDesc = 'ahead';
                    } else {
                        this.rmsPaceDesc = 'inline';
                    }
                } else {
                    this.rmsPaceDesc = Constants.MISSING_STR;
                    this.rmsPaceVar = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(adrVal)
                    && $.isNumeric(adrTrendMedian)
                ) {
                    let diff = adrVal - adrTrendMedian;
                    this.adrPaceVar = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        Math.abs(diff),
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                    if (diff < 0) {
                        this.adrPaceDesc = 'behind';
                    } else if (diff > 0) {
                        this.adrPaceDesc = 'ahead';
                    } else {
                        this.adrPaceDesc = 'inline';
                    }
                } else {
                    this.adrPaceDesc = Constants.MISSING_STR;
                    this.adrPaceVar = Constants.MISSING_STR;
                }

                if (typeof otbLeadSibPaceEvoVal === 'object') {
                    let sorted = Object.values(otbLeadSibPaceEvoVal).sort((a, b) => {
                        return a.leadTime - b.leadTime;
                    });
                    let now = sorted[0];
                    if (
                        typeof now.value === 'object'
                        && 'T0' in now.value
                        && 'setDenseRank' in now.value.T0
                        && 'setDenseLength' in now.value.T0
                        && $.isNumeric(now.value.T0.setDenseRank)
                    ) {
                        let nowRank = now.value.T0.setDenseRank;
                        let nowLength = now.value.T0.setDenseLength;
                        let thenRank = null;
                        let thenLength = null;
                        let i;
                        for (i = 1; i <= 14; i++) {
                            let then = sorted[i] || null;
                            if (
                                typeof then === 'object'
                                && 'value' in then
                                && 'T0' in then.value
                                && 'setDenseRank' in then.value.T0
                                && 'setDenseLength' in then.value.T0
                                && $.isNumeric(then.value.T0.setDenseRank)
                            ) {
                                thenRank = then.value.T0.setDenseRank;
                                thenLength = then.value.T0.setDenseLength;
                            }
                            if (thenRank !== null && thenRank !== nowRank) {
                                break;
                            }
                        }
                        this.paceDiffInterval = i;
                        if (nowRank !== null) {
                            this.paceTo = window.infinito.vao.controller.analyseAttributesHelper
                                .scaleRankMapForUniqueLength(
                                    paceDescMap,
                                    nowLength
                                )[nowRank] || Constants.MISSING_STR;
                        } else {
                            this.paceTo = Constants.MISSING_STR;
                        }
                        if (thenRank !== null) {
                            this.paceFrom = window.infinito.vao.controller.analyseAttributesHelper
                                .scaleRankMapForUniqueLength(
                                    paceDescMap,
                                    thenLength
                                )[thenRank] || Constants.MISSING_STR;
                        } else {
                            this.paceFrom = Constants.MISSING_STR;
                        }
                        if (nowRank && thenRank && nowRank !== thenRank) {
                            if (nowRank < thenRank) {
                                this.paceDiff = 'improved';
                            } else if (nowRank > thenRank) {
                                this.paceDiff = 'fallen behind';
                            } else {
                                this.paceDiff = 'not changed';
                            }
                        } else {
                            this.paceDiff = Constants.MISSING_STR;
                        }
                    } else {
                        this.paceDiff = Constants.MISSING_STR;
                        this.paceDiffInterval = Constants.MISSING_STR;
                        this.paceFrom = Constants.MISSING_STR;
                        this.paceTo = Constants.MISSING_STR;
                    }
                } else {
                    this.paceDiff = Constants.MISSING_STR;
                    this.paceDiffInterval = Constants.MISSING_STR;
                    this.paceFrom = Constants.MISSING_STR;
                    this.paceTo = Constants.MISSING_STR;
                }
            }
        )
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyInternalKpiPace">
    ${storyHtml`
    <p>
        Our typical ${new StoryText(this.dow, true, storyTextTypes.TYPE_NEUTRAL)}, 
        ${new StoryText(this.leadTime, true, storyTextTypes.TYPE_NEUTRAL)} days 
        out has an occupancy of ${new StoryText(this.medianOcc, true, storyTextTypes.TYPE_NEUTRAL)} and an 
        ADR of ${new StoryText(this.medianAdr, true, storyTextTypes.TYPE_MONETARY, this.locale)}. 
    </p>
    <p>
        Our current revenue pace is considered 
        ${new StoryText(this.paceDesc, true, storyTextTypes.TYPE_TEXTUAL)}
        with rooms ${new StoryText(this.rmsPaceDesc, true, storyTextTypes.TYPE_TEXTUAL)} of pace 
        by ${new StoryText(this.rmsPaceVar, true, storyTextTypes.TYPE_NEUTRAL)} 
        and ADR ${new StoryText(this.adrPaceDesc, true, storyTextTypes.TYPE_TEXTUAL)} of pace 
        by ${new StoryText(this.adrPaceVar, true, storyTextTypes.TYPE_NEUTRAL)}.
    </p>
    ${this.paceDiff === Constants.MISSING_STR
        ? html ``
        : storyHtml`<p>
        Our rooms pace has ${new StoryText(this.paceDiff, true, storyTextTypes.TYPE_TEXTUAL)} in 
        the last ${new StoryText(this.paceDiffInterval, true, storyTextTypes.TYPE_NEUTRAL)} days. 
        It changed from ${new StoryText(this.paceFrom, true, storyTextTypes.TYPE_TEXTUAL)} 
        to ${new StoryText(this.paceTo, true, storyTextTypes.TYPE_TEXTUAL)}.
    </p>`}
    `}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalKpiPace = StoryInternalKpiPace;
customElements.define('vao-story-internal-kpi-pace', StoryInternalKpiPace);
