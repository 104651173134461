import {makeChannelOptionHtml} from "./ChannelOptionHtml";

export function makeExpediaChannelOptionHtml() {
    const html = makeChannelOptionHtml('Expedia', [
        'background: #00355F',
        'color: #EEC218',
        'border-color: #00355F'
    ]);
    return html;
}
