import { AppElement, html } from '../AppElement.js';

export const radioVariants = {
    square: '',
    round: 'p-round',
    curve: 'p-curve'
};

export const radioColors = {
    primary: 'p-primary',
    success: 'p-success',
    info: 'p-info',
    warning: 'p-warning',
    danger: 'p-danger'
};

export default class Radio extends AppElement {
    static get properties() {
        return {
            states: { type: Array },
            onChange: { type: Function }
        };
    }

    static makeState(label, name, value, variant, color, checked, content) {
        return {
            label: label || '', // UI
            name: name || '', // radio group
            value: value || '',
            variant: variant || '',
            color: color || '',
            checked: (checked === true),
            content: content || null
        }
    }

    constructor(props = {}) {
        super();
        this.states = props.states || [];
        this.compClass = 'vao__components--radio';
        this.id = AppElement.getUniqueElementId();
        this.onChange = props.onChange;
    }

    update(changedProperties) {
        super.update(changedProperties);
        $('#' + this.id + ' input[type=radio]').off('change').on('change', (e) => {
            if (typeof this.onChange === 'function') {
                this.onChange(e);
            }
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        $('#' + this.id + ' input[type=radio]').off('change');
    }

    render() {
        return html`
        <div class="${this.compClass}">
             ${this.states.map(state => {
                 let hasContent = state.content !== null;
                return html`
                <div class="pretty p-default ${state.variant} ${hasContent ? 'vao__components--radio-hasContent' : ''}">
                    <input type="radio" ?checked="${state.checked === true}" name="${state.name}" value="${state.value}"/>
                    <div class="state ${state.color}">
                        <label>${state.label}</label>
                    </div>
                </div>
                <div class="vao__components--radio-content">
                    ${hasContent ? state.content : ''}
                </div>
                `;
            })}
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Radio = Radio;
customElements.define('vao-radio', Radio);
