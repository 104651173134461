import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import Button from "../button/Button";

export default class DateSelectInput extends AppElement {
    static get properties() {
        return {
            date: { type: String },
            canShowNextPrev: { type: String },
            canShowIcon: { type: String },
            makeMinDate: { type: Function },
            makeMaxDate: { type: Function }
        };
    }

    constructor(props = {}) {
        super();
        this.date = props.date;
        if (!window.infinito.vao.controller.dateHelper.isDateStr(this.date)) {
            this.date = window.infinito.vao.controller.storageHelper.getCurrentDate();
        }
        this.onChange = props.onChange;
        this.makeMinDate = props.makeMinDate;
        this.makeMaxDate = props.makeMaxDate;
        this.canShowNextPrev = super.reflowBool('true', props.canShowNextPrev);
        this.canShowIcon = super.reflowBool('true', props.canShowIcon);

        this.id = AppElement.getUniqueElementId();
    }

    reflow(props = {}) {
        this.date = props.date || this.date;
        if (!window.infinito.vao.controller.dateHelper.isDateStr(this.date)) {
            this.date = window.infinito.vao.controller.storageHelper.getCurrentDate();
        }
        this.onChange = props.onChange || this.onChange;
        this.makeMinDate = props.makeMinDate || this.makeMinDate;
        this.makeMaxDate = props.makeMaxDate || this.makeMaxDate;
        this.canShowNextPrev = super.reflowBool(this.canShowNextPrev, props.canShowNextPrev);
        this.canShowIcon = super.reflowBool(this.canShowIcon, props.canShowIcon);
    }

    _onChange(newDate) {
        this.date = newDate;
        if (typeof this.onChange === 'function') {
            this.onChange(newDate);
        }
    }

    convertISOToDatepickerFormat(isoDate) {
        return window.infinito.vao.controller.dateHelper.format(isoDate, 'ddd, DD MMM YYYY');
    }

    getDateInDatepickerFormat() {
        return this.convertISOToDatepickerFormat(this.date);
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        $('#' + this.id).find('input').datepicker('destroy').datepicker({
            dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            dateFormat: 'D, dd M yy',
            defaultDate: this.getDateInDatepickerFormat(),
            changeMonth: false,
            changeYear: false,
            minDate: this._makeMinDate(),
            maxDate: this._makeMaxDate(),
            onSelect: () => {
                const asISO = $.datepicker.formatDate(
                    'yy-mm-dd',
                    $('#' + this.id).find('input').datepicker('getDate')
                );
                this._onChange(asISO);
            }
        }).datepicker('setDate', this.getDateInDatepickerFormat());
    }

    _makeMinDate(asIso = false) {
        if (typeof this.makeMinDate === 'function') {
            const isoDate = this.makeMinDate(this.date);
            if (asIso) {
                return isoDate;
            }
            return this.convertISOToDatepickerFormat(isoDate);
        }
        return null;
    }

    _makeMaxDate(asIso = false) {
        if (typeof this.makeMaxDate === 'function') {
            const isoDate = this.makeMaxDate(this.date);
            if (asIso) {
                return isoDate;
            }
            return this.convertISOToDatepickerFormat(isoDate);
        }
        return null;
    }

    renderNextPrev() {
        if (this.canShowNextPrev !== 'true') {
            return '';
        }
        const min = this._makeMinDate(true);
        const max = this._makeMaxDate(true);
        let shouldDisableMin = false;
        let shouldDisableMax = false;
        if (
            min
            && window.infinito.vao.controller.dateHelper.compareDateStrs(this.date, min) <= 0
        ) {
            shouldDisableMin = true;
        }
        if (
            max
            && window.infinito.vao.controller.dateHelper.compareDateStrs(this.date, max) >= 0
        ) {
            shouldDisableMax = true;
        }
        let prevBtn = new Button({
            startIcon: Constants.ICONS.CHEVRON_LEFT,
            onClick: () => {
                this._onChange(
                    window.infinito.vao.controller.dateHelper.subtractDaysFromDateString(this.date, 1)
                );
            },
            disabledState: shouldDisableMin + '',
            size: 'small',
            variant: 'invert',
            color: 'primary'
        });
        let nextBtn = new Button({
            startIcon: Constants.ICONS.CHEVRON_RIGHT,
            onClick: () => {
                this._onChange(
                    window.infinito.vao.controller.dateHelper.addDaysToDateString(this.date, 1)
                );
            },
            disabledState: shouldDisableMax + '',
            size: 'small',
            variant: 'invert',
            color: 'primary'
        });
        return html`
            <div style="display:inline-block;">
                <div style="display:inline-block;">${prevBtn}</div>
                <div style="display:inline-block;">${nextBtn}</div>
            </div>`;
    }

    render() {
        return html`
            <div class="vao__components--dateSelectInput">
                ${this.canShowIcon === 'true' ? html`
                <vao-icon cls="${Constants.ICONS.STAY_DATE}" style="margin-right:.5rem;"></vao-icon>
                ` : ''}
                <input 
                    class="form-control form-control-sm" 
                    style="max-width:145px;cursor:pointer;background-color:#fff;" 
                    readonly
                >
                ${this.renderNextPrev()}
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.DateSelectInput = DateSelectInput;
customElements.define('vao-date-select-input', DateSelectInput);
