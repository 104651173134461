import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StoryWhatToExpectPickupSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String },

            recordDate: { type: String },
            stayDate: { type: String },
            hotel: { type: Object },
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.roomsOtbLeaderCloserTrendProphecy
                ]
            }),
            (data, procData) => {
                let roomsOtbLeaderCloserTrendProphecy =
                    ((procData.roomsOtbLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || {};

                if (
                    typeof roomsOtbLeaderCloserTrendProphecy === 'object'
                    && 'leadersClosersVariance' in roomsOtbLeaderCloserTrendProphecy
                    && $.isNumeric(roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance)
                ) {
                    const whatToExpect = Math.floor(roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance);
                    if (roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance < 0) {
                        this.subtitle = `${whatToExpect} decrease`;
                        this.badgeClass = 'badge-danger';
                    } else if (roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance > 0) {
                        this.subtitle = `${whatToExpect} increase`;
                        this.badgeClass = 'badge-success';
                    } else {
                        this.subtitle = 'flat';
                        this.badgeClass = 'badge-primary';
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryWhatToExpectPickupSubtitle = StoryWhatToExpectPickupSubtitle;
customElements.define('vao-story-what-to-expect-pickup-subtitle', StoryWhatToExpectPickupSubtitle);
