import { AppElement, html } from '../AppElement.js';
import TableNoDataRow from "../table/TableNoDataRow";
import Table from "../table/Table";
import TableCell, {tableCellTypes} from "../table/TableCell";
import TableColumn from "../table/TableColumn";
import TableRow from "../table/TableRow";
import {getIdentityDependantTableStateKey} from "../TableUtils";

const cellFieldMap = {
    month: 'month',
    occ: 'occ',
    rms: 'rms',
    adr: 'adr',
    rev: 'rev',
    // pkuprms:'pkuprms',
    // pkupadr:'pkupadr',
    // pkuprev:'pkuprev',
    occfc:'occfc',
    roomsfc:'roomsfc',
    adrfc:'adrfc',
    revfc:'revfc',
    occBu:'occBu',
    roomsBu:'roomsBu',
    adrBu:'adrBu',
    revBu:'revBu',
    occaLy:'occaLy',
    roomsaLy:'roomsaLy',
    adraLy:'adraLy',
    revaLy:'revaLy',
    occstLy:'occstLy',
    roomsstLy:'roomsstLy',
    adrstLy:'adrstLy',
    revstLy:'revstLy'
};

export default class PickupPerMonthPopup extends AppElement {

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();
        this.cache = {};
        this.cellFieldMap = cellFieldMap;
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;
        this.randomNumberId = Math.round(Math.random() * 100000);
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        if ($.isNumeric(props.pickupSetting)) {
            this.pickupSetting = props.pickupSetting;
        }
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.month,
                    value: 'Month',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occ,
                    value: 'Occupancy',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.rms,
                    value: 'Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adr,
                    value: 'ADR',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.rev,
                    value: 'Revenue',
                    type: tableCellTypes.TH
                })
            ),
            // new TableColumn(
            //     new TableCell({
            //         field: cellFieldMap.pkuprms,
            //         value: 'PU Rooms',
            //         type: tableCellTypes.TH
            //     })
            // ),
            //
            // new TableColumn(
            //     new TableCell({
            //         field: cellFieldMap.pkupadr,
            //         value: 'PU ADR',
            //         type: tableCellTypes.TH
            //     })
            // ),
            //
            // new TableColumn(
            //     new TableCell({
            //         field: cellFieldMap.pkuprev,
            //         value: 'PU Revenue',
            //         type: tableCellTypes.TH
            //     })
            // ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occfc,
                    value: 'FC Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsfc,
                    value: 'FC Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrfc,
                    value: 'FC ADR',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revfc,
                    value: 'FC Rev',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occBu,
                    value: 'B Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsBu,
                    value: 'B Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrBu,
                    value: 'B ADR',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revBu,
                    value: 'B Rev',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occstLy,
                    value: 'STLY Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsstLy,
                    value: 'STLY Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrstLy,
                    value: 'STLY ADR',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revstLy,
                    value: 'STLY Rev',
                    type: tableCellTypes.TH
                })
            ),

            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occaLy,
                    value: 'LY Occ',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.roomsaLy,
                    value: 'LY Rooms',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adraLy,
                    value: 'LY ADR',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.revaLy,
                    value: 'LY Rev',
                    type: tableCellTypes.TH
                })
            )


        ];
        this.cache['cols'] = cols;
        return cols;
    }

    getTable() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            const stateKey = getIdentityDependantTableStateKey(
                `PickupPerMonthPopup_${this.getTableStateKey()}`
            );
            let table = new Table({
                stateKey: stateKey,
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ]
            });
            this.cache['table'] = table;
        }

        return this.cache.table;
    }

    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows
            });
        }

        return table;
    }

    domTheTable(table) {
        $('#' + this.id).find('.vao__components--pickupPerMonth-table').empty().append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    makeTableRow(title) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(title)) {
            return this.cache.rowMap.get(title);
        }

        const row = new TableRow([
            new TableCell({
                field: cellFieldMap.month,
                value: title
            }),
            new TableCell({
                field: cellFieldMap.occ,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.rms,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adr,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.rev,
                value: null,
                isLoading: true
            }),
            // new TableCell({
            //     field: cellFieldMap.pkuprms,
            //     value: null,
            //     isLoading: true
            // }),
            // new TableCell({
            //     field: cellFieldMap.pkupadr,
            //     value: null,
            //     isLoading: true
            // }),
            // new TableCell({
            //     field: cellFieldMap.pkuprev,
            //     value: null,
            //     isLoading: true
            // }),

            new TableCell({
                field: cellFieldMap.occfc,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsfc,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adrfc,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revfc,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occBu,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsBu,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adrBu,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revBu,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occstLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsstLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adrstLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revstLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.occaLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.roomsaLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.adraLy,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.revaLy,
                value: null,
                isLoading: true
            })

        ]);

        this.cache.rowMap.set(title, row);
        return row;
    }

    forEachMonthKey(callback) {
        [...Array(12).keys()].forEach((i => {
            let month = window.infinito.vao.controller.dateHelper.addMonthsToDateString(this.recordDate, i);
            let monthStr = window.infinito.vao.controller.dateHelper.getShortYearMonStr(month);
            let yearMonKey = window.infinito.vao.controller.dateHelper.getYearMonthDateStringFrom(month);
            callback(monthStr, yearMonKey);
        }));
    }

    makeTableRows() {
        let rows = [];
        const mtdRow = this.makeTableRow('MTD');
        rows.push(mtdRow);
        this.forEachMonthKey(monthKey => {
            rows.push(this.makeTableRow(monthKey));
        });
        return rows;
    }

    objectify(input) {
        if (input && typeof input === 'object') {
            return input;
        }
        if (input && typeof input === 'string' && input.includes("{")) {
            return JSON.parse(input);
        }
        return {};
    }

    fill() {
        // Expect child class overrides.
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
    }

    updated(changedProperties) {
        super.updated(changedProperties);
        this.fill();
    }

    render() {
        if(document.getElementById('vao__pickuppermonthpopup--Variancedecimals-check')){
            const element = document.getElementById('vao__pickuppermonthpopup--Variancedecimals-check');
            element.remove();
        }
        return html`
        <div class="vao__components--pickupPerMonth">
            <div class="vao__myAccount--betaTableWrapper vao-div">
                <div id="vao__myAccount--revPardecimals" class="pretty p-switch p-slim">
                    <input id="vao__pickuppermonthpopup--Variancedecimals-check" type="checkbox" />
                    <div class="state">
                        <label>Variance Toggle</label>
                    </div>
                </div>
            </div>
            <div class="vao__components--pickupPerMonth-title">
                <h6 class="vao__components--flashGroup-bodySectionTitle">Yearly Performance</h6>
            </div>
            <div class="vao__components--pickupPerMonth-table"></div>
           
        </div>
        `;
    }
}


window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PickupPerMonthPopup = PickupPerMonthPopup;
customElements.define('vao-pickup-per-month-pupup', PickupPerMonthPopup);
