import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StoryWhatToExpectMyPriceSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.rateLeaderFutureLeaderTrendProphecy
                ]
            }),
            (data, procData) => {
                let rateLeaderFutureLeaderTrendProphecy =
                    ((procData.rateLeaderFutureLeaderTrendProphecy || {})[this.stayDate] || {}).value || {};

                // copy pasta
                if (
                    rateLeaderFutureLeaderTrendProphecy
                    && typeof rateLeaderFutureLeaderTrendProphecy === 'object'
                    && 'leaders' in rateLeaderFutureLeaderTrendProphecy
                    && Array.isArray(rateLeaderFutureLeaderTrendProphecy.leaders)
                    && 'futureLeaders' in rateLeaderFutureLeaderTrendProphecy
                    && Array.isArray(rateLeaderFutureLeaderTrendProphecy.futureLeaders)
                ) {
                    let findMatchingFutureLeader = (stayDate) => {
                        return rateLeaderFutureLeaderTrendProphecy.futureLeaders.find((closer) => {
                            if (closer.stayDate === stayDate) {
                                return true;
                            }
                            return false;
                        });
                    };

                    let numUp = 0;
                    let ups = [];
                    let numDown = 0;
                    let downs = [];
                    rateLeaderFutureLeaderTrendProphecy.leaders.forEach((leader) => {
                        let futureLeader = findMatchingFutureLeader(leader.stayDate);
                        if (!futureLeader) {
                            return;
                        }
                        let then = leader.value;
                        let now = futureLeader.value;
                        if (!$.isNumeric(then)) {
                            then = Constants.MISSING_STR;
                        }
                        if (!$.isNumeric(now)) {
                            now = Constants.MISSING_STR;
                        }
                        if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                            numDown++;
                            downs.push(now - then);
                        }
                        if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                            numUp++;
                            ups.push(now - then);
                        }
                    });
                    // end copy pasta
                    if (numDown === numUp) {
                        this.subtitle = 'flat next 7 days';
                        this.badgeClass = 'badge-primary';
                    } else if (numDown > numUp) {
                        this.subtitle = 'drop next 7 days';
                        this.badgeClass = 'badge-warning';
                    } else {
                        this.subtitle = 'increase next 7 days';
                        this.badgeClass = 'badge-info';
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryWhatToExpectMyPriceSubtitle = StoryWhatToExpectMyPriceSubtitle;
customElements.define('vao-story-what-to-expect-my-price-subtitle', StoryWhatToExpectMyPriceSubtitle);
