import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryInternalTotalPickup extends AppElement {
    static get properties() {
        return {
            pickupDesc: { type: String },
            pickupAB: { type: String },
            otbPickup: { type: String },
            pickupsAdr: { type: String },
            revenuePickup: { type: String },
            roomsPickupTrend: { type: String },
            pickupsAdrTrend: { type: String },
            revenuePickupTrend: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.pickupDesc = props.pickupDesc || Constants.MISSING_STR;
        this.pickupAB = props.pickupAB || Constants.MISSING_STR;
        this.otbPickup = props.otbPickup || Constants.MISSING_STR;
        this.pickupsAdr = props.pickupsAdr || Constants.MISSING_STR;
        this.revenuePickup = props.revenuePickup || Constants.MISSING_STR;
        this.roomsPickupTrend = props.roomsPickupTrend || Constants.MISSING_STR;
        this.pickupsAdrTrend = props.pickupsAdrTrend || Constants.MISSING_STR;
        this.revenuePickupTrend = props.revenuePickupTrend || Constants.MISSING_STR;
    }

    reflow(props = {}) {
        this.pickupDesc = props.pickupDesc || this.pickupDesc;
        this.pickupAB = props.pickupAB || this.pickupAB;
        this.otbPickup = props.otbPickup || this.otbPickup;
        this.pickupsAdr = props.pickupsAdr || this.pickupsAdr;
        this.revenuePickup = props.revenuePickup || this.revenuePickup;
        this.roomsPickupTrend = props.roomsPickupTrend || this.roomsPickupTrend;
        this.pickupsAdrTrend = props.pickupsAdrTrend || this.pickupsAdrTrend;
        this.revenuePickupTrend = props.revenuePickupTrend || this.revenuePickupTrend;
    }

    render() {
        return html`
<div class="vao__components--storyInternalTotalPickup">
    ${storyHtml`
    <p>
        Our pickup since ${new StoryText(this.pickupDesc, true, storyTextTypes.TYPE_NEUTRAL)}  
        has been ${new StoryText(this.pickupAB, true, storyTextTypes.TYPE_TEXTUAL)} revenue trend. 
        We picked up 
        ${new StoryText(this.otbPickup, true, storyTextTypes.TYPE_NUMERIC)} rooms at an ADR of 
        ${new StoryText(this.pickupsAdr, true, storyTextTypes.TYPE_NEUTRAL)} 
        resulting in revenue of 
        ${new StoryText(this.revenuePickup, true, storyTextTypes.TYPE_MONETARY, this.locale)}.
    </p>
    <p>
        Trend rooms pickup: ${new StoryText(this.roomsPickupTrend, true, storyTextTypes.TYPE_NUMERIC)}
    </p>
    <p>
        Trend pickups ADR: ${new StoryText(this.pickupsAdrTrend, true, 
            storyTextTypes.TYPE_MONETARY, this.locale)}
    </p>
    <p>
        Trend revenue pickup: ${new StoryText(this.revenuePickupTrend, true, 
            storyTextTypes.TYPE_MONETARY, this.locale)}
    </p>
    `}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalTotalPickup = StoryInternalTotalPickup;
customElements.define('vao-story-internal-total-pickup', StoryInternalTotalPickup);
