import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export const EVENT_BUDGET_FORM_TABLE_ACTION_CELL_SAVE = 'vao-budget-form-table-action-cell-save';
export const EVENT_BUDGET_FORM_TABLE_ACTION_CELL_DIFF = 'vao-budget-form-table-action-cell-diff';
export const EVENT_BUDGET_FORM_TABLE_ACTION_CELL_RESET = 'vao-budget-form-table-action-cell-reset';

export default class BudgetForecastFormTableActionCell extends AppElement {
    static get properties() {
        return {
            budgetValue: { type: Object },
            isDisabled: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.budgetValue = props.budgetValue;
        this.isDisabled = super.reflowBool('true', props.isDisabled);

        this.startIcon = Constants.ICONS.SAVE;
        this.diffIcon = Constants.ICONS.DIFF;
        this.resetIcon = Constants.ICONS.UNDO;
    }

    reflow(props = {}) {
        this.budgetValue = props.budgetValue || this.budgetValue;
        this.isDisabled = super.reflowBool(this.isDisabled, props.isDisabled);
    }

    _dispatchEvent(event) {
        if (this.isDisabled === 'false') {
            this.dispatchEvent(event);
        }
    }

    _onClickSave() {
        const event = new CustomEvent(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_SAVE, {
            detail: {
                budgetValue: this.budgetValue
            },
            bubbles: true
        });
        this._dispatchEvent(event);
    }

    _onClickDiff() {
        const event = new CustomEvent(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_DIFF, {
            detail: {
                budgetValue: this.budgetValue
            },
            bubbles: true
        });
        this._dispatchEvent(event);
    }

    _onClickReset() {
        const event = new CustomEvent(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_RESET, {
            detail: {
                budgetValue: this.budgetValue
            },
            bubbles: true
        });
        this._dispatchEvent(event);
    }

    render() {
        const saveTooltipText = 'Save';
        const diffTooltipText = 'Diff';
        const resetTooltipText = 'Undo';

        return html`
<div class="vao__components--budgetFormTableActionCell" style="display:flex;">
    <vao-button 
        .startIcon="${this.startIcon}" 
        .tooltip="${saveTooltipText}"
        .disabledState="${this.isDisabled}"
        variant="invert"
        color="${this.isDisabled === 'true' ? 'light' : 'primary'}"
        size="small"       
        @click="${this._onClickSave}"
    ></vao-button>
    <vao-button 
        .startIcon="${this.diffIcon}" 
        .tooltip="${diffTooltipText}"
        .disabledState="${this.isDisabled}"
        variant="invert"
        color="${this.isDisabled === 'true' ? 'light' : 'secondary'}"
        size="small"       
        @click="${this._onClickDiff}"
    ></vao-button>
    <vao-button 
        .startIcon="${this.resetIcon}" 
        .tooltip="${resetTooltipText}"
        .disabledState="${this.isDisabled}"
        variant="invert"
        color="light"
        size="small"
        @click="${this._onClickReset}"
    ></vao-button>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetForecastFormTableActionCell = BudgetForecastFormTableActionCell;
customElements.define('vao-budgetforecast-form-table-action-cell', BudgetForecastFormTableActionCell);
