import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import ColorArray from "../ColorArray";

export default class RoomsOtbPickupEvolutionGraph extends AppElement {
    static get properties() {
        return {};
    }

    constructor(props = {}) {
        super();
        this.chartId = AppElement.getUniqueElementId();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.pickupSetting = props.pickupSetting || window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.chartHeight = props.chartHeight || '300px';
        this.evolutionRange = props.evolutionRange || 14;
        this.leadTime = 0;
        this.evolutionRange = 14;
        this.series = {
            name: 'rooms',
            data: [],
            yAxis: 0,
            type: 'spline',
            zIndex: 1,
            lineWidth: 4,
            marker: {
                enabled: true
            }
        };
        this.chart = null;
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.pickupSetting = props.pickupSetting || window.infinito.vao.controller.storageHelper.getPickupOffset();
        this.chartHeight = props.chartHeight || '300px';
        this.evolutionRange = props.evolutionRange || 14;
        this.empty();
        this.series = {
            name: 'rooms',
            data: [],
            yAxis: 0,
            type: 'spline',
            zIndex: 1,
            lineWidth: 4,
            marker: {
                enabled: true
            }
        };
        this.fill();
    }

    chartOpts() {
        let currentDate = this.recordDate;
        let leadTime = this.leadTime;

        return {
            title: {
                text: 'Recent Pickup'
            },
            credits: {
                enabled: false
            },
            chart: {
                spacingTop: 20,
                spacingBottom: 20,
                height: this.chartHeight
            },
            colors: (new ColorArray()).colorArray,
            lang: {
                noData: 'No data to display'
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            },
            xAxis: {
                type: 'linear',
                title: {
                    text: 'Lead time'
                },
                reversed: true,
                max: this.leadTime + this.evolutionRange,
                min: this.leadTime
            },
            yAxis: [
                {
                    opposite: true,
                    title: {
                        text: null
                    },
                    labels: {
                        format: '{value}'
                    },
                    endOnTick: false,
                    maxPadding: 0.02
                }
            ],
            legend: {
                enabled: false,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                labelFormatter: function labelFormatter() {
                    return this.name;
                },
                itemMarginBottom: 5
            },
            plotOptions: {
                series: {
                    events: {},
                    connectNulls: true,
                    label: {
                        connectorAllowed: false
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                },
                column: {
                    /* Limit the maximum column width. */
                    maxPointWidth: 15
                }
            },
            tooltip: {
                shared: true,
                split: false,
                enabled: true,
                formatter: function formatter() {
                    let asOfDate = infinito.vao.controller.dateHelper.format(
                        infinito.vao.controller.dateHelper.subtractDaysFromDateString(
                            currentDate,
                            (this.x - leadTime)
                        ),
                        'dddd, DD MMM YYYY'
                    );
                    // https://api.highcharts.com/highcharts/tooltip.formatter
                    // https://api.highcharts.com/highcharts/tooltip.pointFormat
                    return this.points.reduce(function reduce(s, point) {
                        return s + '<br/>'
                            + point.series.name + ': <b>' + point.y + '</b>';
                    }, 'Lead time ' + this.x + ' (' + asOfDate + ')<br/>');
                }
            },
            series: [],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };
    }

    empty() {
        let chart = this.getChart();
        if (!chart) {
            return;
        }
        while (chart.series.length) {
            chart.series[0].remove();
        }
    }

    getChart() {
        if (this.chart) {
            return this.chart;
        }
        if (document.getElementById(this.chartId) === null) {
            // wait until its in the DOM or else highcharts will error
            return null;
        }
        this.chart = window.Highcharts.chart(this.chartId, this.chartOpts());
        return this.chart;
    }

    makeEvolutionDateStrArray() {
        let evolutionStart = window.infinito.vao.controller.dateHelper.dateStrToDate(
            null,
            this.recordDate
        );
        let evolutionEnd = window.infinito.vao.controller.dateHelper.dateStrToDate(
            null,
            infinito.vao.controller.dateHelper.subtractDaysFromDateString(this.recordDate, this.evolutionRange)
        );
        let dates = infinito.vao.controller.dateHelper.calcDateRangeArray(evolutionEnd, evolutionStart);
        let evolutionDates = [];
        dates.forEach((date) => {
            let evd = infinito.vao.controller.dateHelper.dateToDateStr(null, date);
            if (evd) {
                evolutionDates.push(evd);
            }
        });
        return evolutionDates;
    }

    makeSeriesData(valObj, valLeadTime) {
        let val =  parseFloat(valObj.roomsOtbPickup || 0);
        if (!$.isNumeric(val)) {
            val = null;
        }
        return {
            y: val,
            x: valLeadTime
        };
    }

    fill() {
        if (
            !this.hotel
            || typeof this.hotel !== 'object'
            || !this.hotel.id
            || !$.isNumeric(this.pickupSetting)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
            this.stayDate,
            this.recordDate
        );

        let evolutionDates = this.makeEvolutionDateStrArray();
        let chart = this.getChart();
        if (!chart) {
            return;
        }
        chart.showLoading();

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.roomsOtbPickupEvolution
                ],
                evolutionRange: this.evolutionRange
            }),
            (data, procData) => {
                let evolutionVal = ((procData.roomsOtbPickupEvolution || {})[this.stayDate] || {}).value || {};

                chart.hideLoading();

                evolutionDates.reverse().forEach((evdate) => {
                    let evolutionDateData = (evolutionVal[evdate] || {});
                    let evolutionLeadTime = evolutionDateData.leadTime || 0;

                    let seriesData = this.makeSeriesData(evolutionDateData, evolutionLeadTime);

                    if (seriesData) {
                        this.series.data.push(seriesData);
                    }
                });

                chart.addSeries(this.series, false);
                chart.redraw();
            }
        );
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        let chart = this.getChart();
        if (chart) {
            // https://api.highcharts.com/class-reference/Highcharts.Chart#destroy
            chart.destroy();
        }
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <div id="${this.chartId}"></div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.RoomsOtbPickupEvolutionGraph = RoomsOtbPickupEvolutionGraph;
customElements.define('vao-rooms-otb-pickup-evolution-graph', RoomsOtbPickupEvolutionGraph);
