import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {sendRequest} from "../App.common";

export default class GroupRatesDateRangePopup extends AppElement {
    static get properties() {
        return {
            populateTabsString: { type: String },
            mastersallseason: []
        };
    }
    constructor(props = {}) {
        super();
        this.populateTabsString ='';
        this.mastersallseason = [];
        this.stayDate = this.getAttribute("stayDate")
    }
    render() {
        return html`<div style="width: auto;overflow-x: auto;">
                <table id="season__daterange_table" class="vao__components--table-table table nowrap table-bordered table-md table-align-center table-fs-small table-static-left" style="text-align: center;"
                       class="vao__components--table-table table nowrap table-md table-align-center table-fs-small table-static-left">
                    <thead>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">From Date
                    </th>
                    <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                        To Date
                    </th>
                    </thead>
                    <tbody>
                    <tr role="row" class="">
                        <td class="table-cell-available" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" type="date" name="from_date" id="from_date"
                                   step="1" pattern="">
                        </td>
                        <td class="table-cell-revenue" rowspan="1" colspan="1">
                            <input class="form-control form-control-sm width-auto" type="date" name="to_date" id="to_date" value="0"
                                   step="any" pattern="">
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table id="season_dow_table" class="vao__components--table-table table nowrap table-bordered table-md table-align-center table-fs-small table-static-left" style="text-align: center;"
                       class="vao__components--table-table table nowrap table-md table-align-center table-fs-small table-static-left">
                    <thead>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Mon
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Tue
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Wed
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Thu
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Fri
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Sat
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Sun
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            All
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Seasons
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Rate
                        </th>
                        <th style="z-index: 3;left: 0;background: #ffffff;position: -webkit-sticky;position: sticky;">
                            Buffer %
                        </th>
                    </thead>
                    <tbody>
                        <tr role="row" class="">
                            <td class="table-cell-monday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto monday" type="checkbox" name="monday" id="monday"
                                       step="1" pattern="">
                            </td>
                            <td class="table-cell-tuesday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto tuesday" type="checkbox" name="tuesday" id="tuesday"
                                       step="any" pattern="" >
                            </td>
                            <td class="table-cell-wednesday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto wednesday" type="checkbox" name="wednesday" id="wednesday"
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-thursday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto thursday" type="checkbox" name="thursday" id="thursday"
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-friday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto friday" type="checkbox" name="friday" id="friday"
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-saturday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto saturday" type="checkbox" name="saturday" id="saturday"
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-sunday" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto sunday" type="checkbox" name="sunday" id="sunday"
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-all_days" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto all_days" type="checkbox" name="all_days" id="all_days" 
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-season" rowspan="1" colspan="1">
                                <select class="diff_class form-control form-control-sm width-auto" name="season_id" id="season_id">
                                    
                                </select>
                            </td>
                            <td class="table-cell-highratebuffer" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto buffer_value" type="number" name="vao-rate_span" id="vao-rate_span"
                                       step="any" pattern="">
                            </td>
                            <td class="table-cell-revenuebuffer" rowspan="1" colspan="1">
                                <input class="form-control form-control-sm width-auto buffer_value" type="number" name="buffer_value" id="buffer_value"
                                       step="any" pattern="">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style="float:right;">
                    <vao-button
                            text="Save"
                            variant="invert"
                            size="sm"
                            @click=${this.onClicksaveSeasonBtn}
                    ></vao-button>
                </div>
            </div>`;
    }

    onClicksaveSeasonBtn(){
        let monday = 0;
        let tuesday = 0;
        let wednesday = 0;
        let thursday = 0;
        let friday = 0;
        let saturday = 0;
        let sunday = 0;
        if ($('#monday').is(":checked")) {
            monday = 1;
        }
        if ($('#tuesday').is(":checked")) {
            tuesday = 1;
        }
        if ($('#wednesday').is(":checked")) {
            wednesday = 1;
        }
        if ($('#thursday').is(":checked")) {
            thursday = 1;
        }
        if ($('#friday').is(":checked")) {
            friday = 1;
        }
        if ($('#saturday').is(":checked")) {
            saturday = 1;
        }
        if ($('#sunday').is(":checked")) {
            sunday = 1;
        }

        let seasons_id = $("#season_id").val();
        let buffer_value =$("#buffer_value").val();
        let rate_value =$("#vao-rate_span").val();
        let from_date =$("#from_date").val();
        let to_date =$("#to_date").val();
        let hoteldata = window.infinito.vao.controller.storageHelper.getSelectedHotel();

        let postData = {
            "hotel_id": hoteldata.id,
            "identity_id":infinito.vao.controller.storageHelper.getIdentitiesEmail(),
            "monday":monday,
            "tuesday":tuesday,
            "wednesday":wednesday,
            "thursday":thursday,
            "friday":friday,
            "saturday":saturday,
            "sunday":sunday,
            "seasons_id":seasons_id,
            "buffer_value":buffer_value,
            "rate_value":rate_value,
            "from_date":from_date,
            "to_date":to_date,
            'op': 'createdaterangewisebuffers',
            'serviceName': "hotelbufferrates.php"
        };
        sendRequest(postData,function(res){

            $('.close').click();
            var bodyText = 'Date Range Created Successfully.';
            $(infinito.vao.controller.alertHelper.constructPrimaryAlert(
                '',
                bodyText,
                true
            )).insertBefore('#season_table');
            setTimeout(function(){window.location.reload()},3000);

        });
    }

    firstUpdated(changedProperties) {
        let thisflag = this;
        $("#all_days").on('change',function(){

            if (document.getElementById('all_days').checked) {
                $('#monday').prop('checked', true);
                $('#tuesday').prop('checked', true);
                $('#wednesday').prop('checked', true);
                $('#thursday').prop('checked', true);
                $('#friday').prop('checked', true);
                $('#saturday').prop('checked', true);
                $('#sunday').prop('checked', true);
            } else {
                $('#monday').prop('checked', false);
                $('#tuesday').prop('checked', false);
                $('#wednesday').prop('checked', false);
                $('#thursday').prop('checked', false);
                $('#friday').prop('checked', false);
                $('#saturday').prop('checked', false);
                $('#sunday').prop('checked', false);
            }
        });
        $("#season_id").on('change',function(){
            $("#vao-rate_span").val("");
            $("#buffer_value").val("");
            let seasons_id = $("#season_id").val();
            for(var i=0;i<thisflag.mastersallseason.length;i++){
                if(thisflag.mastersallseason[i]['id']===seasons_id){
                    $("#vao-rate_span").val(thisflag.mastersallseason[i]['rate_value']);
                    $("#buffer_value").val(thisflag.mastersallseason[i]['buffer_value']);
                }
            }
        });
        let hoteldata = window.infinito.vao.controller.storageHelper.getSelectedHotel();
        let postData = {
            'hotel_id': hoteldata.id,
            'op': 'gethotelsSeasons',
            'serviceName': "hotelbufferrates.php"
        };
        let flag = this;
        sendRequest(postData,function(res){
            var jsonObj = JSON.parse(res);
            flag.mastersallseason = jsonObj.data;
            $('#season_id').html('');
            $('#season_id')
                .append($("<option></option>")
                    .attr("value", '0')
                    .text('Select'));
            $.each(jsonObj.data, function(key, value) {
                $('#season_id')
                    .append($("<option></option>")
                        .attr("value", value['id'])
                        .text(value['season_name']));
            });
        });
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktop = GroupRatesDateRangePopup;
customElements.define('vao-page-bufferrates-daterange-modal', GroupRatesDateRangePopup);