import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';
import {isFutureLeaderStatistic} from "../StatisticUtils";

export default class StoryWhatToExpectPickup extends AppElement {
    static get properties() {
        return {
            expectedRoomsChange: { type: String },
            expected7DaysRoomsChange: { type: String },
        };
    }

    constructor(props = {}) {
        super();

        this.empty();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.fill();
    }

    empty() {
        this.expectedRoomsChange = Constants.MISSING_STR;
        this.expected7DaysRoomsChange = Constants.MISSING_STR;
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.roomsOtbLeaderCloserTrendProphecy,
                    modelBiStats.fields.roomsOtbLeaderFutureLeaderTrendProphecy
                ]
            }),
            (data, procData) => {
                let roomsOtbLeaderCloserTrendProphecy =
                    ((procData.roomsOtbLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || null;
                let roomsOtbLeaderFutureLeaderTrendProphecy =
                    (procData.roomsOtbLeaderFutureLeaderTrendProphecy || {})[this.stayDate];

                this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(this.stayDate);
                this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    this.stayDate,
                    this.recordDate
                );

                if (
                    typeof roomsOtbLeaderCloserTrendProphecy === 'object'
                    && roomsOtbLeaderCloserTrendProphecy
                    && 'leadersClosersVariance' in roomsOtbLeaderCloserTrendProphecy
                    && $.isNumeric(roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance)
                ) {
                    this.expectedRoomsChange = Math.floor(roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance);
                } else {
                    this.expectedRoomsChange = Constants.MISSING_STR;
                }

                if (
                    this.canShowWhatToExpected7Days()
                    && isFutureLeaderStatistic(roomsOtbLeaderFutureLeaderTrendProphecy)
                ) {
                    const expectedChange = roomsOtbLeaderFutureLeaderTrendProphecy.value.leadersFutureLeadersVariance;
                    if ($.isNumeric(expectedChange)) {
                        this.expected7DaysRoomsChange = Math.floor(expectedChange);
                    } else {
                        this.expected7DaysRoomsChange = Constants.MISSING_STR;
                    }
                } else {
                    this.expected7DaysRoomsChange = Constants.MISSING_STR;
                }
            }
        );
    }

    canShowWhatToExpected7Days() {
        return Number.isInteger(this.leadTime) && this.leadTime > 7;
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    renderWhatToExpectClose() {
        const leadTime = new StoryText(
            this.leadTime,
            true,
            storyTextTypes.TYPE_NEUTRAL
        );
        return storyHtml`
        <p>
            Recent trends indicate that we should expect our rooms to change by 
            ${new StoryText(this.expectedRoomsChange, true, storyTextTypes.TYPE_NUMERIC)} between 
            now and the stay date, which is ${leadTime} days from now.
        </p>
        `;
    }

    renderWhatToExpect7Days() {
        if (
            !this.canShowWhatToExpected7Days()
            || this.expected7DaysRoomsChange === Constants.MISSING_STR
        ) {
            return '';
        }

        const expected7Days = new StoryText(
            this.expected7DaysRoomsChange,
            true,
            storyTextTypes.TYPE_NUMERIC
        );
        return storyHtml`
        <p>
            Over the next 7 days, we expect our rooms to change by ${expected7Days}.
        </p>   
        `;
    }

    render() {
        return html`
<div class="vao__components--storyWhatToExpectPickup">
    ${this.renderWhatToExpectClose()}
    ${this.renderWhatToExpect7Days()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryWhatToExpectPickup = StoryWhatToExpectPickup;
customElements.define('vao-story-what-to-expect-pickup', StoryWhatToExpectPickup);
