import TableCell from "./TableCell";

export default class TableRow {

    constructor(tableCells = [], data = {}, rowClasses = []) {
        this.tableCellPerField = new Map();
        tableCells.forEach((tableCell) => {
           if (tableCell instanceof TableCell) {
               this.tableCellPerField.set(tableCell.field, tableCell);
           }
        });
        this.data = data;
        if (Array.isArray(rowClasses) && rowClasses.length > 0) {
            // Convert the input string array into a single string that can be put into the HTMLElement class attr.
            this.rowClasses = rowClasses.reduce((acc, cur) => {
                if (typeof cur === 'string' && cur.length > 0) {
                    return acc + ' ' + cur;
                }
                return acc;
            }, '');
        } else {
            this.rowClasses = '';
        }
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableRow = TableRow;
