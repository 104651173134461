// Will construct an array of date strings incrementing in ascending order until and including the input
// recordDate. The total length of the resulting array will be evolutionRange + 1.
// Example:
// input: makeEvolutionDateStrArray('2020-05-01', 3);
// output: ["2020-04-28", "2020-04-29", "2020-04-30", "2020-05-01"]
export function makeEvolutionDateStrArray(recordDate, evolutionRange) {
    let dateHelper = window.infinito.vao.controller.dateHelper;
    let evolutionStart = dateHelper.dateStrToDate(
        null,
        recordDate
    );
    let evolutionEnd = dateHelper.dateStrToDate(
        null,
        dateHelper.subtractDaysFromDateString(recordDate, evolutionRange)
    );
    let dates = dateHelper.calcDateRangeArray(evolutionEnd, evolutionStart);
    let evolutionDates = [];
    dates.forEach((date) => {
        let evd = dateHelper.dateToDateStr(null, date);
        if (evd) {
            evolutionDates.push(evd);
        }
    });
    return evolutionDates;
}