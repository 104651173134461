import { AppElement, html } from '../AppElement.js';
import TableLoadingRow from "../table/TableLoadingRow";
import TableNoDataRow from "../table/TableNoDataRow";
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import Constants from "../Constants";
import TableRow from "../table/TableRow";
import MiniContext from "../miniContext/MiniContext";
import VirtualAnalyst from "../virtualAnalayst/VirtualAnalyst";
import {isJSONString} from "../Utils";
import ColorArray from "../ColorArray";
import {getIdentityDependantTableStateKey} from "../TableUtils";
import Table from "../table/Table";
import {storyHtml, StoryText, storyTextTypes} from "../StoryTextElement";
import {sendRequest} from "../App.common";
import {mapCompPacePerSetRank, mapRatePacePerSetRank} from "../StoryPitchMappings";

// Additionally, there will be dynamic columns for the competitive set names.
export const cellFieldMap = {
    stayDate: 'stayDate',
    notes: 'notes',
    occ: 'occ',
    fcpercent: 'fcpercent',
    buffer: 'buffer',
    rate: 'rate',
    seasons: 'seasons',
    frameworkrate: 'frameworkrate'
};

let requestCount = 0; // Use this to make sure that only the latest ajax request can change internal properties.

export default class PageGroupBufferSingleTable extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            startDate: { type: String },
            endDate: { type: String },

            // @internalProperty
            tableOpts: { attribute: false },
            columns: { attribute: false },
            rows: { attribute: false },
            isLoading: { attribute: false },
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel || this.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;

        this.tableStateKey = getIdentityDependantTableStateKey('PageGroupBufferSingleTable');
        this.tableOpts = {
            ...Table.getDefaultTableOpts(),
            isStickyHeader: true,
            stickyHeaderMinHeight: '74vh'
        };
        this.columns = this.makeStaticColumns();
        this.rows = [];
        this.isLoading = false;
        this.tableCellCache = new Map();
        this.roomsPerHousekeeper = 0;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;
    }

    makeStaticColumns() {
        const columnKeyToStringMap = {
            [cellFieldMap.stayDate]: Constants.STRINGS.STAY_DATE,
            [cellFieldMap.notes]: 'Notes',
            [cellFieldMap.seasons]: 'Seasons',
            [cellFieldMap.occ]: 'OTB Occ %',
            [cellFieldMap.rate]: 'Selling Rate',
            [cellFieldMap.fcpercent]: 'Forecast %',
            [cellFieldMap.buffer]: 'Max Rms',
            [cellFieldMap.frameworkrate]:'Walk Rate'
        };
        let columns = [];
        Object.keys(columnKeyToStringMap).forEach(key => {
            columns.push(new TableColumn(
                new TableCell({
                    field: key,
                    value: columnKeyToStringMap[key],
                    type: tableCellTypes.TH
                }),
                [],
                undefined,
                null,
                true,
                false
            ));
        });
        return columns;
    }

    onClickStayDate(stayDate, e) {
        const prettyRecordDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
            this.recordDate
        );
        const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
            stayDate
        );
        const prettyPickupSetting = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
            this.pickupSetting
        );

        window.infinito.components.basicModal.render({
            title: $('<div></div>').append('<h5>Virtual Analyst</h5>').append(new MiniContext({
                recordDate: prettyRecordDate,
                stayDate: prettyStayDate,
                pickupSetting: prettyPickupSetting,
                isModalStyle: false
            })),
            isTitleHtml: true,
            bodyElem: $(new VirtualAnalyst({
                recordDate: this.recordDate,
                stayDate: stayDate,
                pickupSetting: this.pickupSetting,
                hotel: this.hotel,
            }))
        });
    }
    acquireTableCell(stayDate, props) {
        const key = `__@${props.field}_${stayDate}__`;
        if (this.tableCellCache.has(key)) {
            let existingTableCell = this.tableCellCache.get(key);
            existingTableCell.reflow(props);
            return existingTableCell;
        }
        let newTableCell = new TableCell(props);
        this.tableCellCache.set(key, newTableCell);
        return newTableCell;
    }
    makeGradientColorCoderForMinAndMax(min, max) {
        const colorBuckets = window.infinito.vao.controller.gradientColorHelper.getColorBucketsForMinForMax(min, max);
        return function (statisticValue) {
            if (!$.isNumeric(statisticValue)) {
                return null;
            }
            const floatValue = parseFloat(statisticValue);
            if (floatValue === 0 || floatValue === 0.0) {
                return null;
            }
            return window.infinito.vao.controller.gradientColorHelper.getColorForValueForColorBuckets(
                floatValue,
                colorBuckets,
                null
            );
        }
    }
    makeGradientColorCoder(procData, key) {
        let values = [];
        let min, max;
        if (
            procData
            && typeof procData === 'object'
            && procData[key]
            && typeof procData[key] === 'object'
        ) {
            Object.values(procData[key]).forEach(statistic => {
                if (
                    statistic
                    && typeof statistic === 'object'
                    && 'value' in statistic
                    && $.isNumeric(statistic.value)
                ) {
                    values.push(parseFloat(statistic.value));
                }
            });
        }
        if (values.length <= 1) {
            // No point coloring.
            min = null;
            max = null;
        } else {
            min = Math.min(...values);
            max = Math.max(...values);
        }

        return this.makeGradientColorCoderForMinAndMax(min, max);
    }
    makeAdrMoveGradientColorCoder(procData) {
        return this.makeGradientColorCoder(procData, 'adrPickup');
    }
    formatPercentageValue(unformattedValue) {
        var percent = parseFloat(unformattedValue * 100);
        if (!Number.isNaN(percent)) {
            return percent.toFixed(1) + '%';
        }
        return unformattedValue;
    }
    fill() {
        if (
            !this.hotel
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.startDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.endDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            this.rows = [];
            return;
        }

        const biStatistics = window.infinito.vao.model.biStatistics;
        const dates = window.infinito.vao.controller.dateHelper.calcStayDateRangeArray(this.startDate, this.endDate);
        this.isLoading = true;
        this.rows = [];

        requestCount++;
        const preRequestCount = requestCount;

        (new Promise((resolve) => {
            const vaoSettings = window.infinito.vao.model.vaoSettings;
            const housekeeperSettingKey = vaoSettings.obtainHotelIdHousekeeperPerShiftKey(
                this.hotel.id
            );
            vaoSettings.fetchSettingByKey(
                window.infinito.vao.controller.storageHelper.getIdentitiesId(),
                housekeeperSettingKey,
                (setting) => {
                    if (setting && $.isNumeric(setting.value)) {
                        this.roomsPerHousekeeper = parseInt(setting.value);
                    }
                    resolve();
                }
            );
        })).then(() => {

            biStatistics.fetchStatistics(
                this.hotel.id,
                biStatistics.buildQuery({
                    recordDate: this.recordDate,
                    firstStayDate: this.startDate,
                    lastStayDate: this.endDate,
                    fields: [
                        biStatistics.fields.rate,
                        biStatistics.fields.occupancyLeaderCloserTrendProphecy,
                        biStatistics.fields.occupancy
                    ]
                }),
                (data, procData) => {

                    if (requestCount !== preRequestCount) {
                        return; // Another ajax has taken over.
                    }

                    const _procData = (procData || {});
                    if (!_procData || Object.keys(_procData).length === 0) {
                        this.isLoading = false;
                        return;
                    }
                    let rows = [];
                    dates.forEach(stayDate => {
                        let rowCells = [];
                        const prettyStayDate = window.infinito.vao.controller.dateHelper
                            .getShortDayDateMonFullYearString(stayDate);
                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.stayDate,
                            value: html`
                            <vao-button 
                                text="${prettyStayDate}" 
                                variant="invert" 
                                size="xs"
                                
                            ></vao-button>`
                        }));

                        let notes = this.fetchNotes(stayDate,this.hotel.id,rowCells);
                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.notes,
                            value: ''
                        }));

                        let occProphecy = ((_procData.occupancyLeaderCloserTrendProphecy || {})[stayDate] || {}).value || null;
                        if (
                            _procData.occupancy
                            && _procData.occupancy[stayDate]
                            && 'value' in _procData.occupancy[stayDate]
                            && $.isNumeric(_procData.occupancy[stayDate].value)
                        ) {

                            const occupancyvalue = _procData.occupancy[stayDate].value;
                            var occbadgeClass = '';
                            if (occupancyvalue < 0.29) {
                                occbadgeClass = 'color:#030289;';
                            } else if (occupancyvalue > 0.29 && occupancyvalue < 0.39) {
                                occbadgeClass = 'color:#3e2f6b;';
                            } else if (occupancyvalue >= 0.39 && occupancyvalue < 0.49) {
                                occbadgeClass = 'color:#67527c;';
                            } else if (occupancyvalue >= 0.49 && occupancyvalue < 0.59) {
                                occbadgeClass = 'color:#916e40;';
                            } else if (occupancyvalue >= 0.59 && occupancyvalue < 0.69) {
                                occbadgeClass = 'color:#c89724;';
                            } else if (occupancyvalue >= 0.69 && occupancyvalue < 0.79) {
                                occbadgeClass = 'color:#f3b80d;';
                            } else if (occupancyvalue >= 0.79 && occupancyvalue < 0.89) {
                                occbadgeClass = 'color:#c4ba17;';
                            } else if (occupancyvalue >= 0.89 && occupancyvalue < 0.95) {
                                occbadgeClass = 'color:#99b524;';
                            } else if (occupancyvalue > 0.95 && occupancyvalue <= 1) {
                                occbadgeClass = 'color:#4aab3b;';
                            } else {
                                occbadgeClass = 'color:green;';
                            }

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.occ,
                                value: html`<div class="vao__components--storyForecastSubtitle" style="${occbadgeClass}">
                                                ${this.formatPercentageValue(_procData.occupancy[stayDate].value)}
                                            </div>`
                            }));
                        }

                        this.fn_GetForecastAndBuffervalues(occProphecy,stayDate,rowCells);
                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.fcpercent,
                            value: ''
                        }));

                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.buffer,
                            value: ''
                        }));
                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.frameworkrate,
                            value: ''
                        }));
                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.seasons,
                            value: ''
                        }));

                        if (
                            _procData.rate
                            && _procData.rate[stayDate]
                            && 'value' in _procData.rate[stayDate]
                        ) {
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                _procData.rate[stayDate].value,
                                Constants.RATE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.rate,
                                value: formatted
                            }));
                        }

                        rows.push(new TableRow(rowCells));
                    });

                    this.rows = rows;
                    this.isLoading = false;
                }
            );
        });
    }
    onClickNotes(e,hotels_id,stayDate,rowCells){

        let body = '<vao-modal-notes-modal stayDate="'+e+'" hotel_id="'+hotels_id+'" rowCells="'+rowCells+'"></vao-modal-notes-modal>';
        let stay_date = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(e);
        stay_date = `<h5>Notes - ${stay_date}</h5>`;
        let $title =  $('<div></div>').append(stay_date);
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'lg'
        });
    }
    fetchNotes(stayDate,hotels_id,rowCells){
        let dataObj = {
            //'hotel_id': this.hotel.id,
            'hotel_id': hotels_id,
            'stayDate': stayDate,
            'op': 'getNotesCount',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            let counttext = jsonObj.data.count;
            let counthtml = html `<span id="${stayDate}">${counttext}</span>`;

            if(counttext==0){
                counthtml = html `<span id="${stayDate}">${Constants.MISSING_STR}</span>`;
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.notes,
                    value: html` <vao-button
                                .text="${counthtml}"
                                variant="invert"
                                size="xs"
                                @click=${flag.onClickNotes.bind(this,stayDate,hotels_id,rowCells)}
                            ></vao-button>`,
                }));
            }else{
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.notes,
                    value: html `<vao-button
                            .text="${counthtml}"
                            variant="invert"
                            size="xs"
                            @click=${flag.onClickNotes.bind(this,stayDate,hotels_id,rowCells)}
                    ></vao-button>`,
                }));
            }

        });

    }
    fn_GetForecastAndBuffervalues(occProphecy,stayDate,rowCells){
        if (
            typeof occProphecy === 'object'
            && 'leadersClosersProphecy' in occProphecy
            && $.isNumeric(occProphecy.leadersClosersProphecy)
        ) {

            var fForecast = parseFloat((occProphecy.leadersClosersProphecy || Constants.MISSING_STR));
            var fcolor = '';
            var ForecastValue = 0;
            if (fForecast < 0.29) {
                ForecastValue = 29;
                fcolor = 'color:#030289;';
            } else if (fForecast > 0.29 && fForecast < 0.39) {
                fcolor = 'color:#3e2f6b;';
                ForecastValue = 39;
            } else if (fForecast >= 0.39 && fForecast < 0.49) {
                fcolor = 'color:#67527c;';
                ForecastValue = 49;
            } else if (fForecast >= 0.49 && fForecast < 0.59) {
                fcolor = 'color:#916e40;';
                ForecastValue = 59;
            } else if (fForecast >= 0.59 && fForecast < 0.69) {
                fcolor = 'color:#c89724;';
                ForecastValue = 69;
            } else if (fForecast >= 0.69 && fForecast < 0.79) {
                fcolor = 'color:#f3b80d;';
                ForecastValue = 79;
            } else if (fForecast >= 0.79 && fForecast < 0.89) {
                fcolor = 'color:#c4ba17;';
                ForecastValue = 89;
            } else if (fForecast >= 0.89 && fForecast < 0.95) {
                fcolor = 'color:#99b524;';
                ForecastValue = 94;
            } else if (fForecast > 0.95 && fForecast <= 1) {
                fcolor = 'color:#4aab3b;';
                ForecastValue = 100;
            } else {
                ForecastValue = 101;
                fcolor = 'color:green;';
            }

        } else {
            ForecastValue = Constants.MISSING_STR;
            fcolor = 'color:black;';
        }

        let postData = {
            'hotel_id': this.hotel.id,
            'stayDate':stayDate,
            'recordDate':this.recordDate,
            "identity_id":infinito.vao.controller.storageHelper.getIdentitiesEmail(),
            'op': 'getStaydateWiseHotelBuffer',
            'serviceName': "hotelbufferrates.php"
        };
        let flag = this;
        sendRequest(postData,function(res){
            let Jsonobj = JSON.parse(res);

            if(Jsonobj['status']==="failure"){

                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.buffer,
                    value: html`<span ="color: black;font-weight: bold;">Ask Revenue<span>`
                }));

                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.frameworkrate,
                    value: html`<span ="color: black;font-weight: bold;">Ask Revenue<span>`
                }));

                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.seasons,
                    value: ''
                }));
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.fcpercent,
                    value: html`<span style="${fcolor}">${ForecastValue}%</span>`
                }));

            }else if(Jsonobj['status']==="success"){
                if(Jsonobj.data['closeout']==="1" || Jsonobj.data['closeout']===1){
                    rowCells.push(flag.acquireTableCell(stayDate, {
                        field: cellFieldMap.buffer,
                        value: html`<span ="color: black;font-weight: bold;">Ask Revenue<span>`
                    }));

                    rowCells.push(flag.acquireTableCell(stayDate, {
                        field: cellFieldMap.frameworkrate,
                        value: html`<span ="color: black;font-weight: bold;">Ask Revenue<span>`
                    }));
                    return;
                }
                let rate = Jsonobj.data['rate'];
                let buffer = Jsonobj.data['buffer'];
                let availablerooms = Jsonobj.data['availablerooms'];
                let season_name = Jsonobj.data['season_name'];
                let totalbuffer = parseFloat(buffer) + parseFloat(ForecastValue);
                let roomspart = Math.round((availablerooms*totalbuffer)/100);
                let bufferValue = availablerooms-roomspart;

                if(totalbuffer>=100){
                    rowCells.push(flag.acquireTableCell(stayDate, {
                        field: cellFieldMap.buffer,
                        value: html`<span ="color: black;font-weight: bold;">Ask Revenue<span>`
                    }));
                }else{
                    rowCells.push(flag.acquireTableCell(stayDate, {
                        field: cellFieldMap.buffer,
                        value: html`<span style="${fcolor}">${bufferValue}</span>`
                    }));
                }
                let seasontext = '';
                let scolor = '';
                if(season_name==="Event"){
                    seasontext = 'PK';
                    scolor = 'background:#ff1c1c;color:white;';
                }else if(season_name==="High"){
                    seasontext = 'HI';
                    scolor = 'background:#ff6e41;color:white;';
                }else if(season_name==="Medium"){
                    seasontext = 'MD';
                    scolor = 'background:#ffc107;color:white;';
                }else if(season_name==="Low"){
                    seasontext = 'LO';
                    scolor = 'background:#03a9f4;color:white;';
                }
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.seasons,
                    value: html`<span style="${scolor}">${seasontext}</span>`
                }));
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    rate,
                    Constants.RATE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.frameworkrate,
                    value: formatted
                }));
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.fcpercent,
                    value: html`<span style="${fcolor}">${totalbuffer}%</span>`
                }));
            }
        });

    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('hotel')
            || _changedProperties.has('recordDate')
            || _changedProperties.has('pickupSetting')
            || _changedProperties.has('startDate')
            || _changedProperties.has('endDate')
        ) {
            this.fill();
        }
    }

    onChangeTableOpts(e) {
        if (e.detail.newTableOpts) {
            this.tableOpts = e.detail.newTableOpts; // Share obj with table to not cause re-render.
        }
    }

    render() {
        return html`
<div class="vao__components--pageDesktopSingleTable">
    <vao-table 
        stateKey="${this.tableStateKey}"
        .tableOpts="${this.tableOpts}"
        .columns="${this.columns}"
        .rows="${this.isLoading ? [new TableLoadingRow()] : this.rows.length > 0 ? this.rows : [new TableNoDataRow()]}"
        @vao-table-tableopts-change="${this.onChangeTableOpts}"
    ></vao-table>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageGroupBufferSingleTable = PageGroupBufferSingleTable;
customElements.define('vao-page-page-buffer', PageGroupBufferSingleTable);
