import { AppElement, html } from '../AppElement.js';
import TableNoDataRow from "../table/TableNoDataRow";
import Table from "../table/Table";
import TableLoadingRow from "../table/TableLoadingRow";
import TableCell, {tableCellTypes} from "../table/TableCell";
import TableColumn from "../table/TableColumn";
import Constants from "../Constants";
import TableRow from "../table/TableRow";
import TableCellValueInput, {EVENT_TABLE_CELL_VALUE_INPUT_CHANGE, INPUT_TYPES} from "../table/TableCelIValueInput";
import BudgetFormTableActionCell, {
    EVENT_BUDGET_FORM_TABLE_ACTION_CELL_DIFF,
    EVENT_BUDGET_FORM_TABLE_ACTION_CELL_RESET,
    EVENT_BUDGET_FORM_TABLE_ACTION_CELL_SAVE
} from "./BudgetFormTableActionCell";
import BudgetUtils from "./BudgetUtils";
import BudgetDiff from "./BudgetDiff";

const cellFieldMap = {
    date: 'date',
    availableRooms: 'available',
    roomsOtb: 'roomsOtb',
    revenue: 'revenue',
    action: 'action'
};

export default class BudgetFormTable extends AppElement {
    static get properties() {
        return {
            // props
            aggregator: { type: String },
            year: { type: String },
            hotel: { type: Object },

            // state
            isLoading: { type: String },
            budgetValues: { type: Array },
        };
    }

    constructor(props = {}) {
        super();

        this.aggregator = props.aggregator;
        this.year = props.year;
        this.hotel = props.hotel;

        this.tableOpts = {
            canShowToolbar: false
        };
        this.cache = new Map();
        this.empty();
        this.getTable();

        this.addEventListener(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_SAVE, this.handleBudgetFormTableActionCellSave);
        this.addEventListener(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_DIFF, this.handleBudgetFormTableActionCellDiff);
        this.addEventListener(EVENT_BUDGET_FORM_TABLE_ACTION_CELL_RESET, this.handleBudgetFormTableActionCellReset);
        this.addEventListener(EVENT_TABLE_CELL_VALUE_INPUT_CHANGE, this.handleTableCellInputChange);
    }

    reflow(props = {}) {
        this.aggreagtor = props.aggregator || this.aggreagtor;
        this.year = props.year || this.year;
        this.hotel = props.hotel || this.hotel;
    }

    empty() {
        this.isLoading = false;
        this.budgetValues = [];
    }

    makeDateCellValue(budgetValue) {
        return BudgetUtils.formatDate(budgetValue);
    }

    postBudgetValue(budgetValue) {
        const apiModel = window.infinito.vao.model.biBudgets;
        apiModel.post(
            this.hotel.id,
            {
                startDate: budgetValue.startDate,
                endDate: budgetValue.endDate,
                roomType: budgetValue.roomType,
                marketSegment: budgetValue.marketSegment,
                availableRooms: this.getMetricFromBudgetValue(budgetValue, 'availableRooms'),
                roomsOtb: this.getMetricFromBudgetValue(budgetValue, 'roomsOtb'),
                revenue: this.getMetricFromBudgetValue(budgetValue, 'revenue')
            },
            (errorMsg, data) => {
                if (errorMsg) {
                    console.error(errorMsg);
                    this.reduceBudgetValues({
                        ...budgetValue,
                        hasError: 'true',
                        isPosting: 'false',
                    });
                } else {
                    this.reduceBudgetValues({
                        ...data.data,
                        hasError: 'false',
                        isPosting: 'false',
                    });
                }
            }
        )
    }

    handleTableCellInputChange(e) {
        const budgetValue = e.detail.eventData.budgetValue;
        const metric = e.detail.eventData.metric;
        const event = e.detail.event;
        const element = e.detail.that;
        this.onChangeTableRowInput(event, element, budgetValue, metric);
    }

    handleBudgetFormTableActionCellSave(e) {
        const budgetValue = e.detail.budgetValue;
        const match = this.budgetValues.find(possibleMatch => {
             return possibleMatch.key === budgetValue.key;
        });
        if (match) {
            this.reduceBudgetValues({
                ...match,
                isPosting: 'true',
            });
            this.postBudgetValue(budgetValue);
        }
    }

    handleBudgetFormTableActionCellDiff(e) {
        const budgetValue = e.detail.budgetValue;
        const budgetDiffTitle = 'Budget Diff';
        window.infinito.components.basicModal.render({
            title: budgetDiffTitle,
            bodyElem: $(new BudgetDiff({
                budgetValue
            }))
        });
    }

    handleBudgetFormTableActionCellReset(e) {
        const budgetValue = e.detail.budgetValue;
        this.reduceBudgetValues({
            ...budgetValue,
            diff: new Map()
        });
    }

    getCachedBudgetFormTableActionCell(budgetValue) {
        const key = 'budget-form-table-action-cell-' + budgetValue.key;
        const opts = {
            budgetValue,
            isDisabled: (budgetValue.diff || new Map()).size > 0 ? 'false' : 'true',
        };
        return this.acquireCacheElement(opts, key, (constructorOpts) => {
            return new BudgetFormTableActionCell(constructorOpts);
        });
    }

    reduceBudgetValues(changedBudgetValue) {
        this.budgetValues = this.budgetValues.map(budgetValue => {
            if (budgetValue.key === changedBudgetValue.key) {
                return changedBudgetValue;
            }  else {
                return budgetValue;
            }
        });
    }

    onChangeTableRowInput(event, element, budgetValue, metric) {
        const value = event.currentTarget.value;
        const diff = budgetValue.diff || new Map();
        if (element.isDirty === 'true') {
            diff.set(metric, value);
        } else {
            diff.delete(metric);
        }
        this.reduceBudgetValues({
            ...budgetValue,
            diff,
            hasError: 'false'
        });
    }

    getMetricFromBudgetValue(budgetValue, metric) {
        const diff = budgetValue.diff || new Map();
        if (diff.has(metric)) {
            return diff.get(metric);
        }
        return budgetValue[metric];
    }

    getTableCellKey(budgetValue, fieldMapKey) {
        return 'vao-table-cell-' + cellFieldMap[fieldMapKey] + '-' + budgetValue.key;
    }

    acquireCacheElement(opts, cacheKey, newElementCreator) {
        if (this.cache.has(cacheKey)) {
            const existingElement = this.cache.get(cacheKey);
            existingElement.reflow(opts);
            return existingElement;
        }
        const newElement = newElementCreator(opts);
        this.cache.set(cacheKey, newElement);
        return newElement;
    }

    makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value) {
        const opts = {
            field: fieldMapKey,
            value
        };
        return this.acquireCacheElement(opts, cellKey, (constructorOpts) => {
            return new TableCell(constructorOpts);
        });
    }

    makeTableCellDate(budgetValue) {
        const fieldMapKey = cellFieldMap.date;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeDateCellValue(budgetValue);
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellNumberInput(budgetValue, metricKey, step) {
        const cacheKey = 'makeTableCellNumberInput-' + metricKey + '-' + budgetValue.key;
        const opts = {
            eventData: {
                budgetValue,
                metric: metricKey
            },
            initialValue: budgetValue[metricKey],
            value: this.getMetricFromBudgetValue(budgetValue, metricKey),
            type: INPUT_TYPES.number,
            step,
            isDisabled: budgetValue.isPosting,
            isError: budgetValue.hasError,
        };
        return this.acquireCacheElement(opts, cacheKey, (constructorOpts) => {
            return new TableCellValueInput(constructorOpts);
        });
    }

    makeTableCellAvailable(budgetValue) {
        const fieldMapKey = cellFieldMap.availableRooms;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeTableCellNumberInput(budgetValue, 'availableRooms', '1');
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellRooms(budgetValue) {
        const fieldMapKey = cellFieldMap.roomsOtb;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeTableCellNumberInput(budgetValue, 'roomsOtb', '1');
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellRevenue(budgetValue) {
        const fieldMapKey = cellFieldMap.revenue;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.makeTableCellNumberInput(budgetValue, 'revenue', 'any');
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    makeTableCellAction(budgetValue) {
        const fieldMapKey = cellFieldMap.action;
        const cellKey = this.getTableCellKey(budgetValue, fieldMapKey);
        const value = this.getCachedBudgetFormTableActionCell(budgetValue);
        return this.makeTableCellByFieldMapKeyByCellKeyByValue(fieldMapKey, cellKey, value);
    }

    getTableRows() {
        return this.budgetValues.map(budgetValue => {
            return new TableRow([
                this.makeTableCellDate(budgetValue),
                this.makeTableCellAvailable(budgetValue),
                this.makeTableCellRooms(budgetValue),
                this.makeTableCellRevenue(budgetValue),
                this.makeTableCellAction(budgetValue),
            ], budgetValue)
        });
    }

    reflowTable() {
        const rows = this.getTableRows();
        let innerRows;
        if (this.isLoading) {
            innerRows = [
                new TableLoadingRow()
            ];
        } else if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        table.reflow({
            columns: table.columns,
            rows: innerRows,
        });
        return table;
    }

    makeTableColumns() {
        if (!this.cache.has('tableColumns')) {
            this.cache.set('tableColumns', [
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.date,
                        value: Constants.STRINGS.DATE,
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.availableRooms,
                        value: BudgetUtils.getNameForMetric('availableRooms'),
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.roomsOtb,
                        value: BudgetUtils.getNameForMetric('roomsOtb'),
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.revenue,
                        value: BudgetUtils.getNameForMetric('revenue'),
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
                new TableColumn(
                    new TableCell({
                        field: cellFieldMap.action,
                        value: Constants.STRINGS.ACTION,
                        rowSpan: 1,
                        colSpan: 1,
                        type: tableCellTypes.TH
                    })
                ),
            ]);
        }
        return this.cache.get('tableColumns');
    }

    getTable() {
        if (!this.cache.has('table')) {
            this.cache.set('table', new Table({
                columns: this.makeTableColumns(),
                rows: [],
                tableOpts: this.tableOpts
            }));
        }
        return this.cache.get('table');
    }

    canFill() {
        if (
            this.hotel
            && this.hotel.id
        ) {
            return true;
        }
        return false;
    }

    fill() {
        const apiModel = window.infinito.vao.model.biBudgets;
        this.isLoading = true;
        apiModel.fetch(
            this.hotel.id,
            {
                'aggregator': this.aggregator,
                'year': this.year,
                'canPadGroups': 'true',
                'isOnlyTotals': 'true'
            },
            (errorMsg, data) => {
                this.isLoading = false;
                if (errorMsg) {
                    console.error(errorMsg);
                    this.budgetValues = [];
                    return; // TODO handle error
                }
                this.budgetValues = data.data;
            }
        );
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('aggregator')
            || _changedProperties.has('year')
            || _changedProperties.has('hotel')
        ) {
            this.empty();
            if (this.canFill()) {
                this.fill();
            }
        }
        if (
            _changedProperties.has('isLoading')
            || _changedProperties.has('budgetValues')
        ) {
            this.reflowTable();
        }
    }

    render() {
        const table = this.cache.get('table');

        return html`
<div class="vao__components--budgeFormTable"> 
    <div style="margin-bottom:1rem;">
        <vao-budget-latest-ts-badge .hotel="${this.hotel}" .budgetValues="${this.budgetValues}">
        </vao-budget-latest-ts-badge>
    </div>
    ${table}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetFormTable = BudgetFormTable;
customElements.define('vao-budget-form-table', BudgetFormTable);
