import { html } from '../AppElement.js';
import Yesterday from './Yesterday';
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import Table from "../table/Table";
import TableNoDataRow from "../table/TableNoDataRow";
import TableRow from "../table/TableRow";
import Constants from "../Constants";
import {formatOccupancy} from "../Utils";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";

const cellFieldMap = {
    kpi: 'kpi',
    actual: 'actual',
    pu: 'pickup',
};

const tableRowTitles = {
    otb: 'OTB',
    occ: 'Occupancy',
    adr: 'ADR',
    rev: 'Revenue'
};

export default class YesterdayGroup extends Yesterday {

    static get properties() {
        return {
            recordDate: { type: String },
            hotelGroup: { type: Object }
        };
    }

    constructor(props = {}) {
        super(props);
        this.hotelGroup = props.hotelGroup;
    }

    reflow(props = {}) {
        super.reflow(props);
        this.hotelGroup = props.hotelGroup || this.hotelGroup;
        this.fill();
    }

    fill() {
        if (
            !this.hotelGroup
            || typeof this.hotelGroup !== 'object'
            || !('id' in this.hotelGroup)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.yesterday)
        ) {
            return;
        }

        let rows = this.makeTableRows();
        let table = this.reflowTable(rows);
        this.domTheTable(table);

        let biStatistics = window.infinito.vao.model.biHotelGroupsStatistics;
        biStatistics.fetchStatistics(
            this.hotelGroup.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.yesterday,
                lastStayDate: this.yesterday,
                fields: [
                    biStatistics.fields.roomsOtb,
                    biStatistics.fields.roomsOtbPickup,
                    biStatistics.fields.occupancy,
                    biStatistics.fields.occupancyPickup,
                    biStatistics.fields.adr,
                    biStatistics.fields.adrPickup,
                    biStatistics.fields.revenue,
                    biStatistics.fields.revenuePickup
                ],
                pickupOffset: -1
            }),
            (data, procData) => {
                const _procData = (procData || {});
                const roomsOtb = ((_procData.roomsOtb || {})[this.yesterday] || {}).value;
                const roomsOtbPickup = ((_procData.roomsOtbPickup)[this.yesterday] || {}).value;
                const occupancy = ((_procData.occupancy)[this.yesterday] || {}).value;
                const occupancyPickup = ((_procData.occupancyPickup)[this.yesterday] || {}).value;
                const adr = ((_procData.adr)[this.yesterday] || {}).value;
                const adrPickup = ((_procData.adrPickup)[this.yesterday] || {}).value;
                const revenue = ((_procData.revenue)[this.yesterday] || {}).value;
                const revenuePickup = ((_procData.revenuePickup)[this.yesterday] || {}).value;
                let cell, row;
                let foundAnyData = false;

                if (
                    $.isNumeric(roomsOtb)
                    || $.isNumeric(roomsOtbPickup)
                    || $.isNumeric(occupancy)
                    || $.isNumeric(occupancyPickup)
                    || $.isNumeric(adr)
                    || $.isNumeric(adrPickup)
                    || $.isNumeric(revenue)
                    || $.isNumeric(revenuePickup)
                ) {
                    foundAnyData = true;
                }
                if (!foundAnyData || !procData || Object.keys(procData).length === 0) {
                    this.empty();
                    return;
                }

                // OTB.
                row = this.makeTableRow(tableRowTitles.otb);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                if ($.isNumeric(roomsOtb)) {
                    cell.value = String(roomsOtb);
                } else {
                    cell.value = Constants.MISSING_STR;
                }
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.pu);
                cell.value = new NumericVarianceBlock({
                    numericValue: roomsOtbPickup,
                    formattedValue: super.formatNumeric(roomsOtbPickup)
                });
                cell.isLoading = false;

                // Occupancy.
                row = this.makeTableRow(tableRowTitles.occ);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                cell.value = formatOccupancy(occupancy);
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.pu);
                cell.value = new NumericVarianceBlock({
                    numericValue: occupancyPickup,
                    formattedValue: formatOccupancy(occupancyPickup)
                });
                cell.isLoading = false;

                // ADR.
                row = this.makeTableRow(tableRowTitles.adr);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                if ($.isNumeric(adr)) {
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adr,
                        Constants.ADR_DIGITS,
                        true,
                        this.hotelGroup.locale
                    );
                } else {
                    cell.value = Constants.MISSING_STR;
                }
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.pu);
                cell.value = new NumericVarianceBlock({
                    numericValue: adrPickup,
                    formattedValue: super.formatCurrency(adrPickup, Constants.ADR_DIGITS)
                });
                cell.isLoading = false;

                // Revenue.
                row = this.makeTableRow(tableRowTitles.rev);
                cell = row.tableCellPerField.get(cellFieldMap.actual);
                if ($.isNumeric(revenue)) {
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenue,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotelGroup.locale
                    );
                } else {
                    cell.value = Constants.MISSING_STR;
                }
                cell.isLoading = false;
                cell = row.tableCellPerField.get(cellFieldMap.pu);
                cell.value = new NumericVarianceBlock({
                    numericValue: revenuePickup,
                    formattedValue: super.formatCurrency(revenuePickup, Constants.REVENUE_DIGITS)
                });
                cell.isLoading = false;
            }
        )
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        let cols = [
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.kpi,
                    value: 'KPI',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.actual,
                    value: 'Actual',
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.pu,
                    value: 'On The Day Pickup',
                    type: tableCellTypes.TH
                })
            ),
        ];
        this.cache['cols'] = cols;
        return cols;
    }

    getTable() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            let table = new Table({
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ],
                tableOpts: {
                    canShowToolbar: false,
                }
            });
            this.cache['table'] = table;
        }

        return this.cache.table;
    }

    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows
            });
        }

        return table;
    }

    domTheTable(table) {
        $('#' + this.id).find('.vao__components--yesterdayGroup-table').empty().append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    makeTableRow(title) {
        this.cache.rowMap = this.cache.rowMap || new Map();

        if (this.cache.rowMap.has(title)) {
            return this.cache.rowMap.get(title);
        }

        const row = new TableRow([
            new TableCell({
                field: cellFieldMap.kpi,
                value: title
            }),
            new TableCell({
                field: cellFieldMap.actual,
                value: null,
                isLoading: true
            }),
            new TableCell({
                field: cellFieldMap.pu,
                value: null,
                isLoading: true
            }),
        ]);

        this.cache.rowMap.set(title, row);
        return row;
    }

    makeTableRows() {
        let rows = [];
        Object.values(tableRowTitles).forEach(title => {
            rows.push(this.makeTableRow(title));
        });
        return rows;
    }

    renderYesterday() {
        return html`<div class="vao__components--yesterdayGroup-table"></div>`;
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.YesterdayGroup = YesterdayGroup;
customElements.define('vao-yesterday-group', YesterdayGroup);
