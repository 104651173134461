import { AppElement, html } from '../AppElement.js';
import TableLoadingRow from "../table/TableLoadingRow";
import TableNoDataRow from "../table/TableNoDataRow";
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import Constants from "../Constants";
import TableRow from "../table/TableRow";
import MiniContext from "../miniContext/MiniContext";
import VirtualAnalyst from "../virtualAnalayst/VirtualAnalyst";
import {isJSONString} from "../Utils";
import ColorArray from "../ColorArray";
import {getIdentityDependantTableStateKey} from "../TableUtils";
import Table from "../table/Table";
import {storyHtml, StoryText, storyTextTypes} from "../StoryTextElement";
import {sendRequest} from "../App.common";

const data = require('../adhoctemplate_operators.json');
const data2 = require('../template2.json');
const dataocc = require('../template_occ.json');
const data_positioning = require('../template_positioning.json');
const data_forecast = require('../template_forecast.json');
const data_leadtime = require('../template_leadtime.json');

// Additionally, there will be dynamic columns for the competitive set names.
export const cellFieldMap = {
    stayDate: 'stayDate',
    notes: 'notes',
    roomsToSell: 'roomsToSell',
    roomsOtb: 'roomsOtb',
    occupancy: 'occupancy',
    revenue: 'revenue',
    adr: 'adr', 
    roomsOtbVsLastYear: 'roomsOtbVsLastYear',
    revenueVsLastYear: 'revenueVsLastYear',
    adrVsLastYear: 'adrVsLastYear',
    roomsOtbPickup: 'roomsOtbPickup',
    revenuePickup: 'revenuePickup',
    pickupsAdr: 'pickupsAdr',
    adrPickup: 'adrPickup',
    roomsOtbPace: 'roomsOtbPace',
    ratePace: 'ratePace',
    availabilityPace: 'availabilityPace',
    compSetAvgPace: 'compSetAvgPace',
    forecast: 'forecast',
    fcrange: 'fcrange',
    expectedPickup: 'expectedPickup',
    positioning:'positioning',
    details:'details',
    housekeepers: 'housekeepers',
    rate: 'rate',
    ratePickup: 'ratePickup',
    compSetAvg: 'compSetAvg',
};

let requestCount = 0; // Use this to make sure that only the latest ajax request can change internal properties.

export default class PageAdhocSingleTable extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            startDate: { type: String },
            endDate: { type: String },

            // @internalProperty
            tableOpts: { attribute: false },
            columns: { attribute: false },
            rows: { attribute: false },
            isLoading: { attribute: false },

            hotel_id: {type: Object},
            templatedata : [],
            operatorbox: {},
            metricbox: {},
            valuebox: {},
            textbox: {},
            bridgebox: {},
            periodbox: {},
            conditionbox: {},
            initialcount: {type: Number},
            counter: {type: Number},
            schedule_days_obj: {},
            expanded: {},
            FinalDateArr: []
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel || this.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;

        this.tableStateKey = getIdentityDependantTableStateKey('PageDesktopSingleTable');
        this.tableOpts = {
            ...Table.getDefaultTableOpts(),
            isStickyHeader: true,
            stickyHeaderMinHeight: '74vh'
        };
        this.columns = this.makeStaticColumns();
        this.rows = [];
        this.isLoading = false;
        this.tableCellCache = new Map();
        this.roomsPerHousekeeper = 0;

        this.hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();
        this.templatedata = [];
        this.operatorbox = '';
        this.metricbox = '';
        this.valuebox = '';
        this.textbox = '';
        this.bridgebox = '';
        this.periodbox = '';
        this.conditionbox = '';
        this.initialcount = 0;
        this.counter = 1;
        this.schedule_days_obj = {};
        this.expanded = false;
        this.FinalDateArr = [];
    }

    /************************** My UI *****************************/

    articleTemplate() {
        return html`
        <div class="vao__components--pageDesktopSingle">
            <div class="rounded border bg-white" style="padding: 50px 0px;">
                
                <div class="table-responsive div1" id="tr_${this.initialcount}">
                    <table id="table_operator" class="table" style="width:100%; text-align: center">
                        <tbody>
                            <tr>
                                <td style="vertical-align: inherit;width: 15%;">Show me days where</td>
                                <td style="width: 13%;">
                                    <select id="operator_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._operatorHandler}" class="form-control selectbox opblink"></select>
                                </td>
                                <td style="width: 15%;">
                                    <select id="metric_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._metricHandler}" class="form-control selectbox" style="display:none;">
                                        
                                    </select>
                                </td>       
                                <td id="display_textbox_${this.initialcount}" style="min-width:11%">
                                    <input type="text" id="text_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%" placeholder="Value" />
                                            
                                    <div class="input-group selectbox" style="min-width:11%">
                                        <input type="text" id="occ_text_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control" placeholder="Value" aria-label="Value" aria-describedby="basic-addon2" style="display:none; border:none; font-size: 14px; color: #2196f3;">
                                        <div class="input-group-append">
                                            <span id="span_text_box_${this.initialcount}" class="input-group-text" id="basic-addon2" style="display:none; background: none; border: none">%</span>
                                        </div>
                                    </div>

                                    <select id="select_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                                        
                                    </select>
                                    <select id="select_box_pricerank_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                                        
                                    </select>
                                    <select id="select_box_myprice_rate_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                                        
                                    </select>
                                    <select id="select_box_pace_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                                        
                                    </select>
                                    <select id="select_forecast_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                                        
                                    </select>            
                                </td>
                                <td style="min-width: 13%;" id="display_valuebox_${this.initialcount}">
                                    <select id="value_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._valueHandler}" class="form-control selectbox" style="display:none;">
                                        
                                    </select>
                                </td>
                                <td style="width: 7%;" id="display_bridgebox_${this.initialcount}">
                                    <select id="bridge_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._bridgeHandler}" class="form-control selectbox" style="display:none;">
                                        
                                    </select>
                                </td>
                                <td style="width: 11%;" id="display_periodbox_${this.initialcount}">
                                    <select id="period_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._periodHandler}" class="form-control selectbox" style="display:none;">
                                        
                                    </select>
                                </td>
                                <td style="width: fit-content; display: flex;">
                                    <select id="condition_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._conditionHandler}" class="form-control selectbox conditionbox" style="display:none;">
                                        <option value="">-</option> 
                                        <option value="and">AND</option>
                                        <option value="or">OR</option>
                                    </select>
                                    <button type="button" id="addnewtrbtn_${this.initialcount}" key="${this.initialcount}" @click="${this.addnewtr}" class="btn btn-success" style="vertical-align: text-top;border-radius:50px;display:none">
                                        <i class="fa fa-plus"></i>
                                    </button> &nbsp;
                                    <button type="button" id="removenewtrbtn_${this.initialcount}" key="${this.initialcount}" @click="${this.removenewtr}" class="btn btn-danger" style="vertical-align: text-top;border-radius:50px;display:none">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>`;
    }
    
    _operatorHandler(e) {              

        // console.log(e.target.getAttribute('key'));

        // $.each(data, function(k, v) {
        //     console.log(k);
        // });

        let d = e.target.getAttribute('key');
        let dropdown = $('#operator_box_' + d);                
                               
        let dropdown2 = $('#metric_box_' + d);
        $(dropdown2).addClass('opblink');

        let dropdown3 = $('#value_box_' + d);
        let dropdown4 = $('#text_box_' + d);
        let dropdown5 = $('#bridge_box_' + d);
        let dropdown6 = $('#period_box_' + d);
        dropdown.empty();
        dropdown2.empty();
        dropdown3.empty();
        dropdown4.empty();
        dropdown5.empty();
        dropdown6.empty();
        // dropdown.append('<option selected="true" disabled>Please Select</option>');
        dropdown.prop('selectedIndex', 0);

        // Populate dropdown with list of operators                
        for(var i in data){
            // console.log(i);
            dropdown.append($('<option></option>').attr('value', i).text(data [i]['display']));
        }

        $("#metric_box_" + d).css("display","block");

        $('#operator_box_' + d).on('change', function() {

            var operator_val = $("#operator_box_" + d).val();

            var len = $(this).val().length;
            if(operator_val == 'adr'){
                $(this).parent(0).css("width", '6%');                                
            }else if(len > 14){
                $(this).parent(0).css("width", (len - 3) + '%');                                
            }else{
                $(this).parent(0).css("width", len + 1 + '%');
            }            
            // $(this).parent(0).css("width", len + 1 + '%');                                
        });

    }

    _metricHandler(e) {
        this.metricbox = e.target.textContent;

        let d = e.target.getAttribute('key');                          

        $('#metric_box_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("min-width", len + 2 + '%');                                
            $(this).parent(0).css("width", len + '%');   

            var operator_val = $("#operator_box_" + d).val();        
            var metric_val = $("#metric_box_" + d).val();

            $("#display_textbox_" + d).css("display","table-cell");
            $("#text_box_" + d).css("display","table-cell");
            $("#occ_text_box_" + d).css("display","none");
            $("#span_text_box_" + d).css("display","none");
            $("#select_box_" + d).css("display","none");
            $("#select_box_pricerank_" + d).css("display","none");
            $("#select_box_myprice_rate_" + d).css("display","none");
            $("#select_box_pace_" + d).css("display","none");
            $("#select_forecast_" + d).css("display","none");        
                        
            if( operator_val == "rms_to_sell" || operator_val == "rms_sold" || operator_val == "adr" || operator_val == "revenue" || operator_val == "occupancy" || operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "comp_average")
            {
                $("#bridge_box_" + d).css("display","table-cell");                 
            }             
            
            if(operator_val == "occupancy"){
                if(metric_val == "is_equal_to" || metric_val == "is_greater_than" || metric_val == "is_less_than"){
                    $("#text_box_" + d).css("display","none");
                    $("#occ_text_box_" + d).css("display","table-cell");
                    $("#span_text_box_" + d).css("display","table-cell");
                }else{
                    $("#occ_text_box_" + d).css("display","none");
                    $("#span_text_box_" + d).css("display","none");
                }
            }else{                
                    $("#occ_text_box_" + d).css("display","none");       
                    $("#span_text_box_" + d).css("display","none");         
            }

            if(operator_val == "my_pace"){                
                if(metric_val == "is_ahead_by_more_than" || metric_val == "is_behind_by_more_than"){
                    $("#text_box_" + d).css("display","table-cell");
                }   
                if(metric_val == "has_improved" || metric_val == "has_fallen_behind" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + d).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');   
                }                             
            }

            if(operator_val == "positioning" || operator_val == "price_rank"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + d).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');  
                }                                            
            }    
            
            if(operator_val == "expected_pick_up"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){                    
                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell"); 
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");                    
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');  
                }else{
                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","table-cell");
                }                                                      
            }

            if(operator_val == "pick_up_trend"){                                    
                
                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");
                let condition_box = $('#condition_box_' + d);
                $(condition_box).addClass('opblink');                                                                            

            }

            if(operator_val == "forecast_range"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","none");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + d);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');

                }

            }

            if(operator_val == "my_price_or_rate"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");
                    $("#value_box_" + d).css("display","none");
                    $("#select_box_" + d).css("display","none");                
                    $("#select_box_myprice_rate_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + d);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');

                }

            }            

            if(metric_val=="has_no_Change" || operator_val == "my_pace" || operator_val == "expected_pick_up" || operator_val == "positioning" || operator_val == "price_rank"){
                /********* Bridge *****/
                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + d);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);

                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + d);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + d);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + d).css("display","block");
                /********* Bridge *****/
            }
            
        }); 
        
        var operator_val = $("#operator_box_" + d).val();        
        if(operator_val == "rms_to_sell"){
            var parameter = data.rms_to_sell.metric;                      
        }else if(operator_val == "rms_sold") {
            var parameter = data.rms_sold.metric;
        }else if(operator_val == "adr") {
            var parameter = data.adr.metric;
        }else if(operator_val == "revenue") {
            var parameter = data.revenue.metric;
        }else if(operator_val == "occupancy") {
            var parameter = data.occupancy.metric;
        }else if(operator_val == "rooms_pick_up") {
            var parameter = data.rooms_pick_up.metric;
        }else if(operator_val == "adr_pick_up") {
            var parameter = data.adr_pick_up.metric;
        }else if(operator_val == "revenue_pick_up") {
            var parameter = data.revenue_pick_up.metric;
        }else if(operator_val == "adr_move") {
            var parameter = data.adr_move.metric;
        }else if(operator_val == "my_pace") {

            var parameter = data.my_pace.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","block");
                $("#select_forecast_" + d).css("display","none");
            });

            $("#select_box_pace_" + d).prop('disabled', false);

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_pace_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "my_price_or_rate") {
            var parameter = data.my_price_or_rate.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","block");
                $("#select_box_pace_" + d).css("display","none");
                $("#select_forecast_" + d).css("display","none");
            });

            $("#select_box_myprice_rate_" + d).prop('disabled', false);

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_myprice_rate_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "comp_average") {
            var parameter = data.comp_average.metric;
        }else if(operator_val == "forecast_range") {
            var parameter = data.forecast_range.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");
                $("#select_forecast_" + d).css("display","block");
            });

            $("#select_forecast_" + d).prop('disabled', false);            

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_forecast_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "expected_pick_up") {
            var parameter = data.expected_pick_up.metric;
        }else if(operator_val == "positioning") {
            var parameter = data.positioning.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","block");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");
            });            

            $("#select_box_" + d).prop('disabled', false);  

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "price_rank") {
            var parameter = data.price_rank.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","block");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");
                $("#select_forecast_" + d).css("display","none");
            });

            $("#select_box_pricerank_" + d).prop('disabled', false);  

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_pricerank_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "leadtime") {
            var parameter = data.leadtime.metric;
        }else if(operator_val == "pick_up_trend") {
            
            var parameter = data.pick_up_trend.metric;                                    

            let condition_box = $('#condition_box_' + d);
            $(condition_box).addClass('opblink');

            let dropdownval = $('#value_box_' + d);
            dropdownval.empty();
            dropdownval.prop('selectedIndex', 0);

            // Populate dropdownval with list of value
            dropdownval.append($('<option></option>').attr('value', '-').text('-'));            
            
        }

        let dropdown2 = $('#metric_box_' + d);
        let dropdown5 = $('#bridge_box_' + d);
        dropdown2.empty();
        dropdown5.empty();
        dropdown2.prop('selectedIndex', 0);

        // Populate dropdown2 with list of metric
        for(var i=0; i<parameter.length; i++){            
            dropdown2.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
        }
        
        let dropdown_operator_box = $('#operator_box_' + d); 
        $(dropdown_operator_box).removeClass('opblink');

        let dropdown_valuebox = $('#text_box_' + d);
        $(dropdown_valuebox).addClass('opblink');        

        $("#text_box_" + d).css("display","block");
              
    }
    
    _textHandler(e) {
        this.textbox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();                
        
        var regex = /^\d*[.]?\d*$/;

        $('#text_box_' + d).on("input", function() {

            var len = $(this).val().length;            
            $(this).parent(0).css("width", len + 3 +'%'); 

            var inputVal = $(this).val(); 

            if(regex.test(inputVal)){
                return true;
            } else{
                $(this).val('');
            }

        });

        $('#occ_text_box_' + d).on("input", function() {

            ($(this).parent(0)).parent(0).css("width", '11%');

            var inputVal = $(this).val(); 

            if(regex.test(inputVal)){

            } else{
                $(this).val('');
            }

        });

        if(operator_val == "rms_to_sell"){            
            if(metric_val == "is_equal_to"){

                var parameter = data2.is_equal_to.value;              

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;               
                
                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell"); 

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;    
                
                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                // $("#condition_box_" + d).css("display","block");
                
            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                // $("#condition_box_" + d).css("display","block");
            }
            if(metric_val == "has_no_Change"){

                var parameter = data2.has_no_Change.value;

                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }                        
            
        }else if(operator_val == "rms_sold") {
            if(metric_val == "is_equal_to"){
                
                var parameter = data2.is_equal_to.value;        

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell"); 

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell"); 

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = data2.has_no_Change.value;
                
                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "adr") {
            if(metric_val == "is_equal_to"){

                var parameter = data2.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = data2.has_no_Change.value;
                
                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "revenue") {
            if(metric_val == "is_equal_to"){
                
                var parameter = data2.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;
                
                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){

                var parameter = data2.has_no_Change.value;

                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "occupancy") {
            if(metric_val == "is_equal_to"){

                var parameter = dataocc.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#occ_text_box_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#occ_text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');                    
                });                               

            }            
            if(metric_val == "is_greater_than"){

                var parameter = dataocc.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#occ_text_box_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#occ_text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');                    
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = dataocc.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#occ_text_box_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#occ_text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');                    
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = dataocc.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = dataocc.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = dataocc.has_no_Change.value;
                
                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "rooms_pick_up") {
            
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
                
            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
            }
            if(metric_val == "is_less_than"){

                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "adr_pick_up") {
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            if(metric_val == "is_less_than"){

                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "revenue_pick_up") {
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            if(metric_val == "is_less_than"){

                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "adr_move") {
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            if(metric_val == "is_less_than"){
                
                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "comp_average") {            
            if(metric_val == "is_equal_to"){

                var parameter = data_compavg.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){

                var parameter = data_compavg.is_higher_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_lower_than"){

                var parameter = data_compavg.is_lower_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data_compavg.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data_compavg.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = data_compavg.has_no_Change.value;

                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');
                
            }     
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }       
        }else if(operator_val == "expected_pick_up") {
            if(metric_val == "is_equal_to"){
                
                var parameter = data_expected_pickup.is_equal_to.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){
                
                var parameter = data_expected_pickup.is_higher_than.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_lower_than"){
                
                var parameter = data_expected_pickup.is_lower_than.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased"){
                
                var parameter = data_expected_pickup.has_increased.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased"){
                
                var parameter = data_expected_pickup.has_decreased.value;            
                
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameterpickup = data_expected_pickup.has_no_Change.value;                

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameterpickup.length; i++){
                    // console.log(parameterpickup[i]['dev_name']);
                    // console.log(parameterpickup[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameterpickup[i]['dev_name']).text(parameterpickup[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }            
        }else if(operator_val == "leadtime") {            
            if(metric_val == "is_equal_to"){

                var parameter = data_leadtime.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
                    
            }            
            if(metric_val == "is_greater_than"){

                var parameter = data_leadtime.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data_leadtime.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }           

        if(operator_val == "positioning") {
            if(metric_val == "is_equal_to"){
               
                var parameter_select_box_ = data_positioning.is_equal_to.value;

                let dropdownselect_box_ = $('#select_box_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }        
                
                /**********************************/
                var parameter_valuebox = data_positioning.is_equal_to.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){                
             
                var parameter_select_box_ = data_positioning.is_higher_than.value;
                
                let dropdownselect_box_ = $('#select_box_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                

                /**********************************/
                var parameter_valuebox = data_positioning.is_higher_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });


            }
            if(metric_val == "is_lower_than"){
                               
                var parameter_select_box_ = data_positioning.is_lower_than.value;
                
                let dropdownselect_box_ = $('#select_box_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_positioning.is_lower_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased"){

                var parameter = data_positioning.has_increased.value;                

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased"){

                var parameter = data_positioning.has_decreased.value;
                
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){

                var parameter = data_positioning.has_no_Change.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
        }

        if(operator_val == "price_rank") {
            if(metric_val == "is_equal_to"){
               
                var parameter_select_box_ = data_pricerank.is_equal_to.value;

                let dropdownselect_box_ = $('#select_box_pricerank_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }       
                
                /**********************************/
                var parameter_valuebox = data_pricerank.is_equal_to.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pricerank_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });


            }            
            if(metric_val == "is_higher_than"){                

                var parameter_select_box_ = data_pricerank.is_higher_than.value;
                
                let dropdownselect_box_ = $('#select_box_pricerank_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }     
                
                /**********************************/
                var parameter_valuebox = data_pricerank.is_higher_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pricerank_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });


            }
            if(metric_val == "is_lower_than"){
                               
                var parameter_select_box_ = data_pricerank.is_lower_than.value;
                
                let dropdownselect_box_ = $('#select_box_pricerank_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }               
                
                /**********************************/
                var parameter_valuebox = data_pricerank.is_lower_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pricerank_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });                

            }
            if(metric_val == "has_increased"){

                var parameter = data_pricerank.has_increased.value;
                
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");
            }
            if(metric_val == "has_decreased"){

                var parameter = data_pricerank.has_decreased.value;
               
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");
            }
            if(metric_val == "has_no_Change"){

                var parameter = data_pricerank.has_no_Change.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
        }

        if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){

                var parameter_select_box_ = data_my_price_rate.is_equal_to.valuepricerate;

                let dropdownselect_box_ = $('#select_box_myprice_rate_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                                     
                
                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_myprice_rate_' + d).on('change', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){                

                var parameter_select_box_ = data_my_price_rate.is_higher_than.valuepricerate;
                
                let dropdownselect_box_ = $('#select_box_myprice_rate_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                                    

                $('#select_box_myprice_rate_' + d).on('click', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");
                    $("#value_box_" + d).css("display","table-cell");

                    $("#select_box_" + d).css("display","none");
                    $("#select_box_myprice_rate_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                });

            }
            if(metric_val == "is_lower_than"){
                
                var parameter_select_box_ = data_my_price_rate.is_lower_than.valuepricerate;
                
                let dropdownselect_box_ = $('#select_box_myprice_rate_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                    
                
                $('#select_box_myprice_rate_' + d).on('click', function() {
                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#value_box_" + d).css("display","table-cell");
                    $("#select_box_myprice_rate_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data_my_price_rate.has_increased_by.value;
                
                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $('#select_box_myprice_rate_' + d).on('click', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","table-cell");
                    $("#value_box_" + d).css("display","table-cell");
                    $("#select_box_" + d).css("display","none");                
                    $("#select_box_myprice_rate_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");

                });
                
            }
            if(metric_val == "has_decreased_by"){

                var parameter = data_my_price_rate.has_decreased_by.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $('#select_box_myprice_rate_' + d).on('click', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","table-cell");
                    $("#value_box_" + d).css("display","table-cell");
                    $("#select_box_" + d).css("display","none");                
                    $("#select_box_myprice_rate_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");

                });
            }
            if(metric_val == "has_no_Change"){

                var parameter = data_my_price_rate.has_no_Change.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');
            }
        }   

        if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){
              
                var parameter_select_box_ = datamypace.is_equal_to.valuepace;

                let dropdownselect_box_ = $('#select_box_pace_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }             
                
                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pace_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_ahead_by_more_than"){                
                             
                var parameter_valuebox = datamypace.is_ahead_by_more_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                
                $("#select_box_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    $("#bridge_box_" + d).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });

            }
            if(metric_val == "is_behind_by_more_than"){                               
                                
                var parameter_valuebox = datamypace.is_behind_by_more_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                
                $("#select_box_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    $("#bridge_box_" + d).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });

            }
            if(metric_val == "has_improved"){
                
                var parameter = datamypace.has_improved.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");                

            }
            if(metric_val == "has_fallen_behind"){
               
                var parameter = datamypace.has_fallen_behind.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");
               
            }
            if(metric_val == "has_no_Change"){

                var parameter = datamypace.has_no_Change.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");               

            }
        }  
        
        if(operator_val == "forecast_range") {
            if(metric_val == "is_equal_to"){

                var parameter_select_box_ = data_forecast.is_equal_to.valueforecast;

                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }        
                
                /**********************************/
                var parameter_valuebox = data_forecast.is_equal_to.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/                

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){                
               
                var parameter_select_box_ = data_forecast.is_higher_than.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                

                /**********************************/
                var parameter_valuebox = data_forecast.is_higher_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_lower_than"){
                
                var parameter_select_box_ = data_forecast.is_lower_than.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_forecast.is_lower_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased"){
              
                var parameter_select_box_ = data_forecast.has_increased.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_forecast.has_increased.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });


            }
            if(metric_val == "has_decreased"){

                var parameter_select_box_ = data_forecast.has_decreased.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_forecast.has_decreased.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });

            }
            if(metric_val == "has_no_Change"){
             
                var parameter = data_forecast.has_no_Change.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","none");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            
            }
        }
        
        let dropdown_text_box = $('#metric_box_' + d);
        $(dropdown_text_box).removeClass('opblink');

        let dropdown_bridge_box = $('#value_box_' + d);
        $(dropdown_bridge_box).addClass('opblink');    
        
        $('#text_box_' + d).on('click', function() {

            if(operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "my_pace"){
                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + d);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);

                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + d);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + d);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + d).css("display","block");
            }

        });

        $('#select_forecast_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');

            if(operator_val == "forecast_range") {                
                if(metric_val == "has_increased"){
                    var parameter = data_forecast.has_increased.bridgeval;
                    let dropdown5 = $('#bridge_box_' + d);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + d);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + d);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + d);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + d).css("display","block");
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_forecast.has_decreased.bridgeval;
                    let dropdown5 = $('#bridge_box_' + d);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + d);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + d);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + d);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + d).css("display","block");
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_forecast.has_no_Change.bridgeval;
                    let dropdown5 = $('#bridge_box_' + d);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + d);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + d);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + d);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + d).css("display","block");
                }
                 
            }                                   

        });

        $('#select_box_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_pricerank_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_myprice_rate_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 4 + '%');                                

        });

        $('#select_box_pace_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');                                

        });

        $('#value_box_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });
                
    }    

    _valueHandler(e) {
        this.valuebox = e.target.textContent;

        let d = e.target.getAttribute('key');            
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();    
        var select_box_myprice_rate_val = $("#select_box_myprice_rate_" + d).val();    

        if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){             
                if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                    
                    /**********************************/
                    var parameter_valuepricerate = data_my_price_rate.is_equal_to.value;

                    let dropdown_parameter_valuepricerate = $('#value_box_' + d);
                    dropdown_parameter_valuepricerate.empty();
                    dropdown_parameter_valuepricerate.prop('selectedIndex', 0);

                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuepricerate.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuepricerate.append($('<option></option>').attr('value', parameter_valuepricerate[i]['dev_name']).text(parameter_valuepricerate[i]['display']));
                    }
                    /**********************************/
                }
            }            
            if(metric_val == "is_higher_than"){                
                if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                    
                    var parameter = data_my_price_rate.is_higher_than.value;

                    let dropdown3 = $('#value_box_' + d);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                                        
                }
            }
            if(metric_val == "is_lower_than"){                
                if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                    
                    var parameter = data_my_price_rate.is_higher_than.value;

                    let dropdown3 = $('#value_box_' + d);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }

                }

            }            
        }

        if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){             

                    /**********************************/
                    var parameter_valuepace = datamypace.is_equal_to.value;

                    let dropdown3 = $('#value_box_' + d);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);

                    // Populate parameter_valuepace with list of value
                    for(var i=0; i<parameter_valuepace.length; i++){
                        // console.log(parameter_valuepace[i]['dev_name']);
                        // console.log(parameter_valuepace[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter_valuepace[i]['dev_name']).text(parameter_valuepace[i]['display']));
                    }
                    /**********************************/                
            }
        }

        let dropdown3 = $('#bridge_box_' + d);
        dropdown3.empty();
        dropdown3.prop('selectedIndex', 0);        

        let dropdown_valuebox = $('#text_box_' + d);
        $(dropdown_valuebox).removeClass('opblink');

        let dropdown_text_box = $('#bridge_box_' + d);
        $(dropdown_text_box).addClass('opblink');

        $("#bridge_box_" + d).css("display","block");

        $('#value_box_' + d).on('change', function() {

            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }           
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){
                    var parameter = dataocc.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = dataocc.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = dataocc.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = dataocc.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = dataocc.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = dataocc.has_no_Change.bridgeval;
                }            
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
                    var parameter = datamypace.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_ahead_by_more_than"){
                    var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                }
                if(metric_val == "is_behind_by_more_than"){
                    var parameter = datamypace.is_behind_by_more_than.bridgeval;
                }
                if(metric_val == "has_improved"){
                    var parameter = datamypace.has_improved.bridgeval;
                }
                if(metric_val == "has_fallen_behind"){
                    var parameter = datamypace.has_fallen_behind.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = datamypace.has_no_Change.bridgeval;
                }
            }else if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_my_price_rate.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_my_price_rate.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_my_price_rate.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data_my_price_rate.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_compavg.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_compavg.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_compavg.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data_compavg.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data_compavg.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_compavg.has_no_Change.bridgeval;
                }
            }else if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){ 
                    var parameter = data_forecast.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_forecast.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_forecast.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_forecast.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_forecast.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_forecast.has_no_Change.bridgeval;
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_expected_pickup.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_expected_pickup.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_expected_pickup.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_expected_pickup.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_expected_pickup.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_expected_pickup.has_no_Change.bridgeval;
                }
            }else if(operator_val == "positioning") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_positioning.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_positioning.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_positioning.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_positioning.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_positioning.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_positioning.has_no_Change.bridgeval;
                }
            }else if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_pricerank.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_pricerank.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_pricerank.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_pricerank.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_pricerank.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_pricerank.has_no_Change.bridgeval;
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_leadtime.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data_leadtime.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data_leadtime.is_less_than.bridgeval;
                }
            }else if(operator_val == "pick_up_trend") {
                var parameter = data.pick_up_trend.bridgeval;
            }
        
            let dropdown5 = $('#bridge_box_' + d);
            dropdown5.empty();
            dropdown5.prop('selectedIndex', 0);

            // Populate dropdown3 with list of bridge
            for(var i=0; i<parameter.length; i++){            
                dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
                
            let dropdown_value_box = $('#value_box_' + d);
            $(dropdown_value_box).removeClass('opblink');
    
            let dropdown_bridge_box = $('#bridge_box_' + d);
            $(dropdown_bridge_box).removeClass('opblink');
            
            let dropdown_period_box = $('#period_box_' + d);
            $(dropdown_period_box).addClass('opblink');
    
            $("#period_box_" + d).css("display","block");

        });
    }

    _bridgeHandler(e) {
        this.bridgebox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();
        
        if(operator_val == "rms_to_sell"){
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }           
        }else if(operator_val == "rms_sold") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }
        }else if(operator_val == "adr") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }
        }else if(operator_val == "revenue") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }
        }else if(operator_val == "occupancy") {
            if(metric_val == "is_equal_to"){
                var parameter = dataocc.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = dataocc.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = dataocc.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = dataocc.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = dataocc.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = dataocc.has_no_Change.bridgeval;
            }            
        }else if(operator_val == "rooms_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "adr_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "revenue_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "adr_move") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){
                var parameter = datamypace.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_ahead_by_more_than"){
                var parameter = datamypace.is_ahead_by_more_than.bridgeval;
            }
            if(metric_val == "is_behind_by_more_than"){
                var parameter = datamypace.is_behind_by_more_than.bridgeval;
            }
            if(metric_val == "has_improved"){
                var parameter = datamypace.has_improved.bridgeval;
            }
            if(metric_val == "has_fallen_behind"){
                var parameter = datamypace.has_fallen_behind.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = datamypace.has_no_Change.bridgeval;
            }
        }else if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){
                var parameter = data_my_price_rate.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_my_price_rate.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_my_price_rate.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_my_price_rate.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_my_price_rate.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_my_price_rate.has_no_Change.bridgeval;                
            }
        }else if(operator_val == "comp_average") {
            if(metric_val == "is_equal_to"){
                var parameter = data_compavg.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_compavg.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_compavg.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_compavg.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_compavg.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_compavg.has_no_Change.bridgeval;
            }
        }else if(operator_val == "forecast_range") {
            if(metric_val == "is_equal_to"){
                var parameter = data_forecast.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_forecast.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_forecast.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_forecast.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_forecast.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_forecast.has_no_Change.bridgeval;
            }
        }else if(operator_val == "expected_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data_expected_pickup.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_expected_pickup.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_expected_pickup.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_expected_pickup.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_expected_pickup.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_expected_pickup.has_no_Change.bridgeval;
            }
        }else if(operator_val == "positioning") {
            if(metric_val == "is_equal_to"){
                var parameter = data_positioning.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_positioning.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_positioning.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_positioning.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_positioning.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_positioning.has_no_Change.bridgeval;
            }
        }else if(operator_val == "price_rank") {
            if(metric_val == "is_equal_to"){
                var parameter = data_pricerank.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_pricerank.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_pricerank.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_pricerank.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_pricerank.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_pricerank.has_no_Change.bridgeval;
            }
        }else if(operator_val == "leadtime") {
            if(metric_val == "is_equal_to"){
                var parameter = data_leadtime.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data_leadtime.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data_leadtime.is_less_than.bridgeval;
            }
        }else if(operator_val == "pick_up_trend") {
            var parameter = data.pick_up_trend.bridgeval;
        }

        $('#value_box_' + d).on('click', function() {

            let dropdown5 = $('#bridge_box_' + d);
            dropdown5.empty();
            dropdown5.prop('selectedIndex', 0);

            // Populate dropdown3 with list of bridge
            for(var i=0; i<parameter.length; i++){            
                dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }

        });        

        let dropdown_value_box = $('#value_box_' + d);
        $(dropdown_value_box).removeClass('opblink');

        let dropdown_bridge_box = $('#bridge_box_' + d);
        $(dropdown_bridge_box).removeClass('opblink');
        
        let dropdown_period_box = $('#period_box_' + d);
        $(dropdown_period_box).addClass('opblink');

        $("#period_box_" + d).css("display","block");
    }

    _periodHandler(e) {
        this.periodbox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();
        
        if(operator_val == "rms_to_sell"){
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "rms_sold") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "adr") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "revenue") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "occupancy") {
            if(metric_val == "is_equal_to"){
                var parameter = dataocc.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = dataocc.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = dataocc.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = dataocc.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = dataocc.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = dataocc.has_no_Change.periodval;
            }
        }else if(operator_val == "rooms_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "adr_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "revenue_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "adr_move") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){
                var parameter = datamypace.is_equal_to.periodval;
            }            
            if(metric_val == "is_ahead_by_more_than"){
                var parameter = datamypace.is_ahead_by_more_than.periodval;
            }
            if(metric_val == "is_behind_by_more_than"){
                var parameter = datamypace.is_behind_by_more_than.periodval;
            }
            if(metric_val == "has_improved"){
                var parameter = datamypace.has_improved.periodval;
            }
            if(metric_val == "has_fallen_behind"){
                var parameter = datamypace.has_fallen_behind.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = datamypace.has_no_Change.periodval;
            }
        }else if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){
                var parameter = data_my_price_rate.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_my_price_rate.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_my_price_rate.is_lower_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_my_price_rate.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_my_price_rate.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_my_price_rate.has_no_Change.periodval;                
            }
        }else if(operator_val == "comp_average") {
            if(metric_val == "is_equal_to"){
                var parameter = data_compavg.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_compavg.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_compavg.is_lower_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_compavg.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_compavg.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_compavg.has_no_Change.periodval;
            }
        }else if(operator_val == "forecast_range") {
            if(metric_val == "is_equal_to"){
                var parameter = data_forecast.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_forecast.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_forecast.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_forecast.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_forecast.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_forecast.has_no_Change.periodval;               
            }
        }else if(operator_val == "expected_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data_expected_pickup.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_expected_pickup.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_expected_pickup.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_expected_pickup.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_expected_pickup.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_expected_pickup.has_no_Change.periodval;
            }
        }else if(operator_val == "positioning") {
            if(metric_val == "is_equal_to"){
                var parameter = data_positioning.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_positioning.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_positioning.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_positioning.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_positioning.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_positioning.has_no_Change.periodval;                
            }
        }else if(operator_val == "price_rank") {
            if(metric_val == "is_equal_to"){
                var parameter = data_pricerank.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_pricerank.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_pricerank.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_pricerank.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_pricerank.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_pricerank.has_no_Change.periodval;                
            }
        }else if(operator_val == "leadtime") {
            if(metric_val == "is_equal_to"){
                var parameter = data_leadtime.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data_leadtime.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data_leadtime.is_less_than.periodval;
            }
        }else if(operator_val == "pick_up_trend") {
            var parameter = data.pick_up_trend.periodval;
        }

        let dropdown6 = $('#period_box_' + d);
        dropdown6.empty();
        dropdown6.prop('selectedIndex', 0);

        // Populate dropdown3 with list of value
        for(var i=0; i<parameter.length; i++){            
            dropdown6.append($('<option></option>').attr('value', parameter[i]['display']).text(parameter[i]['display']));
        }

        let dropdown_period_box = $('#period_box_' + d);
        $(dropdown_period_box).removeClass('opblink');

        let dropdown_condition_box = $('#condition_box_' + d);
        $(dropdown_condition_box).addClass('opblink');

        $("#condition_box_" + d).css("display","block");
    }

    _conditionHandler(e) {
        
        // this.conditionbox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var val = $("#condition_box_" + d).val();
        // console.log(val);

        // if(val == 0){ 
        //     $("#addnewtrbtn_" + d).css("display","none");   
        // }else{
        //     $("#addnewtrbtn_" + d).css("display","block");      
        // }

        let dropdown_condition_box = $('#condition_box_' + d);
        $(dropdown_condition_box).addClass('opblink');
        
        $("#addnewtrbtn_" + d).css("display","block");
        $("#removenewtrbtn_" + d).css("display","block");
        
        $('#condition_box_' + d).on('change', function() {
            let condition_val = $('#condition_box_' + d).val();
            if(condition_val != "-"){
                $("#condition_box_" + d).css("color","green");
                $("#condition_box_" + d).css("font-weight","bold");
            //     $("#condition_box_" + d).css("color","red");
            //     $("#condition_box_" + d).css("font-weight","bold");   
            // }else{
            //     $("#condition_box_" + d).css("color","green");
            //     $("#condition_box_" + d).css("font-weight","bold");   
            }
            
            $("#table_operator>tbody>tr>td>select").removeClass("opblink");
            $("#table_operator>tbody>tr>td>input").removeClass("opblink");

        });


    }

    addnewtr(){

        $("#addnewtrbtn_" + this.initialcount).css("display","none");
        $("#removenewtrbtn_" + this.initialcount).css("display","block");                      

        let dropdown_condition_box = $('#condition_box_' + this.initialcount);
        $(dropdown_condition_box).removeClass('opblink');
        
        let flapSupport = 
            `<div class="table-responsive div_added" id="tr_added_${this.counter}">
            <table class="table" style="width:100%; text-align: center">
            <tbody>
            <tr>
                <td style="vertical-align: inherit;width: 15%;"></td>
                <td style="width: 13%;">
                    <select id="operator_box_${this.counter}" key="${this.counter}" class="form-control selectbox oplink"></select>
                </td>
                <td style="width: 13%;">
                    <select id="metric_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>                
                <td id="display_textbox_${this.counter}" style="min-width:11%">
                    <input type="text" id="text_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;" placeholder="Value" />
                    
                    <div class="input-group selectbox">
                        <input type="text" id="occ_text_box_${this.counter}" key="${this.counter}" class="form-control" placeholder="Value" aria-label="Value" aria-describedby="basic-addon2" style="display:none;border:none; font-size: 14px; color: #2196f3;">
                        <div class="input-group-append">
                            <span id="span_text_box_${this.counter}" class="input-group-text" id="basic-addon2" style="display:none; background: none; border: none">%</span>
                        </div>
                    </div>

                    <select id="select_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>
                    <select id="select_box_pricerank_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>
                    <select id="select_box_myprice_rate_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>  
                    <select id="select_box_pace_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>    
                    <select id="select_forecast_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>              
                </td>
                <td style="width: 13%;" id="display_valuebox_${this.counter}">
                    <select id="value_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>
                <td style="width: 7%;" id="display_bridgebox_${this.counter}">
                    <select id="bridge_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>
                <td style="width: 11%;" id="display_periodbox_${this.counter}">
                    <select id="period_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>
                <td style="width: fit-content; display: flex;">
                    <select type="text" id="condition_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        <option value="">-</option> 
                        <option value="and">AND</option>
                        <option value="or">OR</option>
                    </select>
                    <button type="button" id="addnewtrbtn_${this.counter}" key="${this.counter}" class="btn btn-success" style="vertical-align: text-top;border-radius:50px;display:none">
                        <i class="fa fa-plus"></i>
                    </button> &nbsp;
                    <button type="button" id="removenewtrbtn_${this.counter}" key="${this.counter}" class="btn btn-danger" style="vertical-align: text-top;border-radius:50px;display:none">
                        <i class="fa fa-minus"></i>
                    </button>
                </td>
            </tr>
            </tr>
            </tbody>
            </table>
            </div>`;                     

        if(this.initialcount == 0 && this.counter == 1){
            $(".div1").eq(0).after(flapSupport);
        }else{            
            $(".div_added").parent(1).append(flapSupport);
            // console.log($(".div_added").parent(1));            
        }
        // $("#table_operator").find(a).append(flapSupport);                
        $('#operator_box_' + this.counter).click(this.myFunction_newrow(this.counter));        

        this.counter++; 
        
        // console.log(this.counter);
                
        // counter--;        
        // $("#TextBoxDiv" + counter).remove();
    } 

    removenewtr(){

        let remove_tr = $('#tr_' + this.initialcount);        
        $(remove_tr).remove();        
                
    }    

    myFunction_newrow(counter){   
        
        // console.log(counter);

        let dropdownname = $('#operator_box_' + counter);
       
        dropdownname.empty();
        dropdownname.prop('selectedIndex', 0);       

        for(var i in data){
            // console.log(i);
            dropdownname.append($('<option></option>').attr('value', i).text(data [i]['display']));
        }
                
        // var cntminus = counter - 1;
        // $("#addnewtrbtn_" + cntminus).css("display","none");

        $('#operator_box_' + counter).on('change', function() {                                    

            $("#metric_box_" + counter).css("display","block");                   

            let dropdown_valuebox = $('#metric_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            // alert( this.value );            
            var operator_val = this.value;

            var len = $(this).val().length;
            if(operator_val == 'adr'){
                $(this).parent(0).css("width", '6%');                                
            }else if(len > 14){
                $(this).parent(0).css("width", (len - 3) + '%');                                
            }else{
                $(this).parent(0).css("width", len + 1 + '%');
            }

            if(operator_val == "rms_to_sell"){
                var parameter = data.rms_to_sell.metric;
            }else if(operator_val == "rms_sold") {
                var parameter = data.rms_sold.metric;
            }else if(operator_val == "adr") {
                var parameter = data.adr.metric;
            }else if(operator_val == "revenue") {
                var parameter = data.revenue.metric;
            }else if(operator_val == "occupancy") {
                var parameter = data.occupancy.metric;
            }else if(operator_val == "rooms_pick_up") {
                var parameter = data.rooms_pick_up.metric;
            }else if(operator_val == "adr_pick_up") {
                var parameter = data.adr_pick_up.metric;
            }else if(operator_val == "revenue_pick_up") {
                var parameter = data.revenue_pick_up.metric;
            }else if(operator_val == "adr_move") {
                var parameter = data.adr_move.metric;
            }else if(operator_val == "my_pace") {
                var parameter = data.my_pace.metric;
            }else if(operator_val == "my_price_or_rate") {
                var parameter = data.my_price_or_rate.metric;
            }else if(operator_val == "comp_average") {
                var parameter = data.comp_average.metric;
            }else if(operator_val == "forecast_range") {
                var parameter = data.forecast_range.metric;
            }else if(operator_val == "expected_pick_up") {
                var parameter = data.expected_pick_up.metric;
            }else if(operator_val == "positioning") {
                var parameter = data.positioning.metric;
            }else if(operator_val == "price_rank") {
                var parameter = data.price_rank.metric;
            }else if(operator_val == "leadtime") {
                var parameter = data.leadtime.metric;
            }else if(operator_val == "pick_up_trend") {
                var parameter = data.pick_up_trend.metric;
            }
    
            let dropdown_metric_counter = $('#metric_box_' + counter);
    
            dropdown_metric_counter.empty();
            dropdown_metric_counter.prop('selectedIndex', 0);
    
            for(var i in parameter){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown_metric_counter.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }                
            
            /****************Value****************/                        

        });               
        
        $('#metric_box_' + counter).on('change', function() {
                                               
            var len = $(this).val().length;
            $(this).parent(0).css("min-width", len + 2 + '%');                                
            $(this).parent(0).css("width", len + '%'); 

            $("#text_box_" + counter).css("display","block");
            $("#occ_text_box_" + counter).css("display","none");
            $("#span_text_box_" + counter).css("display","none");

            let dropdown_operator_box = $('#operator_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            var regex = /^\d*[.]?\d*$/;

            $('#text_box_' + counter).on("input", function() {

                var inputVal = $(this).val(); 

                if(regex.test(inputVal)){

                } else{
                    $(this).val('');
                }

            });    
            
            $('#occ_text_box_' + counter).on("input", function() {

                var inputVal = $(this).val(); 

                if(regex.test(inputVal)){

                } else{
                    $(this).val('');
                }

            });

            // if( operator_val == "rms_to_sell" || operator_val == "rms_sold" || operator_val == "adr" || operator_val == "revenue" || operator_val == "occupancy" || operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "comp_average")
            // {
            //     $("#bridge_box_" + counter).css("display","table-cell"); 
            // }

            if(operator_val == "occupancy"){
                if(metric_val == "is_equal_to" || metric_val == "is_greater_than" || metric_val == "is_less_than"){
                    $("#text_box_" + counter).css("display","none");
                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#span_text_box_" + counter).css("display","table-cell");
                }else{
                    $("#occ_text_box_" + counter).css("display","none");
                    $("#span_text_box_" + counter).css("display","none");
                }
            }else{                
                    $("#occ_text_box_" + counter).css("display","none");       
                    $("#span_text_box_" + counter).css("display","none");         
            }

            if(operator_val == "expected_pick_up"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell"); 
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");                    
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');  
                }else{
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                }                                                      
            }

            if(operator_val == "pick_up_trend"){                                    
                
                $("#display_textbox_" + counter).css("display","none");
                $("#text_box_" + counter).css("display","none");
                $("#display_valuebox_" + counter).css("display","none");
                $("#display_bridgebox_" + counter).css("display","none");
                $("#display_periodbox_" + counter).css("display","none");
                $("#condition_box_" + counter).css("display","table-cell");
                let condition_box = $('#condition_box_' + counter);
                $(condition_box).addClass('opblink');                                                                            

            }

            if(operator_val == "positioning" || operator_val == "price_rank"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + counter).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');  
                }                                            
            }

            if(operator_val == "forecast_range"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + counter);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }

            }

            if(operator_val == "my_price_or_rate"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","none");
                    $("#select_box_" + counter).css("display","none");                
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + counter);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }

            }

            if(operator_val == "my_pace"){                
                if(metric_val == "is_ahead_by_more_than" || metric_val == "is_behind_by_more_than"){
                    $("#text_box_" + counter).css("display","table-cell");
                }   
                if(metric_val == "has_improved" || metric_val == "has_fallen_behind" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + counter).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');   
                }                             
            }

            if(metric_val=="has_no_Change" || operator_val == "my_pace" || operator_val == "my_price_or_rate" || operator_val == "expected_pick_up" || operator_val == "positioning" || operator_val == "price_rank"){
                /********* Bridge *****/
                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + counter);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);

                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + counter);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + counter);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + counter);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + counter).css("display","block");
                /********* Bridge *****/
            }            
        
            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;                    

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_greater_than"){
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value; 

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased_by"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");                    

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                }            
                if(metric_val == "is_greater_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }            
                if(metric_val == "is_greater_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }            
                if(metric_val == "is_greater_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell"); 

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = dataocc.is_equal_to.value;                    

                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#occ_text_box_' + counter).on('click', function() {                        
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    }); 

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = dataocc.is_greater_than.value;

                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#occ_text_box_' + counter).on('click', function() {                        
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = dataocc.is_less_than.value;

                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#occ_text_box_' + counter).on('click', function() {                        
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = dataocc.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = dataocc.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = dataocc.has_no_Change.value;                    

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");          
                    
                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');
                    
                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    
                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data_compavg.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_higher_than"){

                    var parameter_value = data_compavg.is_higher_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_lower_than"){

                    var parameter_value = data_compavg.is_lower_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    var parameter_value = data_compavg.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased_by"){

                    var parameter_value = data_compavg.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    var parameter_value = data_compavg.has_no_Change.value;                    

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");

                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                    let dropdown_bridge_box = $('#bridge_box_' + counter);
                    $(dropdown_bridge_box).addClass('opblink');
                    
                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "forecast_range") {
                var parameter_value = data.forecast_range.value;
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data_expected_pickup.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_higher_than"){

                    var parameter_value = data_expected_pickup.is_higher_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_value = data_expected_pickup.is_lower_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }
                if(metric_val == "has_increased"){

                    var parameter_value = data_expected_pickup.has_increased.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased"){

                    var parameter_value = data_expected_pickup.has_decreased.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data_expected_pickup.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data_leadtime.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data_leadtime.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data_leadtime.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "pick_up_trend") {                  

                let dropdownval = $('#value_box_' + counter);
                dropdownval.empty();
                dropdownval.prop('selectedIndex', 0);

                // Populate dropdownval with list of value
                dropdownval.append($('<option></option>').attr('value', '-').text('-'));
                
            }                

            if(operator_val == "positioning") {                
                if(metric_val == "is_equal_to"){
                   
                    var parameter_select_box_ = data_positioning.is_equal_to.value; 
    
                    let dropdownselect_box_ = $('#select_box_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }       
                    
                    /**********************************/
                    var parameter_valuebox = data_positioning.is_equal_to.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_higher_than"){                
                       
                    var parameter_select_box_ = data_positioning.is_higher_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }     
                    
                    /**********************************/
                    var parameter_valuebox = data_positioning.is_higher_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });                    
    
                }
                if(metric_val == "is_lower_than"){
                                        
                    var parameter_select_box_ = data_positioning.is_lower_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }               
                    
                    /**********************************/
                    var parameter_valuebox = data_positioning.is_lower_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased"){
                        
                    var parameter = data_positioning.has_increased.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased"){
                    
                    var parameter = data_positioning.has_decreased.value;                    
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    var parameter = data_positioning.has_no_Change.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
            }

            if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){

                    var parameter_select_box_ = data_pricerank.is_equal_to.value;
    
                    let dropdownselect_box_ = $('#select_box_pricerank_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }       
                    
                    /**********************************/
                    var parameter_valuebox = data_pricerank.is_equal_to.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pricerank_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_higher_than"){                
    
                    var parameter_select_box_ = data_pricerank.is_higher_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_pricerank_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }     
                    
                    /**********************************/
                    var parameter_valuebox = data_pricerank.is_higher_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pricerank_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_select_box_ = data_pricerank.is_lower_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_pricerank_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }               
                    
                    /**********************************/
                    var parameter_valuebox = data_pricerank.is_lower_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pricerank_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }
                if(metric_val == "has_increased"){
    
                    var parameter = data_pricerank.has_increased.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased"){

                    var parameter = data_pricerank.has_decreased.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter = data_pricerank.has_no_Change.value;
                   
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
            }                

            if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                            
                    var parameter_select_box_ = data_my_price_rate.is_equal_to.valuepricerate;
    
                    let dropdownselect_box_ = $('#select_box_myprice_rate_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                                         
                    
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_myprice_rate_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_myprice_rate_' + counter).on('change', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_higher_than"){                
    
                    var parameter_select_box_ = data_my_price_rate.is_higher_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_myprice_rate_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                   
                    
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_myprice_rate_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
    
                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_select_box_ = data_my_price_rate.is_lower_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_myprice_rate_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                                        
                    
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_myprice_rate_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
    
                }
                if(metric_val == "has_increased_by"){
    
                    var parameter = data_my_price_rate.has_increased_by.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
    
                    $("#bridge_box_" + counter).css("display","block");
    
                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_" + counter).css("display","none");                
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    var parameter = data_my_price_rate.has_decreased_by.value;
                    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
    
                    $("#bridge_box_" + counter).css("display","block");
    
                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_" + counter).css("display","none");                
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    var parameter = data_my_price_rate.has_no_Change.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
    
                    $("#bridge_box_" + counter).css("display","block");
    
                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');
                }
            }

            if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
    
                    var parameter_select_box_ = datamypace.is_equal_to.valuepace;
    
                    let dropdownselect_box_ = $('#select_box_pace_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = datamypace.is_equal_to.value;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pace_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_ahead_by_more_than"){                
                 
                    /**********************************/
                    var parameter_valuebox = datamypace.is_ahead_by_more_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    
                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        $("#bridge_box_" + counter).css("display","table-cell");
                        let bridge_box = $('#bridge_box_' + counter);
                        $(bridge_box).addClass('opblink');
                    });
                    
    
                }
                if(metric_val == "is_behind_by_more_than"){                               
                    
                    /**********************************/
                    var parameter_valuebox = datamypace.is_behind_by_more_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value 
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    
                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        $("#bridge_box_" + counter).css("display","table-cell");
                        let bridge_box = $('#bridge_box_' + counter);
                        $(bridge_box).addClass('opblink');
                    });
    
                }
                if(metric_val == "has_improved"){
                        
                    var parameter = datamypace.has_improved.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_fallen_behind"){

                    var parameter = datamypace.has_fallen_behind.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    var parameter = datamypace.has_no_Change.value;
                    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
            }

            if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){
    
                    var parameter_select_box_ = data_forecast.is_equal_to.valueforecast;
    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }        
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.is_equal_to.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink');
    
                }            
                if(metric_val == "is_higher_than"){                
    
                    var parameter_select_box_ = data_forecast.is_higher_than.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                
    
                    /**********************************/
                    var parameter_valuebox = data_forecast.is_higher_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink');
    
                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_select_box_ = data_forecast.is_lower_than.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.is_lower_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink');
    
                }
                if(metric_val == "has_increased"){
    
                    var parameter_select_box_ = data_forecast.has_increased.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.has_increased.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');
    
                }
                if(metric_val == "has_decreased"){
    
                    var parameter_select_box_ = data_forecast.has_decreased.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.has_decreased.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');
    
                }
                if(metric_val == "has_no_Change"){
    
                    var parameter = data_positioning.has_no_Change.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink'); 
    
                }
            }

            /*************Bridge***************/
            var metric_val = $("#metric_box_" + counter).val();
            
            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }           
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = dataocc.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = dataocc.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = dataocc.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = dataocc.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = dataocc.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = dataocc.has_no_Change.bridgeval;
                }
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = datamypace.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_ahead_by_more_than"){
                    var parameter_bridge = datamypace.is_ahead_by_more_than.bridgeval;
                }
                if(metric_val == "is_behind_by_more_than"){
                    var parameter_bridge = datamypace.is_behind_by_more_than.bridgeval;
                }
                if(metric_val == "has_improved"){
                    var parameter_bridge = datamypace.has_improved.bridgeval;
                }
                if(metric_val == "has_fallen_behind"){
                    var parameter_bridge = datamypace.has_fallen_behind.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = datamypace.has_no_Change.bridgeval;
                }
            }else if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_my_price_rate.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_my_price_rate.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_my_price_rate.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data_my_price_rate.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data_my_price_rate.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_my_price_rate.has_no_Change.bridgeval;
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_compavg.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_compavg.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_compavg.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data_compavg.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data_compavg.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_compavg.has_no_Change.bridgeval;
                }
            }else if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_forecast.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_forecast.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_forecast.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_forecast.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_forecast.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_forecast.has_no_Change.bridgeval;
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_expected_pickup.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_expected_pickup.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_expected_pickup.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_expected_pickup.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_expected_pickup.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_expected_pickup.has_no_Change.bridgeval;
                }
            }else if(operator_val == "positioning") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_positioning.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_positioning.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_positioning.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_positioning.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_positioning.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_positioning.has_no_Change.bridgeval;
                }
            }else if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_pricerank.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_pricerank.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_pricerank.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_pricerank.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_pricerank.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_pricerank.has_no_Change.bridgeval;
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_leadtime.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data_leadtime.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data_leadtime.is_less_than.bridgeval;
                }
            }else if(operator_val == "pick_up_trend") {
                var parameter_bridge = data.pick_up_trend.bridgeval;
            }
    
            let dropdown_bridgeval_counter = $('#bridge_box_' + counter);
    
            dropdown_bridgeval_counter.empty();
            dropdown_bridgeval_counter.prop('selectedIndex', 0);
    
            for(var i in parameter_bridge){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown_bridgeval_counter.append($('<option></option>').attr('value', parameter_bridge[i]['dev_name']).text(parameter_bridge[i]['display']));
            }

            /**************Period*********/
            var metric_val = $("#metric_box_" + counter).val();
        
            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = dataocc.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = dataocc.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = dataocc.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = dataocc.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = dataocc.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = dataocc.has_no_Change.periodval;
                }
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = datamypace.is_equal_to.periodval;
                }            
                if(metric_val == "is_ahead_by_more_than"){
                    var parameter_period = datamypace.is_ahead_by_more_than.periodval;
                }
                if(metric_val == "is_behind_by_more_than"){
                    var parameter_period = datamypace.is_behind_by_more_than.periodval;
                }
                if(metric_val == "has_improved"){
                    var parameter_period = datamypace.has_improved.periodval;
                }
                if(metric_val == "has_fallen_behind"){
                    var parameter_period = datamypace.has_fallen_behind.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = datamypace.has_no_Change.periodval;
                }
            }else if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_my_price_rate.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_my_price_rate.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_my_price_rate.is_lower_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data_my_price_rate.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data_my_price_rate.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_my_price_rate.has_no_Change.periodval;
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_compavg.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_compavg.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_compavg.is_lower_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data_compavg.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data_compavg.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_compavg.has_no_Change.periodval;
                }
            }else if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_forecast.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_forecast.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_forecast.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_forecast.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_forecast.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_forecast.has_no_Change.periodval;
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_expected_pickup.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_expected_pickup.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_expected_pickup.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_expected_pickup.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_expected_pickup.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_expected_pickup.has_no_Change.periodval;
                }
            }else if(operator_val == "positioning") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_positioning.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_positioning.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_positioning.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_positioning.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_positioning.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_positioning.has_no_Change.periodval;
                }
            }else if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_pricerank.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_pricerank.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_pricerank.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_pricerank.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_pricerank.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_pricerank.has_no_Change.periodval;
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_leadtime.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data_leadtime.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data_leadtime.is_less_than.periodval;
                }
            }else if(operator_val == "pick_up_trend") {
                var parameter_period = data.pick_up_trend.periodval;
            }
    
            let dropdown_periodval_counter = $('#period_box_' + counter);
    
            dropdown_periodval_counter.empty();
            dropdown_periodval_counter.prop('selectedIndex', 0);
    
            for(var i in parameter_period){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown_periodval_counter.append($('<option></option>').attr('value', parameter_period[i]['display']).text(parameter_period[i]['display']));
            }
            /******************/

        });

        $('#text_box_' + counter).on('click', function() {
                        
            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            $("#value_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            $('#text_box_' + counter).on('click', function() {

                if(operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "my_pace"){
                    if(operator_val == "rms_to_sell"){
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }           
                    }else if(operator_val == "rms_sold") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "adr") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "revenue") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "occupancy") {
                        if(metric_val == "is_equal_to"){
                            var parameter = dataocc.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = dataocc.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = dataocc.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = dataocc.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = dataocc.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = dataocc.has_no_Change.bridgeval;
                        }            
                    }else if(operator_val == "rooms_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "adr_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "revenue_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "adr_move") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "my_pace") {
                        if(metric_val == "is_equal_to"){
                            var parameter = datamypace.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_ahead_by_more_than"){
                            var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                        }
                        if(metric_val == "is_behind_by_more_than"){
                            var parameter = datamypace.is_behind_by_more_than.bridgeval;
                        }
                        if(metric_val == "has_improved"){
                            var parameter = datamypace.has_improved.bridgeval;
                        }
                        if(metric_val == "has_fallen_behind"){
                            var parameter = datamypace.has_fallen_behind.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = datamypace.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "my_price_or_rate") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_my_price_rate.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_my_price_rate.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_my_price_rate.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data_my_price_rate.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                        }
                    }else if(operator_val == "comp_average") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_compavg.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_compavg.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_compavg.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data_compavg.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data_compavg.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_compavg.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "forecast_range") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_forecast.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_forecast.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_forecast.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_forecast.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_forecast.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_forecast.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "expected_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_expected_pickup.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_expected_pickup.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_expected_pickup.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_expected_pickup.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_expected_pickup.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_expected_pickup.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "positioning") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_positioning.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_positioning.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_positioning.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_positioning.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_positioning.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_positioning.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "price_rank") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_pricerank.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_pricerank.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_pricerank.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_pricerank.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_pricerank.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_pricerank.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "leadtime") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_leadtime.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data_leadtime.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data_leadtime.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "pick_up_trend") {
                        var parameter = data.pick_up_trend.bridgeval;
                    }
                
                    let dropdown5 = $('#bridge_box_' + counter);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + counter);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + counter);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + counter);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + counter).css("display","block");
                }
    
            });            

        });

        $('#select_box_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_pricerank_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_box_pricerank_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_box_pace_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_box_myprice_rate_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none"); 
            $("#select_box_pricerank_" + counter).css("display","none");

            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();
            var select_box_myprice_rate_val = $("#select_box_myprice_rate_" + counter).val();

            if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){             
                    if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                        
                        /**********************************/
                        var parameter_valuepricerate = data_my_price_rate.is_equal_to.value;
    
                        let dropdown_parameter_valuepricerate = $('#value_box_' + counter);
                        dropdown_parameter_valuepricerate.empty();
                        dropdown_parameter_valuepricerate.prop('selectedIndex', 0);
    
                        // Populate dropdown_parameter_valuebox with list of value
                        for(var i=0; i<parameter_valuepricerate.length; i++){
                            // console.log(parameter_valuebox[i]['dev_name']);
                            // console.log(parameter_valuebox[i]['display']);
                            dropdown_parameter_valuepricerate.append($('<option></option>').attr('value', parameter_valuepricerate[i]['dev_name']).text(parameter_valuepricerate[i]['display']));
                        }
                        /**********************************/
                    }
                }            
                if(metric_val == "is_higher_than"){                
                    if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                        
                        var parameter = data_my_price_rate.is_higher_than.value;
    
                        let dropdown3 = $('#value_box_' + counter);
                        dropdown3.empty();
                        dropdown3.prop('selectedIndex', 0);
    
                        // Populate dropdown3 with list of value
                        for(var i=0; i<parameter.length; i++){
                            // console.log(parameter[i]['dev_name']);
                            // console.log(parameter[i]['display']);
                            dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                        }
                                            
                    }
                }
                if(metric_val == "is_lower_than"){                
                    if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                        
                        var parameter = data_my_price_rate.is_higher_than.value;
    
                        let dropdown3 = $('#value_box_' + counter);
                        dropdown3.empty();
                        dropdown3.prop('selectedIndex', 0);
    
                        // Populate dropdown3 with list of value
                        for(var i=0; i<parameter.length; i++){
                            // console.log(parameter[i]['dev_name']);
                            // console.log(parameter[i]['display']);
                            dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                        }
    
                    }
    
                }
            }
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_forecast_' + counter).on('click', function() {
                        
            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            $('#select_forecast_' + counter).on('change', function() {
    
                if(operator_val == "forecast_range") {                
                    if(metric_val == "has_increased"){
                        
                        var parameter_bridgeval = data_forecast.has_increased.bridgeval;
                        
                        let dropdown5 = $('#bridge_box_' + counter);
                        dropdown5.empty();
                        dropdown5.prop('selectedIndex', 0);
            
                        // Populate dropdown3 with list of bridge
                        for(var i=0; i<parameter_bridgeval.length; i++){            
                            dropdown5.append($('<option selected></option>').attr('value', parameter_bridgeval[i]['dev_name']).text(parameter_bridgeval[i]['display']));
                        }
                            
                        let dropdown_value_box = $('#value_box_' + counter);
                        $(dropdown_value_box).removeClass('opblink');
                
                        let dropdown_bridge_box = $('#bridge_box_' + counter);
                        $(dropdown_bridge_box).removeClass('opblink');
                        
                        let dropdown_period_box = $('#period_box_' + counter);
                        $(dropdown_period_box).addClass('opblink');
                
                        $("#period_box_" + counter).css("display","block"); 
                    }
                    if(metric_val == "has_decreased"){

                        var parameter_bridgeval = data_forecast.has_decreased.bridgeval;

                        let dropdown5 = $('#bridge_box_' + counter);
                        dropdown5.empty();
                        dropdown5.prop('selectedIndex', 0);
            
                        // Populate dropdown3 with list of bridge
                        for(var i=0; i<parameter_bridgeval.length; i++){            
                            dropdown5.append($('<option selected></option>').attr('value', parameter_bridgeval[i]['dev_name']).text(parameter_bridgeval[i]['display']));
                        }
                            
                        let dropdown_value_box = $('#value_box_' + counter);
                        $(dropdown_value_box).removeClass('opblink');
                
                        let dropdown_bridge_box = $('#bridge_box_' + counter);
                        $(dropdown_bridge_box).removeClass('opblink');
                        
                        let dropdown_period_box = $('#period_box_' + counter);
                        $(dropdown_period_box).addClass('opblink');
                
                        $("#period_box_" + counter).css("display","block");
                    }
                    if(metric_val == "has_no_Change"){

                        var parameter_bridgeval = data_forecast.has_no_Change.bridgeval;

                        let dropdown5 = $('#bridge_box_' + counter);
                        dropdown5.empty();
                        dropdown5.prop('selectedIndex', 0);
            
                        // Populate dropdown3 with list of bridge
                        for(var i=0; i<parameter_bridgeval.length; i++){            
                            dropdown5.append($('<option selected></option>').attr('value', parameter_bridgeval[i]['dev_name']).text(parameter_bridgeval[i]['display']));
                        }
                            
                        let dropdown_value_box = $('#value_box_' + counter);
                        $(dropdown_value_box).removeClass('opblink');
                
                        let dropdown_bridge_box = $('#bridge_box_' + counter);
                        $(dropdown_bridge_box).removeClass('opblink');
                        
                        let dropdown_period_box = $('#period_box_' + counter);
                        $(dropdown_period_box).addClass('opblink');
                
                        $("#period_box_" + counter).css("display","block");
                    }                    
                }                                           
    
            });

        });

        $('#value_box_' + counter).on('click', function() {
                        
            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            $("#bridge_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#text_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#bridge_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            $('#value_box_' + counter).on('change', function() {

                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){ 
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + counter);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);
    
                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + counter);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + counter);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + counter);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + counter).css("display","block");
    
            });

        });        

        $('#bridge_box_' + counter).on('click', function() {
                        
            $("#period_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#value_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#period_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#period_box_' + counter).on('click', function() {
                        
            $("#condition_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#bridge_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#condition_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#condition_box_' + counter).on('change', function() {
                                    
            let dropdown_operator_box = $('#period_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');           

            let dropdown_condition_box_counter = $('#condition_box_' + counter);
            $(dropdown_condition_box_counter).removeClass('opblink');

            $("#table_operator>tbody>tr>td>select").removeClass("opblink");
            $("#table_operator>tbody>tr>td>input").removeClass("opblink");

            let condition_val = $('#condition_box_' + counter).val();
            
            if(condition_val != "0"){                
                $("#condition_box_" + counter).css("color","green");
                $("#condition_box_" + counter).css("font-weight","bold");
                $("#addnewtrbtn_" + counter).css("display","block");
                $("#removenewtrbtn_" + counter).css("display","block");
                
                var counteradd = counter + 1;
                let remove_tr_added = $('#tr_added_' + counteradd);        

                if($(remove_tr_added).length){    
                    $("#addnewtrbtn_" + counter).css("display","none");
                    $("#removenewtrbtn_" + counter).css("display","block");                     
                }else{
                    // $("#addnewtrbtn_" + counter).css("display","block");
                    // $("#removenewtrbtn_" + counter).css("display","block");
                }

            }else{                                
                $("#addnewtrbtn_" + counter).css("display","none");
                $("#removenewtrbtn_" + counter).css("display","block");            

                var counteradd = counter + 1;
                let remove_tr_added = $('#tr_added_' + counteradd);

                if($(remove_tr_added).length){ 
                    $(remove_tr_added).remove();
                }else{
                }

            }            

            $('#removenewtrbtn_' + counter).on('click', function() {

                let remove_tr_added = $('#tr_added_' + counter);        
                $(remove_tr_added).remove(); 
    
            });                                

        });      
        
        var flag = this;        

        $('#addnewtrbtn_' + counter).click(function(){
            $("#addnewtrbtn_" + counter).css("display","none");
            $("#removenewtrbtn_" + counter).css("display","block");
            flag.addnewtr();
        });

        $('#text_box_' + counter).on('input', function() {
            var len = $(this).val().length;            
            $(this).parent(0).css("width", len + 3 +'%');
        });          
        
        $('#select_box_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_pricerank_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_myprice_rate_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 4 + '%');                                

        });

        $('#select_box_pace_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');                                

        });

        $('#value_box_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_forecast_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');                               

        });

        $('#occ_text_box_' + counter).on("input", function() {

            ($(this).parent(0)).parent(0).css("width", '11%');           

        });
                
    }           

    rundata(){
        
        let flag = this;

        /*******************/
        var tableobj=[];
        
        $('table tbody tr').each(function(){
            var obj = {};
            var inputs = $(this).find('input');            
            inputs.each(function(){
                obj[$(this).attr('id')] = this.value;
            });                        

            var select = $(this).find('select');
            select.each(function(){
                obj[$(this).attr('id')] = this.value;
            });                   

            tableobj.push(obj);

        });
        
        let finalarray = [];
        for(var i = 0; i < tableobj.length; i++){            
            if(Object.keys(tableobj[i]).length === 0){
                // console.log(tableobj[i]);
                // const index = tableobj.indexOf(tableobj[i]);
            }else{
                finalarray.push(tableobj[i]);
            }            
        }

        // console.log(finalarray);                      

        // Create Final Data Object
        let dataObj = {
            'hotel_id': this.hotel_id,
            'recordDate': infinito.vao.controller.storageHelper.getCurrentDate(),
            'stayDate': infinito.vao.controller.storageHelper.getCurrentDate(),
            'offset': infinito.vao.controller.storageHelper.getPickupOffset(),
            'identity_id': infinito.vao.controller.storageHelper.getIdentitiesId(),                        
            'filters': finalarray,
            'op': 'getRuleOutcome',
            'serviceName': "runRules.php"
        };

        // console.log(dataObj);        
        // return false;

        sendRequest(dataObj, function (res) {
                        
            var result = JSON.parse(res);
            // console.log(result['data']);
            flag.FinalDateArr = result['data'];
            console.log("Dates from Run", flag.FinalDateArr);        
            flag.fill();

        });    
    }
    /************************** My UI ***************************/

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;
    }

    makeStaticColumns() {
        const columnKeyToStringMap = {
            [cellFieldMap.stayDate]: Constants.STRINGS.STAY_DATE,
            [cellFieldMap.notes]: 'What Changed',
            [cellFieldMap.roomsToSell]: 'Rms to sell',
            [cellFieldMap.expectedPickup]: 'Expected PU',
            [cellFieldMap.occupancy]: 'Occupancy',
            [cellFieldMap.forecast]: 'Forecast',
            [cellFieldMap.fcrange]: 'FC Range',            
            [cellFieldMap.roomsOtbPace]: 'My Pace',
            [cellFieldMap.roomsOtb]: 'Rms',
            [cellFieldMap.revenue]: 'Rev',
            [cellFieldMap.adr]: 'ADR',
            [cellFieldMap.roomsOtbPickup]: 'Rms PU',
            [cellFieldMap.revenuePickup]: 'Rev PU',
            [cellFieldMap.pickupsAdr]: 'PU ADR',
            [cellFieldMap.rate]: 'My Rate',
            [cellFieldMap.ratePace]: 'My Price',
            [cellFieldMap.compSetAvgPace]: 'Comp Avg',            
            [cellFieldMap.positioning]:'Positioning',
            [cellFieldMap.roomsOtbVsLastYear]: 'Rms vs LY',
            [cellFieldMap.revenueVsLastYear]: 'Rev vs LY',
            [cellFieldMap.adrVsLastYear]: 'ADR vs LY',        
            [cellFieldMap.adrPickup]: 'ADR Move',                                                
            [cellFieldMap.details]:'Details',
            [cellFieldMap.housekeepers]: 'Housekeepers',            
            [cellFieldMap.ratePickup]: 'Rate PU',
            [cellFieldMap.compSetAvg]: 'vs Comp Avg',
        };

        let columns = [];
        Object.keys(columnKeyToStringMap).forEach(key => {
            columns.push(new TableColumn(
                new TableCell({
                    field: key,
                    value: columnKeyToStringMap[key],
                    type: tableCellTypes.TH
                }),
                [],
                undefined,
                null,
                true,
                false
            ));
        });
        return columns;
    }

    addCompNameColumn(dynamicColumnKey, dynamicColumnText) {
        const existing = this.columns.find(column => column.tableCell.field === dynamicColumnKey);
        if (existing) {
            return;
        }

        // Sync.
        const newColumn = new TableColumn(
            new TableCell({
                field: dynamicColumnKey,
                value: dynamicColumnText,
                type: tableCellTypes.TH
            }),
            [],
            undefined,
            null,
            true,
            false
        );
        this.columns = [...this.columns, newColumn];

        // Async.
        $.when(window.infinito.vao.controller.compSetMappingHelper.fetchCompMapForCompetitor(dynamicColumnText))
            .then(mapName => {
                if (mapName && mapName !== dynamicColumnText) {
                    newColumn.tableCell.value = mapName;
                    this.columns = [...this.columns];
                }
            });
    }

    onClickStayDate(stayDate, e) {
        const prettyRecordDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
            this.recordDate
        );
        const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
            stayDate
        );
        const prettyPickupSetting = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
            this.pickupSetting
        );

        window.infinito.components.basicModal.render({
            title: $('<div></div>').append('<h5>Virtual Analyst</h5>').append(new MiniContext({
                recordDate: prettyRecordDate,
                stayDate: prettyStayDate,
                pickupSetting: prettyPickupSetting,
                isModalStyle: false
            })),
            isTitleHtml: true,
            bodyElem: $(new VirtualAnalyst({
                recordDate: this.recordDate,
                stayDate: stayDate,
                pickupSetting: this.pickupSetting,
                hotel: this.hotel,
            }))
        });
    }

    onClickDetails(e,stayDate){
        var $paceEvoGraph = null;

        let body = '<vao-page-businessdetails-modal stayDate="'+e+'"></vao-page-businessdetails-modal>';

        let stay_date = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(e);
        stay_date = `<h5>Business Details - ${stay_date}</h5>`;
        let $title =  $('<div></div>').append(stay_date);
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'lg'
        });

    }

    onClickNotes(e,hotels_id,stayDate,rowCells){

        let body = '<vao-modal-notes-modal stayDate="'+e+'" hotel_id="'+hotels_id+'" rowCells="'+rowCells+'"></vao-modal-notes-modal>';

        let stay_date = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(e);
        stay_date = `<h5>Notes - ${stay_date}</h5>`;
        let $title =  $('<div></div>').append(stay_date);
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size:'lg'
        });
    }

    acquireTableCell(stayDate, props) {
        const key = `__@${props.field}_${stayDate}__`;
        if (this.tableCellCache.has(key)) {
            let existingTableCell = this.tableCellCache.get(key);
            existingTableCell.reflow(props);
            return existingTableCell;
        }
        let newTableCell = new TableCell(props);
        this.tableCellCache.set(key, newTableCell);
        return newTableCell;
    }

    makeGradientColorCoderForMinAndMax(min, max) {
        const colorBuckets = window.infinito.vao.controller.gradientColorHelper.getColorBucketsForMinForMax(min, max);
        return function (statisticValue) {
            if (!$.isNumeric(statisticValue)) {
                return null;
            }
            const floatValue = parseFloat(statisticValue);
            if (floatValue === 0 || floatValue === 0.0) {
                return null;
            }
            return window.infinito.vao.controller.gradientColorHelper.getColorForValueForColorBuckets(
                floatValue,
                colorBuckets,
                null
            );
        }
    }
    makeGradientColorCoder(procData, key) {
        let values = [];
        let min, max;
        if (
            procData
            && typeof procData === 'object'
            && procData[key]
            && typeof procData[key] === 'object'
        ) {
            Object.values(procData[key]).forEach(statistic => {
                if (
                    statistic
                    && typeof statistic === 'object'
                    && 'value' in statistic
                    && $.isNumeric(statistic.value)
                ) {
                    values.push(parseFloat(statistic.value));
                }
            });
        }
        if (values.length <= 1) {
            // No point coloring.
            min = null;
            max = null;
        } else {
            min = Math.min(...values);
            max = Math.max(...values);
        }

        return this.makeGradientColorCoderForMinAndMax(min, max);
    }

    makeAdrMoveGradientColorCoder(procData) {
        return this.makeGradientColorCoder(procData, 'adrPickup');
    }

    makeCompSetAvgGradientColorCoder(procData) {
        let values = [];
        let min, max;
        if (
            procData
            && typeof procData === 'object'
            && procData.rate
            && typeof procData.rate === 'object'
            && procData.compAverageRate
            && typeof procData.compAverageRate === 'object'
        ) {
            Object.keys(procData.rate).forEach(stayDate => {
                const rate = procData.rate[stayDate];
                const compAverageRate = procData.compAverageRate[stayDate];
                if (
                    rate
                    && typeof rate === 'object'
                    && 'value' in rate
                    && $.isNumeric(rate.value)
                    && compAverageRate
                    && typeof compAverageRate === 'object'
                    && 'value' in compAverageRate
                    && $.isNumeric(compAverageRate.value)
                ) {
                    const diff = parseFloat(rate.value) - parseFloat(compAverageRate.value);
                    values.push(diff);
                }
            });
        }
        if (values.length <= 1) {
            // No point coloring.
            min = null;
            max = null;
        } else {
            min = Math.min(...values);
            max = Math.max(...values);
        }

        return this.makeGradientColorCoderForMinAndMax(min, max);
    }

    renderPaceTag(pnn, pacePickup) {
        const positive = window.infinito.vao.controller.analyseAttributesHelper.positive;
        const negative = window.infinito.vao.controller.analyseAttributesHelper.negative;
        const neutral = window.infinito.vao.controller.analyseAttributesHelper.neutral;
        const bgColor = (pacePickup > 0) ? '#def7df' : (pacePickup < 0) ? '#ffe6e7' : null;
        let icon, iconColor;

        if (pnn === positive) {
            icon = html`<vao-icon cls="${Constants.ICONS.EMOTE_SMILE}"></vao-icon>`;
            iconColor = 'text-success';
        } else if (pnn === negative) {
            icon = html`<vao-icon cls="${Constants.ICONS.EMOTE_FROWN}"></vao-icon>`;
            iconColor = 'text-danger';
        } else if (pnn === neutral) {
            icon = html`<vao-icon cls="${Constants.ICONS.EMOTE_MEH}"></vao-icon>`;
            iconColor = 'text-info';
        } else {
            return Constants.MISSING_STR;
        }

        return html`<vao-tag .label="${icon}" labelColor="${iconColor}" bgColor="${bgColor}"></vao-tag>`;
    }

    formatPercentageValue(unformattedValue) {
        var percent = parseFloat(unformattedValue * 100);
        if (!Number.isNaN(percent)) {
            return percent.toFixed(1) + '%';
        }
        return unformattedValue;
    }

    fill() {
        if (
            !this.hotel
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.startDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.endDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            this.rows = [];
            return;
        }

        const biStatistics = window.infinito.vao.model.biStatistics;
        // const dates = window.infinito.vao.controller.dateHelper.calcStayDateRangeArray(this.startDate, this.endDate);
        const dates = this.FinalDateArr;
        console.log("Dates from fill",dates);
        this.isLoading = true; 
        this.rows = []; 

        requestCount++;
        const preRequestCount = requestCount;

        (new Promise((resolve) => {
            const vaoSettings = window.infinito.vao.model.vaoSettings;
            const housekeeperSettingKey = vaoSettings.obtainHotelIdHousekeeperPerShiftKey(
                this.hotel.id
            );
            vaoSettings.fetchSettingByKey(
                window.infinito.vao.controller.storageHelper.getIdentitiesId(),
                housekeeperSettingKey,
                (setting) => {
                    if (setting && $.isNumeric(setting.value)) {
                        this.roomsPerHousekeeper = parseInt(setting.value);
                    }
                    resolve();
                }
            );
        })).then(() => {
            biStatistics.fetchStatistics(
                this.hotel.id,
                biStatistics.buildQuery({
                    recordDate: this.recordDate,
                    firstStayDate: this.startDate,
                    lastStayDate: this.endDate,
                    fields: [
                        biStatistics.fields.roomsToSell,
                        biStatistics.fields.roomsOtb,
                        biStatistics.fields.revenue,
                        biStatistics.fields.adr,
                        biStatistics.fields.roomsOtbLastYear,
                        biStatistics.fields.revenueLastYear,
                        biStatistics.fields.adrLastYear,
                        biStatistics.fields.roomsOtbPickup,
                        biStatistics.fields.revenuePickup,
                        biStatistics.fields.pickupsAdr,
                        biStatistics.fields.adrPickup,
                        biStatistics.fields.rate,
                        biStatistics.fields.ratePickup,
                        biStatistics.fields.compAverageRate,
                        biStatistics.fields.compPrices,
                        biStatistics.fields.compPricesPickup,
                        biStatistics.fields.roomsOtbLeadSiblingPacePickup,
                        biStatistics.fields.rateLeadSiblingPacePickup,
                        biStatistics.fields.compSetAvgLeadSiblingPacePickup,
                        biStatistics.fields.ratesPickup,
                        biStatistics.fields.occupancy,
                        // biStatistics.fields.priceRank,
                        // biStatistics.fields.priceRankLeadSiblingPace,
                    ],
                    pickupOffset: this.pickupSetting
                }),
                (data, procData) => {
                    if (requestCount !== preRequestCount) {
                        return; // Another ajax has taken over.
                    }

                    const _procData = (procData || {});
                    if (!_procData || Object.keys(_procData).length === 0) {
                        this.isLoading = false;
                        return;
                    }
                    const adrMoveColorCoder = this.makeAdrMoveGradientColorCoder(_procData);
                    const compSetAvgColorCoder = this.makeCompSetAvgGradientColorCoder(_procData);

                    let rows = [];
                    dates.forEach(stayDate => {
                        let rowCells = [];

                        const prettyStayDate = window.infinito.vao.controller.dateHelper
                            .getShortDayDateMonFullYearString(stayDate);
                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.stayDate,
                            value: html`
                            <vao-button 
                                text="${prettyStayDate}" 
                                variant="invert" 
                                size="xs" 
                                @click=${this.onClickStayDate.bind(this, stayDate)}
                            ></vao-button>
                        `
                        }));

                        // let notes = this.fetchNotes(stayDate,this.hotel.id,rowCells);
                        let notes = '-';

                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.notes,
                            value: ''
                        }));

                        if (
                            _procData.roomsToSell
                            && _procData.roomsToSell[stayDate]
                            && 'value' in _procData.roomsToSell[stayDate]
                            && $.isNumeric(_procData.roomsToSell[stayDate].value)
                        ) {
                            let value = parseInt(_procData.roomsToSell[stayDate].value, 10);
                            let labelColor = null;
                            if (value !== 0) {
                                //value *= -1; // Because Fabian dont wants the inverse rendered.
                                if (value < 0) {
                                    // There actually is rooms still left to sell.
                                    labelColor = Constants.COLORS.HEX.DANGER;
                                }
                            }
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.roomsToSell,
                                value: html`
                                <vao-tag
                                    .label="${value}"
                                    .labelColor="${labelColor}"
                                    canInterpretBgColor="false"
                                ></vao-tag>   
                            `
                            }));
                        }

                        if (
                            _procData.roomsOtb
                            && _procData.roomsOtb[stayDate]
                            && 'value' in _procData.roomsOtb[stayDate]
                            && $.isNumeric(_procData.roomsOtb[stayDate].value)
                        ) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.roomsOtb,
                                value: _procData.roomsOtb[stayDate].value
                            }));
                            /*
                             * Requirement:
                             *    you need to add a color formatting to show if housekeepers
                             *    changes in line with pick up (dont need to show the pick up
                             *    number change). ie if housekeeper over pick up became more
                             *    = red background, if housekeepers became less (eg cancellations)
                             *    then green. However: ONLY if the main number changes, eg from 3.8
                             *    to 4.1 (no need to show if 3.8 to 3.9 eg.) same goes for main
                             *    digits to go down.
                             */
                            if (this.roomsPerHousekeeper > 0) {
                                const roomsPerHouseKeeper = this.roomsPerHousekeeper;
                                const roomsOtb = parseInt(_procData.roomsOtb[stayDate].value, 10);
                                const perRoom = (roomsOtb / roomsPerHouseKeeper).toFixed(2);
                                let offsetRoomsOtb;
                                if (
                                    _procData.roomsOtbPickup
                                    && _procData.roomsOtbPickup[stayDate]
                                    && 'value' in _procData.roomsOtbPickup[stayDate]
                                    && $.isNumeric(_procData.roomsOtbPickup[stayDate].value)
                                ) {
                                    const pickup = parseInt(_procData.roomsOtbPickup[stayDate].value, 10);
                                    offsetRoomsOtb = roomsOtb - pickup;
                                } else {
                                    offsetRoomsOtb = roomsOtb; // To indicate no change.
                                }
                                const offsetPerRoom = (offsetRoomsOtb / roomsPerHouseKeeper).toFixed(2);
                                const offsetPerRoomInt = parseInt(offsetPerRoom, 10);
                                const perRoomInt = parseInt(perRoom, 10);
                                let labelColor = null;
                                if (perRoomInt > offsetPerRoomInt) {
                                    labelColor = Constants.COLORS.HEX.DANGER;
                                } else if (perRoomInt < offsetPerRoomInt) {
                                    labelColor = Constants.COLORS.HEX.SUCCESS;
                                }
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.housekeepers,
                                    value: html`<vao-tag
                                        label="${perRoom}"
                                        labelColor="${labelColor}"
                                        title="${offsetPerRoom}"
                                    ></vao-tag>
                                    `
                                }));
                            }
                        }

                        if (
                            _procData.occupancy
                            && _procData.occupancy[stayDate]
                            && 'value' in _procData.occupancy[stayDate]
                            && $.isNumeric(_procData.occupancy[stayDate].value)
                        ) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.occupancy,
                                value: this.formatPercentageValue(_procData.occupancy[stayDate].value)
                            }));
                        }


                        if (
                            _procData.revenue
                            && _procData.revenue[stayDate]
                            && 'value' in _procData.revenue[stayDate]
                            && $.isNumeric(_procData.revenue[stayDate].value)
                        ) {
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                _procData.revenue[stayDate].value,
                                Constants.REVENUE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.revenue,
                                value: formatted
                            }));
                        }

                        if (
                            _procData.adr
                            && _procData.adr[stayDate]
                            && 'value' in _procData.adr[stayDate]
                            && $.isNumeric(_procData.adr[stayDate].value)
                        ) {
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                _procData.adr[stayDate].value,
                                Constants.ADR_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.adr,
                                value: formatted
                            }));
                        }

                        if (
                            _procData.roomsOtb
                            && _procData.roomsOtbLastYear
                            && _procData.roomsOtb[stayDate]
                            && _procData.roomsOtbLastYear[stayDate]
                            && 'value' in _procData.roomsOtb[stayDate]
                            && 'value' in _procData.roomsOtbLastYear[stayDate]
                            && $.isNumeric(_procData.roomsOtb[stayDate].value)
                            && $.isNumeric(_procData.roomsOtbLastYear[stayDate].value)
                        ) {
                            const roomsOtb = parseInt(_procData.roomsOtb[stayDate].value, 10);
                            const roomsOtbLastYear = parseInt(_procData.roomsOtbLastYear[stayDate].value, 10);
                            const diff = String(roomsOtb - roomsOtbLastYear);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.roomsOtbVsLastYear,
                                value: html`
                                <vao-numeric-variance-block
                                    numericValue="${diff}"
                                    formattedValue="${diff}"
                                ></vao-numeric-variance-block>
                            `
                            }));
                        }

                        if (
                            _procData.revenue
                            && _procData.revenueLastYear
                            && _procData.revenue[stayDate]
                            && _procData.revenueLastYear[stayDate]
                            && 'value' in _procData.revenue[stayDate]
                            && 'value' in _procData.revenueLastYear[stayDate]
                            && $.isNumeric(_procData.revenue[stayDate].value)
                            && $.isNumeric(_procData.revenueLastYear[stayDate].value)
                        ) {
                            const revenue = parseFloat(_procData.revenue[stayDate].value);
                            const revenueLastYear = parseFloat(_procData.revenueLastYear[stayDate].value);
                            const diff = revenue - revenueLastYear;
                            const formattedDiff = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                diff,
                                Constants.REVENUE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.revenueVsLastYear,
                                value: html`
                                <vao-numeric-variance-block
                                    numericValue="${diff}"
                                    formattedValue="${formattedDiff}"
                                ></vao-numeric-variance-block>
                            `
                            }));
                        }

                        if (
                            _procData.adr
                            && _procData.adrLastYear
                            && _procData.adr[stayDate]
                            && _procData.adrLastYear[stayDate]
                            && 'value' in _procData.adr[stayDate]
                            && 'value' in _procData.adrLastYear[stayDate]
                            && $.isNumeric(_procData.adr[stayDate].value)
                            && $.isNumeric(_procData.adrLastYear[stayDate].value)
                        ) {
                            const adr = parseFloat(_procData.adr[stayDate].value);
                            const adrLastYear = parseFloat(_procData.adrLastYear[stayDate].value);
                            const diff = adr - adrLastYear;
                            const formattedDiff = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                diff,
                                Constants.ADR_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.adrVsLastYear,
                                value: html`
                                <vao-numeric-variance-block
                                    numericValue="${diff}"
                                    formattedValue="${formattedDiff}"
                                ></vao-numeric-variance-block>
                            `
                            }));
                        }

                        if (
                            _procData.roomsOtbPickup
                            && _procData.roomsOtbPickup[stayDate]
                            && 'value' in _procData.roomsOtbPickup[stayDate]
                            && $.isNumeric(_procData.roomsOtbPickup[stayDate].value)
                        ) {
                            const value = _procData.roomsOtbPickup[stayDate].value;
                            const valueInt = parseInt(value, 10);
                            const color = window.infinito.vao.controller.pickupHelper.getPickupColorFor(valueInt);
                            let bgColor = null;
                            if (color) {
                                bgColor = ColorArray.hexToRGBA(color, .15);
                            }
                            const numVarBlock = html`
                            <vao-numeric-variance-block
                                numericValue="${value}"
                                formattedValue="${value}"
                            ></vao-numeric-variance-block>
                        `;
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.roomsOtbPickup,
                                value: html`
                                <vao-tag
                                    .label="${numVarBlock}"
                                    bgColor="${bgColor}"
                                ></vao-tag>
                            `
                            }));
                        }

                        if (
                            _procData.revenuePickup
                            && _procData.revenuePickup[stayDate]
                            && 'value' in _procData.revenuePickup[stayDate]
                            && $.isNumeric(_procData.revenuePickup[stayDate].value)
                        ) {
                            const value = _procData.revenuePickup[stayDate].value;
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                value,
                                Constants.REVENUE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.revenuePickup,
                                value: html`
                                <vao-numeric-variance-block
                                    numericValue="${value}"
                                    formattedValue="${formatted}"
                                ></vao-numeric-variance-block>
                            `
                            }));
                        }

                        if (
                            _procData.pickupsAdr
                            && _procData.pickupsAdr[stayDate]
                            && 'value' in _procData.pickupsAdr[stayDate]
                            && $.isNumeric(_procData.pickupsAdr[stayDate].value)
                        ) {
                            const value = _procData.pickupsAdr[stayDate].value;
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                value,
                                Constants.ADR_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.pickupsAdr,
                                value: html`
                                <vao-numeric-variance-block
                                    numericValue="${value}"
                                    formattedValue="${formatted}"
                                ></vao-numeric-variance-block>
                            `
                            }));
                        }

                        if (
                            _procData.adrPickup
                            && _procData.adrPickup[stayDate]
                            && 'value' in _procData.adrPickup[stayDate]
                            && $.isNumeric(_procData.adrPickup[stayDate].value)
                        ) {
                            const value = _procData.adrPickup[stayDate].value;
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                value,
                                Constants.ADR_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            const bgColor = adrMoveColorCoder(value);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.adrPickup,
                                value: html`
                                <vao-tag
                                    label="${formatted}"
                                    bgColor="${bgColor}"
                                ></vao-tag>
                            `
                            }));
                        }

                        if (
                            _procData.roomsOtbLeadSiblingPacePickup
                            && _procData.roomsOtbLeadSiblingPacePickup[stayDate]
                            && 'value' in _procData.roomsOtbLeadSiblingPacePickup[stayDate]
                            && _procData.roomsOtbLeadSiblingPacePickup[stayDate].value
                            && typeof _procData.roomsOtbLeadSiblingPacePickup[stayDate].value === 'object'
                            && 'T0' in _procData.roomsOtbLeadSiblingPacePickup[stayDate].value
                        ) {
                            const value = _procData.roomsOtbLeadSiblingPacePickup[stayDate].value;
                            const variance = parseInt(value.T0.valueDense, 10);
                            const rankNow = value.T0.raw.setDenseRank;
                            const lengthNow = value.T0.raw.setDenseLength;
                            const pnn = window.infinito.vao.controller.analyseAttributesHelper
                                .getMyOtbPNNForSetRank(rankNow, lengthNow);
                            const tagTemplateResult = this.renderPaceTag(pnn, variance);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.roomsOtbPace,
                                value: tagTemplateResult
                            }));
                        }

                        if (
                            _procData.rateLeadSiblingPacePickup
                            && _procData.rateLeadSiblingPacePickup[stayDate]
                            && 'value' in _procData.rateLeadSiblingPacePickup[stayDate]
                            && _procData.rateLeadSiblingPacePickup[stayDate].value
                            && typeof _procData.rateLeadSiblingPacePickup[stayDate].value === 'object'
                            && 'T0' in _procData.rateLeadSiblingPacePickup[stayDate].value
                        ) {
                            const value = _procData.rateLeadSiblingPacePickup[stayDate].value;
                            const variance = parseInt(value.T0.valueDense, 10);
                            const rankNow = value.T0.raw.setDenseRank;
                            const lengthNow = value.T0.raw.setDenseLength;
                            const pnn = window.infinito.vao.controller.analyseAttributesHelper
                                .getMyPricePNNForSetRank(rankNow, lengthNow);
                            const tagTemplateResult = this.renderPaceTag(pnn, variance);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.ratePace,
                                value: tagTemplateResult
                            }));
                        }

                        if (
                            _procData.ratesPickup
                            && _procData.ratesPickup[stayDate]
                            && 'value' in _procData.ratesPickup[stayDate]
                            && _procData.ratesPickup[stayDate].value
                            && typeof _procData.ratesPickup[stayDate].value === 'object'
                        ) {
                            const nowAndThen = window.infinito.vao.controller.analyseAttributesHelper
                                .interpretRatesPickupForAvailability(_procData.ratesPickup[stayDate]);
                            const now = nowAndThen.now;
                            const then = nowAndThen.then;
                            let variance = 0;
                            if (now === 'positive') {
                                if (then === 'neutral' || then === 'negative') {
                                    variance = 1;
                                }
                            } else if (now === 'neutral') {
                                if (then === 'positive') {
                                    variance = -1;
                                } else if (then === 'negative') {
                                    variance = 1;
                                }
                            } else if (now === 'negative') {
                                if (then === 'positive' || then === 'neutral') {
                                    variance = -1;
                                }
                            }
                            const tagTemplateResult = this.renderPaceTag(now, variance);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.availabilityPace,
                                value: tagTemplateResult
                            }));
                        }

                        if (
                            _procData.compSetAvgLeadSiblingPacePickup
                            && _procData.compSetAvgLeadSiblingPacePickup[stayDate]
                            && 'value' in _procData.compSetAvgLeadSiblingPacePickup[stayDate]
                            && _procData.compSetAvgLeadSiblingPacePickup[stayDate].value
                            && typeof _procData.compSetAvgLeadSiblingPacePickup[stayDate].value === 'object'
                            && 'T0' in _procData.compSetAvgLeadSiblingPacePickup[stayDate].value
                        ) {
                            const value = _procData.compSetAvgLeadSiblingPacePickup[stayDate].value;
                            const variance = parseInt(value.T0.valueDense, 10);
                            const rankNow = value.T0.raw.setDenseRank;
                            const lengthNow = value.T0.raw.setDenseLength;
                            const pnn = window.infinito.vao.controller.analyseAttributesHelper
                                .getMyPricePNNForSetRank(rankNow, lengthNow);
                            const tagTemplateResult = this.renderPaceTag(pnn, variance);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.compSetAvgPace,
                                value: tagTemplateResult
                            }));
                        }

                        if (
                            _procData.rate
                            && _procData.rate[stayDate]
                            && 'value' in _procData.rate[stayDate]
                            /*&& $.isNumeric(_procData.rate[stayDate].value)*/
                        ) {
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                _procData.rate[stayDate].value,
                                Constants.RATE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.rate,
                                value: formatted
                            }));
                        }

                        if (
                            _procData.ratePickup
                            && _procData.ratePickup[stayDate]
                            && 'value' in _procData.ratePickup[stayDate]
                            && $.isNumeric(_procData.ratePickup[stayDate].value)
                        ) {
                            const value = _procData.ratePickup[stayDate].value;
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                value,
                                Constants.RATE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.ratePickup,
                                value: html`
                                <vao-numeric-variance-block
                                    numericValue="${value}"
                                    formattedValue="${formatted}"
                                ></vao-numeric-variance-block>
                            `
                            }));
                        }

                        if (
                            _procData.rate
                            && _procData.rate[stayDate]
                            && 'value' in _procData.rate[stayDate]
                            && $.isNumeric(_procData.rate[stayDate].value)
                            && _procData.compAverageRate
                            && _procData.compAverageRate[stayDate]
                            && 'value' in _procData.compAverageRate[stayDate]
                            && $.isNumeric(_procData.compAverageRate[stayDate].value)
                        ) {
                            const rateValue = parseFloat(_procData.rate[stayDate].value);
                            const compValue = parseFloat(_procData.compAverageRate[stayDate].value);
                            const diff = rateValue - compValue;
                            const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                diff,
                                Constants.RATE_DIGITS,
                                true,
                                this.hotel.locale
                            );
                            const bgColor = compSetAvgColorCoder(diff);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.compSetAvg,
                                value: html`
                                <vao-tag
                                    label="${formatted}"
                                    bgColor="${bgColor}"
                                ></vao-tag>
                            `
                            }));
                        }

                        let compPricesPickup = {};
                        if (
                            _procData.compPricesPickup
                            && _procData.compPricesPickup[stayDate]
                            && 'value' in _procData.compPricesPickup[stayDate]
                            && isJSONString(_procData.compPricesPickup[stayDate].value)
                        ) {
                            compPricesPickup = JSON.parse(_procData.compPricesPickup[stayDate].value);
                        }

                        if (
                            _procData.compPrices
                            && _procData.compPrices[stayDate]
                            && 'value' in _procData.compPrices[stayDate]
                            && _procData.compPrices[stayDate].value != 'no data'
                            && isJSONString(_procData.compPrices[stayDate].value)
                        ) {
                            const compPrices = JSON.parse(_procData.compPrices[stayDate].value);
                            Object.keys(compPrices).forEach(compKey => {
                                const safeCompKey = window.infinito.vao.controller.utils.safeCssName(compKey);
                                const compValue = compPrices[compKey];
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    compValue,
                                    Constants.RATE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                const pickup = compPricesPickup[compKey];
                                let labelColor = '';
                                if ($.isNumeric(pickup)) {
                                    const pickupFloat = parseFloat(pickup);
                                    if (pickupFloat > 0) {
                                        labelColor = Constants.COLORS.HEX.SUCCESS;
                                    } else if (pickupFloat < 0) {
                                        labelColor = Constants.COLORS.HEX.DANGER;
                                    }
                                }
                                this.addCompNameColumn(safeCompKey, compKey);

                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: safeCompKey,
                                    value: html`
                                    <vao-tag
                                        label="${formatted}"
                                        labelColor="${labelColor}"
                                    ></vao-tag>
                                `
                                }));
                            });
                        }

                        if (
                            _procData.roomsOtb
                            && _procData.roomsOtb[stayDate]
                            && 'value' in _procData.roomsOtb[stayDate]
                            && $.isNumeric(_procData.roomsOtb[stayDate].value)
                        ) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.forecast,
                                value: html`
                                <vao-story-forecast-subtitle 
                                    .recordDate="${this.recordDate}" 
                                    .stayDate="${stayDate}" 
                                    .hotel="${this.hotel}"
                                ></vao-story-forecast-subtitle>
                            `
                            }));
                        }
                        if (
                            _procData.roomsOtb
                            && _procData.roomsOtb[stayDate]
                            && 'value' in _procData.roomsOtb[stayDate]
                            && $.isNumeric(_procData.roomsOtb[stayDate].value)
                        ) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.fcrange,
                                value: html`
                                <vao-story-forecastrange-subtitle
                                    .recordDate="${this.recordDate}" 
                                    .stayDate="${stayDate}" 
                                    .hotel="${this.hotel}"
                                ></vao-story-forecastrange-subtitle>
                            `
                            }));
                        }

                        if (
                            _procData.roomsOtb
                            && _procData.roomsOtb[stayDate]
                            && 'value' in _procData.roomsOtb[stayDate]
                            && $.isNumeric(_procData.roomsOtb[stayDate].value)
                        ) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.expectedPickup,
                                value: html`
                                <vao-story-what-to-expect-pickup-subtitle
                                    .recordDate="${this.recordDate}" 
                                    .stayDate="${stayDate}" 
                                    .hotel="${this.hotel}"
                                ></vao-story-what-to-expect-pickup-subtitle>
                            `
                            }));
                        }


                        // Below if is for Positioning -

                        var leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                            stayDate,
                            this.recordDate
                        );

                        let normality = this.fetchPosition(stayDate,this.recordDate,rowCells);

                        rowCells.push(this.acquireTableCell(stayDate, {
                            field: cellFieldMap.positioning,
                                value: ''
                        }));

                        // Below if is for Details -

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.details,   //priceRankLeadSiblingPace,
                                value: html` <vao-button
                                                startIcon="${Constants.ICONS.ELLIPSIS}"
                                                variant="invert"
                                                color="secondary"
                                                size="xs"
                                                @click=${this.onClickDetails.bind(this,stayDate)}
                                        ></vao-button>`
                            }));

                        rows.push(new TableRow(rowCells));
                    });

                    this.rows = rows;
                    this.isLoading = false;
                }
            );
        });
    }

    fetchNotes(stayDate,hotels_id,rowCells){
        let dataObj = {
            //'hotel_id': this.hotel.id,
            'hotel_id': hotels_id,
            'stayDate': stayDate,
            'op': 'getNotesCount',
            'serviceName': "desktop.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            let counttext = jsonObj.data.count;
            let counthtml = html `<span id="${stayDate}">${counttext}</span>`;

            if(counttext==0){
                counthtml = html `<span id="${stayDate}">${Constants.MISSING_STR}</span>`;
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.notes,
                    value: html` <vao-button
                                .text="${counthtml}"
                                variant="invert"
                                size="xs"
                                @click=${flag.onClickNotes.bind(this,stayDate,hotels_id,rowCells)}
                            ></vao-button>`,
                }));

            }else{
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.notes,
                    value: html `<vao-button
                            .text="${counthtml}"
                            variant="invert"
                            size="xs"
                            @click=${flag.onClickNotes.bind(this,stayDate,hotels_id,rowCells)}
                    ></vao-button>`,
                    /*
                        <vao-button
                            .text="${counttext}"
                            variant="invert"
                            size="xs"
                            @click=${flag.onClickNotes.bind(this,stayDate,hotels_id,rowCells)}
                        ></vao-button>
                    */
                }));
            }

        });


    }
    fetchPosition(stayDate,recordDate,rowCells){
        let normality = Constants.MISSING_STR;
        const biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: recordDate,
                firstStayDate: stayDate,
                lastStayDate: stayDate,
                fields: [
                    biStatistics.fields.priceRank,
                    biStatistics.fields.priceRankLeadSiblingPace,
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {

                let typicalPriceRank = '';
                let _procData = procData;
                let priceRankLeadSiblingPace =
                    ((_procData.priceRankLeadSiblingPace || {})[stayDate] || {}).value || null;

                if (
                    priceRankLeadSiblingPace
                    && typeof priceRankLeadSiblingPace === 'object'
                    && 'T0' in priceRankLeadSiblingPace
                    && 'setRank' in priceRankLeadSiblingPace.T0
                    && $.isNumeric(priceRankLeadSiblingPace.T0.setRank)
                ) {
                    let setLength = parseInt(priceRankLeadSiblingPace.T0.setLength);
                    let setRankSum = 0;
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T0.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T1.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T2.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T3.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T4.value || 0) || 0);
                    if (setLength) {
                        let avg = +parseFloat(setRankSum / setLength).toFixed(0); // + to drop 0's
                        typicalPriceRank = avg + '';
                    } else {
                        typicalPriceRank = Constants.MISSING_STR;
                    }
                } else {
                    typicalPriceRank = Constants.MISSING_STR;
                }
                let rateRank = _procData.priceRank[stayDate].value;

                if ($.isNumeric(rateRank) && $.isNumeric(typicalPriceRank)) {
                    const diff = parseInt(rateRank) - parseInt(typicalPriceRank);

                    if (diff <= 1 && diff >= -1) {
                        normality = new StoryText('Typical', true, storyTextTypes.TYPE_NEUTRAL);
                    } else if (diff > 0) {
                        normality = new StoryText('High', true, storyTextTypes.TYPE_POSITIVE);
                    } else {
                        normality = new StoryText('Low', true, storyTextTypes.TYPE_NEGATIVE);
                    }
                }

                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.positioning,
                    value: storyHtml`${normality}`,
                }));
            });

    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('hotel')
            || _changedProperties.has('recordDate')
            || _changedProperties.has('pickupSetting')
            || _changedProperties.has('startDate')
            || _changedProperties.has('endDate')
        ) {
            this.fill();
        }
    }

    onChangeTableOpts(e) {
        if (e.detail.newTableOpts) {
            this.tableOpts = e.detail.newTableOpts; // Share obj with table to not cause re-render.
        }
    }

    render() {
        return html`
            <!-------------------------- Add New Template Starts --------------------------->
            <div style="width: auto;">
                <style>
                    .modal{
                        padding-left: 0px !important;
                    }
                    .modal-xl{
                        max-width: 1200px !important;
                        min-width: 1200px !important;
                    }                    
                    #no_schedule_display .col-sm-2 {                        
                        max-width: 14% !important; 
                    }
                    input,
                    input::placeholder {
                        font-size:14px;
                    }
                    .selectbox{
                        border: none;
                        // border-bottom: 2px solid #bdbdbd;
                        border-radius: 0px;
                        -webkit-appearance: none;
                        font-size: 14px;
                        color: #2196f3;                        
                    }
                    .opblink{
                        animation: blink 0.7s;
                        animation-iteration-count: infinite;
                    }
                    @keyframes blink { 50% { border-bottom: 2px solid #bdbdbd; border-color:#2196f3; }  }                                        
                    .table{
                        margin-bottom: 0px;
                    }
                    .table td, .table th{
                        border: none;
                    }                    
                    .form-control:focus {
                        border-color: #ffffff;
                        box-shadow: none;
                    }                    
                    .selectbox:hover{
                        border-bottom: 2px solid #2196f3;
                    }                                       

                    .schedulelabel{
                        display: flex;
                    }
                    .scheduledays{
                        width: 20px;
                        height: 20px;
                    }
                    .flex{
                        display: flex;
                    }
                    input[type="checkbox"], select {
                        cursor: pointer;
                    }
                    select option[value="none"] {
                        color: #999999 !important;
                    }
                    input{
                        padding-left:0px;
                    }
                    select{
                        padding-left:0px;
                    }                               
                    
                    .table-cell-roomsOtbVsLastYear, .table-cell-revenueVsLastYear, .table-cell-adrVsLastYear, .table-cell-adrPickup, .table-cell-details, .table-cell-housekeepers, .table-cell-ratePickup, .table-cell-compSetAvg, .table-cell-notes{
                        display: none !important;
                    }
                </style>                                

                <div class="mb-2">                    
                    ${this.articleTemplate()}                                                            
                </div>                                    
                
                <div class="row" style="display: block; margin: auto; text-align: center;">
                    <button type="button" class="btn btn-md btn-primary btn-class" id="rundata" @click="${this.rundata}">Run</button>
                    <!-- <button type="button" class="btn btn-md btn-danger btn-class" class="close" data-dismiss="modal" aria-label="Close">Cancel</button> -->
                </div>            
            
            </div>
            <!-------------------------- Add New Template Ends --------------------------->

            <div class="vao__components--PageAdhocSingleTable" style="margin-top:30px">
                <vao-table 
                    stateKey="${this.tableStateKey}"
                    .tableOpts="${this.tableOpts}"
                    .columns="${this.columns}"
                    .rows="${this.isLoading ? [new TableLoadingRow()] : this.rows.length > 0 ? this.rows : [new TableNoDataRow()]}"
                    @vao-table-tableopts-change="${this.onChangeTableOpts}"
                ></vao-table>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageAdhocSingleTable = PageAdhocSingleTable;
customElements.define('vao-page-adhoc-single-table', PageAdhocSingleTable);
