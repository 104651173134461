import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import Expand from "./Expand";

export default class ExpandCollapseAllBtn extends AppElement {
    static get properties() {
        return {
            targetSelector: { type: String },
        };
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();

        this.targetSelector = props.targetSelector;
    }

    reflow(props = {}) {
        this.targetSelector = props.targetSelector || this.targetSelector;
    }

    onClick() {
        $(this.targetSelector).find('vao-expand').each((index, elem) => {
            if (elem instanceof Expand) {
                elem.isExpanded = 'false';
            }
        });
    }

    render() {
        return html`
        <div class="vao__components--expandCollapseAllBtn">
            <vao-button 
                startIcon="${Constants.ICONS.COLLAPSE_ALL}" 
                variant="invert"
                color="primary"
                size="xs"
                @click="${this.onClick}"
            ></vao-button>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ExpandCollapseAllBtn = ExpandCollapseAllBtn;
customElements.define('vao-expand-collapse-all-btn', ExpandCollapseAllBtn);