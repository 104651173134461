let forecastInterpretations = window.infinito.vao.controller.utils.forecastInterpretations;
let pos = 'positive';
let neu = 'neutral';
let neg = 'negative';

export const mapForecast = {
    [forecastInterpretations.excessDemand]: 'We are expected to fill. ',
    [forecastInterpretations.abilityToFill]: 'High 90\'s. It\'s in our hands to fill. ',
    [forecastInterpretations.noFill]: 'Not a day where we will fill. '
};

export const mapRoomsPaceAdrPaceComboPNN = {
    [pos + pos]: 'Both rooms and ADR pace are ahead of trend. ',
    [pos + neg]: 'Ahead of rooms pace but lagging behind in ADR. ',
    [neg + neg]: 'Both rooms and ADR pace are behind of trend. ',
    [neg + pos]: 'Behind in rooms pace, yet pacing ahead in ADR. '
};

export const mapMyPricePacePNN = {
    [pos]: 'Our rates are higher than usual. ',
    [neu]: 'Our rates are as usual. ',
    [neg]: 'Our rates are lower than usual. '
};

export const mapCompPricePacePNN = {
    [pos]: 'Competitors are pricing higher than where they typically price themselves. This means they are seeing demand too. ',
    [neu]: 'Competitors are pricing how they normally price themselves. This means our competitors are treating it as business as usual from their end. ',
    [neg]: 'Competitors are pricing below where they normally price themselves. This means downward pressure. '
};

export const mapPositionPNN = {
    [pos]: 'Positioning wise, we are sitting at the top end of the competitor set. Meaning higher than where we are normally positioned. This might impact our conversions and visibility on OTA\'s. ',
    [neu]: 'Our positioning is within our typical range, nothing out of the ordinary. Really just means business as usual. ',
    [neg]: 'We are positioned below our normal range. This means we are either losing out on opportunities or we are trying to buy occupancy. '
};

export const mapAvailabilityPNN = {
    [pos]: 'Some of our competitors are closed out or have restrictions in place. This means less choice for people to book our competitors. ',
    [neu]: 'All competitors are selling for the day. ',
    [neg]: 'We are not selling or have a one night restriction in place. '
};

export const mapRoomsOtbPacePerSetRank = {
    1: 'We are ahead in occupancy',
    2: 'Slightly ahead in occupancy',
    3: 'In line with typical occupancy levels',
    4: 'Slightly behind in occupancy',
    5: 'We are behind in occupancy'
};

export const mapCompPacePerSetRank = {
    1: 'Top of their price range',
    2: 'Higher than normal',
    3: 'Typical price range',
    4: 'Lower than normal',
    5: 'Bottom of their price range'
};

export const mapRatePacePerSetRank = {
    1: 'Top of our price range',
    2: 'Higher than normal',
    3: 'Typical price range',
    4: 'Lower than normal',
    5: 'Bottom of our price range'
};

export const mapPriceRankPerSetRank = {
    1: 'Top of the pack',
    2: 'Typical',
    3: 'Typical',
    4: 'Typical',
    5: 'Bottom of the pack'
};

export const mapRoomsPacePickupTrendComboPNN = {
    [pos + pos]: 'Ahead with strong pickup',
    [pos + neg]: 'Ahead but pickup trend declining',
    [pos + neu]: 'Keeping ahead of pace',
    [neg + pos]: 'Behind but signs of recovery',
    [neg + neg]: 'Worsening decline trend',
    [neg + neu]: 'Behind pace, but not getting worse',
    [neu + pos]: 'Review as getting stronger',
    [neu + neg]: 'Watch out, we are getting weaker',
    [neu + neu]: 'Business as usual, change approach'
};

export const mapMyPricingCompPricingPositioningAvailabilityCombosPNN = {
    [pos + pos + pos + pos]: 'Time to be bullish',
    [pos + pos + pos + neu]: 'Time to be bullish',
    [pos + pos + neu + pos]: 'Top of our price range',
    [pos + pos + neu + neu]: 'Top of our price range',
    [pos + pos + neg + pos]: 'Upside opportunity',
    [pos + pos + neg + neu]: 'Upside opportunity',
    [pos + neu + pos + pos]: 'Risk of overpricing',
    [pos + neu + pos + neu]: 'Risk of overpricing',
    [pos + neu + neu + pos]: 'Upside opportunity',
    [pos + neu + neu + neu]: 'Review positioning',
    [pos + neu + neg + pos]: 'Upside opportunity',
    [pos + neu + neg + neu]: 'Review positioning',
    [pos + neg + pos + pos]: 'High risk of being overpriced',
    [pos + neg + pos + neu]: 'High risk of being overpriced',
    [pos + neg + neu + pos]: 'High risk of being overpriced',
    [pos + neg + neu + neu]: 'High risk of being overpriced',
    [pos + neg + neg + pos]: 'High risk of being overpriced',
    [pos + neg + neg + neu]: 'High risk of being overpriced',
    [neu + pos + pos + pos]: 'Review positioning',
    [neu + pos + pos + neu]: 'Review positioning',
    [neu + pos + neu + pos]: 'Upside opportunity',
    [neu + pos + neu + neu]: 'Upside opportunity',
    [neu + pos + neg + pos]: 'Upside opportunity',
    [neu + pos + neg + neu]: 'Upside opportunity',
    [neu + neu + pos + pos]: 'Review positioning',
    [neu + neu + pos + neu]: 'Review positioning',
    [neu + neu + neu + pos]: 'Typical price range',
    [neu + neu + neu + neu]: 'Typical price range',
    [neu + neu + neg + pos]: 'Upside opportunity',
    [neu + neu + neg + neu]: 'Upside opportunity',
    [neu + neg + pos + pos]: 'Review positioning',
    [neu + neg + pos + neu]: 'Review positioning',
    [neu + neg + neu + pos]: 'Review positioning',
    [neu + neg + neu + neu]: 'Review positioning',
    [neu + neg + neg + pos]: 'Review positioning',
    [neu + neg + neg + neu]: 'Review positioning',
    [neg + pos + pos + pos]: 'High chance of being underpriced',
    [neg + pos + pos + neu]: 'High chance of being underpriced',
    [neg + pos + neu + pos]: 'High chance of being underpriced',
    [neg + pos + neu + neu]: 'Upside opportunity',
    [neg + pos + neg + pos]: 'Low rates to drive demand?',
    [neg + pos + neg + neu]: 'Low rates to drive demand?',
    [neg + neu + pos + pos]: 'Low rates to drive demand?',
    [neg + neu + pos + neu]: 'Low rates to drive demand?',
    [neg + neu + neu + pos]: 'Low rates to drive demand?',
    [neg + neu + neu + neu]: 'Low rates to drive demand?',
    [neg + neu + neg + pos]: 'Low rates to drive demand?',
    [neg + neu + neg + neu]: 'Low rates to drive demand?',
    [neg + neg + pos + pos]: 'Overpriced in low demand',
    [neg + neg + pos + neu]: 'Overpriced in low demand',
    [neg + neg + neu + pos]: 'Review positioning',
    [neg + neg + neu + neu]: 'Review positioning',
    [neg + neg + neg + pos]: 'Trying to buy occupancy?',
    [neg + neg + neg + neu]: 'Rock bottom',
};

export const mapWhatToExpectPickupCompMyPricePNN = {
    [pos + pos + pos]: 'We need to act',
    [pos + pos + neg]: 'Mixed signals',
    [pos + pos + neu]: 'We need to act',
    [pos + neg + pos]: 'Downward pressure',
    [pos + neg + neg]: 'Downward pressure',
    [pos + neg + neu]: 'Downward pressure',
    [pos + neu + pos]: 'We need to act',
    [pos + neu + neg]: 'We need to act',
    [pos + neu + neu]: 'We need to act',
    [neg + pos + pos]: 'Expect drop',
    [neg + pos + neg]: 'Mixed signals',
    [neg + pos + neu]: 'Expect drop',
    [neg + neg + pos]: 'Mixed signals',
    [neg + neg + neg]: 'Everything will drop',
    [neg + neg + neu]: 'Expect drop',
    [neg + neu + pos]: 'Expect drop',
    [neg + neu + neg]: 'Expect drop',
    [neg + neu + neu]: 'Expect drop',
    [neu + pos + pos]: 'Rates will increase',
    [neu + pos + neg]: 'Mixed signals',
    [neu + pos + neu]: 'Review comp set',
    [neu + neg + pos]: 'Review comp set',
    [neu + neg + neg]: 'Review comp set',
    [neu + neg + neu]: 'Review comp set',
    [neu + neu + pos]: 'We will make a move',
    [neu + neu + neg]: 'We will make a move',
    [neu + neu + neu]: 'Nothing in next 7 days'
};
