import {AppElement, html} from "../AppElement";
import Icon from "../icon/Icon";
import Constants from "../Constants";

export default class TableNoDataRow extends AppElement {
    static get properties() {
        return {
            text: { type: String },
            icon: { type: Object }
        };
    }

    constructor(props = {}) {
        super();
        this.text = props.text || Constants.NO_DATA_STRING;
        this.icon = (props.icon instanceof Icon) ? props.icon : html`
            <vao-icon cls="${Constants.ICONS.EMOTE_SAD_CRY}"></vao-icon>
        `;
    }

    render() {
        return html`
<div class="vao__components--tableNoDataRow" style="color:gray;padding:.8rem;text-align:center;">
    <div style="font-size:3rem;">${this.icon}</div>
    <div style="font-size:.8rem;">${this.text}</div>
</div>`;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableNoDataRow = TableNoDataRow;
customElements.define('vao-table-no-data-row', TableNoDataRow);
