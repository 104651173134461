import {AppElement, html} from "../AppElement";
import Constants from "../Constants";

export const tableCellTypes = {
    TD: 'td',
    TH: 'th'
};

export default class TableCell extends AppElement {
    static get properties() {
        return {
            field: { type: String },
            value: { type: Object },
            sortableValue: { type: String },
            rowspan: { type: Number },
            colspan: { type: Number },
            isLoading: { type: Boolean },
            type: { type: String },
            cellcolor: {type: String},
        };
    }

    constructor(props = {}) {
        super();
        this.id = AppElement.getUniqueElementId();
        if (typeof props.field !== 'string') {
            this.field = 'none';
        } else {
            this.field = props.field;
        }
        this.value = props.value || Constants.MISSING_STR;
        this.rowSpan = props.rowSpan || 1;
        this.colSpan = props.colSpan || 1;
        this.isLoading = props.isLoading;
        this.cellcolor = props.cellcolor || '';
        this.type = (props.type in tableCellTypes) ? props.type : tableCellTypes.TD;
        this.sortableValue = props.sortableValue;
    }

    reflow(props = {}) {
        if (typeof props.field !== 'string') {
            this.field = 'none';
        } else {
            this.field = props.field;
        }
        this.value = props.value || Constants.MISSING_STR;
        this.rowSpan = props.rowSpan || 1;
        this.colSpan = props.colSpan || 1;
        this.isLoading = props.isLoading;
        this.cellcolor = props.cellcolor || '';
        this.type = (props.type in tableCellTypes) ? props.type : tableCellTypes.TD;
        this.sortableValue = props.sortableValue || this.sortableValue;
    }

    getSortableValue() {
        if ($.isNumeric(this.sortableValue)) {
            return parseFloat(this.sortableValue);
        }
        if (typeof this.sortableValue === 'string') {
            return this.sortableValue;
        }
        return null;
    }

    render() {
        if (this.isLoading === true) {
            return html`
<div class="vao__components--tableCell">
    <div class="loading-shimmer"></div>
</div>`;
        } else {
            return html`
<div class="vao__components--tableCell">
    ${this.value === null ? Constants.MISSING_STR : this.value}
</div>`;
        }
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TableCell = TableCell;
customElements.define('vao-table-cell', TableCell);
