import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';
import StoryWhatToExpectPickup from "../storyWhatToExpectPickup/StoryWhatToExpectPickup";

export default class StoryInternalKpi extends AppElement {
    static get properties() {
        return {
            roomsToSell: { type: String },
            roomsToHitLY: { type: String },
            roomsToHitB: { type: String},
            revToHitLY: { type: String },
            revToHitB: { type: String },
            adrToHitLY: { type: String },
            adrToHitB: { type: String },
            rate: { type: String },
            rateToAdrLYDiff: { type: String },
            otbForecast: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.kpiWrapId = AppElement.getUniqueElementId();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();

        this.cache = new Map();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.reflowPickupEl();
        this.fill();
    }

    reflowPickupEl() {
        if (this.cache.has('pickupEl')) {
            let pickupEl = this.cache.get('pickupEl');
            if (pickupEl instanceof StoryWhatToExpectPickup) {
                pickupEl.reflow({
                    recordDate: this.recordDate,
                    stayDate: this.stayDate,
                    hotel: this.hotel
                });
            }
        }
    }

    empty() {
        this.roomsToSell = Constants.MISSING_STR;
        this.roomsToHitLY = Constants.MISSING_STR;
        this.roomsToHitB = Constants.MISSING_STR;
        this.revToHitLY = Constants.MISSING_STR;
        this.revToHitB = Constants.MISSING_STR;
        this.adrToHitLY = Constants.MISSING_STR;
        this.adrToHitB = Constants.MISSING_STR;
        this.rate = Constants.MISSING_STR;
        this.rateToAdrLYDiff = Constants.MISSING_STR;
        this.otbForecast = Constants.MISSING_STR;
    }

    fill() {
        // Anti-pattern: Need to refactor each of these components.
        let kpiOpts = {
            hotel: this.hotel,
            recordDate: this.recordDate,
            stayDate: this.stayDate,
            locale: this.locale
        };
        let $kpiWrap = $('#' + this.kpiWrapId);
        let $occDetailsBlock = window.infinito.components.occupancyDetailsBlock.render(kpiOpts);
        let $adrDetailsBlock = window.infinito.components.adrDetailsBlock.render(kpiOpts);
        let $otbDetailsBlock = window.infinito.components.revDetailsBlock.render(kpiOpts);
        $kpiWrap.empty();
        $kpiWrap.append($occDetailsBlock).append($adrDetailsBlock).append($otbDetailsBlock);

        // Fetch data from BI API
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;

        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.roomsToSell,
                    modelBiStats.fields.roomsOtb,
                    modelBiStats.fields.roomsOtbLastYear,
                    modelBiStats.fields.roomsOtbBudget,
                    modelBiStats.fields.revenue,
                    modelBiStats.fields.revenueLastYear,
                    modelBiStats.fields.revenueBudget,
                    modelBiStats.fields.rate,
                    modelBiStats.fields.occupancyLeaderCloserTrendProphecy
                ]
            }),
            (data, procData) => {
                let roomsToSellVal = ((procData.roomsToSell || {})[this.stayDate] || {}).value || null;
                let roomsOtbVal = ((procData.roomsOtb || {})[this.stayDate] || {}).value || null;
                let roomsOtbLastYearVal = ((procData.roomsOtbLastYear || {})[this.stayDate] || {}).value || null;
                let roomsOtbBudgetVal = ((procData.roomsOtbBudget || {})[this.stayDate] || {}).value || null;
                let revenueVal = ((procData.revenue || {})[this.stayDate] || {}).value || null;
                let revenueLastYearVal = ((procData.revenueLastYear || {})[this.stayDate] || {}).value || null;
                let revenueBudgetVal = ((procData.revenueBudget || {})[this.stayDate] || {}).value || null;
                let rateVal = ((procData.rate || {})[this.stayDate] || {}).value || null;
                let occProphecy =
                    ((procData.occupancyLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || null;

                if ($.isNumeric(roomsToSellVal)) {
                    this.roomsToSell = roomsToSellVal;
                } else {
                    this.roomsToSell = Constants.MISSING_STR;
                }

                if ($.isNumeric(roomsOtbVal) && $.isNumeric(roomsOtbLastYearVal)) {
                    this.roomsToHitLY = roomsOtbLastYearVal - roomsOtbVal;
                } else {
                    this.roomsToHitLY = Constants.MISSING_STR;
                }

                if ($.isNumeric(roomsOtbVal) && $.isNumeric(roomsOtbBudgetVal)) {
                    this.roomsToHitB = roomsOtbBudgetVal - roomsOtbVal;
                } else {
                    this.roomsToHitB = Constants.MISSING_STR;
                }

                if ($.isNumeric(revenueVal) && $.isNumeric(revenueLastYearVal)) {
                    this.revToHitLY = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueLastYearVal - revenueVal,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.revToHitLY = Constants.MISSING_STR;
                }

                if ($.isNumeric(revenueVal) && $.isNumeric(revenueBudgetVal)) {
                    this.revToHitB = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revenueBudgetVal - revenueVal,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.revToHitB = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(roomsOtbVal)
                    && $.isNumeric(roomsOtbLastYearVal)
                    && $.isNumeric(revenueVal)
                    && $.isNumeric(revenueLastYearVal)
                ) {
                    let rms = roomsOtbLastYearVal - roomsOtbVal;
                    let rev = revenueLastYearVal - revenueVal;
                    let adr = rev / rms;
                    if ($.isNumeric(adr)) {
                        this.adrToHitLY = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            adr,
                            Constants.ADR_DIGITS,
                            true,
                            this.locale
                        );
                    } else {
                        this.adrToHitLY = Constants.MISSING_STR;
                    }
                } else {
                    this.adrToHitLY = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(roomsOtbVal)
                    && $.isNumeric(roomsOtbBudgetVal)
                    && $.isNumeric(revenueVal)
                    && $.isNumeric(revenueBudgetVal)
                ) {
                    let rms = roomsOtbBudgetVal - roomsOtbVal;
                    let rev = revenueBudgetVal - revenueVal;
                    let adr = rev / rms;
                    if ($.isNumeric(adr)) {
                        this.adrToHitB = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            adr,
                            Constants.ADR_DIGITS,
                            true,
                            this.locale
                        );
                    } else {
                        this.adrToHitB = Constants.MISSING_STR;
                    }
                } else {
                    this.adrToHitB = Constants.MISSING_STR;
                }

                if ($.isNumeric(rateVal)) {
                    this.rate = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        rateVal,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    )
                } else {
                    this.rate = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(rateVal)
                    && $.isNumeric(roomsOtbVal)
                    && $.isNumeric(roomsOtbLastYearVal)
                    && $.isNumeric(revenueVal)
                    && $.isNumeric(revenueLastYearVal)
                ) {
                    let rms = roomsOtbLastYearVal - roomsOtbVal;
                    let rev = revenueLastYearVal - revenueVal;
                    let adr = rev / rms;
                    if ($.isNumeric(adr)) {
                        let rateToAdr = rateVal - adr;
                        if (rateToAdr < 0) {
                            this.rateToAdrLYDiff = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                rateToAdr,
                                Constants.RATE_DIGITS,
                                true,
                                this.locale
                            ) + ' below';
                        } else if (rateToAdr > 0) {
                            this.rateToAdrLYDiff = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                rateToAdr,
                                Constants.RATE_DIGITS,
                                true,
                                this.locale
                            ) + ' ahead';
                        } else {
                            this.rateToAdrLYDiff = 'inline';
                        }
                    } else {
                        this.rateToAdrLYDiff = Constants.MISSING_STR;
                    }
                } else {
                    this.rateToAdrLYDiff = Constants.MISSING_STR;
                }

                if (
                    typeof occProphecy === 'object'
                    && 'leadersClosersProphecy' in occProphecy
                    && $.isNumeric(occProphecy.leadersClosersProphecy)
                ) {
                    this.otbForecast = (window.infinito.vao.controller.utils.interpretOccupancyForecastOutput(
                        occProphecy.leadersClosersProphecy
                    ) || Constants.MISSING_STR);
                } else {
                    this.otbForecast = Constants.MISSING_STR;
                }
            }
        )
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    forecastStoryTextType() {
        if (this.otbForecast === window.infinito.vao.controller.utils.forecastInterpretations.noFill) {
            return storyTextTypes.TYPE_NEGATIVE;
        } else if (this.otbForecast === window.infinito.vao.controller.utils.forecastInterpretations.abilityToFill) {
            return storyTextTypes.TYPE_NEUTRAL;
        } else if (this.otbForecast === window.infinito.vao.controller.utils.forecastInterpretations.excessDemand) {
            return storyTextTypes.TYPE_POSITIVE;
        } else {
            return null;
        }
    }

    renderWhatToExpectPickup() {
        if (this.cache.has('pickupEl')) {
            return this.cache.get('pickupEl');
        }
        let pickupEl = new StoryWhatToExpectPickup({
            recordDate: this.recordDate,
            stayDate: this.stayDate,
            hotel: this.hotel
        });
        this.cache.set('pickupEl', pickupEl);
        return pickupEl;
    }

    render() {
        return html`
<div class="vao__components--storyInternalKpi">
    <div id="${this.kpiWrapId}" class="vao__components--storyInternalKpi-kpiWrap"></div>
    ${storyHtml`
    <div class="vao__components--storyInternalKpi-blurb">
        <p>
            Forecast ${new StoryText(this.otbForecast, true, this.forecastStoryTextType())}.
        </p>
        ${this.renderWhatToExpectPickup()}
        <p>
            We have ${new StoryText(this.roomsToSell, true, storyTextTypes.TYPE_NEUTRAL)} rooms to sell 
            and require ${new StoryText(this.roomsToHitLY, true, storyTextTypes.TYPE_NEUTRAL)} rooms to 
            hit LY, as well as ${new StoryText(this.roomsToHitB, true, storyTextTypes.TYPE_NEUTRAL)} 
            to hit budget.
        </p>
        <p>
            Revenue wise, we need another 
            ${new StoryText(this.revToHitLY, true, storyTextTypes.TYPE_NEUTRAL)} for LY 
            actual, and ${new StoryText(this.revToHitB, true, storyTextTypes.TYPE_NEUTRAL)} 
            to achieve budget.
        </p>
        <p>
            In order to achieve LY and budget, we must sell all rooms at
            an ADR of ${new StoryText(this.adrToHitLY, true, storyTextTypes.TYPE_NEUTRAL)}  
            for LY and ${new StoryText(this.adrToHitB, true, storyTextTypes.TYPE_NEUTRAL)} to hit budget.
        </p>
        <p>
            We are current selling at ${new StoryText(this.rate, true, storyTextTypes.TYPE_NEUTRAL)},
            which is 
            ${new StoryText(this.rateToAdrLYDiff, true, storyTextTypes.TYPE_MONETARY, this.locale)} 
            of required LY ADR.
        </p>
    </div>
    `}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalKpi = StoryInternalKpi;
customElements.define('vao-story-internal-kpi', StoryInternalKpi);
