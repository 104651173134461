import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';

export default class HourGlass extends AppElement {
    static get properties() {
        return {
            hotel_id: {type: Object},
            templatedata : []
        };
    }

    constructor(props = {}) {
        super();
        this.hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();
        this.templatedata = [];

    }        

    render(){
        return html`
            <style>
                .modal{
                    padding-left: 0px !important;
                }
                .modal-lg{
                    max-width: 500px !important;
                }                
            </style>
            <div style="width: auto;" id="popuphourglass">                
                <div class="row">
                    <div class="col-sm-6">
                        <h5 class="hourdiv" @click="${this.loadRulepage}">
                            <div class="my-2"><i class="fas fa-dna"></i></div>
                            IVI Rule
                        </h5>
                    </div>
                    <div class="col-sm-6">
                        <h5 class="hourdiv" @click="${this.loadAdhocpage}">
                            <div class="my-2"><i class="fas fa-dna"></i></div>
                            Adhoc Rule  
                        </h5>
                    </div>
                </div>                                 
            </div>`;
    }

    loadRulepage(){
        let pageName = 'rules';
        if (pageName) {
            allotz.spa.framework.pageTransition(pageName);
        }       
    }

    loadAdhocpage(){
        let pageName = 'adhocrules';
        if (pageName) {
            allotz.spa.framework.pageTransition(pageName);
        }       
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.HourGlass = HourGlass;
customElements.define('vao-hour-glass', HourGlass);