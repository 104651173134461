import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class Yesterday extends AppElement {

    constructor(props = {}) {
        super();
        this.cache = {};
        this.id = AppElement.getUniqueElementId();

        this.recordDate = props.recordDate;
        this.yesterday = window.infinito.vao.controller.dateHelper.subtractOneDayFromCurrentDateString(this.recordDate);
    }

    formatCurrency(raw, digits,is_adr = 0) {
        if (!$.isNumeric(raw)) {
            return Constants.MISSING_STR;
        }
        return window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            raw,
            digits,
            true,
            (this.hotel || this.hotelGroup || {}).locale,
            is_adr===1 ? 1 : is_adr===2 ? 2 : 0
        );
    }


    formatNumeric(raw) {
        if (!$.isNumeric(raw)) {
            return Constants.MISSING_STR;
        }
        return String(raw);
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        this.yesterday = window.infinito.vao.controller.dateHelper.subtractOneDayFromCurrentDateString(this.recordDate);
    }

    renderYesterday() {
        return html``;
    }

    render() {
        return html`
        <div class="vao__components--yesterday">
            ${this.renderYesterday()}   
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Yesterday = Yesterday;
customElements.define('vao-yesterday', Yesterday);
