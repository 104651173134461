import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryInternalPickup extends AppElement {
    static get properties() {
        return {
            pickupDesc: { type: String },
            pickupAB: { type: String },
            otbPickup: { type: String },
            pickupsAdr: { type: String },
            revenuePickup: { type: String },
            adrPickup: { type: String },
            roomsPickupTrend: { type: String },
            pickupsAdrTrend: { type: String },
            revenuePickupTrend: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = props.pickupSetting;
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.pickupEvolutionId = AppElement.getUniqueElementId();

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        if ('pickupSetting' in props) {
            this.pickupSetting = props.pickupSetting;
        }
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.pickupDesc = Constants.MISSING_STR;
        this.pickupAB = Constants.MISSING_STR;
        this.otbPickup = Constants.MISSING_STR;
        this.pickupsAdr = Constants.MISSING_STR;
        this.revenuePickup = Constants.MISSING_STR;
        this.adrPickup = Constants.MISSING_STR;
        this.roomsPickupTrend = Constants.MISSING_STR;
        this.pickupsAdrTrend = Constants.MISSING_STR;
        this.revenuePickupTrend = Constants.MISSING_STR;
    }

    fill() {
        if (
            !$.isNumeric(this.pickupSetting)
            || typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        document.getElementById(this.pickupEvolutionId).reflow({
            recordDate: this.recordDate,
            stayDate: this.stayDate,
            hotel: this.hotel,
            locale: this.locale,
            pickupSetting: this.pickupSetting,
            chartHeight: '200px'
        });

        let modelBiStats = window.infinito.vao.model.biStatistics;

        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                pickupOffset: this.pickupSetting,
                fields: [
                    modelBiStats.fields.roomsOtbPickup,
                    modelBiStats.fields.pickupsAdr,
                    modelBiStats.fields.revenuePickup,
                    modelBiStats.fields.adrPickup,
                    modelBiStats.fields.revenuePickupTrend,
                    modelBiStats.fields.roomsOtbPickupTrend,
                    modelBiStats.fields.pickupsAdrTrend
                ]
            }),
            (data, procData) => {
                let roomsOtbPUVal = ((procData.roomsOtbPickup || {})[this.stayDate] || {}).value || null;
                let revPUVal = ((procData.revenuePickup || {})[this.stayDate] || {}).value || null;
                let PUAdrVal = ((procData.pickupsAdr || {})[this.stayDate] || {}).value || null;
                let adrPUVal = ((procData.adrPickup || {})[this.stayDate] || {}).value || null;
                let revPUTrend = ((procData.revenuePickupTrend || {})[this.stayDate] || {}).value || null;
                let otbPUTrend = ((procData.roomsOtbPickupTrend || {})[this.stayDate] || {}).value || null;
                let puADRTrend = ((procData.pickupsAdrTrend || {})[this.stayDate] || {}).value || null;

                this.pickupDesc = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
                    this.pickupSetting
                );

                if ($.isNumeric(roomsOtbPUVal)) {
                    this.otbPickup = roomsOtbPUVal;
                } else {
                    this.otbPickup = Constants.MISSING_STR;
                }

                if ($.isNumeric(revPUVal)) {
                    this.revenuePickup = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revPUVal,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.revenuePickup = Constants.MISSING_STR;
                }

                if ($.isNumeric(PUAdrVal)) {
                    this.pickupsAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        PUAdrVal,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.pickupsAdr = Constants.MISSING_STR;
                }

                if ($.isNumeric(adrPUVal)) {
                    this.adrPickup = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        adrPUVal,
                        Constants.MISSING_STR,
                        true,
                        this.locale
                    );
                } else {
                    this.adrPickup = Constants.MISSING_STR;
                }

                if (
                    $.isNumeric(revPUVal)
                    && typeof revPUTrend === 'object'
                    && 'median' in revPUTrend
                    && $.isNumeric(revPUTrend.median)
                ) {
                    let assessment = window.infinito.vao.controller.pickupHelper.determinePickupAssessment(
                        revPUVal,
                        revPUTrend.median
                    );
                    if (assessment === 'above') {
                        this.pickupAB = 'above';
                    } else if (assessment === 'below') {
                        this.pickupAB = 'below';
                    } else if (assessment === 'inline') {
                        this.pickupAB = 'inline with';
                    } else {
                        this.pickupAB = Constants.MISSING_STR;
                    }
                } else {
                    this.pickupAB = Constants.MISSING_STR;
                }

                if (
                    typeof revPUTrend === 'object'
                    && 'median' in revPUTrend
                    && $.isNumeric(revPUTrend.median)
                ) {
                    this.revenuePickupTrend = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        revPUTrend.median,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.revenuePickupTrend = Constants.MISSING_STR;
                }

                if (
                    typeof otbPUTrend === 'object'
                    && 'median' in otbPUTrend
                    && $.isNumeric(otbPUTrend.median)
                ) {
                    this.roomsPickupTrend = otbPUTrend.median;
                } else {
                    this.roomsPickupTrend = Constants.MISSING_STR;
                }

                if (
                    typeof puADRTrend === 'object'
                    && 'median' in puADRTrend
                    && $.isNumeric(puADRTrend.median)
                ) {
                    this.pickupsAdrTrend = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        puADRTrend.median,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.pickupsAdrTrend = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyInternalPickup">
    ${storyHtml`
    <p>
        Our pickup since ${new StoryText(this.pickupDesc, true, storyTextTypes.TYPE_NEUTRAL)}  
        has been ${new StoryText(this.pickupAB, true, storyTextTypes.TYPE_TEXTUAL)} revenue trend. 
        We picked up 
        ${new StoryText(this.otbPickup, true, storyTextTypes.TYPE_NUMERIC)} rooms at an ADR of 
        ${new StoryText(this.pickupsAdr, true, storyTextTypes.TYPE_NEUTRAL)} 
        resulting in revenue of 
        ${new StoryText(this.revenuePickup, true, storyTextTypes.TYPE_MONETARY, this.locale)}. 
        This pickup changed our 
        ADR by ${new StoryText(this.adrPickup, true, storyTextTypes.TYPE_MONETARY, this.locale)} for 
        the stay date.
    </p>
    <p>
        Trend rooms pickup: ${new StoryText(this.roomsPickupTrend, true, storyTextTypes.TYPE_NUMERIC)}
    </p>
    <p>
        Trend pickups ADR: ${new StoryText(this.pickupsAdrTrend, true, 
            storyTextTypes.TYPE_MONETARY, this.locale)}
    </p>
    <p>
        Trend revenue pickup: ${new StoryText(this.revenuePickupTrend, true, 
            storyTextTypes.TYPE_MONETARY, this.locale)}
    </p>
    `}
    <div style="
    border: 1px solid lightgray;
    border-radius: .3rem;
    padding: 0 1rem;">
        <vao-rooms-otb-pickup-evolution-graph id="${this.pickupEvolutionId}"></vao-rooms-otb-pickup-evolution-graph>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryInternalPickup = StoryInternalPickup;
customElements.define('vao-story-internal-pickup', StoryInternalPickup);
