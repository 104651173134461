import { AppElement, html } from '../AppElement.js';
import TableColumn from '../table/TableColumn';
import TableCell, { tableCellTypes } from '../table/TableCell';
import Table from '../table/Table';
import TableNoDataRow from '../table/TableNoDataRow';
import TableRow from '../table/TableRow';
import NumericVarianceBlock from '../numericVarianceBlock/NumericVarianceBlock';
import Constants from '../Constants';
import Popover, { popPlacements, popTriggers } from '../popover/Popover';
import TwelveMonthsOptionBlock from '../twelveMonthsOptionBlock/TwelveMonthsOptionBlock';
import { getIdentityDependantTableStateKey } from '../TableUtils';
import { sendRequest } from '../App.common';

const cellFieldMap = {
    propertyName: 'propertyName',
    occupancyGroup: 'occupancyGroup',
    occupancyGroupInventory: 'occupancyGroupInventory',
    budgetInventory: 'budgetInventory',
    occupancyGroupMonth: 'occupancyGroupMonth',
    occupancyGroupBudget: 'occupancyGroupBudget',
    occupancyGroupVariance: 'occupancyGroupVariance',
    pickupGroup: 'pickupGroup',
    pickupGroupWeek: 'pickupGroupWeek',
    pickupGroupMonth: 'pickupGroupMonth',
    budgetGroup: 'budgetGroup',
    budgetRequired: 'budgetRequired',
    budgetAvailable: 'budgetAvailable',
    adrGroup: 'adrGroup',
    adrGroupWeek: 'adrGroupWeek',
    adrGroupMonth: 'adrGroupMonth',
    adrGroupBudget: 'adrGroupBudget',
    adrGroupVar: 'adrGroupVar'
};

class GroupPerPropertyMonthTable extends AppElement {
    static get properties() {
        return {
            hotelGroup: { type: Object },
            recordDate: { type: String },
            month: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.compClass = 'vao__components--groupPerPropertyMonthTable';
        this.id = AppElement.getUniqueElementId();
        this.assignProps(props);

        this.tableStateKey = getIdentityDependantTableStateKey('GroupPerPropertyMonthTable');
        this.cache = {};
        this.locale = (this.hotelGroup || {}).locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.dynamicOtbCell = null;
    }

    assignProps(props) {
        this.hotelGroup = props.hotelGroup || window.infinito.vao.controller.storageHelper.getSelectedGroup();
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.month = props.month || window.infinito.vao.controller.dateHelper.getYearMonthDateStringFrom(
            this.recordDate
        );
    }

    reflow(props = {}) {
        this.assignProps(props);
        this.cache = {};
        this.locale = (this.hotelGroup || {}).locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    domTheTable(table) {
        let tableWrapperClass = this.makeTableWrapperClass();
        $('#' + this.id)
            .find('.' + tableWrapperClass)
            .empty()
            .append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    reflowTable(rows = []) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            innerRows = [
                new TableNoDataRow()
            ];
        } else {
            innerRows = rows;
        }

        let table = this.getTable();
        if (table instanceof Table) {
            table.reflow({
                columns: table.columns,
                rows: innerRows,
                tableOpts: {
                    tableExportOpts: {
                        formats: ['csv'],
                        exportButtons: false,
                        additionalEmptyCSS: ['td.table-cell-otbSpark']
                    }
                }
            });
        }

        return table;
    }

    getTable() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // already exists
        } else {
            this.cache.table = new Table({
                stateKey: this.tableStateKey,
                columns: this.makeTableColumns(),
                rows: [
                    new TableNoDataRow()
                ]
            });
        }

        return this.cache.table;
    }

    makeOtbMonthColumnValue() {
        if (this.month) {
            return infinito.vao.controller.dateHelper.getPrettyMonthDateStringFrom(this.month);
        }
        return 'OTB for month';
    }

    updateOtbMonthCell() {
        if (this.dynamicOtbCell instanceof TableCell) {
            this.dynamicOtbCell.reflow({
                field: cellFieldMap.occupancyGroupMonth,
                value: this.makeOtbMonthColumnValue(),
                type: tableCellTypes.TH
            });
        }
    }

    makeTableColumns() {
        if ('cols' in this.cache) {
            return this.cache.cols;
        }
        this.dynamicOtbCell = new TableCell({
            field: cellFieldMap.occupancyGroupMonth,
            value: this.makeOtbMonthColumnValue(),
            type: tableCellTypes.TH
        });
        let cols = [
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.propertyName,
                    value: 'Property Name',
                    rowSpan: 2,
                    colSpan: 1,
                    type: tableCellTypes.TH
                })
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.occupancyGroup,
                    value: 'Occupancy',
                    rowSpan: 1,
                    colSpan: 13,
                    type: tableCellTypes.TH
                }),
                [
                    new TableColumn(
                        new TableCell({
                            field: cellFieldMap.occupancyGroupInventory,
                            value: 'Inventory',
                            type: tableCellTypes.TH
                        }),
                        [],
                        undefined,
                        'The average total rooms available to sell for the month.'
                    ),
                    new TableColumn(
                        new TableCell({
                            field: cellFieldMap.budgetInventory,
                            value: 'Budget Inventory',
                            type: tableCellTypes.TH
                        }),
                        [],
                        undefined,
                        'The average budget total rooms available to sell for the month.'
                    ),
                    new TableColumn(
                        this.dynamicOtbCell,
                        [],
                        undefined,
                        'The sum of rooms sold for the month divided by the total rooms available to sell.'
                    ),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.occupancyGroupBudget,
                        value: 'Budget',
                        type: tableCellTypes.TH
                    })),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.occupancyGroupVariance,
                        value: 'Variance to Budget',
                        type: tableCellTypes.TH
                    }))
                ]
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.pickupGroup,
                    value: 'Pickup',
                    rowSpan: 1,
                    colSpan: 2,
                    type: tableCellTypes.TH
                }),
                [
                    new TableColumn(new TableCell({
                        field: cellFieldMap.pickupGroupWeek,
                        value: 'Week Pickup (%)',
                        type: tableCellTypes.TH
                    })),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.pickupGroupMonth,
                        value: 'Month Pickup (%)',
                        type: tableCellTypes.TH
                    }))
                ]
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.budgetGroup,
                    value: 'Budget',
                    rowSpan: 1,
                    colSpan: 2,
                    type: tableCellTypes.TH
                }),
                [
                    new TableColumn(new TableCell({
                        field: cellFieldMap.budgetRequired,
                        value: 'Required Nights',
                        type: tableCellTypes.TH
                    })),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.budgetAvailable,
                        value: 'Rooms Available',
                        type: tableCellTypes.TH
                    }))
                ]
            ),
            new TableColumn(
                new TableCell({
                    field: cellFieldMap.adrGroup,
                    value: 'ADR',
                    rowSpan: 1,
                    colSpan: 4,
                    type: tableCellTypes.TH
                }),
                [
                    new TableColumn(new TableCell({
                        field: cellFieldMap.adrGroupWeek,
                        value: 'Past Week',
                        type: tableCellTypes.TH
                    })),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.adrGroupMonth,
                        value: 'Current Month',
                        type: tableCellTypes.TH
                    })),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.adrGroupBudget,
                        value: 'Budget',
                        type: tableCellTypes.TH
                    })),
                    new TableColumn(new TableCell({
                        field: cellFieldMap.adrGroupVar,
                        value: 'Var. to B',
                        type: tableCellTypes.TH
                    }))
                ]
            )
        ];
        this.cache.cols = cols;
        return cols;
    }

    getNewTableRow(data, rowClasses = []) {
        let propertyNameCell = new TableCell({
            field: cellFieldMap.propertyName,
            value: data.name
        });

        let otbInventoryCell = new TableCell({
            field: cellFieldMap.occupancyGroupInventory,
            value: null,
            isLoading: true
        });
        let budgetInventoryCell = new TableCell({
            field: cellFieldMap.budgetInventory,
            value: null,
            isLoading: true
        });
        let otbMonthCell = new TableCell({
            field: cellFieldMap.occupancyGroupMonth,
            value: null,
            isLoading: true
        });
        let otbBudgetMonthCell = new TableCell({
            field: cellFieldMap.occupancyGroupBudget,
            value: null,
            isLoading: true
        });
        let otbBudgetVarMonthCell = new TableCell({
            field: cellFieldMap.occupancyGroupVariance,
            value: null,
            isLoading: true
        });

        let pickupWeekCell = new TableCell({
            field: cellFieldMap.pickupGroupWeek,
            value: null,
            isLoading: true
        });
        let pickupMonthCell = new TableCell({
            field: cellFieldMap.pickupGroupMonth,
            value: null,
            isLoading: true
        });

        let budgetRequiredCell = new TableCell({
            field: cellFieldMap.budgetRequired,
            value: null,
            isLoading: true
        });
        let budgetAvailCell = new TableCell({
            field: cellFieldMap.budgetAvailable,
            value: null,
            isLoading: true
        });

        let adrWeekCell = new TableCell({
            field: cellFieldMap.adrGroupWeek,
            value: null,
            isLoading: true
        });
        let adrMonthCell = new TableCell({
            field: cellFieldMap.adrGroupMonth,
            value: null,
            isLoading: true
        });
        let adrBudgetCell = new TableCell({
            field: cellFieldMap.adrGroupBudget,
            value: null,
            isLoading: true
        });
        let adrVarianceCell = new TableCell({
            field: cellFieldMap.adrGroupVar,
            value: null,
            isLoading: true
        });

        // Order does not matter here
        return new TableRow([
            propertyNameCell,
            otbInventoryCell,
            budgetInventoryCell,
            otbMonthCell,
            otbBudgetMonthCell,
            otbBudgetVarMonthCell,
            pickupWeekCell,
            pickupMonthCell,
            budgetRequiredCell,
            budgetAvailCell,
            adrWeekCell,
            adrMonthCell,
            adrBudgetCell,
            adrVarianceCell
        ], data, rowClasses);
    }

    getTotalRow() {
        this.cache.rowMap = this.cache.rowMap || new Map();
        if (this.cache.rowMap.has('total')) {
            return this.cache.rowMap.get('total');
        }
        let row = this.getNewTableRow({
            name: 'Total'
        }, [
            this.compClass + '-totalRow'
        ]);
        this.cache.rowMap.set('total', row);
        return row;
    }

    getTableRow(hotel) {
        let id = hotel.id;
        this.cache.rowMap = this.cache.rowMap || new Map();
        if (this.cache.rowMap.has(id)) {
            return this.cache.rowMap.get(id);
        }
        let row = this.getNewTableRow(hotel);
        this.cache.rowMap.set(id, row);
        return row;
    }

    objectify(input) {
        if (!input || typeof input !== 'object') {
            return {};
        }
        return input;
    }

    percentify(sum, count) {
        if (!$.isNumeric(count) || !$.isNumeric(sum) || count === 0) {
            return;
        }
        let round = Math.round((sum / count) * 100);
        return round + '%';
    }

    adrify(sum, count) {
        if (!$.isNumeric(count) || !$.isNumeric(sum) || count === 0) {
            return;
        }
        let avg = sum / count;
        avg = avg.toFixed(Constants.ADR_DIGITS);
        return window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
            avg,
            Constants.ADR_DIGITS,
            true,
            this.locale
        );
    }

    fillRow(row) {
        let dataObj = {
            hotel_id: row.data.id,
            recordDate: this.recordDate,
            month: this.month,
            offset: window.infinito.vao.controller.storageHelper.getPickupOffset(),
            op: 'getHotelsDataForMonthlyTile',
            serviceName: 'groupmonthly.php'
        };
        return new Promise((resolve) => {
            sendRequest(dataObj, (res) => {
                let jsonObj = JSON.parse(res);
                jsonObj = jsonObj.data;
                let cell;

                cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupInventory);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.Inventory)
                ) {
                    cell.value = Math.round(parseFloat(jsonObj.Inventory));
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.budgetInventory);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.BIBudgetInventory)
                ) {
                    cell.value = Math.round(parseFloat(jsonObj.BIBudgetInventory));
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupMonth);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.monthOccupancy)
                ) {
                    cell.value = Math.round(parseFloat(jsonObj.monthOccupancy)) + '%';
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupBudget);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.budgetOccupancy)
                ) {
                    cell.value = Math.round(parseFloat(jsonObj.budgetOccupancy)) + '%';
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupVariance);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.variancetobudget)
                ) {
                    let variance = jsonObj.variancetobudget;
                    let formattedVariance = variance + '%';
                    cell.value = new NumericVarianceBlock({
                        numericValue: variance,
                        formattedValue: formattedVariance
                    });
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.pickupGroupWeek);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.pickupweek)
                ) {
                    cell.value = new NumericVarianceBlock({
                        numericValue: jsonObj.pickupweek,
                        formattedValue: jsonObj.pickupweek + '%'
                    });
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }
                cell = row.tableCellPerField.get(cellFieldMap.pickupGroupMonth);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.monthpickupValue)
                ) {
                    cell.value = new NumericVarianceBlock({
                        numericValue: jsonObj.monthpickupValue,
                        formattedValue: jsonObj.monthpickupValue + '%'
                    });
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.budgetRequired);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.budgetRequiredNights)
                ) {
                    let variance = jsonObj.budgetRequiredNights;
                    let absVariance = Math.abs(jsonObj.budgetRequiredNights) + '';
                    cell.value = new NumericVarianceBlock({
                        numericValue: variance,
                        formattedValue: absVariance
                    });
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.budgetAvailable);
                let budgetRA = jsonObj.totalbudgetRoomsAvailable;
                if (
                    cell instanceof TableCell
                && $.isNumeric(budgetRA)
                ) {
                    cell.value = budgetRA;
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.adrGroupWeek);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.pastweekAdr)
                ) {
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        jsonObj.pastweekAdr,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.adrGroupMonth);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.currentmonthadr)
                ) {
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        jsonObj.currentmonthadr,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.adrGroupBudget);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.budgetadr)
                ) {
                    cell.value = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        jsonObj.budgetadr,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                cell = row.tableCellPerField.get(cellFieldMap.adrGroupVar);
                if (
                    cell instanceof TableCell
                && $.isNumeric(jsonObj.variancetobudgetadr)
                ) {
                    let variance = jsonObj.variancetobudgetadr;
                    const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        variance,
                        Constants.ADR_DIGITS,
                        true,
                        this.locale
                    );
                    cell.value = new NumericVarianceBlock({
                        numericValue: variance,
                        formattedValue: formatted
                    });
                    cell.isLoading = false;
                } else {
                    (cell || {}).value = null;
                    (cell || {}).isLoading = false;
                }

                resolve();
            });
        });
    }

    canCalcTotal() {
        return this.cache
            && typeof this.cache === 'object'
            && 'rowMap' in this.cache
            && this.cache.rowMap instanceof Map
            && this.cache.rowMap.size > 1;
    }

    extractValuesForTotalRowForField(field, opts = {}) {
        let vals = [];
        if (typeof field !== 'string') {
            throw new Error('Invalid field input');
        }
        if (!opts || typeof opts !== 'object') {
            throw new Error('Invalid opts input');
        }
        this.cache.rowMap.forEach((row, key) => {
            if (
                !(row instanceof TableRow)
                || key === 'total'
                || !(row.tableCellPerField instanceof Map)
            ) {
                return;
            }
            let cell = row.tableCellPerField.get(field);
            if (!(cell instanceof TableCell)) {
                return;
            }
            if (cell.value instanceof HTMLElement) {
                if (cell.value instanceof NumericVarianceBlock) {
                    if (opts.numericVarBlockNumericVal === true) {
                        vals.push(cell.value.numericValue);
                    } else {
                        vals.push(cell.value.formattedValue);
                    }
                }
            } else {
                vals.push(cell.value);
            }
        });
        return vals;
    }

    calcTotalRowOccColumn(field) {
        if (!this.canCalcTotal()) {
            return undefined;
        }
        let extracted = this.extractValuesForTotalRowForField(field);
        if (extracted.length === 0) {
            return null;
        }
        let sum = 0;
        let count = 0;
        extracted.forEach(val => {
            if (typeof val === 'string' && val.includes('%')) {
                let parsed = (parseInt(val, 10) / 100);
                if (Number.isFinite(parsed)) {
                    sum += parsed;
                    count++;
                }
            }
        });
        let val = this.percentify(sum, count);
        if (val === undefined) {
            return null;
        }
        return val;
    }

    calcTotalRowSumColumn(field) {
        if (!this.canCalcTotal()) {
            return undefined;
        }
        let extracted = this.extractValuesForTotalRowForField(field);
        if (extracted.length === 0) {
            return null;
        }
        let sum = 0;
        let count = 0;
        extracted.forEach(val => {
            if ($.isNumeric(val)) {
                let parsed = parseFloat(val);
                if (Number.isFinite(parsed)) {
                    sum += parsed;
                    count++;
                }
            }
        });
        if (count === 0) {
            return null;
        }
        return sum;
    }

    calcTotalRowMonetaryAdrColumn(field) {
        if (!this.canCalcTotal()) {
            return undefined;
        }
        let extracted = this.extractValuesForTotalRowForField(field);
        if (extracted.length === 0) {
            return null;
        }
        let sum = 0;
        let count = 0;
        extracted.forEach(val => {
            let unformmated = window.infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
                val,
                this.locale
            );
            if (typeof unformmated === 'number' && Number.isFinite(unformmated)) {
                sum += unformmated;
                count++;
            }
        });
        let val = this.adrify(sum, count);
        if (val === undefined) {
            return null;
        }
        return val;
    }

    calcTotalRowInventory() {
        return this.calcTotalRowSumColumn(cellFieldMap.occupancyGroupInventory);
    }

    calcTotalRowBudgetInventory() {
        return this.calcTotalRowSumColumn(cellFieldMap.budgetInventory);
    }

    calcTotalRowOtbMonth() {
        return this.calcTotalRowOccColumn(cellFieldMap.occupancyGroupMonth);
    }

    calcTotalRowOtbBudget() {
        return this.calcTotalRowOccColumn(cellFieldMap.occupancyGroupBudget);
    }

    calcTotalRowOtbBudgetVar() {
        return this.calcTotalRowOccColumn(cellFieldMap.occupancyGroupVariance);
    }

    calcTotalRowWeekPickup() {
        return this.calcTotalRowOccColumn(cellFieldMap.pickupGroupWeek);
    }

    calcTotalRowMonthPickup() {
        return this.calcTotalRowOccColumn(cellFieldMap.pickupGroupMonth);
    }

    calcTotalRowBudgetRequired() {
        return this.calcTotalRowSumColumn(cellFieldMap.budgetRequired);
    }

    calcTotalRowBudgetAvailable() {
        return this.calcTotalRowSumColumn(cellFieldMap.budgetAvailable);
    }

    calcTotalRowAdrPastWeek() {
        return this.calcTotalRowMonetaryAdrColumn(cellFieldMap.adrGroupWeek);
    }

    calcTotalRowAdrPastMonth() {
        return this.calcTotalRowMonetaryAdrColumn(cellFieldMap.adrGroupMonth);
    }

    calcTotalRowAdrBudget() {
        return this.calcTotalRowMonetaryAdrColumn(cellFieldMap.adrGroupBudget);
    }

    calcTotalRowAdrBudgetVar() {
        return this.calcTotalRowMonetaryAdrColumn(cellFieldMap.adrGroupVar);
    }

    fillTotalRow(row) {
        let fillCell = (cell, val) => {
            if (cell instanceof TableCell && val !== undefined) {
                cell.value = val;
                cell.isLoading = false;
            }
        };

        let cell;
        let val;

        cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupInventory);
        val = this.calcTotalRowInventory();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.budgetInventory);
        val = this.calcTotalRowBudgetInventory();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupMonth);
        val = this.calcTotalRowOtbMonth();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupBudget);
        val = this.calcTotalRowOtbBudget();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.occupancyGroupVariance);
        val = this.calcTotalRowOtbBudgetVar();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.pickupGroupWeek);
        val = this.calcTotalRowWeekPickup();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.pickupGroupMonth);
        val = this.calcTotalRowMonthPickup();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.budgetRequired);
        val = this.calcTotalRowBudgetRequired();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.budgetAvailable);
        val = this.calcTotalRowBudgetAvailable();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.adrGroupWeek);
        val = this.calcTotalRowAdrPastWeek();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.adrGroupMonth);
        val = this.calcTotalRowAdrPastMonth();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.adrGroupBudget);
        val = this.calcTotalRowAdrBudget();
        fillCell(cell, val);

        cell = row.tableCellPerField.get(cellFieldMap.adrGroupVar);
        val = this.calcTotalRowAdrBudgetVar();
        fillCell(cell, val);
    }

    fill() {
        if (
            !this.hotelGroup
            || typeof this.hotelGroup !== 'object'
            || !('hotels' in this.hotelGroup)
            || !Array.isArray(this.hotelGroup.hotels)
            || this.hotelGroup.hotels.length === 0
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        ) {
            this.empty();
            return;
        }
        let rows = [];
        let rowFillPromises = [];
        this.hotelGroup.hotels.forEach(hotel => {
            let row = this.getTableRow(hotel);
            rows.push(row);
            rowFillPromises.push(this.fillRow(row));
        });

        let totalRow = this.getTotalRow();
        rows.push(totalRow);
        Promise.all(rowFillPromises)
            .then(() => {
                this.fillTotalRow(totalRow);
            });

        let table = this.reflowTable(rows);
        this.domTheTable(table);
    }

    renderPopover() {
        let $popWrapper = $('#' + this.id)
            .find('.' + this.compClass + '-yearMonSel');
        let $anchor = $popWrapper.find('vao-chip');
        if ($anchor) {
            let pop;
            let content = new TwelveMonthsOptionBlock({
                recordDate: this.recordDate,
                onChanged: (yearMOn) => {
                    if (yearMOn === this.month) {
                        return;
                    }
                    this.month = yearMOn;
                },
                current: this.month
            });
            $popWrapper.find('vao-popover')
                .remove();
            pop = new Popover({
                anchor: $anchor,
                content: content,
                trigger: popTriggers.focusCustom,
                placement: popPlacements.bottom
            });
            $popWrapper.append(pop);
        }
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.renderPopover();
    }

    updated(changedProperties) {
        if (changedProperties instanceof Map) {
            let monthChange = changedProperties.get('month');
            let recordDateChange = changedProperties.get('recordDate');
            if (monthChange) {
                this.updateOtbMonthCell();
            }
            if (recordDateChange) {
                this.renderPopover();
            }
        }
        this.reflow({ month: this.month });
    }

    makeTableWrapperClass() {
        return `${this.compClass}-tableWrapper`;
    }

    makeYearMonSelChipLabel() {
        let res = window.infinito.vao.controller.dateHelper.getPrettyYearMonthDateStringFrom(this.month);
        return res || Constants.MISSING_STR;
    }

    render() {
        return html`
            <div class="${this.compClass}">
                <div class="${this.compClass + '-yearMonSel'}">
                    <div style="display:inline-block;">
                        <vao-chip
                                .label="${this.month ? this.makeYearMonSelChipLabel() : Constants.MISSING_STR}"
                                endIconCls="${Constants.ICONS.ANGLE_DOWN}"
                                clickable="true">
                        </vao-chip>
                    </div>
                </div>
                <div class="${this.makeTableWrapperClass()}"></div>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.GroupPerPropertyMonthTable = GroupPerPropertyMonthTable;
customElements.define('vao-group-per-property-month-table', GroupPerPropertyMonthTable);
