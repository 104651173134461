import { AppElement, html } from '../AppElement.js';

export default class PageFramework extends AppElement {
    static get properties() {
        return {};
    }

    constructor(props = {}) {
        super();
    }

    reflow(props = {}) {}

    render() {
        return html`
            <div class="vao__components--pageFramework">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <vao-personal-demand></vao-personal-demand>
                    </div>
                    <div class="col-12 col-lg-4">
                        <vao-personal-pickup></vao-personal-pickup>
                    </div>
                    <div class="col-12 col-lg-4">
                        <vao-personal-housekeeping></vao-personal-housekeeping>
                        <vao-personal-average></vao-personal-average>
                    </div>
                </div>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageFramework = PageFramework;
customElements.define('vao-page-framework', PageFramework);
