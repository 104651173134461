import {AppElement, html} from '../AppElement.js';
import TableLoadingRow from "../table/TableLoadingRow";
import TableNoDataRow from "../table/TableNoDataRow";
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import Constants from "../Constants";
import TableRow from "../table/TableRow";
import {formatOccupancy} from "../Utils";
import Table from "../table/Table";
import {getIdentityDependantTableStateKey} from "../TableUtils";

// Additionally, there will be dynamic columns for the individual properties in the group.
export const cellFieldMap = {
    stayDate: 'stayDate',
    roomsToSell: 'roomsToSell',
    roomsOtb: 'roomsOtb',
    revenue: 'revenue',
    adr: 'adr',
    roomsOtbPickup: 'roomsOtbPickup',
    revenuePickup: 'revenuePickup',
    pickupsAdr: 'pickupsAdr',
    adrPickup: 'adrPickup',
};

export const PROPERTY_METRIC_MODE_ROOMS = 'propertyMetricModeRoomsOtb';
export const PROPERTY_METRIC_MODE_OCCUPANCY = 'propertyMetricModeOccupancy';

let requestCount = 0; // Use this to make sure that only the latest ajax request can change internal properties.

export default class PageDesktopGroupTable extends AppElement {
    static get properties() {
        return {
            group: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            startDate: { type: String },
            endDate: { type: String },
            propertyMetricMode: { type: String },

            // @internalProperty
            tableOpts: { attribute: false },
            columns: { attribute: false },
            rows: { attribute: false },
            isLoading: { attribute: false },
        };
    }

    constructor(props = {}) {
        super();
        this.group = props.group || this.group || window.infinito.vao.controller.storageHelper.getSelectedGroup();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;
        this.propertyMetricMode = props.propertyMetricMode || this.propertyMetricMode || PROPERTY_METRIC_MODE_ROOMS;

        // @internalProperty
        this.tableStateKey = getIdentityDependantTableStateKey('PageDesktopGroupTable');
        this.tableOpts = {
            ...Table.getDefaultTableOpts(),
            isStickyHeader: true,
            stickyHeaderMinHeight: '74vh'
        };
        this.columns = this.makeStaticColumns();
        this.rows = [];
        this.isLoading = false;

        // @internalCache
        this.tableCellCache = new Map();
        this.lastUsedFillData = null;
    }

    reflow(props = {}) {
        this.group = props.group || this.group;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;
        this.propertyMetricMode = props.propertyMetricMode || this.propertyMetricMode;
    }

    makeStaticColumns() {
        const columnKeyToStringMap = {
            [cellFieldMap.stayDate]: Constants.STRINGS.STAY_DATE,
            [cellFieldMap.roomsToSell]: 'Rms to sell',
            [cellFieldMap.roomsOtb]: 'Rms',
            [cellFieldMap.revenue]: 'Rev',
            [cellFieldMap.adr]: 'ADR',
            [cellFieldMap.roomsOtbPickup]: 'Rms PU',
            [cellFieldMap.revenuePickup]: 'Rev PU',
            [cellFieldMap.pickupsAdr]: 'PU ADR',
            [cellFieldMap.adrPickup]: 'ADR Move',
        };
        let columns = [];
        Object.keys(columnKeyToStringMap).forEach(key => {
            columns.push(new TableColumn(
                new TableCell({
                    field: key,
                    value: columnKeyToStringMap[key],
                    type: tableCellTypes.TH
                }),
                [],
                undefined,
                null,
                true,
                false
            ));
        });
        return columns;
    }

    addGroupProperty(dynamicColumnKey, dynamicColumnText) {
        const existing = this.columns.find(column => column.tableCell.field === dynamicColumnKey);
        if (existing) {
            return;
        }

        const newColumn = new TableColumn(
            new TableCell({
                field: dynamicColumnKey,
                value: dynamicColumnText,
                type: tableCellTypes.TH
            }),
            [],
            undefined,
            null,
            true,
            false
        );
        this.columns = [...this.columns, newColumn];
    }

    onClickStayDate(stayDate, e) {
        window.infinito.components.groupStayDateQuickViewModal.render({
            stayDate: stayDate
        });
    }

    acquireTableCell(stayDate, props) {
        const key = `__@${props.field}_${stayDate}__`;
        if (this.tableCellCache.has(key)) {
            let existingTableCell = this.tableCellCache.get(key);
            existingTableCell.reflow(props);
            return existingTableCell;
        }
        let newTableCell = new TableCell(props);
        this.tableCellCache.set(key, newTableCell);
        return newTableCell;
    }

    fillWithData(data) {
        if (!data) {
            return;
        }
        this.lastUsedFillData = data;
        const _procData = data;
        const dates = window.infinito.vao.controller.dateHelper.calcStayDateRangeArray(this.startDate, this.endDate);

        let rows = [];
        dates.forEach(stayDate => {
            let rowCells = [];

            const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
                stayDate
            );
            rowCells.push(this.acquireTableCell(stayDate, {
                field: cellFieldMap.stayDate,
                value: html`
                    <vao-button 
                        text="${prettyStayDate}" 
                        variant="invert" 
                        size="xs" 
                        @click=${this.onClickStayDate.bind(this, stayDate)}
                    ></vao-button>
                `
            }));

            if (
                _procData.roomsToSell
                && _procData.roomsToSell[stayDate]
                && 'value' in _procData.roomsToSell[stayDate]
                && $.isNumeric(_procData.roomsToSell[stayDate].value)
            ) {
                let value = parseInt(_procData.roomsToSell[stayDate].value, 10);
                let labelColor = null;
                if (value !== 0) {
                    // value *= -1; // Because Fabian wants the inverse rendered.
                    if (value < 0) {
                        // There actually is rooms still left to sell.
                        labelColor = Constants.COLORS.HEX.DANGER;
                    }
                }

                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.roomsToSell,
                    value: html`
                        <vao-tag
                            .label="${value}"
                            .labelColor="${labelColor}"
                            canInterpretBgColor="false"
                        ></vao-tag>   
                    `
                }));
            }

            if (
                _procData.roomsOtb
                && _procData.roomsOtb[stayDate]
                && 'value' in _procData.roomsOtb[stayDate]
                && $.isNumeric(_procData.roomsOtb[stayDate].value)
            ) {
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.roomsOtb,
                    value: _procData.roomsOtb[stayDate].value
                }));
            }

            if (
                _procData.revenue
                && _procData.revenue[stayDate]
                && 'value' in _procData.revenue[stayDate]
                && $.isNumeric(_procData.revenue[stayDate].value)
            ) {
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    _procData.revenue[stayDate].value,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.group.locale
                );
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.revenue,
                    value: formatted
                }));
            }

            if (
                _procData.adr
                && _procData.adr[stayDate]
                && 'value' in _procData.adr[stayDate]
                && $.isNumeric(_procData.adr[stayDate].value)
            ) {
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    _procData.adr[stayDate].value,
                    Constants.ADR_DIGITS,
                    true,
                    this.group.locale,
                    1
                );
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.adr,
                    value: formatted
                }));
            }

            if (
                _procData.roomsOtbPickup
                && _procData.roomsOtbPickup[stayDate]
                && 'value' in _procData.roomsOtbPickup[stayDate]
                && $.isNumeric(_procData.roomsOtbPickup[stayDate].value)
            ) {
                const value = _procData.roomsOtbPickup[stayDate].value;
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.roomsOtbPickup,
                    value: html`
                        <vao-numeric-variance-block
                            numericValue="${value}"
                            formattedValue="${value}"
                        ></vao-numeric-variance-block>
                    `
                }));
            }

            if (
                _procData.revenuePickup
                && _procData.revenuePickup[stayDate]
                && 'value' in _procData.revenuePickup[stayDate]
                && $.isNumeric(_procData.revenuePickup[stayDate].value)
            ) {
                const value = _procData.revenuePickup[stayDate].value;
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    value,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.group.locale
                );
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.revenuePickup,
                    value: html`
                        <vao-numeric-variance-block
                            numericValue="${value}"
                            formattedValue="${formatted}"
                        ></vao-numeric-variance-block>
                    `
                }));
            }

            if (
                _procData.pickupsAdr
                && _procData.pickupsAdr[stayDate]
                && 'value' in _procData.pickupsAdr[stayDate]
                && $.isNumeric(_procData.pickupsAdr[stayDate].value)
            ) {
                const value = _procData.pickupsAdr[stayDate].value;
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    value,
                    Constants.ADR_DIGITS,
                    true,
                    this.group.locale,
                    1
                );
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.pickupsAdr,
                    value: html`
                        <vao-numeric-variance-block
                            numericValue="${value}"
                            formattedValue="${formatted}"
                        ></vao-numeric-variance-block>
                    `
                }));
            }

            if (
                _procData.adrPickup
                && _procData.adrPickup[stayDate]
                && 'value' in _procData.adrPickup[stayDate]
                && $.isNumeric(_procData.adrPickup[stayDate].value)
            ) {
                const value = _procData.adrPickup[stayDate].value;
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    value,
                    Constants.ADR_DIGITS,
                    true,
                    this.group.locale,
                    1
                );
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.adrPickup,
                    value: html`
                        <vao-numeric-variance-block
                            numericValue="${value}"
                            formattedValue="${formatted}"
                        ></vao-numeric-variance-block>
                    `
                }));
            }

            if (
                _procData.perGroupProperty
                && 'objectValue' in _procData.perGroupProperty
                && _procData.perGroupProperty.objectValue
                && typeof _procData.perGroupProperty.objectValue === 'object'
                && Object.keys(_procData.perGroupProperty.objectValue).length > 0
            ) {
                Object.values(_procData.perGroupProperty.objectValue).forEach(propertyStatistics => {
                    const propertyId = propertyStatistics.id;
                    const safePropertyId = window.infinito.vao.controller.utils.safeCssName(propertyId);
                    const propertyName = propertyStatistics.name;
                    this.addGroupProperty(safePropertyId, propertyName);

                    let labelColor = null;
                    if (
                        propertyStatistics.roomsOtbPickup
                        && propertyStatistics.roomsOtbPickup[stayDate]
                        && $.isNumeric(propertyStatistics.roomsOtbPickup[stayDate])
                    ) {
                        const pickup = parseInt(propertyStatistics.roomsOtbPickup[stayDate], 10);
                        if (pickup > 0) {
                            labelColor = Constants.COLORS.HEX.SUCCESS;
                        } else if (pickup < 0) {
                            labelColor = Constants.COLORS.HEX.DANGER;
                        }
                    }

                    if (this.propertyMetricMode === PROPERTY_METRIC_MODE_ROOMS) {
                        if (
                            propertyStatistics.roomsOtb
                            && propertyStatistics.roomsOtb[stayDate]
                            && $.isNumeric(propertyStatistics.roomsOtb[stayDate])
                        ) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: safePropertyId,
                                value: html`
                                    <vao-tag
                                        .label="${propertyStatistics.roomsOtb[stayDate]}"
                                        .labelColor="${labelColor}"
                                    ></vao-tag>
                                `
                            }));
                        }
                    } else if (this.propertyMetricMode === PROPERTY_METRIC_MODE_OCCUPANCY) {
                        if (
                            propertyStatistics.occupancy
                            && propertyStatistics.occupancy[stayDate]
                            && $.isNumeric(propertyStatistics.occupancy[stayDate])
                        ) {
                            const occVal = formatOccupancy(propertyStatistics.occupancy[stayDate]);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: safePropertyId,
                                value: html`
                                    <vao-tag
                                        .label="${occVal}"
                                        .labelColor="${labelColor}"
                                    ></vao-tag>
                                `
                            }));
                        }
                    }
                });
            }

            rows.push(new TableRow(rowCells));
        });

        this.rows = rows;
        this.isLoading = false;
    }

    fill() {
        if (
            !this.group
            || !this.group.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.startDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.endDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            this.rows = [];
            this.lastUsedFillData = null;
            return;
        }

        this.isLoading = true;
        this.rows = [];
        requestCount++;

        const preRequestCount = requestCount;
        const biStatistics = window.infinito.vao.model.biHotelGroupsStatistics;
        biStatistics.fetchStatistics(
            this.group.id,
            biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.startDate,
                lastStayDate: this.endDate,
                fields: [
                    biStatistics.fields.roomsToSell,
                    biStatistics.fields.roomsOtb,
                    biStatistics.fields.revenue,
                    biStatistics.fields.adr,
                    biStatistics.fields.roomsOtbPickup,
                    biStatistics.fields.revenuePickup,
                    biStatistics.fields.pickupsAdr,
                    biStatistics.fields.adrPickup,
                    biStatistics.fields.perGroupProperty
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                if (requestCount !== preRequestCount) {
                    return; // Another ajax has taken over.
                }
                const _procData = (procData || {});
                if (!_procData || Object.keys(_procData).length === 0) {
                    this.isLoading = false;
                    this.lastUsedFillData = null;
                    return;
                }

                this.fillWithData(_procData);
            }
        );
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('group')
            || _changedProperties.has('recordDate')
            || _changedProperties.has('pickupSetting')
            || _changedProperties.has('startDate')
            || _changedProperties.has('endDate')
        ) {
            this.fill();
        }
        if (_changedProperties.has('propertyMetricMode')) {
            this.fillWithData(this.lastUsedFillData);
        }
    }

    onChangeTableOpts(e) {
        if (e.detail.newTableOpts) {
            this.tableOpts = e.detail.newTableOpts; // Share obj with table to not cause re-render.
        }
    }

    render() {
        return html`
<div class="vao__components--pageDesktopGroupTable">
    <vao-table
        stateKey="${this.tableStateKey}"
        .tableOpts="${this.tableOpts}"
        .columns="${this.columns}"
        .rows="${this.isLoading ? [new TableLoadingRow()] : this.rows.length > 0 ? this.rows : [new TableNoDataRow()]}"
        @vao-table-tableopts-change="${this.onChangeTableOpts}"
    ></vao-table>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktopGroupTable = PageDesktopGroupTable;
customElements.define('vao-page-desktop-group-table', PageDesktopGroupTable);
