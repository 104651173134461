import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import TopPickup from "./TopPickup";
import TopPickupCardExpand from "./TopPickupCardExpand";
import ColorArray from "../ColorArray";

export default class TopPickupCard extends AppElement {
    static get properties() {
        return {
            name: { type: String },
            topPickups: { type: Array },
            color: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.name = props.name || Constants.MISSING_STR;
        this.color = props.color;
        this.topPickups = props.topPickups || [];
    }

    reflow(props = {}) {
        this.name = props.name || this.name;
        this.color = props.color || this.color;
        this.topPickups = props.topPickups || this.topPickups;
    }

    getBorderStyle() {
        if (ColorArray.isHexColor(this.color)) {
            return `border: 1px solid ${this.color};`;
        }
        return `border: 1px solid lightgray;`;
    }

    render() {
        return html`
        <div class="vao__components--topPickupCard vao-div" style="${this.getBorderStyle()}">
            <div class="vao__components--topPickupCard-title">${this.name}</div>
            <div class="vao__components--topPickupCard-topPickups">
                ${this.topPickups.filter(topPickup => {
                    return topPickup instanceof TopPickup;    
                }).map(topPickup => {
                    return new TopPickupCardExpand({
                        topPickup
                    });
                })}
            </div>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.TopPickupCard = TopPickupCard;
customElements.define('vao-top-pickup-card', TopPickupCard);
