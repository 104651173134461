import { AppElement, html } from '../AppElement.js';

export const EVENT_LOCALE_SELECT_ON_CHANGE = 'vao-locale-select-on-change';

export default class LocaleSelect extends AppElement {
    static get properties() {
        return {
            selectedLocale: { type: String },
            locales: { type: Object },
        };
    }

    constructor(props = {}) {
        super();
        this.selectedLocale = props.selectedLocale
            || this.selectedLocale
            || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.locales = props.locales
            || this.locales
            || {...window.infinito.vao.controller.moneyHelper.availableLocales};
    }

    reflow(props = {}) {
        this.selectedLocale = props.selectedLocale || this.selectedLocale;
        this.locales = props.locales || this.locales;
    }

    _onChange(e) {
        this.selectedLocale = e.detail.newValue;
        const event = new CustomEvent(EVENT_LOCALE_SELECT_ON_CHANGE, {
            detail: {
                newValue: this.selectedLocale
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    /*
     * Turn a data structure like this:
     * {
     *   'vi': 'Vietnamese Vietnam',
     *   'en-sg': 'English Singapore',
     *   'en-us': 'English United States',
     *   'de': 'German Germany',
     *   'en-au': 'English Australia',
     * }
     *
     * Into this (sorted by label in the root and text in the options):
     * [
     *   {
     *     type: 'optgroup',
     *     label: 'English',
     *     options: [
     *       {
     *         text: 'English Australia',
     *         value: 'en-au'
     *       },
     *       {
     *         text: 'English Singapore',
     *         value: 'en-sg'
     *       },
     *       {
     *         text: 'English United States',
     *         value: 'en-us'
     *       }
     *     ]
     *   },
     *   {
     *     type: 'optgroup',
     *     label: 'German',
     *     options: [
     *       {
     *         text: 'German Germany',
     *         value: 'de'
     *       }
     *     ]
     *   },
     *   {
     *     type: 'optgroup',
     *     label: 'Vietnamese',
     *     options: [
     *       {
     *         text: 'Vietnamese Vietnam',
     *         value: 'vi'
     *       }
     *     ]
     *   }
     * ]
     */
    makeOptions() {
        let result = new Map();
        const locales = (this.locales && typeof this.locales === 'object') ? this.locales : {};
        for (const [localeKey, localeValue] of Object.entries(locales)) {
            const {lang} = this.separateLocaleOnLang(localeValue);
            if (!result.has(lang)) {
                result.set(lang, {
                    type: 'optgroup',
                    label: lang,
                    options: []
                });
            }
            const resultGroup = result.get(lang);
            resultGroup.options.push({
                value: localeKey,
                text: localeValue
            });
            resultGroup.options = resultGroup.options.sort((a, b) => (a.text.localeCompare(b.text)));
        }
        return [...result].map(([name, value]) => (value)).sort((a, b) => (a.label.localeCompare(b.label)));
    }

    separateLocaleOnLang(localeText) {
        const safeLocaleText = this.getEscapedText(localeText);
        let [lang, ...parts] = safeLocaleText.split(" ");
        parts = parts.join(" ");
        return {
            lang,
            parts
        };
    }

    optionFormatter(state) {
        if (state.children) {
            return state.text; // This is the optgroup label.
        }
        const safeLocale = this.getEscapedText(state.id);
        const {lang, parts} = this.separateLocaleOnLang(state.text);
        const safeLocaleText = this.getEscapedText(parts || lang);
        return $(
            '<div>'
            + safeLocaleText
            + '<span class="badge badge-light" style="margin-left:.5rem;">'
            + safeLocale
            + '</span></div>'
        );
    }

    getEscapedText(text) {
        return $('<div/>').text(text).html();
    }

    makeLangBade(text, cls) {
        return '<span class="badge ' + cls + '" style="margin-right:.5rem;">' + text + '</span>';
    }

    render() {
        const options = this.makeOptions();
        const optionFormatter = this.optionFormatter.bind(this);

        return html`
            <div class="vao__components--localeSelect">
                <vao-select2 
                    .value="${this.selectedLocale}" 
                    .options="${options}" 
                    @vao-select2-select="${this._onChange.bind(this)}"
                    .dropdownFormatter="${optionFormatter}"
                    .selectionFormatter="${optionFormatter}"
                    minimumResultsForSearch="1"
                ></vao-select2>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.LocaleSelect = LocaleSelect;
customElements.define('vao-locale-select', LocaleSelect);
