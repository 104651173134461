import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StoryForecastRangeSubtitle extends AppElement {
    static get properties() {
        return {
            forecast: { type: String },
            badgeClass: { type: String },

            recordDate: { type: String },
            stayDate: { type: String },
            hotel: { type: Object },
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.hotel = props.hotel || this.hotel;

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        this.stayDate = props.stayDate || this.stayDate;
        this.hotel = props.hotel || this.hotel;
        this.fill();
    }

    empty() {
        this.forecast = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !this.recordDate
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !this.stayDate
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.occupancyLeaderCloserTrendProphecy
                ]
            }),
            (data, procData) => {
                const _procData = procData || {};
                let occProphecy =
                    ((_procData.occupancyLeaderCloserTrendProphecy || {})[this.stayDate] || {}).value || null;

                if (
                    typeof occProphecy === 'object'
                    && 'leadersClosersProphecy' in occProphecy
                    && $.isNumeric(occProphecy.leadersClosersProphecy)
                ) {

                    this.badgeClass = 'font-size: 13.6px;';
                    var fForecast = parseFloat((occProphecy.leadersClosersProphecy || Constants.MISSING_STR));

                    if (fForecast < 0.29) {
                        this.forecast = 'Below 29%';
                        this.badgeClass = 'color:#030289;';
                    } else if (fForecast > 0.29 && fForecast < 0.39) {
                        this.badgeClass = 'color:#3e2f6b;';
                        this.forecast = '30-39%';
                    } else if (fForecast >= 0.39 && fForecast < 0.49) {
                        this.forecast = '40-49%';
                        this.badgeClass = 'color:#67527c;';
                    } else if (fForecast >= 0.49 && fForecast < 0.59) {
                        this.forecast = '50-59%';
                        this.badgeClass = 'color:#916e40;';
                    } else if (fForecast >= 0.59 && fForecast < 0.69) {
                        this.forecast = '60-69%';
                        this.badgeClass = 'color:#c89724;';
                    } else if (fForecast >= 0.69 && fForecast < 0.79) {
                        this.forecast = '70-79%';
                        this.badgeClass = 'color:#f3b80d;';
                    } else if (fForecast >= 0.79 && fForecast < 0.89) {
                        this.forecast = '80-89%';
                        this.badgeClass = 'color:#c4ba17;';
                    } else if (fForecast >= 0.89 && fForecast < 0.95) {
                        this.forecast = '90-94%';
                        this.badgeClass = 'color:#99b524;';
                    } else if (fForecast > 0.95 && fForecast <= 1) {
                        this.forecast = '95-100%';
                        this.badgeClass = 'color:#4aab3b;';
                    } else {
                        this.forecast = '101+%';
                        this.badgeClass = 'color:green;';
                    }

                } else {
                    this.forecast = Constants.MISSING_STR;
                    this.badgeClass = 'color:black;';
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyForecastSubtitle" style="${this.badgeClass}">
    ${this.forecast}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryForecastRangeSubtitle = StoryForecastRangeSubtitle;
customElements.define('vao-story-forecastrange-subtitle', StoryForecastRangeSubtitle);
