import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement.js';

export default class StoryMyPricing extends AppElement {
    static get properties() {
        return {
            myRate: { type: String },
            myRateConsider: { type: String },
            dow: { type: String },
            leadTime: { type: String },
            pickupDesc: { type: String },
            myRateChange: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.pickupSetting = props.pickupSetting;
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        if ('pickupSetting' in props) {
            this.pickupSetting = props.pickupSetting;
        }
        this.locale = props.locale || window.infinito.vao.controller.moneyHelper.getNumeralLocale();
        this.fill();
    }

    empty() {
        this.myRate = Constants.MISSING_STR;
        this.myRateConsider = Constants.MISSING_STR;
        this.dow = Constants.MISSING_STR;
        this.leadTime = Constants.MISSING_STR;
        this.pickupDesc = Constants.MISSING_STR;
        this.myRateChange = Constants.MISSING_STR;
        this.typicalLow = Constants.MISSING_STR;
        this.typicalHigh = Constants.MISSING_STR;
        this.typicalNow = Constants.MISSING_STR;
    }

    fill() {
        if (
            !$.isNumeric(this.pickupSetting)
            || typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.rate,
                    modelBiStats.fields.rateLeadSiblingPace,
                    modelBiStats.fields.ratePickup
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                let rate = ((procData.rate || {})[this.stayDate] || {}).value || null;
                let ratePace = ((procData.rateLeadSiblingPace || {})[this.stayDate] || {}).value || null;
                let ratePU = ((procData.ratePickup || {})[this.stayDate] || {}).value || null;

                if ($.isNumeric(rate)) {
                    this.myRate = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        rate,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.myRate = Constants.MISSING_STR;
                }

                if (
                    typeof ratePace === 'object'
                    && 'T0' in ratePace
                    && typeof ratePace.T0 === 'object'
                    && 'setDenseRank' in ratePace.T0
                    && 'setDenseLength' in ratePace.T0
                ) {
                    let pnn = window.infinito.vao.controller.analyseAttributesHelper
                        .getMyPricePNNForSetRank(
                            ratePace.T0.setDenseRank,
                            ratePace.T0.setDenseLength
                        );
                    if (pnn) {
                        let prettyPnn = window.infinito.vao.controller.analyseAttributesHelper
                            .getMyPriceUserFriendlyForPNN(pnn);
                        if (prettyPnn) {
                            this.myRateConsider = prettyPnn;
                        } else {
                            this.myRateConsider = pnn;
                        }
                    } else {
                        this.myRateConsider = Constants.MISSING_STR;
                    }

                    let t0 = parseFloat((ratePace.T0 || {}).value || 0);
                    let t1 = parseFloat((ratePace.T1 || {}).value || 0);
                    let t2 = parseFloat((ratePace.T2 || {}).value || 0);
                    let t3 = parseFloat((ratePace.T3 || {}).value || 0);
                    let t4 = parseFloat((ratePace.T4 || {}).value || 0);
                    let set = [t0, t1, t2, t3, t4];
                    set = set.filter((setItem) => {
                        if (!setItem || typeof setItem !== 'number' || Number.isNaN(setItem) || setItem <= 0) {
                            return false;
                        }
                        return true;
                    });
                    let max = Math.max.apply(null, set);
                    let min = Math.min.apply(null, set);
                    this.typicalLow = min;
                    this.typicalHigh = max;
                    this.typicalNow = t0;
                } else {
                    this.myRateConsider = Constants.MISSING_STR;
                    this.typicalLow = Constants.MISSING_STR;
                    this.typicalHigh = Constants.MISSING_STR;
                    this.typicalNow = Constants.MISSING_STR;
                }

                // Put these here to help batch lit async DOM update
                this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(this.stayDate);
                this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                    this.stayDate,
                    this.recordDate
                );
                this.pickupDesc = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
                    this.pickupSetting
                );

                if ($.isNumeric(ratePU)) {
                    this.myRateChange = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ratePU,
                        Constants.RATE_DIGITS,
                        true,
                        this.locale
                    );
                } else {
                    this.myRateChange = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    isNotSelling() {
        return window.infinito.vao.controller.moneyHelper.unformatMoneyBracketStyle(
            this.myRate,
            this.locale
        ) === 0;
    }

    render() {
        return html`
            <div class="vao__components--storyMyPricing">
                ${this.isNotSelling()
            ? html`We are not selling.`
            : html`
            ${storyHtml`
            <p>
                We are currently pricing at 
                ${new StoryText(this.myRate, true, storyTextTypes.TYPE_NEUTRAL)}. 
                Overall, this is considered 
                ${new StoryText(this.myRateConsider, true, storyTextTypes.TYPE_TEXTUAL)} for 
                a ${new StoryText(this.dow, true, storyTextTypes.TYPE_NEUTRAL)}, 
                ${new StoryText(this.leadTime, true, storyTextTypes.TYPE_NEUTRAL)} days out. 
                Since ${new StoryText(this.pickupDesc, true, storyTextTypes.TYPE_NEUTRAL)}, our rate changed 
                by ${new StoryText(this.myRateChange, true, storyTextTypes.TYPE_MONETARY, this.locale)}.
            </p>
            `}
            <vao-typical-price-evaluation 
                .low="${this.typicalLow}" 
                .high="${this.typicalHigh}" 
                .now="${this.typicalNow}">
            </vao-typical-price-evaluation>
            `}
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryMyPricing = StoryMyPricing;
customElements.define('vao-story-my-pricing', StoryMyPricing);
