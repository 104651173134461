// Example Rate Object (rateObj):
// {
//     "receivedTs": "2019-12-27T17:29:42+0000",
//     "snapshotDate": "2019-12-28",
//     "stayDate": "2020-04-23",
//     "marketValuesId": "116257518",
//     "hotelsCompetitorsId": "",
//     "hotelsDataSourcesId": "260",
//     "channelsId": "1",
//     "propertyName": "Property A",
//     "rate": "0",
//     "roomType": "",
//     "occupancy": 0,
//     "cancellationPolicy": "",
//     "mealPolicy": "",
//     "depositPolicy": "",
//     "mlos": 0
// }

// Example BI All Rates Object (allRates):
// "rateObjs": {
//     "me":[ ...rateObjs... ],
//     "comps":[ ...rateObjs... ]
// }

// Determine if a rateObj from the backend is of type "lowest rate".
import ColorArray from "./ColorArray";

export function isLowestRateObj(rateObj) {
    if (typeof rateObj !== 'object' || !rateObj) {
        return false;
    }

    if (
        rateObj.roomType === ''
        && rateObj.occupancy === 0
        && rateObj.cancellationPolicy === ''
        && rateObj.mealPolicy === ''
        && rateObj.depositPolicy === ''
        && rateObj.mlos === 0
    ) {
        return true;
    }

    return false;
}

// Common filter functions here.
export const filters = {
    lowest: (rateObj) => {
        return isLowestRateObj(rateObj);
    }
};

// Filter allRates BIStatistic response.
export function filterRateObjs(allRates, filter = null, makeUnique = true) {
    if (
        !allRates
        || typeof allRates !== 'object'
        || !('me' in allRates)
        || !('comps' in allRates)
        || !Array.isArray(allRates.me)
        || !Array.isArray(allRates.comps)
    ) {
        throw 'Cannot filter allRates invalid input';
    }

    let _filter;
    if (typeof filter === 'string' && filter in filters) {
        _filter = filters[filter];
    } else if (typeof filter === 'function') {
        _filter = filter;
    } else {
        _filter = filters.lowest;
    }

    let res = {
        ...allRates,
        me: allRates.me.filter(_filter),
        comps: allRates.comps.filter(_filter)
    };

    if (makeUnique) {
        // Ensure only one rateObj for the me array.
        if (res.me.length > 1) {
            res.me = [res.me[0]];
        }

        // Ensure only one rate object per competitor in the comps array.
        let map = new Map();
        res.comps.forEach((rateObj) => {
            if (!rateObj || typeof rateObj !== 'object') {
                return;
            }
            let hotelsCompetitorsId = rateObj.hotelsCompetitorsId;
            if (hotelsCompetitorsId && !map.has(hotelsCompetitorsId)) {
                map.set(hotelsCompetitorsId, rateObj);
            }
        });
        res.comps = Array.from(map.values());
    }

    return res;
}

// Assumes array has already been filtered.
export function calcRateObjsAvgRate(rateObjs) {
    if (
        !rateObjs
        || !Array.isArray(rateObjs)
    ) {
        throw 'Cannot calc rateObjs average';
    }

    if (rateObjs.length === 0) {
        return 0;
    }

    let sum = 0;
    let count = 0;

    rateObjs.forEach((rateObj) => {
        if (typeof rateObj !== 'object' || !rateObj) {
            return;
        }
        if ($.isNumeric(rateObj.rate)) {
            let rate = parseFloat(rateObj.rate);
            if (rate > 0) {
                sum += rate;
                count++;
            }
        }
    });

    if (count === 0) {
        return 0;
    }

    return sum / count;
}