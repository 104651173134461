import { AppElement, html } from '../AppElement.js';
import ColorArray from "../ColorArray";
import Constants from "../Constants";

export default class Tag extends AppElement {
    static get properties() {
        return {
            label: { type: String },
            labelColor: { type : String },
            bgColor: { type: String },
            canInterpretBgColor: { type: String },
            size: { type: String },
            width: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.label = props.label;
        this.labelColor = props.labelColor;
        this.bgColor = props.bgColor;
        this.canInterpretBgColor = super.reflowBool('true', props.canInterpretBgColor);
        this.size = props.size;
        this.width = props.width;
    }

    reflow(props = {}) {
        this.label = props.label || this.label;
        this.labelColor = props.labelColor || this.label;
        this.bgColor = props.bgColor || this.label;
        this.canInterpretBgColor = super.reflowBool(this.canInterpretBgColor, props.canInterpretBgColor);
        this.size = props.size || this.size;
        this.width = props.width || this.width;
    }

    isValidCssClass(input) {
        return input
            && typeof input === 'string'
            && input.length > 0
            && !ColorArray.isValidColor(input)
            && !input.includes(' ');
    }

    isValidStyleColor(input) {
        return ColorArray.isValidColor(input);
    }

    makeCls() {
        let parts = [];
        // Support inputs such as "text-primary" etc.
        if (this.isValidCssClass(this.labelColor)) {
            parts.push(this.labelColor.trim());
        }
        if (this.isValidCssClass(this.bgColor)) {
            parts.push(this.bgColor.trim());
        }
        if (this.isValidCssClass(this.width)) {
            parts.push(this.width.trim());
        }
        return parts.join(' ');
    }

    makeStyle() {
        let parts = [];
        const isValidLabelColor = this.isValidStyleColor(this.labelColor);
        const isHexLabelColor = ColorArray.isHexColor(this.labelColor);
        const isValidBgColor = this.isValidStyleColor(this.bgColor);
        if (isValidLabelColor) {
            parts.push(`color:${this.labelColor}`);
        }
        if (isValidBgColor) {
            parts.push(`background-color:${this.bgColor}`);
        } else if (isHexLabelColor && this.canInterpretBgColor === 'true') {
            const hex = ColorArray.hexToRGBA(this.labelColor, .08);
            parts.push(`background-color:${hex}`);
        }
        if (this.size === Constants.SIZES.XS) {
            parts.push('font-size:80%');
        } else if (this.size === Constants.SIZES.SMALL) {
            parts.push('font-size:90%');
        } else if (this.size === Constants.SIZES.MEDIUM) {
            parts.push('font-size:100%');
        } else if (this.size === Constants.SIZES.LARGE) {
            parts.push('font-size:110%');
        } else if (this.size === Constants.SIZES.EXTRA_LARGE) {
            parts.push('font-size:120%');
        }
        return parts.join(';');
    }

    render() {
        return html`
<div class="vao__components--tag rounded ${this.makeCls()}" style="${this.makeStyle()}">
    <div class="vao__components--tag-label" style="padding:.1rem .25rem .1rem .25rem;">${this.label}</div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Tag = Tag;
customElements.define('vao-tag', Tag);
