import ContextBalance from "./ContextBalance";
import Constants from "../Constants";

export default class ContextBalanceRoomType extends ContextBalance {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            btnText: { type: String },
            btnColor: { type: String }
        };
    }

    constructor(props = {}) {
        super(props);
    }

    reflow(props = {}) {
        super.reflow(props);
    }

    getCategory() {
        return Constants.CTX_CATEGORIES.ROOMTYPE;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ContextBalanceRoomType = ContextBalanceRoomType;
customElements.define('vao-context-balance-room-type', ContextBalanceRoomType);
