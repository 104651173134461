import { AppElement, html, TemplateResult } from '../AppElement.js';
import Button from '../button/Button';
import Icon from "../icon/Icon";
import ColorArray from "../ColorArray";
import Constants from "../Constants";

export default class Expand extends AppElement {
    static get properties() {
        return {
            contentEl: { type: Object },
            expandEl: { type: Object },
            isExpanded: { type: String },
            canShowIndication: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();
        this._expandElCache = null;
        this.indiIco = new Icon({
            cls: Constants.ICONS.CHEVRON_RIGHT
        });

        this.contentEl = props.contentEl;
        this.expandEl = props.expandEl;
        this.isExpanded = props.isExpanded;
        this.canShowIndication = props.canShowIndication;
    }

    reflow(props = {}) {
        this.contentEl = props.contentEl || this.contentEl;
        this.expandEl = props.expandEl || this.expandEl;
        this.isExpanded = super.reflowBool(this.isExpanded, props.isExpanded);
        this.canShowIndication = super.reflowBool(this.canShowIndication, props.canShowIndication);
        if ('expandEl' in props) {
            this._expandElCache = null;
        }
    }

    _isExpanded() {
        return String(this.isExpanded) === 'true';
    }

    getIsExpandedClass() {
        if (this._isExpanded()) {
            return ' expanded ';
        }
        return '';
    }

    obtainRenderEl(inputEl) {
        let el;
        if (inputEl instanceof HTMLElement || inputEl instanceof TemplateResult) {
            el = inputEl;
        } else {
            el = html``;
        }
        return el;
    }

    renderContent() {
        let contentEl = this.obtainRenderEl(this.contentEl);
        return html`
            <div class="vao__components--expand-content">
                ${contentEl}
            </div>
        `;
    }

    renderIndication() {
        if (String(this.canShowIndication) === 'false') {
            return html``;
        }
        return html`
            <div class="vao__components--expand-indication">
                ${this.indiIco}
            </div>
        `;
    }

    renderBody() {
        if (this._expandElCache === null && this._isExpanded()) {
            this._expandElCache = this.obtainRenderEl(this.expandEl);
        }
        return this._expandElCache || '';
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        let $this = $('#' + this.id);
        $this.on('click', (event) => {
            const target = $(event.target);
            const isCurrentTargetBtn = target.is('button');
            const isTargetChildOfABtn = target.parents('button').length;
            const isTargetChildOfContent = target.parents('.vao__components--expand-body').length;

            if (isCurrentTargetBtn || isTargetChildOfABtn || isTargetChildOfContent) {
                return;
            }

            if (this._isExpanded()) {
                this.isExpanded = 'false';
            } else {
                this.isExpanded = 'true';
            }
        });
    }

    render() {
        return html`
        <div class="vao__components--expand ${this.getIsExpandedClass()}">
            <div class="vao__components--expand-face">
                ${this.renderContent()}
                <div class="vao__components--expand-divider"></div>
                ${this.renderIndication()}
            </div>
            <div class="vao__components--expand-body">
                ${this.renderBody()}
            </div>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Expand = Expand;
customElements.define('vao-expand', Expand);
