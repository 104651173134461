import { AppElement, html } from '../AppElement.js';

export default class Icon extends AppElement {
    static get properties() {
        return {
            cls: { type: String },
            tooltip: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.cls = props.cls || '';
        this.tooltip = props.tooltip || null;
        this.id = AppElement.getUniqueElementId();
    }

    addTooltip() {
        this.removeTooltip();
        if (this.tooltip !== null && typeof this.tooltip === 'string' && this.tooltip.length > 0) {
            $('#' + this.id + ' .vao__components--icon').tooltip({
                placement: 'bottom',
                title: this.tooltip
            });
        }
    }

    removeTooltip() {
        $('#' + this.id + ' .vao__components--icon').tooltip('dispose');
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.addTooltip();
    }

    connectedCallback() {
        super.connectedCallback();
        this.addTooltip();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeTooltip();
    }

    render() {
        return html`
<i class="vao__components--icon ${this.cls}"></i>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Icon = Icon;
customElements.define('vao-icon', Icon);
