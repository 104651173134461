import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {getDisplayableTextForCategory} from "../CtxUtils";

export default class CategoryOptionBlock extends AppElement {
    static get properties() {
        return {
            currentCategory: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.currentCategory = props.currentCategory;
        this.onChange = props.onChange;
        this.id = AppElement.getUniqueElementId();
    }

    getDisplayableTextForCategory(category) {
        return getDisplayableTextForCategory(category);
    }

    _onChange(newCategory) {
        if (newCategory === this.currentCategory) {
            return;
        }
        this.currentCategory = newCategory;
        if (typeof this.onChange === 'function') {
            this.onChange(this.currentCategory);
        }
    }

    renderOptions() {
        let options = [];
        let btn;

        const getColorForCategory = (category) => {
            if (category === this.currentCategory) {
                return 'primary';
            }
            return 'light';
        };

        const getDisabledStateForCategory = (category) => {
            if (category === this.currentCategory) {
                return 'true';
            }
            return 'false';
        };

        btn = html`
<vao-button  
    @click="${() => {this._onChange(Constants.CTX_CATEGORIES.ROOMTYPE)}}"
    text="${this.getDisplayableTextForCategory(Constants.CTX_CATEGORIES.ROOMTYPE)}" 
    startIcon="${Constants.ICONS.ROOM_TYPE}"
    variant="contained" 
    size="small"
    color="${getColorForCategory(Constants.CTX_CATEGORIES.ROOMTYPE)}" 
    disabledState="${getDisabledStateForCategory(Constants.CTX_CATEGORIES.ROOMTYPE)}">                            
</vao-button>`;
        options.push(btn);

        btn = html`
<vao-button  
    @click="${() => {this._onChange(Constants.CTX_CATEGORIES.SEGMENT)}}"
    text="${this.getDisplayableTextForCategory(Constants.CTX_CATEGORIES.SEGMENT)}" 
    startIcon="${Constants.ICONS.MARKET_SEGMENT}"
    variant="contained" 
    size="small"
    color="${getColorForCategory(Constants.CTX_CATEGORIES.SEGMENT)}" 
    disabledState="${getDisabledStateForCategory(Constants.CTX_CATEGORIES.SEGMENT)}">                            
</vao-button>
        `;
        options.push(btn);
        return html`<div class="vao__components--categoryOptionBlock-options">${options}</div>`;
    }

    render() {
        return html`
<div class="vao__components--categoryOptionBlock">
    ${this.renderOptions()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.CategoryOptionBlock = CategoryOptionBlock;
customElements.define('vao-category-option-block', CategoryOptionBlock);
