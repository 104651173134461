import { AppElement, html } from '../AppElement.js';
import {OPTION_DAY, OPTION_MONTH} from "./BudgetForecastRangeSelect";

export default class BudgetForecastControl extends AppElement {
    static get properties() {
        return {
            // props
            recordDate: { type: String },
            hotel: { type : Object },

            // state
            rangeValue: { type: String },
            year: { type: String },
        };
    }
    constructor(props = {}) {
        super();

        // props
        this.recordDate = props.recordDate || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.hotel = props.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();

        // state
        this.rangeValue = props.rangeValue || OPTION_MONTH;
        this.year = props.year || window.infinito.vao.controller.dateHelper.format(this.recordDate, 'YYYY');
    }
    reflow(props = {}) {
        this.recordDate = props.recordDate || this.recordDate;
        this.hotel = props.hotel || this.hotel;

        this.rangeValue = props.rangeValue || this.rangeValue;
        this.year = props.year || this.year;
    }

    onRangeChange(e) {
        this.rangeValue = e.currentTarget.value;
    }

    onChangeYear(e) {
        this.year = e.currentTarget.value;
    }
    render() {
        const baseYear = window.infinito.vao.controller.dateHelper.format(this.recordDate, 'YYYY');

        return html`
    <div class="vao__components--budgetControl">
<!--        <a href="../../../../images/BudgetForecast.xls" download>-->
<!--            <button  class="btn" style="margin-top: 15px;margin-right:15px;margin-bottom:15px;border-color: #2196f3;color:grey;height:38px;"><i class="fas fa-download"></i> Download</button>-->
<!--        </a>-->
        
        
        <vao-budgetforecast-range-select 
            .value="${this.rangeValue}" 
            @change="${this.onRangeChange}"
        ></vao-budgetforecast-range-select>
        <br>
        <vao-budgetforecast-year-select 
            .baseYear="${baseYear}" 
            .year="${this.year}" 
            @change="${this.onChangeYear}"
        ></vao-budgetforecast-year-select>
        <br> 
        <vao-budgetforecast-form-table
            .aggregator="${this.rangeValue}"
            .year="${this.year}"
            .hotel="${this.hotel}"
        ></vao-budgetforecast-form-table>
    </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetForecastControl = BudgetForecastControl;
customElements.define('vao-budgetforecast-control', BudgetForecastControl);
