import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";
import {Throttler} from "../Throttler";
import {makeEvolutionDateStrArray} from "../EvolutionUtils";
import ColorArray from "../ColorArray";

export default class OccupancyEvolutionLeadTimeCompareChart extends AppElement {
    static get properties() {
        return {
            property: { type: Object },
            stayDate: { type: String },
            snapshotDate: { type: String },
            evolutionRange: { type: Number },
            historicRange: { type: Number }
        };
    }

    constructor(props = {}) {
        super();
        this.property = props.property || {};
        this.stayDate = props.stayDate || null;
        this.snapshotDate = props.snapshotDate || null;
        this.evolutionRange = props.evolutionRange || null;
        this.historicRange = props.historicRange || null;
        this.compClass="vao__components--occupancyEvolutionLeadTimeCompareChart";
        this.chartId = AppElement.getUniqueElementId();
        this.throttler = new Throttler(1, 0);
        this.configs = new Map();
    }

    reflow(props = {}) {
        this.property = props.property || this.property;
        this.stayDate = props.stayDate || this.stayDate;
        this.snapshotDate = props.snapshotDate || this.snapshotDate;
        this.evolutionRange = props.evolutionRange || this.evolutionRange;
        this.historicRange = props.historicRange || this.historicRange;
        this.throttler.emptyQueue();
        this.configs = new Map();
        this.fill();
    }

    chartOpts() {
        let leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
            this.stayDate,
            this.snapshotDate
        );
        let minX = 0;
        let maxX = this.evolutionRange;
        let self = this;
        let colModifier = (hex, index) => {
            try {
                return ColorArray.hexToRGBA(hex, (1 - (index / 10)))
            } catch (e) {
                return null;
            }
        };
        let colorArray = new ColorArray(null, null, null, 0, colModifier);

        return {
            title: {
                text: 'Occupancy DOW Evolution'
            },
            credits: {
                enabled: false
            },
            chart: {
                spacingTop: 20,
                spacingBottom: 20,
                height: this.chartHeight
            },
            colors: colorArray.colorArray,
            lang: {
                noData: 'No data to display'
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#303030'
                }
            },
            xAxis: {
                type: 'linear',
                title: {
                    text: 'Lead time'
                },
                min: minX,
                max: maxX,
                reversed: true,
                plotLines: [{
                    color: '#d3d3d3',
                    width: 1,
                    value: leadTime,
                    dashStyle: 'Dash',
                    label: {
                        text: 'Today'
                    }
                }]
            },
            yAxis: [
                {
                    gridLineWidth: 0,
                    lineWidth: 1,
                    opposite: true,
                    title: {
                        text: 'Occupancy'
                    },
                    labels: {
                        format: '{value}%'
                    },
                    endOnTick: false,
                    maxPadding: 0.02
                }
            ],
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                labelFormatter: function labelFormatter() {
                    return this.name;
                },
                itemMarginBottom: 5
            },
            plotOptions: {
                series: {
                    events: {},
                    connectNulls: true,
                    label: {
                        connectorAllowed: false
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                },
                column: {
                    /* Limit the maximum column width. */
                    maxPointWidth: 15
                }
            },
            tooltip: {
                shared: true,
                split: false,
                enabled: true,
                formatter: function formatter() {
                    return this.points.reduce(function reduce(s, point) {
                        let seriesName = point.series.name;
                        let config = self.configs.get(seriesName);
                        let y = point.y + '%'; // this formatter should only be called for non null y points
                        let asOf = '-';
                        if (config) {
                            asOf = window.infinito.vao.controller.dateHelper.getShortDateMonString(
                                window.infinito.vao.controller.dateHelper.subtractDaysFromDateString(
                                    config.stayDate,
                                    point.x
                                )
                            );
                        }

                        return s + '<br/>'
                            + '<span style="color:' + point.color + '">\u25CF</span> '
                            + seriesName + ': <b>' + y + '</b>'
                            + '<span style="font-size:.7rem;">' + ' (as of ' + asOf + ')</span>';
                    }, 'Lead time ' + this.x + '<br/><hr><br/>');
                }
            },
            series: [],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };
    }

    empty() {
        let chart = this.getChart();
        if (!chart) {
            return;
        }
        while (chart.series.length) {
            chart.series[0].remove();
        }
    }

    getChart() {
        if (this.chart) {
            return this.chart;
        }
        if (document.getElementById(this.chartId) === null) {
            // wait until its in the DOM or else highcharts will error
            return null;
        }
        this.chart = window.Highcharts.chart(this.chartId, this.chartOpts());
        return this.chart;
    }

    getRequiredAPIRecordDateForStayDate(stayDate) {
        // Interpreting the result:
        // 0 means the stayDate === snapshotDate
        // 1 means the stayDate is BEFORE the snapshotDate
        // -1 means the stayDate is AFTER the snapshotDate
        let compare = window.infinito.vao.controller.dateHelper.compareDateStrs(this.snapshotDate, stayDate);

        // For the current date or future dates, use the current snapshotDate.
        if (compare <= 0) {
            return this.snapshotDate;
        }

        // For any stay dates that have already closed, gets the data as of that stay date.
        return stayDate;
    }

    makeSeriesData(valObj, valLeadTime) {
        let val;
        if ($.isNumeric(valObj.occupancy)) {
            val =  Math.round(parseFloat(valObj.occupancy) * 100);
        } else {
            val = null;
        }
        return {
            y: val,
            x: valLeadTime,
            stuff: 'abcd'
        };
    }

    initConfigs() {
        let biStatistics = window.infinito.vao.model.biStatistics;
        this.configs = new Map();
        let makeNameForSeries = (stayDate) => {
            return window.infinito.vao.controller.dateHelper.getShortDayDateMonString(stayDate);
        };

        for (let i = 0; i <= this.historicRange; i++) {
            let config = {};
            let daysToSub = (i * 7);
            let subbedStayDate = window.infinito.vao.controller.dateHelper.subtractDaysFromDateString(
                this.stayDate,
                daysToSub
            );
            if (!window.infinito.vao.controller.dateHelper.isDateStr(subbedStayDate)) {
                continue;
            }
            let requiredRecordDate = this.getRequiredAPIRecordDateForStayDate(subbedStayDate);
            let seriesName = makeNameForSeries(subbedStayDate);
            config['stayDate'] = subbedStayDate;
            config['recordDate'] = requiredRecordDate;
            config['evolutionRange'] = this.evolutionRange;
            config['biQuery'] = biStatistics.buildQuery({
                recordDate: requiredRecordDate,
                firstStayDate: subbedStayDate,
                lastStayDate: subbedStayDate,
                fields: [
                    biStatistics.fields.occupancyEvolution
                ],
                evolutionRange: this.evolutionRange
            });
            config['series'] = {
                index: i,
                name: seriesName,
                data: [],
                yAxis: 0,
                type: 'spline',
                zIndex: ((this.historicRange - i) + 1),
                lineWidth: 2,
                marker: {
                    enabled: false
                }
            };
            this.configs.set(seriesName, config);
        }

        return this.configs;
    }

    fillBI() {
        let biStatistics = window.infinito.vao.model.biStatistics;

        let chart = this.getChart();
        if (!chart) {
            return;
        }
        let promises = [];
        chart.showLoading();
        this.empty();
        this.initConfigs();
        this.disableChange();

        this.configs.forEach(config => {
            promises.push(new Promise((apiFillDone) => {
                this.throttler.queueUp((throttleDone) => {
                    biStatistics.fetchStatistics(
                        this.property.id,
                        config.biQuery,
                        (data, procData) => {
                            if (!procData || typeof procData !== 'object') {
                                procData = {};
                            }
                            let configSeries = config.series;
                            let configStayDate = config.stayDate;
                            let evolutionVal = ((procData.occupancyEvolution || {})[configStayDate] || {}).value || {};
                            let evolutionDates = makeEvolutionDateStrArray(
                                config.recordDate,
                                config.evolutionRange
                            );

                            evolutionDates.reverse().forEach((evdate) => {
                                let evolutionDateData = (evolutionVal[evdate] || {});
                                let evolutionLeadTime = evolutionDateData.leadTime || 0;

                                let seriesData = this.makeSeriesData(evolutionDateData, evolutionLeadTime);
                                if (seriesData) {
                                    configSeries.data.push(seriesData);
                                }
                            });

                            chart.addSeries(configSeries, false);
                            throttleDone();
                            apiFillDone();
                        }
                    );
                });
            }));
        });

        Promise.all(promises).then(() => {}).catch(() => {}).finally(() => {
            chart.hideLoading();
            chart.redraw();
            this.enableChange();
        });
    }

    fill() {
        if (
            !this.property
            || typeof this.property !== 'object'
            || !('id' in this.property)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.snapshotDate)
            || !$.isNumeric(this.evolutionRange)
            || this.evolutionRange < 0
            || this.evolutionRange > 120
            || !$.isNumeric(this.historicRange)
            || this.historicRange < 0
            || this.historicRange > 5
            ||  window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
                this.stayDate,
                this.snapshotDate
            ) > 120
        ) {
            this.empty();
            return;
        }

        this.fillBI();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        let chart = this.getChart();
        if (chart) {
            // https://api.highcharts.com/class-reference/Highcharts.Chart#destroy
            chart.destroy();
        }
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties instanceof Map) {
            let evoRangeChange = _changedProperties.get('evolutionRange');
            if (evoRangeChange) {
                let chart = this.getChart();
                if (chart) {
                    this.empty();
                    chart.update({
                        xAxis: {
                            max: this.evolutionRange
                        }
                    });
                }
                this.fill();
            }
        }
    }

    disableChange() {
        let $optWrapper = $(this).find('.' + this.compClass + '-opts');
        $optWrapper.find('button').attr('disabled', true);
    }

    enableChange() {
        let $optWrapper = $(this).find('.' + this.compClass + '-opts');
        $optWrapper.find('button').attr('disabled', false);
    }

    onCLickRange(e) {
        let newEvoRange = e.currentTarget.value;
        if ($.isNumeric(newEvoRange)) {
            this.evolutionRange = newEvoRange;
        }
    }

    render() {
        return html`
<div class="${this.compClass}">
    <div class="${this.compClass + '-opts'} row no-gutters" style="align-items:center;">
        <div class="col-xs-12" style="display:flex;justify-content:flex-end;">
            <vao-button-group 
            size="small"
            .slot=${
            [14, 30, 60, 120].map(evoRange => {
                return html`
                        <button 
                        @click="${this.onCLickRange.bind(this)}" 
                        type="button" 
                        class="btn btn-light ${parseInt(this.evolutionRange) === evoRange ? 'active' : ''}" 
                        value="${evoRange}">
                            ${evoRange}
                        </button>`;
            })
        }>
            </vao-button-group>
        </div>
    </div>
    <div id="${this.chartId}"></div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.OccupancyEvolutionLeadTimeCompareChart = OccupancyEvolutionLeadTimeCompareChart;
customElements.define('vao-occupancy-evolution-lead-time-compare-chart', OccupancyEvolutionLeadTimeCompareChart);
