import { html } from '../AppElement';
import PerformanceForecastTable from './PerformanceForecastTable';
import { sendRequest } from '../App.common';

export default class PerformanceForecastTableWrapper extends PerformanceForecastTable {
    static get properties() {
        return {
            recordDate: { type: String },
            hotels: { type: Array },
            performanceMetric: { type: String }
        };
    }

    constructor() {
        super();
        this.recordDate = null;
        this.hotels = [];
        this.months = this.calculateMonths();
        this.performanceMetric = 'roomsOtb';
        this.dataCache = {};
    }

    async fill() {
        if (
            !this.hotels
            || !(this.hotels instanceof Array)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        ) {
            super.empty();
            return;
        }

        let rows = super.makeTableRows();
        await this.fillRows();
        let table = super.reflowTable(rows);
        super.domTheTable(table);
    }

    async fillRows() {
        const totalRows = this.hotels.length;
        await Promise.all(
            this.hotels.map((hotel, rowIndex) => this.fillSingleRow(hotel, rowIndex, totalRows))
        );
    }

    async fillSingleRow(hotel, rowIndex, totalRows) {
        if (this.dataCache[hotel.id]) {
            this.processHotelData(hotel.id, this.dataCache[hotel.id], rowIndex, totalRows);
        } else {
            try {
                const hotelData = await this.fetchHotelData(hotel.id);
                this.dataCache[hotel.id] = hotelData;
                this.processHotelData(hotel.id, hotelData, rowIndex, totalRows);
            } catch (error) {
                console.error(`Error processing hotel row for hotel ${hotel.name}:`, error);
            }
        }
    }

    async fetchHotelData(hotelId) {
        let requestDataObj = {
            hotelId: hotelId,
            recordDate: this.recordDate,
            op: 'getHotelPerformanceForecast',
            serviceName: 'groupinsightsapi.php'
        };

        try {
            const hotelPerformanceForecastResponse = await new Promise((resolve, reject) => {
                sendRequest(requestDataObj, (res) => {
                    if (res instanceof Error) {
                        reject(res);
                    } else {
                        resolve(res);
                    }
                });
            });

            const jsonObj = JSON.parse(hotelPerformanceForecastResponse);
            return jsonObj.data;
        } catch (error) {
            console.error(`Error fetching hotel performance forecast for hotel ${hotelId}:`, error);
            throw error;
        }
    }

    processHotelData(hotelId, data, rowIndex, totalRows) {
        const hotel = this.hotels.find(h => h.id === hotelId);
        if (!hotel) return;

        let row = this.cache.rowMap.get(hotel.name);
        let hotelNameCell = row.tableCellPerField.get('hotelName');

        hotelNameCell.value = html`${hotel.name}`;
        hotelNameCell.isLoading = false;

        let differences = data.map(monthData => {
            let hotelMonthValue = monthData.now[this.performanceMetric];
            let stlyValue = monthData.stly[this.performanceMetric];
            if ($.isNumeric(hotelMonthValue) && $.isNumeric(stlyValue)) {
                return hotelMonthValue - stlyValue;
            }
            return null;
        })
            .filter(diff => diff !== null);

        const minDifference = Math.min(...differences);
        const maxDifference = Math.max(...differences);
        const range = maxDifference - minDifference;

        data.forEach((monthData, index) => {
            let hotelMonthValue = monthData.now[this.performanceMetric];
            let stlyValue = monthData.stly[this.performanceMetric];

            let cell = row.tableCellPerField.get(monthData.month);

            if ($.isNumeric(hotelMonthValue) && $.isNumeric(stlyValue)) {
                hotelMonthValue = Math.round(hotelMonthValue * 100) / 100;
                stlyValue = Math.round(stlyValue * 100) / 100;

                let difference = hotelMonthValue - stlyValue;
                difference = Math.round(difference * 100) / 100;
                let bgColor = this.getDynamicBackgroundColor(difference, minDifference, maxDifference, range);

                cell.__cellcolor = bgColor;

                const isLastTwoRows = rowIndex >= totalRows - 2;
                const isRightEdgeColumn = index === data.length - 1;
                const popoverPosition = this.setPopoverDirection(isRightEdgeColumn, isLastTwoRows);

                cell.value = html`
                    <div class="popover__wrapper" style="background-color: ${bgColor};">
                        <div class="popover__title">
                            ${hotelMonthValue}
                        </div>
                        <div class="popover__content ${popoverPosition}">
                            <div class="popover__message ${difference >= 0 ? 'popover-green-txt' : 'popover-red-txt'}">
                                ${difference >= 0 ? '↑' : '↓'} ${Math.abs(difference)}
                            </div>
                            <button class="btn popover-btn"
                                    @click="${() => this.onMonthlyButtonClick(hotel)}">
                                Monthly Analysis
                            </button>
                        </div>
                    </div>
                `;
            } else {
                cell.value = null;
            }
            cell.isLoading = false;
        });
    }

    getDynamicBackgroundColor(difference, min, max, range) {
        if (range === 0) {
            return 'white';
        }

        let normalizedValue = (difference - min) / range;

        if (difference > 0) {
            return this.getGreenShade(normalizedValue);
        }
        if (difference < 0) {
            return this.getRedShade(normalizedValue);
        }
        return 'white';
    }

    getGreenShade(normalizedValue) {
        let color;
        if (normalizedValue < 0.33) {
            color = '#d4edda'; // Light green
        } else if (normalizedValue < 0.66) {
            color = '#c3e6cb'; // Medium green
        } else {
            color = '#a3d6a7'; // Dark green
        }
        return color;
    }

    getRedShade(normalizedValue) {
        let color;
        if (normalizedValue < 0.33) {
            color = '#e99396'; // Dark red
        } else if (normalizedValue < 0.66) {
            color = '#f5c6cb'; // Medium red
        } else {
            color = '#f8d7da'; // Light red
        }
        return color;
    }

    setPopoverDirection(isRightEdgeColumn, isLastTwoRows) {
        if (isRightEdgeColumn) {
            return 'left';
        }
        if (isLastTwoRows) {
            return 'top';
        }
        return 'bottom';
    }

    onMonthlyButtonClick(hotel) {
        let body = '<input type="hidden" id="hid" value="' + hotel.id + '" />'
            + '<input type="hidden" id="hname" value="' + hotel.name + '" />'
            + '<vao-page-monthlyanalysis-modal></vao-page-monthlyanalysis-modal>';

        let $title = $('<div></div>')
            .append(
                '<h5>Monthly Analysis</h5>'
            );
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size: 'xl'
        });
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PerformanceForecastTableWrapper = PerformanceForecastTableWrapper;
customElements.define('vao-performance-forecast-table-wrapper', PerformanceForecastTableWrapper);
