import { AppElement, html } from '../AppElement.js';

export default class ExpandBtns extends AppElement {
    static get properties() {
        return {
            targetSelector: { type: String },
        };
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();

        this.targetSelector = props.targetSelector;
    }

    reflow(props = {}) {
        this.targetSelector = props.targetSelector || this.targetSelector;
    }

    render() {
        return html`
        <div class="vao__components--expandBtns">
            <vao-expand-collapse-all-btn .targetSelector="${this.targetSelector}"></vao-expand-collapse-all-btn>
            <vao-expand-all-btn .targetSelector="${this.targetSelector}"></vao-expand-all-btn>
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ExpandBtns = ExpandBtns;
customElements.define('vao-expand-btns', ExpandBtns);
