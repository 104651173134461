import Constants from "../Constants";
import { html } from "../AppElement";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";

export default class TopPickup {

    constructor(isoDate, rawRooms, rawRev, rawAdr, locale) {
        if (!window.infinito.vao.controller.dateHelper.isDateStr(isoDate)) {
            throw 'TopPickup expects a valid ISO8601 date';
        }
        this.isoDate = isoDate;
        this.rawRooms = rawRooms;
        this.rawRev = rawRev;
        this.rawAdr = rawAdr;
        this.locale = locale;
    }

    getDate() {
        return window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(this.isoDate);
    }

    getRooms() {
        if ($.isNumeric(this.rawRooms)) {
            if (this.locale) {
                const formatted = String(this.rawRooms);
                return new NumericVarianceBlock({
                    numericValue: this.rawRooms,
                    formattedValue: formatted,
                })
            } else {
                return String(this.rawRooms);
            }
        }
        return Constants.MISSING_STR;
    }

    getAdr() {
        if ($.isNumeric(this.rawAdr)) {
            if (this.locale) {
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    this.rawAdr,
                    Constants.ADR_DIGITS,
                    true,
                    this.locale,
                    1
                );
                return new NumericVarianceBlock({
                    numericValue: this.rawAdr,
                    formattedValue: formatted,
                });
            } else {
                return String(this.rawAdr);
            }
        }
        return Constants.MISSING_STR;
    }

    getRevenue() {
        if ($.isNumeric(this.rawRev)) {
            if (this.locale) {
                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    this.rawRev,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.locale
                );
                return new NumericVarianceBlock({
                    numericValue: this.rawRev,
                    formattedValue: formatted,
                });
            } else {
                return String(this.rawRev);
            }
        }
        return Constants.MISSING_STR;
    }

}

window.vao = window.vao || {};
window.vao.classes = window.vao.classes || {};
window.vao.classes.TopPickup = TopPickup;