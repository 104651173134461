import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';

export default class AddNewRoomType extends AppElement {
    static get properties() {
        return {
            hotel_id: { type: Object }
        };
    }

    constructor(props = {}) {
        super();
        this.options = [];
        this.hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();
    }
    saveNew(e){
        var hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();
        let room_type = $('#room_type').val();
        let shortname = $('#shortname').val();
        let description = $('#description').val();
        let total_rooms = $('#total_rooms').val();

        let jsonObj = {
            "room_type":room_type,
            "shortname":shortname,
            "description":description,
            "total_rooms":total_rooms
        }

        $('.vao__components--basicModal').modal('toggle');

        let dataObj = {
            'hotel_id':hotel_id,
            'data':JSON.stringify(jsonObj),
            'op':'saveNewRoomType',
            'serviceName':"roomtypesapi.php"
        };

        let flag = this;
        sendRequest(dataObj,function(res){
            //window.location.reload();
        });


    }

    _onChange(e){
        var sel = e.target.value;
        if(sel == -1){
            $('#shortname').val('');
            return false;
        }

        $('#shortname').val($("#room_type option:selected").text());

    }

    render() {

        return html`
            <div class="vao__setup--hotel-table-panel">
                <table class="table vao__setup--hotel-table">
                    <tbody>
                    <tr class="vao__setup--selHotelTzRow">
                        <th scope="row">Room Type</th>
                        <td id="vao__setup--selroomtype">
                            
                            <select @change=${this._onChange} id="room_type" class="form-control" name="room_type" required>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Short Name</th>
                        <td class="vao__setup--selRateChannel-td">
                            <input class="form-control form-control-sm width-auto" readonly
                                   type="text" id="shortname" name="shortname" min="" max=""
                                     step="any" pattern="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Description</th>
                        <td class="vao__setup--selRateChannel-td">
                            <input class="form-control form-control-sm width-auto" type="text" id="description" name="description" min="" max=""
                                     step="any" pattern="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Total Rooms</th>
                        <td class="vao__setup--selRateChannel-td">
                            <input class="form-control form-control-sm width-auto" type="number" id="total_rooms" name="total_rooms" min="" max=""
                                     step="any" pattern="">
                        </td>
                    </tr>
                    <tr>
                        <th scope="row"></th>
                        <td class="vao__setup--selRateChannel-td">
                            <button @click="${this.saveNew}" id="vao__save--roomtypeBtn" style="float:right;"
                                    class="btn btn-primary">Save
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>`;

    }
    firstUpdated(){
        let dataObj = {
            'hotel_id':this.hotel_id,
            'op':'getRoomTypesList',
            'serviceName':"roomtypesapi.php"
        };

        let flag = this;
        sendRequest(dataObj,function(res){
            /**LOAD OPTIONS TO SELECT COMBO**/
                // var jsonObj = JSON.parse('[{"id":"new","text":"New"},{"id":"1","text":"Expedia"},{"id":"2","text":"TPMS"},{"id":"3","text":"OperaHF"},{"id":"4","text":"Opera-RoomType"},{"id":"5","text":"RMS"},{"id":"6","text":"TBF"}]');
            let obj = JSON.parse(res);
            var jsonObj = obj.data;

            //jsonObj.push({"id":"new","text":"New"});

            $('#room_type')
                .append($("<option></option>")
                    .attr("value", "-1")
                    .text("Select Room Type"));
            $.each(jsonObj, function(key, value) {
                $('#room_type')
                    .append($("<option></option>")
                        .attr("value", value.id)
                        .text(value.text));
            });

            // $('#room_type').select2({
            //     data: jsonObj,
            //     width: 'resolve'
            // });
        });
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.RoomType = AddNewRoomType;
customElements.define('vao-new-room-type', AddNewRoomType);