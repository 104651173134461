import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';
import TotalPickupTitle from "../totalPickupExpandCard/TotalPickupTitle";
import PickupPerMonthHotel from "../pickupPerMonth/PickupPerMonthHotel";
import FlashExpandCard from "../expand/FlashExpandCard";
import Button from '../button/Button';
import Constants from "../Constants";
import TotalPickupHotelTrend from "../totalPickupExpandCard/TotalPickupHotelTrend";
import TotalPickupExpandCardInfo from "../totalPickupExpandCard/TotalPickupExpandCardInfo";
import {calcDuration} from '../Utils';
import TopPickupHotelCardList from "../topPickup/TopPickupHotelCardList";
import MiniContext from "../miniContext/MiniContext";
import YesterdayHotel from "../yesterday/YesterdayHotel";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";

export default class FlashGroupHotelExpandCard extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super();

        this.id = AppElement.getUniqueElementId();

        this.hotel = props.hotel || {};
        this.recordDate = props.recordDate;
        this.pickupSetting = props.pickupSetting;

        this.card = this.createCard();
    }

    reflow() {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = props.pickupSetting || this.pickupSetting;

        this.card = this.createCard();
        this.fill();
    }

    fill() {
        // if (
        //     !this.hotel
        //     || typeof this.hotel !== 'object'
        //     || !('id' in this.hotel)
        //     || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
        //     || !$.isNumeric(this.pickupSetting)
        // ) {
        //     return;
        // }
        let dataObj = {
            'hotel_id':this.hotel.id,
            'recordDate':this.recordDate,  // '2021-06-20' this.recordDate
            'pickupOffset':this.pickupSetting,
            'op':'getFlashGroupHotelCard',
            'serviceName':"flashgroupapi.php"
        };

        let flag = this;
        sendRequest(dataObj,function(res){
            // console.log("Success Call Back - ",JSON.parse(res));
            let obj = JSON.parse(res);
            const _procData = obj.data || {};
            const roomsOtbTotalPickup = _procData[0].value || {};
            const roomsOtbTotalPickupVal = roomsOtbTotalPickup;
            const adrTotalPickupVal = _procData[1].value || {};
            const revenueTotalPickupVal = _procData[2].value || {};

            let otb, adr, rev, duration;
            if ($.isNumeric(roomsOtbTotalPickupVal)) {
                otb = new NumericVarianceBlock({
                    numericValue: roomsOtbTotalPickupVal,
                    formattedValue: String(roomsOtbTotalPickupVal),
                    tooltip: Constants.STRINGS.ROOMS
                });
                let roomsotbttlpickup = {
                    "receivedTs"  : _procData[0].receivedTs,
                    "offsetReceivedTs"  : _procData[0].offsetReceivedTs
                };
                duration = calcDuration(roomsotbttlpickup);
            } else {
                if(_procData[0].value == 0){
                    otb = new NumericVarianceBlock({
                        numericValue: 0,
                        formattedValue: String(0),
                        tooltip: Constants.STRINGS.ROOMS
                    });
                }else{
                    otb = Constants.MISSING_STR;
                }

            }
            if ($.isNumeric(adrTotalPickupVal)) {
                let _fAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    adrTotalPickupVal,
                    Constants.ADR_DIGITS,
                    true,
                    flag.hotel.locale
                );
                adr = new NumericVarianceBlock({
                    numericValue: adrTotalPickupVal,
                    formattedValue: _fAdr,
                    tooltip: Constants.STRINGS.ADR
                });
            } else {
                if(_procData[1].value == 0){
                    let _fAdr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        0,
                        Constants.ADR_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    adr = new NumericVarianceBlock({
                        numericValue: 0,
                        formattedValue: _fAdr,
                        tooltip: Constants.STRINGS.ADR
                    });
                }else{
                    adr = Constants.MISSING_STR;
                }

            }

            if ($.isNumeric(revenueTotalPickupVal)) {
                let _fRev = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    revenueTotalPickupVal,
                    Constants.REVENUE_DIGITS,
                    true,
                    flag.hotel.locale
                );
                rev = new NumericVarianceBlock({
                    numericValue: revenueTotalPickupVal,
                    formattedValue: _fRev,
                    tooltip: Constants.STRINGS.REV
                });
            } else {
                if(_procData[2].value == 0){
                    let _fRev = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        0,
                        Constants.REVENUE_DIGITS,
                        true,
                        flag.hotel.locale
                    );
                    rev = new NumericVarianceBlock({
                        numericValue: 0,
                        formattedValue: _fRev,
                        tooltip: Constants.STRINGS.REV
                    })
                }else{
                    rev = Constants.MISSING_STR;
                }

            }

            if (flag.card instanceof FlashExpandCard && flag.card.contentEl instanceof TotalPickupTitle) {
                flag.card.contentEl.reflow({
                    otb,
                    adr,
                    rev,
                    duration,
                    isLoading: false
                });
            }
        });

    }

    onCardHelp() {
        window.infinito.components.basicModal.render({
            title: 'Skeleton',
            bodyElem: $(new TotalPickupExpandCardInfo())
        });
    }

    createCard() {
        const content = new TotalPickupTitle({
            name: this.hotel.name,
            isLoading: true
        });

        const action = new TotalPickupHotelTrend({
            hotel: this.hotel,
            pickupSetting: this.pickupSetting,
            recordDate: this.recordDate
        });

        const pickupPerMonthHotel = new PickupPerMonthHotel({
            hotel: this.hotel,
            recordDate: this.recordDate,
            pickupSetting: this.pickupSetting
        });

        const topPickups = new TopPickupHotelCardList({
            hotel: this.hotel,
            recordDate: this.recordDate,
            pickupSetting: this.pickupSetting
        });

        const jumpBtn = new Button({
            text: 'Jump',
            startIcon: Constants.ICONS.JUMP,
            size: 'small',
            variant: 'invert',
            onClick: () => {
                window.infinito.vao.controller.storageHelper.changeSelectedHotel(this.hotel);
                window.infinito.vao.controller.storageHelper.changeAppMode('single');
            }
        });

        const yesterdayBtn = new Button({
            text: 'Yesterday',
            color: 'light',
            size: 'small',
            onClick: () => {
                const yesterdayHotel = new YesterdayHotel({
                    recordDate: this.recordDate,
                    hotel: this.hotel
                });
                const prettyRecordDate = window.infinito.vao.controller.dateHelper
                    .getShortDayDateMonFullYearString(this.recordDate);
                const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
                    yesterdayHotel.yesterday
                );
                const $title =  $('<div></div>').append(
                    '<h5>Yesterday</h5>'
                ).append(new MiniContext({
                    recordDate: prettyRecordDate,
                    stayDate: prettyStayDate,
                    isModalStyle: false
                }));
                window.infinito.components.basicModal.render({
                    title: $title,
                    isTitleHtml: true,
                    bodyElem: $(yesterdayHotel)
                });
            }
        });

        const expandBtnsTarget = `#${this.id} .vao__components--flashGroupHotelExpandCard-topPickups`;
        const expand = html`
            <div 
                class="vao__components--flashGroupHotelExpandCard-btns" 
                style="padding:.5rem 0;display:flex;justify-content:flex-end;"
            >
                <div style="margin-right:1rem;">${yesterdayBtn}</div>
                <div>${jumpBtn}</div>     
            </div>
            <h6 class="vao__components--flashGroup-bodySectionTitle">
                Top Pickups
                <span style="display:inline-block;">
                    <vao-expand-btns .targetSelector="${expandBtnsTarget}"></vao-expand-btns>
                </span>
            </h6>
            <div class="vao__components--flashGroupHotelExpandCard-topPickups">${topPickups}</div>
            <br>
            ${pickupPerMonthHotel} 
        `;

        return new FlashExpandCard({
            actionEl: action,
            hotel_id:this.hotel,
            contentEl: content,
            expandEl: expand,
            onHelp: 'tooltips',
            isExpanded: false
        });
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    render() {
        return html`
        <div class="vao__components--flashGroupHotelExpandCard">
            ${this.card}     
        </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.FlashGroupHotelExpandCard = FlashGroupHotelExpandCard;
customElements.define('vao-flash-group-hotel-expand-card', FlashGroupHotelExpandCard);
