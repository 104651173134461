import {makeChannelOptionHtml} from "./ChannelOptionHtml";

export function makeBcomChannelOptionHtml() {
    const innerHtml = '<div><span style="color:white;">Booking</span>.com</div>';
    const html = makeChannelOptionHtml(innerHtml, [
        'background: #23387E',
        'color: #47A1DE',
        'border-color: #23387E'
    ]);
    return html;
}
