import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export const OPTION_DAY = 'day';
export const OPTION_WEEK = 'week';
export const OPTION_MONTH = 'month';
export const OPTION_YEAR = 'year';

export default class BudgetForecastRangeSelect extends AppElement {
    static get properties() {
        return {
            value: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.value = props.value || undefined;
        this.onChange = props.onChange;
    }

    reflow(props = {}) {
        this.value = props.value || this.value;
        this.onChange = props.onChange || this.onChange;
    }

    getValue() {
        if (
            this.value === OPTION_DAY
            || this.value === OPTION_WEEK
            || this.value === OPTION_MONTH
            || this.value === OPTION_YEAR
        ) {
            return this.value;
        }
        return OPTION_DAY;
    }

    _onChange(e) {
        this.value = e.currentTarget.value;
        if (typeof this.onChange === 'function') {
            this.onChange(e);
        }
    }

    render() {
        const options = [
            {
                value: OPTION_DAY,
                text: Constants.STRINGS.DAY
            },
            {
                value: OPTION_WEEK,
                text: Constants.STRINGS.WEEK
            },
            {
                value: OPTION_MONTH,
                text: Constants.STRINGS.MONTH
            },
            {
                value: OPTION_YEAR,
                text: Constants.STRINGS.YEAR
            }
        ];

        return html`
<div class="vao__components--budgetRangeSelect">
    <vao-select .value="${this.value}" .options="${options}" @change="${this._onChange}">
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.BudgetForecastRangeSelect = BudgetForecastRangeSelect;
customElements.define('vao-budgetforecast-range-select', BudgetForecastRangeSelect);
