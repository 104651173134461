import { AppElement, html } from '../AppElement.js';

export default class Chip extends AppElement {
    static get properties() {
        return {
            label: { type: String },
            clickable: { type : Boolean },
            endIconCls: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.label = props.label || '';
        this.clickable = props.clickable || false;
        this.endIconCls = props.endIconCls || null;
        this.color = props.color || '';
        this.onClick = props.onClick || (() => {});
        this.compClass="vao__components--chip";
        this.id = AppElement.getUniqueElementId();
    }

    reflow(props = {}) {
        this.label = props.label || this.label;
        this.clickable = ('clickable' in props) ? props.clickable : this.clickable;
        this.endIconCls = props.endIconCls || this.endIconCls;
        this.color = props.color || this.color;
        this.onClick = props.onClick || this.onClick;
    }

    _onClick(e) {
        if (!this.clickable || typeof this.onClick !== 'function') {
            return;
        }
        this.onClick(e);
    }

    makeColorCls() {
        if (this.color && typeof this.color === 'string' && this.color.length > 0) {
            return 'chip-' + this.color;
        }
        return '';
    }

    makeCls() {
        return `
        ${this.clickable === true ? 'chip-clickable': ''}
        `;
    }

    render() {
        return html`
<div class="${this.compClass} ${this.makeCls()}" @click="${this._onClick}">
    <span class="${this.compClass + '-label'}">${this.label}</span>
    ${(this.endIconCls) 
        ? html`
    <div class="${this.compClass + '-endIcon'}">
        <vao-icon .cls="${this.endIconCls}"></vao-icon>
    </div>` 
        : html``}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Chip = Chip;
customElements.define('vao-chip', Chip);
