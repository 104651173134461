import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';
import {mapRatePacePerSetRank} from '../StoryPitchMappings';

export default class StoryMyPricingSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.rateLeadSiblingPace
                ]
            }),
            (data, procData) => {
                let rateLeadSiblingPace =
                    ((procData.rateLeadSiblingPace || {})[this.stayDate] || {}).value || null;

                if (
                    rateLeadSiblingPace
                    && typeof rateLeadSiblingPace === 'object'
                    && 'T0' in rateLeadSiblingPace
                    && 'setDenseRank' in rateLeadSiblingPace.T0
                    && 'setDenseLength' in rateLeadSiblingPace.T0
                    && $.isNumeric(rateLeadSiblingPace.T0.setDenseRank)
                ) {
                    let setRank = parseInt(rateLeadSiblingPace.T0.setDenseRank);
                    let setLength = rateLeadSiblingPace.T0.setDenseLength;
                    let mapVal = window.infinito.vao.controller.analyseAttributesHelper
                        .scaleRankMapForUniqueLength(
                            mapRatePacePerSetRank,
                            setLength
                        )[setRank] || null;
                    let pnn = window.infinito.vao.controller.analyseAttributesHelper.getMyPricePNNForSetRank(
                        setRank,
                        setLength
                    );
                    if (mapVal) {
                        this.subtitle = mapVal;
                        if (pnn === window.infinito.vao.controller.analyseAttributesHelper.positive) {
                            this.badgeClass = Constants.BADGES.CLASSES.SUCCESS;
                        } else if (pnn === window.infinito.vao.controller.analyseAttributesHelper.negative) {
                            this.badgeClass = Constants.BADGES.CLASSES.DANGER;
                        } else {
                            this.badgeClass = Constants.BADGES.CLASSES.PRIMARY;
                        }
                    } else {
                        this.subtitle = Constants.MISSING_STR;
                    }
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="${'vao__components--' + this.constructor.name}">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryMyPricingSubtitle = StoryMyPricingSubtitle;
customElements.define('vao-story-my-pricing-subtitle', StoryMyPricingSubtitle);
