import { AppElement, html } from '../AppElement.js';
import Constants from "../Constants";

export default class NumericVarianceBlock extends AppElement {
    static get properties() {
        return {
            numericValue: { type: String },
            formattedValue: { type : String },
            emptyColorClass: { type: String },
            beEmptyOnEmpty: { type: String }, // "true"
            tooltip: { type: String },
            tooltipTrigger: { type: String }
        };
    }

    constructor(props = {}) {
        super();
        this.numericValue = props.numericValue || null;
        this.formattedValue = props.formattedValue || null;
        this.emptyColorClass = props.emptyColorClass || '';
        this.beEmptyOnEmpty = props.beEmptyOnEmpty;
        this.tooltip = props.tooltip;
        this.tooltipTrigger = props.tooltipTrigger;
        this.id = AppElement.getUniqueElementId();
    }

    addTooltip() {
        this.removeTooltip();
        if (this.tooltip !== null && typeof this.tooltip === 'string' && this.tooltip.length > 0) {
            $('#' + this.id + ' .vao__components--numericVarianceBlock').tooltip({
                placement: 'bottom',
                title: this.tooltip,
                trigger: this.tooltipTrigger || Constants.TOOLTIP.TRIGGERS.MANUAL
            });
        }
    }

    removeTooltip() {
        $('#' + this.id + ' .vao__components--numericVarianceBlock').tooltip('dispose');
    }

    connectedCallback() {
        super.connectedCallback();
        this.addTooltip();
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.addTooltip();
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        this.addTooltip();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeTooltip();
    }

    isEmpty() {
        return !(this.numericValue < 0 || this.numericValue > 0);
    }

    makeColorClass() {
        if (this.numericValue < 0) {
            return 'text-danger';
        } else if (this.numericValue > 0) {
            return 'text-success';
        } else {
            return this.emptyColorClass;
        }
    }

    renderCaret() {
        if (this.numericValue < 0) {
            return html`<vao-icon cls="${Constants.ICONS.CARET_DOWN}" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>`;
        } else if (this.numericValue > 0) {
            return html`<vao-icon cls="${Constants.ICONS.CARET_UP}" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>`;
        } else {
            return html``;
        }
    }

    renderValue() {
        if (typeof this.formattedValue === 'string' && this.formattedValue.length > 0) {
            return html`<div class="vao__components--numericVarianceBlock-val">${this.formattedValue}</div>`;
        } else {
            return html`<div class="vao__components--numericVarianceBlock-val">${Constants.MISSING_STR}</div>`;
        }
    }

    render() {
        if (this.isEmpty() && String(this.beEmptyOnEmpty) === 'true') {
            return html``;
        }

        return html`
<div class="vao__components--numericVarianceBlock ${this.makeColorClass()}" style="display:flex;align-items:center;">
    ${this.renderCaret()}
    ${this.renderValue()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.NumericVarianceBlock = NumericVarianceBlock;
customElements.define('vao-numeric-variance-block', NumericVarianceBlock);
