import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class MiniContext extends AppElement {
    static get properties() {
        return {
            recordDate: { type: String },
            stayDate: { type: String },
            pickupSetting: { type: String },
            isModalStyle: { type: Boolean }
        };
    }

    constructor(props = {}) {
        super();
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate;
        this.pickupSetting = props.pickupSetting;
        this.isModalStyle = (props.isModalStyle === false) ? false : true;
    }

    render() {
        return html`
<div class="vao__components--miniContext ${this.isModalStyle ? 'modal-styled' : ''}">
    <div class="vao__components--miniContext-recordDate">
        <i class="${Constants.ICONS.RECORD_DATE}"></i>
        <span class="val">${this.recordDate}</span>
    </div>
    ${this.stayDate && html`
        <div class="vao__components--miniContext-stayDate">
            <i class="${Constants.ICONS.STAY_DATE}"></i>
            <span class="val">${this.stayDate}</span>
        </div>`
    }
    ${this.pickupSetting && html`
        <div class="vao__components--miniContext-pickupSetting">
            <i class="${Constants.ICONS.PICKUP}"></i>
            <span class="val">${this.pickupSetting}</span>
        </div>`
    }
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.MiniContext = MiniContext;
customElements.define('vao-mini-context', MiniContext);
