import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';
const data = require('../new_template.json');
const data2 = require('../template2.json');
const dataocc = require('../template_occ.json');
const data3 = require('../template3.json');
const datamypace = require('../template_mypace.json');
const data_compavg = require('../template_compavg.json');
const data_expected_pickup = require('../template_expected_pickup.json');
const data_leadtime = require('../template_leadtime.json');
const data_my_price_rate = require('../template_my_price_rate.json');
const data_positioning = require('../template_positioning.json');
const data_pricerank = require('../template_pricerank.json');
const data_forecast = require('../template_forecast.json');

// console.log(data);

export default class EditTemplate extends AppElement {

    static get properties() {
        return {
            hotel_id: {type: Object},
            templatedata : [],
            operatorbox: {},
            metricbox: {},
            valuebox: {},
            textbox: {},
            bridgebox: {},
            periodbox: {},
            conditionbox: {},
            initialcount: {type: Number},
            counter: {type: Number},
            schedule_days_obj: {},
            
            editOperatorval: {},
            editMetricval: {},
            editTextval: {},
            editValueval: {},
            editOccval: {},
            editSelectbox: {},            
            editSelectboxPriceRank: {},
            editSelectboxMyPriceRate: {},
            editSelectboxPace: {},
            editSelectForecast: {},
            editBridgebox: {},
            editPeriodbox: {},
            editConditionbox: {},

            filterarr: {},
            expanded: {}
        };
    }

    constructor(props = {}) {
        super();
        this.hotel_id = infinito.vao.controller.storageHelper.getSelectedHotelId();
        this.templatedata = [];
        this.operatorbox = '';
        this.metricbox = '';
        this.valuebox = '';
        this.textbox = '';
        this.bridgebox = '';
        this.periodbox = '';
        this.conditionbox = '';
        this.initialcount = '';
        this.counter = '';
        this.schedule_days_obj = {};
        
        this.editOperatorval = '',
        this.editMetricval = '',
        this.editTextval = '';
        this.editValueval = '';
        this.editOccval = '';
        this.editSelectbox = '';
        this.editSelectboxPriceRank = '';
        this.editSelectboxMyPriceRate = '';
        this.editSelectboxPace = '';
        this.editSelectForecast = '';
        this.editBridgebox = '';
        this.editPeriodbox = '';
        this.editConditionbox = '';

        this.filterarr = [];
        this.expanded = false;
    }    
    
    articleTemplate() {
        return html`
        <div class="table-responsive div1" id="tr_${this.initialcount}">
        <table id="table_operator" class="table" style="width:100%; text-align: center">
        <tbody>
        <tr>
        <td style="vertical-align: inherit;width: 15%;">Show me days where</td>
        <td style="width: 13%;">
            <select id="operator_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._operatorHandler}" class="form-control selectbox opblink"></select>
        </td>
        <td style="width: 15%;">
            <select id="metric_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._metricHandler}" class="form-control selectbox" style="display:none;">
                
            </select>
        </td>       
        <td id="display_textbox_${this.initialcount}" style="min-width:11%">
            <input type="text" id="text_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%" placeholder="Value" />
                       
            <div class="input-group selectbox" style="min-width:11%">
                <input type="text" id="occ_text_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control" placeholder="Value" aria-label="Value" aria-describedby="basic-addon2" style="display:none; border:none; font-size: 14px; color: #2196f3;">
                <div class="input-group-append">
                    <span id="span_text_box_${this.initialcount}" class="input-group-text" id="basic-addon2" style="display:none; background: none; border: none">%</span>
                </div>
            </div>

            <select id="select_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                
            </select>
            <select id="select_box_pricerank_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                
            </select>
            <select id="select_box_myprice_rate_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                
            </select>
            <select id="select_box_pace_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                
            </select>
            <select id="select_forecast_${this.initialcount}" key="${this.initialcount}" @focus="${this._textHandler}" class="form-control selectbox" style="display:none; min-width:11%">
                
            </select>            
        </td>
        <td style="min-width: 13%;" id="display_valuebox_${this.initialcount}">
            <select id="value_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._valueHandler}" class="form-control selectbox" style="display:none;">
                
            </select>
        </td>
        <td style="width: 7%;" id="display_bridgebox_${this.initialcount}">
            <select id="bridge_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._bridgeHandler}" class="form-control selectbox" style="display:none;">
                
            </select>
        </td>
        <td style="width: 11%;" id="display_periodbox_${this.initialcount}">
            <select id="period_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._periodHandler}" class="form-control selectbox" style="display:none;">
                
            </select>
        </td>
        <td style="width: fit-content; display: flex;">
            <select id="condition_box_${this.initialcount}" key="${this.initialcount}" @focus="${this._conditionHandler}" class="form-control selectbox conditionbox" style="display:none;">
                <option value="">-</option> 
                <option value="and">AND</option>
                <option value="or">OR</option>
            </select>
            <button type="button" id="addnewtrbtn_${this.initialcount}" key="${this.initialcount}" @click="${this.addnewtr}" class="btn btn-success" style="vertical-align: text-top;border-radius:50px;display:none">
                <i class="fa fa-plus"></i>
            </button> &nbsp;
            <button type="button" id="removenewtrbtn_${this.initialcount}" key="${this.initialcount}" @click="${this.removenewtr}" class="btn btn-danger" style="vertical-align: text-top;border-radius:50px;display:none">
                <i class="fa fa-minus"></i>
            </button>
        </td>
        </tr>
        </tbody>
        </table>
        </div>`;
    }    

    firstUpdated(){
        
        let dataObj = {
            'notify_id': $('#notify_id').val(),            
            'op': 'EditTemplatedata',
            'serviceName': "notifymangement.php"
        };
        let flag = this;
        sendRequest(dataObj, function (res) {
                                
            var jsonObj = JSON.parse(res);
            // console.log(jsonObj); 
            
            // console.log(jsonObj['data']['filters'].length);             
            // console.log(jsonObj['data']['filters'].length + 1);             

            flag.initialcount = jsonObj['data']['filters'].length;
            flag.counter = flag.initialcount + 1;

            flag.filterarr = jsonObj['data']['filters'];
                        
            $('#template_name').val(jsonObj['data']['template_name']);
            $('#email_subject').val(jsonObj['data']['email_subject']);

            if(jsonObj['data']['schedule_days']['mon_schedule'] == 1){
                $('#mon_schedule').prop('checked', true);
            }
            if(jsonObj['data']['schedule_days']['tue_schedule'] == 1){
                $('#tue_schedule').prop('checked', true);
            }
            if(jsonObj['data']['schedule_days']['wed_schedule'] == 1){
                $('#wed_schedule').prop('checked', true);
            }
            if(jsonObj['data']['schedule_days']['thu_schedule'] == 1){
                $('#thu_schedule').prop('checked', true);
            }
            if(jsonObj['data']['schedule_days']['fri_schedule'] == 1){
                $('#fri_schedule').prop('checked', true);
            }
            if(jsonObj['data']['schedule_days']['sat_schedule'] == 1){
                $('#sat_schedule').prop('checked', true);
            }
            if(jsonObj['data']['schedule_days']['sun_schedule'] == 1){
                $('#sun_schedule').prop('checked', true);
            }
            
            $('#schedule_time').val(jsonObj['data']['schedule_time']);

            for(var i=0;i<jsonObj['data']['filters'].length;i++){                                

                let operator_box = "operator_box_" + i;
                let metric_box = "metric_box_" + i;
                let text_box = "text_box_" + i;
                let value_box = "value_box_" + i;
                let occ_box = "occ_text_box_" + i;
                let select_box = "select_box_" + i;
                let select_box_pricerank = "select_box_pricerank_" + i;
                let select_box_myprice_rate = "select_box_myprice_rate_" + i;
                let select_box_pace = "select_box_pace_" + i;
                let select_forecast = "select_forecast_" + i;                

                let bridge_box = "bridge_box_" + i;
                let period_box = "period_box_" + i;
                let condition_box = "condition_box_" + i;

                flag.editOperatorval = jsonObj['data']['filters'][i][operator_box];                
                flag.editMetricval = jsonObj['data']['filters'][i][metric_box];                
                
                let textval = "text_box_" + i;
                if(textval in jsonObj['data']['filters'][i]){
                    flag.editTextval = jsonObj['data']['filters'][i][text_box];
                }else{
                    flag.editTextval = "";
                }              

                // console.log(flag.editTextval);
                    
                let valueval = "value_box_" + i;
                if(valueval in jsonObj['data']['filters'][i]){
                    flag.editValueval = jsonObj['data']['filters'][i][value_box];
                }else{
                    flag.editValueval = "";
                }

                // console.log(flag.editValueval);

                let occval = "occ_text_box_" + i;
                if(occval in jsonObj['data']['filters'][i]){
                    flag.editOccval = jsonObj['data']['filters'][i][occ_box];
                }else{
                    flag.editOccval = "";
                }

                // console.log(flag.editOccval);
                
                let selectboxpos = "select_box_" + i;
                if(selectboxpos in jsonObj['data']['filters'][i]){
                    flag.editSelectbox = jsonObj['data']['filters'][i][select_box];
                }else{
                    flag.editSelectbox = "";
                }

                // console.log(flag.editSelectbox);

                let selectboxpricerank = "select_box_pricerank_" + i;
                if(selectboxpricerank in jsonObj['data']['filters'][i]){
                    flag.editSelectboxPriceRank = jsonObj['data']['filters'][i][select_box_pricerank];
                }else{
                    flag.editSelectboxPriceRank = "";
                }                             

                // console.log(flag.editSelectboxPriceRank);

                let selectboxMyPriceRate = "select_box_myprice_rate_" + i;
                if(selectboxMyPriceRate in jsonObj['data']['filters'][i]){
                    flag.editSelectboxMyPriceRate = jsonObj['data']['filters'][i][select_box_myprice_rate];
                }else{
                    flag.editSelectboxMyPriceRate = "";
                }                

                // console.log(flag.editSelectboxMyPriceRate);

                let selectboxpace = "select_box_pace_" + i;
                if(selectboxpace in jsonObj['data']['filters'][i]){
                    flag.editSelectboxPace = jsonObj['data']['filters'][i][select_box_pace];
                }else{
                    flag.editSelectboxPace = "";
                } 

                // console.log(flag.editSelectboxPace);
                
                let selectforecast = "select_forecast_" + i;
                if(selectforecast in jsonObj['data']['filters'][i]){
                    flag.editSelectForecast = jsonObj['data']['filters'][i][select_forecast];
                }else{
                    flag.editSelectForecast = "";
                }

                // console.log(flag.editSelectForecast);
                
                let selectbridge = "bridge_box_" + i;
                if(selectbridge in jsonObj['data']['filters'][i]){
                    flag.editBridgebox = jsonObj['data']['filters'][i][bridge_box];
                }else{
                    flag.editBridgebox = "";
                }               

                // console.log(flag.editBridgebox);

                let selectperiod = "period_box_" + i;
                if(selectperiod in jsonObj['data']['filters'][i]){
                    flag.editPeriodbox = jsonObj['data']['filters'][i][period_box];
                }else{
                    flag.editPeriodbox = "";
                }

                // console.log(flag.editPeriodbox);

                // flag.editConditionbox = jsonObj['data']['filters'][i][condition_box];
                let selectcondition = "condition_box_" + i;   
                if(selectcondition in jsonObj['data']['filters'][i]){
                    flag.editConditionbox = jsonObj['data']['filters'][i][condition_box];
                }else{
                    flag.editConditionbox = "";
                }               

                
                $('#appenddiv').append('<div class="removediv_'+[i]+'"><div id="editdiv_'+[i]+'" style="width: 100%; margin:15px; text-transform: capitalize; text-indent: 5px;">'+ 
                    flag.editOperatorval + '&nbsp;' + 
                    flag.editMetricval  + '&nbsp;' + 
                    flag.editTextval  + '&nbsp;' + 
                    flag.editValueval  + '&nbsp;' + 
                    flag.editOccval  + '&nbsp;' +   
                    flag.editSelectbox  + '&nbsp;' + 
                    flag.editSelectboxPriceRank  + '&nbsp;' + 
                    flag.editSelectboxMyPriceRate  + '&nbsp;' + 
                    flag.editSelectboxPace  + '&nbsp;' + 
                    flag.editSelectForecast  + '&nbsp;' + 
                    flag.editBridgebox  + '&nbsp;' + 
                    flag.editPeriodbox  + '&nbsp;' + 
                    '<span style="color: rgb(0, 128, 0);font-weight: bold; text-transform: uppercase;">' + flag.editConditionbox + '</span>' + 
                    '<button type="button" id="'+ i +'" class="btn btn-danger removeedit" style="vertical-align: text-top;border-radius:50px;font-size: 10px;padding: 5px 8px;margin-left: 10px; margin-top: -5px;"><i class="fa fa-minus"></i></button>' +
                    '</div></div>'); 
                
                // $("#editdiv_" + i).replaceAll('_', ' '); 

            }
            
            /********Hotle Select Box********/

            infinito.vao.model.ssoMe.fetchMe(function(data){

                let hoteldataObj = {
                    'email': data.data.email,
                    'op': 'getHotelsdata',
                    'serviceName': "notifymangement.php"
                };

                sendRequest(hoteldataObj, function (res) {
                                    
                    var jsonhotelsObj = JSON.parse(res);
                    // console.log(jsonhotelsObj);                        
            
                    let allhtml = `
                    <label for="one"><input type="checkbox" class="scheduledays hotelcheck" style="vertical-align: bottom;" id="" value="" /> All</label>
                    `;

                    $('#checkboxes_multi').append(allhtml);
                    
                    for(var i=0;i<jsonhotelsObj['data'].length;i++){                                                                                                       
    
                        let html = `
                            <label for="one"><input type="checkbox" class="scheduledays hotelchecknot" id="checkboxhotels_`+i+`" style="vertical-align: bottom;" value="`+jsonhotelsObj['data'][i]['Hotels_id']+`" /> ` + jsonhotelsObj['data'][i]['name'] + `</label>
                        `;
    
                        $('#checkboxes_multi').append(html);                    
                     
                        for(var j=0;j<jsonObj['data']['hotels'].length;j++){                                                                                                                                                                      
    
                            // console.log('inside 1');

                            for(var k=0;k<jsonhotelsObj['data'].length;k++){

                                // console.log('inside 2');
                                // console.log(jsonObj['data']['hotels'][j][k]);
                                // console.log(jsonhotelsObj['data'][i]['Hotels_id']);

                                if(jsonObj['data']['hotels'][j][k] == jsonhotelsObj['data'][i]['Hotels_id']){
                                    // console.log('inside 3');
                                    var valc = '#checkboxhotels_'+i;
                                    $(valc).prop('checked', true);  
                                }else{
                                    // console.log('no');
                                }

                            }                            
                            
                        }

                    }                                        
                                            
                });

            });                                    

            /****************/

            // console.log(flag.filterarr); 

            $('.removeedit').click(function(){
            
                // console.log(this.id);                
                
                flag.filterarr.splice(this.id, 1);
                
                console.log(flag.filterarr);                                

                $('#editdiv_' + this.id).html('');   
                
                // console.log(flag.filterarr.length);             
                // console.log(flag.filterarr.length - 1);             

                flag.initialcount = flag.filterarr.length;
                flag.counter = flag.initialcount - 1;

            });                                    
                                    
        });         
        
    }

    render() {

        return html`
            <div style="width: auto;">
                <style>
                    .modal{
                        padding-left: 0px !important;
                    }
                    .modal-xl{
                        max-width: 1200px !important;
                        min-width: 1200px !important;
                    }                    
                    #no_schedule_display .col-sm-2 {                        
                        max-width: 14% !important; 
                    }
                    input,
                    input::placeholder {
                        font-size:14px;
                    }
                    .selectbox{
                        border: none;
                        // border-bottom: 2px solid #bdbdbd;
                        border-radius: 0px;
                        -webkit-appearance: none;
                        font-size: 14px;
                        color: #2196f3;                        
                    }
                    .opblink{
                        animation: blink 0.7s;
                        animation-iteration-count: infinite;
                    }
                    @keyframes blink { 50% { border-bottom: 2px solid #bdbdbd; border-color:#2196f3; }  }                                        
                    .table{
                        margin-bottom: 0px;
                    }
                    .table td, .table th{
                        border: none;
                    }                    
                    .form-control:focus {
                        border-color: #ffffff;
                        box-shadow: none;
                      }                    
                    .selectbox:hover{
                        border-bottom: 2px solid #2196f3;
                    }                                       

                    .schedulelabel{
                        display: flex;
                    }
                    .scheduledays{
                        width: 20px;
                        height: 20px;
                    }
                    .flex{
                        display: flex;
                    }
                    input[type="checkbox"], select {
                        cursor: pointer;
                    }
                    select option[value="none"] {
                        color: #999999 !important;
                    }
                    input{
                        padding-left:0px;
                    }
                    select{
                        padding-left:0px;
                    }
                    .spanhtml{
                        margin-right: 10px;
                    }

                    .selectmulti {
                        position: relative;
                        cursor: pointer;
                    }
                    
                    .selectmulti select {
                        width: 100%;
                        font-weight: bold;
                    }
                    
                    .overSelect_multi {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                    
                    #checkboxes_multi {
                        display: none;
                        border: 1px #dadada solid;
                    }
                    
                    #checkboxes_multi label {
                        display: block;
                    }
                    
                    #checkboxes_multi label:hover {
                        
                    }

                    .selectmulti select {
                        font-weight: 100;
                    }
                </style>
                <!-------------------------- Add New Template Starts --------------------------->

                <div class="row mb-2">
                    <div class="col-sm-4 mb-2"> Template Name &nbsp;
                        <input type="text" class="form-control" id="template_name" name="template_name" placeholder="Template Name" style="border: 1px solid #ced4da;" />
                    </div>
                    <div class="col-sm-8"> Email Subject &nbsp;
                    <input type="text" class="form-control" id="email_subject" name="email_subject" placeholder="Enter Email Subject" style="border: 1px solid #ced4da;" />
                    </div>                   
                </div>

                <div class="row mt-3 mb-2">                    
                    <div class="col-sm-12">
                        <div>Schedule</div> 
                        <div style="padding: 10px; border:solid 1px #ced4da;border-radius:4px">                            
                            <div class="flex">
                                <input type="checkbox" name="no_schedule" id="no_schedule" @click="${this.displaySchedule}" style="width: 20px;height: 20px;"> &nbsp; No Schedule                          
                            </div>                            
                            <div class="row mt-4" id="no_schedule_display" style="margin-top: 12px;">                                
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="mon_schedule" id="mon_schedule"> &nbsp; Monday                                    
                                </div>
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="tue_schedule" id="tue_schedule"> &nbsp; Tuesday                                    
                                </div>
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="wed_schedule" id="wed_schedule"> &nbsp; Wednesday                                    
                                </div>
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="thu_schedule" id="thu_schedule"> &nbsp; Thursday                                    
                                </div>
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="fri_schedule" id="fri_schedule">  &nbsp; Friday                                    
                                </div>
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="sat_schedule" id="sat_schedule"> &nbsp; Saturday                                    
                                </div>
                                <div class="col-sm-2 flex">
                                    <input type="checkbox" class="scheduledays" name="sun_schedule" id="sun_schedule"> &nbsp; Sunday                                    
                                </div>
                                <div class="col-sm-4" style="margin-top: 20px;">
                                    Time: <input type="time" class="form-control" name="schedule_time" id="schedule_time" style="cursor:pointer; border: 1px solid #ced4da;" />
                                </div>
                                <div class="col-sm-3" style="margin-top: 20px;">
                                    Hotels:
                                    <div class="multiselect">
                                        <div class="selectmulti" @click="${this.showCheckboxes}">
                                        <select class="form-control" style="width: 300px; font-size: 14px">
                                            <option>Select</option>
                                        </select>
                                        <div class="overSelect_multi"></div>
                                        </div>
                                        <div id="checkboxes_multi" style="position: absolute;background: #fff;width: 300px;height: 120px;overflow-y: auto; padding: 10px 15px;">
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>

                <div style="border: 1px solid #ced4da; padding:5px; border-radius:4px" class="mb-2 mt-3">  
                    <div style="font-weight: bold; text-indent: 18px; margin-top: 10px;">Selected Filters</div>
                    <div id="appenddiv"></div>
                    <div style="font-weight: bold; text-indent: 18px; margin-top: 10px;">Add New Filters</div>                  
                    ${this.articleTemplate()}                                                            
                </div>                                    
                
                <div class="row" style="display: block; margin: auto; text-align: center;">
                    <button type="button" class="btn btn-md btn-primary btn-class" id="savedata" @click="${this.savedata}">Save</button>
                    <button type="button" class="btn btn-md btn-danger btn-class" class="close" data-dismiss="modal" aria-label="Close">Cancel</button>
                </div>

                <!-------------------------- Add New Template Ends --------------------------->
                
            </div>`;
    }        

    showCheckboxes(e) {
        var checkboxes = document.getElementById("checkboxes_multi");
        if (!this.expanded) {
            checkboxes.style.display = "block";
            this.expanded = true;
        } else {
            checkboxes.style.display = "none";
            this.expanded = false;
        }

        $('.hotelcheck').on('click', function() {
            $('.hotelchecknot').not(this).prop('checked', this.checked);
        });
    }

    _operatorHandler(e) {              

        // console.log(e.target.getAttribute('key'));

        // $.each(data, function(k, v) {
        //     console.log(k);
        // });

        let d = e.target.getAttribute('key');
        let dropdown = $('#operator_box_' + d);                
                               
        let dropdown2 = $('#metric_box_' + d);
        $(dropdown2).addClass('opblink');

        let dropdown3 = $('#value_box_' + d);
        let dropdown4 = $('#text_box_' + d);
        let dropdown5 = $('#bridge_box_' + d);
        let dropdown6 = $('#period_box_' + d);
        dropdown.empty();
        dropdown2.empty();
        dropdown3.empty();
        dropdown4.empty();
        dropdown5.empty();
        dropdown6.empty();
        // dropdown.append('<option selected="true" disabled>Please Select</option>');
        dropdown.prop('selectedIndex', 0);

        // Populate dropdown with list of operators                
        for(var i in data){
            // console.log(i);
            dropdown.append($('<option></option>').attr('value', i).text(data [i]['display']));
        }

        $("#metric_box_" + d).css("display","block");

        $('#operator_box_' + d).on('change', function() {

            var operator_val = $("#operator_box_" + d).val();

            var len = $(this).val().length;
            if(operator_val == 'adr'){
                $(this).parent(0).css("width", '6%');                                
            }else if(len > 14){
                $(this).parent(0).css("width", (len - 3) + '%');                                
            }else{
                $(this).parent(0).css("width", len + 1 + '%');
            }            
            // $(this).parent(0).css("width", len + 1 + '%');                                
        });

    }

    _metricHandler(e) {
        this.metricbox = e.target.textContent;

        let d = e.target.getAttribute('key');                          

        $('#metric_box_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("min-width", len + 2 + '%');                                
            $(this).parent(0).css("width", len + '%');   

            var operator_val = $("#operator_box_" + d).val();        
            var metric_val = $("#metric_box_" + d).val();

            $("#display_textbox_" + d).css("display","table-cell");
            $("#text_box_" + d).css("display","table-cell");
            $("#occ_text_box_" + d).css("display","none");
            $("#span_text_box_" + d).css("display","none");
            $("#select_box_" + d).css("display","none");
            $("#select_box_pricerank_" + d).css("display","none");
            $("#select_box_myprice_rate_" + d).css("display","none");
            $("#select_box_pace_" + d).css("display","none");
            $("#select_forecast_" + d).css("display","none");        
                        
            if( operator_val == "rms_to_sell" || operator_val == "rms_sold" || operator_val == "adr" || operator_val == "revenue" || operator_val == "occupancy" || operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "comp_average")
            {
                $("#bridge_box_" + d).css("display","table-cell");                 
            }             
            
            if(operator_val == "occupancy"){
                if(metric_val == "is_equal_to" || metric_val == "is_greater_than" || metric_val == "is_less_than"){
                    $("#text_box_" + d).css("display","none");
                    $("#occ_text_box_" + d).css("display","table-cell");
                    $("#span_text_box_" + d).css("display","table-cell");
                }else{
                    $("#occ_text_box_" + d).css("display","none");
                    $("#span_text_box_" + d).css("display","none");
                }
            }else{                
                    $("#occ_text_box_" + d).css("display","none");       
                    $("#span_text_box_" + d).css("display","none");         
            }

            if(operator_val == "my_pace"){                
                if(metric_val == "is_ahead_by_more_than" || metric_val == "is_behind_by_more_than"){
                    $("#text_box_" + d).css("display","table-cell");
                }   
                if(metric_val == "has_improved" || metric_val == "has_fallen_behind" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + d).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');   
                }                             
            }

            if(operator_val == "positioning" || operator_val == "price_rank"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + d).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');  
                }                                            
            }    
            
            if(operator_val == "expected_pick_up"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){                    
                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell"); 
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");                    
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');  
                }else{
                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","table-cell");
                }                                                      
            }

            if(operator_val == "pick_up_trend"){                                    
                
                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");
                let condition_box = $('#condition_box_' + d);
                $(condition_box).addClass('opblink');                                                                            

            }

            if(operator_val == "forecast_range"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","none");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + d);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');

                }

            }

            if(operator_val == "my_price_or_rate"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");
                    $("#value_box_" + d).css("display","none");
                    $("#select_box_" + d).css("display","none");                
                    $("#select_box_myprice_rate_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + d);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');

                }

            }            

            if(metric_val=="has_no_Change" || operator_val == "my_pace" || operator_val == "expected_pick_up" || operator_val == "positioning" || operator_val == "price_rank"){
                /********* Bridge *****/
                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + d);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);

                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + d);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + d);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + d).css("display","block");
                /********* Bridge *****/
            }
            
        }); 
        
        var operator_val = $("#operator_box_" + d).val();        
        if(operator_val == "rms_to_sell"){
            var parameter = data.rms_to_sell.metric;                      
        }else if(operator_val == "rms_sold") {
            var parameter = data.rms_sold.metric;
        }else if(operator_val == "adr") {
            var parameter = data.adr.metric;
        }else if(operator_val == "revenue") {
            var parameter = data.revenue.metric;
        }else if(operator_val == "occupancy") {
            var parameter = data.occupancy.metric;
        }else if(operator_val == "rooms_pick_up") {
            var parameter = data.rooms_pick_up.metric;
        }else if(operator_val == "adr_pick_up") {
            var parameter = data.adr_pick_up.metric;
        }else if(operator_val == "revenue_pick_up") {
            var parameter = data.revenue_pick_up.metric;
        }else if(operator_val == "adr_move") {
            var parameter = data.adr_move.metric;
        }else if(operator_val == "my_pace") {

            var parameter = data.my_pace.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","block");
                $("#select_forecast_" + d).css("display","none");
            });

            $("#select_box_pace_" + d).prop('disabled', false);

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_pace_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "my_price_or_rate") {
            var parameter = data.my_price_or_rate.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","block");
                $("#select_box_pace_" + d).css("display","none");
                $("#select_forecast_" + d).css("display","none");
            });

            $("#select_box_myprice_rate_" + d).prop('disabled', false);

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_myprice_rate_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "comp_average") {
            var parameter = data.comp_average.metric;
        }else if(operator_val == "forecast_range") {
            var parameter = data.forecast_range.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");
                $("#select_forecast_" + d).css("display","block");
            });

            $("#select_forecast_" + d).prop('disabled', false);            

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_forecast_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "expected_pick_up") {
            var parameter = data.expected_pick_up.metric;
        }else if(operator_val == "positioning") {
            var parameter = data.positioning.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","block");
                $("#select_box_pricerank_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");
            });            

            $("#select_box_" + d).prop('disabled', false);  

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "price_rank") {
            var parameter = data.price_rank.metric;

            $('#metric_box_' + d).on('change', function() {
                $("#text_box_" + d).css("display","none");
                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","block");
                $("#select_box_myprice_rate_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");
                $("#select_forecast_" + d).css("display","none");
            });

            $("#select_box_pricerank_" + d).prop('disabled', false);  

            let dropdown_operator_box = $('#operator_box_' + d);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#select_box_pricerank_' + d);
            $(dropdown_valuebox).addClass('opblink');

        }else if(operator_val == "leadtime") {
            var parameter = data.leadtime.metric;
        }else if(operator_val == "pick_up_trend") {
            
            var parameter = data.pick_up_trend.metric;                                    

            let condition_box = $('#condition_box_' + d);
            $(condition_box).addClass('opblink');

            let dropdownval = $('#value_box_' + d);
            dropdownval.empty();
            dropdownval.prop('selectedIndex', 0);

            // Populate dropdownval with list of value
            dropdownval.append($('<option></option>').attr('value', '-').text('-'));            
            
        }

        let dropdown2 = $('#metric_box_' + d);
        let dropdown5 = $('#bridge_box_' + d);
        dropdown2.empty();
        dropdown5.empty();
        dropdown2.prop('selectedIndex', 0);

        // Populate dropdown2 with list of metric
        for(var i=0; i<parameter.length; i++){            
            dropdown2.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
        }
        
        let dropdown_operator_box = $('#operator_box_' + d); 
        $(dropdown_operator_box).removeClass('opblink');

        let dropdown_valuebox = $('#text_box_' + d);
        $(dropdown_valuebox).addClass('opblink');        

        $("#text_box_" + d).css("display","block");
              
    }
    
    _textHandler(e) {
        this.textbox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();                
        
        var regex = /^\d*[.]?\d*$/;

        $('#text_box_' + d).on("input", function() {

            var len = $(this).val().length;            
            $(this).parent(0).css("width", len + 3 +'%'); 

            var inputVal = $(this).val(); 

            if(regex.test(inputVal)){
                return true;
            } else{
                $(this).val('');
            }

        });

        $('#occ_text_box_' + d).on("input", function() {

            ($(this).parent(0)).parent(0).css("width", '11%');

            var inputVal = $(this).val(); 

            if(regex.test(inputVal)){

            } else{
                $(this).val('');
            }

        });

        if(operator_val == "rms_to_sell"){            
            if(metric_val == "is_equal_to"){

                var parameter = data2.is_equal_to.value;              

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;               
                
                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell"); 

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;    
                
                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                // $("#condition_box_" + d).css("display","block");
                
            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                // $("#condition_box_" + d).css("display","block");
            }
            if(metric_val == "has_no_Change"){

                var parameter = data2.has_no_Change.value;

                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }                        
            
        }else if(operator_val == "rms_sold") {
            if(metric_val == "is_equal_to"){
                
                var parameter = data2.is_equal_to.value;        

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell"); 

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell"); 

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = data2.has_no_Change.value;
                
                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "adr") {
            if(metric_val == "is_equal_to"){

                var parameter = data2.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = data2.has_no_Change.value;
                
                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "revenue") {
            if(metric_val == "is_equal_to"){
                
                var parameter = data2.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data2.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data2.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data2.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data2.has_decreased_by.value;
                
                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){

                var parameter = data2.has_no_Change.value;

                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "occupancy") {
            if(metric_val == "is_equal_to"){

                var parameter = dataocc.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#occ_text_box_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#occ_text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');                    
                });                               

            }            
            if(metric_val == "is_greater_than"){

                var parameter = dataocc.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#occ_text_box_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#occ_text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');                    
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = dataocc.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#occ_text_box_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#occ_text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');                    
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = dataocc.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = dataocc.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = dataocc.has_no_Change.value;
                
                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "rooms_pick_up") {
            
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
                
            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
            }
            if(metric_val == "is_less_than"){

                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "adr_pick_up") {
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            if(metric_val == "is_less_than"){

                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');
            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "revenue_pick_up") {
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            if(metric_val == "is_less_than"){

                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "adr_move") {
            if(metric_val == "is_equal_to"){

                var parameter = data3.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }            
            if(metric_val == "is_greater_than"){

                var parameter = data3.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            if(metric_val == "is_less_than"){
                
                var parameter = data3.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");    

                $("#bridge_box_" + d).css("display","table-cell");
                let bridge_box = $('#bridge_box_' + d);
                $(bridge_box).addClass('opblink');

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }else if(operator_val == "comp_average") {            
            if(metric_val == "is_equal_to"){

                var parameter = data_compavg.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){

                var parameter = data_compavg.is_higher_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_lower_than"){

                var parameter = data_compavg.is_lower_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data_compavg.has_increased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased_by"){

                var parameter = data_compavg.has_decreased_by.value;

                $("#value_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","table-cell");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameter = data_compavg.has_no_Change.value;

                $("#text_box_" + d).css("display","none");
                $("#value_box_" + d).css("display","table-cell");

                $("#display_textbox_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");                
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).addClass('opblink');
                
            }     
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }       
        }else if(operator_val == "expected_pick_up") {
            if(metric_val == "is_equal_to"){
                
                var parameter = data_expected_pickup.is_equal_to.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){
                
                var parameter = data_expected_pickup.is_higher_than.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_lower_than"){
                
                var parameter = data_expected_pickup.is_lower_than.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased"){
                
                var parameter = data_expected_pickup.has_increased.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased"){
                
                var parameter = data_expected_pickup.has_decreased.value;            
                
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){
                
                var parameterpickup = data_expected_pickup.has_no_Change.value;                

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameterpickup.length; i++){
                    // console.log(parameterpickup[i]['dev_name']);
                    // console.log(parameterpickup[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameterpickup[i]['dev_name']).text(parameterpickup[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }            
        }else if(operator_val == "leadtime") {            
            if(metric_val == "is_equal_to"){

                var parameter = data_leadtime.is_equal_to.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
                    
            }            
            if(metric_val == "is_greater_than"){

                var parameter = data_leadtime.is_greater_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_less_than"){

                var parameter = data_leadtime.is_less_than.value;

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            let dropdown3 = $('#value_box_' + d);
            dropdown3.empty();
            dropdown3.prop('selectedIndex', 0);

            // Populate dropdown3 with list of value
            for(var i=0; i<parameter.length; i++){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
        }           

        if(operator_val == "positioning") {
            if(metric_val == "is_equal_to"){
               
                var parameter_select_box_ = data_positioning.is_equal_to.value;

                let dropdownselect_box_ = $('#select_box_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }        
                
                /**********************************/
                var parameter_valuebox = data_positioning.is_equal_to.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){                
             
                var parameter_select_box_ = data_positioning.is_higher_than.value;
                
                let dropdownselect_box_ = $('#select_box_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                

                /**********************************/
                var parameter_valuebox = data_positioning.is_higher_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });


            }
            if(metric_val == "is_lower_than"){
                               
                var parameter_select_box_ = data_positioning.is_lower_than.value;
                
                let dropdownselect_box_ = $('#select_box_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_positioning.is_lower_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased"){

                var parameter = data_positioning.has_increased.value;                

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_decreased"){

                var parameter = data_positioning.has_decreased.value;
                
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
            if(metric_val == "has_no_Change"){

                var parameter = data_positioning.has_no_Change.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
        }

        if(operator_val == "price_rank") {
            if(metric_val == "is_equal_to"){
               
                var parameter_select_box_ = data_pricerank.is_equal_to.value;

                let dropdownselect_box_ = $('#select_box_pricerank_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }       
                
                /**********************************/
                var parameter_valuebox = data_pricerank.is_equal_to.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pricerank_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });


            }            
            if(metric_val == "is_higher_than"){                

                var parameter_select_box_ = data_pricerank.is_higher_than.value;
                
                let dropdownselect_box_ = $('#select_box_pricerank_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }     
                
                /**********************************/
                var parameter_valuebox = data_pricerank.is_higher_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pricerank_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });


            }
            if(metric_val == "is_lower_than"){
                               
                var parameter_select_box_ = data_pricerank.is_lower_than.value;
                
                let dropdownselect_box_ = $('#select_box_pricerank_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }               
                
                /**********************************/
                var parameter_valuebox = data_pricerank.is_lower_than.valuebox;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pricerank_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pricerank_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });                

            }
            if(metric_val == "has_increased"){

                var parameter = data_pricerank.has_increased.value;
                
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");
            }
            if(metric_val == "has_decreased"){

                var parameter = data_pricerank.has_decreased.value;
               
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");
            }
            if(metric_val == "has_no_Change"){

                var parameter = data_pricerank.has_no_Change.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");

            }
        }

        if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){

                var parameter_select_box_ = data_my_price_rate.is_equal_to.valuepricerate;

                let dropdownselect_box_ = $('#select_box_myprice_rate_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                                     
                
                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_myprice_rate_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_myprice_rate_' + d).on('change', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){                

                var parameter_select_box_ = data_my_price_rate.is_higher_than.valuepricerate;
                
                let dropdownselect_box_ = $('#select_box_myprice_rate_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                                    

                $('#select_box_myprice_rate_' + d).on('click', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");
                    $("#value_box_" + d).css("display","table-cell");

                    $("#select_box_" + d).css("display","none");
                    $("#select_box_myprice_rate_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                });

            }
            if(metric_val == "is_lower_than"){
                
                var parameter_select_box_ = data_my_price_rate.is_lower_than.valuepricerate;
                
                let dropdownselect_box_ = $('#select_box_myprice_rate_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                    
                
                $('#select_box_myprice_rate_' + d).on('click', function() {
                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#value_box_" + d).css("display","table-cell");
                    $("#select_box_myprice_rate_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                });

            }
            if(metric_val == "has_increased_by"){

                var parameter = data_my_price_rate.has_increased_by.value;
                
                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $('#select_box_myprice_rate_' + d).on('click', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","table-cell");
                    $("#value_box_" + d).css("display","table-cell");
                    $("#select_box_" + d).css("display","none");                
                    $("#select_box_myprice_rate_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");

                });
                
            }
            if(metric_val == "has_decreased_by"){

                var parameter = data_my_price_rate.has_decreased_by.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $('#select_box_myprice_rate_' + d).on('click', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","table-cell");
                    $("#value_box_" + d).css("display","table-cell");
                    $("#select_box_" + d).css("display","none");                
                    $("#select_box_myprice_rate_" + d).css("display","none");
                    $("#display_valuebox_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");

                });
            }
            if(metric_val == "has_no_Change"){

                var parameter = data_my_price_rate.has_no_Change.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');
            }
        }   

        if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){
              
                var parameter_select_box_ = datamypace.is_equal_to.valuepace;

                let dropdownselect_box_ = $('#select_box_pace_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }             
                
                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","none");

                $("#select_box_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","table-cell");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","none");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#select_box_pace_' + d).on('click', function() {
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_ahead_by_more_than"){                
                             
                var parameter_valuebox = datamypace.is_ahead_by_more_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                
                $("#select_box_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    $("#bridge_box_" + d).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });

            }
            if(metric_val == "is_behind_by_more_than"){                               
                                
                var parameter_valuebox = datamypace.is_behind_by_more_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }

                $("#display_textbox_" + d).css("display","table-cell");
                $("#text_box_" + d).css("display","table-cell");
                
                $("#select_box_" + d).css("display","none");
                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","none");
                $("#condition_box_" + d).css("display","table-cell");

                $('#text_box_' + d).on('click', function() {
                    $("#bridge_box_" + d).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });

            }
            if(metric_val == "has_improved"){
                
                var parameter = datamypace.has_improved.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");                

            }
            if(metric_val == "has_fallen_behind"){
               
                var parameter = datamypace.has_fallen_behind.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");
               
            }
            if(metric_val == "has_no_Change"){

                var parameter = datamypace.has_no_Change.value;

                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }

                $("#display_textbox_" + d).css("display","none");
                $("#text_box_" + d).css("display","none");

                $("#select_box_pace_" + d).css("display","none");

                $("#display_valuebox_" + d).css("display","none");
                $("#display_bridgebox_" + d).css("display","table-cell");
                $("#display_periodbox_" + d).css("display","table-cell");
                $("#condition_box_" + d).css("display","table-cell");               

            }
        }  
        
        if(operator_val == "forecast_range") {
            if(metric_val == "is_equal_to"){

                var parameter_select_box_ = data_forecast.is_equal_to.valueforecast;

                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }        
                
                /**********************************/
                var parameter_valuebox = data_forecast.is_equal_to.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/                

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }            
            if(metric_val == "is_higher_than"){                
               
                var parameter_select_box_ = data_forecast.is_higher_than.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }                

                /**********************************/
                var parameter_valuebox = data_forecast.is_higher_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "is_lower_than"){
                
                var parameter_select_box_ = data_forecast.is_lower_than.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_forecast.is_lower_than.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#display_bridgebox_" + d).css("display","none");
                    $("#display_periodbox_" + d).css("display","none");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });

            }
            if(metric_val == "has_increased"){
              
                var parameter_select_box_ = data_forecast.has_increased.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_forecast.has_increased.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });


            }
            if(metric_val == "has_decreased"){

                var parameter_select_box_ = data_forecast.has_decreased.valueforecast;
                
                let dropdownselect_box_ = $('#select_forecast_' + d);
                dropdownselect_box_.empty();
                dropdownselect_box_.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter_select_box_.length; i++){
                    // console.log(parameter_select_box_[i]['dev_name']);
                    // console.log(parameter_select_box_[i]['display']);
                    dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                }    
                
                /**********************************/
                var parameter_valuebox = data_forecast.has_decreased.value;

                let dropdown_parameter_valuebox = $('#value_box_' + d);
                dropdown_parameter_valuebox.empty();
                dropdown_parameter_valuebox.prop('selectedIndex', 0);

                // Populate dropdown_parameter_valuebox with list of value
                for(var i=0; i<parameter_valuebox.length; i++){
                    // console.log(parameter_valuebox[i]['dev_name']);
                    // console.log(parameter_valuebox[i]['display']);
                    dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                }
                /**********************************/

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","table-cell");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","table-cell");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + d);
                    $(bridge_box).addClass('opblink');
                });

            }
            if(metric_val == "has_no_Change"){
             
                var parameter = data_forecast.has_no_Change.value;

                /**********************************/
                let dropdown3 = $('#value_box_' + d);
                dropdown3.empty();
                dropdown3.prop('selectedIndex', 0);

                // Populate dropdown3 with list of value
                for(var i=0; i<parameter.length; i++){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                /**********************************/

                $("#bridge_box_" + d).css("display","block");

                let dropdown_operator_box = $('#metric_box_' + d);
                $(dropdown_operator_box).removeClass('opblink');
    
                let dropdown_valuebox = $('#bridge_box_' + d);
                $(dropdown_valuebox).addClass('opblink');

                $('#select_forecast_' + d).on('change', function() {

                    $("#display_textbox_" + d).css("display","none");
                    $("#text_box_" + d).css("display","none");

                    $("#select_box_" + d).css("display","none");
                    $("#select_forecast_" + d).css("display","none");

                    $("#display_valuebox_" + d).css("display","none");
                    $("#bridge_box_" + d).css("display","table-cell");
                    $("#display_bridgebox_" + d).css("display","table-cell");
                    $("#display_periodbox_" + d).css("display","table-cell");
                    $("#condition_box_" + d).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + d);
                    $(condition_box).addClass('opblink');
                });
            
            }
        }
        
        let dropdown_text_box = $('#metric_box_' + d);
        $(dropdown_text_box).removeClass('opblink');

        let dropdown_bridge_box = $('#value_box_' + d);
        $(dropdown_bridge_box).addClass('opblink');    
        
        $('#text_box_' + d).on('click', function() {

            if(operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "my_pace"){
                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + d);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);

                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + d);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + d);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + d);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + d).css("display","block");
            }

        });

        $('#select_forecast_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');

            if(operator_val == "forecast_range") {                
                if(metric_val == "has_increased"){
                    var parameter = data_forecast.has_increased.bridgeval;
                    let dropdown5 = $('#bridge_box_' + d);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + d);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + d);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + d);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + d).css("display","block");
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_forecast.has_decreased.bridgeval;
                    let dropdown5 = $('#bridge_box_' + d);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + d);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + d);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + d);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + d).css("display","block");
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_forecast.has_no_Change.bridgeval;
                    let dropdown5 = $('#bridge_box_' + d);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + d);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + d);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + d);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + d).css("display","block");
                }
                 
            }                                   

        });

        $('#select_box_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_pricerank_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_myprice_rate_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 4 + '%');                                

        });

        $('#select_box_pace_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');                                

        });

        $('#value_box_' + d).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });
                
    }    

    _valueHandler(e) {
        this.valuebox = e.target.textContent;

        let d = e.target.getAttribute('key');            
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();    
        var select_box_myprice_rate_val = $("#select_box_myprice_rate_" + d).val();    

        if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){             
                if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                    
                    /**********************************/
                    var parameter_valuepricerate = data_my_price_rate.is_equal_to.value;

                    let dropdown_parameter_valuepricerate = $('#value_box_' + d);
                    dropdown_parameter_valuepricerate.empty();
                    dropdown_parameter_valuepricerate.prop('selectedIndex', 0);

                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuepricerate.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuepricerate.append($('<option></option>').attr('value', parameter_valuepricerate[i]['dev_name']).text(parameter_valuepricerate[i]['display']));
                    }
                    /**********************************/
                }
            }            
            if(metric_val == "is_higher_than"){                
                if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                    
                    var parameter = data_my_price_rate.is_higher_than.value;

                    let dropdown3 = $('#value_box_' + d);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                                        
                }
            }
            if(metric_val == "is_lower_than"){                
                if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                    
                    var parameter = data_my_price_rate.is_higher_than.value;

                    let dropdown3 = $('#value_box_' + d);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);

                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }

                }

            }            
        }

        if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){             

                    /**********************************/
                    var parameter_valuepace = datamypace.is_equal_to.value;

                    let dropdown3 = $('#value_box_' + d);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);

                    // Populate parameter_valuepace with list of value
                    for(var i=0; i<parameter_valuepace.length; i++){
                        // console.log(parameter_valuepace[i]['dev_name']);
                        // console.log(parameter_valuepace[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter_valuepace[i]['dev_name']).text(parameter_valuepace[i]['display']));
                    }
                    /**********************************/                
            }
        }

        let dropdown3 = $('#bridge_box_' + d);
        dropdown3.empty();
        dropdown3.prop('selectedIndex', 0);        

        let dropdown_valuebox = $('#text_box_' + d);
        $(dropdown_valuebox).removeClass('opblink');

        let dropdown_text_box = $('#bridge_box_' + d);
        $(dropdown_text_box).addClass('opblink');

        $("#bridge_box_" + d).css("display","block");

        $('#value_box_' + d).on('change', function() {

            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }           
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){
                    var parameter = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){
                    var parameter = dataocc.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = dataocc.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = dataocc.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = dataocc.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = dataocc.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = dataocc.has_no_Change.bridgeval;
                }            
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){
                    var parameter = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
                    var parameter = datamypace.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_ahead_by_more_than"){
                    var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                }
                if(metric_val == "is_behind_by_more_than"){
                    var parameter = datamypace.is_behind_by_more_than.bridgeval;
                }
                if(metric_val == "has_improved"){
                    var parameter = datamypace.has_improved.bridgeval;
                }
                if(metric_val == "has_fallen_behind"){
                    var parameter = datamypace.has_fallen_behind.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = datamypace.has_no_Change.bridgeval;
                }
            }else if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_my_price_rate.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_my_price_rate.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_my_price_rate.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data_my_price_rate.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_compavg.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_compavg.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_compavg.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter = data_compavg.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter = data_compavg.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_compavg.has_no_Change.bridgeval;
                }
            }else if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){ 
                    var parameter = data_forecast.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_forecast.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_forecast.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_forecast.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_forecast.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_forecast.has_no_Change.bridgeval;
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_expected_pickup.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_expected_pickup.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_expected_pickup.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_expected_pickup.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_expected_pickup.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_expected_pickup.has_no_Change.bridgeval;
                }
            }else if(operator_val == "positioning") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_positioning.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_positioning.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_positioning.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_positioning.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_positioning.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_positioning.has_no_Change.bridgeval;
                }
            }else if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_pricerank.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter = data_pricerank.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter = data_pricerank.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter = data_pricerank.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter = data_pricerank.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter = data_pricerank.has_no_Change.bridgeval;
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){
                    var parameter = data_leadtime.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter = data_leadtime.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter = data_leadtime.is_less_than.bridgeval;
                }
            }else if(operator_val == "pick_up_trend") {
                var parameter = data.pick_up_trend.bridgeval;
            }
        
            let dropdown5 = $('#bridge_box_' + d);
            dropdown5.empty();
            dropdown5.prop('selectedIndex', 0);

            // Populate dropdown3 with list of bridge
            for(var i=0; i<parameter.length; i++){            
                dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }
                
            let dropdown_value_box = $('#value_box_' + d);
            $(dropdown_value_box).removeClass('opblink');
    
            let dropdown_bridge_box = $('#bridge_box_' + d);
            $(dropdown_bridge_box).removeClass('opblink');
            
            let dropdown_period_box = $('#period_box_' + d);
            $(dropdown_period_box).addClass('opblink');
    
            $("#period_box_" + d).css("display","block");

        });
    }

    _bridgeHandler(e) {
        this.bridgebox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();
        
        if(operator_val == "rms_to_sell"){
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }           
        }else if(operator_val == "rms_sold") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }
        }else if(operator_val == "adr") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }
        }else if(operator_val == "revenue") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.bridgeval;
            }
        }else if(operator_val == "occupancy") {
            if(metric_val == "is_equal_to"){
                var parameter = dataocc.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = dataocc.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = dataocc.is_less_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = dataocc.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = dataocc.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = dataocc.has_no_Change.bridgeval;
            }            
        }else if(operator_val == "rooms_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "adr_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "revenue_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "adr_move") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.bridgeval;
            }
        }else if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){
                var parameter = datamypace.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_ahead_by_more_than"){
                var parameter = datamypace.is_ahead_by_more_than.bridgeval;
            }
            if(metric_val == "is_behind_by_more_than"){
                var parameter = datamypace.is_behind_by_more_than.bridgeval;
            }
            if(metric_val == "has_improved"){
                var parameter = datamypace.has_improved.bridgeval;
            }
            if(metric_val == "has_fallen_behind"){
                var parameter = datamypace.has_fallen_behind.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = datamypace.has_no_Change.bridgeval;
            }
        }else if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){
                var parameter = data_my_price_rate.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_my_price_rate.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_my_price_rate.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_my_price_rate.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_my_price_rate.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_my_price_rate.has_no_Change.bridgeval;                
            }
        }else if(operator_val == "comp_average") {
            if(metric_val == "is_equal_to"){
                var parameter = data_compavg.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_compavg.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_compavg.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_compavg.has_increased_by.bridgeval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_compavg.has_decreased_by.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_compavg.has_no_Change.bridgeval;
            }
        }else if(operator_val == "forecast_range") {
            if(metric_val == "is_equal_to"){
                var parameter = data_forecast.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_forecast.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_forecast.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_forecast.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_forecast.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_forecast.has_no_Change.bridgeval;
            }
        }else if(operator_val == "expected_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data_expected_pickup.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_expected_pickup.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_expected_pickup.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_expected_pickup.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_expected_pickup.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_expected_pickup.has_no_Change.bridgeval;
            }
        }else if(operator_val == "positioning") {
            if(metric_val == "is_equal_to"){
                var parameter = data_positioning.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_positioning.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_positioning.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_positioning.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_positioning.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_positioning.has_no_Change.bridgeval;
            }
        }else if(operator_val == "price_rank") {
            if(metric_val == "is_equal_to"){
                var parameter = data_pricerank.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_pricerank.is_higher_than.bridgeval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_pricerank.is_lower_than.bridgeval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_pricerank.has_increased.bridgeval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_pricerank.has_decreased.bridgeval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_pricerank.has_no_Change.bridgeval;
            }
        }else if(operator_val == "leadtime") {
            if(metric_val == "is_equal_to"){
                var parameter = data_leadtime.is_equal_to.bridgeval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data_leadtime.is_greater_than.bridgeval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data_leadtime.is_less_than.bridgeval;
            }
        }else if(operator_val == "pick_up_trend") {
            var parameter = data.pick_up_trend.bridgeval;
        }

        $('#value_box_' + d).on('click', function() {

            let dropdown5 = $('#bridge_box_' + d);
            dropdown5.empty();
            dropdown5.prop('selectedIndex', 0);

            // Populate dropdown3 with list of bridge
            for(var i=0; i<parameter.length; i++){            
                dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }

        });        

        let dropdown_value_box = $('#value_box_' + d);
        $(dropdown_value_box).removeClass('opblink');

        let dropdown_bridge_box = $('#bridge_box_' + d);
        $(dropdown_bridge_box).removeClass('opblink');
        
        let dropdown_period_box = $('#period_box_' + d);
        $(dropdown_period_box).addClass('opblink');

        $("#period_box_" + d).css("display","block");
    }

    _periodHandler(e) {
        this.periodbox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var operator_val = $("#operator_box_" + d).val();
        var metric_val = $("#metric_box_" + d).val();
        
        if(operator_val == "rms_to_sell"){
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "rms_sold") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "adr") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "revenue") {
            if(metric_val == "is_equal_to"){
                var parameter = data2.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data2.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data2.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data2.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data2.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data2.has_no_Change.periodval;
            }
        }else if(operator_val == "occupancy") {
            if(metric_val == "is_equal_to"){
                var parameter = dataocc.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = dataocc.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = dataocc.is_less_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = dataocc.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = dataocc.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = dataocc.has_no_Change.periodval;
            }
        }else if(operator_val == "rooms_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "adr_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "revenue_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "adr_move") {
            if(metric_val == "is_equal_to"){
                var parameter = data3.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data3.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data3.is_less_than.periodval;
            }
        }else if(operator_val == "my_pace") {
            if(metric_val == "is_equal_to"){
                var parameter = datamypace.is_equal_to.periodval;
            }            
            if(metric_val == "is_ahead_by_more_than"){
                var parameter = datamypace.is_ahead_by_more_than.periodval;
            }
            if(metric_val == "is_behind_by_more_than"){
                var parameter = datamypace.is_behind_by_more_than.periodval;
            }
            if(metric_val == "has_improved"){
                var parameter = datamypace.has_improved.periodval;
            }
            if(metric_val == "has_fallen_behind"){
                var parameter = datamypace.has_fallen_behind.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = datamypace.has_no_Change.periodval;
            }
        }else if(operator_val == "my_price_or_rate") {
            if(metric_val == "is_equal_to"){
                var parameter = data_my_price_rate.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_my_price_rate.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_my_price_rate.is_lower_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_my_price_rate.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_my_price_rate.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_my_price_rate.has_no_Change.periodval;                
            }
        }else if(operator_val == "comp_average") {
            if(metric_val == "is_equal_to"){
                var parameter = data_compavg.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_compavg.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_compavg.is_lower_than.periodval;
            }
            if(metric_val == "has_increased_by"){
                var parameter = data_compavg.has_increased_by.periodval;
            }
            if(metric_val == "has_decreased_by"){
                var parameter = data_compavg.has_decreased_by.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_compavg.has_no_Change.periodval;
            }
        }else if(operator_val == "forecast_range") {
            if(metric_val == "is_equal_to"){
                var parameter = data_forecast.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_forecast.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_forecast.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_forecast.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_forecast.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_forecast.has_no_Change.periodval;               
            }
        }else if(operator_val == "expected_pick_up") {
            if(metric_val == "is_equal_to"){
                var parameter = data_expected_pickup.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_expected_pickup.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_expected_pickup.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_expected_pickup.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_expected_pickup.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_expected_pickup.has_no_Change.periodval;
            }
        }else if(operator_val == "positioning") {
            if(metric_val == "is_equal_to"){
                var parameter = data_positioning.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_positioning.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_positioning.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_positioning.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_positioning.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_positioning.has_no_Change.periodval;                
            }
        }else if(operator_val == "price_rank") {
            if(metric_val == "is_equal_to"){
                var parameter = data_pricerank.is_equal_to.periodval;
            }            
            if(metric_val == "is_higher_than"){
                var parameter = data_pricerank.is_higher_than.periodval;
            }
            if(metric_val == "is_lower_than"){
                var parameter = data_pricerank.is_lower_than.periodval;
            }
            if(metric_val == "has_increased"){
                var parameter = data_pricerank.has_increased.periodval;
            }
            if(metric_val == "has_decreased"){
                var parameter = data_pricerank.has_decreased.periodval;
            }
            if(metric_val == "has_no_Change"){
                var parameter = data_pricerank.has_no_Change.periodval;                
            }
        }else if(operator_val == "leadtime") {
            if(metric_val == "is_equal_to"){
                var parameter = data_leadtime.is_equal_to.periodval;
            }            
            if(metric_val == "is_greater_than"){
                var parameter = data_leadtime.is_greater_than.periodval;
            }
            if(metric_val == "is_less_than"){
                var parameter = data_leadtime.is_less_than.periodval;
            }
        }else if(operator_val == "pick_up_trend") {
            var parameter = data.pick_up_trend.periodval;
        }

        let dropdown6 = $('#period_box_' + d);
        dropdown6.empty();
        dropdown6.prop('selectedIndex', 0);

        // Populate dropdown3 with list of value
        for(var i=0; i<parameter.length; i++){            
            dropdown6.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
        }

        let dropdown_period_box = $('#period_box_' + d);
        $(dropdown_period_box).removeClass('opblink');

        let dropdown_condition_box = $('#condition_box_' + d);
        $(dropdown_condition_box).addClass('opblink');

        $("#condition_box_" + d).css("display","block");
    }

    _conditionHandler(e) {
        
        // this.conditionbox = e.target.textContent;

        let d = e.target.getAttribute('key');
        var val = $("#condition_box_" + d).val();
        // console.log(val);

        // if(val == 0){ 
        //     $("#addnewtrbtn_" + d).css("display","none");   
        // }else{
        //     $("#addnewtrbtn_" + d).css("display","block");      
        // }

        let dropdown_condition_box = $('#condition_box_' + d);
        $(dropdown_condition_box).addClass('opblink');
        
        $("#addnewtrbtn_" + d).css("display","block");
        $("#removenewtrbtn_" + d).css("display","block");
        
        $('#condition_box_' + d).on('change', function() {
            let condition_val = $('#condition_box_' + d).val();
            if(condition_val != "-"){
                $("#condition_box_" + d).css("color","green");
                $("#condition_box_" + d).css("font-weight","bold");
            //     $("#condition_box_" + d).css("color","red");
            //     $("#condition_box_" + d).css("font-weight","bold");   
            // }else{
            //     $("#condition_box_" + d).css("color","green");
            //     $("#condition_box_" + d).css("font-weight","bold");   
            }
            
            $("#table_operator>tbody>tr>td>select").removeClass("opblink");
            $("#table_operator>tbody>tr>td>input").removeClass("opblink");

        });


    }

    addnewtr(){

        $("#addnewtrbtn_" + this.initialcount).css("display","none");
        $("#removenewtrbtn_" + this.initialcount).css("display","block");                      

        let dropdown_condition_box = $('#condition_box_' + this.initialcount);
        $(dropdown_condition_box).removeClass('opblink');
        
        let flapSupport = 
            `<div class="table-responsive div_added" id="tr_added_${this.counter}">
            <table class="table" style="width:100%; text-align: center">
            <tbody>
            <tr>
                <td style="vertical-align: inherit;width: 15%;"></td>
                <td style="width: 13%;">
                    <select id="operator_box_${this.counter}" key="${this.counter}" class="form-control selectbox oplink"></select>
                </td>
                <td style="width: 13%;">
                    <select id="metric_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>                
                <td id="display_textbox_${this.counter}" style="min-width:11%">
                    <input type="text" id="text_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;" placeholder="Value" />
                    
                    <div class="input-group selectbox">
                        <input type="text" id="occ_text_box_${this.counter}" key="${this.counter}" class="form-control" placeholder="Value" aria-label="Value" aria-describedby="basic-addon2" style="display:none;border:none; font-size: 14px; color: #2196f3;">
                        <div class="input-group-append">
                            <span id="span_text_box_${this.counter}" class="input-group-text" id="basic-addon2" style="display:none; background: none; border: none">%</span>
                        </div>
                    </div>

                    <select id="select_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>
                    <select id="select_box_pricerank_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>
                    <select id="select_box_myprice_rate_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>  
                    <select id="select_box_pace_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>    
                    <select id="select_forecast_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                
                    </select>              
                </td>
                <td style="width: 13%;" id="display_valuebox_${this.counter}">
                    <select id="value_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>
                <td style="width: 7%;" id="display_bridgebox_${this.counter}">
                    <select id="bridge_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>
                <td style="width: 11%;" id="display_periodbox_${this.counter}">
                    <select id="period_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        
                    </select>
                </td>
                <td style="width: fit-content; display: flex;">
                    <select type="text" id="condition_box_${this.counter}" key="${this.counter}" class="form-control selectbox" style="display:none;">
                        <option value="">-</option> 
                        <option value="and">AND</option>
                        <option value="or">OR</option>
                    </select>
                    <button type="button" id="addnewtrbtn_${this.counter}" key="${this.counter}" class="btn btn-success" style="vertical-align: text-top;border-radius:50px;display:none">
                        <i class="fa fa-plus"></i>
                    </button> &nbsp;
                    <button type="button" id="removenewtrbtn_${this.counter}" key="${this.counter}" class="btn btn-danger" style="vertical-align: text-top;border-radius:50px;display:none">
                        <i class="fa fa-minus"></i>
                    </button>
                </td>
            </tr>
            </tr>
            </tbody>
            </table>
            </div>`;                     

        if ($(".div_added")[0]){
            $(".div_added").parent(1).append(flapSupport);
        } else {
            $(".div1").eq(0).after(flapSupport);
        }

        // $("#table_operator").find(a).append(flapSupport);                
        $('#operator_box_' + this.counter).click(this.myFunction_newrow(this.counter));        

        this.counter++; 
        
        // console.log(this.counter);
                
        // counter--;        
        // $("#TextBoxDiv" + counter).remove();
    } 

    removenewtr(){

        let remove_tr = $('#tr_' + this.initialcount);        
        $(remove_tr).remove();        
                
    }    

    myFunction_newrow(counter){   
        
        // console.log(counter);

        let dropdownname = $('#operator_box_' + counter);
       
        dropdownname.empty();
        dropdownname.prop('selectedIndex', 0);       

        for(var i in data){
            // console.log(i);
            dropdownname.append($('<option></option>').attr('value', i).text(data [i]['display']));
        }
                
        // var cntminus = counter - 1;
        // $("#addnewtrbtn_" + cntminus).css("display","none");

        $('#operator_box_' + counter).on('change', function() {                                    

            $("#metric_box_" + counter).css("display","block");                   

            let dropdown_valuebox = $('#metric_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            // alert( this.value );            
            var operator_val = this.value;

            var len = $(this).val().length;
            if(operator_val == 'adr'){
                $(this).parent(0).css("width", '6%');                                
            }else if(len > 14){
                $(this).parent(0).css("width", (len - 3) + '%');                                
            }else{
                $(this).parent(0).css("width", len + 1 + '%');
            }

            if(operator_val == "rms_to_sell"){
                var parameter = data.rms_to_sell.metric;
            }else if(operator_val == "rms_sold") {
                var parameter = data.rms_sold.metric;
            }else if(operator_val == "adr") {
                var parameter = data.adr.metric;
            }else if(operator_val == "revenue") {
                var parameter = data.revenue.metric;
            }else if(operator_val == "occupancy") {
                var parameter = data.occupancy.metric;
            }else if(operator_val == "rooms_pick_up") {
                var parameter = data.rooms_pick_up.metric;
            }else if(operator_val == "adr_pick_up") {
                var parameter = data.adr_pick_up.metric;
            }else if(operator_val == "revenue_pick_up") {
                var parameter = data.revenue_pick_up.metric;
            }else if(operator_val == "adr_move") {
                var parameter = data.adr_move.metric;
            }else if(operator_val == "my_pace") {
                var parameter = data.my_pace.metric;
            }else if(operator_val == "my_price_or_rate") {
                var parameter = data.my_price_or_rate.metric;
            }else if(operator_val == "comp_average") {
                var parameter = data.comp_average.metric;
            }else if(operator_val == "forecast_range") {
                var parameter = data.forecast_range.metric;
            }else if(operator_val == "expected_pick_up") {
                var parameter = data.expected_pick_up.metric;
            }else if(operator_val == "positioning") {
                var parameter = data.positioning.metric;
            }else if(operator_val == "price_rank") {
                var parameter = data.price_rank.metric;
            }else if(operator_val == "leadtime") {
                var parameter = data.leadtime.metric;
            }else if(operator_val == "pick_up_trend") {
                var parameter = data.pick_up_trend.metric;
            }
    
            let dropdown_metric_counter = $('#metric_box_' + counter);
    
            dropdown_metric_counter.empty();
            dropdown_metric_counter.prop('selectedIndex', 0);
    
            for(var i in parameter){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown_metric_counter.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
            }                
            
            /****************Value****************/                        

        });               
        
        $('#metric_box_' + counter).on('change', function() {
                                               
            var len = $(this).val().length;
            $(this).parent(0).css("min-width", len + 2 + '%');                                
            $(this).parent(0).css("width", len + '%'); 

            $("#text_box_" + counter).css("display","block");
            $("#occ_text_box_" + counter).css("display","none");
            $("#span_text_box_" + counter).css("display","none");

            let dropdown_operator_box = $('#operator_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            var regex = /^\d*[.]?\d*$/;

            $('#text_box_' + counter).on("input", function() {

                var inputVal = $(this).val(); 

                if(regex.test(inputVal)){

                } else{
                    $(this).val('');
                }

            });    
            
            $('#occ_text_box_' + counter).on("input", function() {

                var inputVal = $(this).val(); 

                if(regex.test(inputVal)){

                } else{
                    $(this).val('');
                }

            });

            // if( operator_val == "rms_to_sell" || operator_val == "rms_sold" || operator_val == "adr" || operator_val == "revenue" || operator_val == "occupancy" || operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "comp_average")
            // {
            //     $("#bridge_box_" + counter).css("display","table-cell"); 
            // }

            if(operator_val == "occupancy"){
                if(metric_val == "is_equal_to" || metric_val == "is_greater_than" || metric_val == "is_less_than"){
                    $("#text_box_" + counter).css("display","none");
                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#span_text_box_" + counter).css("display","table-cell");
                }else{
                    $("#occ_text_box_" + counter).css("display","none");
                    $("#span_text_box_" + counter).css("display","none");
                }
            }else{                
                    $("#occ_text_box_" + counter).css("display","none");       
                    $("#span_text_box_" + counter).css("display","none");         
            }

            if(operator_val == "expected_pick_up"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell"); 
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");                    
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');  
                }else{
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                }                                                      
            }

            if(operator_val == "pick_up_trend"){                                    
                
                $("#display_textbox_" + counter).css("display","none");
                $("#text_box_" + counter).css("display","none");
                $("#display_valuebox_" + counter).css("display","none");
                $("#display_bridgebox_" + counter).css("display","none");
                $("#display_periodbox_" + counter).css("display","none");
                $("#condition_box_" + counter).css("display","table-cell");
                let condition_box = $('#condition_box_' + counter);
                $(condition_box).addClass('opblink');                                                                            

            }

            if(operator_val == "positioning" || operator_val == "price_rank"){                
                if(metric_val == "has_increased" || metric_val == "has_decreased" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + counter).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');  
                }                                            
            }

            if(operator_val == "forecast_range"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + counter);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }

            }

            if(operator_val == "my_price_or_rate"){                                                

                if(metric_val == "has_no_Change"){
                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","none");
                    $("#select_box_" + counter).css("display","none");                
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_boxx = $('#condition_box_' + counter);
                    $(condition_boxx).removeClass('opblink');

                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }

            }

            if(operator_val == "my_pace"){                
                if(metric_val == "is_ahead_by_more_than" || metric_val == "is_behind_by_more_than"){
                    $("#text_box_" + counter).css("display","table-cell");
                }   
                if(metric_val == "has_improved" || metric_val == "has_fallen_behind" || metric_val == "has_no_Change"){
                    $("#bridge_box_" + counter).css("display","table-cell"); 
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');   
                }                             
            }

            if(metric_val=="has_no_Change" || operator_val == "my_pace" || operator_val == "my_price_or_rate" || operator_val == "expected_pick_up" || operator_val == "positioning" || operator_val == "price_rank"){
                /********* Bridge *****/
                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + counter);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);

                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + counter);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + counter);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + counter);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + counter).css("display","block");
                /********* Bridge *****/
            }            
        
            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;                    

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_greater_than"){
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value; 

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased_by"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");                    

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    
                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                }            
                if(metric_val == "is_greater_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }            
                if(metric_val == "is_greater_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }            
                if(metric_val == "is_greater_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#text_box_' + counter).on('click', function() {                    
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = data2.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data2.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell"); 

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = dataocc.is_equal_to.value;                    

                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#occ_text_box_' + counter).on('click', function() {                        
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    }); 

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = dataocc.is_greater_than.value;

                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#occ_text_box_' + counter).on('click', function() {                        
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = dataocc.is_less_than.value;

                    $("#occ_text_box_" + counter).css("display","table-cell");
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    $('#occ_text_box_' + counter).on('click', function() {                        
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = dataocc.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    $("#text_box_" + counter).css("display","block");
                    var parameter_value = dataocc.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = dataocc.has_no_Change.value;                    

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");          
                    
                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');
                    
                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    
                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data3.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data3.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data3.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");    

                    $("#bridge_box_" + counter).css("display","table-cell");
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data_compavg.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_higher_than"){

                    var parameter_value = data_compavg.is_higher_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_lower_than"){

                    var parameter_value = data_compavg.is_lower_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased_by"){

                    var parameter_value = data_compavg.has_increased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased_by"){

                    var parameter_value = data_compavg.has_decreased_by.value;

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    var parameter_value = data_compavg.has_no_Change.value;                    

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#text_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");

                    $("#display_textbox_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");                
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                    let dropdown_bridge_box = $('#bridge_box_' + counter);
                    $(dropdown_bridge_box).addClass('opblink');
                    
                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "forecast_range") {
                var parameter_value = data.forecast_range.value;
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data_expected_pickup.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_higher_than"){

                    var parameter_value = data_expected_pickup.is_higher_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_value = data_expected_pickup.is_lower_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }
                if(metric_val == "has_increased"){

                    var parameter_value = data_expected_pickup.has_increased.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased"){

                    var parameter_value = data_expected_pickup.has_decreased.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter_value = data_expected_pickup.has_no_Change.value;

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){

                    var parameter_value = data_leadtime.is_equal_to.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
                    
                }            
                if(metric_val == "is_greater_than"){

                    var parameter_value = data_leadtime.is_greater_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "is_less_than"){

                    var parameter_value = data_leadtime.is_less_than.value;

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                let dropdown_value_counter = $('#value_box_' + counter);    
                dropdown_value_counter.empty();
                dropdown_value_counter.prop('selectedIndex', 0);
        
                for(var i in parameter_value){
                    // console.log(parameter[i]['dev_name']);
                    // console.log(parameter[i]['display']);
                    dropdown_value_counter.append($('<option></option>').attr('value', parameter_value[i]['dev_name']).text(parameter_value[i]['display']));
                }
            }else if(operator_val == "pick_up_trend") {                  

                let dropdownval = $('#value_box_' + counter);
                dropdownval.empty();
                dropdownval.prop('selectedIndex', 0);

                // Populate dropdownval with list of value
                dropdownval.append($('<option></option>').attr('value', '-').text('-'));
                
            }                

            if(operator_val == "positioning") {                
                if(metric_val == "is_equal_to"){
                   
                    var parameter_select_box_ = data_positioning.is_equal_to.value; 
    
                    let dropdownselect_box_ = $('#select_box_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }       
                    
                    /**********************************/
                    var parameter_valuebox = data_positioning.is_equal_to.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_higher_than"){                
                       
                    var parameter_select_box_ = data_positioning.is_higher_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }     
                    
                    /**********************************/
                    var parameter_valuebox = data_positioning.is_higher_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });                    
    
                }
                if(metric_val == "is_lower_than"){
                                        
                    var parameter_select_box_ = data_positioning.is_lower_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }               
                    
                    /**********************************/
                    var parameter_valuebox = data_positioning.is_lower_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });

                }
                if(metric_val == "has_increased"){
                        
                    var parameter = data_positioning.has_increased.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_decreased"){
                    
                    var parameter = data_positioning.has_decreased.value;                    
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    var parameter = data_positioning.has_no_Change.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
            }

            if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){

                    var parameter_select_box_ = data_pricerank.is_equal_to.value;
    
                    let dropdownselect_box_ = $('#select_box_pricerank_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }       
                    
                    /**********************************/
                    var parameter_valuebox = data_pricerank.is_equal_to.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pricerank_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_higher_than"){                
    
                    var parameter_select_box_ = data_pricerank.is_higher_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_pricerank_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }     
                    
                    /**********************************/
                    var parameter_valuebox = data_pricerank.is_higher_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pricerank_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_select_box_ = data_pricerank.is_lower_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_pricerank_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }               
                    
                    /**********************************/
                    var parameter_valuebox = data_pricerank.is_lower_than.valuebox;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pricerank_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }
                if(metric_val == "has_increased"){
    
                    var parameter = data_pricerank.has_increased.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased"){

                    var parameter = data_pricerank.has_decreased.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none"); 
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){

                    var parameter = data_pricerank.has_no_Change.value;
                   
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#bridge_box_" + counter).css("display","block");

                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
            }                

            if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                            
                    var parameter_select_box_ = data_my_price_rate.is_equal_to.valuepricerate;
    
                    let dropdownselect_box_ = $('#select_box_myprice_rate_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                                         
                    
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_myprice_rate_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_myprice_rate_' + counter).on('change', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_higher_than"){                
    
                    var parameter_select_box_ = data_my_price_rate.is_higher_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_myprice_rate_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                   
                    
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_myprice_rate_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
    
                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_select_box_ = data_my_price_rate.is_lower_than.value;
                    
                    let dropdownselect_box_ = $('#select_box_myprice_rate_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                                        
                    
                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_pricerank_" + counter).css("display","none");
                    $("#select_box_myprice_rate_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
    
                }
                if(metric_val == "has_increased_by"){
    
                    var parameter = data_my_price_rate.has_increased_by.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
    
                    $("#bridge_box_" + counter).css("display","block");
    
                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_" + counter).css("display","none");                
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_decreased_by"){

                    var parameter = data_my_price_rate.has_decreased_by.value;
                    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
    
                    $("#bridge_box_" + counter).css("display","block");
    
                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    $("#value_box_" + counter).css("display","table-cell");
                    $("#select_box_" + counter).css("display","none");                
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    $("#display_valuebox_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    $("#select_box_myprice_rate_" + counter).css("display","none");
                    var parameter = data_my_price_rate.has_no_Change.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
    
                    $("#bridge_box_" + counter).css("display","block");
    
                    let dropdown_operator_box = $('#metric_box_' + counter);
                    $(dropdown_operator_box).removeClass('opblink');
        
                    let dropdown_valuebox = $('#bridge_box_' + counter);
                    $(dropdown_valuebox).addClass('opblink');
                }
            }

            if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
    
                    var parameter_select_box_ = datamypace.is_equal_to.valuepace;
    
                    let dropdownselect_box_ = $('#select_box_pace_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = datamypace.is_equal_to.value;

                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#select_box_pace_' + counter).on('click', function() {
                        let condition_box = $('#condition_box_' + counter);
                        $(condition_box).addClass('opblink');
                    });
    
                }            
                if(metric_val == "is_ahead_by_more_than"){                
                 
                    /**********************************/
                    var parameter_valuebox = datamypace.is_ahead_by_more_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    
                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        $("#bridge_box_" + counter).css("display","table-cell");
                        let bridge_box = $('#bridge_box_' + counter);
                        $(bridge_box).addClass('opblink');
                    });
                    
    
                }
                if(metric_val == "is_behind_by_more_than"){                               
                    
                    /**********************************/
                    var parameter_valuebox = datamypace.is_behind_by_more_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value 
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","table-cell");
                    
                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");

                    $('#text_box_' + counter).on('click', function() {
                        $("#bridge_box_" + counter).css("display","table-cell");
                        let bridge_box = $('#bridge_box_' + counter);
                        $(bridge_box).addClass('opblink');
                    });
    
                }
                if(metric_val == "has_improved"){
                        
                    var parameter = datamypace.has_improved.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                }
                if(metric_val == "has_fallen_behind"){

                    var parameter = datamypace.has_fallen_behind.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
                if(metric_val == "has_no_Change"){
                    
                    var parameter = datamypace.has_no_Change.value;
                    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_box_myprice_rate" + counter).css("display","none");
                    $("#select_box_pace_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");

                }
            }

            if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){
    
                    var parameter_select_box_ = data_forecast.is_equal_to.valueforecast;
    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }        
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.is_equal_to.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink');
    
                }            
                if(metric_val == "is_higher_than"){                
    
                    var parameter_select_box_ = data_forecast.is_higher_than.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }                
    
                    /**********************************/
                    var parameter_valuebox = data_forecast.is_higher_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink');
    
                }
                if(metric_val == "is_lower_than"){
                    
                    var parameter_select_box_ = data_forecast.is_lower_than.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.is_lower_than.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#display_bridgebox_" + counter).css("display","none");
                    $("#display_periodbox_" + counter).css("display","none");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink');
    
                }
                if(metric_val == "has_increased"){
    
                    var parameter_select_box_ = data_forecast.has_increased.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.has_increased.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');
    
                }
                if(metric_val == "has_decreased"){
    
                    var parameter_select_box_ = data_forecast.has_decreased.valueforecast;
                    
                    let dropdownselect_box_ = $('#select_forecast_' + counter);
                    dropdownselect_box_.empty();
                    dropdownselect_box_.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter_select_box_.length; i++){
                        // console.log(parameter_select_box_[i]['dev_name']);
                        // console.log(parameter_select_box_[i]['display']);
                        dropdownselect_box_.append($('<option></option>').attr('value', parameter_select_box_[i]['dev_name']).text(parameter_select_box_[i]['display']));
                    }    
                    
                    /**********************************/
                    var parameter_valuebox = data_forecast.has_decreased.value;
    
                    let dropdown_parameter_valuebox = $('#value_box_' + counter);
                    dropdown_parameter_valuebox.empty();
                    dropdown_parameter_valuebox.prop('selectedIndex', 0);
    
                    // Populate dropdown_parameter_valuebox with list of value
                    for(var i=0; i<parameter_valuebox.length; i++){
                        // console.log(parameter_valuebox[i]['dev_name']);
                        // console.log(parameter_valuebox[i]['display']);
                        dropdown_parameter_valuebox.append($('<option></option>').attr('value', parameter_valuebox[i]['dev_name']).text(parameter_valuebox[i]['display']));
                    }
                    /**********************************/

                    $("#display_textbox_" + counter).css("display","table-cell");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","table-cell");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let bridge_box = $('#bridge_box_' + counter);
                    $(bridge_box).addClass('opblink');
    
                }
                if(metric_val == "has_no_Change"){
    
                    var parameter = data_positioning.has_no_Change.value;
    
                    /**********************************/
                    let dropdown3 = $('#value_box_' + counter);
                    dropdown3.empty();
                    dropdown3.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of value
                    for(var i=0; i<parameter.length; i++){
                        // console.log(parameter[i]['dev_name']);
                        // console.log(parameter[i]['display']);
                        dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                    /**********************************/
                    
                    $("#display_textbox_" + counter).css("display","none");
                    $("#text_box_" + counter).css("display","none");

                    $("#select_box_" + counter).css("display","none");
                    $("#select_forecast_" + counter).css("display","none");

                    $("#display_valuebox_" + counter).css("display","none");
                    $("#bridge_box_" + counter).css("display","table-cell");
                    $("#display_bridgebox_" + counter).css("display","table-cell");
                    $("#display_periodbox_" + counter).css("display","table-cell");
                    $("#condition_box_" + counter).css("display","table-cell");
                    
                    let condition_box = $('#condition_box_' + counter);
                    $(condition_box).addClass('opblink'); 
    
                }
            }

            /*************Bridge***************/
            var metric_val = $("#metric_box_" + counter).val();
            
            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }           
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data2.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data2.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data2.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data2.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data2.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data2.has_no_Change.bridgeval;
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = dataocc.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = dataocc.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = dataocc.is_less_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = dataocc.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = dataocc.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = dataocc.has_no_Change.bridgeval;
                }
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data3.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data3.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data3.is_less_than.bridgeval;
                }
            }else if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = datamypace.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_ahead_by_more_than"){
                    var parameter_bridge = datamypace.is_ahead_by_more_than.bridgeval;
                }
                if(metric_val == "is_behind_by_more_than"){
                    var parameter_bridge = datamypace.is_behind_by_more_than.bridgeval;
                }
                if(metric_val == "has_improved"){
                    var parameter_bridge = datamypace.has_improved.bridgeval;
                }
                if(metric_val == "has_fallen_behind"){
                    var parameter_bridge = datamypace.has_fallen_behind.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = datamypace.has_no_Change.bridgeval;
                }
            }else if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_my_price_rate.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_my_price_rate.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_my_price_rate.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data_my_price_rate.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data_my_price_rate.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_my_price_rate.has_no_Change.bridgeval;
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_compavg.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_compavg.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_compavg.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_bridge = data_compavg.has_increased_by.bridgeval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_bridge = data_compavg.has_decreased_by.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_compavg.has_no_Change.bridgeval;
                }
            }else if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_forecast.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_forecast.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_forecast.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_forecast.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_forecast.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_forecast.has_no_Change.bridgeval;
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_expected_pickup.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_expected_pickup.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_expected_pickup.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_expected_pickup.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_expected_pickup.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_expected_pickup.has_no_Change.bridgeval;
                }
            }else if(operator_val == "positioning") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_positioning.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_positioning.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_positioning.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_positioning.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_positioning.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_positioning.has_no_Change.bridgeval;
                }
            }else if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_pricerank.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_bridge = data_pricerank.is_higher_than.bridgeval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_bridge = data_pricerank.is_lower_than.bridgeval;
                }
                if(metric_val == "has_increased"){
                    var parameter_bridge = data_pricerank.has_increased.bridgeval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_bridge = data_pricerank.has_decreased.bridgeval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_bridge = data_pricerank.has_no_Change.bridgeval;
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){
                    var parameter_bridge = data_leadtime.is_equal_to.bridgeval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_bridge = data_leadtime.is_greater_than.bridgeval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_bridge = data_leadtime.is_less_than.bridgeval;
                }
            }else if(operator_val == "pick_up_trend") {
                var parameter_bridge = data.pick_up_trend.bridgeval;
            }
    
            let dropdown_bridgeval_counter = $('#bridge_box_' + counter);
    
            dropdown_bridgeval_counter.empty();
            dropdown_bridgeval_counter.prop('selectedIndex', 0);
    
            for(var i in parameter_bridge){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown_bridgeval_counter.append($('<option></option>').attr('value', parameter_bridge[i]['dev_name']).text(parameter_bridge[i]['display']));
            }

            /**************Period*********/
            var metric_val = $("#metric_box_" + counter).val();
        
            if(operator_val == "rms_to_sell"){
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "rms_sold") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "adr") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "revenue") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data2.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data2.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data2.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data2.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data2.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data2.has_no_Change.periodval;
                }
            }else if(operator_val == "occupancy") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = dataocc.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = dataocc.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = dataocc.is_less_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = dataocc.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = dataocc.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = dataocc.has_no_Change.periodval;
                }
            }else if(operator_val == "rooms_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "adr_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "revenue_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "adr_move") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data3.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data3.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data3.is_less_than.periodval;
                }
            }else if(operator_val == "my_pace") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = datamypace.is_equal_to.periodval;
                }            
                if(metric_val == "is_ahead_by_more_than"){
                    var parameter_period = datamypace.is_ahead_by_more_than.periodval;
                }
                if(metric_val == "is_behind_by_more_than"){
                    var parameter_period = datamypace.is_behind_by_more_than.periodval;
                }
                if(metric_val == "has_improved"){
                    var parameter_period = datamypace.has_improved.periodval;
                }
                if(metric_val == "has_fallen_behind"){
                    var parameter_period = datamypace.has_fallen_behind.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = datamypace.has_no_Change.periodval;
                }
            }else if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_my_price_rate.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_my_price_rate.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_my_price_rate.is_lower_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data_my_price_rate.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data_my_price_rate.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_my_price_rate.has_no_Change.periodval;
                }
            }else if(operator_val == "comp_average") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_compavg.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_compavg.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_compavg.is_lower_than.periodval;
                }
                if(metric_val == "has_increased_by"){
                    var parameter_period = data_compavg.has_increased_by.periodval;
                }
                if(metric_val == "has_decreased_by"){
                    var parameter_period = data_compavg.has_decreased_by.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_compavg.has_no_Change.periodval;
                }
            }else if(operator_val == "forecast_range") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_forecast.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_forecast.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_forecast.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_forecast.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_forecast.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_forecast.has_no_Change.periodval;
                }
            }else if(operator_val == "expected_pick_up") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_expected_pickup.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_expected_pickup.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_expected_pickup.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_expected_pickup.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_expected_pickup.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_expected_pickup.has_no_Change.periodval;
                }
            }else if(operator_val == "positioning") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_positioning.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_positioning.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_positioning.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_positioning.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_positioning.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_positioning.has_no_Change.periodval;
                }
            }else if(operator_val == "price_rank") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_pricerank.is_equal_to.periodval;
                }            
                if(metric_val == "is_higher_than"){
                    var parameter_period = data_pricerank.is_higher_than.periodval;
                }
                if(metric_val == "is_lower_than"){
                    var parameter_period = data_pricerank.is_lower_than.periodval;
                }
                if(metric_val == "has_increased"){
                    var parameter_period = data_pricerank.has_increased.periodval;
                }
                if(metric_val == "has_decreased"){
                    var parameter_period = data_pricerank.has_decreased.periodval;
                }
                if(metric_val == "has_no_Change"){
                    var parameter_period = data_pricerank.has_no_Change.periodval;
                }
            }else if(operator_val == "leadtime") {
                if(metric_val == "is_equal_to"){
                    var parameter_period = data_leadtime.is_equal_to.periodval;
                }            
                if(metric_val == "is_greater_than"){
                    var parameter_period = data_leadtime.is_greater_than.periodval;
                }
                if(metric_val == "is_less_than"){
                    var parameter_period = data_leadtime.is_less_than.periodval;
                }
            }else if(operator_val == "pick_up_trend") {
                var parameter_period = data.pick_up_trend.periodval;
            }
    
            let dropdown_periodval_counter = $('#period_box_' + counter);
    
            dropdown_periodval_counter.empty();
            dropdown_periodval_counter.prop('selectedIndex', 0);
    
            for(var i in parameter_period){
                // console.log(parameter[i]['dev_name']);
                // console.log(parameter[i]['display']);
                dropdown_periodval_counter.append($('<option></option>').attr('value', parameter_period[i]['dev_name']).text(parameter_period[i]['display']));
            }
            /******************/

        });

        $('#text_box_' + counter).on('click', function() {
                        
            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            $("#value_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            $('#text_box_' + counter).on('click', function() {

                if(operator_val == "rooms_pick_up" || operator_val == "adr_pick_up" || operator_val == "revenue_pick_up" || operator_val == "adr_move" || operator_val == "my_pace"){
                    if(operator_val == "rms_to_sell"){
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }           
                    }else if(operator_val == "rms_sold") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "adr") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "revenue") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data2.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data2.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data2.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data2.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data2.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data2.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "occupancy") {
                        if(metric_val == "is_equal_to"){
                            var parameter = dataocc.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = dataocc.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = dataocc.is_less_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = dataocc.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = dataocc.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = dataocc.has_no_Change.bridgeval;
                        }            
                    }else if(operator_val == "rooms_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "adr_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "revenue_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "adr_move") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data3.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data3.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data3.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "my_pace") {
                        if(metric_val == "is_equal_to"){
                            var parameter = datamypace.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_ahead_by_more_than"){
                            var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                        }
                        if(metric_val == "is_behind_by_more_than"){
                            var parameter = datamypace.is_behind_by_more_than.bridgeval;
                        }
                        if(metric_val == "has_improved"){
                            var parameter = datamypace.has_improved.bridgeval;
                        }
                        if(metric_val == "has_fallen_behind"){
                            var parameter = datamypace.has_fallen_behind.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = datamypace.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "my_price_or_rate") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_my_price_rate.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_my_price_rate.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_my_price_rate.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data_my_price_rate.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                        }
                    }else if(operator_val == "comp_average") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_compavg.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_compavg.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_compavg.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased_by"){
                            var parameter = data_compavg.has_increased_by.bridgeval;
                        }
                        if(metric_val == "has_decreased_by"){
                            var parameter = data_compavg.has_decreased_by.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_compavg.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "forecast_range") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_forecast.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_forecast.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_forecast.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_forecast.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_forecast.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_forecast.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "expected_pick_up") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_expected_pickup.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_expected_pickup.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_expected_pickup.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_expected_pickup.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_expected_pickup.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_expected_pickup.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "positioning") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_positioning.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_positioning.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_positioning.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_positioning.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_positioning.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_positioning.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "price_rank") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_pricerank.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_higher_than"){
                            var parameter = data_pricerank.is_higher_than.bridgeval;
                        }
                        if(metric_val == "is_lower_than"){
                            var parameter = data_pricerank.is_lower_than.bridgeval;
                        }
                        if(metric_val == "has_increased"){
                            var parameter = data_pricerank.has_increased.bridgeval;
                        }
                        if(metric_val == "has_decreased"){
                            var parameter = data_pricerank.has_decreased.bridgeval;
                        }
                        if(metric_val == "has_no_Change"){
                            var parameter = data_pricerank.has_no_Change.bridgeval;
                        }
                    }else if(operator_val == "leadtime") {
                        if(metric_val == "is_equal_to"){
                            var parameter = data_leadtime.is_equal_to.bridgeval;
                        }            
                        if(metric_val == "is_greater_than"){
                            var parameter = data_leadtime.is_greater_than.bridgeval;
                        }
                        if(metric_val == "is_less_than"){
                            var parameter = data_leadtime.is_less_than.bridgeval;
                        }
                    }else if(operator_val == "pick_up_trend") {
                        var parameter = data.pick_up_trend.bridgeval;
                    }
                
                    let dropdown5 = $('#bridge_box_' + counter);
                    dropdown5.empty();
                    dropdown5.prop('selectedIndex', 0);
    
                    // Populate dropdown3 with list of bridge
                    for(var i=0; i<parameter.length; i++){            
                        dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                    }
                        
                    let dropdown_value_box = $('#value_box_' + counter);
                    $(dropdown_value_box).removeClass('opblink');
            
                    let dropdown_bridge_box = $('#bridge_box_' + counter);
                    $(dropdown_bridge_box).removeClass('opblink');
                    
                    let dropdown_period_box = $('#period_box_' + counter);
                    $(dropdown_period_box).addClass('opblink');
            
                    $("#period_box_" + counter).css("display","block");
                }
    
            });            

        });

        $('#select_box_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_pricerank_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_box_pricerank_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_box_pace_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_box_myprice_rate_' + counter).on('click', function() {
                        
            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none"); 
            $("#select_box_pricerank_" + counter).css("display","none");

            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();
            var select_box_myprice_rate_val = $("#select_box_myprice_rate_" + counter).val();

            if(operator_val == "my_price_or_rate") {
                if(metric_val == "is_equal_to"){             
                    if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                        
                        /**********************************/
                        var parameter_valuepricerate = data_my_price_rate.is_equal_to.value;
    
                        let dropdown_parameter_valuepricerate = $('#value_box_' + counter);
                        dropdown_parameter_valuepricerate.empty();
                        dropdown_parameter_valuepricerate.prop('selectedIndex', 0);
    
                        // Populate dropdown_parameter_valuebox with list of value
                        for(var i=0; i<parameter_valuepricerate.length; i++){
                            // console.log(parameter_valuebox[i]['dev_name']);
                            // console.log(parameter_valuebox[i]['display']);
                            dropdown_parameter_valuepricerate.append($('<option></option>').attr('value', parameter_valuepricerate[i]['dev_name']).text(parameter_valuepricerate[i]['display']));
                        }
                        /**********************************/
                    }
                }            
                if(metric_val == "is_higher_than"){                
                    if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                        
                        var parameter = data_my_price_rate.is_higher_than.value;
    
                        let dropdown3 = $('#value_box_' + counter);
                        dropdown3.empty();
                        dropdown3.prop('selectedIndex', 0);
    
                        // Populate dropdown3 with list of value
                        for(var i=0; i<parameter.length; i++){
                            // console.log(parameter[i]['dev_name']);
                            // console.log(parameter[i]['display']);
                            dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                        }
                                            
                    }
                }
                if(metric_val == "is_lower_than"){                
                    if(select_box_myprice_rate_val == "numeric" || select_box_myprice_rate_val == "compavg" || select_box_myprice_rate_val == "spec_comp"){        
                        
                        var parameter = data_my_price_rate.is_higher_than.value;
    
                        let dropdown3 = $('#value_box_' + counter);
                        dropdown3.empty();
                        dropdown3.prop('selectedIndex', 0);
    
                        // Populate dropdown3 with list of value
                        for(var i=0; i<parameter.length; i++){
                            // console.log(parameter[i]['dev_name']);
                            // console.log(parameter[i]['display']);
                            dropdown3.append($('<option></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                        }
    
                    }
    
                }
            }
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#select_forecast_' + counter).on('click', function() {
                        
            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            $("#value_box_" + counter).css("display","block");
            
            $("#select_box_" + counter).css("display","none");
            
            let dropdown_operator_box = $('#metric_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink'); 

            let dropdown_valuebox = $('#value_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            $('#select_forecast_' + counter).on('change', function() {
    
                if(operator_val == "forecast_range") {                
                    if(metric_val == "has_increased"){
                        
                        var parameter_bridgeval = data_forecast.has_increased.bridgeval;
                        
                        let dropdown5 = $('#bridge_box_' + counter);
                        dropdown5.empty();
                        dropdown5.prop('selectedIndex', 0);
            
                        // Populate dropdown3 with list of bridge
                        for(var i=0; i<parameter_bridgeval.length; i++){            
                            dropdown5.append($('<option selected></option>').attr('value', parameter_bridgeval[i]['dev_name']).text(parameter_bridgeval[i]['display']));
                        }
                            
                        let dropdown_value_box = $('#value_box_' + counter);
                        $(dropdown_value_box).removeClass('opblink');
                
                        let dropdown_bridge_box = $('#bridge_box_' + counter);
                        $(dropdown_bridge_box).removeClass('opblink');
                        
                        let dropdown_period_box = $('#period_box_' + counter);
                        $(dropdown_period_box).addClass('opblink');
                
                        $("#period_box_" + counter).css("display","block"); 
                    }
                    if(metric_val == "has_decreased"){

                        var parameter_bridgeval = data_forecast.has_decreased.bridgeval;

                        let dropdown5 = $('#bridge_box_' + counter);
                        dropdown5.empty();
                        dropdown5.prop('selectedIndex', 0);
            
                        // Populate dropdown3 with list of bridge
                        for(var i=0; i<parameter_bridgeval.length; i++){            
                            dropdown5.append($('<option selected></option>').attr('value', parameter_bridgeval[i]['dev_name']).text(parameter_bridgeval[i]['display']));
                        }
                            
                        let dropdown_value_box = $('#value_box_' + counter);
                        $(dropdown_value_box).removeClass('opblink');
                
                        let dropdown_bridge_box = $('#bridge_box_' + counter);
                        $(dropdown_bridge_box).removeClass('opblink');
                        
                        let dropdown_period_box = $('#period_box_' + counter);
                        $(dropdown_period_box).addClass('opblink');
                
                        $("#period_box_" + counter).css("display","block");
                    }
                    if(metric_val == "has_no_Change"){

                        var parameter_bridgeval = data_forecast.has_no_Change.bridgeval;

                        let dropdown5 = $('#bridge_box_' + counter);
                        dropdown5.empty();
                        dropdown5.prop('selectedIndex', 0);
            
                        // Populate dropdown3 with list of bridge
                        for(var i=0; i<parameter_bridgeval.length; i++){            
                            dropdown5.append($('<option selected></option>').attr('value', parameter_bridgeval[i]['dev_name']).text(parameter_bridgeval[i]['display']));
                        }
                            
                        let dropdown_value_box = $('#value_box_' + counter);
                        $(dropdown_value_box).removeClass('opblink');
                
                        let dropdown_bridge_box = $('#bridge_box_' + counter);
                        $(dropdown_bridge_box).removeClass('opblink');
                        
                        let dropdown_period_box = $('#period_box_' + counter);
                        $(dropdown_period_box).addClass('opblink');
                
                        $("#period_box_" + counter).css("display","block");
                    }                    
                }                                           
    
            });

        });

        $('#value_box_' + counter).on('click', function() {
                        
            var operator_val = $("#operator_box_" + counter).val();
            var metric_val = $("#metric_box_" + counter).val();

            $("#bridge_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#text_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#bridge_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

            $('#value_box_' + counter).on('change', function() {

                if(operator_val == "rms_to_sell"){
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }           
                }else if(operator_val == "rms_sold") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "adr") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "revenue") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data2.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data2.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data2.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data2.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data2.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data2.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "occupancy") {
                    if(metric_val == "is_equal_to"){
                        var parameter = dataocc.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = dataocc.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = dataocc.is_less_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = dataocc.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = dataocc.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = dataocc.has_no_Change.bridgeval;
                    }            
                }else if(operator_val == "rooms_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "revenue_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "adr_move") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data3.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data3.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data3.is_less_than.bridgeval;
                    }
                }else if(operator_val == "my_pace") {
                    if(metric_val == "is_equal_to"){
                        var parameter = datamypace.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_ahead_by_more_than"){
                        var parameter = datamypace.is_ahead_by_more_than.bridgeval;
                    }
                    if(metric_val == "is_behind_by_more_than"){
                        var parameter = datamypace.is_behind_by_more_than.bridgeval;
                    }
                    if(metric_val == "has_improved"){
                        var parameter = datamypace.has_improved.bridgeval;
                    }
                    if(metric_val == "has_fallen_behind"){
                        var parameter = datamypace.has_fallen_behind.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = datamypace.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "my_price_or_rate") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_my_price_rate.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_my_price_rate.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_my_price_rate.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_my_price_rate.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_my_price_rate.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_my_price_rate.has_no_Change.bridgeval;                
                    }
                }else if(operator_val == "comp_average") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_compavg.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_compavg.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_compavg.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased_by"){
                        var parameter = data_compavg.has_increased_by.bridgeval;
                    }
                    if(metric_val == "has_decreased_by"){
                        var parameter = data_compavg.has_decreased_by.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_compavg.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "forecast_range") {
                    if(metric_val == "is_equal_to"){ 
                        var parameter = data_forecast.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_forecast.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_forecast.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_forecast.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_forecast.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_forecast.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "expected_pick_up") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_expected_pickup.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_expected_pickup.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_expected_pickup.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_expected_pickup.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_expected_pickup.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_expected_pickup.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "positioning") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_positioning.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_positioning.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_positioning.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_positioning.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_positioning.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_positioning.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "price_rank") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_pricerank.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_higher_than"){
                        var parameter = data_pricerank.is_higher_than.bridgeval;
                    }
                    if(metric_val == "is_lower_than"){
                        var parameter = data_pricerank.is_lower_than.bridgeval;
                    }
                    if(metric_val == "has_increased"){
                        var parameter = data_pricerank.has_increased.bridgeval;
                    }
                    if(metric_val == "has_decreased"){
                        var parameter = data_pricerank.has_decreased.bridgeval;
                    }
                    if(metric_val == "has_no_Change"){
                        var parameter = data_pricerank.has_no_Change.bridgeval;
                    }
                }else if(operator_val == "leadtime") {
                    if(metric_val == "is_equal_to"){
                        var parameter = data_leadtime.is_equal_to.bridgeval;
                    }            
                    if(metric_val == "is_greater_than"){
                        var parameter = data_leadtime.is_greater_than.bridgeval;
                    }
                    if(metric_val == "is_less_than"){
                        var parameter = data_leadtime.is_less_than.bridgeval;
                    }
                }else if(operator_val == "pick_up_trend") {
                    var parameter = data.pick_up_trend.bridgeval;
                }
            
                let dropdown5 = $('#bridge_box_' + counter);
                dropdown5.empty();
                dropdown5.prop('selectedIndex', 0);
    
                // Populate dropdown3 with list of bridge
                for(var i=0; i<parameter.length; i++){            
                    dropdown5.append($('<option selected></option>').attr('value', parameter[i]['dev_name']).text(parameter[i]['display']));
                }
                    
                let dropdown_value_box = $('#value_box_' + counter);
                $(dropdown_value_box).removeClass('opblink');
        
                let dropdown_bridge_box = $('#bridge_box_' + counter);
                $(dropdown_bridge_box).removeClass('opblink');
                
                let dropdown_period_box = $('#period_box_' + counter);
                $(dropdown_period_box).addClass('opblink');
        
                $("#period_box_" + counter).css("display","block");
    
            });

        });        

        $('#bridge_box_' + counter).on('click', function() {
                        
            $("#period_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#value_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#period_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#period_box_' + counter).on('click', function() {
                        
            $("#condition_box_" + counter).css("display","block");   
            
            let dropdown_operator_box = $('#bridge_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');

            let dropdown_valuebox = $('#condition_box_' + counter);
            $(dropdown_valuebox).addClass('opblink');

        });

        $('#condition_box_' + counter).on('change', function() {
                                    
            let dropdown_operator_box = $('#period_box_' + counter);
            $(dropdown_operator_box).removeClass('opblink');           

            let dropdown_condition_box_counter = $('#condition_box_' + counter);
            $(dropdown_condition_box_counter).removeClass('opblink');

            $("#table_operator>tbody>tr>td>select").removeClass("opblink");
            $("#table_operator>tbody>tr>td>input").removeClass("opblink");

            let condition_val = $('#condition_box_' + counter).val();
            
            if(condition_val != ""){                
                $("#condition_box_" + counter).css("color","green");
                $("#condition_box_" + counter).css("font-weight","bold");
                $("#addnewtrbtn_" + counter).css("display","block");
                $("#removenewtrbtn_" + counter).css("display","block");
                
                var counteradd = counter + 1;
                let remove_tr_added = $('#tr_added_' + counteradd);        

                if($(remove_tr_added).length){    
                    $("#addnewtrbtn_" + counter).css("display","none");
                    $("#removenewtrbtn_" + counter).css("display","block");                     
                }else{
                    // $("#addnewtrbtn_" + counter).css("display","block");
                    // $("#removenewtrbtn_" + counter).css("display","block");
                }

            }else{                                
                $("#addnewtrbtn_" + counter).css("display","none");
                $("#removenewtrbtn_" + counter).css("display","block");            

                var counteradd = counter + 1;
                let remove_tr_added = $('#tr_added_' + counteradd);

                if($(remove_tr_added).length){ 
                    $(remove_tr_added).remove();
                }else{
                }

            }            

            $('#removenewtrbtn_' + counter).on('click', function() {

                let remove_tr_added = $('#tr_added_' + counter);        
                $(remove_tr_added).remove(); 
    
            });                                

        });      
        
        var flag = this;        

        $('#addnewtrbtn_' + counter).click(function(){
            // console.log(1);
            $("#addnewtrbtn_" + counter).css("display","none");
            $("#removenewtrbtn_" + counter).css("display","block");
            flag.addnewtr();
        });

        $('#text_box_' + counter).on('input', function() {
            var len = $(this).val().length;            
            $(this).parent(0).css("width", len + 3 +'%');
        });          
        
        $('#select_box_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_pricerank_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_box_myprice_rate_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 4 + '%');                                

        });

        $('#select_box_pace_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');                                

        });

        $('#value_box_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 3 + '%');                                

        });

        $('#select_forecast_' + counter).on('change', function() {

            var len = $(this).val().length;
            $(this).parent(0).css("width", len + 1 + '%');                               

        });

        $('#occ_text_box_' + counter).on("input", function() {

            ($(this).parent(0)).parent(0).css("width", '11%');           

        });
                
    }   

    displaySchedule(){
        
        if($('#no_schedule').is(":checked")){
            $('#mon_schedule').prop('checked', false);
            $('#tue_schedule').prop('checked', false);
            $('#wed_schedule').prop('checked', false);
            $('#thu_schedule').prop('checked', false);
            $('#fri_schedule').prop('checked', false);
            $('#sat_schedule').prop('checked', false);
            $('#sun_schedule').prop('checked', false);
            $('#schedule_time').val('');

            // $("#no_schedule_display .checkmark").css("background", "#e9ecef");            
            $(".scheduledays").prop("disabled", true);
            $("#schedule_time").prop("disabled", true);
        }else{
            // $("#no_schedule_display .checkmark").css("background", "none");
            $(".scheduledays").prop("disabled", false);
            $("#schedule_time").prop("disabled", false);
        }       

    }

    getschedule_daysvalues(){

        if( $('#no_schedule').is(":checked") == true){            
            this.schedule_days_obj.no_schedule = 1;
        }else{
            this.schedule_days_obj.no_schedule = 0;
        }

        if( $('#mon_schedule').is(":checked") == true){
            this.schedule_days_obj.mon_schedule = 1;
        }else{
            this.schedule_days_obj.mon_schedule = 0;
        }

        if( $('#tue_schedule').is(":checked") == true){
            this.schedule_days_obj.tue_schedule = 1;
        }else{
            this.schedule_days_obj.tue_schedule = 0;
        }

        if( $('#wed_schedule').is(":checked") == true){
            this.schedule_days_obj.wed_schedule = 1;
        }else{
            this.schedule_days_obj.wed_schedule = 0;
        }

        if( $('#thu_schedule').is(":checked") == true){
            this.schedule_days_obj.thu_schedule = 1;
        }else{
            this.schedule_days_obj.thu_schedule = 0;
        }

        if( $('#fri_schedule').is(":checked") == true){
            this.schedule_days_obj.fri_schedule = 1;
        }else{
            this.schedule_days_obj.fri_schedule = 0;
        }

        if( $('#sat_schedule').is(":checked") == true){
            this.schedule_days_obj.sat_schedule = 1;
        }else{
            this.schedule_days_obj.sat_schedule = 0;
        }

        if( $('#sun_schedule').is(":checked") == true){
            this.schedule_days_obj.sun_schedule = 1;
        }else{
            this.schedule_days_obj.sun_schedule = 0;
        }  

    }

    savedata(){
        
        let flag = this;
        flag.getschedule_daysvalues(); 
        
        /*******************/
        var tableobj=[];
        
        $('table tbody tr').each(function(){
            var obj = {};
            var inputs = $(this).find('input');            
            inputs.each(function(){
                obj[$(this).attr('id')] = this.value;
            });                        

            var select = $(this).find('select');
            select.each(function(){
                obj[$(this).attr('id')] = this.value;
            });                   

            tableobj.push(obj);

        });
        
        let finalarray = [];

        for(var i = 0; i < flag.filterarr.length; i++){
            finalarray.push(flag.filterarr[i]);                        
        }

        for(var i = 0; i < tableobj.length; i++){            
            if(Object.keys(tableobj[i]).length === 0){
                // console.log(tableobj[i]);
                // const index = tableobj.indexOf(tableobj[i]);
            }else{
                finalarray.push(tableobj[i]);
            }            
        }

        // console.log(finalarray);   
            
        /*******************/
        var selectedhotels = [];
        $('#checkboxes_multi input:checked').each(function() {            
            selectedhotels.push($(this).attr('value'));                        
        });      
        // console.log(selectedhotels);        
        // console.log(Object.keys(selectedhotels)[0]);
        // console.log(selectedhotels[Object.getOwnPropertyNames(selectedhotels)[0]]);

        if(selectedhotels[Object.getOwnPropertyNames(selectedhotels)[0]] == ''){
            selectedhotels.shift();
        }

        /*******************/

        // Create Final Data Object
        let dataObj = {
            'hotel_id': this.hotel_id,
            'notify_id': $('#notify_id').val(),
            'recordDate': infinito.vao.controller.storageHelper.getCurrentDate(),
            'stayDate': infinito.vao.controller.storageHelper.getCurrentDate(),
            'offset': infinito.vao.controller.storageHelper.getPickupOffset(),
            'identity_id': infinito.vao.controller.storageHelper.getIdentitiesId(),            
            'template_name': $('#template_name').val(),
            'email_subject': $('#email_subject').val(),
            'schedule_days': this.schedule_days_obj,
            'schedule_time':$('#schedule_time').val(),
            'filters': finalarray,
            'hotels': selectedhotels,
            'op': 'updateTemplate',
            'serviceName': "notifymangement.php"
        };

        // console.log(dataObj);        
        // return false;

        sendRequest(dataObj, function (res) {
            
            // var result = JSON.parse(res);
            // console.log(result['status']);
            // console.log(result['msg']); 

            $(".close").trigger("click");
            flag.gettemplate();

        });    
    }

    gettemplate(){        
        
        infinito.vao.model.ssoMe.fetchMe(function(data){

            let dataObj = {
                'email': data.data.email,
                'op': 'getTemplatedata',
                'serviceName': "notifymangement.php"
            };
    
            sendRequest(dataObj, function (res) {
                           
                // console.log(res);

                $("#table_templates").find('tbody').empty();

                let jsonObj = JSON.parse(res);
                let cnt = 1;                                            
                
                for(var i = 0; i < jsonObj['data'].length; i++){
                    
                    // console.log(jsonObj['data'][i]);                     

                    let addrow = `
                        <tr>
                            <td>`+ cnt +`</td>
                            <td>`+ jsonObj['data'][i]['template_name'] +`</td>
                            <td>`+ jsonObj['data'][i]['email_subject'] +`</td>
                            <td>`+ jsonObj['data'][i]['schedule_time'] +`</td>
                            <td>
                                <button type="button" id="`+ jsonObj['data'][i]['notify_id'] +`" class="btn-info btn-class edittemplate"><i class="fa fa-pen"></i></button>
                                <button type="button" id="`+ jsonObj['data'][i]['notify_id'] +`" class="btn-danger btn-class deletetemplate"><i class="fa fa-trash"></i></button>
                            </td>                                        
                        </tr>
                    `;

                    $("#table_templates").find('tbody').append(addrow); 
                    cnt++;

                }

                $('.deletetemplate').on('click', function(){
                                
                    console.log('i am delete from edit popup');

                    let notify_id = $(this).attr('id');

                    $('<div></div>').appendTo('body')
                        .html('<div><h7> Are you sure you want to remove template?</h7></div>')
                        .dialog({
                        modal: true,
                        title: 'Delete Template',
                        zIndex: 10000,
                        autoOpen: true,
                        width: 450,
                        resizable: false,
                        // open: function(){
                        //     $('.ui-widget-overlay').hide().fadeIn();
                        // },
                        // show: "fade",
                        // hide: "fade",
                        dialogClass: 'no-close success-dialog',
                        buttons: {
                            Yes: function() {                                        

                                $('body').append('<h1 class="btn-success">Confirm Dialog Result: <i>Yes</i></h1>');

                                $(this).dialog("close");                                                                          
                                
                                let deleteObj = {
                                    'notify_id': notify_id,
                                    'op': 'deleteTemplate',
                                    'serviceName': "notifymangement.php"
                                };                           
                        
                                sendRequest(deleteObj, function (res) {                                                                                                                
                                        
                                    infinito.vao.model.ssoMe.fetchMe(function(data){                                     

                                        let dataObj = {
                                            'email': data.data.email,
                                            'op': 'getTemplatedata',
                                            'serviceName': "notifymangement.php"                                        
                                        };
                                    
                                        sendRequest(dataObj, function (res) {                                       
                                                
                                            $("#table_templates").find('tbody').empty(); 

                                            let jsonObj = JSON.parse(res);
                                            let cnt = 1;                            
                                
                                            for(var i = 0; i < jsonObj['data'].length; i++){
                                                
                                                // console.log(jsonObj['data'][i]); 
                                
                                                let addrow = `
                                                    <tr>
                                                        <td>`+ cnt +`</td>
                                                        <td>`+ jsonObj['data'][i]['template_name'] +`</td>
                                                        <td>`+ jsonObj['data'][i]['email_subject'] +`</td>
                                                        <td>`+ jsonObj['data'][i]['schedule_time'] +`</td>
                                                        <td>
                                                            <button type="button" id="`+ jsonObj['data'][i]['notify_id'] +`" class="btn-info btn-class edittemplate"><i class="fa fa-pen"></i></button>
                                                            <button type="button" id="`+ jsonObj['data'][i]['notify_id'] +`" class="btn-danger btn-class deletetemplate"><i class="fa fa-trash"></i></button>
                                                        </td>                                        
                                                    </tr>
                                                `;
                                
                                                $("#table_templates").find('tbody').append(addrow); 
                                                cnt++;
                                
                                            }
                                        });
                                    });

                                });                                                                           

                            },
                            No: function() {
                            // $('body').append('<h1 class="btn-danger">Confirm Dialog Result: <i>No</i></h1>');

                            $(this).dialog("close");
                            }
                        },
                        close: function(event, ui) {
                            $(this).remove();
                        }
                    });               
                    
                    $('.ui-dialog-titlebar-close').css('display','none');  

                });

                $('.edittemplate').on('click', function(){
                    
                    let notify_id = $(this).attr('id');

                    let body = '<vao-edit-template></vao-edit-template>';
                    let $title =  $('<div><input type="hidden" id="notify_id" value="'+notify_id+'" > </div>').append('<h5>Edit Template</h5>');

                    window.infinito.components.basicModal.render({
                        title: $title,
                        isTitleHtml: true,
                        bodyElem: $(body),
                        size:'xl'
                    });                                

                });
    
            });

        });                

    }

}
 
window.vao = window.vao || {}; 
window.vao.components = window.vao.components || {};
window.vao.components.EditTemplate = EditTemplate;
customElements.define('vao-edit-template', EditTemplate); 