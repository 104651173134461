import { AppElement, html } from '../AppElement.js';
import {sendRequest} from '../App.common';
import TableLoadingRow from "../table/TableLoadingRow";
import TableNoDataRow from "../table/TableNoDataRow";
import Table from "../table/Table";
import Constants from "../Constants";
import TableColumn from "../table/TableColumn";
import TableCell, {tableCellTypes} from "../table/TableCell";
import TableRow from "../table/TableRow";
import NumericVarianceBlock from "../numericVarianceBlock/NumericVarianceBlock";
import ActualWithVarianceTableCellValue from "../table/ActualWithVarianceTableCellValue";
import {
    findCtxStatValueInProcDataByFieldByStayDate,
    findMatchingCtxMetricForCategoryByCtxMetricForCtxValues
} from "../StatisticUtils";
import {formatOccupancy, objectify} from "../Utils";
import Button from "../button/Button";
import Tag from "../tag/Tag";
import ColorArray from "../ColorArray";
import {getRecordMatcherForCategory} from "../CtxUtils";
import {getIdentityDependantTableStateKey} from "../TableUtils";

export const cellFieldMap = {
    stayDate: 'stayDate',
    occupancy: 'occupancy',
    rooms: 'rooms',
    adr: 'adr',
    revenue: 'revenue',

};

export default class ContextTable extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            firstStayDate: { type: String },
            lastStayDate: { type: String },
            pickupSetting: { type: Number },
            metric: { type: String },
        };
    }

    constructor(props = {}) {
        super();
        this.hotel = props.hotel;
        this.recordDate = props.recordDate;
        this.firstStayDate = props.firstStayDate;
        this.lastStayDate = props.lastStayDate;
        this.pickupSetting = props.pickupSetting;
        this.metric = props.metric;

        this.id = AppElement.getUniqueElementId();
        this.cache = {};
        this.contexts = new Set();
        this.biStatistics = window.infinito.vao.model.biStatistics;
        this.pickupHelper = window.infinito.vao.controller.pickupHelper;
        this.dateHelper = window.infinito.vao.controller.dateHelper;
        this.fillId = 0;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.firstStayDate = props.firstStayDate || this.firstStayDate;
        this.lastStayDate = props.lastStayDate || this.lastStayDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.metric = props.metric || this.metric;
        this.fill();
    }

    getCategory() {
        throw new Error(Constants.STRINGS.ABSTRACT_FUNC_ERR_MSG);
    }

    reflowTable(rows = [], isLoading = false) {
        let innerRows;
        if (!rows || !Array.isArray(rows) || rows.length === 0) {
            this.cache.rowMap = new Map();
            if (isLoading) {
                innerRows = [
                    new TableLoadingRow()
                ]
            } else {
                innerRows = [
                    new TableNoDataRow()
                ];
            }
        } else {
            innerRows = rows;
        }

        let table = this.acquireTableEl();
        if (table instanceof Table) {
            table.reflow({
                columns: this.acquireColumns(),
                rows: innerRows
            });
        }

        return table;
    }

    acquireTableEl() {
        if ('table' in this.cache && this.cache.table instanceof Table) {
            // Already exists.
        } else {
            const stateKey = getIdentityDependantTableStateKey(`ContextTable_${this.getCategory()}`);
            let table = new Table({
                stateKey: stateKey,
                tableOpts: {
                    stickyHeaderMinHeight: '70vh'
                },
                rows: [
                    new TableNoDataRow()
                ],
            });
            this.cache['table'] = table;
        }

        return this.cache.table;
    }

    getFieldsForMetric(metric) {
        if (metric === Constants.METRICS.OCCUPANCY) {
            return [
                this.biStatistics.fields.occupancyCtx,
                this.biStatistics.fields.occupancyCtxPickup
            ]
        } else if (metric === Constants.METRICS.ROOMS) {
            return [
                this.biStatistics.fields.roomsOtbCtx,
                this.biStatistics.fields.roomsOtbCtxPickup
            ];
        } else if (metric === Constants.METRICS.ADR) {
            return [
                this.biStatistics.fields.adrCtx,
                this.biStatistics.fields.adrCtxPickup
            ];
        } else if (metric === Constants.METRICS.REVENUE) {
            return [
                this.biStatistics.fields.revenueCtx,
                this.biStatistics.fields.revenueCtxPickup
            ];
        } else {
            return [];
        }
    }

    domTheTable(table) {
        $('#' + this.id).find('.vao__components--contextTable-table').empty().append(table);
    }

    empty() {
        let table = this.reflowTable([]);
        this.domTheTable(table);
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.fill();
    }

    canFill() {
        return (
            this.hotel
            && typeof this.hotel === 'object'
            && ('id' in this.hotel)
            && this.dateHelper.isDateStr(this.recordDate)
            && this.dateHelper.isDateStr(this.firstStayDate)
            && this.dateHelper.isDateStr(this.lastStayDate)
            && $.isNumeric(this.pickupSetting)
            && (this.metric in Constants.METRICS)
        );
    }

    acquireLeafColumn(field, value, isSortable = false) {
        const cacheKey = `col_${field}`;
        if (cacheKey in this.cache) {
            return this.cache[cacheKey];
        }
        const col = new TableColumn(
            new TableCell({
                field: field,
                value: value,
                type: tableCellTypes.TH
            }),
            [],
            undefined,
            null,
            true,
            isSortable
        );
        this.cache[cacheKey] = col;
        return col;
    }

    acquireColumns() {
        const contextSetLen = this.contexts.size;
        const cacheKey = `cols_${contextSetLen}`;
        if (cacheKey in this.cache) {
            return this.cache[cacheKey];
        }
        let cols = [];
        cols.push(this.acquireLeafColumn(cellFieldMap.stayDate, Constants.STRINGS.STAY_DATE, true));
        cols.push(this.acquireLeafColumn(cellFieldMap.occupancy, Constants.STRINGS.OCC, true));
        cols.push(this.acquireLeafColumn(cellFieldMap.rooms, Constants.STRINGS.ROOMS, true));
        cols.push(this.acquireLeafColumn(cellFieldMap.adr, Constants.STRINGS.ADR, true));
        cols.push(this.acquireLeafColumn(cellFieldMap.revenue, Constants.STRINGS.REV, true));
        this.contexts.forEach(ctx => {
            cols.push(this.acquireLeafColumn(ctx, ctx, true));
        });
        this.cache[cacheKey] = cols;
        return cols;
    }

    getStayDates() {
        return this.dateHelper.calcStayDateRangeArray(this.firstStayDate, this.lastStayDate);
    }

    acquireRowByStayDate(stayDate) {
        const cacheKey = `row_${stayDate}`;
        if (cacheKey in this.cache) {
            return this.cache[cacheKey];
        }
        const prettyStayDate = this.dateHelper.getShortDayDateMonFullYearString(stayDate);
        const row = new TableRow([new TableCell({
            field: cellFieldMap.stayDate,
            value: new Button({
                text: prettyStayDate,
                variant: 'invert',
                size: 'xs',
                onClick: () => {
                    let expandedCategory = this.getCategory();
                    let prettyPickupSetting = this.pickupHelper.getPickupOffsetTextForPickupOffset(this.pickupSetting);
                    let body = new vao.components.MetricBreakdown({
                        recordDate: this.recordDate,
                        hotel: this.hotel,
                        stayDate: stayDate,
                        pickupSetting: this.pickupSetting,
                        expandedCategory: expandedCategory
                    });
                    let prettyRecordDate = this.dateHelper.getShortDayDateMonFullYearString(this.recordDate);
                    let $title =  $('<div></div>').append(
                        `<h5>${Constants.STRINGS.METRIC_BREAKDOWN}</h5>`
                    ).append(new vao.components.MiniContext({
                        recordDate: prettyRecordDate,
                        pickupSetting: prettyPickupSetting,
                        isModalStyle: false
                    }));
                    window.infinito.components.basicModal.render({
                        title: $title,
                        isTitleHtml: true,
                        bodyElem: $(body),
                        size: 'lg'
                    });
                }
            }),
            sortableValue: stayDate
        })], {
            stayDate
        });
        this.cache[cacheKey] = row;
        return row;
    }

    acquireTableCellByFieldForTableRow(field, row) {
        if (!row.tableCellPerField.has(field)) {
            let newCell = new TableCell({
                field
            });
            row.tableCellPerField.set(field, newCell);
        }
        return row.tableCellPerField.get(field);
    }

    fillRoomsOtbCell(cell, category, ctx, nowCtxValue, thenCtxValue) {
        let cellValue;
        let sortableCellValue;
        if (
            nowCtxValue
            && typeof nowCtxValue === 'object'
            && category in nowCtxValue
        ) {
            const ctxMetrics = nowCtxValue[category];
            const ctxMetric = ctxMetrics.find(ctxMetricCandidate => {
                return ctxMetricCandidate.ctx === ctx;
            });
            if (ctxMetric && $.isNumeric(ctxMetric.value)) {
                this.hasAnyData = true;
                let numVarBlock;
                const ctxMetricPickupMatch = findMatchingCtxMetricForCategoryByCtxMetricForCtxValues(
                    category,
                    ctxMetric,
                    thenCtxValue
                );
                if (ctxMetricPickupMatch && $.isNumeric(ctxMetricPickupMatch.value)) {
                    numVarBlock = new NumericVarianceBlock({
                        numericValue: ctxMetricPickupMatch.value,
                        formattedValue: String(ctxMetricPickupMatch.value),
                        beEmptyOnEmpty: 'true'
                    });
                }
                cellValue = new ActualWithVarianceTableCellValue({
                    actual: ctxMetric.value,
                    numericVarianceBlock: numVarBlock
                });
                sortableCellValue = parseFloat(ctxMetric.value);
            }
        }
        if (!cellValue) {
            cellValue = Constants.MISSING_STR;
            sortableCellValue = null;
        }
        cell.value = cellValue;
        cell.sortableValue = sortableCellValue;
    }

    makeOccupancyCellActualValue(input) {
        let val = input;
        if (!$.isNumeric(val)) {
            val = Constants.MISSING_STR;
        } else {
            const occVal = formatOccupancy(val);
            const tag = new Tag({
                label: occVal,
                size: Constants.SIZES.SMALL
            });
            const occNumericVal = parseFloat(occVal.replace('%', ''));
            const deferred = window.infinito.vao.controller.demandHelper.fetchDemandColorFor(
                occNumericVal
            );
            $.when(deferred).then((color) => {
                let labelColor;
                if (ColorArray.isHexColor(color) && occNumericVal > 0) {
                    labelColor = color;
                } else {
                    labelColor = Constants.COLORS.HEX.TEXT_MUTED;
                }
                tag.reflow({
                    labelColor
                });
            });
            val = tag;
        }
        return val;
    }

    fillOccupancyCell(cell, category, ctx, nowCtxValue, thenCtxValue) {
        let cellValue;
        let sortableCellValue;
        if (
            nowCtxValue
            && typeof nowCtxValue === 'object'
            && category in nowCtxValue
        ) {
            const ctxMetrics = nowCtxValue[category];
            const ctxMetric = ctxMetrics.find(ctxMetricCandidate => {
                return ctxMetricCandidate.ctx === ctx;
            });
            if (ctxMetric && $.isNumeric(ctxMetric.value)) {
                this.hasAnyData = true;
                let numVarBlock;
                const ctxMetricPickupMatch = findMatchingCtxMetricForCategoryByCtxMetricForCtxValues(
                    category,
                    ctxMetric,
                    thenCtxValue
                );
                if (ctxMetricPickupMatch && $.isNumeric(ctxMetricPickupMatch.value)) {
                    let pickup = formatOccupancy(ctxMetricPickupMatch.value);
                    numVarBlock = new NumericVarianceBlock({
                        numericValue: ctxMetricPickupMatch.value,
                        formattedValue: pickup,
                        beEmptyOnEmpty: 'true'
                    });
                }
                cellValue = new ActualWithVarianceTableCellValue({
                    actual: this.makeOccupancyCellActualValue(ctxMetric.value),
                    numericVarianceBlock: numVarBlock
                });
                sortableCellValue = parseFloat(ctxMetric.value);
            }
        }
        if (!cellValue) {
            cellValue = Constants.MISSING_STR;
            sortableCellValue = null;
        }
        cell.value = cellValue;
        cell.sortableValue = sortableCellValue;
    }

    fillRevenueCell(cell, category, ctx, nowCtxValue, thenCtxValue) {
        let cellValue;
        let sortableCellValue;
        if (
            nowCtxValue
            && typeof nowCtxValue === 'object'
            && category in nowCtxValue
        ) {
            const ctxMetrics = nowCtxValue[category];
            const ctxMetric = ctxMetrics.find(ctxMetricCandidate => {
                return ctxMetricCandidate.ctx === ctx;
            });
            if (ctxMetric && $.isNumeric(ctxMetric.value)) {
                this.hasAnyData = true;
                let numVarBlock;
                let nowFormatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    ctxMetric.value,
                    Constants.REVENUE_DIGITS,
                    true,
                    this.hotel.locale
                );
                const ctxMetricPickupMatch = findMatchingCtxMetricForCategoryByCtxMetricForCtxValues(
                    category,
                    ctxMetric,
                    thenCtxValue
                );
                if (ctxMetricPickupMatch && $.isNumeric(ctxMetricPickupMatch.value)) {
                    const thenFormatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ctxMetricPickupMatch.value,
                        Constants.REVENUE_DIGITS,
                        true,
                        this.hotel.locale
                    );
                    numVarBlock = new NumericVarianceBlock({
                        numericValue: ctxMetricPickupMatch.value,
                        formattedValue: thenFormatted,
                        beEmptyOnEmpty: 'true'
                    });
                }
                cellValue = new ActualWithVarianceTableCellValue({
                    actual: nowFormatted,
                    numericVarianceBlock: numVarBlock
                });
                sortableCellValue = parseFloat(ctxMetric.value);
            }
        }
        if (!cellValue) {
            cellValue = Constants.MISSING_STR;
            sortableCellValue = null;
        }
        cell.value = cellValue;
        cell.sortableValue = sortableCellValue;
    }

    fillAdrCell(cell, category, ctx, nowCtxValue, thenCtxValue) {
        let cellValue;
        let sortableCellValue;
        if (
            nowCtxValue
            && typeof nowCtxValue === 'object'
            && category in nowCtxValue
        ) {
            const ctxMetrics = nowCtxValue[category];
            const ctxMetric = ctxMetrics.find(ctxMetricCandidate => {
                return ctxMetricCandidate.ctx === ctx;
            });
            if (ctxMetric && $.isNumeric(ctxMetric.value)) {
                this.hasAnyData = true;
                let numVarBlock;
                let nowFormatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    ctxMetric.value,
                    Constants.ADR_DIGITS,
                    true,
                    this.hotel.locale,
                    1
                );
                const ctxMetricPickupMatch = findMatchingCtxMetricForCategoryByCtxMetricForCtxValues(
                    category,
                    ctxMetric,
                    thenCtxValue
                );
                if (ctxMetricPickupMatch && $.isNumeric(ctxMetricPickupMatch.value)) {
                    const thenFormatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                        ctxMetricPickupMatch.value,
                        Constants.ADR_DIGITS,
                        true,
                        this.hotel.locale,
                        1
                    );
                    numVarBlock = new NumericVarianceBlock({
                        numericValue: ctxMetricPickupMatch.value,
                        formattedValue: thenFormatted,
                        beEmptyOnEmpty: 'true'
                    });
                }
                cellValue = new ActualWithVarianceTableCellValue({
                    actual: nowFormatted,
                    numericVarianceBlock: numVarBlock
                });
                sortableCellValue = parseFloat(ctxMetric.value);
            }
        }
        if (!cellValue) {
            cellValue = Constants.MISSING_STR;
            sortableCellValue = null;
        }
        cell.value = cellValue;
        cell.sortableValue = sortableCellValue;
    }

    findCtxStatValueInProcDataByFieldByStayDate(procData, field, stayDate) {
        return findCtxStatValueInProcDataByFieldByStayDate(procData, field, stayDate);
    }

    fillForStayDateWithProcData(stayDate, procData) {
        let row = this.acquireRowByStayDate(stayDate);
        const occCtxVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.occupancyCtx,
            stayDate
        );
        const occCtxPickupVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.occupancyCtxPickup,
            stayDate
        );
        const roomsOtbCtxVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.roomsOtbCtx,
            stayDate
        );
        const roomsOtbCtxPickupVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.roomsOtbCtxPickup,
            stayDate
        );
        const adrCtxVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.adrCtx,
            stayDate
        );
        const adrCtxPickupVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.adrCtxPickup,
            stayDate
        );
        const revCtxVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.revenueCtx,
            stayDate
        );
        const revCtxPickupVal = this.findCtxStatValueInProcDataByFieldByStayDate(
            procData,
            this.biStatistics.fields.revenueCtxPickup,
            stayDate
        );
        const category = this.getCategory();
        const propertyCategory = Constants.CTX_CATEGORIES.PROPERTY;
        const propertyCtx = Constants.CTX_PROPERTY_KEY;
        let cell;

        cell = this.acquireTableCellByFieldForTableRow(cellFieldMap.occupancy, row);
        this.fillOccupancyCell(cell, propertyCategory, propertyCtx, occCtxVal, occCtxPickupVal);

        cell = this.acquireTableCellByFieldForTableRow(cellFieldMap.rooms, row);
        this.fillRoomsOtbCell(cell, propertyCategory, propertyCtx, roomsOtbCtxVal, roomsOtbCtxPickupVal);

        cell = this.acquireTableCellByFieldForTableRow(cellFieldMap.adr, row);
        this.fillAdrCell(cell, propertyCategory, propertyCtx, adrCtxVal, adrCtxPickupVal);

        cell = this.acquireTableCellByFieldForTableRow(cellFieldMap.revenue, row);
        this.fillRevenueCell(cell, propertyCategory, propertyCtx, revCtxVal, revCtxPickupVal);

        let metricValObj = {};
        let metricPickupValObj = {};
        let filler = () => {};
        if (this.metric === Constants.METRICS.ROOMS) {
            metricValObj = roomsOtbCtxVal;
            metricPickupValObj = roomsOtbCtxPickupVal;
            filler = this.fillRoomsOtbCell;
        } else if (this.metric === Constants.METRICS.OCCUPANCY) {
            metricValObj = occCtxVal;
            metricPickupValObj = occCtxPickupVal;
            filler = this.fillOccupancyCell;
        } else if (this.metric === Constants.METRICS.ADR) {
            metricValObj = adrCtxVal;
            metricPickupValObj = adrCtxPickupVal;
            filler = this.fillAdrCell;
        } else if (this.metric === Constants.METRICS.REVENUE) {
            metricValObj = revCtxVal;
            metricPickupValObj = revCtxPickupVal;
            filler = this.fillRevenueCell;
        }
        if (Array.isArray(metricValObj[category]) && metricValObj[category].length > 0) {
            metricValObj[category].forEach(categoriesCtxMetric => {
                const ctx = categoriesCtxMetric.ctx;
                this.contexts.add(ctx);
                let cell = this.acquireTableCellByFieldForTableRow(ctx, row);
                filler.call(this, cell, category, ctx, metricValObj, metricPickupValObj);
            });
        }

        return row;
    }

    fill() {
        if (!this.canFill()) {
            this.empty();
            return;
        }

        const category = this.getCategory();
        const recordMatcher = getRecordMatcherForCategory(category);
        const stayDates = this.getStayDates();
        if (recordMatcher === null || stayDates.length === 0) {
            this.empty();
            return;
        }

        let table = this.reflowTable([], [],true);
        this.domTheTable(table);

        this.fillId = this.fillId + 1;
        const currentFillId = this.fillId; // Track the state of fillId as of before the async data request.

        this.biStatistics.fetchStatistics(
            this.hotel.id,
            this.biStatistics.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.firstStayDate,
                lastStayDate: this.lastStayDate,
                fields: [
                    this.biStatistics.fields.occupancyCtx,
                    this.biStatistics.fields.occupancyCtxPickup,
                    this.biStatistics.fields.roomsOtbCtx,
                    this.biStatistics.fields.roomsOtbCtxPickup,
                    this.biStatistics.fields.adrCtx,
                    this.biStatistics.fields.adrCtxPickup,
                    this.biStatistics.fields.revenueCtx,
                    this.biStatistics.fields.revenueCtxPickup
                ],
                recordMatcher: recordMatcher,
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {

                if(recordMatcher == "roomType"){
                    this.recordDate = this.firstStayDate;
                    let obj = procData.occupancyCtx;
                    let pickupobj = procData.occupancyCtxPickup;
                    let receivedTs = procData.occupancyCtx[this.recordDate]['receivedTs'];
                    let pickupreceivedTs = procData.occupancyCtxPickup[this.recordDate]['offsetReceivedTs'];
                    let flag = this;
                    let dataObj = {
                        'hotel_id':this.hotel.id,
                        'recordDate':this.recordDate,
                        'pickupOffset':this.pickupSetting,
                        'firstStayDate': this.firstStayDate,
                        'lastStayDate': this.lastStayDate,
                        'receivedTs': receivedTs,
                        'pickupreceivedTs': pickupreceivedTs,
                        'op':'getRoomtypeOccupancy',
                        'serviceName':"roomtypesapi.php"
                    };

                    sendRequest(dataObj,function(res){
                        let jsonObj = JSON.parse(res);
                        var resultObj = jsonObj.data['occ'];
                        for(var i=0;i<resultObj.length;i++){
                            var staydt = resultObj[i]['stayDate'];
                            var roomtypeObj = obj[staydt]['value']['roomType'];
                            for(var j=0;j<roomtypeObj.length;j++){
                                if(roomtypeObj[j]['ctx'] == resultObj[i]['name']){
                                    let occ = resultObj[i]['occ'];
                                    procData.occupancyCtx[staydt]['value']['roomType'][j]['value'] = occ;
                                }
                            }
                        }

                        var pickupresultObj = jsonObj.data['pickupOcc'];
                        for(var i=0;i<pickupresultObj.length;i++){
                            var staydt = pickupresultObj[i]['stayDate'];
                            var roomtypeObj = pickupobj[staydt]['value']['roomType'];
                            for(var j=0;j<roomtypeObj.length;j++){
                                if(roomtypeObj[j]['ctx'] == pickupresultObj[i]['name']){
                                    let occ = pickupresultObj[i]['occ'];
                                    procData.occupancyCtxPickup[staydt]['value']['roomType'][j]['value'] = occ;
                                }
                            }
                        }

                        const _procData = (procData || {});

                        if (flag.fillId !== currentFillId) {
                            // Another fill invocation has already been made whilst this ones data was loading.
                            console.warn('Redundant API request was made.');
                            return;
                        }

                        flag.hasAnyData = false;
                        let rows = [];
                        stayDates.forEach(stayDate => {
                            let row = flag.fillForStayDateWithProcData(stayDate, _procData);
                            if (row instanceof TableRow) {
                                rows.push(row);
                            }
                        });

                        if (!flag.hasAnyData) {
                            flag.empty();
                        } else {
                            flag.reflowTable(rows);
                        }

                    });
                }else{
                    const _procData = (procData || {});

                    if (this.fillId !== currentFillId) {
                        // Another fill invocation has already been made whilst this ones data was loading.
                        console.warn('Redundant API request was made.');
                        return;
                    }

                    this.hasAnyData = false;
                    let rows = [];
                    stayDates.forEach(stayDate => {
                        let row = this.fillForStayDateWithProcData(stayDate, _procData);
                        if (row instanceof TableRow) {
                            rows.push(row);
                        }
                    });

                    if (!this.hasAnyData) {
                        this.empty();
                    } else {
                        this.reflowTable(rows);
                    }
                }

            }
        )
    }

    render() {
        return html`
            <div class="vao__components--contextTable">
                <div class="vao__components--contextTable-table"></div>
            </div>
        `;
    }

}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.ContextTable = ContextTable;
customElements.define('vao-context-table', ContextTable);
