import { AppElement, html } from '../AppElement.js';
import Constants from '../Constants';

export default class StoryLastRateChangeSubtitle extends AppElement {
    static get properties() {
        return {
            subtitle: { type: String },
            badgeClass: { type: String }
        };
    }

    constructor(props = {}) {
        super();

        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};

        this.empty();
    }

    reflow(props = {}) {
        this.recordDate = props.recordDate || Constants.MISSING_STR;
        this.stayDate = props.stayDate || Constants.MISSING_STR;
        this.hotel = props.hotel || {};
        this.fill();
    }

    empty() {
        this.subtitle = Constants.MISSING_STR;
        this.badgeClass = '';
    }

    fill() {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.stayDate)
        ) {
            this.empty();
            return;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: this.stayDate,
                lastStayDate: this.stayDate,
                fields: [
                    modelBiStats.fields.ratesLastChanges
                ]
            }),
            (data, procData) => {
                let ratesLastChanges =
                    ((procData.ratesLastChanges || {})[this.stayDate] || {}).value || null;

                let primChannelName = window.infinito.vao.controller.channelHelper.getPrimaryChannelsName();

                this.badgeClass = 'badge-primary';

                if (
                    ratesLastChanges
                    && typeof ratesLastChanges === 'object'
                    && primChannelName in ratesLastChanges
                    && typeof ratesLastChanges[primChannelName] === 'object'
                    && 'dayDiff' in ratesLastChanges[primChannelName]
                    && $.isNumeric(ratesLastChanges[primChannelName].dayDiff)
                ) {
                    let dayDiffTxt =  ratesLastChanges[primChannelName].dayDiff + ' days ago';
                    if (
                        $.isNumeric(ratesLastChanges[primChannelName].currentVal)
                        && $.isNumeric(ratesLastChanges[primChannelName].offsetVal)
                    ) {
                        let diff = ratesLastChanges[primChannelName].currentVal -
                            ratesLastChanges[primChannelName].offsetVal;
                        if (diff < 0) {
                            dayDiffTxt += ' decrease';
                        } else if (diff > 0) {
                            dayDiffTxt += ' increase';
                        }
                    }
                    this.subtitle = dayDiffTxt;
                } else {
                    this.subtitle = Constants.MISSING_STR;
                }
            }
        );
    }

    firstUpdated(changedProperties) {
        this.fill();
    }

    render() {
        return html`
<div class="vao__components--storyLastRateChangeSubtitle">
    <vao-story-subtitle subtitle="${this.subtitle}" badgeClass="${this.badgeClass}"></vao-story-subtitle>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.StoryLastRateChangeSubtitle = StoryLastRateChangeSubtitle;
customElements.define('vao-story-last-rate-change-subtitle', StoryLastRateChangeSubtitle);
