import { AppElement, html, TemplateResult } from '../AppElement.js';

export const EVENT_ON_SELECT2_SELECT = 'vao-select2-select';

export default class Select2 extends AppElement {
    static get properties() {
        return {
            value: { type: String },
            options: { type: Array },

            // Select2 Config.
            selectionFormatter: { type: Function },
            dropdownFormatter: { type: Function },
            minimumResultsForSearch: { type: Number },
        };
    }

    constructor(props = {}) {
        super();
        this.id = AppElement.getUniqueElementId();

        this.value = props.value || this.value || undefined;
        this.options = props.options || this.options || [];

        this.selectionFormatter = props.selectionFormatter || this.selectionFormatter || null;
        this.dropdownFormatter = props.dropdownFormatter || this.dropdownFormatter || null;
        this.minimumResultsForSearch = props.minimumResultsForSearch || this.minimumResultsForSearch || null;
    }

    reflow(props = {}) {
        this.value = props.value || this.value;
        this.options = props.options || this.options;

        this.selectionFormatter = props.selectionFormatter || this.selectionFormatter;
        this.dropdownFormatter = props.dropdownFormatter || this.dropdownFormatter;
        this.minimumResultsForSearch = props.minimumResultsForSearch || this.minimumResultsForSearch;
    }

    makeSelect2Config() {
        let opts = {};
        if (typeof this.selectionFormatter === 'function') {
            opts.templateSelection = this.selectionFormatter;
        }
        if (typeof this.dropdownFormatter === 'function') {
            opts.templateResult = this.dropdownFormatter;
        }
        if (Number.isInteger(this.minimumResultsForSearch)) {
            opts.minimumResultsForSearch = this.minimumResultsForSearch;
        } else {
            opts.minimumResultsForSearch = Infinity;
        }
        return opts;
    }

    _onSelect(e) {
        const newVal = e.params.data.id;
        this.value = newVal;
        const event = new CustomEvent(EVENT_ON_SELECT2_SELECT, {
            detail: {
                newValue: newVal
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    $getSelectEl() {
        return $('#' + this.id + ' > select');
    }

    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);

        const config = this.makeSelect2Config();
        this.$getSelectEl().select2(config);
        this.$getSelectEl().val(this.value).trigger('change');
        this.$getSelectEl().on('select2:select', this._onSelect.bind(this));
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);

        const config = this.makeSelect2Config();
        this.$getSelectEl().select2('destroy').select2(config).trigger('change');
    }

    render() {
        return html`
<select class="vao__components--select2 form-control" value="${this.value}">
    ${this.options.map(option => {
        if (option instanceof HTMLElement || option instanceof TemplateResult) {
            return option;
        } else if (typeof option === 'string') {
            return html`<option>${option}</option>`
        } else if (option.type === 'optgroup') {
            const label = option.label || 'undefined';
            const groupOptions = (option.options || []).map(groupOption => {
                let isSelected = groupOption.value === this.value;
                return html`<option value="${groupOption.value}" ?selected="${isSelected}">${groupOption.text}</option>`;
            });
            return html`<optgroup label="${label}">${groupOptions}</optgroup>`;
        } else {
            let isSelected = option.value === this.value;
            return html`<option value="${option.value}" ?selected="${isSelected}">${option.text}</option>`;
        }
    })}
</select>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Select2 = Select2;
customElements.define('vao-select2', Select2);
